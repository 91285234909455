
import { ScreenDetail } from './ScreenDetail';
import { Action } from './actions/Action';
import { Utilities } from '../services/utilities/utilities';
import { ScreenType } from './Enums';

// Web UI generated object
export class ListDetail {

  id: string = Utilities.uniqueId();

  screenDetail: ScreenDetail;

  action: Action;

  loadScreenDetail: boolean = true;

  // Used for displaying title in breadcrumb (main.component)
  title: string;
  subtitle: string;

  // Reference back to row that generated action e.g. in a drilldown
  row: object[];
  screenType: ScreenType;

}
