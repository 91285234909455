import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { ActionIdentifier, ViewType } from 'src/app/models/Enums';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { ScreenColumn } from 'src/app/models/ScreenColumn';
import { DetailSection } from 'src/app/models/ScreenDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'app-inline-grid',
  templateUrl: './inline-grid.component.html',
  styleUrls: ['./inline-grid.component.scss']
})
export class InlineGridComponent implements OnInit {

  @Input() gridTemplate: DetailSection;
  _dataObjArray: any[];
  @Input()
  public set dataObjArray(v: any[]) {
    this._dataObjArray = v;
    this.dataSource.data = v;
  }
  public get dataObjArray(): any[] {
    return this._dataObjArray;
  }

  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() uriCollections: UriCollection[];

  dataSource = new MatTableDataSource();

  columnHeadings = [];

  public ViewType = ViewType;

  constructor(public toastr: ToastrService, private thingService: ThingService, public dialog: MatDialog) { }

  ngOnInit() {

    this.refreshGrid();

    if (this.gridTemplate) {
      this.loadGrid();
    }
  }

  refreshGrid(): void {

    if (!this.dataObjArray && this.gridTemplate.templateDataKey) {
      const uc = this.uriCollections.find(u => u.name === this.gridTemplate.templateDataKey);
      if (uc) {
        this.dataObjArray = uc.dataValues;
      } else {
        this.dataObjArray = [];
      }
    }
    this.dataObjArray = this.dataObjArray ? this.dataObjArray : [];

    this.dataSource.data = this.dataObjArray;
  }

  loadGrid() {
    this.gridTemplate.details.forEach(column => {
      this.columnHeadings.push(column.tag);
    });
  }

  getText(column: ScreenColumn, row: any) {
    switch (column.viewType) {
      case ViewType.Text:
      case ViewType.TextImage:
        return Utilities.parseArgumentsFromData(column.text.text, column.text.argumentIds, row);

      case ViewType.Image:
        if (column.icon && column.icon.iconArgument && column.icon.iconArgument.length > 0) {

          const property = Utilities.getLastEntry(column.icon.iconArgument);
          if (!property.startsWith('data.')) {
            return row[property];
          } else {
            return null;
          }
        }
    }
  }

  getMatIcon(column: ScreenColumn, row: any) {
    switch (column.viewType) {
      case ViewType.TextImage:
        if (column.icon && column.icon.iconArgument) {
          // const arg = column.icon.iconArgument;
          return column.icon.iconArgument;
        }
      // return Utilities.parseArgumentsFromData(column.text.text, column.text.argumentIds, row);

      // case ViewType.Image:
      //   if (column.icon && column.icon.iconArgument && column.icon.iconArgument.length > 0) {
      //     const property = Utilities.getLastEntry(column.icon.iconArgument);
      //     return row[property];
      //   }
    }
  }

  getPropertyName(path: string) {
    return Utilities.getLastEntry(path);
  }

  getButtonClass(item: any) {
    if (item && item.class) {
      if (item.class === 'qm-stroked-button') { return 'mat-stroked-button'; }
      if (item.class === 'qm-button') { return 'mat-button'; }
      return item.class;
    } else {
      return 'mat-button';
    }
  }

  thumbnailUrl(fileId: string) {
    return this.thingService.getFileUrl(fileId, 'tiny');
  }

  public itemClick(event, item: ScreenColumn, row: any) {

    item.action.forEach(action => {

      let done = false;
      if (action.action === ActionIdentifier.DeleteItem) {
        const actionArg = action.actionArgument as UpdateDataAction;
        if (actionArg.editUri == null) {
          const index = this.dataObjArray.findIndex(v => v === row);
          this.dataObjArray.splice(index, 1);
          this.dataSource.data = this.dataObjArray;
          done = true;
        }
      }

      if (!done) {
        const completionEvent = new EventEmitter<ActionCompletedEvent>();
        completionEvent.subscribe(complete => {
          // this.refresh();
        });
        const ab = new ActionBuilder(item.action, row, null, this.uriCollections, null, completionEvent, null, this.thingService, this.toastr, this.dialog);
        ab.PerformAction();
      }
    });
  }
}
