import { DataPath } from '../models/dataPathModels/DataPath';
import { DataUtils } from '../services/data-utils/data-utils';
import { Utilities } from '../services/utilities/utilities';
import { ViewControlType, ViewType, UIDataType, DataType } from '../models/Enums';
import { GridColumnItem } from '../models/viewGroupHelpers/GridColumnItem';
import { ScreenColumn } from '../models/ScreenColumn';
import { FTThingDetail } from '../models/data/FTThingDetail';
import { ToastrService } from 'ngx-toastr';

export class GridColumnHelper {

    private _columnHeadings: string[] = [];
    public get columnHeadings(): string[] {
        return this._columnHeadings;
    }
    public set columnHeadings(v: string[]) {
        this._columnHeadings = v;
    }

    private _columnFields: GridColumnItem[] = [];
    public get columnFields(): GridColumnItem[] {
        return this._columnFields;
    }
    public set columnFields(v: GridColumnItem[]
    ) {
        this._columnFields = v;
    }


    public constructor(public toastr: ToastrService) {

    }

    public generateColumns(columns: ScreenColumn[], metadata: FTThingDetail[], dataItems: any[], bulkAction: boolean, showCheckboxForSingleSelect: boolean) {

        let viewControlType: ViewControlType = ViewControlType.Default;
        let viewType: ViewType = ViewType.None;
        let referenceTo = '';

        if (bulkAction) {

            this.columnHeadings.push('select');

            this.columnFields.push({
                visible: true, columnHeader: 'select', referenceTo: 'select', propertyName: 'select', objectName: null, childObjectName: null, icon: null,
                class: 'col-select', viewControlType: ViewControlType.MultiSelectAll,
                isEnabledCondition: null, isVisibleCondition: null, isSticky: true, controlStyle: null,
                canReorder: false,
                index: 0, isFilterable: false, lookup: null, tag: null, text: null, viewType: null, action: null, color: null, style: null, maxValue: null, hint: null
            });

        } else if (showCheckboxForSingleSelect) {

            // Single selection
            this.columnHeadings.push('select');

            this.columnFields.push({
                visible: true, columnHeader: 'select', referenceTo: 'select', propertyName: 'select', objectName: null, childObjectName: null, icon: null,
                class: 'col-select', viewControlType: ViewControlType.SingleSelect, isSticky: true, canReorder: false,
                index: 0, isFilterable: false, lookup: null, tag: null, text: null, viewType: null, color: null, style: null, action: null,
                isEnabledCondition: null, isVisibleCondition: null, maxValue: null, hint: null, controlStyle: null
            });
        }

        // Index 0 = select
        let index = 0;
        for (const item of columns.sort((n1, n2) => n1.sequence - n2.sequence)) {

            index++;
            viewType = item.viewType;
            referenceTo = '';
            viewControlType = ViewControlType.Default;

            if (viewType === ViewType.List) {
                const md = DataUtils.getMetadatumAndDataPath(item.rootObjectPath, metadata);
                if (md[0].dataType === DataType.StockInfo) {
                    return;
                }
            }

            if (viewType === ViewType.NumberPicker) {
                viewControlType = ViewControlType.NumberPicker;
            }

            if (item.text && item.text.argumentIds) {

                // if (viewType === ViewType.Text || viewType === ViewType.EditText) {

                const md = DataUtils.getMetadatumAndDataPath(item.text.argumentIds[0], metadata);
                if (!md) {
                    console.log(`Can't find metadata for ${item.text.argumentIds[0]}`);
                    continue;
                }

                item.metadata = md[0];
                const dataPath = md[1];
                if (!dataPath || !item.metadata) {
                    continue;
                }

                let lookupName: string;

                if (item.metadata) {
                    item.columnId = item.metadata.javaScriptName;
                } else {
                    this.toastr.error('No metadata provided for column ' + item.columnHeader);
                }

                if (item.metadata != null) {

                    const uiDataType = DataUtils.dataTypeToUIDataType(item.metadata.dataType, item.metadata.referenceTo);
                    if (uiDataType === UIDataType.DateTime) {
                        viewControlType = ViewControlType.Date;
                    } else if ((uiDataType === UIDataType.Number || uiDataType === UIDataType.Money) && !(item.text.text)) {
                        viewControlType = ViewControlType.Number;

                        if (item.colClass === 'qm-circle') {
                            // if (item.text && item.text.text && item.text.text.indexOf('/') > -1) {
                            viewControlType = ViewControlType.Counter;
                        }
                    }

                    if (item.metadata.referenceTo) {
                        // if (Utilities.getFirstEntry(item.metadata.referenceTo).toLowerCase() === 'lookups') {
                        if (uiDataType === UIDataType.Lookup) {
                            viewControlType = ViewControlType.Lookup;
                            lookupName = Utilities.getLastEntry(item.metadata.referenceTo);
                        } else if (uiDataType === UIDataType.FlowStatus) {
                            viewControlType = ViewControlType.Flow;
                        }
                    }
                }

                if (item.action) {
                    viewControlType = ViewControlType.Button;
                }

                if (item.columnId) {
                    this.columnHeadings.push(item.columnId);

                    this.columnFields.push({
                        index: index, viewType: viewType, text: item.text,
                        visible: true, class: item.colClass, style: item.colStyle,
                        columnHeader: item.columnHeader, isFilterable: item.isFilterable, lookup: lookupName,
                        propertyName: item.columnId, objectName: dataPath.rootObjectName, childObjectName: dataPath.childObjectNames[0],
                        icon: (item.icon ? item.icon.iconArgument : null), color: item.icon ? Utilities.getLastEntry(item.icon.colour) : null,
                        viewControlType: viewControlType, tag: item.tag,
                        referenceTo: item.metadata.referenceTo, action: item.action ? item.action : null,
                        isEnabledCondition: item.isEnabledCondition, isVisibleCondition: item.isVisibleCondition, maxValue: item.maxValue,
                        isSticky: item.sticky, hint: item.hint, controlStyle: item.controlStyle, canReorder: item.canReorder
                    });
                }
            } else if (item.icon && item.icon.iconArgument && item.icon.iconArgument.indexOf('.') > -1) {

                const md = DataUtils.getMetadatumAndDataPath(item.icon.iconArgument, metadata);
                item.metadata = md[0];
                const dataPath = md[1];
                if (!dataPath || !item.metadata) {
                    continue;
                }

                if (item.metadata) { item.columnId = item.metadata.javaScriptName; }

                this.columnHeadings.push(item.columnId);

                let color: string;
                if (!!item.icon.colour) {
                    color = Utilities.getLastEntry(item.icon.colour);
                    // color = Utilities.getAttributeName(this.uri.name, item.icon.colour);
                }

                viewControlType = ViewControlType.Icon;
                let lookupName: string;

                if (item.metadata && item.metadata.referenceTo) {
                    if (Utilities.getFirstEntry(item.metadata.referenceTo).toLowerCase() === 'lookups') {
                        viewControlType = ViewControlType.Lookup;
                        lookupName = Utilities.getLastEntry(item.metadata.referenceTo);
                    } else {
                        viewControlType = ViewControlType.Flow;
                    }
                }

                this.columnFields.push({
                    index: index, viewType: viewType, visible: true, class: item.colClass, style: item.colStyle, color: color,
                    columnHeader: item.columnHeader, isFilterable: item.isFilterable, referenceTo: referenceTo, lookup: lookupName,
                    propertyName: item.columnId, objectName: dataPath.rootObjectName, childObjectName: dataPath.childObjectNames[0],
                    viewControlType: viewControlType, icon: (item.icon ? item.icon.iconArgument : null), tag: item.tag,
                    text: null, action: item.action ? item.action : null, isEnabledCondition: item.isEnabledCondition, isVisibleCondition: item.isVisibleCondition,
                    maxValue: item.maxValue, isSticky: item.sticky, hint: item.hint, controlStyle: item.controlStyle, canReorder: item.canReorder
                });

            } else if (item.icon && item.icon.colour) {

                const md = DataUtils.getMetadatumAndDataPath(item.icon.colour, metadata);
                item.metadata = md[0];
                const dataPath = md[1];
                if (!dataPath || !item.metadata) {
                    continue;
                }

                if (item.metadata) {
                    item.columnId = item.metadata.javaScriptName;
                }

                // this.columnHeadings.push(item.columnId);

                let color: string;
                if (!!item.icon.colour) {
                    color = Utilities.getLastEntry(item.icon.colour);
                    // color = Utilities.getAttributeName(this.uri.name, item.icon.colour);
                }

                viewControlType = ViewControlType.Color;

                this.columnHeadings.push('sidebar');
                console.log(item);
                this.columnFields.push({
                    visible: true, columnHeader: 'sidebar', referenceTo: 'sidebar', propertyName: 'sidebar', objectName: dataPath.rootObjectName,
                    childObjectName: dataPath.childObjectNames[0],
                    icon: null, class: 'col-sidebar', viewControlType: ViewControlType.SideBar,
                    isEnabledCondition: null, isVisibleCondition: null, maxValue: item.maxValue, isSticky: item.sticky, hint: item.hint,
                    canReorder: item.canReorder,
                    color: color,
                    index: 0, isFilterable: false, lookup: null, style: null, tag: null, text: null, viewType: null, action: item.action ? item.action : null, controlStyle: item.controlStyle
                });
            } else {

                let path = '';
                let dataPath: DataPath;
                if (item.dataPath) {
                    item.columnId = item.dataPath;
                    path = item.dataPath;
                    const md = DataUtils.getMetadatumAndDataPath(path, metadata);
                    item.metadata = md[0];
                    dataPath = md[1];
                } else if (item.isCheckedCondition) {
                    item.columnId = Utilities.getLastEntry(item.isCheckedCondition);
                    path = item.isCheckedCondition;
                    const md = DataUtils.getMetadatumAndDataPath(path, metadata);
                    item.metadata = md[0];
                    dataPath = md[1];
                } else if (item.tag) {
                    item.columnId = `tag-${item.tag}`;
                } else {
                    item.columnId = item.sequence.toString();
                }

                if (item.defaultValue) {
                    for (const row of dataItems) {
                        row[item.columnId] = item.defaultValue;
                    }
                }

                item.isFilterable = false;

                this.columnHeadings.push(item.columnId);

                const hasAction: boolean = (item.action && item.action[0].action > 0);

                if (item.viewType === ViewType.NumberPicker) {
                    viewControlType = ViewControlType.NumberPicker;
                } else if (item.viewType === ViewType.Checkbox) {
                    viewControlType = ViewControlType.DataSelect;
                } else if (hasAction && viewControlType === ViewControlType.Default) {
                    // Change to a button if its a text / icon and it has an action
                    viewControlType = ViewControlType.Button;
                }

                this.columnFields.push({
                    index: index, viewType: viewType, text: item.text, hint: item.hint,
                    visible: true, class: item.colClass, style: item.colStyle,
                    columnHeader: item.columnHeader, isFilterable: item.isFilterable, action: hasAction ? item.action : null,
                    propertyName: item.columnId, objectName: dataPath ? dataPath.rootObjectName : '', childObjectName: dataPath ? dataPath.childObjectNames[0] : '',
                    viewControlType: viewControlType, icon: (item.icon ? item.icon.iconArgument : null), tag: item.tag,
                    color: null, lookup: null, referenceTo: item.metadata ? item.metadata.referenceTo : null, controlStyle: item.controlStyle,
                    isEnabledCondition: item.isEnabledCondition, isVisibleCondition: item.isVisibleCondition, maxValue: item.maxValue, isSticky: item.sticky,
                    canReorder: item.canReorder
                });
            }

        }
    }
}
