

export class DataDetail  {

  totalRecordCount: number;

  returnRecordCount: number;

  dataItems: any[];

}
