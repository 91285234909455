import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeENGB from '@angular/common/locales/en-GB';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule, MatSelectModule, MatSidenavModule, MatSortModule, MatTableModule, MatTooltipModule, MatTreeModule, MatProgressSpinnerModule, MAT_DATE_LOCALE } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncCacheModule } from 'angular-async-cache';
import { ChartModule } from 'angular-highcharts';
import { MomentModule } from 'angular2-moment';
import { TextMaskModule } from 'angular2-text-mask';
import { SidebarModule } from 'ng-sidebar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ColorPickerModule } from 'ngx-color-picker';
import { ContextMenuModule } from 'ngx-contextmenu';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { FileDropModule } from 'ngx-file-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { QChartComponent } from './components/controls/charts/q-chart/q-chart.component';
import { QFromToIndicatorComponent } from './components/controls/charts/q-from-to-indicator/q-from-to-indicator.component';
import { QStockIndicatorComponent } from './components/controls/charts/q-stock-indicator/q-stock-indicator.component';
import { AlertDialogComponent } from './components/controls/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './components/controls/dialogs/confirm-dialog/confirm-dialog.component';
import { FilterDialogComponent } from './components/controls/dialogs/filter-dialog/filter-dialog.component';
import { GraphDialogComponent } from './components/controls/dialogs/graph-dialog/graph-dialog.component';
import { StockGraphDialogComponent } from './components/controls/dialogs/graph-dialog/stock-graph-dialog.component';
import { GridSettingsDialogComponent } from './components/controls/dialogs/grid-settings-dialog/grid-settings-dialog.component';
import { SwitchContentViewDialogComponent } from './components/controls/dialogs/switch-content-view-dialog/switch-content-view-dialog.component';
import { ViewDetailsDialogComponent } from './components/controls/dialogs/view-details-dialog/view-details-dialog.component';
import { AutoCompleteDropdownComponent } from './components/controls/dropdowns/auto-complete-dropdown/auto-complete-dropdown.component';
import { AutoCompleteMultiDropdownComponent } from './components/controls/dropdowns/auto-complete-multi-dropdown/auto-complete-multi-dropdown.component';
import { SimpleDropdownComponent } from './components/controls/dropdowns/simple-dropdown/simple-dropdown.component';
import { SimpleMultiDropdownComponent } from './components/controls/dropdowns/simple-multi-dropdown/simple-multi-dropdown.component';
import { VirtualScrollDropdownComponent } from './components/controls/dropdowns/virtual-scroll-dropdown/virtual-scroll-dropdown.component';
import { VirtualScrollMultiDropdownComponent } from './components/controls/dropdowns/virtual-scroll-multi-dropdown/virtual-scroll-multi-dropdown.component';
import { CounterIconComponent } from './components/controls/icons/counter-icon/counter-icon.component';
import { InitialsIconComponent } from './components/controls/icons/initials-icon/initials-icon.component';
import { DatetimeInputComponent } from './components/controls/inputs/datetime-input/datetime-input.component';
import { IntegerInputComponent } from './components/controls/inputs/integer-input/integer-input.component';
import { IntegerModelInputComponent } from './components/controls/inputs/integer-model-input/integer-model-input.component';
import { QMultiDatetimeInputComponent } from './components/controls/inputs/q-multi-datetime-input/q-multi-datetime-input.component';
import { TagListInputComponent } from './components/controls/inputs/tag-list-input/tag-list-input.component';
import { ActionToolbarComponent } from './components/controls/lists/action-toolbar/action-toolbar.component';
import { CardListComponent } from './components/controls/lists/card-list/card-list.component';
import { OrderBasketCardComponent } from './components/controls/lists/cards/order-basket-card/order-basket-card.component';
import { QuantityCardComponent } from './components/controls/lists/cards/quantity-card/quantity-card.component';
import { SimpleStatusCardComponent } from './components/controls/lists/cards/simple-status-card/simple-status-card.component';
import { StatusCardComponent } from './components/controls/lists/cards/status-card/status-card.component';
import { GridListComponent } from './components/controls/lists/grid-list/grid-list.component';
import { ItemListComponent } from './components/controls/lists/item-list/item-list.component';
import { SectionListComponent } from './components/controls/lists/section-list/section-list.component';
import { OrderBasketComponent } from './components/controls/order-basket-rental/order-basket/order-basket.component';
import { OrderTotalComponent } from './components/controls/order-basket-rental/order-total/order-total.component';
import { QuickAddComponent } from './components/controls/order-basket-rental/quick-add/quick-add.component';
import { PriceBandUpdateDialogComponent } from './components/controls/pricing/price-band-update-dialog/price-band-update-dialog.component';
import { PriceBandComponent } from './components/controls/pricing/price-band/price-band.component';
import { PriceItemComponent } from './components/controls/pricing/price-item/price-item.component';
import { PriceSchemeDetailUpdateDialogComponent } from './components/controls/pricing/price-scheme-detail-update-dialog/price-scheme-detail-update-dialog.component';
import { PriceSchemeDetailComponent } from './components/controls/pricing/price-scheme-detail/price-scheme-detail.component';
import { PriceSchemeUpdateDialogComponent } from './components/controls/pricing/price-scheme-update-dialog/price-scheme-update-dialog.component';
import { PriceSchemeComponent } from './components/controls/pricing/price-scheme/price-scheme.component';
import { PricingDialogComponent } from './components/controls/pricing/pricing-dialog/pricing-dialog.component';
import { PricingComponent } from './components/controls/pricing/pricing/pricing.component';
import { SideNavComponent } from './components/controls/side-nav/side-nav.component';
import { TopNavComponent } from './components/controls/top-nav/top-nav.component';
import { TreeViewComponent } from './components/controls/tree/tree-view/tree-view.component';
import { UpdateDetailComponent } from './components/controls/update-detail/update-detail.component';
import { ViewDetailComponent } from './components/controls/view-detail/view-detail.component';
import { ViewMultipleComponent } from './components/controls/view-multiple/view-multiple.component';
import { LoginComponent } from './components/login-views/login/login.component';
import { MainComponent } from './components/main/main.component';
import { AutoFocusDirective } from './directives/auto-focus/auto-focus.directive';
import { IntegerOnlyDirective } from './directives/integer-only/integer-only.directive';
import { NumberOnlyDirective } from './directives/number-only/number-only.directive';
import { PositiveOnlyDirective } from './directives/positive-only/positive-only.directive';
import { QContrastColorDirective } from './directives/q-contrast-color/q-contrast-color.directive';
import { AuthGuard } from './guards/auth.guard';
import { ElementImagePipe } from './pipes/card/element-image.pipe';
import { ElementLabelPipe } from './pipes/card/element-label.pipe';
import { ElementPropertyNamePipe } from './pipes/card/element-property-name.pipe';
import { IsElementEnabledPipe } from './pipes/card/is-element-enabled.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { UIDataTypePipe } from './pipes/uidata-type.pipe';
import { SafeStylePipe } from './pipes/safeStyle.pipe';
import { AuthenticationService } from './services/authentication/authentication.service';
import { TokenInterceptor } from './services/authentication/token.interceptor';
import { FlowService } from './services/flow/flow.service';
import { ThingService } from './services/thing/thing.service';
import { FileUploadComponent } from './components/controls/inputs/file-upload/file-upload.component';
import { SecuredImageComponent } from './components/controls/secured-image/secured-image.component';
import { UpdateDetailsDialogComponent } from './components/controls/dialogs/update-details-dialog/update-details-dialog.component';
import { FilePagerDialogComponent } from './components/controls/dialogs/file-pager-dialog/file-pager-dialog.component';
import { LookupDropdownComponent } from './components/controls/dropdowns/lookup-dropdown/lookup-dropdown.component';
import { TelephoneNumbersComponent } from './components/controls/inputGroups/telephone-numbers/telephone-numbers.component';
import { LoadingImageComponent } from './components/controls/view-controls/loading-image/loading-image.component';
import { ManageProfileComponent } from './components/login-views/manage-profile/manage-profile.component';
import { ChangePasswordComponent } from './components/login-views/change-password/change-password.component';
import { ProductCatalogueComponent } from './components/controls/order-catalogue-checkout/product-catalogue/product-catalogue.component';
import { OrderCheckoutComponent } from './components/controls/order-catalogue-checkout/order-checkout/order-checkout.component';
import { OrderCheckoutItemComponent } from './components/controls/lists/cards/order-checkout-item/order-checkout-item.component';
import { OrderFormComponent } from './components/controls/order-catalogue-checkout/order-form/order-form.component';
import { TreeFilterDialogComponent } from './components/controls/dialogs/tree-filter-dialog/tree-filter-dialog.component';
import { CompositeItemsSelectionComponent } from './components/controls/inputGroups/composite-items-selection/composite-items-selection.component';
import { ChecklistComponent } from './components/controls/dropdowns/checklist/checklist.component';
import { InlineGridComponent } from './components/controls/inputGroups/inline-grid/inline-grid.component';
import { FlagSummaryComponent } from './components/controls/view-controls/flag-summary/flag-summary.component';
import { FlagSummaryDialogComponent } from './components/controls/view-controls/flag-summary-dialog/flag-summary-dialog.component';
import { StockGridListComponent } from './components/controls/lists/stock-grid-list/stock-grid-list.component';
import { UpdateViewDialogComponent } from './components/controls/dialogs/update-view-dialog/update-view-dialog.component';
import { ActionsComponent } from './components/controls/actions/actions.component';
import { Menu1Component } from './components/menu/menu1/menu1.component';
import { Menu2Component } from './components/menu/menu2/menu2.component';
import { ViewTimelineComponent } from './components/controls/view-timeline/view-timeline.component';
import { TimelineDialogComponent } from './components/controls/dialogs/timeline-dialog/timeline-dialog.component';
import { EmbeddedOrderBasketComponent } from './components/controls/inputGroups/embedded-order-basket/embedded-order-basket.component';
import { AutoCompleteEditTextComponent } from './components/controls/dropdowns/auto-complete-edit-text/auto-complete-edit-text.component';
import { DateTimeRangeInputComponent } from './components/controls/inputs/date-time-range-input/date-time-range-input.component';
import { CalendarTestComponent } from './components/controls/calendar/calendar-test/calendar-test.component';
import { MyCalendarModule } from './components/controls/calendar/my-calendar/my-calendar.module';
import { CalendarModule } from './components/controls/calendar/modules/calendar.module';
import { GanttActivityModule } from './activity/gantt-activity.module';
import { GanttDemoComponent } from './gantt-demo/gantt-demo.component';
import { BaseGanttComponent } from './gantt/base-gantt.component';
import { GanttFooterComponent } from './gantt/footer/gantt-footer.component';
import { GanttHeaderComponent } from './gantt/header/gantt-header.component';
import { SwitchContentComponent } from './components/controls/switch-content-component/switch-content.component';
import { MatRadioModule } from '@angular/material';
import { GenerateDocumentDialogComponent } from './components/controls/dialogs/generate-document-dialog/generate-document-dialog.component';

// register GB locale
registerLocaleData(localeENGB);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    ViewDetailComponent,
    UpdateDetailComponent,
    TopNavComponent,
    SideNavComponent,
    AutoCompleteDropdownComponent,
    SimpleDropdownComponent,
    VirtualScrollDropdownComponent,
    AutoCompleteMultiDropdownComponent,
    SimpleMultiDropdownComponent,
    VirtualScrollMultiDropdownComponent,
    OrderBasketComponent,
    TreeViewComponent,
    ViewDetailsDialogComponent,
    QuickAddComponent,
    OrderTotalComponent,
    AutoFocusDirective,
    IntegerInputComponent,
    IntegerOnlyDirective,
    NumberOnlyDirective,
    PositiveOnlyDirective,
    QContrastColorDirective,
    ConfirmDialogComponent,
    ViewMultipleComponent,
    AlertDialogComponent,
    GraphDialogComponent,
    StockGraphDialogComponent,
    PricingDialogComponent,
    EscapeHtmlPipe,
    SecurePipe,
    SafeStylePipe,
    DatetimeInputComponent,
    ItemListComponent,
    GridListComponent,
    CardListComponent,
    SectionListComponent,
    GridSettingsDialogComponent,
    FilterDialogComponent,
    CounterIconComponent,
    SwitchContentViewDialogComponent,
    InitialsIconComponent,
    QChartComponent,
    QFromToIndicatorComponent,
    QStockIndicatorComponent,
    PricingComponent,
    PriceBandComponent,
    PriceSchemeDetailComponent,
    PriceBandUpdateDialogComponent,
    PriceSchemeUpdateDialogComponent,
    PriceSchemeComponent,
    IntegerModelInputComponent,
    PriceItemComponent,
    PriceSchemeDetailUpdateDialogComponent,
    QMultiDatetimeInputComponent,
    QuantityCardComponent,
    OrderBasketCardComponent,
    StatusCardComponent,
    ActionToolbarComponent,
    TagListInputComponent,
    ElementImagePipe,
    ElementLabelPipe,
    ElementPropertyNamePipe,
    UIDataTypePipe,
    IsElementEnabledPipe,
    SimpleStatusCardComponent,
    FileUploadComponent,
    SecuredImageComponent,
    UpdateDetailsDialogComponent,
    FilePagerDialogComponent,
    LookupDropdownComponent,
    TelephoneNumbersComponent,
    LoadingImageComponent,
    ManageProfileComponent,
    ChangePasswordComponent,
    ProductCatalogueComponent,
    OrderCheckoutComponent,
    OrderCheckoutItemComponent,
    OrderFormComponent,
    TreeFilterDialogComponent,
    CompositeItemsSelectionComponent,
    ChecklistComponent,
    InlineGridComponent,
    FlagSummaryComponent,
    FlagSummaryDialogComponent,
    StockGridListComponent,
    UpdateViewDialogComponent,
    ActionsComponent,
    Menu1Component,
    Menu2Component,
    ViewTimelineComponent,
    TimelineDialogComponent,
    EmbeddedOrderBasketComponent,
    AutoCompleteEditTextComponent,
    DateTimeRangeInputComponent,
    CalendarTestComponent,
    GanttHeaderComponent,
    GanttFooterComponent,
    BaseGanttComponent,
    GanttDemoComponent,
    SwitchContentComponent,
    GenerateDocumentDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    BrowserAnimationsModule,
    GanttActivityModule,
    MyCalendarModule,
    ToastrModule.forRoot(),
    MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatDatepickerModule, MatSidenavModule,
    MatCardModule, MatGridListModule, MatButtonToggleModule,
    MatInputModule, MatTableModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatSelectModule, MatTreeModule, MatDialogModule, MatDividerModule,
    MatMomentDateModule, MatTooltipModule, MatChipsModule,
    MatListModule, MatExpansionModule, MatMenuModule,
    SidebarModule.forRoot(),
    routing,
    ContextMenuModule.forRoot({ useBootstrap4: true }),
    FlexLayoutModule,
    ModalModule.forRoot(),
    MomentModule,
    TextMaskModule,
    InfiniteScrollModule,
    ChartModule,
    AsyncCacheModule.forRoot(),
    ColorPickerModule,
    DragDropModule,
    FileDropModule,
    ScrollingModule
  ],
  entryComponents: [
    ViewDetailsDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    GridSettingsDialogComponent,
    FilterDialogComponent,
    GraphDialogComponent,
    StockGraphDialogComponent,
    SwitchContentViewDialogComponent,
    PricingDialogComponent,
    PriceBandUpdateDialogComponent,
    PriceSchemeUpdateDialogComponent,
    PriceSchemeDetailUpdateDialogComponent,
    UpdateDetailsDialogComponent,
    FilePagerDialogComponent,
    ManageProfileComponent,
    ChangePasswordComponent,
    TreeFilterDialogComponent,
    FlagSummaryDialogComponent,
    UpdateViewDialogComponent,
    TimelineDialogComponent,
    GenerateDocumentDialogComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    AuthGuard,
    AuthenticationService,
    TokenInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ThingService,
    FlowService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
