import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataDetail } from 'src/app/models/DataDetail';
import { FileUploadPost } from 'src/app/models/dataService/FileUploadPost';
import { FtFile } from 'src/app/models/dataService/FtFile';
import { PostResponse } from 'src/app/models/PostResponse';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

export interface DialogData {
  parentObjectType: string;
  parentObjectIID: number;
  rowObject: any;
  primaryFileIdPath: string;
}

@Component({
  selector: 'app-file-pager-dialog',
  templateUrl: './file-pager-dialog.component.html',
  styleUrls: ['./file-pager-dialog.component.scss']
})
export class FilePagerDialogComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  fileList: FtFile[];
  currentFile: FtFile = new FtFile();
  _currentFilePosition: number;
  addingFile: boolean;
  get currentFilePosition(): number { return this._currentFilePosition; }
  set currentFilePosition(position: number) {
    if (position === -2) {
      position = this.fileList.length - 1;
    }
    if (position < 0 || position > this.fileList.length - 1) {
      this._currentFilePosition = -1;
      this.currentFile = null;
    } else {
      this._currentFilePosition = position;
      this.currentFile = Object.assign({}, this.fileList[position]);
    }
  }

  constructor(
    public dialogRef: MatDialogRef<FilePagerDialogComponent>,
    public thingService: ThingService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.initFiles();
    this.dialogRef.beforeClosed().subscribe(() => this.saveCurrentFile());
  }

  initFiles() {
    this.thingService.getFileList(this.data.parentObjectType, this.data.parentObjectIID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetFilesSuccessful(results), error => console.log(error));
  }

  onGetFilesSuccessful(results: DataDetail): void {
    this.fileList = results.dataItems;
    if (this.fileList && this.fileList.length > 0) {
      this.currentFilePosition = 0;
      this.currentFile = Object.assign({}, this.fileList[0]);
    }
  }

  getFileUrl(fileId: string) {
    if (!fileId) {
      return;
    }
    return this.thingService.getFileUrl(fileId, 'medium');
  }

  saveCurrentFile() {

    if (!this.fileList || !this.currentFile) { return; }
    if (this.fileList[this.currentFilePosition].notes === this.currentFile.notes) { return; }
    this.thingService.updateFile(this.currentFile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        results => { if (results[0].errorCode === 0) { this.fileList[this.currentFilePosition] = this.currentFile; } },
        error => console.log(error));
  }

  increment() {
    this.saveCurrentFile();
    this.currentFilePosition++;
  }

  decrement() {
    this.saveCurrentFile();
    this.currentFilePosition--;
  }

  deleteFile(fileId: string) {

    if (this.data.rowObject[Utilities.getLastEntry(this.data.primaryFileIdPath)] === fileId) {
      this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          message: 'Cannot delete primary image',
          neutralButton: { title: 'OK' }
        }
      });
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { message: 'Are you sure you want to delete this item?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.thingService.deleteSingleItem(`/api/data/ftfile/${fileId}`)
          .pipe(takeUntil(this.destroy$))
          .subscribe(results => this.onDeleteFileSuccessful(results, fileId), error => console.log(error));
      }
    });
  }

  onDeleteFileSuccessful(results: PostResponse, fileId: string): void {
    if (results.errorCode === 0) {
      const index = this.fileList.findIndex(f => f.ftFileItemID === fileId);
      this.fileList.splice(index, 1);
      if (this.currentFilePosition > 0) {
        this.currentFilePosition--;
      } else {
        this.currentFilePosition++;
      }
    }
  }

  public dropped(event: UploadEvent) {

    for (const droppedFile of event.files) {

      if (droppedFile.fileEntry.isFile) {

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        this.uploadFile(fileEntry);
      }
    }
  }

  uploadFile(fileEntry: FileSystemFileEntry) {

    this.addingFile = true;
    fileEntry.file((file: File) => {

      // Here you can access the real file
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      let base64String = '';
      reader.onload = (function (readerEvent) {
        const binaryString = readerEvent.target.result;
        base64String = btoa(binaryString);

        const fileUpload: FileUploadPost = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64String,
          parentObjectIID: this.data.parentObjectIID,
          parentObjectType: this.data.parentObjectType,
        };
        this.thingService.uploadFile(fileUpload)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            this.addingFile = false;
            if (result.errorCode === 0) {

              const fileOb = result.returnedObject as FtFile;
              this.fileList.push(fileOb);
              this.currentFilePosition = this.fileList.length - 1;

            } else {
              console.log(result.errorMessage);
            }
            this.fileEntry = null;
          }, error => {
            this.addingFile = false;
            this.fileEntry = null;
            console.log(error);
          });
      }).bind(this);
    });
  }
  
  ngOnDestroy(): void {

    this.saveCurrentFile();
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
