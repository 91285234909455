import { DataPathHelper } from "src/app/helpers/data-path-helper";

export class BindingPath {

    constructor(bindingPath: string) {
        if (!DataPathHelper.isBindingPath(bindingPath)) {
            // console.log(`${dataPath} is not a data path`);
            this.isValid = false;
            return null;
        }

        this.isValid = true;
        const parts = bindingPath.split('.');
        if(parts.length == 3){
            this.tag = parts[1];
            this.propertyName = parts[2];
        }
        else{
            this.tag = parts[1];
            this.propertyName = "value";
        }

        parts.shift();
    }

    tag: string;
    propertyName: string;

    isValid: boolean;
}
