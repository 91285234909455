import { Component, OnInit, Input } from '@angular/core';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { MenuType, ActionIdentifier } from 'src/app/models/Enums';
import { Action } from 'src/app/models/actions/Action';
import { Utilities } from 'src/app/services/utilities/utilities';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { PostResponse } from 'src/app/models/PostResponse';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { IconService } from 'src/app/services/icon/icon.service';

@Component({
  selector: 'app-action-toolbar',
  templateUrl: './action-toolbar.component.html',
  styleUrls: ['./action-toolbar.component.scss']
})
export class ActionToolbarComponent implements OnInit {

  @Input() screenDetailComponents: ScreenDetail;
  @Input() search: boolean = true;
  @Input() buttonHeaderHeight: number = 56;

  constructor(private iconHelper: IconHelper, private iconService: IconService) { }

  ngOnInit() {
  }

//   public onClick(item: MenuItem) {

//     if (item.menuItemType === MenuType.ToggleGroup) {

//       for (let menuItem of this.screenDetailComponents.toolbarItems.filter(m => m.parentMenuItemId === item.parentMenuItemId)) {
//         menuItem.active = (menuItem.menuItemId === item.menuItemId);
//       }
//     }

//     let action: Action = item.action[0];

//     if (action.action === ActionIdentifier.LaunchScreen) {

//       var parsedAction = Utilities.parseAction(action, this.row, this.screenParameters);
//       this.onAdd.emit({ action: parsedAction, newItem: true, screenParameters: this.screenParameters });
//     }
//     else if (action.action === ActionIdentifier.DeleteItem) {
//       this.deleteSelected(item);
//     }
//     else if (action.action === ActionIdentifier.ReloadData) {
//       this.reloadData(action);
//     }
//     else {
//       this.toastr.error("Action " + action.action + " not defined.");
//     }
//   }

//   private reloadData(action: Action) {

//     let dataUri: string = Utilities.parseArgumentsFromData(action.actionArgument.dataUri, action.actionArgument.dataUriArgs, null, this.row,
//       this.screenParameters);
//     // TODO should use uriCollections to refresh and so know the name

//     let name = this.uriCollections[0].name;

//     this.loadingIndicator = true;

//     this.thingService.getDataDetailWithHeader(dataUri, this.pageIndex, this.componentPageSize, this.sortActive, this.sortDirection, null, this.searchValue)
//       .subscribe(result => this.getDataDetailWithHeaderSuccessful(name, this.paging, result), error => this.onGetDetailFailed(error));
//   }

//   private deleteSelected(item: MenuItem) {

//     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
//       width: '450px',
//       data: { message: "Are you sure you want to delete the selected items?" }
//     });

//     dialogRef.afterClosed().subscribe(result => {

//       if (result) {

//         let arg: string = item.action[0].actionArgument.editUriArgs[0];
//         this.idName = arg.substring(arg.lastIndexOf('.') + 1);

//         let selectedIds = [];

//         for (let r of this.dataDetail.dataItems) {
//           if (r.selected) {
//             selectedIds.push(r[this.idName]);
//           }
//         }

//         this.loadingIndicator = true;
//         this.thingService.deleteItem(item.action[0].actionArgument.editUri, selectedIds)
//           .subscribe(results => this.onDeleteItemSuccessful(results), error => this.onDeleteItemFailed(error));
//       }

//     });

//   }

//   private onDeleteItemSuccessful(result: PostResponse[]) {

//     for (let r of result) {
//       if (r.errorCode == 0) {
//         this.refresh();
//         this.onDeleteCompleted.emit({ name: this.idName, value: r.returnedObject });
//       }

//       else {

//         this.toastr.error(r.errorMessage, null, { closeButton: true, tapToDismiss: true });

//       }

//     };
//   }

//   private onDeleteItemFailed(error: any) {

//     this.loadingIndicator = false;
//     this.toastr.error(`Unable to delete data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, null, { closeButton: true, tapToDismiss: true });
//   }


public getFontAwesomeIcon(id: any)
{
  var iconHex = this.iconHelper.getFontAwesomeHex(id);
  var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
  return this.iconHelper.setIcon(iconMapping, iconHex);
}
  
}
