export class DataPathHelper{
    public static isDataPath(path: string){
        if(!path){
            return false;
        }
        return path.startsWith("data") && path.split(".").length >= 3;
    }
    public static isBindingPath(path: string){
        if(!path){
            return false;
        }
        return path.startsWith("binding");
    }
    public static isScreenParameterPath(path: string){
        if(!path){
            return false;
        }
        return path.startsWith("screenParameters");
    }
    public static isCalcPath(path: string){
        if(!path){
            return false;
        }
        return path.startsWith("calc<") && path.endsWith(">");
    }
}