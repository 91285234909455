import { Injectable } from '@angular/core';
import { ThingService } from '../thing/thing.service';
import { FTFlowGroup } from '../../models/data/FTFlowGroup';
import { FTFlow } from '../../models/data/FTFlow';
import { FTFlowStatus } from '../../models/data/FTFlowStatus';
import { Lookup } from '../../models/data/Lookup';
import { LookupDetail } from '../../models/LookupDetail';
import { Utilities } from '../utilities/utilities';


@Injectable()
export class FlowService {

  constructor(private thingService: ThingService) { }

  public static flowGroups: FTFlowGroup[] = null;
  public static flows: FTFlow[] = null;
  public static flowStatus: FTFlowStatus[] = null;

  public static lookups: Lookup[] = null;
  public static lookupDetails: LookupDetail[] = null;

  public static setup(flowGroups: FTFlowGroup[], flows: FTFlow[], flowStatus: FTFlowStatus[], lookups: Lookup[], lookupDetails: LookupDetail[]) {

    this.flowGroups = flowGroups;
    this.flows = flows;
    this.flowStatus = flowStatus;
    this.lookups = lookups;
    this.lookupDetails = lookupDetails;
  }

  public static getFlowStatus(flowStatusId: number, referenceTo?: string): FTFlowStatus {

    if (!this.flows) {
      return;
    }

    if (!referenceTo) {
      return this.getFlowStatusFromID(flowStatusId);
    }

    const flowRef = Utilities.getLastEntry(referenceTo);
    if (flowRef) {
      const flow = this.flows.find(f => f.name.toLowerCase() === flowRef.toLowerCase());
      if (flow && this.flowStatus !== null && flowStatusId > 0) {
        // flowStatusId sometimes gets passed as string
        var status = this.flowStatus.find(status => status.ftFlowID === flow.ftFlowID && status.ftFlowStatusID == flowStatusId);
        if(status){
          return status
        }
      }
    }
    return this.getFlowStatusFromID(flowStatusId);
  }

  private static getFlowStatusFromID(flowStatusId: number): FTFlowStatus{
    if (this.flowStatus !== null && flowStatusId > 0) {
      // flowStatusId sometimes gets passed as string
      return this.flowStatus.find(status => status.ftFlowStatusID == flowStatusId);
    }
    return null;
  }

  /**
   * Gets the list of flow statuses to show in a context menu for example
   * @param flowId
   * @param flowStatusId
   * @param currentFlowOnly if true, will only return statuses in the current flow. Otherwise it will return all in the flowGroup
   * @param hideInvisible if true, only returns statuses which are visibleByDefault or selected
   */
  public static getFlowStatuses(flowId: number, flowStatusId: number, currentFlowOnly: boolean = false, hideInvisible: boolean = true): FTFlowStatus[] {

    let retVal: FTFlowStatus[] = [];
    if (currentFlowOnly) {
      const flow: FTFlow = this.flows.find(f => f.ftFlowID === flowId);
      if (flow) {
        if (!flow.items) {
          flow.items = this.flowStatus.filter(s => s.ftFlowID === flowId);
        }
        retVal = flow.items;
      }
    } else {
      const flow: FTFlow = this.flows.find(f => f.ftFlowID === flowId);
      if (!flow) {
        console.log(`Flows do not contain flowId ${flowId}`);
        return null;
      }
      const flowGroupId = flow.ftFlowGroupID;
      const flowIds = [];
      this.flows.filter(f => f.ftFlowGroupID === flowGroupId).forEach(element => {
        flowIds.push(element.ftFlowID);
      });

      retVal = this.flowStatus.filter(f => flowIds.includes(f.ftFlowID));
      if (hideInvisible) {
        retVal = retVal.filter(f => f.isVisibleByDefault || f.ftFlowStatusID === flowStatusId);
      }

    }
    retVal = retVal.filter(f => f.enabled);
    return retVal.sort((a, b) => a.seqNo > b.seqNo ? 1 : -1);
  }

  public static getFlowStatusesByFlowName(name: string): FTFlowStatus[] {
    const flow: FTFlow = this.flows.find(f => f.name === name);
    if (flow) {

      if (!flow.items) {
        flow.items = this.flowStatus.filter(s => s.ftFlowID === flow.ftFlowID);
      }

      return flow.items.sort((a, b) => a.seqNo > b.seqNo ? 1 : -1);
    }
    return [];
  }

  public static getLookupDetails(name: string): LookupDetail[] {

    if (!this.lookups) { return; }
    if (!name || (typeof (name) !== 'string')) { return; }

    name = Utilities.getLastEntry(name);

    const lookup: Lookup = this.lookups.find(m => m.name === name);
    if (lookup) {
      if (!lookup.items) {
        lookup.items = this.lookupDetails.filter(m => m.ftUserLookupID === lookup.ftUserLookupID);
      }
      return lookup.items;
    }

    return [];
  }

  public static getLookupDetail(name: string, value: number): LookupDetail {

    if (this.getLookupDetails(name)) {
      return this.getLookupDetails(name).find(m => (m.value as number) == value);
    }
  }
}
