import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { } from '@angular/core';

@Component({
  selector: 'q-stock-indicator',
  templateUrl: './q-stock-indicator.component.html',
  styleUrls: ['./q-stock-indicator.component.css'],
  host: {
    '(window:resize)': 'onResized($event)'
  },

})

export class QStockIndicatorComponent implements OnInit, AfterViewInit {

  @Input() loading: boolean;

  //Indicate whether the allocatedStock is updated on realtime during drag or when the mouse is released
  @Input() public isUpdateStockDuringDrag: boolean = false;

  //Indicate whether the component is read-only
  @Input() public isReadOnly: boolean = false;
  // @Input() minStock: number;
  // @Input() minAvailableStock: number;
  // @Input() maxAvailableStock: number;

  //Options
  //Colours when within and exceeding minAvailableStock
  @Input() public safeZoneTrackColour: any = null; //default style picked from style setting in ngOnInit
  @Input() public unsafeZoneTrackColour: any = '#ff3056';
  @Input() public safeSelectorTrackColour: any = null //default style picked from style setting in ngOnInit
  @Input() public unsafeSelectorTrackColour: any = '#ff3056';

  _allocatedStock: number = 0;

  public selectorPosition: number = 0;

  //Indicates whether the allocated stock exceeds min available
  public isAboveAvailable: boolean = false;
  //Indicates whether Track B should be displayed
  public isShowMaxAvailable: boolean = true;

  //#region private variables 
  public currentSelectorStock: number = 0;
  private selectorLeftPos: number = 0;
  private dragStartXPos: number = 0;
  private isMouseDown: boolean = false;

  private trackAWidth: number = 0;
  private trackBWidth: number = 0;
  private totalTrackWidth: number = 0;
  //#endregion private variables 

  //#region handles for template elements
  @ViewChild('gaugeLineStockTrackA') trackA: ElementRef;
  @ViewChild('gaugeLineStockTrackB') trackB: ElementRef;
  @ViewChild('gaugeLineStockSelector') selector: ElementRef;
  //#endregion handles for template elements

  @Output() onUpdate = new EventEmitter<number>();
  _minStock: any = 0;
  _minAvailableStock: any = 0;
  _maxAvailableStock: number = 0;

  constructor() { }

  @HostListener('document:mouseup') onMouseUp() {

    if (this.isMouseDown) {

      if (this.isReadOnly)
        return;

      //If we have stopped the drag and realtime stock update is off, then update allocated stock
      if (this.isMouseDown && !this.isUpdateStockDuringDrag) {
        this.allocatedStock = this.currentSelectorStock;
      }

      this.isMouseDown = false;
    }
  }

  get allocatedStock(): number {
    return this._allocatedStock;
  }

  @Input() set allocatedStock(value: number) {

    if (value !== this._allocatedStock) {
      this.onUpdate.emit(Math.round(value));
    }

    this._allocatedStock = value;
    this.positionSelector();
  }


  // The starting point of Track A
  get minStock(): number {
    return this._minStock;
  }
  @Input() set minStock(value: number) {
    this._minStock = value;
    this.positionSelector();
  }

  //The end point of Track A
  get minAvailableStock(): number {
    return this._minAvailableStock;
  }
  @Input() set minAvailableStock(value: number) {
    this._minAvailableStock = value;
    this.positionSelector();
  }

  //The end point of Track B
  get maxAvailableStock(): number {
    return this._maxAvailableStock;
  }
  @Input() set maxAvailableStock(value: number) {
    this._maxAvailableStock = value;
    this.positionSelector();
  }

  ngOnInit() {
    //Initialise
    this.isMouseDown = false;
    this.isShowMaxAvailable = (this.maxAvailableStock > this.minAvailableStock);
    this.isAboveAvailable = (this.allocatedStock > this.minAvailableStock);
    this.currentSelectorStock = this.allocatedStock;

    //Get hold of default colours if not overidden via @Input
    if (this.safeZoneTrackColour == null && this.trackA) {
      this.safeZoneTrackColour = window.getComputedStyle(this.trackA.nativeElement, null).getPropertyValue('border-top-color');
    }

    if (this.safeZoneTrackColour == null && this.selector) {
      this.safeSelectorTrackColour = window.getComputedStyle(this.selector.nativeElement, null).getPropertyValue('border-top-color');
    }
  }

  //We need to adjust the position after the view is initialised
  ngAfterViewInit() {
    //Call within a timeout in order to avoid exception "Expression has changed after it was checked"
    setTimeout(() => {
      this.positionSelector();
    }, 0);
  }

  //We need to adjust the position each time the view is resized
  onResized(event: Event): void {
    this.positionSelector();
  }

  public onSelectorMouseDown(event: MouseEvent) {
    if (this.isReadOnly)
      return;
    this.isMouseDown = true;
    this.currentSelectorStock = this.allocatedStock;
    this.selectorLeftPos = parseInt(window.getComputedStyle(this.selector.nativeElement, null).getPropertyValue('margin-left'));
    this.dragStartXPos = event.clientX;
  }

  public onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown || this.isReadOnly)
      return;

    let distance: number = event.clientX - this.dragStartXPos;
    let leftPos: number = this.getLeftPosLimits(this.selectorLeftPos + distance);

    this.selectorPosition = leftPos;

    this.positionStock(leftPos);
  }

  //Ensure the position is within range
  private getLeftPosLimits(pos: number): number {
    return (pos > this.totalTrackWidth) ? this.totalTrackWidth : (pos < 0) ? 0 : pos;
  }

  private positionSelector() {
    //Get wdth of Track A and Track B
    if (this.loading)
      return;

    this.isShowMaxAvailable = (this.maxAvailableStock > this.minAvailableStock);
    this.isAboveAvailable = (this.allocatedStock > this.minAvailableStock);

    this.trackAWidth = this.trackA.nativeElement.offsetWidth;

    this.trackBWidth = this.isShowMaxAvailable ? this.trackB.nativeElement.offsetWidth : 0;
    this.totalTrackWidth = this.trackAWidth + this.trackBWidth;
    //Adjust for the bottom point of the arrow
    let selectorAdjustment: number = 0;//this.selector.nativeElement.offsetWidth /4;

    //If allocated stock is 0 then set position to 0;
    if (this.allocatedStock == this.minStock) {
      this.selectorPosition = 0;
      this.currentSelectorStock = 0;
      return;
    }
    //If allocated stock is >= to max then position it just outside;
    else if (this.allocatedStock >= this.maxAvailableStock) {
      this.selectorPosition = 0;

      this.selectorPosition = this.trackAWidth + this.trackBWidth + selectorAdjustment;
      this.currentSelectorStock = this.selectorPosition;
      return;
    }

    let trackAPos: number = 0;

    //Calculate Position
    if (this.allocatedStock <= this.minAvailableStock) {
      //divide Track A width by range of Track A and multiply by allocated stock
      trackAPos = (this.trackAWidth / (this.minAvailableStock - this.minStock)) * this.allocatedStock;
      this.selectorPosition = trackAPos - selectorAdjustment;
    }
    else if (this.isShowMaxAvailable) {
      //Width of Track A + (Divide Track B Length by range between Min avail & Max Avail) and multiply by the difference of Allocated and Min Avail
      trackAPos = this.trackAWidth + ((this.trackBWidth / (this.maxAvailableStock - this.minAvailableStock)) * (this.allocatedStock - this.minAvailableStock));
      this.selectorPosition = trackAPos;
    }

    this.isAboveAvailable = (this.allocatedStock > this.minAvailableStock);
  }
  private positionStock(selectorPos: number) {

    let stock: number = 0;
    let selectorAdjustment: number = this.selector.nativeElement.offsetWidth / 4;

    if (selectorPos <= 0) {
      this.allocatedStock = this.minStock;
      this.currentSelectorStock = this.allocatedStock;
      return;
    }
    if (selectorPos >= this.totalTrackWidth) {
      this.allocatedStock = this.maxAvailableStock;
      this.currentSelectorStock = this.allocatedStock;
      return;
    }

    //Calculate Position
    if (selectorPos <= this.trackAWidth) {
      //divide Track A Width by selector position and multipply by min available
      stock = ((selectorPos + selectorAdjustment) / this.trackAWidth) * (this.minAvailableStock - this.minStock);
    }
    else if (this.isShowMaxAvailable) {
      //Add to minAvailStock the (current pos on Track - length of Track A) divided by track B with and multiplied by diff between min and max range
      stock = this.minAvailableStock + ((selectorPos - this.trackAWidth) / this.trackBWidth) * (this.maxAvailableStock - this.minAvailableStock);
    }
    if (this.isUpdateStockDuringDrag) {
      this.allocatedStock = stock;
    }
    else {
      this.currentSelectorStock = stock;
    }

    this.isAboveAvailable = (stock > this.minAvailableStock);
  }
}
