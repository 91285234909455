
//https://www.highcharts.com/demo
export enum QChartType {
    Line = 'line',
    Bar = 'bar',
    StackedBar='stacked-bar',  //this value is set to bar when rendering and stacking set to 'normal'
    Column='column',
    StackedColumn='stacked-column', //this value is set to column when rendering and stacking set to 'normal'
    Spline='spline',
    Pie = 'pie',
    Area = 'area',
    Dougnut='doughnut', //this value is set to pie when rendering and plot option is adjusted
    SemiDougnut='semiDougnut', //this value is set to pie when rendering and plot option is adjusted
   };

//https://api.highcharts.com/highcharts/yAxis.type
export enum QChartAxisType {
    DateTime = 'datetime', //here numbers are given in milliseconds
    Linear = 'linear',
    Category='category'
};

export class QChartAxisFormat {
    AxisTitle:string;
    AxisType:QChartAxisType;
    LabelFormat:string = '%d %b %y';
    /*https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
    https://stackoverflow.com/questions/7101464/how-to-get-highcharts-dates-in-the-x-axis
    
    day month year: '%d %b %y'
    millisecond: '%H:%M:%S.%L',
    second: '%H:%M:%S',
    minute: '%H:%M',
    hour: '%H:%M',
    day: '%e. %b',
    week: '%e. %b',
    month: '%b \'%y',
    year: '%Y'

    For Pie/Doughnut charts, the Y format is used
    '{point.name}: {point.y}' //display name & value
    '{point.name}: {point.percentage:.2f} %' //auto calculates percentages and displays with 2 decimal points

    */
   };

//Not used for Pie, Doughnut or Semi Doughnut
export class QChartDataSeries {
    seriesName: string;
    dataArray:any[];
    color:any;
  enabled: boolean;
}

//Not Used for Pie, Doughnut and Semi Doughnut
export class QChartPieData {
    Name:string;
    Data:any;
    Color:any;
    IsSliced: boolean = false;
    IsSelected: boolean = false;
}

export class QChartHighlightSegment {
    BandTitle: string;
    Color:any = '#eaecef';
    FromDate:Date;      //Provide either from-to dates of from-to item no
    ToDate:Date;
    FromDataItemNo:number; //This is ineffective if plotSeries is defined as a datetime
    ToDataItemNo: number; //This is ineffective if plotSeries is defined as a datetime
}

export enum QChartInterval
{
    Seconds = 0, // = 1000,
    Minute = 1, // Seconds * 60,
    Hours = 2, // = Minute * 60,
    Day = 3, // 'day',
    Week = 4, // Day * 7,
    Month = 5, //'month',
    Year = 6, //'year
}

export class QChartData {
    ChartTitle:string;
    ChartType: QChartType = QChartType.Line;
    IsExportEnabled: boolean = false;
    StartDate:Date;
    Interval: QChartInterval
    Categories:any[]; //Can be left empty if Start Date is provided
    XAxisDisplay:QChartAxisFormat;
    YAxisDisplay:QChartAxisFormat;  //LabelFormat from Y Axis are used for pie types
    IsShowLegend: boolean = true;
    DataSeries:QChartDataSeries[]; //Used of Non Pie Types
    PieDataSeries:QChartPieData[];  //USed for PieTypes
    IsShowSeriesValueLabels: boolean = false;
    IsShowSeriesShadow: boolean = true;
    SeriesValueLabelsColor: any = '#5f5f60';
    SeriesValueBorderColor: any = '#lightgrey'
    HighlightSegment: QChartHighlightSegment
    public IsPieType() :boolean {
        return (this.ChartType == QChartType.Pie || this.ChartType == QChartType.Dougnut || this.ChartType == QChartType.SemiDougnut);
    }
};
    



