import { Component, OnInit, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utilities } from '../../../../services/utilities/utilities';

const noop = () => {};

@Component({
  selector: 'app-integer-model-input',
  templateUrl: './integer-model-input.component.html',
  styleUrls: ['./integer-model-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntegerModelInputComponent),
      multi: true
    }
  ]
})
export class IntegerModelInputComponent implements ControlValueAccessor {

  @Input() min: number;
  @Input() disabled: boolean;

  upSet: boolean = false;
  downSet: boolean = false;

  upHover: boolean = false;
  downHover: boolean = false;
  inputHover: boolean = false;

  upFocus: boolean = false;
  downFocus: boolean = false;
  inputFocus: boolean = false;
  inputUpdated: boolean = false;

  upInterval: any;
  downInterval: any;

  //The internal data model
  private _value: string;

  //Placeholders for the callbacks
  private _onTouchedCallback: () => void = noop;

  private _onChangeCallback: (_:any) => void = noop;

  //get accessor
  get value(): any { return this._value; };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChangeCallback(v);
    }
  }

  //Set touched on blur
  onTouched(){
    this._onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this._value = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this._onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this._onTouchedCallback = fn;
  }

  private moveUp() {

    let numericValue = parseFloat(this._value);
    this.value = (++numericValue).toFixed(2);
  
  }

  private moveDown() {

    let numericValue = parseFloat(this._value);
    numericValue--;

    if (this.min !== undefined && numericValue < this.min) {
      numericValue = this.min;
    }

    this.value = numericValue.toFixed(2);
 
  }

  onIncrement() {

    if (!this.upSet) {
      this.moveUp();
    }

    this.upSet = false;

  }

  onDecrement() {

    if (!this.downSet) {
      this.moveDown();
    }

    this.downSet = false;

  }

  get hasFocus(): boolean {
    return (this.upFocus || this.downFocus || this.inputFocus || this.upHover || this.downHover || this.inputHover);
  }

  get hidden(): boolean {
    return !this.hasFocus;
  }

  onFocusIn() {
    this.inputFocus = true;
  }

  onFocusOut(value: number) {

    if (value === -1) {
      this.downFocus = false;
    }
    else if (value === 0) {
      this.inputFocus = false;
    }
    else {
      this.upFocus = false;
    }

  }

  onUpMouseDown() {
    this.upInterval = setInterval(() => {
      this.moveUp();
      this.upSet = true;
    }, 150);
  }

  onUpMouseUp() {
    if (this.upInterval) {
      clearInterval(this.upInterval);
      this.upInterval = null;
    }
  }

  onDownMouseDown() {
    this.downInterval = setInterval(() => {
      this.moveDown();
      this.downSet = true;
    }, 150);
  }

  onDownMouseUp() {
    if (this.downInterval) {
      clearInterval(this.downInterval);
      this.downInterval = null;
    }
  }

}
