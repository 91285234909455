import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Action } from 'src/app/models/actions/Action';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { DataDetail } from 'src/app/models/DataDetail';
import { UIDataType } from 'src/app/models/Enums';
import { LoadDataEvent } from 'src/app/models/events/LoadDataEvent';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'app-calendar-test',
  templateUrl: './calendar-test.component.html',
  styleUrls: ['./calendar-test.component.scss']
})
export class CalendarTestComponent implements OnInit {
  public uriCollections?: UriCollection[] = [];
  dataDetail: DataDetail;
  dataItems: any[];
  filterUri: ActionURI;
  customFilter = '';
  searchValue: string;
  pageSize: number = 25;
  metadata: FTThingDetail[];
  pageIndex = 0;

  loadingIndicator = true;

  @Input() action: Action;
  @Input() row: object[];

  constructor(public toastr: ToastrService, private thingService: ThingService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadUriData(false);
  }

  loadUriData(includeScreenDetail: boolean) {

    let uris: ActionURI;

    const action: Action = JSON.parse(JSON.stringify(this.action));

    for (const uri of action.actionArgument.uris) {

      if (uri.dataUri) {
        uri.dataUri = Utilities.parseArgumentsFromData(uri.dataUri, uri.dataUriArgs, this.row);
      }
    }

    if (action.actionArgument.uris.length === 1) {
      uris = action.actionArgument.uris[0];
    }

    if (uris) {

      this.filterUri = uris;

      if (uris.dataUri) {

        if (includeScreenDetail) {
          // uris.dataUri
          this.thingService.getDataScreenDetail(uris.metaUri, null, this.action.actionArgument.screenUri,
            0, this.pageSize, null, null, this.customFilter, this.searchValue)
            .subscribe(result => {
              this.getDataDetailWithHeaderSuccessful(uris.name, false, result[1], result[0]);
              this.onLoadData({ pageIndex: this.pageIndex, pageSize: this.pageSize });
            }, error => this.onGetDetailFailed(error));
        } else {
          this.thingService.getData(uris.metaUri, null, 0, 25, null, null, this.customFilter, this.searchValue)
            .subscribe(result => {
              this.getDataDetailWithHeaderSuccessful(uris.name, false, result[1], result[0]);
              this.onLoadData({ pageIndex: this.pageIndex, pageSize: this.pageSize });
            }, error => this.onGetDetailFailed(error));
        }
      } else {

        this.thingService.getFTThingDetail(uris.metaUri)
          .subscribe(result => {
            this.metadata = result;
            // console.log('got metadata - set loading indicator false');
            // this.loadingIndicator = false;
          });
      }
    } else {

      Utilities.log(JSON.stringify(this.action));
      Utilities.log('uris not present');
    }
  }

  public onLoadData(event: LoadDataEvent) {

    this.loadingIndicator = true;

    let filter = this.customFilter;
    let filter1;
    let filter2;
    let filterValues;
    let tags: string[] = [];

    // if (this.filterMenuItem) {

    //   for (const c of this.userFilterFields) {
    //     if ((c.filterLookupValues && c.filterLookupValues.value != null && c.filterLookupValues.value.toString() !== '')
    //       || c.filter1 != null) {

    //       const md = this.metadata.find(d => d.javaScriptName === c.columnId);
    //       if (!md) {
    //         continue;
    //       }
    //       const dataType = DataUtils.dataTypeToUIDataType(md.dataType, md.referenceTo);

    //       filterValues = c.filterLookupValues ? c.filterLookupValues.value : null;
    //       filter1 = c.filter1;
    //       filter2 = c.filter2;

    //       if (filter && filter.length > 0 && dataType !== UIDataType.TagList) {
    //         filter += ' and ';
    //       }

    //       switch (dataType) {
    //         case UIDataType.DateTime:
    //           filter += `${c.columnId} ge '${Utilities.jsonDate(new Date(filter1.toString()))}'`;

    //           if (filter2) {
    //             filter += ` and ${c.columnId} le '${Utilities.jsonDate(new Date(filter2.toString()))}'`;
    //           }
    //           break;

    //         case UIDataType.Number:
    //           filter += `${c.columnId} ge ${filter1.toString()}`;

    //           if (filter2) {
    //             filter += ` and ${c.columnId} le ${filter2.toString()}`;
    //           }
    //           break;
    //         case UIDataType.TagList:
    //           tags = filterValues;
    //           break;

    //         case UIDataType.Bool:
    //           filter += `${c.columnId} eq ${filter1.toString()}`;
    //           break;

    //         case UIDataType.String:
    //         case UIDataType.FlowStatus:
    //         case UIDataType.Lookup:
    //           // or c.filterType > 0??
    //           if (dataType === UIDataType.FlowStatus) {
    //             const stringIds: string[] = [];

    //             for (const f of filterValues) {
    //               stringIds.push(f.ftFlowStatusID);
    //             }
    //             filterValues = stringIds;
    //           }
    //           if (dataType === UIDataType.Lookup) {
    //             const stringIds: string[] = [];

    //             for (const f of filterValues) {
    //               stringIds.push(f.value);
    //             }
    //             filterValues = stringIds;
    //           }

    //           if (filterValues && filterValues.length > 1) {
    //             filter += '(';
    //           }

    //           let filterIndex = 0;

    //           for (const f of filterValues) {

    //             if (filterIndex > 0) {
    //               filter += ' or ';
    //             }

    //             filter += `${c.columnId} eq '${filterValues[filterIndex]}'`;

    //             filterIndex++;
    //           }

    //           if (filterValues && filterValues.length > 1) {
    //             filter += ')';
    //           }
    //           break;

    //         default:
    //           filter += filter1;
    //           break;
    //       }
    //     }
    //   }
    // }

      const dataUri = this.filterUri.dataUri.toString();

      this.thingService.getDataDetailWithHeader(this.filterUri.dataUri.toString(), this.pageIndex, 100, null,
        null, filter, this.searchValue, null, null, tags, event.customParams)
        .subscribe(result => {
          this.getDataDetailWithHeaderSuccessful(this.filterUri.name, false, result);
        }, error => this.onGetDetailFailed(error));
  }

  private getDataDetailWithHeaderSuccessful(name: string, paging: boolean, dataDetail: DataDetail, columns?: FTThingDetail[]) {

    let uriCollection;

    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail ? dataDetail.dataItems : null };
      this.uriCollections.push(uriCollection);
    }

    if (columns) {
      uriCollection.dataMetadata = columns;
    }

    if (!!dataDetail)
    {

      this.dataDetail = dataDetail;
      uriCollection.dataValues = this.dataDetail.dataItems;
      this.dataItems = this.dataDetail.dataItems;
      console.log(this.dataItems);
    }

    this.cdr.detectChanges();
  }

  private onGetDetailFailed(error: any) {
    setTimeout(() =>
      this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
        null, { closeButton: true, tapToDismiss: true }));
  }

}
