import { Pipe, PipeTransform } from '@angular/core';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { Utilities } from 'src/app/services/utilities/utilities';

@Pipe({
  name: 'elementLabel'
})
export class ElementLabelPipe implements PipeTransform {

  transform(elementName: string, cardTemplate: any, metadata: FTThingDetail[]): any {
    if (metadata && this.getElementPropertyName(elementName, cardTemplate)) {
      const md = metadata.find(m => m.javaScriptName === this.getElementPropertyName(elementName, cardTemplate));
      if (md) {
        return md.displayName;
      }
    }
    return '';
  }

  private getElementPropertyName(elementName: string, cardTemplate: any): string {
    if (cardTemplate[elementName] && cardTemplate[elementName].text) {
      return Utilities.getLastEntry(cardTemplate[elementName].text.argumentIds[0]);
    } else if (cardTemplate[elementName] && cardTemplate[elementName].icon) {
      return Utilities.getLastEntry(cardTemplate[elementName].icon.iconArgument);
    }
    return '';
  }

}
