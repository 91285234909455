import { Component, OnInit, forwardRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { el } from 'date-fns/locale';
import { Utilities } from 'src/app/services/utilities/utilities';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => LookupDropdownComponent),
  multi: true
};

@Component({
  selector: 'app-lookup-dropdown',
  templateUrl: './lookup-dropdown.component.html',
  styleUrls: ['./lookup-dropdown.component.scss', './../dropdown-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class LookupDropdownComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  static staticBindLabel: string;

  _selectedItem: number;
  selectString: string;
  _displayStringArgs: string[];
  propertyDisplayArgs: string[];
  _bindValue: string;
  _selectedValue: number;
  isDisabled: boolean;

  set value(val: any) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){

    this._selectedValue = parseInt(val as any, 10);
    this.onChange(val);
    this.onTouched(val);
  }
  get value(): any {
    return this._selectedValue;
  }

  @Input() title: string;
  @Input() name: string;
  @Input() readonly: boolean;
  @Input() dataItems: [];
  @Input()
  set bindValue(v: string) {
    this._bindValue = Utilities.getLastEntry(v);
  }
  get bindValue() { return this._bindValue; }
  @Input() labelName: string;
  @Input() bindLabel: string;
  @Input() required = false;
  @Input() placeholder: string;
  @Input() errorText: string;

  totalRecordCount: number;
  returnRecordCount: number;

  @ViewChild('dropdown') public dropdown: NgSelectComponent;
  constructor() { }

  ngOnInit() {
    LookupDropdownComponent.staticBindLabel = this.bindLabel;
  }

  ngAfterViewInit(): void {
  }

  writeValue(value: any): void {
    if (value !== this.value) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  onChange: any = () => { };
  onTouched: any = () => { };

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item[LookupDropdownComponent.staticBindLabel].toLowerCase().includes(term);
  }

  getText(dropdownItem:any, bindLabel: any){
    if(typeof dropdownItem == "string"){
      return dropdownItem;
    }
    else {
      return dropdownItem[bindLabel];
    }
  }
}
