import { Utilities } from "src/app/services/utilities/utilities";
import { Action } from "./Action";

export class GenerateDocumentBody{
    parentObjectIID: number;
    parentObjectType: string;
    dataContext: any;
    documentReturnOptions: number;
    previewOnly: boolean;
    allowPDF: boolean;
    allowHTML: boolean;
}

export class GenerateDocumentAction{
    parentObjectType: string; //"Data.Invoice"
    parentObjectIIDPath: string; //"data.invoice.invoiceID"
    documentReturnOptions: number; //0 or 1
    previewOnly: boolean;
    ftDocumentTemplateID: number;
    title: string;
    onGenerateAction: Action[];
    allowPDF: boolean = true;
    allowHTML: boolean = true;
}

export abstract class GenerateDocumentHelper{
    static getGenerateDocumentBody(action: GenerateDocumentAction, dataObject: any){
        var body = new GenerateDocumentBody();
        body.parentObjectIID = Utilities.getData(action.parentObjectIIDPath, dataObject);
        body.parentObjectType = action.parentObjectType;
        body.dataContext = dataObject;
        body.dataContext["FTDocumentTemplateID"] = action.ftDocumentTemplateID;
        body.documentReturnOptions = action.documentReturnOptions;
        body.previewOnly = action.previewOnly;
    
        if(action.allowHTML === true || action.allowHTML === false)
            body.allowHTML = action.allowHTML;
        else
            body.allowHTML = true;
        
        if(action.allowPDF === true || action.allowPDF === false)
            body.allowPDF = action.allowPDF;
        else
            body.allowPDF = true;
            
        return body;
    }
    static downloadFile(data: any, type: string, filename: string): boolean{
        let blob = new Blob([data], { type: type});
        let url = window.URL.createObjectURL(blob);

        var downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.click();
        return true;
        /*
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            return false;
        }
        return true;
        */
    }

    static openHTML(content: string){
        var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width="+(2*screen.width/3)+",height="+(2*screen.height/3)+",top="+(screen.height/6)+",left="+(screen.width/6));
        win.document.body.innerHTML = content;
    }

}