import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { DataType, FilterType, ViewType } from 'src/app/models/Enums';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { LookupDetail } from 'src/app/models/LookupDetail';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { TextArgument } from 'src/app/models/TextModel';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { FlowService } from 'src/app/services/flow/flow.service';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderFormComponent implements OnInit {

  @Input() detailSection: ScreenDetail;
  @Input() uriCollections: UriCollection[];
  @Input() screenParameters: any;
  @Input() index = 0;
  @Input() templateDataKey: string;
  @Input() newObjectDefaults: any;

  @Output() updateCompleted = new EventEmitter<UpdateCompletedEvent>();

  dataObj: any;

  ViewType = ViewType;
  DataType = DataType;
  FilterType = FilterType;
  metadata: FTThingDetail[];

  constructor(private toastr: ToastrService, private thingService: ThingService, private dialog: MatDialog) { }

  ngOnInit() {

    this.detailSection.detailSections.forEach(ds => {
      ds.details = ds.details.sort((d1, d2) => d1.sequence - d2.sequence);
      const uriCollection = this.uriCollections.find(u => u.name === this.templateDataKey);
      const dataObjects = uriCollection.dataValues;

      this.metadata = uriCollection.dataMetadata;
      if (dataObjects.length > this.index) {
        this.dataObj = dataObjects[this.index];
      } else {
        let order = {};
        if (this.newObjectDefaults && this.newObjectDefaults[this.templateDataKey]) {
          order = Utilities.createItemFromObjectDefaults(this.newObjectDefaults[this.templateDataKey], order, order, this.uriCollections, this.screenParameters);
        }
        dataObjects.push(order);
        this.dataObj = dataObjects[this.index];
      }

      ds.details.forEach(field => {

        if (field.defaultValue) {
          const ob = this.getDataObject(field.dataPath);

          if (field.defaultValue.startsWith('data.')) {

            ob[Utilities.getLastEntry(field.dataPath)] = Utilities.getDataFromUriCollection(field.defaultValue, this.uriCollections);
            // ob[Utilities.getLastEntry(field.dataPath)] = Utilities.parseArgumentsFromUriCollection(null, [field.defaultValue], this.uriCollections, null, this.screenParameters);
          } else {
            ob[Utilities.getLastEntry(field.dataPath)] = field.defaultValue;
          }
        }
      });
    });
  }

  getDataProperty(propertyName: string) {
    return Utilities.getData(propertyName, this.dataObj);
  }

  getDataObject(propertyName) {
    return Utilities.getOrCreateDataObject(propertyName, this.dataObj);
  }

  getPropertyName(propertyName: string) {
    return Utilities.getLastEntry(propertyName);
  }

  getText(text: TextArgument) {
    return Utilities.parseArgumentsFromUriCollection(text.text, text.argumentIds, this.uriCollections);
  }

  getDataType(propertyPath: string) {
    const md = DataUtils.getMetadatumAndDataPath(propertyPath, this.metadata);
    return md[0].dataType;
  }

  getLookupValues(propertyPath: string): LookupDetail[] {

    const metadata = DataUtils.getMetadatumAndDataPath(propertyPath, this.metadata);
    if (metadata[0].referenceTo) {
      const lookupName = metadata[0].referenceTo.split('.')[1];
      return FlowService.getLookupDetails(lookupName);
    }
  }

  public getLookupDataUri(itemSource: string) {

    if (!itemSource) { return; }

    const sourceDataSp = itemSource.split('|');
    if (sourceDataSp && sourceDataSp.length > 0) {
      const sourceData = sourceDataSp[0].substring(sourceDataSp[0].lastIndexOf('.') + 1);
      if (!this.uriCollections) { return; }

      const uriCollection = this.uriCollections.find(u => u.name === sourceData);
      if (uriCollection) {
        const uris = this.uriCollections.find(u => u.name === sourceData).uris;
        if (uris) {
          return uris.dataUri;
        }
      } else {
        this.toastr.error(`Can't find uri collection for ${sourceData}`);
      }
      return null;
    }
  }

  public getLookupBindValueKey(itemSource: string) {

    if (!itemSource) { return; }

    const sourceDataSp = itemSource.split('|');
    if (sourceDataSp && sourceDataSp.length > 1) {
      return sourceDataSp[1].substring(sourceDataSp[1].lastIndexOf('.') + 1);
    }
  }


  public get isFormEnabled(): boolean {
    if (this.detailSection.isEnabledCondition) {
      const res = Utilities.evaluateCondition(this.detailSection.isEnabledCondition, this.uriCollections, null, null, null);
      return res;
    } else {
      return true;
    }
  }
}
