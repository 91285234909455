import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { StatusCardModel } from 'src/app/models/cardModels/StatusCardModel';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { UIDataType } from 'src/app/models/Enums';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { FlowService } from 'src/app/services/flow/flow.service';
import { Utilities } from 'src/app/services/utilities/utilities';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { MatDialog } from '@angular/material';
import { FilePagerDialogComponent } from '../../../dialogs/file-pager-dialog/file-pager-dialog.component';
import { ThingService } from 'src/app/services/thing/thing.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FtFile } from 'src/app/models/dataService/FtFile';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { CardItem } from 'src/app/models/cardModels/CardItem';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ItemClickEvent } from 'src/app/models/events/ItemClickEvent';
import { IconService } from 'src/app/services/icon/icon.service';

@Component({
  selector: 'app-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss', './../card-style.scss']
})
export class StatusCardComponent implements OnInit, OnDestroy {

  public static cardHeight = 140;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() cardTemplate: StatusCardModel;
  @Input() cardItem: CardItem;
  @Input() screenParameters: any;
  @Input() dataItem: any;
  @Input() groupName: any;
  @Input() isEditing = false;
  @Input() metadata: FTThingDetail[];
  @Output() onAction = new EventEmitter<any>();
  @Output() onContextMenuClick = new EventEmitter<any>();
  @Output() onIconMenuClick = new EventEmitter<any>();
  @Input() uriCollections?: UriCollection[] = [];
  @Input() menuItems: MenuItem[];
  @Input() index: number;
  showContextMenu: boolean = false;
  contextRowItem: any;
  @Output() onChildItemClick = new EventEmitter<ItemClickEvent>();

  hasContextMenu: boolean = false;

  public DataType = UIDataType;
  public StatusCardComponent = StatusCardComponent;
  lastEventDisplayed: any;
  iconArgument: string;

  constructor(public dialog: MatDialog, public thingService: ThingService, private iconHelper: IconHelper, private iconService: IconService) { }

  ngOnInit() {
    this.hasContextMenu = !!this.cardItem.contextMenu && this.cardItem.contextMenu.length > 0;
  }

  public getElementPropertyName(elementName: string): string {

    if (this.cardTemplate[elementName] && this.cardTemplate[elementName].text) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].text.argumentIds[0]);
    } else if (this.cardTemplate[elementName] && this.cardTemplate[elementName].icon) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
    }
    return '';
  }

  public onActionClick(event: any, dataItem: any, cardItem: string) {

    this.onAction.emit({ event: event, dataItem: dataItem, action: this.cardTemplate[cardItem].action });
  }

  public onContextMenu(event, dataItem) {
    if (this.lastEventDisplayed == dataItem)
    {
      this.showContextMenu = !this.showContextMenu;
    }
    else {
      this.showContextMenu = true;
    }
    this.lastEventDisplayed = dataItem;
    this.onContextMenuClick.emit({ event: event, dataItem: dataItem, index: this.index });
    this.contextRowItem = dataItem;
  }

  public onIconMenu(event, dataItem, elementName)
  {
    const status = this.cardTemplate[elementName];
    var action;

    if (!!status.action)
    {
      action = status.action[0];
    }

    dataItem.icon = this.iconArgument;
    dataItem.action = action;
    this.contextRowItem = dataItem;
    this.onIconMenuClick.emit({ event: event, dataItem: dataItem, action: action, index: this.index });
  }

  getIcon(row: any, elementName: string): any {

    const status = this.cardTemplate[elementName];

    const propertyName = Utilities.getLastEntry(status.icon.iconArgument);
    this.iconArgument = status.icon.iconArgument;

    const md = this.metadata.find(m => m.javaScriptName === propertyName);
    if (md) {
      const uiDataType = DataUtils.dataTypeToUIDataType(md.dataType, md.referenceTo);
      if (uiDataType === UIDataType.FlowStatus) {
        const item = FlowService.getFlowStatus(row[propertyName], md.referenceTo);
        if (item != null)
        {
          var iconHex = this.iconHelper.getFontAwesomeHex(item.iconID);
          var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
          return this.iconHelper.setIcon(iconMapping, iconHex);
        }
        return null;
      }
    }
    if (status && status.icon && row) {
      return Utilities.evaluate(status.icon.iconArgument, row);
    }

    return null;
  }

  getIconById(id: number) {
    var iconHex = this.iconHelper.getFontAwesomeHex(id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  getCardColor(): string {

    if (!this.cardTemplate.cardColour) {
      return '';
    }
    const property = Utilities.getLastEntry(this.cardTemplate.cardColour.icon.colour);
    if (this.dataItem[property]) {

      if (this.dataItem[property].length > 0) {
        return `${this.dataItem[property]}`;
      }
    }
    return '';
  }

  getHint(elementName: string): string {

    if (!this.cardTemplate[elementName]) { return ''; }

    const path = this.cardTemplate[elementName]['hint'];
    return this.getValue(path);
  }

  getValue(propertyPath: string) {
    if (propertyPath == null || propertyPath === '') {
      return '';
    }
    if (propertyPath.startsWith('data.')) {
      return Utilities.getData(propertyPath, this.dataItem);
    } else {
      return propertyPath;
    }
  }

  public isVisible(row: any, elementName: string): boolean {

    const element = this.cardTemplate[elementName];
    if (element && element.isVisibleCondition && row) {
      // console.log(element.isVisibleCondition);
      const res = Utilities.evaluateCondition(element.isVisibleCondition, null, this.screenParameters, null, row);
      // console.log(res);
      return res;
      // return Utilities.evaluate(element.isVisibleCondition, row, null, this.screenParameters);
    } else {
      return true;
    }
  }

  showPictureViewer(elementName: string) {

    const elementPropertyName = Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
    const fileId = this.dataItem[elementPropertyName];

    this.thingService.getFileItem(fileId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetFileSuccessful(results, elementPropertyName), error => console.log(error));
  }

  onGetFileSuccessful(file: FtFile, primaryFileIdPath: string) {
    this.dialog.open(FilePagerDialogComponent, {
      // width: '450px',
      data: {
        parentObjectIID: file.parentObjectIID,
        parentObjectType: file.parentObjectType,
        rowObject: this.dataItem,
        primaryFileIdPath: primaryFileIdPath
      }
    });
  }

  onContextExecute(row: any, menuItem: MenuItem, event?: any) {
    let item = row;

    // Fix for sub-items not calling (execute) and passing item in the event - pick from global variable
    if (!item) {
      item = this.contextRowItem; 
    }

    this.onChildItemClick.emit({ mouseEvent: event, action: menuItem.action, row: item, newItem: menuItem.item });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
