import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loading-image',
  templateUrl: './loading-image.component.html',
  styleUrls: ['./loading-image.component.scss']
})
export class LoadingImageComponent implements OnInit {

  imageLoader = true;

  @Input() width: number;
  @Input() height: number;
  @Input() aspectRatio = 1;
  @Input() imageSizeBucket: string;
  @Input() src: string;
  @Input() alt: string;
  @Input() objectFit: string;
  @Output() click = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

    if (this.width && this.height) { return; }

    if (this.imageSizeBucket) {
      switch (this.imageSizeBucket) {
        case 'tiny':
          this.width = 100;
          break;

        case 'small':
          this.width = 200;
          break;

        case 'medium':
          this.width = 800;
          break;
      }
      this.height = this.width / this.aspectRatio;
    }
  }

  onClick(event: any) {
    this.click.emit(event);
  }
}
