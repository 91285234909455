import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-secured-image',
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss']
})
export class SecuredImageComponent implements OnChanges {

  @Input() private src: string;
  private src$ = new BehaviorSubject(this.src);

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$ = this.src$
    .pipe(
      switchMap(url => this.loadImage(url))
    );

  // we need HttpClient to load the image
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {
  }

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  private loadImage(url: string): Observable<any> {
    return null;
    // return this.httpClient
    //   .get(url, { responseType: 'blob' })
    //   .pipe(map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)))
    //   );
  }

}
