import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { ContextMenuService, ContextMenuComponent } from 'ngx-contextmenu';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { DataDetail } from '../../../../models/DataDetail';
import { FormTemplateItem } from '../../../../models/screenModels/TemplateItem';
import { MenuItem } from '../../../../models/data/MenuItem';
import { ViewType } from '../../../../models/Enums';
import { PostResponse } from 'src/app/models/PostResponse';
import { ScreenColumn } from 'src/app/models/ScreenColumn';

interface ColumnData {
  columnItem: ScreenColumn,
  name: string,
  // metadata: FTThingDetail
}

@Component({
  selector: 'app-price-band',
  templateUrl: './price-band.component.html',
  styleUrls: ['./price-band.component.scss']
})
export class PriceBandComponent implements OnInit {

  loadingIndicator: boolean = true;
  
  @Input() screenParameters: any;
  @Input() defaultPriceSchemeId: any;  
  @Input() saving: boolean;
  @Input() priceSummaryTemplate: FormTemplateItem;
  @Input() productPriceSchemePriceBandTemplate: FormTemplateItem;
  @Input() priceSummaryDataDetail: DataDetail;
  @Input() priceBand: any;
  @Input() productPriceSchemePriceBandDataDetail: DataDetail;
  @Input() priceSchemeDataDetail: DataDetail;
  @Input() product: any;
  columnDataPriceSchemeAdd: ColumnData;

  @Input()
  set priceBandTemplate(value: FormTemplateItem) {

    this._priceBandTemplate = value;

    if (value) {

      if (this.priceBand) {

        for (let viewItem of this.priceBandTemplate.templateItem) {

          if (viewItem.viewType === ViewType.Image && viewItem.icon) {
            this.initials = this.priceBand[Utilities.getLastEntry(viewItem.icon.iconArgument)];
            this.color = `${this.priceBand[Utilities.getLastEntry(viewItem.icon.colour)]}`;
          }
          else if (viewItem.viewType === ViewType.Text && viewItem.text) {
            this.title = this.priceBand[Utilities.getLastEntry(viewItem.text.argumentIds[0])];
          }
        }
      }
    }
  }
  get priceBandTemplate(): FormTemplateItem {
    return this._priceBandTemplate;
  }

  _priceBandTemplate: FormTemplateItem;
  _schemePrices: any[] = null;

  title: string;
  initials: string;
  color: string;

  selectedSchemeId: number;

  @ViewChild(ContextMenuComponent) public bandMenu: ContextMenuComponent

  @Output() menuClick = new EventEmitter<any>();

  constructor(public toastr: ToastrService, private thingService: ThingService, private contextMenuService: ContextMenuService) { }

  ngOnInit() {

    this.columnDataPriceSchemeAdd = this.getColumnData(this.productPriceSchemePriceBandTemplate, 'priceScheme');
  }


  getColumnData(template: FormTemplateItem, columnTag: string): ColumnData {

    let item = template.templateItem.find(m => m.tag === columnTag);
    if (item) {
      // get main argument based on ViewType
      let name;
      switch (item.viewType) {
        case ViewType.IconPicker:
          name = Utilities.getLastEntry(item.iconArgument);
          break;
        case ViewType.Checkbox:
          name = Utilities.getLastEntry(item.isCheckedCondition);
          break;
        default:
          name = Utilities.getLastEntry(item.dataPath);
          break;
      }
      if (name == null)
        Utilities.log2('PriceSchemeUpdateDialog', 'null name for ' + columnTag, 'warn');

      return {
        columnItem: item,
        name: name,
        // metadata: this.columns.find(m => m.javaScriptName === name)
      }
    }
  }

  // get productPriceSchemes() : any[] {

  //   if (this.productPriceSchemePriceBandDataDetail && this.productPriceSchemePriceBandDataDetail.dataItems) {
  //     return this.productPriceSchemePriceBandDataDetail.dataItems.filter(m => m.priceBandID === this.priceBand.priceBandID);
  //   }
  // }


  get productPriceSchemeByPriceBands(): any[] {

    if (this.productPriceSchemePriceBandDataDetail && this.productPriceSchemePriceBandDataDetail.dataItems) {

      return this.productPriceSchemePriceBandDataDetail.dataItems.filter(m => m.priceBandID === this.priceBand.priceBandID);
    }
  }

  // getPrice(productPriceScheme: any): any {

  //   return this.priceSummaryDataDetail.dataItems.find(m => m.productPriceSchemePriceBandID === productPriceScheme.productPriceSchemePriceBandID && !m.priceSchemeDetailID);
  //   // Utilities.log2('PriceBand - getPrice (priceSummary)', JSON.stringify(ret));
  //   // return ret;

  // }

  getDetailPrices(productPriceScheme: any): any[] {

    if (this.priceSummaryDataDetail && this.priceSummaryDataDetail.dataItems) {
      return this.priceSummaryDataDetail.dataItems.filter(m => m.priceBandID === this.priceBand.priceBandID && m.productPriceSchemePriceBandID === productPriceScheme.productPriceSchemePriceBandID);
    }
  }

  getScheme(productPriceScheme: any) {

    return this.priceSchemeDataDetail.dataItems.find(m => m.priceSchemeID === productPriceScheme.priceSchemeID);

  }

  // Show context menu on left mouse click
  onContextMenu($event: MouseEvent, item: any) {

    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: this.bandMenu,
      event: $event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();

  }

  onContextExecute(row: any, menuItem: MenuItem) {

    this.menuClick.emit({ item: menuItem, row: row });
  }

  isVisible(menuItem: MenuItem): boolean {

    if (menuItem.visibleCondition === undefined) {
      return true;
    }

    return Utilities.evaluate(menuItem.visibleCondition, this.priceBand)
  }

  onMenuClick(event: any) {

    this.menuClick.emit(event);

  }

  onPriceSchemeChange(event) {

    Utilities.log2('PriceBand', 'selectedSchemeId: ' + this.selectedSchemeId);
    const productPriceSchemePriceBand: any = {
      priceSchemeID: this.selectedSchemeId,
      productID: this.product.productID,
      priceBandID: this.priceBand.priceBandID
      // startDate: TODO,
      // endDate: TODO,
    };

    // this.productPriceSchemePriceBandDataDetail.dataItems.push(productPriceSchemePriceBand);

    // Add the new productPriceSchemeTemplate to the database
    const editUri: string = Utilities.parseArgumentsFromData(this.productPriceSchemePriceBandTemplate.onEditAction[0].actionArgument.editUri,
      this.priceSummaryTemplate.onEditAction[0].actionArgument.editUriArgs,
      this.productPriceSchemePriceBandDataDetail.dataItems);

    // save to database
    this.thingService.postDataDetail(editUri, productPriceSchemePriceBand)
      .subscribe(result => this.onPostDataDetailSuccessful(result), error => this.onPostDataDetailFailed(error));
  }

  onPostDataDetailSuccessful(result: PostResponse[]): void {
    result.forEach(r => {
      this.productPriceSchemePriceBandDataDetail.dataItems.push(r.returnedObject);
    });
  }

  private onPostDataDetailFailed(error: any) {
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
    // this.updateCompleted.emit(false);
  }

  get debug(): string {
    return JSON.stringify(this.priceBand);
  }
}
