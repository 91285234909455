import { CardItemType } from '../Enums';
import { IconModel } from '../IconArgument';
import { IconStatusModel } from '../viewControls/IconModel';
import { ItemStatusModel } from '../viewControls/ItemStatusModel';
import { ItemTextModel } from '../viewControls/ItemTextModel';
import { LineSlider } from '../viewControls/LineSlider';
import { NumberPickerModel } from '../viewControls/NumberPickerModel';
import { ICardModel } from './ICardModel';

export class OrderBasketCardModel implements ICardModel {
    cardItemType: CardItemType;
    title: ItemTextModel;
    detail1: ItemTextModel;
    detail2: ItemTextModel;
    numberPicker1: NumberPickerModel;
    numberPicker2: NumberPickerModel;
    numberPicker3: NumberPickerModel;
    status1: ItemStatusModel;
    status2: ItemStatusModel;
    status3: IconStatusModel;
    status4: IconStatusModel;
    lineSlider: LineSlider;
    cardColour: IconModel;
}
