import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import { PricingComponent } from '../pricing/pricing.component';

import { Action } from '../../../../models/actions/Action';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';

export interface DialogData {
  action: Action;
  row: any;
}

@Component({
  selector: 'app-pricing-dialog',
  templateUrl: './pricing-dialog.component.html',
  styleUrls: ['./pricing-dialog.component.scss']
})
export class PricingDialogComponent implements OnInit {

  action: Action;
  row: any;
  title: string;

  updating: boolean = false;

  @ViewChild(PricingComponent) public pricing: PricingComponent

  constructor(public dialogRef: MatDialogRef<PricingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      dialogRef.disableClose = true;
    }

  ngOnInit() {
    this.action = this.data.action;
    this.row = this.data.row;    
  }

  onTitleUpdated(event: TitleUpdateEvent) {
    this.title = event.title;
  }

  public onSave() {

    this.pricing.Save();
    this.updating = true;

  }

  onUpdateCompleted(event) {

    this.updating = false;

    if (event) {
      this.dialogRef.close(true);
    }

  }

}
