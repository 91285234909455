import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import {
  WeekViewAllDayEvent,
  WeekViewTimeEvent,
  WeekViewHourColumn,
} from 'calendar-utils';
import { PlacementArray } from 'positioning';
import * as moment from 'moment';
import { el } from 'date-fns/locale';

@Component({
  selector: 'mwl-calendar-week-view-event',
  templateUrl: 'calendar-week-view.event.component.html',
})
export class CalendarWeekViewEventComponent {
  @Input() locale: string;

  @Input() weekEvent: WeekViewAllDayEvent | WeekViewTimeEvent;

  @Input() tooltipPlacement: PlacementArray;

  @Input() tooltipAppendToBody: boolean;

  @Input() tooltipDisabled: boolean;

  @Input() tooltipDelay: number | null;

  @Input() customTemplate: TemplateRef<any>;

  @Input() eventTitleTemplate: TemplateRef<any>;

  @Input() eventActionsTemplate: TemplateRef<any>;

  @Input() tooltipTemplate: TemplateRef<any>;

  @Input() column: WeekViewHourColumn;

  @Input() daysInWeek: number;

  @Output() eventClicked = new EventEmitter<{
    sourceEvent: MouseEvent | KeyboardEvent;
  }>();

  hexToRgbA(hex){
    if (!hex)
    {
      hex = '#0d6efd';
    }
       var c;
       if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
           c= hex.substring(1).split('');
           if(c.length== 3){
               c= [c[0], c[0], c[1], c[1], c[2], c[2]];
           }
           c= '0x'+c.join('');
           return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', 0.4)';
       }
       throw new Error('Bad Hex');
   }

   hexToRgbASolid(hex){
    if (!hex)
    {
      hex = '#0d6efd';
    }
       var c;
       if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
           c= hex.substring(1).split('');
           if(c.length== 3){
               c= [c[0], c[0], c[1], c[1], c[2], c[2]];
           }
           c= '0x'+c.join('');
           return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', 1)';
       }
       throw new Error('Bad Hex');
   }

   getTime(event: any, isStart)
   {
    var date;
    if(isStart){
      date = event.startDate;
    }
    else{
      date = event.endDate;
    }
    if(this.hasTime(event, isStart)){
      if(this.daysInWeek == 1){
        return moment(date).format('Do MMMM, HH:mm');
      }
      else{
        return moment(date).format('HH:mm');
      }
    }
    return '';
   }

   hasTime(event: any, isStart){
    if(!event.allDay)
      {
        return false;
      }

     if (!!event.startDate && !!event.endDate && moment(event.startDate).format('HH:mm') === '00:00' && moment(event.endDate).format('HH:mm') === '23:59')
     {
       return false;
     }

     return true;
   }
}
