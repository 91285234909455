
import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private inj: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authenticationService = this.inj.get(AuthenticationService);
    const token = authenticationService.getToken();
    const systemId = authenticationService.getCurrentSystemId();

    if (token && systemId) {
      request = request.clone({
        setHeaders: {
/*           getheader: 'yes', */
          token: token,
          systemid: systemId
        }
      });
    }
    return next.handle(request);
  }
}
