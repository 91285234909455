export class DetailChangeEvent{
    sourceTag: string;
    target: string;
    newValue: any;
    overwrite: boolean;
    row: number;

    constructor(source: string, target: string, newValue: any, overwrite: boolean, row?: number){
        this.sourceTag = source;
        this.target = target;
        this.newValue = newValue;
        this.overwrite = overwrite;
        this.row = row;
    }
}