import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { EventType } from '../../my-calendar/entities/eventType';
import { AddEventDialogComponent } from '../../../dialogs/add-event-dialog/add-event-dialog.component';
import { CalendarEvent } from '../calendar.module';
// import { WeekDay } from 'calendar-utils';

@Component({
  selector: 'mwl-calendar-week-view-hour-segment',
  template: `
    <ng-template
      #defaultTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
      let-daysInWeek="daysInWeek"
    >
      <div
        [attr.aria-hidden]="
          {}
            | calendarA11y
              : (daysInWeek === 1
                  ? 'hideDayHourSegment'
                  : 'hideWeekHourSegment')
        "
        class="cal-hour-segment"
        (click)="addEvent(segment)"
        [style.height.px]="segmentHeight"
        [style.background]="getBackgroound(segment)"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
      >
        <div class="cal-time" *ngIf="isTimeLabel">
          {{
            segment.displayDate
              | calendarDate
                : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour')
                : locale
          }}
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        segment: segment,
        locale: locale,
        segmentHeight: segmentHeight,
        isTimeLabel: isTimeLabel,
        daysInWeek: daysInWeek
      }"
    >
    </ng-template>
  `,
})
export class CalendarWeekViewHourSegmentComponent implements OnInit {
  @Input() days: any[];

  @Input() segment: any;

  @Input() segmentHeight: number;

  @Input() locale: string;

  @Input() isTimeLabel: boolean;

  @Input() daysInWeek: number;

  @Input() customTemplate: TemplateRef<any>;

  @Input() events: CalendarEvent[] = [];

  @Output() eventAdded: EventEmitter<any> = new EventEmitter<any>();

  eventForm: FormGroup;

  userId: number;

  refresh = new Subject<void>();

  eventTypes: Array<EventType> = new Array<EventType>();

  modalAddEventData: {
    day: any;
  }

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    // private authService: any,
    // private eventService: any,
    private route: ActivatedRoute) { }

    ngOnInit(): void {
      this.eventTypes = this.route.snapshot.data.eventTypes;

      this.eventForm = this.formBuilder.group({
        title: ['', Validators.required],
        eventType: ['', Validators.required],
        subEventType: ['', Validators.required],
        description: [''],
        start: ['', Validators.required],
        end: ['', Validators.required],
      });
    }

    getBackgroound(segment: any)
    {
      var weekendDays = this.days.filter(x => x.isWeekend);
      var weekendDayNums = weekendDays.map(x => x.day);

      if (weekendDayNums.includes(segment.date.getDay()))
      {
        return '#F1F3F4'
      }
      return;
    }

  get f() { return this.eventForm.controls; }

  addEvent(segment: any) {
    console.log("add event clicked");
    var day = segment.date;
    this.modalAddEventData = { day };
  }
}
