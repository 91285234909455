import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login-views/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { ManageProfileComponent } from './components/login-views/manage-profile/manage-profile.component';
import { IconResolver } from './resolvers/icon.resolver';

const appRoutes: Routes = [
    { path: '', component: MainComponent, canActivate: [AuthGuard], resolve: { icons: IconResolver } },
    { path: 'main', component: MainComponent, canActivate: [AuthGuard], resolve: { icons: IconResolver } },
    { path: 'main/:id', component: MainComponent, canActivate: [AuthGuard], resolve: { icons: IconResolver } },
    { path: 'login', component: LoginComponent, resolve: { icons: IconResolver } },
    { path: 'manageProfile', component: ManageProfileComponent, resolve: { icons: IconResolver } },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
