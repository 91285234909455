import { Pipe, PipeTransform } from '@angular/core';
import { FTThingDetail } from '../models/data/FTThingDetail';
import { UIDataType } from '../models/Enums';
import { DataUtils } from '../services/data-utils/data-utils';
import { Utilities } from '../services/utilities/utilities';

@Pipe({
  name: 'uiDataType'
})
export class UIDataTypePipe implements PipeTransform {

  transform(propertyName: string, metadata: FTThingDetail[]): any {
    propertyName = Utilities.getLastEntry(propertyName);
    if (metadata && propertyName) {
      const m = metadata.find(md => md.javaScriptName === propertyName);
      if (m) {
        const dt = DataUtils.dataTypeToUIDataType(m.dataType, m.referenceTo);
        return dt;
      }
    }
    return UIDataType.Unknown;
  }
}
