import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ViewTimelineComponent } from '../../view-timeline/view-timeline.component';
import { DialogData } from '../confirm-dialog/confirm-dialog.component';
import { UpdateViewDialogComponent } from '../update-view-dialog/update-view-dialog.component';

@Component({
  selector: 'app-timeline-dialog',
  templateUrl: './timeline-dialog.component.html',
  styleUrls: ['./timeline-dialog.component.scss']
})
export class TimelineDialogComponent implements OnInit {

  itemsReversed: boolean = false;

  @ViewChild(ViewTimelineComponent) child: ViewTimelineComponent;
  height: number;
  
  constructor(
    public dialogRef: MatDialogRef<UpdateViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  reverseItems()
  {
    this.child.reverseItems();
    this.itemsReversed = this.child.itemsReversed;
  }

  onClose()
  {
    this.dialogRef.close();
  }

}
