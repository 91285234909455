import { CdkVirtualScrollViewport, FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, PageEvent, MatDatepicker } from '@angular/material';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { ToastrService } from 'ngx-toastr';
import { GridTableDataSource } from 'src/app/data/grid-table-data-source';
import { GridColumnHelper } from 'src/app/helpers/grid-column-helper';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { ItemClickEvent } from 'src/app/models/events/ItemClickEvent';
import { LoadDataEvent } from 'src/app/models/events/LoadDataEvent';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { GridColumnItem } from 'src/app/models/viewGroupHelpers/GridColumnItem';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { isNumber } from 'util';
import { Action } from '../../../../models/actions/Action';
import { ActionURI } from '../../../../models/data/ActionURI';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { MenuItem } from '../../../../models/data/MenuItem';
import { DataDetail } from '../../../../models/DataDetail';
import { ActionIdentifier, ViewControlType, ViewType } from '../../../../models/Enums';
import { MenuNode } from '../../../../models/menu-tree/MenuNode';
import { PostResponse } from '../../../../models/PostResponse';
import { ScreenColumn } from '../../../../models/ScreenColumn';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { TextArgument } from '../../../../models/TextModel';
import { FlowService } from '../../../../services/flow/flow.service';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { GridSettingsDialogComponent } from '../../dialogs/grid-settings-dialog/grid-settings-dialog.component';
import * as moment from 'moment';
import { GraphDialogComponent } from '../../dialogs/graph-dialog/graph-dialog.component';
import { QChartData, QChartType, QChartInterval, QChartAxisType } from '../../charts/q-chart/q-chart-model';
import { ChartDataResult, ChartDateSeries } from 'src/app/models/chartModels/ChartDataResult';
import { StockGraphDialogComponent } from '../../dialogs/graph-dialog/stock-graph-dialog.component';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { IconService } from 'src/app/services/icon/icon.service';

const ROW_HEIGHT = 48;

/**
 * Virtual Scroll Strategy
 */
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(ROW_HEIGHT, 1000, 2000);
  }

  attach(viewport: CdkVirtualScrollViewport): void {
    this.onDataLengthChanged();
  }
}

@Component({
  selector: 'app-stock-grid-list',
  templateUrl: './stock-grid-list.component.html',
  styleUrls: ['./stock-grid-list.component.scss', '../grid-styles.scss'],
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }]
})
export class StockGridListComponent implements OnInit, AfterViewInit {

  viewControlType = ViewControlType; // So template can see ViewControlType
  viewType = ViewType; // So template can see ViewType

  pagerHeight = 50;
  updateIndicator = false;
  initLoad = false;

  toolbarItems: MenuItem[] = [];
  onClickAction: Action[] = null;
  idName: string; // Used to track name being used for primary key, when deleting an item

  customFilter = '';

  allChecked = 0;
  singleSelection: any;

  @Input() height: number;
  @Input() width: number;
  @Input() metadata: FTThingDetail[];
  @Input() screenDetail: ScreenDetail; // Referenced by filter dialog

  @Input() action: Action;
  @Input() screenParameters: any;
  @Input() screenUri: string;
  @Input() uri: ActionURI;
  @Input() bulkAction = false;
  @Input() showCheckboxForSingleSelect = false;
  @Input() pager = false;
  @Input() pageSize = 30; // this.pager ? 25 : 500;
  @Input() rowClick = true;
  @Input() uriCollections: UriCollection[] = [];
  @Input() readOnly = false;
  @Input() toolbarHeight = 56;
  _dataDetail: DataDetail;

  // Call parent page that has a collection of actions
  // @Output() onAction = new EventEmitter<Action>();
  // @Output() onAdd = new EventEmitter<AddUpdateItemModel>();
  @Output() onViewItem = new EventEmitter<ViewDetailEvent>();
  // @Output() onCloseItem = new EventEmitter<any>();
  // @Output() onDeleteCompleted = new EventEmitter<any>();
  @Output() onUpdateCompleted = new EventEmitter<UpdateCompletedEvent>();
  @Output() onLoadData = new EventEmitter<LoadDataEvent>();
  @Output() onItemClick = new EventEmitter<ItemClickEvent>();
  @Output() refreshLists = new EventEmitter();

  columnHeadings = [];
  columnFields: GridColumnItem[] = [];

  dataSource: GridTableDataSource<any>;
  pageEvent: PageEvent;
  itemTotal = 0;
  pageIndex = 0;
  searchValue: string;
  previousSearchValue: string;
  title: string;
  filterMenuItem: MenuItem; // If defined a filter is available
  placeholderHeight = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('fromDateControl') fromDateControl: MatDatepicker<moment.Moment>;
  @ViewChild('toDateControl') toDateControl: MatDatepicker<moment.Moment>;

  @ViewChildren(ContextMenuComponent) contextMenus: QueryList<ContextMenuComponent>;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  contextRow: any;
  contextItem: any;

  statusMenus: MenuNode[] = [];
  fromDate: moment.Moment;
  toDate: moment.Moment;
  loadFromAdjust = -7;
  loadToAdjust = 14;
  stockColumnHeadings: string[] = [];
  stockTemplate: ScreenColumn[];

  allColumnHeadings: string[] = [];

  loadCompleted = false;


  constructor(public toastr: ToastrService, private thingService: ThingService, private contextMenuService: ContextMenuService,
    public dialog: MatDialog, private cdr: ChangeDetectorRef, private iconHelper: IconHelper, private iconService: IconService) {

    console.log('stock grid list');
  }

  get tableHeight(): number {

    if (this.pager) {
      return this.height - this.pagerHeight;
    }

    return this.height - this.pagerHeight;
  }

  get dataDetail(): DataDetail {
    return this._dataDetail;
  }

  @Input()
  set dataDetail(value: DataDetail) {

    this._dataDetail = value;

    if (this.initLoad) {
      this.displayData();
    } else {
      this.setUpStockData();
    }

    if (this.dataDetail && this.dataDetail.dataItems && this.dataDetail.dataItems.length === this.dataDetail.totalRecordCount) {
      this.loadCompleted = true;
    } else {
      this.loadCompleted = false;
    }
  }

  public get infiniteScrollDistance(): number {
    if (this.dataDetail && !this.pager) {
      const vsd = ((this.dataDetail.totalRecordCount - this.dataDetail.returnRecordCount) / this.dataDetail.totalRecordCount) * 10;
      return vsd;
    } else {
      return 0;
    }
  }

  infiniteScrollDisabled() {
    if (this.pager) {
      return true;
    } else {
      return this.loadCompleted;
    }
  }

  ngOnInit() {

    if (this.screenParameters && this.screenParameters['fromDate']) {
      this.fromDate = moment(this.screenParameters['fromDate']);
    }
    if (this.screenParameters && this.screenParameters['toDate']) {
      this.toDate = moment(this.screenParameters['toDate']);
    }
    if (this.screenParameters && this.screenParameters['loadFromAdjust']) {
      this.loadFromAdjust = this.screenParameters['loadFromAdjust'];
    }
    if (this.screenParameters && this.screenParameters['loadToAdjust']) {
      this.loadToAdjust = this.screenParameters['loadToAdjust'];
    }

    this.dataSource = new GridTableDataSource([], this.viewport, 48, this.pageSize);
    this.dataSource.offsetChange.subscribe(offset => {
      this.placeholderHeight = offset;
    });

    if (this.pager && this.paginator) {
      this.paginator.pageSize = this.pageSize;
    }

    this.displayData();
    this.initLoad = true; // Initial load has been carried out.
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.loadData(0, this.pageSize));
  }

  placeholderWhen(index: number, _: any) {
    return index === 0;
  }


  private loadData(pageIndex: number, pageSize: number) {

    this.onLoadData.emit({
      pageIndex: pageIndex, pageSize: pageSize, sortColumn: this.sort.active,
      sortDirection: this.sort.direction, customParams: this.customParams
    });
  }

  onDatesChanged() {
    this.loadData(this.pageIndex, this.pageSize);
  }

  loadMoreLeft() {

    console.log('loadMoreLeft');
    this.fromDate = this.fromDate.add(this.loadFromAdjust, 'days');
    this.fromDateControl.select(this.fromDate);
    this.cdr.detectChanges();

    this.loadData(this.pageIndex, this.pageSize);
  }

  loadMoreRight() {

    console.log('loadMoreRight');
    this.toDate = this.toDate.add(this.loadToAdjust, 'days');
    this.toDateControl.select(this.toDate);
    this.cdr.detectChanges();

    this.loadData(this.pageIndex, this.pageSize);
  }

  private getPropertyFromRow(row: any, childObjectName: string, propertyName: string) {
    if (childObjectName) {
      const arrayPositionIndex = childObjectName.indexOf('[');
      if (arrayPositionIndex > -1) {
        const childObName = childObjectName.substr(0, arrayPositionIndex);
        const arrayIndex = parseInt(childObjectName.substring(arrayPositionIndex + 1, childObjectName.indexOf(']')), 10);
        return row[childObName] && row[childObName][arrayIndex] ? row[childObName][arrayIndex][propertyName] : null;
      } else {
        return row[childObjectName] ? row[childObjectName][propertyName] : null;
      }
    } else {
      return row[propertyName];
    }
  }

  private displayData() {

    if (!this.metadata || !this.dataDetail || !this.dataDetail.dataItems) {
      this.loadData(0, this.pageSize);
      return;
    }

    /*     this.action.rows = this.rows = this.dataDetail.dataItems; */
    this.columnHeadings = [];
    this.columnFields = [];

    if (this.dataDetail) {
      this.itemTotal = this.dataDetail.totalRecordCount;
    }

    this.onClickAction = this.screenDetail.grid.onClickAction;
    this.toolbarItems = this.screenDetail.toolbarItems;

    // TODO set showPaginator from screenDetail property

    if (this.dataDetail && this.dataDetail.dataItems) {
      for (const r of this.dataDetail.dataItems) {
        r.selected = false;
      }
    }

    const gridColHelper = new GridColumnHelper(this.toastr);
    gridColHelper.generateColumns(this.screenDetail.grid.columns, this.metadata, this.dataDetail.dataItems, this.bulkAction, this.showCheckboxForSingleSelect);

    this.columnHeadings = gridColHelper.columnHeadings;
    this.columnFields = gridColHelper.columnFields;

    this.setUpStockData();

    // this.dataSource = new MatTableDataSource(this.dataDetail.dataItems);

    this.dataSource.totalItemSize = this.dataDetail.totalRecordCount;
    this.dataSource.data = this.dataDetail.dataItems;

    this.buildContextMenus();
  }

  setUpStockData() {
    if (!this.dataDetail) { return; }

    if (!this.dataDetail.dataItems || this.dataDetail.dataItems.length === 0) { return; }

    const data = this.dataDetail.dataItems[0]['stockInfo'];

    this.stockColumnHeadings = Object.keys(data);
    this.stockTemplate = this.screenDetail.grid.columns.find(c => c.viewType === ViewType.List).template;

    this.allColumnHeadings = [];

    console.log(this.columnHeadings);

    this.allColumnHeadings.push(...this.columnHeadings);
    this.allColumnHeadings.push('border');
    this.allColumnHeadings.push('loadMoreLeft');
    this.allColumnHeadings.push(...this.stockColumnHeadings);
    this.allColumnHeadings.push('loadMoreRight');
  }

  getStockTemplateText(row: any) {

    if (!this.stockTemplate || this.stockTemplate.length === 0) { return; }

    const displayText = this.stockTemplate[0].text;
    return Utilities.parseArgumentsFromData(displayText.text, displayText.argumentIds, row);
  }

  public parseTextIfVisible(item: GridColumnItem, row: any) {

    if (item && item.isVisibleCondition && !Utilities.evaluate(item.isVisibleCondition, row)) {
      return '';
    }

    if (item.text) {

      if (!item.text.text) {
        item.text.text = '{0}';
      }
      return Utilities.parseArgumentsFromData(item.text.text, item.text.argumentIds, row);
    }

    return row[item.propertyName];
  }

  public parseText(text: TextArgument, row: any, name: string) {

    if (text) {

      if (!text.text) {
        text.text = '{0}';
      }
      return Utilities.parseArgumentsFromData(text.text, text.argumentIds, row);
    }

    return row[name];

  }

  public getValue(path: string, dataItem: any) {
    return Utilities.getData(path, dataItem);
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public hasLookupIcon(name: string, lookupId: number): boolean {

    const item = FlowService.getLookupDetail(name, lookupId);
    if (item && item.iconID) {
      return true;
    }

    return false;
  }

  public getFontAwesomeIcon(flowStatusId: number, referenceTo: string)
  {
    const item = FlowService.getFlowStatus(flowStatusId, referenceTo);
    var id = (item != null ? item.iconID : undefined);
    var iconHex = this.iconHelper.getFontAwesomeHex(id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  public getFontAwesomeIconById(id: any)
  {
    var iconHex = this.iconHelper.getFontAwesomeHex(+id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  public getLookupIconId(name: string, lookupId: number) {

    const item = FlowService.getLookupDetail(name, lookupId);
    return (item != null ? item.iconID : '');
  }

  public getLookupName(name: string, lookupId: number, row: any) {

    if (!lookupId) { return ''; }

    const item = FlowService.getLookupDetail(name, lookupId);
    return (item != null ? item.text : '');
  }

  public getLookupStyle(name: string, lookupId: number) {

    let styles = {};

    const item = FlowService.getLookupDetail(name, lookupId);

    if (item != null && item.colour) {
      styles = {
        '-webkit-filter': 'opacity(.5) drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)',
        'filter': 'opacity(.5)  drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)'
      };
    }

    return styles;
  }

  public getFlowStatusIconId(flowStatusId: number, referenceTo: string) {

    const item = FlowService.getFlowStatus(flowStatusId, referenceTo);
    return (item != null ? item.iconID : '');

  }

  public getFlowStatusName(flowStatusId: number, referenceTo: string) {

    const item = FlowService.getFlowStatus(flowStatusId, referenceTo);
    return (item != null ? item.text : '');

  }

  public getFlowStatusStyle(flowStatusId: number, referenceTo: string) {

    let styles = {};

    if (flowStatusId) {
      const item = FlowService.getFlowStatus(flowStatusId, referenceTo);

      if (item != null && item.colour) {
        styles = {
          '-webkit-filter': 'opacity(.5) drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)',
          'filter': 'opacity(.5)  drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)'
        };
      }
    }

    return styles;
  }

  public isVisible(item, row) {

    const column = this.screenDetail.grid.columns.find(c => c.tag === item.tag);

    if (column && column.isVisibleCondition) {
      return Utilities.evaluate(column.isVisibleCondition, row);
    }

    return true;
  }

  public isEnabled(item, row) {

    const column = this.screenDetail.grid.columns.find(c => c.tag === item.tag);

    if (column && column.isEnabledCondition) {
      return Utilities.evaluate(column.isEnabledCondition, row);
    }

    return true;
  }
  public itemClick(event: MouseEvent, item: GridColumnItem, row: any) {

    for (const r of this.dataDetail.dataItems) {
      r.selected = false;
    }

    // if (item.action[0].action === ActionIdentifier.DisplayDialog) {
    const completionEvent = new EventEmitter<boolean>();
    completionEvent.subscribe(complete => {
      this.refresh();
    });

    if (item.action && item.action.length > 0 &&
      item.action[0].action === ActionIdentifier.DisplayDialog && item.action[0].actionArgument.name === 'StockSummaryChart') {

      const action = Utilities.parseAction(item.action[0], row, this.screenParameters);

      // const ownedSeries: ChartDateSeries = {
      //   seriesId: 1,
      //   seriesName: 'Owned',
      //   dataArray: []
      // };
      // const physicalSeries: ChartDateSeries = {
      //   seriesId: 2,
      //   seriesName: 'Physical',
      //   dataArray: []
      // };
      // const reservedSeries: ChartDateSeries = {
      //   seriesId: 3,
      //   seriesName: 'Reserved',
      //   dataArray: []
      // };
      // const committedSeries: ChartDateSeries = {
      //   seriesId: 4,
      //   seriesName: 'Committed',
      //   dataArray: []
      // };
      // const availableSeries: ChartDateSeries = {
      //   seriesId: 5,
      //   seriesName: 'Available',
      //   dataArray: []
      // };
      // Object.keys(row.stockInfo).forEach(key => {
      //   ownedSeries.dataArray.push(row.stockInfo[key].owned);
      //   physicalSeries.dataArray.push(row.stockInfo[key].physical);
      //   reservedSeries.dataArray.push(row.stockInfo[key].reserved);
      //   committedSeries.dataArray.push(row.stockInfo[key].committed);
      //   availableSeries.dataArray.push(row.stockInfo[key].available);
      // });
      // const dataSeriesFromRow = [ownedSeries, physicalSeries, reservedSeries, committedSeries, availableSeries];

      // const data: ChartDataResult = {
      //   startDate: this.fromDate.toDate(),
      //   endDate: this.toDate.toDate(),
      //   interval: QChartInterval.Day,
      //   hirePeriod: null,
      //   dataSeries: dataSeriesFromRow
      // };

      const screenParameters = Utilities.setScreenParameters(action.actionArgument.screenParameters,
        this.screenDetail.requiredScreenParameters, row, this.uriCollections);
      screenParameters.fromDate = this.fromDate;
      screenParameters.toDate = this.toDate;

      const dialogRef = this.dialog.open(StockGraphDialogComponent, {
        minWidth: '800px',
        data: {
          screenParameters: screenParameters,
          // chartData: data,
          row: row,
          action: action
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    } else {
      this.onItemClick.emit({ action: item.action, row: row, completionEvent: completionEvent });
    }
  }

  private onPostDataDetailSectionSuccessful(action: Action, result: any, reloadGrid: boolean) {

    this.updateIndicator = false;
    let sectionId = -1;

    if (result && result.sectionID) {

      sectionId = result.sectionID;
    }

    this.onUpdateCompleted.emit({ sectionId: sectionId });
  }

  private onPostDataDetailSuccessful(action: Action, result: PostResponse[], reloadGrid: boolean, row: any) {

    this.onUpdateCompleted.emit({ sectionId: -1 });

    // TODO need to merge the result so we don't need to reload the whole grid
    //     console.log(row);
    // row = merge(row, result[0].returnedObject).subscribe;
    // console.log(row);

    if (reloadGrid) {
      if (this.refreshLists) {
        this.refreshLists.emit(true);
      } else {
        this.refresh();
      }
    }
  }

  private onPostDataDetailFailed(error: any) {
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public onView(event, row) {

    // Clear selection
    for (const r of this.dataDetail.dataItems) {
      r.selected = false;
    }

    // Select checkbox for selected row
    this.selectRow(true, row);

    const length: number = (this.dataDetail.dataItems.filter(r => r.selected).length);

    if (this.onClickAction) {

      this.onItemClick.emit({ action: this.onClickAction, row: row, completionEvent: null });

      // if (this.onClickAction[0].action === ActionIdentifier.LaunchFlyout ||
      //   this.onClickAction[0].action === ActionIdentifier.ReloadData ||
      //   this.onClickAction[0].action === ActionIdentifier.DisplayDialog) {
      //   this.onViewItem.emit({ row: row, action: this.onClickAction[0], length: length });
      // }
    }
  }

  /**
   * Called when the flow status icon is selected in the grid - opens the context menu with flow states
   * @param $event the click event
   * @param columnItem the columnFields (custom grid column data) selected
   * @param row the row selected
   */
  public onFlowStatus($event: KeyboardEvent, columnItem, row) {

    // no action means we can't open the list to change a status
    if (!columnItem.action) {
      return;
    }

    // TODO this should all be done on an item click event instead
    this.contextItem = columnItem;
    this.contextRow = row;

    const menuIndex: number = this.statusMenus.findIndex(m => m.id == row['activeFlowID']);

    // Must be a better way to get ViewChildren component by index. Tried toArray
    let index = 0;
    let selectedMenu: ContextMenuComponent;
    this.contextMenus.forEach(m => {

      if (index === menuIndex) {
        selectedMenu = m;
      }

      index++;

    });

    if (menuIndex > -1) {

      this.contextMenuService.show.next({
        anchorElement: $event.target,
        // Optional - if unspecified, all context menu components will open
        contextMenu: selectedMenu,
        event: <any>$event,
        item: columnItem,
      });
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  /**
 * Called when the flow state is changed in the drop down
 * @param newFlowStateId
 * @param isEnabled true if the flowState can be amended by the user
 */
  public onFlowStatusChange(newFlowStateId: number, isEnabled: boolean) {

    // contextItem is the 'columnFields' item selected

    const metadatum = DataUtils.getMetadatumAndDataPath(this.contextItem.icon, this.metadata)[0];
    const column = this.screenDetail.grid.columns[this.contextItem['index'] - 1];

    if (!isEnabled || !column.action) {
      return;
    }

    // get the flow status we're interested in
    const newFlowState = FlowService.getFlowStatus(newFlowStateId, metadatum.referenceTo);

    const completionEvent = new EventEmitter<ActionCompletedEvent>();
    completionEvent.subscribe(() => this.refresh());
    const action = new ActionBuilder(column.action, this.contextRow, newFlowState, this.uriCollections,
      this.screenParameters, completionEvent, this.onViewItem, this.thingService, this.toastr, null);
    action.PerformAction();

    // if (action.actionArgument.singleItemUri) {

    //   let dataUri = Utilities.parseArgumentsFromData(action.actionArgument.singleItemUri, action.actionArgument.singleItemUriArgs, this.contextRow);

    //   this.thingService.getDataDetail(dataUri)
    //     .subscribe(result => {

    //       if (result) {

    //         // Set Flow Status Value
    //         if (result['activeFlowState']) {
    //           result['activeFlowState'] = value;
    //         }

    //         this.thingService.postDataDetail(action.actionArgument.editUri, result)
    //           .subscribe(result => this.onPostDataDetailSuccessful(action, result, true, this.contextRow), error => this.onPostDataDetailFailed(error));

    //       }
    //     }
    //       , error => this.onGetDataDetailFailed(error));
    // }
  }

  public onSelectAll(event) {

    for (const r of this.dataDetail.dataItems) {
      r.selected = event.checked;
    }

    let row: any = null;
    const length: number = (this.dataDetail.dataItems.filter(r => r.selected).length);

    if (length === 1) {
      row = this.dataDetail.dataItems.find(r => r.selected);
    }

    if (length === 0) {
      this.onViewItem.emit(null);
    } else {
      if (this.onClickAction[0].action === ActionIdentifier.LaunchFlyout || this.onClickAction[0].action === ActionIdentifier.ReloadData) {
        this.onViewItem.emit({ row: row, action: this.onClickAction[0], length: length, toolbarItems: this.toolbarItems });
      }
    }

  }

  // Checkbox select defined by the screen definition
  public onDataSelect(event, columnId: string, row) {

    // this method is used in resource allocation

    // Get column via tag reference so we can find out actions *JB - this doesn't seem like the right way to do this at all...
    const isCheckedCondition: string = '.' + columnId;

    // this column is not the same as a ColumnField - no idea what it is though it seems to be more in relation to what is originally passed!!!
    const column: ScreenColumn = this.screenDetail.grid.columns.find(c => c.isCheckedCondition && c.isCheckedCondition.endsWith(isCheckedCondition));

    if (event.checked) {

      for (const action of column.onCheckedAction) {

        const updateRow = Utilities.generateRowFromDefaults(action.actionArgument.newObjectDefaults, row, this.action.actionArgument.screenParameters);

        this.thingService.postDataDetails(action.actionArgument.editUri, updateRow)
          .subscribe(result => this.onPostDataDetailSuccessful(action, result, true, row), error => this.onPostDataDetailFailed(error));
      }
    } else {

      for (const action of column.onUncheckedAction) {

        const updateRow = Utilities.generateRowFromDefaults(action.actionArgument.newObjectDefaults, row, this.action.actionArgument.screenParameters);

        this.thingService.postDataDetails(action.actionArgument.editUri, updateRow)
          .subscribe(result => this.onPostDataDetailSuccessful(action, result, true, row), error => this.onPostDataDetailFailed(error));
      }

    }
  }

  public onSelectSingle(event, row) {

    // Clear existing selection
    for (const r of this.dataDetail.dataItems) {
      r.selected = false;
    }

    this.onSelect(event, row);
  }

  public onSelect(event, row) {

    this.selectRow(event.checked, row);

    const length: number = (this.dataDetail.dataItems.filter(r => r.selected).length);

    let activeRow = row;

    if (length === 1) {
      activeRow = this.dataDetail.dataItems.find(r => r.selected);
    }

    if (length === 0) {
      this.onViewItem.emit(null);
    } else {
      if (this.onClickAction[0].action === ActionIdentifier.LaunchFlyout || this.onClickAction[0].action === ActionIdentifier.ReloadData) {
        this.onViewItem.emit({ row: activeRow, action: this.onClickAction[0], length: length, toolbarItems: this.toolbarItems });
      } else {
        this.toastr.success('Action' + this.onClickAction[0].action + ' not supported.');
      }
    }
  }


  private selectRow(value: boolean, row) {

    row.selected = value;

    const checked = this.dataDetail.dataItems.find(r => r.selected);
    const unchecked = this.dataDetail.dataItems.find(r => !r.selected);

    if (checked && unchecked) {
      this.allChecked = 2;
    } else {
      this.allChecked = (checked && !unchecked) ? 1 : 0;
    }

    if (!this.bulkAction) {
      if (row.selected) {
        this.singleSelection = row;
      } else {
        this.singleSelection = null;
      }
    }
  }

  refresh() {

    for (const r of this.dataDetail.dataItems) {
      r.selected = false;
    }

    this.allChecked = 0;

    // this.dataSource = new MatTableDataSource(this.dataDetail.dataItems);
    this.dataSource.totalItemSize = this.dataDetail.totalRecordCount;
    this.dataSource.data = this.dataDetail.dataItems;

    if (this.paginator && this.paginator.pageIndex) {
      this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
    } else {
      this.loadData(0, this.pageSize);
    }
  }

  public displaySettings() {

    const settingsDialog = this.dialog.open(GridSettingsDialogComponent, {
      width: '450px',
      data: { title: 'Settings', columnFields: this.columnFields, dataUri: this.uri.dataUri }
    });

    settingsDialog.afterClosed().subscribe(result => {

      if (result) {
        this.columnHeadings = [];
        this.allColumnHeadings = [];
        for (const col of result) {

          if (col.visible) {
            this.columnHeadings.push(col.propertyName);
          }
        }

        this.allColumnHeadings.push(...this.columnHeadings);
        if (this.stockColumnHeadings) {
          this.allColumnHeadings.push('border');
          this.allColumnHeadings.push('loadMoreLeft');
          this.allColumnHeadings.push(...this.stockColumnHeadings);
          this.allColumnHeadings.push('loadMoreRight');
        }
      }
    });
  }

  private onGetDataDetailFailed(error: any) {
    this.toastr.error(`Unable to load data from server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  getClasses(item: GridColumnItem) {

    let className = '';

    if (item.viewControlType === ViewControlType.NumberPicker) {

      className = Utilities.addClass(className, 'qm-numberpicker');
    } else if (item.viewControlType === ViewControlType.Icon || item.viewControlType === ViewControlType.Button ||
      item.viewControlType === ViewControlType.DataSelect || item.viewControlType === ViewControlType.Flow ||
      item.viewControlType === ViewControlType.Lookup) {

      className = Utilities.addClass(className, 'qm-compact');
    } else if (item.viewControlType === ViewControlType.SingleSelect ||
      item.viewControlType === ViewControlType.MultiSelectAll) {

      className = Utilities.addClass(className, 'qm-checkbox');
    } else if (item.viewControlType === ViewControlType.Date) {

      className = Utilities.addClass(className, 'qm-date');
    } else if (item.viewControlType === ViewControlType.Number) {

      className = Utilities.addClass(className, 'qm-number');
    } else {

      if (item.controlStyle === 'bold') {
        className = Utilities.addClass(className, 'qm-bold');
      } else {
        className = Utilities.addClass(className, 'qm-default');
      }
    }

    return className;
  }

  hasClass(item: any, value: string) {

    if (item && item.class) {
      if (item.class.indexOf(value) > -1) {
        return true;
      }
    }

    return false;
  }

  getButtonClass(item: any) {
    if (item && item.class) {
      if (item.class === 'qm-stroked-button') { return 'mat-stroked-button'; }
      if (item.class === 'qm-button') { return 'mat-button'; }
      return item.class;
    } else {
      return 'mat-button';
    }
  }

  onSearch() {

    if (this.searchValue !== this.previousSearchValue) {
      this.previousSearchValue = this.searchValue;
      // this.loadData(0, this.paginator.pageSize);
    }

  }

  onSearchClear() {

    if (this.searchValue) {
      this.searchValue = null;
      this.previousSearchValue = null;
      // this.loadData(0, this.paginator.pageSize);
    }

  }

  public setFilter(value: string) {

    console.log('set filter');
    this.customFilter = value;
    this.loadData(0, this.pageSize);

  }

  /**
   * For each row, creates the context menu entries in the statusMenus property
   */
  buildContextMenus() {

    // activeFlowID and activeFlowState should NOT be hardcoded
    // Clear existing menus
    this.statusMenus = [];

    for (const item of this.dataDetail.dataItems) {

      if (item.activeFlowID) {

        let menu: MenuNode = this.statusMenus.find(m => m.id == item.activeFlowID);

        if (!menu) {

          menu = new MenuNode(item.activeFlowID, '');
          this.statusMenus.push(menu);

          for (const status of FlowService.getFlowStatuses(item.activeFlowID, item.activeFlowState, true, true)) {

            if (!menu.children) {
              menu.children = [];
            }

            menu.children.push(new MenuNode(status.ftFlowStatusID, status.text, status.isUserSettable));
          }
        }
      }
    }
  }

  isIcon(value: any) {
    return isNumber(value);
  }
  isInitialsIcon(value: any) {
    return !isNumber(value);
  }
  hexColor(value: string) {
    if (value && !value.startsWith('#')) {
      return '#' + value;
    }

    return value;
  }
  public getPNGColor(color: string) {

    let styles = {};

    if (color) {
      styles = {
        '-webkit-filter': 'opacity(.5) drop-shadow(0 0 0 #' + color + ') contrast(350%)',
        'filter': 'opacity(.5)  drop-shadow(0 0 0 #' + color + ') contrast(350%)'
      };
    }

    return styles;
  }

  private getColor(row: any, columnField: any): string {

    if (columnField.color && row[columnField.color]) {

      if (row[columnField.color].length > 0) {
        const color: string = row[columnField.color];
        if (color.startsWith('#')) {
          return color;
        } else {
          return `#${color}`;
        }
      }
    }
    return '#dfdfdf';
  }

  // // drag and drop

  // canDrag(item: any): boolean {

  //   let ret = false;
  //   if (this.screenDetail.grid.onDragAction) {
  //     this.screenDetail.grid.onDragAction.forEach(action => {
  //       // return true if at least one action can be performed
  //       if (Utilities.evaluateCondition(action.condition, this.uriCollections, false, item)) { ret = true; }
  //     });
  //   }
  //   return ret;
  // }

  // getDragData(item: any) {
  //   const action = this.screenDetail.grid.onDragAction;
  //   if (action) {

  //     const dragAction = (action.find(d => d.action === ActionIdentifier.SetDragParameters).actionArgument as unknown) as DragParametersAction;
  //     // let dragAction = action.find(d => d.action == ActionIdentifier.SetDragParameters);
  //     if (dragAction) {

  //       const result = Utilities.createItemFromObjectDefaults(dragAction.newObjectDefaults, item, item, this.uriCollections, this.screenParameters);
  //       const dragData = new DragData();
  //       dragData.item = result;
  //       dragData.senderKey = dragAction.senderKey;
  //       return dragData;
  //     }
  //   }
  // }

  // getDragPreview(item: any) {
  //   const action = this.screenDetail.grid.onDragAction;
  //   if (action) {

  //     const dragAction = (action.find(d => d.action === ActionIdentifier.SetDragParameters).actionArgument as unknown) as DragParametersAction;
  //     if (dragAction && (dragAction.title || dragAction.subtitle)) {
  //       const obj = {};
  //       const title: string = Utilities.parseArgumentsFromData(dragAction.title.text, dragAction.title.argumentIds, item, null,
  //         this.screenParameters);
  //       const subtitle: string = Utilities.parseArgumentsFromData(dragAction.subtitle.text, dragAction.subtitle.argumentIds, item, null,
  //         this.screenParameters);

  //       obj['title'] = title;
  //       obj['subtitle'] = subtitle;

  //       return obj;
  //     }
  //   }
  // }

  // drop(event: CdkDragDrop<string[]>) {

  //   if (event.container.id === event.previousContainer.id && this.screenDetail.grid.onReorderAction) {
  //     const arrayClone = this.dataDetail.dataItems.slice();
  //     const value = arrayClone[event.previousIndex];
  //     arrayClone.splice(event.previousIndex, 1);
  //     arrayClone.splice(event.currentIndex, 0, value);
  //     this.dataDetail.dataItems = arrayClone;

  //     const action = this.screenDetail.grid.onReorderAction;
  //   } else if (this.screenDetail.grid.onDropAction) {
  //     const action = this.screenDetail.grid.onDropAction;
  //     if (action) { this.onItemClick.emit({ row: event.item.data['item'], action: action }); }
  //   }
  // }

  // infinte scroll

  // get loadCompleted(): boolean {

  //   console.log('loadCompleted');
  //   console.log(this.dataDetail);
  //   console.log(this.dataDetail.dataItems.length);
  //   // Stop load if all the items have been loaded
  //   if (this.dataDetail && this.dataDetail.dataItems && this.dataDetail.dataItems.length === this.dataDetail.totalRecordCount) {
  //     return true;
  //   }
  //   return false;
  // }

  onScroll() {
    if (this.infiniteScrollDisabled()) { return; }

    this.onLoadData.emit({ virtualScroll: true, customParams: this.customParams });
  }

  public get customParams(): string {
    if (this.fromDate && this.toDate) {
      return `FromDate:${this.fromDate.format('YYYY-MM-DD')},ToDate:${this.toDate.format('YYYY-MM-DD')}`;
    }
    return null;
  }
}
