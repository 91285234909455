import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from 'src/app/services/utilities/utilities';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';

@Pipe({
  name: 'isElementEnabled'
})
export class IsElementEnabledPipe implements PipeTransform {

  transform(elementName: string, cardTemplate: any, dataItem: any, uriCollections: UriCollection[], dataObjectKey: string): any {
    const element = cardTemplate[elementName];
    if (element && element.isEnabledCondition && dataItem) {
      if (uriCollections.length < 2) {
        console.log('not enough uriCollections');
        console.log(uriCollections);
      }
      return Utilities.evaluateCondition(element.isEnabledCondition, uriCollections, null, null, dataItem, dataObjectKey);
    }
    return true;
  }

}
