import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {Action} from '../../../../models/actions/Action';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { ScreenType } from 'src/app/models/Enums';
import { MenuItem } from 'src/app/models/data/MenuItem';

export interface DialogData {
  action: Action;
  row: any[];
  screenParameters: any;
}

@Component({
  selector: 'app-view-details-dialog',
  templateUrl: './view-details-dialog.component.html',
  styleUrls: ['./view-details-dialog.component.scss']
})
export class ViewDetailsDialogComponent implements OnInit {

  row: any;
  action: Action;
  title: string;
  screenParameters: any;

  ScreenType = ScreenType;

  toolbarItems: any[] & MenuItem[];

  constructor(
    public dialogRef: MatDialogRef<ViewDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {

    this.action = this.data.action;
    this.row = this.data.row;
    this.screenParameters = this.data.screenParameters;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onSetTitle(value: TitleUpdateEvent) {
    this.title = value.title;
  }

  onMenuItemActions(event: any[] & MenuItem[])
  {
    this.toolbarItems = event;
  }

  onDeleteCompleted(event: any)
  {
    this.dialogRef.close();
  }
}
