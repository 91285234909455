export class Enums {
}

export enum ViewControlType {
    Default = 0,
    MultiSelectAll = 1,
    SingleSelect = 2,
    Flow = 3,
    Lookup = 4,
    Icon = 5,
    Button = 6,
    NumberPicker = 7,
    Date = 8,
    DataSelect = 9,
    Number = 10,
    Counter = 11,
    SideBar = 12,
    Color = 13,
}

export enum CardItemType {
    StatusCardItem = 1,
    CheckableCardItem = 2,
    ScannableCardItem = 3,
    SimpleStatusCardItem = 4,
    // WebGrid = 5,
    QuantityCardItem = 6,
    TreeViewItem = 7,
    // Form = 8,
    TimelineCard = 9,
    CardSummary = 10,
    QuantityCardItemWide = 11,
    OrderBasketCard = 12,
    EditableStatusCard = 13,
    OrderCheckoutItem = 15,
}

export enum FilterType {
    Default = 0,
    Simple = 1,
    AutoComplete = 2,
    VirtualScroll = 3,
    Checklist = 4,
}

export enum ActionIdentifier {
    None = 0,
    LaunchScreen = 1,
    SearchData = 2,
    UpdateData = 3,
    UpdateScreenMetadata = 4,
    ChooseLookup = 5,
    ShareData = 6,
    EmailData = 7,
    DisplayDialog = 8,
    LaunchFlyout = 9,
    DeleteItem = 10,
    AddItem = 11,
    ReloadData = 12,
    AlertDialog = 13,
    SetDragParameters = 14,
    UpdateScreenParameters = 15,
    SortData = 16, 
    ShowMenu = 17,
    Intent = 18,
    GenerateDocument = 19
}

export enum IntentType {
    Email = 1,
    SMS = 2,
    Calendar = 3,
    ExternalLink = 4,
}

export enum ScreenType {
    Undefined = 0,
    Home = -2,
    Preferences = -3,
    SectionCardList = -1,
    ItemGridList = 1,
    ViewDetails = 2,
    EditDetails = 3,
    Report = 4,
    Search = 5,
    ScanItemList = 6,
    ScanItemDetail = 7,
    ShoppingBasket = 8,
    ItemCardList = 9,
    TreeView = 10,
    CompositeScreen = 11,
    TimelineView = 12,
    SwitchContentView = 13,
    PricingScreen = 14,
    ProductCatalogue = 15,
    OrderCheckout = 16,
    ScanEntry = 17,
    Calendar = 18,
    Gantt = 19
}

export enum SubScreenType {
    None = 0,
    ScanList = 1,
    ScanDetails = 2,
    ResourceAllocation = 3,
    OrderBasket = 4,
    ProductCatalogue = 5,
    OrderCheckout = 6,
    AmendCompositeChildren = 7,
    StockLevelGrid = 8,
}

export enum MenuType {
    None = 0,
    Root = 1,
    TopToolbar = 2,
    Fab = 3,
    BottomToolbar = 4,
    BulkActions = 5,
    Filter = 6,
    DialogPositive = 7,
    DialogNegative = 8,
    DialogNeutral = 9,
    Context = 10,
    ToggleGroup = 11,
    BackPressed = 12,
    DropdownAdd = 13,
}

export enum ViewType {
    None = 0,
    // read-only views
    Text = 1,
    Image = 2,
    TextImage = 3,
    Progress = 4,
    // read-write views
    Checkbox = 5,
    EditText = 6,
    NumberPicker = 7,
    DateTimePicker = 8,
    Dropdown = 9,
    List = 10,
    SearchField = 11,
    Chart = 12,
    ContextMenu = 13,
    ToggleButton = 14,
    LineSlider = 15,
    ColorPicker = 16,
    IconPicker = 17,
    FileUpload = 18,
    BarcodeScanner = 19,
    TagList = 20,
    FlagSummary = 21,
    MultilineText = 22,
    MultilineEditText = 23,
    DateRangePicker = 24
}

export enum KeyboardType {
    Default = 0,
    Text = 1,
    TextCapCharacters = 2,
    TextCapSentences = 3,
    TextCapWords = 4,
    TextEmailAddress = 5,
    TextPassword = 6,
    Number = 7,
    NumberDecimal = 8,
    NumberSigned = 9,
    Phone = 10,
    NumberPassword = 11,
    Date = 12,
    DateTime = 13,
    Time = 14
}

export enum DataType {
    Unknown = 0,
    BigInt = 1,
    Bool = 3,
    Char = 4,
    DateTime = 6,
    Decimal = 7,
    Float = 8,
    // Image = 9,
    Int = 10,
    Money = 11,
    NChar = 12,
    NText = 13,
    NVarChar = 14,
    NVarCharMax = 15,
    // Real = 16,
    // SmallDateTime = 17,
    SmallInt = 18, //
    // SmallMoney = 19,
    // Text = 20,
    // Timestamp = 21,
    TinyInt = 22, // TinyInt
    Guid = 23,
    VarBinary = 28,
    VarChar = 30, //
    VarCharMax = 31,
    GeographicPoint = 100, // lat, long, elevation
    TagList = 101,

    InlineObject = 600,
    InlineArray = 602,

    InlineImage = 1000,
    UrlImage = 1001,
    ListInt = 1002,
    ListString = 1003,
    ListGuid = 1004,
    ListBool = 1005,
    ListObject = 1006,
    ListDate = 1007,
    TupleListInt = 2002,
    TupleListString = 2003,
    TupleListGuid = 2004,
    TupleListBool = 2005,
    TupleListObject = 2006,
    TupleListDate = 2007,
    DictInt = 3002,
    DictString = 3003,
    DictGuid = 3004,
    DictBool = 3005,
    DictObject = 3006,
    DictDate = 3007,

    StockInfo = 10000,

    // custom: TODO UIDataTypes
    Lookup = -1,
    FlowStatus = -4,

}


export enum UIDataType {
    Lookup = -1,
    FlowStatus = -4,

    Unknown = 0,
    Bool = 3,
    DateTime = 6,
    Number = 10,
    Money = 11,
    Guid = 23,
    Binary = 28,
    String = 14,

    GeographicPoint = 100, // lat, long, elevation
    TagList = 101,
    InlineImage = 1000,
    UrlImage = 1001,

    ListInt = 1002,
    ListString = 1003,
    ListGuid = 1004,
    ListBool = 1005,
    ListObject = 1006,
    ListDate = 1007,
    TupleListInt = 2002,
    TupleListString = 2003,
    TupleListGuid = 2004,
    TupleListBool = 2005,
    TupleListObject = 2006,
    TupleListDate = 2007,
    DictInt = 3002,
    DictString = 3003,
    DictGuid = 3004,
    DictBool = 3005,
    DictObject = 3006,
    DictDate = 3007,
}

export enum TemplateType {
    Grid = 5,
    Form = 8,
    ContactNumbers = 14,
    CompositeItemSelection = 16,
    EmbeddedOrderBasket = 18
}
export enum InstanceStyle {
    Default = 0,
    Collapsible = 1,
}
