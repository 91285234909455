import { Component, OnInit, Input } from '@angular/core';
import { DetailSection } from 'src/app/models/ScreenDetail';
import { Utilities } from 'src/app/services/utilities/utilities';
import { UpdateDetailItem } from 'src/app/models/viewGroupHelpers/UpdateDetailItem';
import { InvokeFunctionExpr } from '@angular/compiler';

@Component({
  selector: 'app-telephone-numbers',
  templateUrl: './telephone-numbers.component.html',
  styleUrls: ['./telephone-numbers.component.scss']
})
export class TelephoneNumbersComponent implements OnInit {

  @Input() contactNumbersTemplate: DetailSection;
  @Input() dataObjArray: any[];
  @Input() disabled: boolean;
  @Input() readonly: boolean;

  _telephoneNumberField: UpdateDetailItem;
  _telephoneTypeField: UpdateDetailItem;
  _displayNameField: UpdateDetailItem;
  _countryPrefixField: UpdateDetailItem;
  _enableSMSField: UpdateDetailItem;

  constructor() { }

  ngOnInit() {
    if (!this.contactNumbersTemplate.columnFields) {
      return;
    }
  }

  addNewDataObj() {
    this.dataObjArray.push({});
  }

  deleteDataObj(index: number) {
    if (index < 0 || index > this.dataObjArray.length - 1) { return; }
    this.dataObjArray.splice(index, 1);
  }

  get telephoneNumberField(): UpdateDetailItem {
    if (this._telephoneNumberField) { return this._telephoneNumberField; }

    this._telephoneNumberField = this.contactNumbersTemplate.columnFields
      .find(d => d.tag === 'telephoneNumber');

    return this._telephoneNumberField;
  }

  get telephoneTypeField(): UpdateDetailItem {
    if (this._telephoneTypeField) { return this._telephoneTypeField; }

    this._telephoneTypeField = this.contactNumbersTemplate.columnFields
      .find(d => d.tag === 'telephoneType');
    return this._telephoneTypeField;
  }

  get displayNameField(): UpdateDetailItem {
    if (this._displayNameField) { return this._displayNameField; }

    this._displayNameField = this.contactNumbersTemplate.columnFields
      .find(d => d.tag === 'displayName');
    return this._displayNameField;
  }

  get countryPrefixField(): UpdateDetailItem {
    if (this._countryPrefixField) { return this._countryPrefixField; }

    this._countryPrefixField = this.contactNumbersTemplate.columnFields
      .find(d => d.tag === 'countryPrefix');
    return this._countryPrefixField;
  }

  get enableSMSField(): UpdateDetailItem {
    if (this._enableSMSField) { return this._enableSMSField; }

    this._enableSMSField = this.contactNumbersTemplate.columnFields
      .find(d => d.tag === 'enableSMS');
    return this._enableSMSField;
  }

  getBackgroundColor(errorText: string) {
    if (errorText) {
      return { 'background-color': '#fffcf9' };
    } else {
      return { 'background-color': 'transparent' };
    }
  }
  // isEnabled(enabledCondition: string, dataItem: any) {
  //   if (!enabledCondition) { return true; }
  //   const ie = Utilities.evaluateCondition(enabledCondition, this.uriCollections, this.screenParameters, dataItem, null);
  //   return ie;
  // }
}
