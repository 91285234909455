import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { ToastrService } from 'ngx-toastr';
import { DragParametersAction } from 'src/app/models/actions/DragParametersAction';
import { CardItem } from 'src/app/models/cardModels/CardItem';
import { ItemClickEvent } from 'src/app/models/events/ItemClickEvent';
import { LoadDataEvent } from 'src/app/models/events/LoadDataEvent';
import { DragData } from 'src/app/models/itemList/DragData';
import { CardScreenDetail } from 'src/app/models/screenModels/CardScreenDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { Action } from '../../../../models/actions/Action';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { MenuItem } from '../../../../models/data/MenuItem';
import { ActionIdentifier, CardItemType } from '../../../../models/Enums';
import { LineSlider } from '../../../../models/viewControls/LineSlider';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { IconModel } from 'src/app/models/IconArgument';
import { QuantityCardComponent } from '../cards/quantity-card/quantity-card.component';
import { OrderBasketCardComponent } from '../cards/order-basket-card/order-basket-card.component';
import { OrderCheckoutItemComponent } from '../cards/order-checkout-item/order-checkout-item.component';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {

  cols = 2;
  CardItemType = CardItemType;
  currentCardIndex: number;

  @Input() height: number;
  @Input() metadata: FTThingDetail[];
  @Input() screenDetail: CardScreenDetail;
  @Input() pageSize: number;
  @Input() totalRecordCount: number;
  _dataItems: any[];
  @Input() dataItems: any[];
  // public get dataItems(): any[] {
  //   return this._dataItems;
  // }

  // public set dataItems(v: any[]) {
  //   // console.log(v);
  //   this._dataItems = v;
  // }


  @Input() row: object[]; // Parent row
  @Input() idName: string;
  @Input() groupName: string;
  @Input() uriCollections?: UriCollection[] = [];
  @Input() screenParameters?: any = {};
  @Input() readOnly = false;
  menuItems: MenuItem[];

  @Output() onLoadData = new EventEmitter<LoadDataEvent>();
  @Output() onViewItem = new EventEmitter<any>();
  @Output() onItemClick = new EventEmitter<ItemClickEvent>();

  _width: number;
  selectedItem: any;

  stockLoaded = false;
  minAvailableStock: number;
  maxAvailableStock: number;
  allocatedStock: number;
  cardType: number;

  // context menu
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  contextRowItem: any;
  @Output()
  contextRowItemchange = new EventEmitter<any>();

  constructor(public toastr: ToastrService, private thingService: ThingService, private contextMenuService: ContextMenuService,
    public dialog: MatDialog) { }

  ngOnInit() {
  }

  // get dragName(): string {

  //   if (this.groupName) {
  //     return 'ITEM';
  //   }

  //   return null;
  // }

  get width(): number {
    return this._width;
  }

  @Input() set width(value: number) {
    this._width = value;
    if (this.getCardItemType() !== CardItemType.OrderCheckoutItem) {
      this.cols = this.getColumns(value);
    }
    else {
      this.cols = 1;
    }
  }

  get loadCompleted(): boolean {

    // Stop load if all the items have been loaded
    if (this.dataItems && this.dataItems.length === this.totalRecordCount) {
      return true;
    }
    return false;
  }

  getColumns(width: number): number {

    let result: number = Math.floor(width / 410);

    if (result < 1) {
      result = 1;
    }

    return result ? result : 1;
  }

  public getCardItemType(): CardItemType {

    return this.cardItem.cardItemTypeId;
  }

  public getCardItemHeight(): number {

    switch (this.cardItem.cardItemTypeId) {
      case CardItemType.OrderBasketCard:
        return OrderBasketCardComponent.cardHeight;
      case CardItemType.QuantityCardItem:
        return QuantityCardComponent.cardHeight;
      case CardItemType.OrderCheckoutItem:
        return OrderCheckoutItemComponent.cardHeight;
      default:
        return 180;
    }
  }

  get cardItem(): CardItem {

    return this.screenDetail.cardItems[0];
  }

  public getCardTemplate(): any {

    return this.cardItem.cardItemObject;
  }

  public getCardDataObjectKey(): any {

    return this.cardItem.dataObjectKey;
  }

  public getCardDataObjectTag(): any {

    return this.cardItem.tag;
  }

  private getColor(row: any): string {

    if (!this.cardItem.cardItemObject['cardColour']) {
      return '';
    }
    const property = Utilities.getLastEntry((this.cardItem.cardItemObject['cardColour'] as IconModel).icon.colour);
    if (row[property]) {

      if (row[property].length > 0) {
        return `${row[property]}`;
      }
    }
    return '';
  }

  public getText(propertyPath: string, row: any): string {

    if (propertyPath) {
      return Utilities.getData(propertyPath, row);
    }
    return '';
  }

  get hasContextMenu(): boolean {
    return (this.cardItem.contextMenu && this.cardItem.contextMenu.length > 0);
  }

  public onAction(event: any) {
    this.onActionClick(event.event, event.dataItem, event.action);
  }

  public onActionClick(event: any, row: any, actionList: Action[]) {

    // if (item.action[0].action === ActionIdentifier.DisplayDialog) {
    const completionEvent = new EventEmitter<boolean>();
    completionEvent.subscribe(complete => {
      this.refresh();
    });
    //   const ab = new ActionBuilder(item.action, row, null, this.uriCollections, this.screenParameters, completionEvent, this.thingService, this.toastr, this.dialog);
    //   ab.PerformAction();
    // } else {
    this.onItemClick.emit({ action: actionList, row: row, completionEvent: completionEvent });
    // }

    // if (actionList[0].action === ActionIdentifier.DisplayDialog) {
    //   const completionEvent = new EventEmitter<any>();
    //   completionEvent.subscribe(complete => {
    //     console.log('completed');
    //     this.refresh();
    //   });
    //   const ab = new ActionBuilder(actionList, dataItem, null, this.uriCollections, this.screenParameters, completionEvent, this.thingService, this.toastr, this.dialog);
    //   ab.PerformAction();
    // } else {
    //   this.onItemClick.emit({ row: dataItem, action: actionList });
    // }
  }

  itemClicked($event: any, action: Action[], row: any, newItem: any)
  {
    this.onItemClick.emit({ action: action, row: row, newItem: newItem });
    $event.preventDefault();
    $event.stopPropagation();
  }

  refresh() {
    this.onLoadData.emit({ pageIndex: 0, pageSize: this.pageSize });
  }

  onScroll() {
    this.onLoadData.emit({ virtualScroll: true });

  }

  onSelect(item: any) {
    this.selectedItem = item;

    if (this.cardItem.onClickAction) {

      // const action: Action = this.cardItem.onClickAction[0];

      // // 9 = Display Flyout / sidebar
      // if (action.action === ActionIdentifier.LaunchFlyout || action.action === ActionIdentifier.ReloadData) {
      //   this.onViewItem.emit({ row: item, action: action, length: 1 });
      // } else {
        this.onItemClick.emit({ action: this.cardItem.onClickAction, row: this.selectedItem });
      // }
    }
  }

  onIconMenu($event: MouseEvent, item: any, index?: number)
  {
    this.contextRowItemchange.emit(item);
    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: this.basicMenu,
      event: $event,
      item: item
    });
    this.contextRowItem = item;
    this.currentCardIndex = index;
    $event.preventDefault();
    $event.stopPropagation();
  }

  onContextMenu($event: MouseEvent, item: any, index?: number) {

    // this.contextMenuService.show.next({
    //   // Optional - if unspecified, all context menu components will open
    //   contextMenu: this.basicMenu,
    //   event: $event,
    //   item: item,
    // });
    // This is  fix for the sub-item (exectute) function not being called
    this.contextRowItem = item;
    this.currentCardIndex = index;
    $event.preventDefault();
    $event.stopPropagation();
    this.getMenuItems();

  }

  onContextExecute(row: any, menuItem: MenuItem, event?: any) {
    let item = row;

    // Fix for sub-items not calling (execute) and passing item in the event - pick from global variable
    if (!item) {
      item = this.contextRowItem;
    }

    this.onItemClick.emit({ action: menuItem.action, row: item, newItem: menuItem.item });
  }

  loadStock(item: LineSlider) {
    this.stockLoaded = true;

    if (item.dataUri) {
      const dataUri = Utilities.parseArgumentsFromData(item.dataUri, item.dataUriArguments);
    }
  }

  getDragData(item: any) {
    // console.log(this.cardItem);
    const action = this.cardItem.onDragAction;
    if (action) {

      const dragAction = (action.find(d => d.action === ActionIdentifier.SetDragParameters).actionArgument as unknown) as DragParametersAction;
      if (dragAction) {
        const result = Utilities.createItemFromObjectDefaults(dragAction.newObjectDefaults, item, item, this.uriCollections, this.screenParameters);

        const dragData = new DragData();
        dragData.item = result;
        dragData.senderKey = dragAction.senderKey;
        return dragData;
      }
    }
  }

  canDrag() {
    return (this.cardItem.onDragAction != null) ? true : false;
  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.container.id === event.previousContainer.id) {
      if (this.cardItem.onReorderAction) {
        const arrayClone = this.dataItems.slice();
        const value = arrayClone[event.previousIndex];
        arrayClone.splice(event.previousIndex, 1);
        arrayClone.splice(event.currentIndex, 0, value);
        this.dataItems = arrayClone;

        const action = this.cardItem.onReorderAction;
      }
    } else if (this.cardItem.onDropAction) {
      const action = this.cardItem.onDropAction;
      if (action) {
        this.onActionClick(event, event.item.data['item'], action);
      }
    }
  }

  hasMenuItems(parentMenuId?: number): boolean {
    if (!this.screenDetail.cardItems[0].contextMenu) {
      return false;
    }

    if (parentMenuId) {
      return this.screenDetail.cardItems[0].contextMenu.filter(m => m.parentMenuItemId === parentMenuId).length > 0;
    } else {
      return true;
    }
  }

  getMenuItems(parentMenuId?: number): MenuItem[] {

    if (!this.screenDetail.cardItems[0].contextMenu) {
      return;
    }

    let retList: MenuItem[] = [];
    if (!parentMenuId) {
      retList = this.screenDetail.cardItems[0].contextMenu.filter(m => !!!m.parentMenuItemId);
    } else {
      const itemList = this.screenDetail.cardItems[0].contextMenu.filter(m => m.parentMenuItemId === parentMenuId);
      if (itemList.length <= 0) {
        return;
      }
      itemList.forEach(menuItem => {
        if (menuItem.menuItemTitle.startsWith('data.')) {
          const list = Utilities.getUriCollection(menuItem.menuItemTitle, this.uriCollections).dataValues;
          list.forEach(item => {
            const newItem = {
              value: item.sectionId,
              menuItemId: item.sectionId,
              parentMenuItemId: 0,
              menuItemType: menuItem.menuItemType,
              menuItemTitle: Utilities.getValueFromArray(item, menuItem.menuItemTitle),
              menuItemIconId: 0,
              enableCondition: null,
              visibleCondition: null,
              action: menuItem.action,
              sequence: 0,
              active: true,
              open: true,
              item: item,
              index: this.currentCardIndex
            };
            retList.push(newItem);
          });
        } else {
          retList.push(menuItem);
        }
      });
    }

    if (!!retList && retList.length > 0)
    {
      retList.forEach(x => x.index = this.currentCardIndex);
    }
    this.menuItems = retList;
    return retList;
  }
}
