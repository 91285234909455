import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { Action } from 'src/app/models/actions/Action';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { ScreenType } from 'src/app/models/Enums';
import { ActionEvent } from 'src/app/models/events/ActionEvent';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { UpdateScreenDataEvent } from 'src/app/models/events/UpdateScreenDataEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { ListDetail } from 'src/app/models/ListDetail';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { IconService } from 'src/app/services/icon/icon.service';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';
import { ItemListComponent } from '../lists/item-list/item-list.component';

@Component({
  selector: 'app-switch-content',
  templateUrl: './switch-content.component.html',
  styleUrls: ['./switch-content.component.scss']
})
export class SwitchContentComponent implements OnInit {

  screenType = ScreenType;

  triggerRefresh = false;

  @ViewChildren(ItemListComponent) itemLists: QueryList<ItemListComponent>;

  //For compatibility with Item List - should be reworked so this component handles the switch content function instead
  @Input() previousDetail: ListDetail;
  @Input() title: string;
  @Input() id: string;
  @Input() height: number;
  @Input() width: number;
  @Input() action: Action;
  @Input() row: object[];
  @Input() sideBarMode: 'over' | 'push';
  @Input() sideBarOpen: boolean;

  @Output() onAction = new EventEmitter<ActionEvent>();
  private handleOnAction(event: ActionEvent){
    this.onAction.emit(event);
  }

  @Output() onAdd = new EventEmitter<AddUpdateItemModel>();
  private handleOnAdd(event: AddUpdateItemModel){
    this.onAdd.emit(event);
  }

  @Output() onViewItem = new EventEmitter<ViewDetailEvent>();
  private handleOnViewItem(event: ViewDetailEvent){
    this.onViewItem.emit(event);
  }
  
  @Output() onDeleteCompleted = new EventEmitter<DeleteEvent>();
  private handleOnDeleteCompleted(event: DeleteEvent){
    this.onDeleteCompleted.emit(event);
  }
  
  @Output() onTitleUpdated = new EventEmitter<TitleUpdateEvent>(true);
  private handleOnTitleUpdated(event: TitleUpdateEvent){
    this.onTitleUpdated.emit(event);
  }
  
  @Output() updateScreenData = new EventEmitter<UpdateScreenDataEvent>();
  private handleUpdateScreenData(event: UpdateScreenDataEvent){
    this.updateScreenData.emit(event);
  }
  
  @Output() onDisplaySwitchContentDialog = new EventEmitter<any>();
  private handleOnDisplaySwitchContentDialog(event){
    this.onDisplaySwitchContentDialog.emit(event);
  }
  
  // End of Item List Inputs/Outputs
  
  @Input() screenParameters: any = {};
  @Input() uriCollections?: UriCollection[] = [];

  screenDetailComponents: ScreenDetail;
  childScreens : ListDetail[];
  activeChildScreen : ListDetail;

  useLegacyItemList: boolean;

  constructor(private thingService: ThingService, private iconHelper: IconHelper, private iconService: IconService) { }

  ngOnInit() {
    this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
      .subscribe(result => this.onGetScreenDetailSuccessful(result), error => this.onGetScreenDetailFailed(error));
  }

  onGetScreenDetailSuccessful(screenDetail: ScreenDetail){
    this.screenDetailComponents = screenDetail;
    if (this.screenDetailComponents) {
      if(this.screenDetailComponents.componentScreens.every(componentScreen => componentScreen.screenType == ScreenType.ItemCardList || componentScreen.screenType == ScreenType.ItemGridList)){
        // For now, use the existing Item List component for Grid/Card only Switch Content screens
        this.useLegacyItemList = true;
        return;
      }

      this.screenDetailSetup();
    }
  }

  getSubScreenTitle(screenDetail: ScreenDetail){
    var title = Utilities.parseTitle(screenDetail.screenTitle, null, null, this.screenParameters);
    return title ? title : screenDetail.screenType;
  }

  onGetScreenDetailFailed(error){

  }

  public refreshData() {
    this.triggerRefresh = true;
  }

  screenDetailSetup(){
    if (this.action) {
      this.screenParameters = this.action.actionArgument.screenParameters;

      this.screenParameters = Utilities.setScreenParameters(this.screenParameters, this.screenDetailComponents.requiredScreenParameters,
        this.row, this.uriCollections);
      if (!this.screenParameters) { this.screenParameters = {}; }
      this.screenParameters.sidebarOpen = this.sideBarOpen;
      this.screenParameters.sidebarOpenPush = this.sideBarOpen && this.sideBarMode === 'push';;
    }

    if (this.screenDetailComponents.screenTitle) {
      this.title = Utilities.parseArgumentsFromData(this.screenDetailComponents.screenTitle.text,
        this.screenDetailComponents.screenTitle.argumentIds, this.row, null, this.screenParameters);
    }

    if (this.title) {
      this.onTitleUpdated.emit({ id: this.id, title: this.title, subtitle: null /*subtitle*/ });
    }

    this.childScreens = [];
    this.screenDetailComponents.componentScreens.forEach(componentScreen => {
      var listDetail = new ListDetail();
      listDetail.screenDetail = componentScreen;
      listDetail.action = this.action;
      listDetail.row = this.row;
      listDetail.screenType = componentScreen.screenType;
      this.childScreens.push(listDetail);
    });
    this.activeChildScreen = this.childScreens[0];
  }

  onSwitch(switchEvent){
    var screenId = switchEvent.value;
    var newScreen = this.childScreens.find(screen => screen.id == screenId);
    this.activeChildScreen = newScreen;
  }

  public getFontAwesomeIcon(id: any) {
    var iconHex = this.iconHelper.getFontAwesomeHex(+id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }


}
