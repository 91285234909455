import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReloadDataAction } from 'src/app/models/actions/ReloadDataAction';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { DataDetail } from 'src/app/models/DataDetail';
import { TreeItemSelectedEvent } from 'src/app/models/events/TreeItemSelectedEvent';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { Action } from '../../../../models/actions/Action';
import { ActionURI } from '../../../../models/data/ActionURI';
import { ActionIdentifier } from '../../../../models/Enums';
import { MenuNode } from '../../../../models/menu-tree/MenuNode';
import { MenuNodeSection } from '../../../../models/menu-tree/MenuNodeSection';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { ItemListComponent } from '../../lists/item-list/item-list.component';
import { OrderTotalComponent } from '../order-total/order-total.component';




@Component({
  selector: 'app-order-basket',
  templateUrl: './order-basket.component.html',
  styleUrls: ['./order-basket.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class OrderBasketComponent implements OnInit {

  headerHeight = 56;
  titleHeight = 50;
  pageHeight: number = Utilities.innerHeight(this.headerHeight);

  @Input() action: Action;
  @Input() row: any;

  screenDetailComponents: ScreenDetail;

  title: string;

  productItemUri: ActionURI;
  screenParameters: any;

  productClassUri: ActionURI;
  productRelatedUri: ActionURI;
  productRelatedDataUri: string;
  orderItemSectionUri: ActionURI;
  orderBasketSummaryUri: ActionURI;
  orderBasketUri: ActionURI;

  screenDetailProductList: ScreenDetail;
  screenDetailCategoryList: ScreenDetail;
  screenDetailRelatedList: ScreenDetail;
  screenDetailBasket: ScreenDetail;
  screenDetailQuickAdd: ScreenDetail;
  screenDetailOrderBasketSummary: ScreenDetail;

  selectedSection: MenuNodeSection;

  showRelatedProducts = false;
  showCategory = false;
  showMain = true;

  productTitle = 'Loading';
  relatedTitle: string;
  uriCollections: UriCollection[] = [];

  @Output() onCloseScreen = new EventEmitter<any>();

  @ViewChild('productItems') productItems: ItemListComponent;
  @ViewChild('orderTotal') orderTotal: OrderTotalComponent;
  @ViewChild('basketItems') basketItems: ItemListComponent;

  // TODO make this a generic solution
  productItemObjectName = 'productItemCurrentCatalogue';

  constructor(public toastr: ToastrService, private thingService: ThingService) {
  }

  ngOnInit() {
    this.loadInitialData();
  }


  get masterHeight(): number {

    if (this.screenDetailRelatedList) {
      if (this.showRelatedProducts) {
        return (this.pageHeight * .7) - this.titleHeight;
      } else {
        return (this.pageHeight * 1) - this.titleHeight - 58;
      }
    } else {
      return this.pageHeight - this.titleHeight - 2;
    }
  }

  get detailVisible(): boolean {

    return (this.detailHeight !== 50);

  }

  get detailHeight(): number {

    if (this.showRelatedProducts) {
      return (this.pageHeight * .3);
    } else {
      return 50;
    }
  }

  get itemHeight2(): number {
    return (this.itemHeight / 2);
  }

  get itemHeight(): number {
    return (this.pageHeight) - this.totalHeight - this.quickHeight;
  }

  get totalHeight(): number {
    return this.orderTotal.height;
    return 64;
  }

  get quickHeight(): number {
    return 60;
  }

  onClose() {
    this.onCloseScreen.emit();
  }

  onRelatedProducts() {
    this.showRelatedProducts = !this.showRelatedProducts;
    setTimeout(() => {
      this.basketItems.updateWidth();
    });
  }

  onShowCategory() {
    this.showCategory = !this.showCategory;
    setTimeout(() => {
      this.basketItems.updateWidth();
    });
  }

  onShowMain() {
    this.showMain = !this.showMain;
    setTimeout(() => {
      this.basketItems.updateWidth();
      this.productItems.updateWidth();
    });
  }

  onResize(event) {

    this.pageHeight = Utilities.innerHeight(this.headerHeight);
  }

  loadInitialData() {

    for (const uri of this.action.actionArgument.uris) {
      const actionUri: ActionURI = JSON.parse(JSON.stringify(uri));

      if (actionUri.dataUri) {
        actionUri.dataUri = Utilities.parseArgumentsFromData(actionUri.dataUri, actionUri.dataUriArgs, null, this.row);
      }

      if (!this.uriCollections.find(u => u.name === uri.name)) {
        this.uriCollections.push({ name: uri.name, uris: actionUri, dataValues: [] });
      }

      if (uri.name === this.productItemObjectName) {
        this.productItemUri = actionUri;
      } else if (uri.name === 'productClass') {
        this.productClassUri = actionUri;
      } else if (uri.name === 'productRelatedItemsChildren') {
        this.productRelatedUri = actionUri;
      } else if (uri.name === 'orderItemSection') {
        this.orderItemSectionUri = actionUri;
        this.uriCollections.find(u => u.name === uri.name).uris.dataUri = actionUri.dataUri;
      } else if (uri.name === 'orderSummary') {
        this.orderBasketSummaryUri = actionUri;
      } else if (uri.name === 'orderBasket') {
        this.orderBasketUri = actionUri;
        this.uriCollections.find(u => u.name === uri.name).uris.dataUri = actionUri.dataUri;
      }
    }

    this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
      .subscribe(result => this.onGetDetailSuccessful(result), error => this.onGetDetailFailed(error));

    // get the order item sections here

    this.thingService.getData(this.orderItemSectionUri.metaUri, this.orderItemSectionUri.dataUri)
      .subscribe(result => this.getDataDetailWithHeaderSuccessful(this.orderItemSectionUri.name, false, result[1], result[0], null), error => this.onGetDetailFailed(error));

    this.thingService.getData(this.orderBasketUri.metaUri, this.orderBasketUri.dataUri)
      .subscribe(result => this.getDataDetailWithHeaderSuccessful(this.orderBasketUri.name, false, result[1], result[0], null), error => this.onGetDetailFailed(error));
  }

  private onGetDetailSuccessful(screenDetailComponents: ScreenDetail) {

    this.screenDetailComponents = screenDetailComponents;

    if (this.screenDetailComponents.screenTitle) {

      const screenParameters = Utilities.setScreenParameters(this.action.actionArgument.screenParameters, this.action.actionArgument.requiredScreenParameters, this.row);
      this.title = Utilities.parseArgumentsFromData(this.screenDetailComponents.screenTitle.text, this.screenDetailComponents.screenTitle.argumentIds,
        this.row, null, screenParameters);
    }

    this.screenDetailProductList = this.screenDetailComponents.componentScreens.find(c => c.screenTag === 'productList');
    this.screenDetailCategoryList = this.screenDetailComponents.componentScreens.find(c => c.screenTag === 'categoryList');
    this.screenDetailRelatedList = this.screenDetailComponents.componentScreens.find(c => c.screenTag === 'relatedList');
    if (this.screenDetailRelatedList) { this.showRelatedProducts = true; }
    this.screenDetailBasket = this.screenDetailComponents.componentScreens.find(c => c.screenTag === 'basket');
    this.screenDetailQuickAdd = this.screenDetailComponents.componentScreens.find(c => c.screenTag === 'quickAdd');
    this.screenDetailOrderBasketSummary = this.screenDetailComponents.componentScreens.find(c => c.screenTag === 'orderBasketSummary');

    Utilities.log('onGetDetailSuccessful');
  }

  private getDataDetailWithHeaderSuccessful(name: string, paging: boolean, dataDetail: DataDetail, columns?: FTThingDetail[], screenDetailComponents?: ScreenDetail) {

    let uriCollection = this.uriCollections.find(u => u.name === name);

    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail.dataItems };
      this.uriCollections.push(uriCollection);
    }

    uriCollection.dataValues = dataDetail.dataItems;
    if (columns) {
      uriCollection.dataMetadata = columns;
    } else {
      if (name.startsWith('data.')) {
        // console.log(`Incorrect refresh data object format ${name}`);
        name = name.substring(5);
      }
      if (name === 'orderBasket') {
        this.basketItems.refresh();
      }
      if (name === this.productItemObjectName) {
        this.productItems.refresh();
      }

      // const screensToRefresh = this.screenDetailComponents.componentScreens
      //   .filter(s => (s.cardItems && s.cardItems.find(c => c.dataObjectKey === name))
      //     || (s.grid && s.grid.dataObjectKey === name));

      // console.log(`Refresh ${screensToRefresh.join(', ')}`);
      // screensToRefresh.forEach(screen => {
      //   switch (screen.screenTag) {
      //     case 'basket':
      //       this.basketItems.refresh();
      //       break;
      //     case 'productList':
      //       this.productItems.refresh();
      //       break;
      //   }
      // });
    }
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public onTreeItemSelected(value: TreeItemSelectedEvent) {

    const id: string = value.id;
    const actionList: Action[] = value.actionList;
    const menuNodes: MenuNode[] = value.data;

    actionList.forEach(action => {
      if (action.action === ActionIdentifier.ReloadData) {

        let filter = '';

        if (action.actionArgument.targetDataObject === this.productItemObjectName) {

          for (const node of menuNodes) {

            if (filter !== '') {
              filter += ' or ';
            }

            filter += `${id} eq ${node.id}`;
          }
          this.productItems.setFilter(filter);
        }
      }
    });
  }

  public onView(value) {

    if (value) {
      if (value.action.action === ActionIdentifier.ReloadData) {

        const ac = value.action.actionArgument as ReloadDataAction;
        if (ac && ac.targetScreen === 'relatedList') {
          Utilities.log(JSON.stringify(value.action));
          this.productRelatedDataUri = Utilities.parseArgumentsFromData(value.action.actionArgument.dataUri, value.action.actionArgument.dataUriArgs, value.row);
        }
      }
    }
  }

  public onSectionSelected(value: MenuNodeSection) {
    this.selectedSection = value;
  }

  public onBasketChanged(event) {
    this.orderTotal.loadData();
  }

  public onUpdateCompleted(event: any) {
    if (event.sectionId > -1) {
      this.basketItems.refresh({ sectionId: event.sectionId });
    }
    if (event.resultTargetDataObject) {
      const targets = (event.resultTargetDataObject as string).split(',');
      targets.forEach(target => {

        const uri = this.uriCollections.find(u => u.name === target);
        if (uri) {
          this.thingService.getDataDetailWithHeader(uri.uris.dataUri)
            .subscribe(result => this.getDataDetailWithHeaderSuccessful(uri.name, false, result, null, null),
              error => this.onGetDetailFailed(error));
        }
      });
    }
    this.orderTotal.loadData();
  }

  public onProductTitleUpdated(event) {
    this.productTitle = event.title;
  }

  public onRelatedTitleUpdated(event) {
    this.relatedTitle = event.title;
  }
}
