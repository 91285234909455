import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { IconService } from 'src/app/services/icon/icon.service';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';
import { UpdateDetailComponent } from '../../update-detail/update-detail.component';
import { ViewDetailComponent } from '../../view-detail/view-detail.component';
import { DialogData } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-update-view-dialog',
  templateUrl: './update-view-dialog.component.html',
  styleUrls: ['./update-view-dialog.component.scss'],
})
export class UpdateViewDialogComponent implements OnInit {

  @ViewChild(UpdateDetailComponent ) updateDetailComponent: UpdateDetailComponent;
  @ViewChild(ViewDetailComponent ) viewDetailComponent: ViewDetailComponent;

  child: any;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Output()
  notifyUpdated = new EventEmitter();
  
  @Output()
  notifyDeleted = new EventEmitter();

  @Output()
  navigateBack = new EventEmitter();

  @Output()
  navigateClosed = new EventEmitter();

  @Output()
  notifySaveComplete = new EventEmitter();

  @Output()
  notifyAction = new EventEmitter();
  toolbarItems: any[] & MenuItem[];

  savedObject: any;

  updateIndicator: boolean = false;

  constructor(private iconHelper: IconHelper, private iconService: IconService,
    public dialogRef: MatDialogRef<UpdateViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
  }

  public onSetTitle(value: TitleUpdateEvent) {

    this.data['title'] = value.title;
    this.data['sideBarSubTitle'] = value.subtitle;
  }

  public onUpdate(value: AddUpdateItemModel) {
    this.notifyUpdated.emit(value);
  }

  public onClose() {
    this.navigateClosed.emit(true);
    this.dialogRef.close();
  }

  public onBack()
  {
    this.navigateBack.emit(true);
  }

  public refreshScreenOnReturn(){
    if(this.updateDetailComponent){
      this.updateDetailComponent.refreshDropdowns();
    }
  }

  public onUpdateCompleted(event: UpdateCompletedEvent)
  {
    // if (event.row){
    //   this.updateRow = event.row;
    //   this.newItem = event.newItem;
    // }
    if (event.closeFlyout) {
      //event.row = this.updateRow;
      //event.newItem = this.newItem;
      this.dialogRef.close(event);
    }
  }

  public onDeleteCompleted(value: DeleteEvent)
  {
    this.notifyDeleted.emit(value);
    // this.dialogRef.close();
  }

  public onCancel() {
    this.dialogRef.close();
  }

  public onSaveComplete(event: any) {
    this.notifySaveComplete.emit(event);
    // this.dialogRef.close();
  }

  public onAction(event: any) {
    this.notifyAction.emit(event);
  }

  public onUpdateSave(e) {
    e.preventDefault();
    if (this.updateDetailComponent.detailsForm.valid)
    {
      this.updateDetailComponent.onSave({ closeOnFinish: true, objectKey: null });
    }
  }

  checkShowButton(){
    if(this.updateDetailComponent){
      return this.updateDetailComponent.menuSave;
    }
    return false;
  }

  public setSavedObject(event: any) {
    this.savedObject = event;
  }

  getText(text: string, argumentIds: string[], isUpdate: boolean) {
    var r;
    if (isUpdate) {
      r = Utilities.parseArgumentsFromUriCollection(text, argumentIds, this.updateDetailComponent.uriCollections, null, this.updateDetailComponent.screenParameters);
    }
      else {
        r = Utilities.parseArgumentsFromUriCollection(text, argumentIds, this.viewDetailComponent.uriCollections);
      }
    return r;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  getIcon(id: any)
  {
    var iconHex = this.iconHelper.getFontAwesomeHex(id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  onClick(item: any)
  {
    if (!!this.updateDetailComponent)
    {
      this.updateDetailComponent.onClick(item);
    }
    else {
      this.viewDetailComponent.onClick(item);
    }
  }

  onMenuItemActions(event: any[] & MenuItem[])
  {
    this.toolbarItems = event;
    if (!!this.toolbarItems && this.data.updateItem && this.data.updateState !== null)
    {
      this.data.formHeight = this.data.formHeight - 53;
    }
  }

  onUpdateIndicator(event: boolean)
  {
    this.updateIndicator = event;
  }

  hasFooterItems()
  {
  }

}
