import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuNode } from 'src/app/models/menu-tree/MenuNode';

@Component({
  selector: 'app-menu1',
  templateUrl: './menu1.component.html',
  styleUrls: ['./menu1.component.scss']
})
export class Menu1Component implements OnInit {

  @Input()
  canAdd: boolean;

  @Input()
  canDelete: boolean;

  @Input()
  editItemNodeId: any;

  @Input()
  node: MenuNode;

  @Output()
  addItem = new EventEmitter<MenuNode>();

  @Output()
  deleteItem = new EventEmitter<MenuNode>();

  constructor() { }

  ngOnInit() {
  }

  onAddItem(item: MenuNode)
  {
   console.log(item);
    this.addItem.emit(item);
  }

  onDeleteItem(item: MenuNode)
  {
    this.deleteItem.emit(item);
  }
}
