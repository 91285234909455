import { Injectable } from '@angular/core';
import { IconMapping } from '../models/IconMapping';

@Injectable({
  providedIn: 'root'
})
export class IconHelper {

  constructor() { }

  getIconDecimalToHex(id: number)
  {
    if (!!id)
    {
      return `${id.toString(16)}`;
    }
    return;
  }

  geticonHexToDecimal(value: string)
  {
    return `${parseInt(value, 16)}`;
  }

  setIcon(iconMapping: IconMapping, hex: string)
  {


    if(!!hex && hex.startsWith("fa"))
    {
      return hex;
    }
    if (!!iconMapping)
    {
      return iconMapping.cssName;
    }
    return 'fa-question-circle'
  }

  getFontAwesomeHex(iconId: number) {
    switch (iconId) {
      case 1: {
        return 'fa-plus';
      }
      case 2: {
        return 'fa-plus-hexagon';
      }
      case 3: {
        return 'fa-barcode';
      }
      case 4: {
        return 'fa-barcode-read';
      }
      case 5: {
        return 'fa-times-circle';
      }
      case 6: {
        return 'fa-trash-alt';
      }
      case 7: {
        return 'fa-check';
      }
      case 8: {
        return 'fa-pencil';
      }
      case 9: {
        return 'fa-list';
      }
      case 10: {
        return 'fa-sliders-h';
      }
      case 11: {
        return 'fa-arrow-circle-right';
      }
      case 12: {
        return 'fa-arrow-circle-left';
      }
      case 13: {
        return 'fa-question-circle';
      }
      case 14: {
        return 'fa-home-alt';
      }
      case 15: {
        return 'fa-info-circle';
      }
      case 16: {
        return 'fa-tasks';
      }
      case 17: {
        return 'fa-check-circle';
      }
      case 18: {
        return 'fa-sync';
      }
      case 19: {
        return 'fa-save';
      }
      case 20: {
        return 'fa-search';
      }
      case 21: {
        return 'fa-cog';
      }
      case 22: {
        return 'fa-sort-amount-up-alt';
      }
      case 23: {
        return 'fa-sort-amount-down';
      }
      case 24: {
        return 'fa-undo';
      }
      case 25: {
        return 'fa-user';
      }
      case 26: {
        return 'fa-id-card';
      }
      case 27: {
        return 'fa-user-check';
      }
      case 28: {
        return 'fa-ox-check';
      }
      case 29: {
        return 'fa-truck-loading';
      }
      case 30: {
        return 'fa-check';
      }
      case 31: {
        return 'fa-download';
      }
      case 32: {
        return 'fa-upload';
      }
      case 33: {
        return 'fa-heart';
      }
      case 34: {
        return 'fa-heart';
      }
      case 35: {
        return 'fa-link';
      }
      case 36: {
        return 'fa-link';
      }
      case 37: {
        return 'fa-unlink';
      }
      case 38: {
        return 'fa-star';
      }
      case 39: {
        return '';
      }
      case 40: {
        return '';
      }
      case 41: {
        return '';
      }
      case 42: {
        return 'fa-file-plus';
      }
      case 43: {
        return 'fa-hourglass-half';
      }
      case 44: {
        return 'fa-clipboard-list';
      }
      case 45: {
        return 'fa-sort-amount-up-alt';
      }
      case 46: {
        return 'fa-sort-amount-down';
      }
      case 47: {
        return 'fa-star';
      }
      case 48: {
        return 'fa-star';
      }
      case 49: {
        return 'fa-star-half';
      }
      case 50: {
        return 'fa-star-half-alt';
      }
      case 51: {
        return 'fa-thumbs-down';
      }
      case 52: {
        return 'fa-thumbs-up';
      }
      case 53: {
        return 'fa-barcode-read';
      }
      case 54: {
        return 'fa-user';
      }
      case 55: {
        return 'fa-users';
      }
      case 56: {
        return 'fa-box';
      }
      case 57: {
        return 'fa-edit';
      }
      case 58: {
        return 'fa-pencil';
      }
      case 59: {
        return 'fa-box-check';
      }
      case 60: {
        return 'fa-clipboard-check';
      }
      case 61: {
        return 'fa-spinner';
      }
      case 62: {
        return 'fa-times-circle';
      }
      case 63: {
        return 'fa-ban';
      }
      case 64: {
        return 'fa-tachometer-alt-slowest';
      }
      case 65: {
        return 'fa-tachometer-alt-slow';
      }
      case 66: {
        return 'fa-tachometer-alt-average';
      }
      case 67: {
        return 'fa-tachometer-alt-fast';
      }
      case 68: {
        return 'fa-hand-holding-box';
      }
      case 69: {
        return 'fa-truck';
      }
      case 70: {
        return 'fa-pause';
      }
      case 71: {
        return 'fa-play';
      }
      case 72: {
        return 'fa-play';
      }
      case 73: {
        return 'fa-play';
      }
      case 74: {
        return 'fa-filter';
      }
      case 75: {
        return '';
      }
      case 76: {
        return 'fa-chevron-double-down';
      }
      case 77: {
        return '';
      }
      case 78: {
        return 'fa-history';
      }
      case 79: {
        return 'fa-inventory';
      }
      case 80: {
        return '';
      }
      case 81: {
        return 'fa-inbox-in';
      }
      case 82: {
        return 'fa-bars';
      }
      case 83: {
        return 'fa-shopping-basket';
      }
      case 84: {
        return 'fa-info-circle';
      }
      case 85: {
        return 'fa-circle';
      }
      case 86: {
        return 'fa-camera';
      }
      case 87: {
        return 'fa-binoculars';
      }
      case 88: {
        return 'fa-ellipsis-v';
      }
      case 89: {
        return 'fa-times';
      }
      default: {
        return this.getIconDecimalToHex(iconId);
      }
      // if (!!iconId)
      // {
      //   return `${iconId.toString(16)}`;
      // }
      // return;
    }
  }
}
