
export class ActionURI {

  metaUri: string;
  dataUri: string;
  dataUriArgs: string[];
  editUri: string;
  editUriArgs: string[];
  singleItemUri: string;
  singleItemUriArgs: string[];
  name: string;
  loadAtStart: boolean;
  originalDataUri: string;
}
