import { ItemTextModel } from '../viewControls/ItemTextModel';
import { ItemStatusModel } from '../viewControls/ItemStatusModel';
import { IconModel } from '../IconArgument';
import { CardItemType } from '../Enums';
import { ICardModel } from './ICardModel';

export class StatusCardModel implements ICardModel {
    cardItemType: CardItemType;
    title: ItemTextModel;
    detail1: ItemTextModel;
    detail2: ItemTextModel;
    detail3: ItemTextModel;
    countField: ItemTextModel;
    status1: ItemStatusModel;
    status2: ItemStatusModel;
    status3: ItemStatusModel;
    button1: ItemStatusModel;
    button2: ItemStatusModel;
    button3: ItemStatusModel;
    cardColour: IconModel;
}
