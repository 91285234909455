export interface Project {
    id: string;
    name: string;
    startDate?: Date;
    events: any[] 
}

export interface IGanttOptions {
    scale?: IScale;
    zooming?: string;
}

export interface IScale {
    start?: Date;
    end?: Date;
}

export interface IBarStyle {
    status: string;
    backgroundColor: string;
    border: string;
    progressBackgroundColor: string;
}

export enum Zooming {
    hours,
    days
}