import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ColourPalleteService {

    constructor(private httpClient: HttpClient) {
    }

    public getColourPallete(): Observable<Array<string>> {

        // var colorPallete = [
        //     '#19ACE3',
        //     '#46B872',
        //     '#EF4D6E',
        //     '#FDC159',
        //     '#9C46B8',
        //     '#B87846',
        //     '#5B6770',
        // ];

        return this.httpClient.get(`https://ftwebdev01.azurewebsites.net/Qmolo-PrimaryColours.txt`) as Observable<Array<string>>;
    }
}