import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DetailSection } from 'src/app/models/ScreenDetail';
import { UpdateDetailItem } from 'src/app/models/viewGroupHelpers/UpdateDetailItem';
import { ViewType } from 'src/app/models/Enums';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { AutoCompleteDropdownComponent } from '../../dropdowns/auto-complete-dropdown/auto-complete-dropdown.component';
import { Utilities } from 'src/app/services/utilities/utilities';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { ToastrService } from 'ngx-toastr';
import { ThingService } from 'src/app/services/thing/thing.service';
import { MatDialog } from '@angular/material';
import { ActionBuilder } from 'src/app/services/action/action-builder';

@Component({
  selector: 'app-composite-items-selection',
  templateUrl: './composite-items-selection.component.html',
  styleUrls: ['./composite-items-selection.component.scss']
})
export class CompositeItemsSelectionComponent implements OnInit {

  @Input() compositeItemsTemplate: DetailSection;
  @Input() dataObjArray: any[];
  @Input() newObjectDefaults: any[];
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() uriCollections: UriCollection[];
  @Output() compositeAdd = new EventEmitter();

  _dropdownField: UpdateDetailItem;
  _numberPickerField: UpdateDetailItem;
  dataObj: {};

  @ViewChild('dropdown') public dropdown: AutoCompleteDropdownComponent;
  addNewObjectDefaults: any;
  _addNewItemButtonField: any;

  constructor(public toastr: ToastrService, private thingService: ThingService, private dialog: MatDialog) { }

  ngOnInit() {
    if (!this.dataObjArray && this.compositeItemsTemplate.templateDataKey) {
      const uc = this.uriCollections.find(u => u.name === this.compositeItemsTemplate.templateDataKey);
      if (uc) {
        this.dataObjArray = uc.dataValues;
      } else {
        this.dataObjArray = [];
      }
    }
    this.dataObjArray = this.dataObjArray ? this.dataObjArray : [];

    this.dataObj = {};
    if (this.newObjectDefaults && this.newObjectDefaults[this.compositeItemsTemplate.templateDataKey]) {

      this.dataObj = Utilities.generateRowFromDefaults(this.newObjectDefaults[this.compositeItemsTemplate.templateDataKey], null,
        null, this.compositeItemsTemplate.templateDataKey, this.uriCollections);
    }

    if (this.compositeItemsTemplate.onAddAction) {
      const add = this.compositeItemsTemplate.onAddAction[0].actionArgument as UpdateDataAction;
      this.addNewObjectDefaults = add.newObjectDefaults;
    }
  }

  get dropdownField(): UpdateDetailItem {
    if (this._dropdownField) { return this._dropdownField; }

    this._dropdownField = this.compositeItemsTemplate.columnFields
      .find(d => d.viewType === ViewType.Dropdown);

    return this._dropdownField;
  }

  get numberPickerField(): UpdateDetailItem {
    if (this._numberPickerField) { return this._numberPickerField; }

    this._numberPickerField = this.compositeItemsTemplate.columnFields
      .find(d => d.viewType === ViewType.NumberPicker);
    return this._numberPickerField;
  }

  get addNewItemButtonField(): UpdateDetailItem {
    if (this._addNewItemButtonField) { return this._addNewItemButtonField; }

    this._addNewItemButtonField = this.compositeItemsTemplate.columnFields
      .find(d => d.viewType === ViewType.TextImage);
    return this._addNewItemButtonField;
  }

  addSelection(item: any) {
    if (item) {
      this.add(item);
    }
  }

  add(item?: any) {

    if (this.addNewObjectDefaults) {
      const dropdownValue = item ? item : this.dropdown.selectedItem;
      const newVals = Utilities.createItemFromObjectDefaults(this.addNewObjectDefaults, {}, dropdownValue, null, null);
      // merges two objects:
      this.dataObj = { ...this.dataObj, ...newVals };
    }
    this.dataObjArray.push(Object.assign({}, this.dataObj));

    this.dataObj = {};
    if (this.newObjectDefaults && this.newObjectDefaults[this.compositeItemsTemplate.templateDataKey]) {

      this.dataObj = Utilities.generateRowFromDefaults(this.newObjectDefaults[this.compositeItemsTemplate.templateDataKey], null,
        null, this.compositeItemsTemplate.templateDataKey, this.uriCollections);
    }

    this.compositeAdd.emit(null);
  }

  onClick(action: any) {

    const completionEvent = new EventEmitter<ActionCompletedEvent>();
    completionEvent.subscribe((ev: ActionCompletedEvent) => {
      if (ev.isComplete) {
        this.add(ev.updatedObject);
      }
    });
    const viewItemEvent = new EventEmitter<ViewDetailEvent>();

    const ac = new ActionBuilder(action, null, null, null, null, completionEvent, viewItemEvent,
      this.thingService, this.toastr, this.dialog);
    ac.PerformAction();
  }

  getBackgroundColor(errorText: string) {
    if (errorText) {
      return { 'background-color': '#fffcf9' };
    } else {
      return { 'background-color': 'transparent' };
    }
  }

  public lookupDataUri(itemSource: string) {

    if (!itemSource) { return; }

    itemSource = itemSource.substring(itemSource.lastIndexOf('.') + 1);

    if (!this.uriCollections) { return; }

    const uriCollection = this.uriCollections.find(u => u.name === itemSource);
    if (uriCollection) {
      const uris = this.uriCollections.find(u => u.name === itemSource).uris;
      if (uris) {
        return uris.dataUri;
      }
    } else {
      Utilities.log(`Can't find uri collection for ${itemSource}`);
    }
    return null;
  }
}
