import { Component, OnInit, Input, ViewChild, AfterViewInit, forwardRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { SelectedItem } from '../../../../models/SelectedItem';
import { DataDetail } from '../../../../models/DataDetail';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => VirtualScrollDropdownComponent),
  multi: true
};

@Component({
  selector: 'app-virtual-scroll-dropdown',
  templateUrl: './virtual-scroll-dropdown.component.html',
  styleUrls: ['./virtual-scroll-dropdown.component.scss', './../dropdown-style.scss']
})
export class VirtualScrollDropdownComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() name: string;
  @Input() selectedItem: SelectedItem;
  @Input() dataUri: string;
  @Input() bindValue: string;
  @Input() labelName: string;
  @Input() multiple = false;
  @Input() required = false;
  @Input() displayString: string;
  @Input() errorText: string;
  @Input() iconProperty: string;
  @Input() colourProperty: string;
  @Input() placeholder: string;
  _selectedValue: number;
  isDisabled: boolean;
  @Input()
  set displayStringArgs(v: string[]) {
    this.propertyDisplayArgs = v ? v.map(str => Utilities.getLastEntry(str)) : [];
    this._displayStringArgs = v;
  }
  get displayStringArgs(): string[] {
    return this._displayStringArgs;
  }

  set value(val: any) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){

    this._selectedValue = parseInt(val as any, 10);
    this.onChange(val);
    this.onTouched(val);

    if (this.dropdown.items == null && this.value) {

      this.loading = true;
      const select = this.getSelectString();
      this.thingService.getDataDetailWithHeader(this.dataUri, this.pageIndex, this.pageSize, null, null, null, '', select, true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
    }
  }
  get value(): any {
    return this._selectedValue;
  }

  propertyDisplayArgs: string[];
  _displayStringArgs: string[];
  selectString: any;
  totalRecordCount: number;
  returnRecordCount: number;
  loading = true;
  dataItems: any[];
  pageIndex = 0;
  pageSize = 10;

  @ViewChild('dropdown') public dropdown: NgSelectComponent;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.loading = true;
    const select = this.getSelectString();
    this.thingService.getDataDetailWithHeader(this.dataUri, this.pageIndex, this.pageSize, null, null, null, '', select, true)
      .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
  }

  writeValue(value: any): void {
    if (value !== this.value) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  onChange: any = () => { };
  onTouched: any = () => { };

  private getSelectString() {
    if (!this.selectString) {
      const props = Object.assign([{}], this.propertyDisplayArgs);
      if (this.colourProperty) {
        props.push(Utilities.getLastEntry(this.colourProperty));
      }
      if (this.iconProperty) {
        props.push(Utilities.getLastEntry(this.iconProperty));
      }
      this.selectString = props.join(',');
      if (this.propertyDisplayArgs.findIndex(s => s === this.bindValue) < 0) {
        this.selectString = this.selectString + `,${Utilities.getLastEntry(this.bindValue)}`;
      }
    }
    return this.selectString;
  }

  private onGetDataSuccessful(dataDetail: DataDetail) {

    this.loading = false;
    this.totalRecordCount = dataDetail.totalRecordCount;

    if (this.pageIndex === 0) {
      this.dataItems = dataDetail.dataItems;
      this.returnRecordCount = dataDetail.returnRecordCount;
    } else {

      this.dataItems = this.dataItems.concat(dataDetail.dataItems);
      this.returnRecordCount += dataDetail.returnRecordCount;

    }

  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
    this.loading = false;
  }

  fetchMore(event) {

    if (this.returnRecordCount < this.totalRecordCount) {

      const select = this.getSelectString();

      this.pageIndex++;
      this.loading = true;

      this.thingService.getDataDetailWithHeader(this.dataUri, this.pageIndex, this.pageSize, null, null, null, '', select, true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));

    } else {
      return false;
    }
  }

  getDisplayText(item: any) {
    if (item) {
      return Utilities.parseArgumentsFromData(this.displayString, this.displayStringArgs, item);
    }
  }

  public clear() {
    this.selectedItem = new SelectedItem('', []);
    this.dataItems = [];
    this.returnRecordCount = 0;
    this.totalRecordCount = 0;
  }

  public focus() {
    this.dropdown.focus();
  }

  hasIcon() {
    return this.iconProperty;
  }

  hasColor() {
    return this.colourProperty;
  }

  getIconId(item: any) {
    if (item) {
      if (this.iconProperty) {
        return Utilities.parseArgumentsFromData(null, [this.iconProperty], item);
      }
    }
  }

  getColor(item: any): string {

    if (this.colourProperty) {
      const path = Utilities.getLastEntry(this.colourProperty);

      const color: string = item[path];
      if (color && color.length > 0) {
        if (!color.startsWith('#')) {
          return '#' + color;
        } else {
          return color;
        }
      }

      return '';
    }

    return '';
  }
}
