import { Action } from "src/app/models/actions/Action";
import { FTThingDetail } from "src/app/models/data/FTThingDetail";
import { DataPath } from "src/app/models/dataPathModels/DataPath";
import { ActionIdentifier, DataType, FilterType, UIDataType, ViewType } from "src/app/models/Enums";
import { DetailSection } from "src/app/models/ScreenDetail";
import { UriCollection } from "src/app/models/screenModels/UriCollection";
import { UpdateDetailItem } from "src/app/models/viewGroupHelpers/UpdateDetailItem";
import { DataUtils } from "../data-utils/data-utils";
import { FlowService } from "../flow/flow.service";
import { Utilities } from "./utilities";
import { ToastrService } from 'ngx-toastr';
import { ScreenColumn } from "src/app/models/ScreenColumn";
import { DataPathHelper } from "src/app/helpers/data-path-helper";
import { ConfigUtilitiesService } from "./config-utilities.service";

export class DetailUtilities{

    generateScreenDetails(details: ScreenColumn[], uriCollections: UriCollection[], screenParameters: any, toastr: ToastrService) {

        const columnFields: UpdateDetailItem[] = [];
        for (const formDetail of [].sort.call(details,(a, b) => a.sequence - b.sequence)) {
          if (formDetail.isVisibleCondition) {
            const visible = Utilities.evaluateWithUriCollection(formDetail.isVisibleCondition, uriCollections, screenParameters);
            if (!visible) {
              continue;
            }
          }
          // if (formDetail.isVisibleCondition && !Utilities.evaluateWithUriCollection(formDetail.isVisibleCondition, uriCollections)) {
          //   return;
          // }
    
          let dataType = UIDataType.Unknown;
          let metadata: FTThingDetail = null;
          let itemSource = '';
          let itemSourceItems = [];
          let valueName = '';
          let displayName = '';
          let filterType = FilterType.Default;
          let mandatory = false;
          let minValue: any;
          let maxValue: any;
          let displayString = '';
          let displayStringArgs = [];
          let invertCheckbox = false;
          let checkedCondition = '';
          filterType = formDetail.filterType ? formDetail.filterType : FilterType.Default;
          
          let publishSubscribeAction: any;
          if(!!formDetail.publishSubscribeAction){
            publishSubscribeAction = JSON.parse(formDetail.publishSubscribeAction);
          }
          else{
            publishSubscribeAction = null;
          }

          // TODO this all needs refactoring as per task 1520 as we should be using the properties as they arrive from the UI Definitions
          if (formDetail.viewType === ViewType.Checkbox) {
            if(formDetail.isCheckedCondition.startsWith("!")){
              checkedCondition = formDetail.isCheckedCondition.substring(1);
              invertCheckbox = true;
            }
            else{
               checkedCondition = formDetail.isCheckedCondition;
            }
            const dataPath = new DataPath(checkedCondition);
            var propertyName;
            if(DataPathHelper.isCalcPath(formDetail.isCheckedCondition)){
              propertyName = formDetail.isCheckedCondition;
            }
            else{
              propertyName = dataPath ? dataPath.propertyName : null;
            }

    
            columnFields.push({
              columnHeader: formDetail.columnHeader, tag: formDetail.tag,
              mandatory: mandatory, dataType: dataType, viewType: ViewType.Checkbox,
              isReadOnly: formDetail.isReadOnly, action: null, isEnabledCondition: formDetail.isEnabledCondition,
              primaryFileId: null, parentObjectIID: null, objectType: null,
              prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
    
              propertyName: propertyName, objectName: dataPath ? dataPath.rootObjectName : null,
              childObjectName: dataPath.isValid ? dataPath.childObjectNames[0] : null,
    
              displayString: null, displayStringArgs: null,
              itemSource: null, itemSourceItems: null, valueName: null, displayName: null, filterType: null,
              minValue: null, maxValue: null, icon: null,
              isDate: false, isTime: false,
              referenceTo: null,
              publishSubscribeAction: publishSubscribeAction,
              secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,

              invertCheckbox: invertCheckbox
            });
          } else if (formDetail.viewType === ViewType.ColorPicker) {
    
            const dataPath = new DataPath(formDetail.colour);
            var propertyName;
            if(DataPathHelper.isCalcPath(formDetail.colour)){
              propertyName = formDetail.colour;
            }
            else{
              propertyName = dataPath ? dataPath.propertyName : null;
            }
    
            columnFields.push({
              columnHeader: formDetail.columnHeader, tag: formDetail.tag,
              mandatory: mandatory, dataType: dataType, viewType: ViewType.ColorPicker,
              isReadOnly: formDetail.isReadOnly, action: null, isEnabledCondition: formDetail.isEnabledCondition,
              primaryFileId: null, parentObjectIID: null, objectType: null,
              prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
    
              propertyName: propertyName, objectName: dataPath ? dataPath.rootObjectName : null,
              childObjectName: dataPath ? dataPath.childObjectNames[0] : null,
    
              displayString: null, displayStringArgs: null,
              itemSource: null, itemSourceItems: null, valueName: null, displayName: null, filterType: null,
              minValue: null, maxValue: null, icon: null,
              isDate: false, isTime: false,
              referenceTo: null,
              publishSubscribeAction: publishSubscribeAction,
              secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,
            });
          } 
          else if (formDetail.viewType === ViewType.IconPicker) {
    
            const dataPath = new DataPath(formDetail.iconArgument);
            var propertyName;
            if(DataPathHelper.isCalcPath(formDetail.iconArgument)){
              propertyName = formDetail.colour;
            }
            else{
              propertyName = dataPath ? dataPath.propertyName : null;
            }
    
            columnFields.push({
              columnHeader: formDetail.columnHeader, tag: formDetail.tag,
              mandatory: mandatory, dataType: dataType, viewType: ViewType.IconPicker,
              isReadOnly: formDetail.isReadOnly, action: null, isEnabledCondition: formDetail.isEnabledCondition,
              primaryFileId: null, parentObjectIID: null, objectType: null,
              prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
    
              propertyName: propertyName, objectName: dataPath ? dataPath.rootObjectName : null,
              childObjectName: dataPath ? dataPath.childObjectNames[0] : null,
    
              displayString: null, displayStringArgs: null,
              itemSource: null, itemSourceItems: null, valueName: null, displayName: null, filterType: null,
              minValue: null, maxValue: null, icon: null,
              isDate: false, isTime: false,
              referenceTo: null,
              publishSubscribeAction: publishSubscribeAction,
              secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,
            });
          }
          else if (formDetail.viewType === ViewType.Text || formDetail.viewType === ViewType.MultilineText) {
    
            let dataPath: DataPath;
            if (formDetail.text.argumentIds) {
              dataPath = new DataPath(formDetail.text.argumentIds[0]);
              var propertyName;
              if(DataPathHelper.isCalcPath(formDetail.text.text)){
                propertyName = Utilities.stringFormat(formDetail.text.text, formDetail.text.argumentIds);
              }
              else{
                propertyName = dataPath ? dataPath.propertyName : null;
              }
            }
            
            if(dataPath){
              if(!dataPath.childObjectNames || dataPath.childObjectNames.length == 0){
                var uriCollection = Utilities.getUriCollection(dataPath.rootObjectName, uriCollections);
                metadata = uriCollection.dataMetadata.find(md => md.name.toLowerCase() === dataPath.propertyName.toLowerCase());
                dataType = DataUtils.dataTypeToUIDataType(metadata.dataType, metadata.referenceTo);
              }
              else{
                var uriCollection = Utilities.getUriCollection(dataPath.rootObjectName, uriCollections);
                var parentMetadata = uriCollection.dataMetadata.find(md => md.name.toLowerCase() === dataPath.childObjectNames[0].toLowerCase());
                dataPath.childObjectNames.forEach(childObjectName => {
                  if(childObjectName != dataPath.childObjectNames[0])
                    parentMetadata = uriCollection.dataMetadata.find(md => md.name.toLowerCase() === childObjectName.toLowerCase());
                });
                metadata = parentMetadata.sourceDataTypeFTThing.find(md => md.name.toLowerCase() === dataPath.propertyName.toLowerCase());
                dataType = DataUtils.dataTypeToUIDataType(metadata.dataType, metadata.referenceTo);
              }
            }
            
            if(metadata){
              columnFields.push({
                columnHeader: formDetail.columnHeader, tag: formDetail.tag,
                mandatory: mandatory, dataType: dataType, viewType: formDetail.viewType,
                isReadOnly: true, action: null, isEnabledCondition: formDetail.isEnabledCondition,
                prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
        
                propertyName: propertyName, objectName: dataPath ? dataPath.rootObjectName : null,
                childObjectName: dataPath ? dataPath.childObjectNames[0] : null,
        
                primaryFileId: null, parentObjectIID: null, objectType: null,
                displayString: formDetail.text.text, displayStringArgs: formDetail.text.argumentIds,
                itemSource: null, itemSourceItems: null, valueName: null, displayName: null, filterType: null,
                minValue: null, maxValue: null, icon: null,
                
                isDate: false, isTime: false,
                referenceTo: metadata.referenceTo,
                publishSubscribeAction: publishSubscribeAction,
                secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,
              });
            }
          } else if (formDetail.viewType === ViewType.FileUpload) {
    
            const dataPath = new DataPath(formDetail.primaryFileId);
            if (!dataPath) {
              return;
            }
    
            columnFields.push({
              columnHeader: formDetail.columnHeader, tag: formDetail.tag, isEnabledCondition: formDetail.isEnabledCondition,
              mandatory: mandatory, dataType: dataType, viewType: ViewType.FileUpload,
              isReadOnly: formDetail.isReadOnly, action: formDetail.action ? formDetail.action : null,
              primaryFileId: formDetail.primaryFileId, parentObjectIID: formDetail.parentObjectIId, objectType: formDetail.parentObjectType,
              prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
    
              propertyName: dataPath.propertyName, objectName: dataPath.rootObjectName,
              childObjectName: dataPath.childObjectNames ? dataPath.childObjectNames[0] : null,
    
              displayString: null, displayStringArgs: null,
              itemSource: null, itemSourceItems: null, valueName: null, displayName: null, filterType: null,
              minValue: null, maxValue: null, icon: null,
              isDate: false, isTime: false,
              referenceTo: null,
              publishSubscribeAction: publishSubscribeAction,
    
              filter: formDetail.filter,
              secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,
            });
    
          } else if (formDetail.dataPath || formDetail.viewType === ViewType.Dropdown) {
            
            if(DataPathHelper.isCalcPath(formDetail.dataPath)){
              columnFields.push({
                columnHeader: formDetail.columnHeader, tag: formDetail.tag,
                itemSource: itemSource, itemSourceItems: itemSourceItems, valueName: valueName, displayName: displayName, filterType: filterType,
                prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
                nestBy: formDetail.nestBy, orderBy: formDetail.orderBy, defaultValue: formDetail.defaultValue,
    
                propertyName: formDetail.dataPath, objectName: null, childObjectName: null, minValue: minValue, maxValue: maxValue,
                mandatory: mandatory, dataType: dataType, viewType: formDetail.viewType, icon: null, colour: null,
                isDate: formDetail.isDateEnabled, isTime: formDetail.isTimeEnabled, isReadOnly: formDetail.isReadOnly, action: formDetail.action ? formDetail.action : null,
                displayString: displayString, displayStringArgs: displayStringArgs, isEnabledCondition: formDetail.isEnabledCondition,
                referenceTo: null, objectType: null, fixedItems: formDetail.fixedItems,
                publishSubscribeAction: publishSubscribeAction,
                secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,
              });
              continue;
            }

            var dataPath = null;
            var secondDataPath = null;
            if(formDetail.dataPath.includes(',')){
              var paths = formDetail.dataPath.split(',');
              dataPath = new DataPath(paths[0] ? paths[0] : formDetail.iconArgument);
              secondDataPath = new DataPath(paths[1]);
              // formDetail.dataPath = paths[0];
            }
            else{
              dataPath = new DataPath(formDetail.dataPath ? formDetail.dataPath : formDetail.iconArgument);
            }
            if (!dataPath) {
              continue;
            }
            var propertyName;
            if(DataPathHelper.isCalcPath(formDetail.iconArgument)){
              propertyName = formDetail.colour;
            }
            else{
              propertyName = dataPath ? dataPath.propertyName : null;
            }
    
            const objectUC = uriCollections.find(c => c.name === dataPath.rootObjectName);
            if (!objectUC) {
              toastr.error(`No uri collection found named ${dataPath.rootObjectName}`);
              continue;
            }
            
            if(formDetail.dataPath.includes(',')){
              var paths = formDetail.dataPath.split(',');
              const md = DataUtils.getMetadatumAndDataPath(paths[0] ? paths[0] : formDetail.iconArgument, objectUC.dataMetadata);
              if (md) {
                metadata = md[0];
              }
            }
            else{
              const md = DataUtils.getMetadatumAndDataPath(formDetail.dataPath ? formDetail.dataPath : formDetail.iconArgument, objectUC.dataMetadata);
              if (md) {
                metadata = md[0];
              }
            }
            
    
            if (!metadata) {
              toastr.error(`No update detail metadata for columnId ${dataPath.propertyName}`);
              Utilities.log2('UpdateDetail', `No update detail metadata for columnId ${dataPath.propertyName}`, 'warn');
              if(formDetail.viewType === ViewType.Dropdown){
                continue;
              }
            }
    
            // TODO sometimes need this to create an array
            if (dataPath.childObjectNames && dataPath.childObjectNames.length > 0) {
    
              if (!uriCollections.find(u => u.name === dataPath.rootObjectName).dataValue[dataPath.childObjectNames[0]]) {
                const objmd = uriCollections.find(u => u.name === dataPath.rootObjectName).dataMetadata.find(v => v.javaScriptName === dataPath.childObjectNames[0]);
    
                if (objmd.dataType === DataType.InlineObject) {
                  uriCollections.find(u => u.name === dataPath.rootObjectName).dataValue[dataPath.childObjectNames[0]] = {};
                } else if (objmd.dataType === DataType.InlineArray) {
                  uriCollections.find(u => u.name === dataPath.rootObjectName).dataValue[dataPath.childObjectNames[0]] = [{}];
                }
              }
            }
    
            if (metadata && metadata.validationRule) {
              const val = JSON.parse(metadata.validationRule);
    
              mandatory = val[0].mandatory;
              minValue = val[0].minValue;
              maxValue = val[0].maxValue;
            }
            
            if(metadata){
              dataType = DataUtils.dataTypeToUIDataType(metadata.dataType, metadata.referenceTo);
            }
            if (dataType === UIDataType.Unknown) {
              // console.log(metadata);
              // console.log(formDetail);
            }
    
            // Dropdown form fields
            if (formDetail.viewType === ViewType.Dropdown) {
    
              if (metadata.referenceTo) {
    
                if (dataType === UIDataType.Lookup) {
                  const lookupName = metadata.referenceTo.split('.')[1];
                  itemSourceItems = FlowService.getLookupDetails(lookupName);
                  valueName = 'value';
                  displayName = 'text';
                }
    
                if (dataType === UIDataType.FlowStatus) {
    
                  const flowName = metadata.referenceTo.split('.')[1];
    
                  valueName = 'ftFlowStatusID';
                  displayName = 'text';
    
                  // Convert Flow Id to object reference for dropdown
                  const val = uriCollections.find(u => u.name === dataPath.rootObjectName).dataValue[dataPath.propertyName] as number;
                  const currentFlowState = FlowService.getFlowStatus(val, metadata.referenceTo);
    
                  // if (filterType > FilterType.Default) { val = currentFlowState; }
    
                  // only show flow statuses that are user settable
                  if (currentFlowState) {
                    itemSourceItems = FlowService.getFlowStatuses(currentFlowState.ftFlowID, currentFlowState.ftFlowStatusID).filter(f => f.isUserSettable);
                  } else {
                    itemSourceItems = FlowService.getFlowStatusesByFlowName(flowName).filter(f => f.isUserSettable);
                  }
                }
              }
            }
    
            let iconId = formDetail.icon ? formDetail.icon.iconArgument : null;
            let colourId = formDetail.icon ? formDetail.icon.colour : null;
            if (formDetail.itemSource) {
    
              itemSource = formDetail.itemSource;

              if(itemSource.indexOf(',') > -1){
                var items = itemSource.split(',');
                valueName = 'value';
                displayName = 'text';
                itemSourceItems = items.map(pair => {
                  var item = {};
                  var parts = pair.split(":");
                  item["value"] = parts[0];
                  item["text"] = parts[1];
                  return item;
                })
              }
              else{
                valueName = Utilities.getLastEntry(dataPath.propertyName);
              }
    
              if (formDetail.template) {
    
                // Assuming just one id with no text
                const arg = formDetail.template[0].text.argumentIds[0];
                displayName = arg.substring(arg.lastIndexOf('.') + 1);
    
                displayString = formDetail.template[0].text.text;
                displayStringArgs = formDetail.template[0].text.argumentIds;
    
                if (formDetail.template.length > 1 && formDetail.template[1].viewType === ViewType.Image && formDetail.template[1].icon) {
                  iconId = formDetail.template[1].icon.iconArgument;
                  colourId = formDetail.template[1].icon.colour;
                }
              }
            }
    
            if (metadata) {
    
              const valueIndex = itemSource.indexOf('|');
              if (valueIndex > -1) {
                valueName = Utilities.getLastEntry(itemSource.substring(valueIndex + 1));
                itemSource = itemSource.substring(0, valueIndex);
              }
              
              var secondaryPropertyName = null;
              var secondaryObjectName = null;
              var secondaryChildObjectName = null;
              if(secondDataPath){
                secondaryPropertyName = secondDataPath.propertyName;
                secondaryObjectName = secondDataPath.rootObjectName;
                secondaryChildObjectName = secondDataPath.childObjectNames[0];

              }

              columnFields.push({
                columnHeader: formDetail.columnHeader, tag: formDetail.tag,
                itemSource: itemSource, itemSourceItems: itemSourceItems, valueName: valueName, displayName: displayName, filterType: filterType,
                prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
                nestBy: formDetail.nestBy, orderBy: formDetail.orderBy, defaultValue: formDetail.defaultValue,
    
                propertyName: dataPath.propertyName, objectName: dataPath.rootObjectName, childObjectName: dataPath.childObjectNames[0],
                secondaryPropertyName: secondaryPropertyName, secondaryObjectName: secondaryObjectName, secondaryChildObjectName: secondaryChildObjectName,
                minValue: minValue, maxValue: maxValue,
                mandatory: mandatory, dataType: dataType, viewType: formDetail.viewType, icon: iconId, colour: colourId,
                isDate: formDetail.isDateEnabled, isTime: formDetail.isTimeEnabled, isReadOnly: formDetail.isReadOnly, action: formDetail.action ? formDetail.action : null,
                displayString: displayString, displayStringArgs: displayStringArgs, isEnabledCondition: formDetail.isEnabledCondition,
                referenceTo: null, objectType: null, fixedItems: formDetail.fixedItems,
                publishSubscribeAction: publishSubscribeAction
              });
            }
          } else if (formDetail.icon && formDetail.icon.iconArgument && formDetail.icon.iconArgument.indexOf('.') >= 0) {
    
            const dataPath = new DataPath(formDetail.icon.iconArgument);
            if (!dataPath) {
              return;
            }
    
            const objectUC = uriCollections.find(c => c.name === dataPath.rootObjectName);
            if (!objectUC) {
              toastr.error(`No uri collection found named ${dataPath.rootObjectName}`);
              continue;
            }
            const md = DataUtils.getMetadatumAndDataPath(formDetail.icon.iconArgument, objectUC.dataMetadata);
            if (!md) { continue; }
            metadata = md[0];
            if (!metadata) { continue; }
    
            if (dataPath.childObjectNames && dataPath.childObjectNames.length > 0) {
              // if (!this.row[dataPath.rootObjectName][dataPath.childObjectNames[0]]) {
              //   this.row[dataPath.rootObjectName][dataPath.childObjectNames[0]] = {};
              // }
              if (!uriCollections.find(u => u.name === dataPath.rootObjectName).dataValue[dataPath.childObjectNames[0]]) {
                uriCollections.find(u => u.name === dataPath.rootObjectName).dataValue[dataPath.childObjectNames[0]] = {};
              }
            }
    
            columnFields.push({
              columnHeader: formDetail.columnHeader, tag: formDetail.tag, referenceTo: metadata.referenceTo,
              prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
    
              propertyName: dataPath.propertyName, objectName: dataPath.rootObjectName, childObjectName: dataPath.childObjectNames[0],
              minValue: minValue, maxValue: maxValue, mandatory: mandatory,
              dataType: (metadata && metadata.referenceTo ? -4 : -1), viewType: formDetail.viewType,
              icon: (formDetail.icon ? formDetail.icon.iconArgument : null),
              isDate: formDetail.isDateEnabled, isTime: formDetail.isTimeEnabled, isReadOnly: formDetail.isReadOnly,
              action: formDetail.action ? formDetail.action : null, displayName: null, displayString: null, displayStringArgs: null, filterType: FilterType.Default,
              itemSource: null, itemSourceItems: null, valueName: null, objectType: null, isEnabledCondition: formDetail.isEnabledCondition,
              publishSubscribeAction: publishSubscribeAction,
              secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,
            });
          } else {
    
            const columnName = formDetail.sequence.toString();
    
            let action: Action[] = null;
    
            if (formDetail.action && formDetail.action[0].action === ActionIdentifier.LaunchScreen) {
              action = formDetail.action;
            }
    
            columnFields.push({
              columnHeader: formDetail.columnHeader, tag: formDetail.tag, action: formDetail.action ? formDetail.action : null,
              prefix: formDetail.prefix, suffix: formDetail.suffix, hint: formDetail.hint,
              propertyName: columnName, objectName: '', childObjectName: '', mandatory: false,
              dataType: action ? -2 : -3, viewType: formDetail.viewType,
              icon: (formDetail.icon ? formDetail.icon.iconArgument : null),
              isDate: formDetail.isDateEnabled, isTime: formDetail.isTimeEnabled, isReadOnly: formDetail.isReadOnly,
              displayName: null, displayString: null, displayStringArgs: null, filterType: null, itemSource: null, itemSourceItems: null,
              maxValue: null, minValue: null, referenceTo: null, valueName: null, objectType: null, isEnabledCondition: formDetail.isEnabledCondition,
              minLines: formDetail.minLines, maxLines: formDetail.maxLines,
              publishSubscribeAction: publishSubscribeAction,
              secondaryPropertyName: null, secondaryObjectName: null, secondaryChildObjectName: null,
            });
          }
        }
    
        return columnFields;
      }
}