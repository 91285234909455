import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utilities } from 'src/app/services/utilities/utilities';
import { QuantityCardModel } from 'src/app/models/cardModels/QuantityCardModel';
import { UIDataType } from 'src/app/models/Enums';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';

@Component({
  selector: 'app-quantity-card',
  templateUrl: './quantity-card.component.html',
  styleUrls: ['./quantity-card.component.scss', './../card-style.scss']
})
export class QuantityCardComponent implements OnInit {

  public static cardHeight = 160;
  public QuantityCardComponent = QuantityCardComponent;

  @Input() cardTemplate: QuantityCardModel;
  @Input() dataItem: any;
  @Input() groupName: any;
  @Output() onAction = new EventEmitter<any>();
  @Input() hasContextMenu: boolean;
  @Input() metadata: FTThingDetail[];
  @Input() readOnly = false;

  public DataType = UIDataType;

  constructor() {
  }

  ngOnInit() {
    if (this.cardTemplate.numberPicker) {
      this.dataItem['tag-numberPicker'] = this.cardTemplate.numberPicker.defaultValue;
    }
  }

  propertyName(dataPath: string) {
    return Utilities.getLastEntry(dataPath);
  }


  public onActionClick(event, dataItem, cardItem) {
    this.onAction.emit({ event: event, dataItem: dataItem, action: this.cardTemplate[cardItem].action });
  }


  public isEnabled(row: any, elementName: string): boolean {

    if (this.readOnly) { return false; }

    const element = this.cardTemplate[elementName];
    if (element && element.isEnabledCondition && row) {
      return Utilities.evaluate(element.isEnabledCondition, row);
    }
    return true;
  }

  public isVisible(row: any, elementName: string): boolean {

    const element = this.cardTemplate[elementName];
    if (element && element.isVisibleCondition && row) {
      return Utilities.evaluate(element.isVisibleCondition, row);
    }
    return true;
  }

  public getDataType(elementName: string): UIDataType {

    if (this.metadata && this.getElementPropertyName(elementName)) {
      const m = this.metadata.find(m => m.javaScriptName === this.getElementPropertyName(elementName));
      if (m) {
        let dt = DataUtils.dataTypeToUIDataType(m.dataType, m.referenceTo);
        return dt;
      }
    }
    return UIDataType.Unknown;
  }
  private getElementPropertyName(elementName: string): string {
    if (this.cardTemplate[elementName] && this.cardTemplate[elementName].text) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].text.argumentIds[0]);
    } else if (this.cardTemplate[elementName] && this.cardTemplate[elementName].icon) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
    }
    return '';
  }

  getButtonClass(style: string) {
    if (style) {
      return style === 'qm-stroked-button' ? 'mat-stroked-button' : `${style} success`;
    } else {
      return 'mat-button';
    }
  }

  getValue(propertyPath: string) {
    if (propertyPath == null || propertyPath === '') {
      return '';
    }
    if (propertyPath.startsWith('data.')) {
      return Utilities.getData(propertyPath, this.dataItem);
    } else {
      return propertyPath;
    }
  }

  getCardColor(): string {

    if (!this.cardTemplate.cardColor) {
      return '';
    }
    const property = Utilities.getLastEntry(this.cardTemplate.cardColor.icon.colour);
    if (this.dataItem[property]) {

      if (this.dataItem[property].length > 0) {
        return `${this.dataItem[property]}`;
      }
    }
    return '';
  }

  getHint(elementName: string): string {

    if (!this.cardTemplate[elementName]) { return ''; }

    const path = this.cardTemplate[elementName]['hint'];
    return this.getValue(path);
  }

  // public getImage(elementName: string) {
  //   if (this.cardTemplate[elementName]) {
  //     const argId = Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
  //     const fileId = this.dataItem[argId];
  //     if (fileId) {
  //       return this.thingService.getFileUrl(fileId, 'small');
  //     }
  //   }
  // }

  // private getIcon(row: any, status: CardElement): string {

  //   if (status && status.icon && row) {
  //     return Utilities.evaluate(status.icon.iconArgument, row, Utilities.evaluate(status.isCheckedCondition, row, false));
  //   }

  //   return '';
  // }

  // private getColor(row: any): string {

  //   if (row['color']) {

  //     if (row['color'].length > 0)
  //       return `${row['color']}`;
  //   }

  //   return '';
  // }
}
