import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { FormTemplateItem } from '../../../../models/screenModels/TemplateItem';
import { FlowService } from 'src/app/services/flow/flow.service';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { ScreenColumn } from 'src/app/models/ScreenColumn';
import { PostResponse } from 'src/app/models/PostResponse';
import { ActionIdentifier, MenuType } from 'src/app/models/Enums';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { MatDialog } from '@angular/material';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';

@Component({
  selector: 'app-price-scheme-detail',
  templateUrl: './price-scheme-detail.component.html',
  styleUrls: ['./price-scheme-detail.component.scss']
})
export class PriceSchemeDetailComponent implements OnInit {

  @Input() screenDetail: ScreenDetail;
  @Input() columns: FTThingDetail[];
  @Input() priceScheme: any;
  @Input() priceSchemeDetails: any[];
  @Output() updateCompleted = new EventEmitter<boolean>();

  uriCollections: UriCollection[] = [];
  menuType = MenuType;
  addNew = false;
  priceSchemeDetailTemplateQty: FormTemplateItem;
  priceSchemeDetailTemplateDuration: FormTemplateItem;
  nameDisplayName: string;
  nameQtyMin: string;
  nameQtyMax: string;
  nameDurationMin: string;
  nameDurationMax: string;
  columnDisplayName: FTThingDetail;
  columnQtyMin: FTThingDetail;
  columnQtyMax: FTThingDetail;
  columnDurationMin: FTThingDetail;
  columnDurationMax: FTThingDetail;
  itemDisplayName: ScreenColumn;
  itemQtyMin: ScreenColumn;
  itemQtyMax: ScreenColumn;
  itemDurationMin: ScreenColumn;
  itemDurationMax: ScreenColumn;
  buttonNameAdd = 'Add';
  menuItems: import('../../../../models/data/MenuItem').MenuItem[];

  constructor(public toastr: ToastrService, private mThingService: ThingService, public dialog: MatDialog) { }

  ngOnInit() {

    this.priceSchemeDetailTemplateQty = this.screenDetail.priceTemplates.find(t => t.tag === 'priceSchemeDetailQty');
    this.priceSchemeDetailTemplateDuration = this.screenDetail.priceTemplates.find(t => t.tag === 'priceSchemeDetailDuration');

    this.menuItems = this.screenDetail.toolbarItems;

    // Setup data names
    this.itemDisplayName = this.getColumnItem('displayName');
    this.itemQtyMin = this.getColumnItem('qtyMin');
    this.itemQtyMax = this.getColumnItem('qtyMax');
    this.itemDurationMin = this.getColumnItem('durationMin');
    this.itemDurationMax = this.getColumnItem('durationMax');

    if (this.itemDisplayName) {
      this.nameDisplayName = Utilities.getLastEntry(this.itemDisplayName.dataPath);
    }
    if (this.itemQtyMin) {
      this.nameQtyMin = Utilities.getLastEntry(this.itemQtyMin.dataPath);
    }
    if (this.itemQtyMax) {
      this.nameQtyMax = Utilities.getLastEntry(this.itemQtyMax.dataPath);
    }
    if (this.itemDurationMin) {
      this.nameDurationMin = Utilities.getLastEntry(this.itemDurationMin.dataPath);
    }
    if (this.itemDurationMax) {
      this.nameDurationMax = Utilities.getLastEntry(this.itemDurationMax.dataPath);
    }

    // Setup columns
    this.columnDisplayName = this.columns.find(m => m.javaScriptName === this.nameDisplayName);
    this.columnQtyMin = this.columns.find(m => m.javaScriptName === this.nameQtyMin);
    this.columnQtyMax = this.columns.find(m => m.javaScriptName === this.nameQtyMax);
    this.columnDurationMin = this.columns.find(m => m.javaScriptName === this.nameDurationMin);
    this.columnDurationMax = this.columns.find(m => m.javaScriptName === this.nameDurationMax);

    // set the 'Add' button text from template
    const template = this.getPriceSchemeDetailTemplate();
    if (template) {
      const a = template.onAddAction.find(ac => ac.action === ActionIdentifier.UpdateData);
      if (a.actionArgument.title) {
        this.buttonNameAdd = Utilities.parseArgumentsFromData(a.actionArgument.title.text, a.actionArgument.title.argumentIds,
          this.priceScheme);
      }
    }

    this.uriCollections.push({ name: 'priceScheme', dataValues: this.priceScheme, dataMetadata: [] });
    this.uriCollections.push({ name: 'priceSchemeDetail', dataValues: this.priceSchemeDetails, dataMetadata: this.columns });
    this.uriCollections.push({ name: 'priceSchemeProductAssociationSummary', dataValues: [], dataMetadata: [] });
  }

  public getLabel(text: string): string {

    if (this.priceScheme[Utilities.getLastEntry(text)] != null) {
      if (Utilities.getFirstEntry(text) === 'data') {
        if (Utilities.getObjectName(text) === 'priceScheme') {
          if (Utilities.getLastEntry(text) === 'durationUnit') {
            try {
              return FlowService.getLookupDetail('Duration', this.priceScheme[Utilities.getLastEntry(text)]).text;
            } catch {
              Utilities.log2('PriceSchemeDetail-getLabel', 'Can\'t find duration lookup detail for ' + text, 'error');
              return this.priceScheme[Utilities.getLastEntry(text)];
            }
          } else {
            return this.priceScheme[Utilities.getLastEntry(text)];
          }
        }
      }
    } else {
      return text;
    }
  }

  public getPriceSchemeDetailTemplate(): FormTemplateItem {

    // mismatch between lookup detail 'value' type and priceSchemeType so don't check for type match
    if (this.priceScheme.priceSchemeType == 2) {
      return this.priceSchemeDetailTemplateQty;
    } else if (this.priceScheme.priceSchemeType == 3) {
      return this.priceSchemeDetailTemplateDuration;
    } else {
      return null;
    }
  }

  public getColumnItem(name: string): ScreenColumn {

    const t = this.getPriceSchemeDetailTemplate();
    if (t) {
      return t.templateItem.find(m => m.tag === name);
    } else {
      return null;
    }
  }

  public addItem() {

    const template = this.getPriceSchemeDetailTemplate();

    if (template) {
      // var a = template.onAddAction.find(a => a.action === ActionIdentifier.UpdateData);

      // TODO the key 'priceSchemeID' should come from the newObjectDefaults & screenParameters

      this.priceSchemeDetails.push({
        priceSchemeID: this.priceScheme.priceSchemeID
      });
    }
  }

  public Save() {
    const template = this.getPriceSchemeDetailTemplate();

    // if price scheme details are part of a new price scheme, priceSchemeId is set to -1 until the price scheme is saved and PK assigned
    // should we use the Add action???
    this.priceSchemeDetails.forEach(element => {
      if (element.priceSchemeID == null || element.priceSchemeID < 0) {
        element.priceSchemeID = this.priceScheme.priceSchemeID;
      }
    });

    if (template) {

      const completionEvent = new EventEmitter<ActionCompletedEvent>();
      completionEvent.subscribe((ev: ActionCompletedEvent) => {
        this.updateCompleted.emit(ev.isComplete);
      });

      const a = new ActionBuilder(template.onEditAction, this.priceSchemeDetails, null, this.uriCollections, null, completionEvent,
        null, this.mThingService, this.toastr, this.dialog);
      a.PerformAction();
    }
  }

  isVisible(menuItem: MenuItem): boolean {

    if (menuItem.visibleCondition === undefined) {
      return true;
    }

    if (this.priceScheme) {
      return Utilities.evaluate(menuItem.visibleCondition, this.priceScheme);
    }

    return true;
  }

  onContextExecute(menuItem: MenuItem, priceSchemeDetail: any) {

    const completionEvent = new EventEmitter<any>();
    if (menuItem.action.find(ac => ac.action === ActionIdentifier.DeleteItem)) {
      completionEvent.subscribe(complete => {
        const index = this.priceSchemeDetails.findIndex(p => p.priceSchemeDetailID === priceSchemeDetail.priceSchemeDetailID);
        if (index > -1) {
          this.priceSchemeDetails.splice(index, 1);
          // this.deleteCompleted(complete)
        }
      });
    }

    const a = new ActionBuilder(menuItem.action, priceSchemeDetail, null, this.uriCollections, null, completionEvent, null,
      this.mThingService, this.toastr, null);
    a.PerformAction();
  }

  private onPostDataDetailSuccessful(result: PostResponse[]) {

    this.updateCompleted.emit(true);
  }

  private onPostDataDetailFailed(error: any) {

    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
    this.updateCompleted.emit(false);
  }
}
