import { Pipe, PipeTransform } from '@angular/core';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';

@Pipe({
  name: 'elementImage'
})
export class ElementImagePipe implements PipeTransform {

  constructor(private thingService: ThingService) {
  }

  transform(elementName: string, cardTemplate: any, dataItem: any): any {
    if (cardTemplate[elementName]) {
      const argId = Utilities.getLastEntry(cardTemplate[elementName].icon.iconArgument);
      const fileId = dataItem[argId];
      if (fileId) {
        return this.thingService.getFileUrl(fileId, 'tiny');
      }
    }
  }

}
