
import { MenuItem } from '../data/MenuItem';
import { Action } from '../actions/Action';
import { ScreenColumn } from '../ScreenColumn';

export class FormTemplateItem {

  tag: string;

  dataObjectKey: string;

  templateItem: ScreenColumn[];

  template: FormTemplateItem[];

  allowRowReordering: boolean;

  allowMoveBetweenLevels: boolean;

  contextMenu: MenuItem[];

  action: Action;

  onAddAction: Action[];

  onEditAction: Action[];

  onRemoveAction: Action[];
} 