import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Utilities } from '../../../../services/utilities/utilities';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { Action } from 'src/app/models/actions/Action';
import { ToastrService } from 'ngx-toastr';
import { DataDetail } from 'src/app/models/DataDetail';
import { PriceSchemeDetailComponent } from '../price-scheme-detail/price-scheme-detail.component';

export interface DialogData {
  screenDetail: ScreenDetail;
  title: string;
  action: Action;
  columns: FTThingDetail[];
  priceSchemeDetails: any[];
  priceScheme: any;
}

@Component({
  selector: 'app-price-scheme-detail-update-dialog',
  templateUrl: './price-scheme-detail-update-dialog.component.html',
  styleUrls: ['./price-scheme-detail-update-dialog.component.scss']
})
export class PriceSchemeDetailUpdateDialogComponent implements OnInit {

  screenDetail: ScreenDetail;
  title: string;
  // action: Action;
  columns: FTThingDetail[];
  priceScheme: any;
  priceSchemeDetails: any[];
  updating: boolean = false;

  @ViewChild(PriceSchemeDetailComponent) public priceSchemeDetailComponent: PriceSchemeDetailComponent

  constructor(public toastr: ToastrService, public dialogRef: MatDialogRef<PriceSchemeDetailUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.screenDetail = this.data.screenDetail;
    this.priceSchemeDetails = this.data.priceSchemeDetails;
    this.priceScheme = this.data.priceScheme;
    // this.action = this.data.action;
    this.columns = this.data.columns;
    this.title = this.data.title; // Using menu option has title to avoid displaying "Add / update ...." in title

    if (!this.title && this.screenDetail) {
      this.title = Utilities.parseArgumentsFromData(this.screenDetail.screenTitle.text, this.screenDetail.screenTitle.argumentIds);
    }
  }

  public onSave() {

    this.priceSchemeDetailComponent.Save();
    this.updating = true;

  }

  onUpdateCompleted(event) {

    this.updating = false;

    if (event) {
      this.dialogRef.close(true);
    }

  }

}
