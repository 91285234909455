import { Component, OnInit, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { SelectedItem } from '../../../../models/SelectedItem';
import { DataDetail } from '../../../../models/DataDetail';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'app-simple-multi-dropdown',
  templateUrl: './simple-multi-dropdown.component.html',
  styleUrls: ['./simple-multi-dropdown.component.scss']
})
export class SimpleMultiDropdownComponent implements OnInit, AfterViewInit {
  @Input()
  selectedItem: SelectedItem;

  _dataUri: string;
  @Input()
  get dataUri(): string {
    return this._dataUri;
  }
  set dataUri(v: string) {
    const pts = v.split('?');
    if (pts.length > 1) {
      const queryPartSplit = pts[1].split('&');
      const orderIndex = queryPartSplit.findIndex(p => p.startsWith('$orderby'));
      if (orderIndex > -1) {
        queryPartSplit.splice(orderIndex, 1);
      }
      const query = queryPartSplit.join('&');
      this._dataUri = `${pts[0]}?${query}`;
    } else {
      this._dataUri = v;
    }
  }

  @Input()
  name: string;

  @Input()
  bindValue: string;

  @Input()
  bindLabel: string;

  @Input()
  labelName: string;

  @Input()
  multiple = false;

  @Input()
  required = false;

  totalRecordCount: number;

  returnRecordCount: number;

  loading = true;

  dataItems: any[];

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.loading = true;
    const select = `${this.bindValue},${this.bindLabel}`;

    this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, null, '', select, true)
      .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
  }

  private onGetDataSuccessful(dataDetail: DataDetail) {

    this.loading = false;
    this.dataItems = dataDetail.dataItems;
    this.returnRecordCount = dataDetail.returnRecordCount;
    this.totalRecordCount = dataDetail.totalRecordCount;

  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
    this.loading = false;
  }
}
