import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material';

import { AlertDialogComponent } from '../../dialogs/alert-dialog/alert-dialog.component';

import { ToastrService } from 'ngx-toastr';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { ScreenDetail } from '../../../../models/ScreenDetail';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { ActionURI } from '../../../../models/data/ActionURI';
import { TextArgument } from '../../../../models/TextModel';
import { Action } from '../../../../models/actions/Action';
import { PostResponse } from '../../../../models/PostResponse';
import { SimpleDropdownComponent } from '../../dropdowns/simple-dropdown/simple-dropdown.component';
import { AutoCompleteDropdownComponent } from '../../dropdowns/auto-complete-dropdown/auto-complete-dropdown.component';
import { VirtualScrollDropdownComponent } from '../../dropdowns/virtual-scroll-dropdown/virtual-scroll-dropdown.component';
import { ActionIdentifier } from 'src/app/models/Enums';

@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss']
})
export class QuickAddComponent implements OnInit {

  loadingIndicator = true;
  updateIndicator = false;
  @Input() height: number;
  @Input() uri: ActionURI;
  @Input() loadScreenDetail = true;
  @Input() screenParameters: any;
  @Input() customId: any;

  @Output() onUpdateCompleted = new EventEmitter<number>();

  @ViewChild('simpledropdown') public simpleDropdown: SimpleDropdownComponent;
  @ViewChild('autocompletedropdown') public autoCompleteDropdown: AutoCompleteDropdownComponent;
  @ViewChild('virtualscrolldropdown') public virtualScrollDropdown: VirtualScrollDropdownComponent;

  title: string;

  private _screenDetail: ScreenDetail;

  columnFields = [];
  row: any;

  constructor(public toastr: ToastrService, private thingService: ThingService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  get screenDetail(): ScreenDetail {
    return this._screenDetail;
  }

  @Input()
  set screenDetail(value: ScreenDetail) {

    this._screenDetail = value;

    if (!this.loadScreenDetail && this.screenDetail) {

      this.thingService.getFTThingDetail(this.uri.metaUri)
        .subscribe(result => this.getDataDetailWithHeaderSuccessful(result), error => this.onGetDetailFailed(error));

    }

    if (this.screenDetail && this.screenDetail.screenTitle) {

      this.title = Utilities.parseArgumentsFromData(this.screenDetail.screenTitle.text, this.screenDetail.screenTitle.argumentIds,
        null, null, this.screenDetail.requiredScreenParameters);
    }
  }

  private getDataDetailWithHeaderSuccessful(columns: FTThingDetail[]) {

    this.columnFields = [];

    let columnId = '';
    let dataType = -1;
    let viewType = -1;
    let referenceTo = '';
    let columnDetail: FTThingDetail = null;
    let itemSource = '';
    let valueName = '';
    let displayName = '';
    let filterType = 0;

    let mandatory = false;
    let minValue: any;
    let maxValue: any;

    if (!this.row) {
      this.row = {};
    }

    for (const item of this.screenDetail.detailSections[0].details) {

      columnId = '';
      dataType = -1;
      viewType = item.viewType;
      referenceTo = '';
      columnDetail = null;
      mandatory = false;
      itemSource = '';
      displayName = '';
      let displayString = '';
      let displayStringArgs = [];
      filterType = item.filterType;

      if (item.dataPath) {

        columnId = Utilities.getAttributeName(this.uri.name, item.dataPath);


        dataType = -1;

        if (item.itemSource) {
          itemSource = item.itemSource;
          valueName = columnId;

          if (item.template) {

            // // Assuming just one id with no text
            // let arg = item.template[0].text.argumentIds[0];
            // displayName = arg.substring(arg.lastIndexOf('.') + 1);

            displayString = item.template[0].text.text;
            displayStringArgs = item.template[0].text.argumentIds;
          }
        }

        columnDetail = columns.find(c => c.name.toLowerCase() === columnId.toLowerCase());

        if (columnDetail) {

          dataType = columnDetail.dataType;

          if (columnDetail.validationRule) {
            const val = JSON.parse(columnDetail.validationRule);

            mandatory = val.mandatory;
            minValue = val.minValue;
            maxValue = val.maxValue;
          }

          dataType = columnDetail.dataType;

          this.columnFields.push({
            columnHeader: item.columnHeader, itemSource: itemSource, valueName: valueName, displayName: displayName, filterType: filterType,
            name: columnId, mandatory: mandatory, dataType: dataType, viewType: viewType, icon: (item.icon ? item.icon.iconArgument : null),
            class: item.colClass, style: item.colStyle, text: item.text
          });
        }
      } else if (item.icon && item.icon.iconArgument && item.icon.iconArgument.indexOf('.') >= 0) {

        columnId = Utilities.getAttributeName(this.uri.name, item.icon.iconArgument);

        columnDetail = columns.find(c => c.name.toLowerCase() === columnId.toLowerCase());

        this.columnFields.push({
          columnHeader: item.columnHeader, referenceTo: referenceTo,
          name: columnId, mandatory: mandatory, dataType: (columnDetail && columnDetail.referenceTo ? -4 : -1), viewType: viewType,
          icon: (item.icon ? item.icon.iconArgument : null), class: item.colClass, style: item.colStyle, text: item.text
        });

      } else {

        if (item.tag) {
          columnId = `tag-${item.tag}`;
        } else {
          columnId = item.sequence.toString();
        }

        const hasAction: boolean = (item.action && item.action[0].action > 0);

        this.columnFields.push({
          columnHeader: item.columnHeader, action: hasAction ? item.action : null,
          name: columnId, mandatory: mandatory, dataType: hasAction ? -2 : -3, viewType: viewType, icon: (item.icon ? item.icon.iconArgument : null),
          class: item.colClass, style: item.colStyle, text: item.text
        });

      }

      if (columnId !== '') {
        // Generate a blank row
        if (item.defaultValue) {
          this.row[columnId] = item.defaultValue;
        } else {
          this.row[columnId] = '';
        }

        // if (dataType === 10) {
        //   this.row[columnId] = new SelectedItem('');
        // }
      }

    }

    this.loadingIndicator = false;

  }

  private onGetDetailFailed(error: any) {
    this.loadingIndicator = false;
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public lookupDataUri(itemSource: string) {

    return this.uri.dataUri;
  }

  getStyles(item: any) {

    return {
      'style': item.style
    };

  }

  getClasses(item: any) {

    return item.class;

  }

  public parseText(textArgument: TextArgument, data: any[]) {
    return Utilities.parseArgumentsFromData(textArgument.text, textArgument.argumentIds, data);
  }

  hasClass(item: any, value: string) {

    if (item && item.class) {
      if (item.class.indexOf(value) > -1) {
        return true;
      }
    }

    return false;
  }

  public onSave() {

    // Take a copy as lookup values need to be converted
    const updateRow = JSON.parse(JSON.stringify(this.row));

    for (const key in updateRow) {

      if (!key) { continue; }

      const column = this.columnFields.find(c => c.name === key);

      // Convert flow object back to number
      if (key === 'activeFlowState' && updateRow[key]) {
        updateRow[key] = updateRow[key].ftFlowStatusID;
      } else if (column && column.dataType === 23) {

        updateRow[key] = updateRow[key].value;
      }

    }

    Utilities.log('UpdateRow ' + JSON.stringify(updateRow));

  }

  public onItemClick(event, item, row) {

    if (item.action) {

      const action: Action = JSON.parse(JSON.stringify(item.action[0]));

      Utilities.log(JSON.stringify(action));

      if (action.action === ActionIdentifier.UpdateData) {

        if (action.actionArgument) {

          const newItem: any = Utilities.generateRowFromDefaults(action.actionArgument.newObjectDefaults, row, this.screenParameters);

          if (newItem['sectionId']) {
            delete newItem['sectionId'];
          }

          if (this.customId) {
            newItem['sectionId'] = this.customId.id;
          }

          if (newItem['price']) {
            delete newItem['price'];
          }

          let allValuesSelected = true;

          for (const key in newItem) {

            if (newItem[key] === '') {
              allValuesSelected = false;
            }
          }

          if (!allValuesSelected) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '450px',
              data: { message: 'Please enter all required values.' }
            });
          } else {

            Utilities.log(JSON.stringify(newItem));

            this.updateIndicator = true;

            this.thingService.postDataDetails(action.actionArgument.editUri, newItem)
              .subscribe(result => { this.onPostDataDetailSuccessful(action, result); },
                error => this.onPostDataDetailFailed(error));
          }
        }
      }
    }
  }

  private onPostDataDetailSuccessful(action: Action, resultArray: PostResponse[]) {

    const result = resultArray[0];
    let sectionId = -1;

    if (result && result.returnedObject.sectionID) {

      sectionId = result.returnedObject.sectionID;
    }

    this.onUpdateCompleted.emit(sectionId);

    if (this.autoCompleteDropdown) {
      this.autoCompleteDropdown.clear();
      this.autoCompleteDropdown.focus();
    }
    if (this.simpleDropdown) {
      this.simpleDropdown.clear();
      this.simpleDropdown.focus();
    }
    if (this.virtualScrollDropdown) {
      this.virtualScrollDropdown.clear();
      this.virtualScrollDropdown.focus();
    }
    this.updateIndicator = false;
  }

  private onPostDataDetailFailed(error: any) {
    this.updateIndicator = false;
    // // clear the selected item
    // this.row = {};
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  get hasRow(): boolean {

    if (this.row) {
      return true;
    }

    return false;
  }

}
