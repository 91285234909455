import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertDialogComponent, DialogData } from '../../controls/dialogs/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss']
})
export class ManageProfileComponent implements OnInit {

  loginName: string;
  iconId: number;

  constructor(private authService: AuthenticationService, public dialogRef: MatDialogRef<ManageProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

    this.loginName = this.authService.getUsername();
    this.iconId = this.authService.getLoginInfo().iconId;
  }

  changePassword() {

  }

}
