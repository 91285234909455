import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild, ChangeDetectionStrategy, OnChanges, DoCheck, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import { Project, Zooming } from '../interfaces';
import { GanttActivityBarsComponent } from './bars/activity-bars.component';
import * as _ from 'lodash';
import { GanttService } from '../services/gantt/gantt.service';
import { ThingService } from '../services/thing/thing.service';

@Component({
    selector: 'gantt-activity',
    templateUrl: './gantt-activity.component.html',
    styleUrls: ['./gantt-activity.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class GanttActivityComponent implements OnInit, DoCheck {
    // private _project: Project;
    // @Input()
    // public set project(value: Project) {
    //     this._project = value;
    //     if(this.obj){
    //         this.setProjectData();
    //     }
    // }
    // public get project(): Project {
    //     return this._project;
    // }

    private _events: any[];
    public get events(): any[] {
        return this._events;
    }
    @Input()
    public set events(value: any[]) {
        this._events = value;
        if(this.categories){
            this.setProjectData();
        }
    }
    @Input() categories: any;
    primaryEventCategories: Array<any> = [];
    @Input() options: any;
    @Input() primaryEventTypeId: number;
    @Output() onGridRowClick: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(GanttActivityBarsComponent) child: GanttActivityBarsComponent;
    @Output() itemSelected: EventEmitter<any> = new EventEmitter();
    legendCategories: any;
    
    _currentMode: string;
    @Input()
    public set currentMode(value: string) {
      if (value != this._currentMode) {
        this._currentMode = value;
      }
    }
    public get currentMode() { return this._currentMode; }
    @Output() currentModeChange = new EventEmitter<string>();

    @Input() publicDataSource: string;
    @Input() managerDataSource: string;
    @Input() privateDataSource: string;
    @Input() showDataSourceSelect: boolean;
    public changeDataSource(){
        this.currentModeChange.emit(this._currentMode)
    }


    public upTriangle: string = '&#x25b2;' // BLACK UP-POINTING TRIANGLE
    public downTriangle: string = '&#x25bc;'; // BLACK DOWN-POINTING TRIANGLE
    public zoom: EventEmitter<string> = new EventEmitter<string>();
    public activityActions = {
        expanded: false,
        expandedIcon: this.downTriangle
    }

    public timeScale: any;

    public start: Date;
    public end: Date;
    public containerHeight: any;
    public containerWidth: any;
    eventForm: FormGroup;
    userId: number;
    filterActive: boolean = false;

    selectedCategoryId: any;
    previousSelectedPrimaryId: number;

    public activityContainerSizes: any;
    public ganttActivityHeight: any;
    public ganttActivityWidth: any;
    public zoomLevel: string = Zooming[Zooming.hours];
    eventTypes: Array<any> = new Array<any>();

    public eventTypeIdButtons: Array<number> = new Array<number>();

    eventTypeSubEventTypeArray: any[] = [];

    eventTypeCheckList: any[] = [];

    allPrimaryEvents: any;
    allCategories: [][];

    public treeExpanded = false;

    public scale: any = {
        start: null,
        end: null
    };

    public dimensions = {
        height: 0,
        width: 0
    };

    public data: any[] = [];

    public gridColumns: any[] = [
        { name: 'Event Title', left: 20, width: 330 },
        // { name: 'Duration', left: 14, width: 140 }
    ];
    obj: any;
    objGrouped: Array<Array<any>> = new Array<Array<any>>();

    hiddenObj: any;
    primaryEvents: any;
    primaryEventsByInnerObject: any = [];
    showFilter: boolean = false;
    checkedItems: any[] = [];

    constructor(
        public formBuilder: FormBuilder,
        public elem: ElementRef,
        public ganttService: GanttService,
        public cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        public route: ActivatedRoute,
        public thingService: ThingService
    ) {
    }

    ngOnInit() {

        this.selectedCategoryId = this.primaryEventTypeId;
        this.previousSelectedPrimaryId = this.primaryEventTypeId;

        this.legendCategories = _.cloneDeep(this.categories);

        this.checkedItems.push(this.primaryEventTypeId);

        this.eventForm = this.formBuilder.group({
            title: ['', Validators.required],
            eventType: ['', Validators.required],
            subEventType: ['', Validators.required],
            description: [''],
            start: ['', Validators.required],
            end: ['', Validators.required],
        });

        this.setProjectData();
    }

    scrolled: boolean = false;
    ngAfterViewChecked() {
        if(this.scrolled){
            return;
        }
        var offsets = document.getElementById('timelineToday').getBoundingClientRect();
        var scrollArea = document.getElementById("gantt_activity_scroll");
        var scrollBounding = scrollArea.getBoundingClientRect();
        var left = offsets.left - scrollBounding.left - (scrollBounding.width/2);
        scrollArea.scrollLeft = left;
        this.scrolled = true;
    }

    setProjectData(){
        // Cache the project data and only work with that. Only show parent tasks by default
        this.obj = [];
        // this.project.events.forEach((e, i) => (i = e.eventTypeId as number, this.obj[i + 1] ? this.obj[i + 1].push(e) : (this.obj[i + 1] = [e])));
        this.events.forEach((e, i) => (i = e.ftCalendarItemCategoryID as number, this.obj[i + 1] ? this.obj[i + 1].push(e) : (this.obj[i + 1] = [e])));

        var allStartDates = this.events.map(x => x.start.getTime());

        var eventsWithEndDates = this.events.filter(x => !!x.end && !isNaN(x.end));
        var todaysDate = new Date();
        var allEndDates = eventsWithEndDates.map(x => !!x.end ? new Date(x.end!).getTime() : new Date(todaysDate.setDate(todaysDate.getDate() + 7)).getTime()) as Array<number>;

        var allDates = allStartDates.concat(allEndDates);

        var end = new Date(Math.max.apply(null, allDates));
        var start = new Date(Math.min.apply(null, allStartDates));

        this.obj.shift();

        //this.primaryEventCategories = [];

        for (var i = 0; i < this.obj.length; i++) {
            if (!!this.obj[i] && this.obj[i].length > 0) {
                var key = this.obj[i][0].ftCalendarItemCategoryID;
                var values = new Array<any>();
                this.obj[i].forEach(x => !x.filter1 || !!values.find(y => y.filter1 == x.filter1) ? null : values.push({filter1: x.filter1, checked: false}));
                this.categories[i] = { key: key, name: this.obj[i][0].name, values: values, checked: false };
                if(!this.primaryEventCategories.some(c => c.key == key)){
                    this.primaryEventCategories.push({ key: key, name: this.obj[i][0].name, values: values });
                }
            }
            else {
                this.categories[i] = undefined;
            }
        }

        // Remove items that are undefined
        this.categories = this.categories.filter(function (element) {
            return element !== undefined;
        });


        this.primaryEvents = this.obj.slice(this.selectedCategoryId, this.selectedCategoryId + 1);

        this.allPrimaryEvents = _.cloneDeep(this.primaryEvents);

        this.generateExpandedInnerObjects();

        var uniqueEventTypeIds = [...Array.from(new Set(this.events.map(item => item.ftCalendarItemCategoryID)))];
        this.eventTypeIdButtons = uniqueEventTypeIds;
        if (!!this.eventTypeIdButtons && this.eventTypeIdButtons.length > 0) {
            this.eventTypeIdButtons.forEach(x => {
                if (x !== this.selectedCategoryId) {
                    this.eventTypeCheckList.push({ id: x, checked: false })
                }
            });
        }

        this.ganttService.TASK_CACHE = this.events;
        this.ganttService.TIME_SCALE = this.ganttService.calculateScale(start, end);

        this.zoomLevel = this.options.zooming;
        this.start = this.options.scale.start;
        this.end = this.options.scale.end;
        this.containerWidth = this.calculateContainerWidth();
        this.containerHeight = this.calculateContainerHeight();
        this.activityContainerSizes = this.ganttService.calculateActivityContainerDimensions();

        // important that these are called last as it relies on values calculated above.
        this.setScale();
        this.setDimensions();
        this.setSizes();

        this.expand(); // default to expanded
        console.log(this.legendCategories);
    }

    /** Custom model check */
    ngDoCheck() {
        // do a check to see whether any new tasks have been added. If the task is a child then push into array if tree expanded?
        // var tasksAdded = this.ganttService.doTaskCheck(this.project.events, this.treeExpanded);

        // // only force expand if tasks are added and tree is already expanded
        // if (tasksAdded && this.activityActions.expanded) {
        //     this.expand(true);
        // }
//////////////
        // this.events = this.events;
        // this.ganttService.TASK_CACHE = this.events;
        // this.obj = [];
        // this.events.forEach((e, i) => (i = e.ftCalendarItemCategoryID as number, this.obj[i] ? this.obj[i].push(e) : (this.obj[i] = [e])));
     
        // this.obj = this.obj.filter(value => Object.keys(value).length !== 0);
///////////////
        // console.log(this.obj)
        // this.cdr.detectChanges();
    }

    /** On vertical scroll set the scroll top of grid and activity  */
    onVerticalScroll(verticalScroll: any, ganttGrid: any, ganttActivityArea: any): void {
        this.ganttService.scrollTop(verticalScroll, ganttGrid, ganttActivityArea);
    }

    //TODO switch and also don't hardcode it like this
    getEventTypeName(events: any[]) {
        if (!!events && events.length > 0) {
            if (events[0].ftCalendarItemCategoryID == 3) {
                return 'Generic';
            }
            if (events[0].ftCalendarItemCategoryID == 1) {
                return 'Absence';
            }
            if (events[0].ftCalendarItemCategoryID == 2) {
                return 'Order';
            }
        }
        return 'Unknown Event Type';
    }

    getInnerObjectName(object: any) {
        return object.name;
    }


    //TODO switch
    getEventTypeIdName(id: number) {

        // if (!!this.absenceCategories) {
        //     var category = this.absenceCategories.find(x => x.absenceCategoryID == id);
        //     if (!!category) {
        //         return category.text;
        //     }
        // }
        if (id == 3) {
            return 'Generic';
        }
        if (id == 1) {
            return 'Absence';
        }
        if (id == 2) {
            return 'Orders';
        }
        return 'Unknown Event Type';
    }

    /** Removes or adds children for given parent tasks back into DOM by updating TASK_CACHE */
    toggleChildren(rowElem: any, task: any) {
        try {
            console.log(task);

            this.onGridRowClick.emit(task);

        } catch (err) { }
    }

    /** Removes or adds children tasks back into DOM by updating TASK_CACHE */
    toggleAllChildren() {
        try {
            var children: any = document.querySelectorAll('[data-isparent=false]');
            var childrenIds: string[] = Array.prototype.slice.call(children).map((item: any) => {
                return item.getAttribute('data-id').replace("_", ""); // remove id prefix
            });

            // push all the children array items into cache
            if (this.treeExpanded) {
                if (children.length > 0) {
                    let childrenIds: string[] = this.ganttService.TASK_CACHE.filter((task: any) => {
                        return task.treePath.split('/').length > 1;
                    }).map((item: any) => { return item.id });

                    childrenIds.forEach((item: any) => {
                        var removedIndex = this.ganttService.TASK_CACHE.map((item: any) => { return item.id }).indexOf(item);
                        this.ganttService.TASK_CACHE.splice(removedIndex, 1);
                    });
                }

                this.treeExpanded = false;

                if (this.activityActions.expanded) {
                    this.expand(true);
                }
            } else {
                // get all children tasks in project input
                let childrenTasks: any[] = this.events.filter((task: any) => {
                    return task.treePath.split('/').length > 1;
                });

                if (children.length > 0) {
                    // filter out these children as they already exist in task cache
                    childrenTasks = childrenTasks.filter((task: any) => {
                        return childrenIds.indexOf(task.id) === -1;
                    });
                }

                childrenTasks.forEach((task: any) => {
                    this.ganttService.TASK_CACHE.push(task);
                });

                this.treeExpanded = true;

                if (this.activityActions.expanded) {
                    this.expand(true);
                }
            }
        } catch (err) { }
    }

    /** On resize of browser window dynamically adjust gantt activity height and width */
    onResize(event: any): void {
        let activityContainerSizes = this.ganttService.calculateActivityContainerDimensions();
        if (this.activityActions.expanded) {
            this.ganttActivityHeight = this.ganttService.TASK_CACHE.length * this.ganttService.rowHeight + this.ganttService.rowHeight * 3 + 'px';
        } else {
            this.ganttActivityHeight = activityContainerSizes.height + 'px';;
        }

        this.ganttActivityWidth = activityContainerSizes.width;
    }

    setScale() {
        this.scale.start = this.start;
        this.scale.end = this.end;
    }

    setDimensions() {
        this.dimensions.height = this.containerHeight;
        this.dimensions.width = this.containerWidth;
    }

    setGridRowStyle(isParent: boolean): any {
        if (isParent) {
            return {
                'height': this.ganttService.rowHeight + 'px',
                'line-height': this.ganttService.rowHeight + 'px',
                'font-weight': 'bold',
                'cursor': 'pointer'
            };
        }

        return {
            'height': this.ganttService.rowHeight + 'px',
            'line-height': this.ganttService.rowHeight + 'px'
        };
    }

    /** Set the zoom level e.g hours, days */
    zoomTasks(level: string) {
        this.zoomLevel = level;
        this.zoom.emit(this.zoomLevel);
        this.containerWidth = this.calculateContainerWidth();
        this.setDimensions();
        document.querySelector('.gantt_activity')!.scrollLeft = 0 // reset scroll left, replace with @ViewChild?
    }

    /** Expand the gantt grid and activity area height */
    expand(force?: boolean): void {
        var verticalScroll = document.querySelector('.gantt_vertical_scroll');
        var ganttActivityHeight: string = `${(this.ganttService.TASK_CACHE.length * this.ganttService.rowHeight + this.ganttService.rowHeight * 3) + 60}px`;

        if (force && this.activityActions.expanded) {
            this.ganttActivityHeight = ganttActivityHeight;
        } else if (this.activityActions.expanded) {
            this.activityActions.expanded = false;
            this.activityActions.expandedIcon = this.downTriangle;
            this.ganttActivityHeight = this.ganttService.calculateGanttHeight();
        } else {
            if (!!verticalScroll) {
                verticalScroll.scrollTop = 0;
            }

            this.activityActions.expanded = true;
            this.activityActions.expandedIcon = this.upTriangle;
            this.ganttActivityHeight = ganttActivityHeight;
        }
    }

    public setGridScaleStyle() {
        var height = this.ganttService.rowHeight;

        if (this.zoomLevel === Zooming[Zooming.hours]) {
            height *= 2;
        }

        return {
            'height': height + 'px',
            'line-height': height + 'px',
            // 'width': this.ganttService.gridWidth + 'px'
        };
    }

    public calculateContainerHeight(): number {
        return this.ganttService.TASK_CACHE.length * this.ganttService.rowHeight;
    }

    public calculateContainerWidth(): number {
        if (this.zoomLevel === Zooming[Zooming.hours]) {
            return this.ganttService.TIME_SCALE.length * this.ganttService.hourCellWidth * 24 + this.ganttService.hourCellWidth
        } else {
            return this.ganttService.TIME_SCALE.length * this.ganttService.cellWidth + this.ganttService.cellWidth;
        }
    }

    public setSizes(): void {
        this.ganttActivityHeight = this.activityContainerSizes.height + 'px';
        this.ganttActivityWidth = this.activityContainerSizes.width;
    }

    public showGroupedView(arrayByObjectId: any[][]) {
        // this.primaryEvents = this.primaryEvents.filter(x => x !== eventsOfSameType);
        var eventsOfSameType = [];
        var exit = false;

        for (let i = 0; i < arrayByObjectId.length; i++) {
            for (let j = 0; j < arrayByObjectId[i].length; j++) {
                for (let k = 0; k < this.primaryEvents.length; k++) {
                    if(this.primaryEvents[k]){
                        if (!exit && this.primaryEvents[k].includes(arrayByObjectId[i][0])) {
                            this.objGrouped.push(this.primaryEvents[k]);
                            this.primaryEvents.splice(k, 1);
                            for (let z = 0; z<this.primaryEventsByInnerObject.length;z++)
                            {
                                var newArr = this.primaryEventsByInnerObject[z].filter(x => x[0].ftCalendarItemCategoryID !== arrayByObjectId[i][0].ftCalendarItemCategoryID);
                                if (newArr.length < this.primaryEventsByInnerObject[z].length)
                                {
                                    this.primaryEventsByInnerObject.splice(z, 1);
                                    break;
                                }
                            }
                            // this.primaryEventsByInnerObject = this.primaryEventsByInnerObject.filter(x => x[0].ftCalendarItemCategoryID !== arrayByObjectId[i][0].ftCalendarItemCategoryID);
                            exit = true;
                            // this.primaryEvents = this.primaryEvents.filter(x => x !== this.primaryEvents[k]);
                        }
                    }
                    
                }
                if (exit)
                {
                    break;
                }
            }
        }

        this.child.eventsPerDayCount = Array<Array<number>>();

        //Sort so primary event is last on the list
        console.log(this.primaryEventsByInnerObject);

        // this.project.events = this.project.events.filter(x => x.eventTypeId !== eventsOfSameType[0].eventTypeId);
        // if (this.project.events.length == 0) {
        //     this.project.events = new Array<CalendarEvent>();
        // }
        // this.ganttService.TASK_CACHE = this.project.events;
    }

    public showExpandedView(eventsOfSameType: CalendarEvent[]) {
        this.objGrouped = this.objGrouped.filter(x => x != eventsOfSameType);
        if (!this.primaryEvents.includes(eventsOfSameType))
        {
            this.primaryEvents.push(eventsOfSameType);
        }
        this.child.eventsPerDayCount = Array<Array<number>>();


        //this.obj.push(eventsOfSameType);
        this.generateExpandedInnerObjects();
        // eventsOfSameType.forEach(x => this.project.events.push(x));
        // this.ganttService.TASK_CACHE = this.project.events;
    }

    public excludePrimaryGroupedEvents(id: number) {

        this.child.eventsPerDayCount = Array<Array<number>>();

        for (let j = 0; j < this.objGrouped.length; j++) {
            var eventsWithId = this.objGrouped[j].filter(x => x.ftCalendarItemCategoryID == id);
            if (!!eventsWithId && eventsWithId.length > 0) {
                this.objGrouped.splice(j, 1);
                break;
            }
        }
    }

    public collapseExpandViewType(id: number, checked: boolean) {


        this.child.eventsPerDayCount = Array<Array<number>>();

        var eventsById;
        for (let j = 0; j < this.objGrouped.length; j++) {
            var eventsWithId = this.objGrouped[j].filter(x => x.ftCalendarItemCategoryID == id);
            if (!!eventsWithId && eventsWithId.length > 0) {
                this.objGrouped.splice(j, 1);
                // checked = false;
                break;
            }
        }

        if (!checked) {
            this.primaryEventsByInnerObject = [];
            for (let i = 0; i < this.primaryEvents.length; i++) {
                if (!!this.primaryEvents[i] && this.primaryEvents[i].length > 0 && this.primaryEvents[i][0].ftCalendarItemCategoryID == id) {
                    this.primaryEvents.splice(i, 1);
                }
            }

            for (let i = 0; i < this.primaryEvents.length; i++) {
                if (!this.primaryEventsByInnerObject[i]) {
                    this.primaryEventsByInnerObject[i] = [];
                }
                if(this.primaryEvents[i]){
                    this.primaryEvents[i].forEach((e, j) => (j = +e.grouping, this.primaryEventsByInnerObject[i][j + 1] ? this.primaryEventsByInnerObject[i][j + 1].push(e) : (this.primaryEventsByInnerObject[i][j + 1] = [e])));
                    this.primaryEventsByInnerObject[i] = this.primaryEventsByInnerObject[i].filter(n => n);
                }
            }
        }

        if (checked) {
            for (let i = 0; i < this.obj.length; i++) {
                if(this.obj[i]){
                    var eventsById = this.obj[i].filter(x => x.ftCalendarItemCategoryID == id);
                    if (!!eventsById && eventsById.length > 0) {
                        this.objGrouped.push(this.obj[i]);
                        break;
                    }
                }
            }
        }
    }

    public collapseAllViewTypes() {

        for (let i = 0; i < this.eventTypeIdButtons.length; i++) {
            this.child.eventsPerDayCount = Array<Array<number>>();
            for (let j = 0; j < this.objGrouped.length; j++) {
                var eventsWithId = this.objGrouped[j].filter(x => x.eventTypeId == this.eventTypeIdButtons[i]);
                if (!!eventsWithId && eventsWithId.length > 0) {
                    this.objGrouped.splice(j, 1);
                    break;
                }
            }
        }
    }

    getCurrentSecondaryEvents(){
        var groups = this.objGrouped.map(grp => 
            { 
                if(grp[0]) { return grp[0].ftCalendarItemCategoryID; }
                else { return null; }
                
            });
        return groups;
    }

    onPrimaryEvent(event: any) {
        var activeSecondaryCategories = this.getCurrentSecondaryEvents();
        var index = activeSecondaryCategories.findIndex(id => id == +event.value);
        if (index > -1) {
            this.collapseExpandViewType(event.value, false);
            // this.eventTypeCheckList[index].checked = false;
        }

        this.primaryEvents = new Array<Array<CalendarEvent>>();
        for (let i = 0; i < this.obj.length; i++) {
            if (!!this.obj[i] && this.obj[i].length > 0 && this.obj[i][0].ftCalendarItemCategoryID == +event.value) {
                this.primaryEvents.push(this.obj[i]);

                //TODO redo this area based on subtypes available instead
                if (+event.value == 1) {
                    var uniqueEventTypeIds = [...Array.from(new Set(this.events.map(item => item.ftCalendarItemCategoryID)))];
                    // this.eventTypeIdButtons = uniqueEventTypeIds.filter(x => x !== +event.value) as Array<number>;
                }
                this.generateExpandedInnerObjects();
                // return;
            }
        }

        //remove primary events from grouped view if it exists
        this.excludePrimaryGroupedEvents(this.previousSelectedPrimaryId);
        this.previousSelectedPrimaryId = this.selectedCategoryId;

        this.eventTypeCheckList = this.eventTypeCheckList.filter(x => x.id != this.previousSelectedPrimaryId);
        if (!!this.eventTypeIdButtons && this.eventTypeIdButtons.length > 0) {
            this.eventTypeIdButtons.forEach(x => {
                if (x !== this.selectedCategoryId) {
                    this.eventTypeCheckList.push({ id: x, checked: false })
                }
            });
        }

        this.allPrimaryEvents = _.cloneDeep(this.primaryEvents);

        // this.collapseAllViewTypes();
        // this.eventTypeIdButtons = new Array<number>();
        // this.primaryEvents = null;
        this.cdr.detectChanges();
    }

    // shareCheckedList(item: any[]) {
    //     this.showFilter = true;

    //     //do this properly based on primary event type passed in the future;
    //     this.checkedItems = [];
    //     item.push(this.selectedCategoryId);
    //     item = item.filter(function(elem, index, self) {
    //         return index === self.indexOf(elem);
    //     })
    //     this.checkedItems = item;
    // }

    shareIndividualCheckedList(item: any) {
        this.collapseExpandViewType(item.id, item.checked);
    }

    toggleFilter() {
        this.showFilter = !this.showFilter;
    }

    get f() { return this.eventForm.controls; }

    generateExpandedInnerObjects() {
        this.primaryEventsByInnerObject = [];
        for (let i = 0; i < this.primaryEvents.length; i++) {
            if (!this.primaryEventsByInnerObject[i]) {
                this.primaryEventsByInnerObject[i] = [];
            }
            if(this.primaryEvents[i]){
                this.primaryEvents[i].forEach((e, j) => (j = +e.grouping, this.primaryEventsByInnerObject[i][j + 1] ? this.primaryEventsByInnerObject[i][j + 1].push(e) : (this.primaryEventsByInnerObject[i][j + 1] = [e])));
                this.primaryEventsByInnerObject[i] = this.primaryEventsByInnerObject[i].filter(n => n);
            }

            console.log(this.primaryEventsByInnerObject);
        }

        var primaryIndex;
        var primaryEvents;
        for( let i =0; i < this.primaryEventsByInnerObject.length; i++)
        {
            for (let j=0; j<this.primaryEventsByInnerObject[i].length;j++)
            {
                if(this.primaryEventsByInnerObject[i][0][0].ftCalendarItemCategoryID == this.selectedCategoryId)
                {
                    primaryIndex = i;
                    primaryEvents = this.primaryEventsByInnerObject[i];
                    break;
                }
            }
            if (primaryIndex !== undefined)
            {
                this.primaryEventsByInnerObject.splice(primaryIndex, 1);
                this.primaryEventsByInnerObject.push(primaryEvents);
                break;
            }
        }
    }

    generateGroupedInnerObjects(ftCalendarItemCategoryID: number, subEventTypes?: any[]) {

        console.log(this.primaryEvents);
        if (this.selectedCategoryId == ftCalendarItemCategoryID || this.primaryEvents.filter(x => x[0].ftCalendarItemCategoryID == ftCalendarItemCategoryID).length > 0) {
            this.primaryEventsByInnerObject = [];

            for (let i = 0; i < this.primaryEvents.length; i++) {
                if (!this.primaryEventsByInnerObject[i]) {
                    this.primaryEventsByInnerObject[i] = [];
                }
                this.primaryEvents[i].forEach((e, j) => (j = +e.grouping, this.primaryEventsByInnerObject[i][j + 1] ? this.primaryEventsByInnerObject[i][j + 1].push(e) : (this.primaryEventsByInnerObject[i][j + 1] = [e])));
                this.primaryEventsByInnerObject[i] = this.primaryEventsByInnerObject[i].filter(n => n);
            }

            var containsEventTypeId = false;
            for (let j = 0; j < this.objGrouped.length; j++) {
                if (this.objGrouped[j][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
                    this.objGrouped.splice(j, 1);
                    containsEventTypeId = true;
                    break;
                }
            }
            if (containsEventTypeId) {
                for (let k = 0; k < this.primaryEventsByInnerObject.length; k++) {
                    if (this.primaryEventsByInnerObject[k][0][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
                        this.showGroupedView(this.primaryEventsByInnerObject[k]);
                        break;
                    }
                }
            }
        }
        else {
            //Remove grouped row from list and then regenerate it completely before filtering
            var isGrouped = false;
            for (let j = 0; j < this.objGrouped.length; j++) {
                if (this.objGrouped[j][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
                    this.objGrouped.splice(j, 1);
                    isGrouped = true;
                    break;
                }
            }
            if(isGrouped)
            {
                for (let i = 0; i < this.obj.length; i++) {
                    var eventsById = this.obj[i].filter(x => x.ftCalendarItemCategoryID == ftCalendarItemCategoryID);
                    if (!!eventsById && eventsById.length > 0) {
                        this.objGrouped.push(this.obj[i]);
                        break;
                    }
                }
    
                for (let j = 0; j < this.objGrouped.length; j++) {
                    if (this.objGrouped[j][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
                        if (!!subEventTypes && subEventTypes.length > 0)
                        {
                            this.objGrouped[j] = this.objGrouped[j].filter(x => subEventTypes.includes(x.filter1));
                        }
                        break;
                    }
                }
            }
            else {
                for (let i=0; this.primaryEventsByInnerObject.length; i++)
                {
                    if (this.primaryEventsByInnerObject[i].length > 0 && this.primaryEventsByInnerObject[i][0].ftCalendarItemCategoryID === ftCalendarItemCategoryID)
                    {

                    }
                }
            }

            console.log(this.primaryEventsByInnerObject);
            // for (let k = 0; k < this.primaryEventsByInnerObject.length; k++) {
            //     if (this.primaryEventsByInnerObject[k][0][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
            //         this.showGroupedView(this.primaryEventsByInnerObject[k]);
            //         break;
            //     }
            // }
        }
    }

    getFilters(eventTypeId: number) {
        //This currently only contains subeventtypes
        // return this.eventTypes.find(x => x.id == eventTypeId).subEventTypes;
        //     if (!!this.absenceCategories) {
        //     var category = this.absenceCategories.find(x => x.absenceCategoryID == eventTypeId);
        //     if (!!category) {
        //         return category.text;
        //     }
        // }
        var categories = this.categories.find(x => x.key == eventTypeId);
        if (!!categories) {
            return categories.values;
        }
        return;

    }

    filterEvents(ftCalendarItemCategoryID: number, filter1: any, checked: boolean) {
        var addedRecord = this.eventTypeSubEventTypeArray.find(x => +x.ftCalendarItemCategoryID == +ftCalendarItemCategoryID && x.filter1 == filter1);

        var primaryEventCategoryIds = this.primaryEvents.map(x => x[0].ftCalendarItemCategoryID);
        this.primaryEvents = _.cloneDeep(this.obj.filter(x => primaryEventCategoryIds.includes(x[0].ftCalendarItemCategoryID)));
        if (checked)
        {
            this.eventTypeSubEventTypeArray.push({ ftCalendarItemCategoryID: ftCalendarItemCategoryID, filter1: filter1 });
        }
        else if (!!addedRecord) {
            var currentEventTypeSubEventIndex = this.eventTypeSubEventTypeArray.findIndex(x => x.filter1 == addedRecord.filter1 && +x.ftCalendarItemCategoryID == +addedRecord.ftCalendarItemCategoryID);
            
            if (currentEventTypeSubEventIndex > -1)
            {
                this.eventTypeSubEventTypeArray.splice(currentEventTypeSubEventIndex, 1);
            }
        }

        var recordsMatchingEventTypeId = this.eventTypeSubEventTypeArray.filter(x => x.ftCalendarItemCategoryID == ftCalendarItemCategoryID);
        var subEventTypes = recordsMatchingEventTypeId.map(x => x.filter1);

        //if not added then filter
        if (!addedRecord) {


            if (this.selectedCategoryId == ftCalendarItemCategoryID)
            {
                for (let i = 0; i < this.primaryEvents.length; i++) {
                    if (this.primaryEvents[i][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
                        this.primaryEvents[i] = this.primaryEvents[i].filter(x => subEventTypes.includes(x.filter1));
                        this.generateGroupedInnerObjects(ftCalendarItemCategoryID);
                    }
                }
            }
            else {
                // this.generateGroupedInnerObjects(ftCalendarItemCategoryID, subEventTypes);

                for (let i = 0; i < this.primaryEvents.length; i++) {
                    if (this.primaryEvents[i][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
                        this.primaryEvents[i] = this.primaryEvents[i].filter(x => subEventTypes.includes(x.filter1));
                    }
                }
                this.generateGroupedInnerObjects(ftCalendarItemCategoryID, subEventTypes);
            }
        }
        else {
            //already there so means we're unchecking the checkbox so add other events back in
            // var recordsMatchingEventTypeId = this.eventTypeSubEventTypeArray.filter(x => x.ftCalendarItemCategoryID == ftCalendarItemCategoryID);
            // var subEventTypes = recordsMatchingEventTypeId.map(x => x.filter1);

            // this.eventTypeSubEventTypeArray = this.eventTypeSubEventTypeArray.filter(x => x.filter1 != addedRecord.filter1 && x.ftCalendarItemCategoryID != addedRecord.ftCalendarItemCategoryID);
            if (this.eventTypeSubEventTypeArray.length == 0) {
                this.generateGroupedInnerObjects(ftCalendarItemCategoryID, subEventTypes);
                return;
            }

            for (let i = 0; i < this.primaryEvents.length; i++) {
                if (this.primaryEvents[i][0].ftCalendarItemCategoryID == ftCalendarItemCategoryID) {
                    this.primaryEvents[i] = this.primaryEvents[i].filter(x => subEventTypes.includes(x.filter1));
                }
                this.generateGroupedInnerObjects(ftCalendarItemCategoryID, subEventTypes);
            }

        }

    }

    onSelect(item: any) {
        this.itemSelected.emit(item);
    }

    setFilterStatus() {
        var checkedCategories = this.categories.filter(x => x.checked);
        this.filterActive = !!checkedCategories && checkedCategories.length > 0;

        var setFilter = false;
        for(let i=0;i<this.categories.length;i++)
        {
            var currentCategoryValues = this.categories[i].values;
            if(currentCategoryValues.length > 0)
            {
                var currentCategoryFiltersChecked = currentCategoryValues.filter(x => x.checked);
                if (!!currentCategoryFiltersChecked && currentCategoryFiltersChecked.length > 0)
                {
                    this.filterActive = true;
                    setFilter = true;
                }
            }
        }
    }

}