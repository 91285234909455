import { Component, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { FlowService } from 'src/app/services/flow/flow.service';
import { Action } from '../../models/actions/Action';
import { MenuItem } from '../../models/data/MenuItem';
import { MenuItems } from '../../models/data/MenuItems';
import { DisplayMode } from '../../models/DisplayMode';
import { ActionIdentifier, ScreenType, SubScreenType, MenuType } from '../../models/Enums';
import { ListDetail } from '../../models/ListDetail';
import { ThingService } from '../../services/thing/thing.service';
import { Utilities } from '../../services/utilities/utilities';
import { SwitchContentViewDialogComponent } from '../controls/dialogs/switch-content-view-dialog/switch-content-view-dialog.component';
import { ItemListComponent } from '../controls/lists/item-list/item-list.component';
import { PricingDialogComponent } from '../controls/pricing/pricing-dialog/pricing-dialog.component';
import { ViewMultipleComponent } from '../controls/view-multiple/view-multiple.component';
import { UpdateScreenDataEvent } from 'src/app/models/events/UpdateScreenDataEvent';
import { UpdateDetailComponent } from '../controls/update-detail/update-detail.component';
import { UpdateViewDialogComponent } from '../controls/dialogs/update-view-dialog/update-view-dialog.component';
import { TimelineDialogComponent } from '../controls/dialogs/timeline-dialog/timeline-dialog.component';
import { SideNavComponent } from '../controls/side-nav/side-nav.component';
import { RefreshEvent } from 'src/app/models/events/RefreshEvent';
import { BaseCalendarComponent } from '../controls/calendar/my-calendar/base-calendar.component';
import { SwitchContentComponent } from '../controls/switch-content-component/switch-content.component';
import { el } from 'date-fns/locale';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class MainComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  ScreenType = ScreenType;
  SubScreenType = SubScreenType;
  MenuType = MenuType;

  public _showSideBar = false;

  gridHeightMargin: number = 56 + 50 + 2; // Top Navigation + Breadcrumb
  gridHeight: number = Utilities.innerHeight(this.gridHeightMargin);
  gridWidth: number = Utilities.innerWidth() - this.sidebarWidth;
  showMenu = true;

  _showBackdrop = false;
  id: string;
  sub: any;
  menuItem: MenuItem;

  listDetails: ListDetail[] = [];
  activeListDetail: ListDetail;

  viewStateRow: any;
  viewStateAction: Action;

  updateStates: AddUpdateItemModel[] = [];
  updateState: AddUpdateItemModel;
  updateItem = false;

  sideBarTitle: string;
  sideBarSubTitle: string;

  toolbarItems: MenuItem[] = [];
  toolbarItems2: MenuItem[] = [];

  mode: DisplayMode = DisplayMode.main;

  productAction: Action;
  productRow: any;
  menuItems: MenuItem[];
  actionMenuItems: MenuItem[];

  sidebarMode: 'over' | 'push' = 'over';
  sideBarKeyCode = 27;
  previousDetail: ListDetail;
  previousDetails: ListDetail[] = [];

  currentUpdateDialog: MatDialogRef<UpdateViewDialogComponent, any>;
  secondaryUpdateDialog: MatDialogRef<UpdateViewDialogComponent, any>;

  @ViewChildren(ItemListComponent) itemLists: QueryList<ItemListComponent>;
  @ViewChildren(UpdateDetailComponent) updateDetailComponents: QueryList<UpdateDetailComponent>;
  @ViewChildren(ViewMultipleComponent) viewMultipleLists: QueryList<ViewMultipleComponent>;
  @ViewChild(BaseCalendarComponent) calendarComponent: BaseCalendarComponent;
  @ViewChild(SwitchContentComponent) switchContentComponent: SwitchContentComponent;


  constructor(private thingService: ThingService, private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog, private zone: NgZone) { }

  ngOnInit() {

    this.sub = this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.id = params['id'];
        this.onReloadPage();
      });
  }

  onReloadPage() {

    this.thingService.getRootMenu(1)
      .pipe(takeUntil(this.destroy$))
      .subscribe(items => this.onGetTopLevelMenuSuccessful(items));

    // this.thingService.rootMenu$
    // .pipe(takeUntil(this.destroy$))
    // .subscribe(result => {
    //   this.onGetTopLevelMenuSuccessful(result)});

  }

  onResize(event) {

    this.gridHeight = Utilities.innerHeight(this.gridHeightMargin);
    this.gridWidth = Utilities.innerWidth() - this.sidebarWidth;
  }

  get sidebarWidth(): number {
    return this._showSideBar ? 170 : 75;
  }

  get sideBarHeight(): any {
    return this.gridHeight + 50;
  }

  get formHeight(): any {
    return this.gridHeight - 23;
  }

  isEnabled(value: string): boolean {
    return (DisplayMode[value] === this.mode);
  }

  onGetTopLevelMenuSuccessful(menu: MenuItems) {

    if (!menu || !menu.menuItems) { return; }

    if (!this.id) {
      this.id = "home";
    }

    this.menuItems = menu.menuItems;
    // console.log(this.menuItems);

    // Clear all existing lists
    this.listDetails = [];

    this._showSideBar = false;

    // Find menu item action
    this.menuItem = menu.menuItems.find(m => m.action && m.action.length > 0 && m.action[0].actionArgument.safeUrlPath === this.id);

    if (this.menuItem != null && this.id != "home") {
      this.onAction({ action: this.menuItem.action[0], row: null, screenParameters: null, newItem: false });
    }
    else {
      // this means it's a hardcoded menu item that's been clicked (home/preferences)
      var action = new Action();
      action.action = ActionIdentifier.LaunchScreen;
      action.actionArgument = new LaunchScreenAction();

      if (this.id === "home") {
        action.actionArgument.screenType = ScreenType.Home
        this.actionMenuItems = this.menuItems.filter(x => x.appScreenCanvas == 1);
      }
      else if (this.id === "preferences") {
        action.actionArgument.screenType = ScreenType.Preferences;
        this.actionMenuItems = this.menuItems.filter(x => x.appScreenCanvas == 4);

      }

      this.onAction({ action: action, row: null, screenParameters: null, newItem: false });

    }

    // get the flows here
    if (!FlowService.flowGroups) {
      this.thingService.getFlowGroupFlowAndLookupsStatus().subscribe(results => {
        FlowService.setup(results[0], results[1], results[2], results[3], results[4]);

      });
    }
  }

  handleAction(action: Action) {
    this.onAction({ action: action, row: null, screenParameters: null, newItem: false });
  }

  public onAction(event: ViewDetailEvent) {

    // console.log('onAction');
    
    const action = event.action as Action;
    const homeDetail: ListDetail = new ListDetail();

    if (action.action === ActionIdentifier.LaunchScreen || action.action === ActionIdentifier.LaunchFlyout) {

      const actionArg = action.actionArgument as LaunchScreenAction;

      switch (actionArg.screenType) {
        case ScreenType.EditDetails:
        case ScreenType.ViewDetails:
          this.onView(event);
          // this.onAdd({ action: actionArg, row: event.row, screenParameters: event.screenParameters, newItem: event.newItem });
          break;

        case ScreenType.SwitchContentView:
          const existingSwitchContentViewDetail: ListDetail = this.listDetails
            .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

          if (existingSwitchContentViewDetail) {
            if(this.previousDetails){
              var prev = this.previousDetails.pop();
              if(prev == this.previousDetail){
                this.previousDetail = this.previousDetails.pop();
              }
              else{
                this.previousDetail = prev;
              }
            }
            this.onSelect(existingSwitchContentViewDetail);
          } else {

            const listDetail: ListDetail = new ListDetail();
            listDetail.action = event.action;
            listDetail.row = event.row;
            listDetail.screenType = actionArg.screenType;

            if (!!this.listDetails && this.listDetails.length > 0) {
              this.previousDetail = this.listDetails[this.listDetails.length - 1];
              this.previousDetails.push(this.previousDetail);
            }

            this.listDetails.push(listDetail);
            this.activeListDetail = listDetail;
          }
          break;

        case ScreenType.ItemGridList:

          const existingListDetail: ListDetail = this.listDetails
            .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

          if (existingListDetail) {
            this.onSelect(existingListDetail);
          } else {

            const listDetail: ListDetail = new ListDetail();
            listDetail.action = event.action;
            listDetail.row = event.row;
            listDetail.screenType = actionArg.screenType;

            if (!!this.listDetails && this.listDetails.length > 0) {
              this.previousDetail = this.listDetails[this.listDetails.length - 1];
              this.previousDetails.push(this.previousDetail);
            }

            this.listDetails.push(listDetail);
            this.activeListDetail = listDetail;
          }
          break;

        case ScreenType.CompositeScreen:

          const existingCompositeViewDetail: ListDetail = this.listDetails
            .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

          if (existingCompositeViewDetail) {
            this.onSelect(existingCompositeViewDetail);
          } else {

            const listDetail: ListDetail = new ListDetail();
            listDetail.action = event.action;
            listDetail.row = event.row;
            listDetail.screenType = actionArg.screenType;

            this.listDetails.push(listDetail);
            this.activeListDetail = listDetail;
          }
          break;
        case ScreenType.TimelineView:
          const existingTimeLineDetail: ListDetail = this.listDetails
            .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

          if (existingTimeLineDetail) {
            this.onSelect(existingTimeLineDetail);
          } else {

            const listDetail: ListDetail = new ListDetail();
            listDetail.action = event.action;
            listDetail.row = event.row;
            listDetail.screenType = actionArg.screenType;

            this.listDetails.push(listDetail);
            this.activeListDetail = listDetail;
          }
          break;
        case ScreenType.TreeView:
          const existingTreeViewDetail: ListDetail = this.listDetails
            .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

          if (existingTreeViewDetail) {
            this.onSelect(existingTreeViewDetail);
          } else {

            const listDetail: ListDetail = new ListDetail();
            listDetail.action = event.action;
            listDetail.row = event.row;
            listDetail.screenType = actionArg.screenType;

            this.listDetails.push(listDetail);
            this.activeListDetail = listDetail;
          }
          break;

        case ScreenType.ProductCatalogue:
          this._showSideBar = false;

          const productCatalogueDetail: ListDetail = new ListDetail();
          productCatalogueDetail.action = event.action;
          productCatalogueDetail.row = event.row;
          productCatalogueDetail.screenType = actionArg.screenType;

          this.activeListDetail = productCatalogueDetail;
          break;

        case ScreenType.OrderCheckout:
          this._showSideBar = false;

          const orderCheckoutDetail: ListDetail = new ListDetail();
          orderCheckoutDetail.action = event.action;
          orderCheckoutDetail.row = event.row;
          orderCheckoutDetail.screenType = actionArg.screenType;

          this.activeListDetail = orderCheckoutDetail;
          break;

        case ScreenType.ShoppingBasket:
          // this.router.navigate(['/main/' + 'shoppingBasket']);
          this.onDisplayScreen(event);
          break;
        case ScreenType.Preferences:
        case ScreenType.Home:
          homeDetail.action = event.action;
          homeDetail.row = event.row;
          homeDetail.screenType = actionArg.screenType

          this.activeListDetail = homeDetail;
          break;
        case ScreenType.Calendar:
          const existingCalendarDetail: ListDetail = this.listDetails
            .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

          if (existingCalendarDetail) {
            this.onSelect(existingCalendarDetail);
          } else {

            const listDetail: ListDetail = new ListDetail();
            listDetail.action = event.action;
            listDetail.row = event.row;
            listDetail.screenType = actionArg.screenType;

            this.activeListDetail = listDetail;
          }
        case ScreenType.Gantt:
          const existingGanttDetail: ListDetail = this.listDetails
            .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

          if (existingGanttDetail) {
            this.onSelect(existingGanttDetail);
          } else {

            const listDetail: ListDetail = new ListDetail();
            listDetail.action = event.action;
            listDetail.row = event.row;
            listDetail.screenType = actionArg.screenType;

            this.activeListDetail = listDetail;
          }
          break;
        default:
          alert(`Main: Screen Type not supported ***! ${actionArg.screenType}`);
      }
    } else if (event.action.action === ActionIdentifier.DisplayDialog) {


      if (event.action.actionArgument.screenType == ScreenType.PricingScreen) {

        const dialogRef = this.dialog.open(PricingDialogComponent, {
          width: '1000px',
          data: { action: event.action, row: event.row }
        });

        dialogRef.afterClosed().subscribe(result => {
          // TODO should pass the primaryKey generically - right now not sure why this event has the 'new' flag on it...
          const e = {
            row: event.row,
            primaryKey: event.row.productID,
            newItem: false
          };
          this.onUpdateCompleted(e);
        });

      }
    } else {
      alert(`Main: Action not supported! ${action.action}`);
    }
  }

  public isItemListScreen(screenType: ScreenType) {
    return screenType === ScreenType.ItemGridList ||
      screenType === ScreenType.ItemCardList ||
      screenType === ScreenType.SectionCardList //||
    // TODO SwitchContentView shouldn't be in here but for now it works!
    //screenType === ScreenType.SwitchContentView;
  }

  public onBack() {
    this.onSelect(this.listDetails[this.listDetails.length - 2]);
    this.onResize(null);
  }

  public onSelect(listDetail: ListDetail) {

    if (listDetail) {
      this.activeListDetail = listDetail;
    } else {
      this.activeListDetail = this.listDetails[0];
    }

    // Collapse breadcrumbs
    const index = this.listDetails.indexOf((this.activeListDetail));

    if (index < (this.listDetails.length + 1)) {
      this.listDetails.splice(index + 1, this.listDetails.length - index + 1);
    }
    if(this.listDetails.length == 1){
      this.previousDetail = null;
    }
    else{
      this.previousDetail = this.listDetails[this.listDetails.length - 2];
    }
    this.onResize(null);
  }

  toggleSidebar() {

    this._showSideBar = !this._showSideBar;

    if (!this._showSideBar) {
      this._showBackdrop = false;
      this.updateItem = false;
    }

    this.viewStateAction = null;
    this.viewStateRow = null;
    this.updateStates = [];
    this.updateState = null;
  }

  // value = {row, action}
  // public onView(value: ViewDetailEvent) {

  //   if (value === null) {
  //     this._showSideBar = false;
  //     this.viewStateAction = null;
  //     this.viewStateRow = null;
  //   } else {
  //     const action = value.action.actionArgument as LaunchScreenAction;
  //     this._showSideBar = true;
  //     this.sidebarMode = action.screenPosition === 'push' ? 'push' : 'over';

  //     // Needed for generating bulk actions in sidebar
  //     this.toolbarItems = value.toolbarItems;
  //     this.toolbarItems2 = value.toolbarItems2;

  //     if (value.length !== 1) {
  //       this.viewStateAction = null;
  //       this.viewStateRow = null;
  //       this.onSetTitle({ id: '', title: 'Bulk Action', subtitle: '' });
  //     } else {
  //       if (value.row) {
  //         if (action.screenType === ScreenType.ViewDetails) {
  //           this.viewStateAction = value.action;
  //           this.viewStateRow = value.row;
  //         } else if (action.screenType === ScreenType.EditDetails) {
  //           this.onUpdate({ id: action.name, row: value.row, action: action, newItem: false, screenParameters: value.screenParameters });
  //         }
  //       } else {
  //         this.onAdd({ id: action.name, action: action, newItem: true, row: null, screenParameters: null, uriCollections: null });
  //       }
  //     }
  //   }
  // }

  public onView(value: ViewDetailEvent) {

    if (value === null) {
      this._showSideBar = false;
      this.viewStateAction = null;
      this.viewStateRow = null;
    } else {
      const action = value.action.actionArgument as LaunchScreenAction;
      // this._showSideBar = true;
      // this.sidebarMode = action.screenPosition === 'push' ? 'push' : 'over';

      // Needed for generating bulk actions in sidebar
      this.toolbarItems = value.toolbarItems;
      this.toolbarItems2 = value.toolbarItems2;

      if (value.length > 1) {
        this.viewStateAction = null;
        this.viewStateRow = null;
        this.onSetTitle({ id: '', title: 'Bulk Action', subtitle: '' });
      } else {
        if (value.row) {
          if (action.screenType === ScreenType.ViewDetails) {
            this.viewStateAction = value.action;
            this.viewStateRow = value.row;

            this.zone.run(() => {
              var viewDialog = this.dialog.open(UpdateViewDialogComponent, {
                width: `${Utilities.innerWidth()}px`,
                height: `${this.sideBarHeight}px`,
                data: {
                  title: this.sideBarTitle,
                  sideBarSubTitle: this.sideBarSubTitle,
                  updateItem: this.updateItem,
                  toolbarItems: this.toolbarItems,
                  toolbarItems2: this.toolbarItems2,
                  viewStateRow: this.viewStateRow,
                  formHeight: this.formHeight,
                  viewStateAction: this.viewStateAction,
                  updateState: this.updateState,
                  updateStates: this.updateStates
                  // previousDialogTitle: previousDialogTitle
                }

              });
              viewDialog.componentInstance.notifyUpdated.subscribe(x => {
                this.onUpdate(x);
                viewDialog.close();
              });

              viewDialog.componentInstance.notifyDeleted.subscribe(x => {
                this.onDeleteCompleted(x);
                viewDialog.close();
              });
            })

          } else if (action.screenType === ScreenType.EditDetails) {
            this.onUpdate({ id: action.name, row: value.row, action: action, newItem: false, screenParameters: value.screenParameters });
          }
          else if (action.screenType === ScreenType.TimelineView) {
            this.openTimeLineDialog(value.action);
          }
        } else {
          this.onAdd({ id: action.name, action: action, newItem: true, row: null, screenParameters: null, uriCollections: null });
        }
      }
    }
  }

  // public onAdd(value: AddUpdateItemModel) {

  //   const action = value.action as LaunchScreenAction;
  //   this.sidebarMode = action.screenPosition === 'push' ? 'push' : 'over';
  //   this._showSideBar = true;
  //   if (this.sidebarMode === 'over') {
  //     this._showBackdrop = true;
  //   }
  //   this.updateItem = true;
  //   this.updateStates.push(value);
  //   this.updateState = value;
  // }

  public onAdd(value: AddUpdateItemModel) {

    // this._showBackdrop = true;
    this.updateItem = true;
    this.updateStates.push(value);
    this.updateState = value;
    this.openUpdateDialog();
  }


  private openTimeLineDialog(action: Action) {
    // <app-view-timeline [id]=listDetail.id [height]=gridHeight [width]='gridWidth - 100'
    // [action]=listDetail.action [linkedRow]=listDetail.row></app-view-timeline>
    var timelineDialog = this.dialog.open(TimelineDialogComponent, {
      width: `${Utilities.innerWidth()}px`,
      height: `${this.sideBarHeight}px`,
      data: {
        action: action,
        width: Utilities.innerWidth(),
        height: this.sideBarHeight,
        linkedRow: action.row,
      },
      panelClass: 'custom-modalbox'
    });

  }

  private openUpdateDialog() {
    var previousDialogTitle;
    if (!!this.currentUpdateDialog && !!this.currentUpdateDialog.componentInstance) {
      previousDialogTitle = this.currentUpdateDialog.componentInstance.data['title'];
    }

    var updateDialog = this.dialog.open(UpdateViewDialogComponent, {
      width: `${Utilities.innerWidth()}px`,
      height: `${this.sideBarHeight}px`,
      data: {
        title: this.sideBarTitle,
        sideBarSubTitle: this.sideBarSubTitle,
        updateItem: this.updateItem,
        toolbarItems: this.toolbarItems,
        toolbarItems2: this.toolbarItems2,
        viewStateRow: this.viewStateRow,
        formHeight: this.formHeight,
        viewStateAction: this.viewStateAction,
        updateState: this.updateState,
        updateStates: this.updateStates,
        previousDialogTitle: previousDialogTitle
      }
    });

    if (!this.currentUpdateDialog) {
      this.currentUpdateDialog = updateDialog;
    }

    else {
      this.secondaryUpdateDialog = this.currentUpdateDialog;
      this.secondaryUpdateDialog.addPanelClass('hide');
      this.currentUpdateDialog = updateDialog;
    }

    updateDialog.componentInstance.notifyUpdated.subscribe(x => {
      this.onUpdate(x);
      updateDialog.close();
    });

    updateDialog.componentInstance.notifySaveComplete.subscribe(event => {
      if (!!this.secondaryUpdateDialog) {
        this.secondaryUpdateDialog.removePanelClass('hide');
        var currentDialog = this.currentUpdateDialog;
        var secondaryDialog = this.secondaryUpdateDialog;
        this.currentUpdateDialog = secondaryDialog;
        this.secondaryUpdateDialog = currentDialog;
        // if (!!event)
        // {
        //   this.currentUpdateDialog.componentInstance.setSavedObject(event);
        // }

        this.secondaryUpdateDialog.close();
        if(this.currentUpdateDialog && this.currentUpdateDialog.componentInstance){
          this.currentUpdateDialog.componentInstance.refreshScreenOnReturn();
        }
      }
      else {
        updateDialog.close();
      }
      this.onSaveCompleted();


    });

    updateDialog.componentInstance.navigateClosed.subscribe(x => {
      if (!!this.secondaryUpdateDialog) {
        this.secondaryUpdateDialog.close();
      }
    });

    updateDialog.componentInstance.navigateBack.subscribe(x => {
      this.secondaryUpdateDialog.removePanelClass('hide');
      var currentDialog = this.currentUpdateDialog;
      var secondaryDialog = this.secondaryUpdateDialog;
      this.currentUpdateDialog = secondaryDialog;
      this.secondaryUpdateDialog = currentDialog;
      this.secondaryUpdateDialog.close();
      this.currentUpdateDialog.componentInstance.refreshScreenOnReturn();
    });

    updateDialog.componentInstance.notifyDeleted.subscribe(x => {
      this.onDeleteCompleted(x);
      updateDialog.close();
    });

    updateDialog.componentInstance.notifyAction.subscribe(x => {
      this.onAction(x);
    });

    updateDialog.afterClosed().subscribe(x => {
      if (!!x) {  //x === typeof (UpdateCompletedEvent)
        this.onUpdateCompleted(x);
      }
      this.updateItem = false;
    });
  }

  onSidebarOpenChange(open: boolean) {
    this._showSideBar = open;
    if (this.sidebarMode === 'push') {
      if (this.itemLists.last && this.itemLists.last.id === this.activeListDetail.id) {
        setTimeout(() => {
          this.itemLists.last.updateWidth();
        }, 1000);
      }
    }
  }

  // value = {row, action}
  public onUpdate(value: AddUpdateItemModel) {

    DataUtils.setUriCollections(this.thingService, value.action.uris, value.row, value.screenParameters)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => {
        value.uriCollections = results;

        this.updateStates.push(value);
        this.updateState = value;
        this.updateItem = true;
        this.openUpdateDialog();
      }, error => {
        this.updateStates.push(value);
        this.updateState = value;
        this.updateItem = true;
        this.openUpdateDialog();

      });
  }

  public onTitleUpdated(event: TitleUpdateEvent) {

    const listDetail = this.listDetails.find(m => m.id === event.id);

    if (listDetail) {
      listDetail.title = event.title;
    }
  }

  //Trevor: Commented out 24/08/21 as the event is never fired from update-details.component
  // public sidebarNavigateBack(action: LaunchScreenAction) {

  //   this.updateStates.pop();
  //   this.updateState = this.updateStates.length > 0 ? this.updateStates[this.updateStates.length - 1] : null;
  //   if (this.updateState) {
  //     // trigger refresh
  //     this.updateState.uriCollections = null;

  //     this.updateState.action = action;
  //   }
  //   this._showSideBar = this.updateState != null;
  // }

  public onUpdateCompleted(event: UpdateCompletedEvent) {

    if (event.newItem) {

      // Add row to table
      // this.activeAction.rows.push(value.row);

      if (event.closeFlyout) {
        this.viewStateAction = null;
        this.viewStateRow = null;
        this.updateStates = [];
        this.updateState = null;
        this._showSideBar = false;
      }


      // Refresh table
      if (this.itemLists.last && this.itemLists.last.id === this.activeListDetail.id) {
        this.itemLists.last.refresh(event);
      } else if (this.viewMultipleLists.last && this.viewMultipleLists.last.id === this.activeListDetail.id) {
        this.viewMultipleLists.last.refresh(event);
      } else if (this.switchContentComponent && this.switchContentComponent.itemLists.last && this.switchContentComponent.itemLists.last.id === this.activeListDetail.id){
        this.switchContentComponent.itemLists.last.refresh(event);
      }

    } else {
      //refresh calendar
      if (!!this.calendarComponent) {
        this.calendarComponent.refreshData();
      }
      if(!!this.switchContentComponent){
        this.switchContentComponent.refreshData();
      }

      // Need a better approach so individual row is refreshed rather than whole grid
      // Refresh table
      if (this.itemLists.last && this.itemLists.last.id === this.activeListDetail.id) {
        this.itemLists.last.refresh(event);
      } else if (this.viewMultipleLists.last && this.viewMultipleLists.last.id === this.activeListDetail.id) {
        this.viewMultipleLists.last.refresh(event);
      } else if (this.switchContentComponent && this.switchContentComponent.itemLists.last && this.switchContentComponent.itemLists.last.id === this.activeListDetail.id){
        this.switchContentComponent.itemLists.last.refresh(event);
      }
    }

    if (event.closeFlyout) {
      this._showBackdrop = false;
      this.updateItem = false;
      this._showSideBar = false;
    }
  }

  public onDeleteCompleted(event: DeleteEvent) {

    // Delete row from grid
    /*     let item = this.activeAction.rows.find(r => r[event.name] == event.value);
        if (item) {
          let index = this.activeAction.rows.indexOf(item);
          if (index !== -1) {
            this.activeAction.rows.splice(index, 1);
            // Refresh table
            this.itemLists.last.refresh();
          }
        } */

    this.viewStateAction = null;
    this.viewStateRow = null;
    this._showBackdrop = false;
    this.updateItem = false;
    this._showSideBar = false;

    if (this.itemLists.last && this.itemLists.last.id === this.activeListDetail.id) {
      var rEvent: RefreshEvent = { increment: -1 };
      this.itemLists.last.refresh(rEvent);
    } else if (this.viewMultipleLists.last && this.viewMultipleLists.last.id === this.activeListDetail.id) {
      this.viewMultipleLists.last.refresh();
    } else if (this.switchContentComponent && this.switchContentComponent.itemLists.last && this.switchContentComponent.itemLists.last.id === this.activeListDetail.id){
      this.switchContentComponent.itemLists.last.refresh(rEvent);
    }
  }

  public onSaveCompleted() {
    this.viewStateAction = null;
    this.viewStateRow = null;
    this._showBackdrop = false;
    this.updateItem = false;
    this._showSideBar = false;

    if (this.itemLists.last && this.itemLists.last.id === this.activeListDetail.id) {
      var rEvent: RefreshEvent = { increment: 1 };
      this.itemLists.last.refresh(rEvent);
    } else if (this.viewMultipleLists.last && this.viewMultipleLists.last.id === this.activeListDetail.id) {
      this.viewMultipleLists.last.refresh();
    } else if (this.switchContentComponent){
      if(this.switchContentComponent.itemLists && this.switchContentComponent.itemLists.last.id === this.activeListDetail.id) {
        this.switchContentComponent.itemLists.last.refresh();
      }
    }


    //refresh calendar
    if (!!this.calendarComponent) {
      this.calendarComponent.refreshData();
    }
  }

  public onCancel() {

    this._showBackdrop = false;
    this.updateItem = false;

    if (!this.viewStateRow) {
      this._showSideBar = false;
    }
  }

  public onSetTitle(value: TitleUpdateEvent) {

    this.sideBarTitle = value.title;
    this.sideBarSubTitle = value.subtitle;
  }

  public onUpdateScreenData(event: UpdateScreenDataEvent) {

    const updateScreen = this.updateDetailComponents.find(i => i.callingAction.id === event.targetScreen);
    if (updateScreen) {
      const coll = updateScreen.uriCollections.find(u => u.name === event.targetDataObject);
      if (!coll.dataValues) {
        coll.dataValues = [];
      }
      coll.dataValues.push(event.newData);
      // trigger grid refresh
      updateScreen.onCompositeAdd();
    }
  }

  public onClick(item: MenuItem) {

    if (item.action[0].action === ActionIdentifier.DeleteItem) {

      this.itemLists.last.deleteSelected(item.action[0]);

    }
  }

  public onDisplayScreen(event: ViewDetailEvent) {

    this.mode = DisplayMode.product;
    this.productAction = JSON.parse(JSON.stringify(event.action));
    this.productRow = event.row;
  }

  public onCloseScreen(event: any) {

    this.mode = DisplayMode.main;

  }

  public onSideBarEsc(event) {

    if (event) {
      this.sideBarKeyCode = 27;
    } else {
      this.sideBarKeyCode = 0;
    }

  }

  public onDisplaySwitchContentDialog(event) {

    const dialogRef = this.dialog.open(SwitchContentViewDialogComponent, {
      width: '1000px',
      data: { action: event.action, row: event.row }
    });

    dialogRef.afterClosed().subscribe(result => {
      Utilities.log('Switch Content View Dialog closed');

    });

  }

  onMenuOpen(event: boolean) {
    this.showMenu = event;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  isProductCatalogue(activeListDetail: ListDetail) {
    return activeListDetail && activeListDetail.screenType === ScreenType.ProductCatalogue;
  }

  clearMenu() {
    this.thingService.clearRootMenu();
  }
}
