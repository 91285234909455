import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventType } from '../entities/eventType';
import { SubEventType } from '../entities/subEventType';
import { User } from '../entities/user';
@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.scss']
})
export class ViewEventComponent implements OnInit {

  event: any;

  eventType: EventType;

  subEventType: SubEventType | undefined;

  eventUser: User | undefined;

  @Output() onSave = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<ViewEventComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.authService.getAllUsers().subscribe(x => {
    //   this.eventUser = x.find(x => x.id == this.data.event.userId);
    // })

    this.event = this.data.event;
    this.getEventType();
  }

  getEventType()
  {
    var eventType = this.data.eventTypes.find((x: { id: number | undefined; }) => x.id == this.event.eventTypeId);
    if (!!eventType)
    {
      this.eventType = eventType;
      this.subEventType = this.eventType.subEventTypes.find(y => y.id == this.event.subEventTypeId);
    }
  }
}