import { Action } from '../actions/Action';
import { MenuItem } from '../data/MenuItem';
import { CardItemType } from '../Enums';
import { ICardModel } from './ICardModel';

export class CardItem {

  cardItemTypeId: CardItemType;
  tag: string;
  dataObjectKey: string;
  cardItemObject: ICardModel;
  allowRowReordering: boolean;
  allowMoveBetweenLevels: boolean;
  nestBy: string;
  groupBy: string;
  orderBy: string;
  onClickAction: Action[];
  onAddAction: Action[];
  onEditAction: Action[];
  onRemoveAction: Action[];
  onReorderAction: Action[];
  onChangeParentAction: Action[];
  onDragAction: Action[];
  onDropAction: Action[];
  contextMenu: MenuItem[];

  isEnabledCondition?: string;
  isVisibleCondition?: string;
}
