import { Injectable } from '@angular/core';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { Utilities } from '../utilities/utilities';
import { ThingService } from '../thing/thing.service';
import { forkJoin, of, Observable, throwError } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { UIDataType, DataType } from 'src/app/models/Enums';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';

@Injectable({
  providedIn: 'root'
})
export class DataUtils {

  public static getMetadatumAndDataPath(path: string, metadata: FTThingDetail[]): [FTThingDetail, DataPath] {

    if (!path || !metadata) {
      return;
    }

    const dataPath = new DataPath(path);
    if (!dataPath.propertyName) {
      return [null, null];
    }

    if (!dataPath.childObjectNames || dataPath.childObjectNames.length === 0) {
      return [metadata.find(c => c.name.toLowerCase() === dataPath.propertyName.toLowerCase()), dataPath];
    } else {
      let childObName: string;
      if (dataPath.childObjectNames[0].indexOf('[') > -1) {
        childObName = dataPath.childObjectNames[0].substr(0, dataPath.childObjectNames[0].indexOf('['));
      } else {
        childObName = dataPath.childObjectNames[0];
      }

      const rootMetadatum = metadata.find(c => c.name.toLowerCase() === childObName.toLowerCase());
      if (rootMetadatum && rootMetadatum.sourceDataTypeFTThing) {
        return [rootMetadatum.sourceDataTypeFTThing.find(c => c.name.toLowerCase() === dataPath.propertyName.toLowerCase()), dataPath];
      }
    }
  }

  public static setUriCollections(thingService: ThingService, uris: ActionURI[], dataItem: any,
    screenParameters?: any[]): Observable<UriCollection[]> {

    const nameList = uris.map(function (u) { return u.name; });
    const dataUriList = uris.map(function (u) {
      if (u.loadAtStart === undefined || u.loadAtStart === null) {
        u.loadAtStart = true;
      }
      return u.loadAtStart ? Utilities.parseArgumentsFromData(u.dataUri, u.dataUriArgs, dataItem, null, screenParameters) : '';
    });

    const metaDataList = uris.map(function (u) { return u.metaUri; });

    const uriCollections: UriCollection[] = [];
    nameList.forEach(n => {
      uriCollections.push({
        name: n,
        uris: uris.find(u => u.name === n),
        dataValues: []
      });
    });

    const uriList = [];
    uriList.push(...dataUriList);
    uriList.push(...metaDataList);

    const observableBatch = [];
    dataUriList.forEach((uri, key) => {
      observableBatch.push(thingService.getDataDetailWithHeader(uri, 0));
    });

    metaDataList.forEach((uri, key) => {
      observableBatch.push(thingService.getFTThingDetail(uri));
    });

    const r2 = forkJoin(observableBatch).pipe(map(result => {
      for (let i = 0; i < uriCollections.length; i++) {
        if (result[i]) {
          const r = result[i] as any;
          uriCollections[i].dataValues = r.dataItems;
        } else {
          // console.log('no data result for ' + dataUriList[i]);
        }
      }
      for (let i = 0; i < uriCollections.length; i++) {
        if (result[i + uriCollections.length]) {
          uriCollections[i].dataMetadata = result[i + uriCollections.length] as FTThingDetail[];
        } else {
          console.log('no metadata result for ' + metaDataList[i]);
        }
      }
      return uriCollections;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));

    return r2;
  }

  public static addMissingLinkedObjects(row: any, dataMetadata: FTThingDetail[]): any {
    const linkedObjects = dataMetadata
      .filter(m => m.sourceDataType)
      .map(m => m.javaScriptName);

    linkedObjects.forEach(linkedObject => {
      if (!row[linkedObject]) {
        row[linkedObject] = {};
      }
    });
    return row;
  }

  public static dataTypeToUIDataType(dataType: DataType, referenceTo: String): UIDataType {

    if (!dataType) {
      return UIDataType.Unknown;
    }

    if (referenceTo) {
      if (referenceTo.toLowerCase().startsWith('flows.') || referenceTo.toLowerCase().startsWith('ftflowstatus.')) {
        return UIDataType.FlowStatus;
      }
      if (referenceTo.toLowerCase().startsWith('lookups.')) {
        return UIDataType.Lookup;
      }
    }
    switch (dataType) {
      // case DataType.Unknown:
      //   return UIDataType.Unknown;

      case DataType.TinyInt:
      case DataType.SmallInt:
      case DataType.Int:
      case DataType.BigInt:
      case DataType.Decimal:
      case DataType.Float:
        return UIDataType.Number;

      case DataType.Bool:
        return UIDataType.Bool;

      case DataType.Char:
      case DataType.VarChar:
      case DataType.VarCharMax:
      case DataType.NChar:
      case DataType.NText:
      case DataType.NVarChar:
      case DataType.NVarCharMax:
        return UIDataType.String;

      case DataType.DateTime:
        return UIDataType.DateTime;

      case DataType.Money:
        return UIDataType.Money;
      case DataType.Guid:
        return UIDataType.Guid;
      case DataType.VarBinary:
        return UIDataType.Binary;

      case DataType.TagList:
        return UIDataType.TagList;

      case DataType.ListInt:
        return UIDataType.ListInt;

      default:
        console.log(`No known conversion to UIDataType for DataType ${dataType}`);
        return UIDataType.Unknown;
    }
  }
}
