import { Component, OnInit, Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion'
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatCardSubtitle } from '@angular/material';

import { ThingService } from '../../../services/thing/thing.service';
import { FlowService } from '../../../services/flow/flow.service';
import { Utilities } from '../../../services/utilities/utilities';

import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../dialogs/alert-dialog/alert-dialog.component';

import { Action } from 'src/app/models/actions/Action';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { ScreenDetail, DetailSection } from 'src/app/models/ScreenDetail';
import { ScreenType, DataType, UIDataType, ViewType, TemplateType, FilterType, InstanceStyle } from 'src/app/models/Enums';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { PostResponse, ValidationResponse } from 'src/app/models/PostResponse';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { DataDetail } from 'src/app/models/DataDetail';
import { ActionIdentifier } from 'src/app/models/Enums';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { ViewDetailItem } from 'src/app/models/viewGroupHelpers/ViewDetailItem';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';
import { ActionEvent } from 'src/app/models/events/ActionEvent';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
// import { InvokeShoppingBasketEvent } from 'src/app/models/events/InvokeShoppingBasketEvent';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { FilePagerDialogComponent } from '../dialogs/file-pager-dialog/file-pager-dialog.component';
import { FtFile } from 'src/app/models/dataService/FtFile';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { UpdateDetailItem } from 'src/app/models/viewGroupHelpers/UpdateDetailItem';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { TextArgument } from 'src/app/models/TextModel';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { IconService } from 'src/app/services/icon/icon.service';
import { I } from '@angular/cdk/keycodes';
import { DetailUtilities } from 'src/app/services/utilities/detailUtilities';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-view-detail',
  templateUrl: './view-detail.component.html',
  styleUrls: ['./view-detail.component.scss'],
  providers: [DetailUtilities]
})
export class ViewDetailComponent implements OnInit, OnDestroy {

  @Output() itemMenuActions = new EventEmitter<any[] & MenuItem[]>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  public TemplateType = TemplateType;
  public InstanceStyle = InstanceStyle;
  public DataType = UIDataType;
  public ViewType = ViewType;
  public FilterType = FilterType;

  loadingIndicator = true;

  columnFieldGroups: DetailSection[];
  // columnFields: ViewDetailItem[] = [];

  toolbarItems: MenuItem[] = [];
  idName: string; // Used to track name being used for primary key, when deleting an item
  row: any;
  uriCollections: UriCollection[] = [];

  @Input() id: string;
  @Input() loadScreenDetail = true;
  @Input() height: number;
  @Input() screenParameters: any;
  @Input() screenUri: string;
  // @Input() action: Action;

  _linkedRow: any;
  _screenDetail: ScreenDetail;
  _action: Action;

  @Output() onUpdate = new EventEmitter<AddUpdateItemModel>();
  @Output() onDeleteCompleted = new EventEmitter<DeleteEvent>();
  @Output() onSetTitle = new EventEmitter<TitleUpdateEvent>();
  // @Output() onDisplayScreen = new EventEmitter<InvokeShoppingBasketEvent>();
  @Output() onAction = new EventEmitter<ViewDetailEvent>();
  // @Output() viewItemEvent = new EventEmitter<ViewDetailEvent>();
  refreshScreenDetail = true;
  _screenParameters: any;
  screenDetailComponents: ScreenDetail;

  constructor(public toastr: ToastrService,
    private thingService: ThingService,
    public dialog: MatDialog,
    private iconHelper: IconHelper,
    private iconService: IconService,
    private http: HttpClient,
      private sanitizer: DomSanitizer,
      private detailUtils: DetailUtilities) { }

  ngOnInit() {
  }

  getRootObjectArray(path: string) {

    const item = new DataPath(path);
    if (item.rootObjectName) {
      let rootOb: any;
      const uriCollection = this.uriCollections.find(u => u.name === item.rootObjectName);
      if (uriCollection && uriCollection.dataValue) {
        if (item.childObjectNames[0]) {
          rootOb = uriCollection.dataValue[item.childObjectNames[0]][item.propertyName];
        } else {
          rootOb = uriCollection.dataValue[item.propertyName];
        }
      } else {
        // Utilities.log2('UpdateDetail', `Can't find a uriCollection for ${item.objectName} (${item.propertyName})`, 'warn');
      }
      return rootOb;
    }
  }

  getText(text: string, argumentIds: string[]) {
    const r = Utilities.parseArgumentsFromUriCollection(text, argumentIds, this.uriCollections);
    return r;
  }

  public onClick(item: MenuItem) {

    if (item.action) {

        // const saved = this.onSave({ closeOnFinish: false, objectKey: null }, this.saveCompleteEvent);

        // this.updateIndicator = true;
        // this.onUpdateIndicator.emit(true);

        // this.saveCompleteEvent.subscribe(s => {
        //   this.updateIndicator = true;
        //   this.onUpdateIndicator.emit(true);
        //   if (s) {
        //     if (item.action[0].actionArgument.actionData && item.action[0].actionArgument.actionData.rowKey) {
        //       row = this.getUriDataItem(item.action[0].actionArgument.actionData.rowKey);
        //     } else {
        //       row = this.getUriDataItem(this.screenDetail.componentScreens[0].detailSections[0].templateDataKey);
        //     }
        //     const launchScreenAction = item.action[0].actionArgument as LaunchScreenAction;
        //     this.onUpdate.emit({
        //       id: launchScreenAction.name, row: row, action: launchScreenAction,
        //       newItem: item.menuItemType !== MenuType.BackPressed, screenParameters: this.screenParameters
        //     });
        //   }
        // });

      if (item.action[0].action === ActionIdentifier.LaunchFlyout) {
        const launchScreenAction = item.action[0].actionArgument as LaunchScreenAction;
        this.onUpdate.emit({ id: launchScreenAction.name, row: this.row, action: launchScreenAction, newItem: false, screenParameters: this.screenParameters });
        // } else if (item.action[0].action === ActionIdentifier.LaunchScreen && item.action[0].actionArgument.screenType === ScreenType.ShoppingBasket) {

        //   this.onDisplayScreen.emit({ action: item.action[0], row: this.row });
      } else if (item.action.find(a => a.action === ActionIdentifier.DisplayDialog || a.action === ActionIdentifier.LaunchScreen)) {

        const action: Action = JSON.parse(JSON.stringify(item.action[0]));

        Utilities.log2('ViewDetail component', 'onClick(menuItem)');
        Utilities.log(JSON.stringify(action));

        if (action.actionArgument.uris) {
          for (const uri of action.actionArgument.uris) {

            if (uri.dataUri) {
              uri.dataUri = Utilities.parseArgumentsFromData(uri.dataUri, uri.dataUriArgs, this.row, null, this.screenParameters);
            }

          }
        }
        this.onAction.emit({ action: action, row: this.row, newItem: true, screenParameters: null });
      } else if (item.action[0].action === ActionIdentifier.DeleteItem) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '450px',
          data: { message: 'Are you sure you want to delete this item?' }
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result) {

            const arg: string = item.action[0].actionArgument.editUriArgs[0];
            this.idName = arg.substring(arg.lastIndexOf('.') + 1);

            this.thingService.deleteItem(item.action[0].actionArgument.editUri, [this.row[this.idName]])
              .subscribe(results => this.onDeleteItemSuccessful(results), error => this.onDeleteItemFailed(error));
          }

        });
      } else {

        const completionEvent = new EventEmitter<ActionCompletedEvent>();
        completionEvent.subscribe(() => {
          this.setupScreen();
        });

        // if (event.item.action.find(a => a.action == ActionIdentifier.DeleteItem))
        //       completionEvent.subscribe(complete => this.deleteCompleted(complete));

        //     if (event.item.action.find(a => a.action == ActionIdentifier.UpdateData))
        //     {
        //       if (event.item.action[0].actionArgument.resultTargetDataObject === 'screenParameters') {
        //         completionEvent.subscribe(result => {
        //           this.defaultPriceSchemeId = this.screenParameters['defaultPriceSchemeId'];

        //           // TODO notify the parent grid
        //         });
        //       }
        //     }

        const a = new ActionBuilder(item.action, this.row, null, this.uriCollections, this.screenParameters, completionEvent,
          this.onAction, this.thingService, this.toastr, this.dialog);
        a.PerformAction();
      }
    } else {
      alert('No action defined!');
    }
  }

  private onDeleteItemSuccessful(result: PostResponse[]) {

    result.forEach(r => {

      switch (r.errorCode) {
        case 0:
          this.onDeleteCompleted.emit({ name: this.idName, value: r.returnedObject });
          break;
        case 400:
          this.processValidationResponse(null, r.validationResponse);
          break;
        default:
          this.toastr.error(r.errorMessage, null, { tapToDismiss: true });
          break;

      }
    });
  }

  processValidationResponse(objectName: string, validationResponse: ValidationResponse) {

    if (!!validationResponse.items)
    {
      validationResponse.items.forEach(validationItem => {
        // const columnField = this.columnFields.find(c => c.propertyName === validationItem.propertyName);
        // if (columnField) {
  
        // this.detailsForm.form.controls[validationItem.propertyName].setErrors({ 'incorrect': true });
        // columnField.errorText = validationItem.message;
        // } else {
        this.toastr.error(validationItem.Message, 'Delete failed', { tapToDismiss: true });
        // }
      });
    }
    if (!!validationResponse.Items)
    {
      validationResponse.Items.forEach(validationItem => {
        // const columnField = this.columnFields.find(c => c.propertyName === validationItem.propertyName);
        // if (columnField) {
  
        // this.detailsForm.form.controls[validationItem.propertyName].setErrors({ 'incorrect': true });
        // columnField.errorText = validationItem.message;
        // } else {
        this.toastr.error(validationItem.Message, 'Delete failed', { tapToDismiss: true });
        // }
      });
    }
  }

  private onDeleteItemFailed(error: any) {

    this.toastr.error(`Unable to delete data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  private onGetDetailFailed(error: any, metaUri, dataUri, screenUri) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public getLookupIconId(lookupDetailId: number, referenceTo: string) {

    const item = FlowService.getLookupDetail(Utilities.getLastEntry(referenceTo), lookupDetailId);
    return (item != null ? item.iconID : '');
  }

  public getIcon(iconId: number)
  {
    var iconHex = this.iconHelper.getFontAwesomeHex(iconId);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  public getLookupDetailName(lookupDetailId: number, referenceTo: string) {

    const item = FlowService.getLookupDetail(Utilities.getLastEntry(referenceTo), lookupDetailId);
    return (item != null ? item.text : '');
  }

  public getFlowStatusIconId(flowStatusId: number, referenceTo: string) {

    const item = FlowService.getFlowStatus(flowStatusId, referenceTo);
    return (item != null ? item.iconID : '');
  }

  public getFlowStatusName(flowStatusId: number, referenceTo: string) {

    const item = FlowService.getFlowStatus(flowStatusId, referenceTo);
    return (item != null ? item.text : '');
  }

  public getFlowStatusStyle(flowStatusId: number, referenceTo: string) {

    let styles = {};
    const item = FlowService.getFlowStatus(flowStatusId, referenceTo);
    if (item != null && item.colour) {
      styles = {
        '-webkit-filter': 'opacity(.5) drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)',
        'filter': 'opacity(.5)  drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)'
      };
    }

    return styles;
  }

  public getImage(item: ViewDetailItem) {

    try{
      const fileId = item.childObjectName ? this.row[item.childObjectName][item.propertyName] : this.row[item.propertyName];
      return this.thingService.getFileUrl(fileId, 'small');
    }
    catch{
      return null;
    }
  }

  public getImageMed(item: ViewDetailItem) {
    try{
      const fileId = item.childObjectName ? this.row[item.childObjectName][item.propertyName] : this.row[item.propertyName];
      return this.thingService.getFileUrl(fileId, 'medium');
    }
    catch{
      return null;
    }
  }

  public hasImage(item: ViewDetailItem) {
    try{
      const fileId = item.childObjectName ? this.row[item.childObjectName][item.propertyName] : this.row[item.propertyName];
      return fileId;
    }
    catch{
      return null;
    }
  }

  showPictureViewer(item: ViewDetailItem) {

    const obj = item.childObjectName ? this.row[item.childObjectName] : this.row;
    const fileId = item.childObjectName ? this.row[item.childObjectName][item.propertyName] : this.row[item.propertyName];

    this.thingService.getFileItem(fileId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetFileSuccessful(results, item.propertyName, obj), error => console.log(error));
  }

  onGetFileSuccessful(file: FtFile, primaryFileIdPath: string, row: any) {
    this.dialog.open(FilePagerDialogComponent, {
      // width: '450px',
      data: {
        parentObjectIID: file.parentObjectIID,
        parentObjectType: file.parentObjectType,
        rowObject: this.DataType,
        primaryFileIdPath: primaryFileIdPath
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  getValue(row: any, childObjectName: string, propertyName) {
    try{
      const v = childObjectName ? row[childObjectName][propertyName] : row[propertyName];
      if (v) { return v; } else { return null; }
    }
    catch{
      return null;
    }
  }

  getDataList(row: any, rootObjectPath: string): any[] {
    if (!rootObjectPath) { return null; }

    const dataPath = new DataPath(`${rootObjectPath}.*`);
    if (!dataPath) { return null; }
    if (dataPath.childObjectNames && dataPath.childObjectNames.length > 0) {
      return row[dataPath.childObjectNames[0]].length > 0 ? row[dataPath.childObjectNames[0]] : null;
    } else {
      return row;
    }
  }

  getTextFromItem(text: TextArgument, dataItem: any) {
    const r = Utilities.parseArgumentsFromData(text.text, text.argumentIds, dataItem);
    return r;
  }

  public getLookupDataUri(itemSource: string) {

    if (!itemSource) { return; }

    const sourceData = itemSource.substring(itemSource.lastIndexOf('.') + 1);
    if (!this.uriCollections) { return; }

    const uriCollection = this.uriCollections.find(u => u.name === sourceData);
    if (uriCollection) {
      const uris = this.uriCollections.find(u => u.name === sourceData).uris;
      if (uris) {
        return uris.dataUri;
      }
    } else {
      console.log(`Can't find uri collection for ${sourceData}`);
      // this.toastr.error(`Can't find uri collection for ${sourceData}`);
    }
    return null;
  }

////// April '22 REFACTOR //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  placeholderRow: any[]

  @Input() set action(action: Action){
    this.launchAction = action.actionArgument as LaunchScreenAction;
    if(this.launchAction && this.linkedRow){
      this.initialiseScreen();
    }
  }
  launchAction : LaunchScreenAction;

  get linkedRow(): any {
    return this._linkedRow;
  }

  @Input()
  set linkedRow(value: any) {
    if (value !== this._linkedRow) {
      this._linkedRow = value;
      this.setRow(value);
      if (this.launchAction) {
        if (this.loadScreenDetail) {
          this.initialiseScreen();
        }
      }
    }
  }

  get screenDetail(): ScreenDetail {
    return this._screenDetail;
  }

  @Input()
  set screenDetail(value: ScreenDetail) {

    this.refreshScreenDetail = false;
    this._screenDetail = value;

    if (!this.loadScreenDetail && this._screenDetail) {
      this.setupScreen();
    }
  }

  private setRow(row: any){
    // Remove header, if returned with one
    if (row.totalRecordCount) {
      this.row = row.dataItems[0];
    }
    else{
      this.row = row;
    }
  }

  private initialiseScreen(){
    var screenID = this.launchAction.screenId;
    // Get UriCollections
    DataUtils.setUriCollections(this.thingService, this.launchAction.uris, null, this.launchAction.screenParameters)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetUriCollectionsSuccessful(results), error => this.onGetDataFailed(error));
  }

  private onGetDataFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  private onGetUriCollectionsSuccessful(uc: UriCollection[]) {

    this.uriCollections = uc;

    if (!this.uriCollections) {
      this.toastr.error('No UriCollections for component');
    }
    this.thingService.getScreenDetail(this.launchAction.screenUri)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetScreenDetailSuccessful(results), error => this.onGetDataFailed(error));
  }

  private onGetScreenDetailSuccessful(screenDetailComponents: ScreenDetail){
    // Set private directly to not trigger logic in the setter
    this._screenDetail = screenDetailComponents;
    this.setupScreen();
  }

  private setupScreen(){
    this.columnFieldGroups = [];
    
    //Setup Screen Parameters
    this.screenParameters = Utilities.setScreenParameters(this.launchAction.screenParameters, this.screenDetail.requiredScreenParameters, this.row, this.uriCollections);

    //Get Screen Title
    const title = Utilities.parseArgumentsFromUriCollection(this.screenDetail.screenTitle.text, this.screenDetail.screenTitle.argumentIds, this.uriCollections, this.row, this.screenParameters);
    this.onSetTitle.emit({id: null, title: title, subtitle: null});

    //Assume there is only one component screen
    const screenDetail: ScreenDetail = this.screenDetail.componentScreens[0];

    //Setup data
    this.uriCollections.forEach(uriCollection => {
      if(uriCollection.dataValues && uriCollection.dataValues.length == 1 && !uriCollection.dataValue){
        // If there is only one value in the dataValues list, we use that when we need to check a single value
        uriCollection.dataValue = uriCollection.dataValues[0];
      }
      if(!uriCollection.dataValue){
        // There is no data, so we need to use the default values on a new instance
        if(this.screenDetail.newObjectDefaults && this.screenDetail.newObjectDefaults[uriCollection.name]){
          uriCollection.dataValue = Utilities.generateRowFromDefaults(this.screenDetail.newObjectDefaults[uriCollection.name], this.row, this.screenParameters, uriCollection.name, this.uriCollections);
        }
        if(!uriCollection.dataValues){
          uriCollection.dataValues = [{}];
        }
      }
      if(!uriCollection.dataValue){
        // If there is still no data (i.e. no default values)
        uriCollection.dataValue = {};
      }
      
    });

    // Setup UI
    this.columnFieldGroups = Object.assign([], screenDetail.detailSections);
    for (const section of this.columnFieldGroups.sort((a,b) => a.sequenceNo - b.sequenceNo)){
      // Generate details for each section
      section.columnFields = this.detailUtils.generateScreenDetails(section.details, this.uriCollections, this.screenParameters, this.toastr);
    }

    // Setup Menu Items
    if(this.screenDetail.toolbarItems){
      this.toolbarItems = new Array();
      this.screenDetail.toolbarItems.forEach(toolbarItem => {
        // Use all toolbar items with either no condition, or a condition that is true
        if(toolbarItem.visibleCondition){
          const visible = Utilities.evaluateCondition(toolbarItem.visibleCondition, this.uriCollections, this.screenParameters);
          if(visible){
            this.toolbarItems.push(toolbarItem);
          }
        }
        else{
          this.toolbarItems.push(toolbarItem);
        }
      })
    }

    this.itemMenuActions.emit(this.toolbarItems);
    this.loadingIndicator = false;
  }
  getBackgroundColor(errorText: string) {
    if (errorText) {
      return { 'background-color': '#fffcf9' };
    } else {
      return { 'background-color': 'transparent' };
    }
  }

}
