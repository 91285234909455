import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, debounceTime, switchMap, tap } from 'rxjs/operators'

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { SelectedItem } from '../../../../models/SelectedItem';
import { DataDetail } from '../../../../models/DataDetail';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'app-auto-complete-multi-dropdown',
  templateUrl: './auto-complete-multi-dropdown.component.html',
  styleUrls: ['./auto-complete-multi-dropdown.component.scss']
})
export class AutoCompleteMultiDropdownComponent implements OnInit, AfterViewInit {

  @Input() selectedItem: SelectedItem;
  _dataUri: string;
  @Input()
  get dataUri(): string {
    return this._dataUri;
  }
  set dataUri(v: string) {
    const pts = v.split('?');
    if (pts.length > 1) {
      const queryPartSplit = pts[1].split('&');
      const orderIndex = queryPartSplit.findIndex(p => p.startsWith('$orderby'));
      if (orderIndex > -1) {
        queryPartSplit.splice(orderIndex, 1);
      }
      const query = queryPartSplit.join('&');
      this._dataUri = `${pts[0]}?${query}`;
    } else {
      this._dataUri = v;
    }
  }

  @Input() name: string;
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() labelName: string;
  @Input() multiple = false;
  @Input() required = false;

  totalRecordCount: number;
  returnRecordCount: number;
  loading = false;
  dataItems: any[];
  typeahead = new Subject<string>();

  @ViewChild('dropdown') public dropdown: NgSelectComponent;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {

    if (this.selectedItem && this.selectedItem.value && this.selectedItem.value.toString() === '') {
      this.selectedItem.value = null;
    }

    if (this.selectedItem && this.selectedItem.value) {

      let select = `${this.bindValue},${this.bindLabel}`;

      // If bindValue == bindLabel, only select one
      if (this.bindValue === this.bindLabel) {
        select = this.bindValue;
      }

      let filter = `${this.bindValue} eq '${this.selectedItem.value}'`;

      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, filter, '', select, true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
    }
  }

  ngAfterViewInit() {

    let select = `${this.bindValue},${this.bindLabel}`;

    if (this.bindValue === this.bindLabel) {
      select = `${this.bindValue}`;
    }

    this.typeahead.pipe(
      tap(() => this.loading = true),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, `substringof('${term}',${this.bindLabel})`, '', select, true)),
    ).subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
  }

  private onGetDataSuccessful(dataDetail: DataDetail) {

    if (!dataDetail) { console.log('No dataDetail for ' + this.selectedItem.name + ' ' + this.dataUri); }

    this.loading = false;
    this.dataItems = dataDetail.dataItems;
    this.returnRecordCount = dataDetail.returnRecordCount;
    this.totalRecordCount = dataDetail.totalRecordCount;

    // var s = this.selectedItem.value;
    // this.selectedItem.value = 0;
    // this.selectedItem.value = s;
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
    this.loading = false;
  }

  public clear() {
    this.selectedItem = new SelectedItem('', []);
    this.dataItems = [];
    this.returnRecordCount = 0;
    this.totalRecordCount = 0;
  }

  public focus() {
    this.dropdown.focus();

  }
}
