import { Component, OnInit, Input, forwardRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => TagListInputComponent),
  multi: true
};

@Component({
  selector: 'app-tag-list-input',
  templateUrl: './tag-list-input.component.html',
  styleUrls: ['./tag-list-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class TagListInputComponent implements OnInit, ControlValueAccessor, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  existingTagList: string[] = [];
  filteredTagList: string[] = [];
  _tagList: string[];
  // private tagList: string[];

  set value(val: string[]) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){

    this._tagList = val;
    this.onChange(val);
    this.onTouched(val);
  }
  get value(): string[] {
    return this._tagList;
  }

  @Input() placeholder: string;
  @Input() errorText: string;
  @Input() required = false;
  @Input() objectName: string;
  @Input() selectable = true;
  @Input() removable = true;
  @Input() canAdd = true;

  @ViewChild('input') public input: ElementRef;

  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  isDisabled: boolean;

  constructor(private dataService: ThingService) { }

  ngOnInit() {
    if (this.objectName) {
      this.dataService.getTagList(this.objectName)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          this.existingTagList = result.map(t => t.displayName);
          this.filteredTagList = this.existingTagList;
        });
    }
  }

  writeValue(value: string[]): void {
    if (!value) { value = []; }

    if (value !== this.value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  addTag(event: MatChipInputEvent): void {

    if (!this.value) {
      this.value = [];
    }

    const input = event.input;
    const value = event.value.trim();

    if (!this.canAdd) {
      if (!this.existingTagList.find(t => t === value)) {
        return;
      }
    }

    // Add our tag
    if (value && value.length > 0 && !this.value.find(s => s === value)) {
      this.value.push(value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    this._tagList.push(event.option.value);
    this.input.nativeElement.value = '';
    // this.existingTagList$.pipe(filter(t => this._tagList !== t));
  }

  removeTag(tag: string) {

    if (!this.value) {
      this.value = [];
    }

    this.value = this.value.filter(s => s !== tag);
  }

  valueChanged(event: any) {
    const text = event.target.value;
    if (text) {
      this.filteredTagList = this.existingTagList
        .filter(d => d.includes(text.toLowerCase()));
    } else {
      this.filteredTagList = this.existingTagList;
    }
  }

  autocompleteTags() {
    return this.filteredTagList.filter(t => this._tagList.findIndex(l => l === t) === -1);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
