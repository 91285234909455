import { ItemTextModel } from '../viewControls/ItemTextModel';
import { NumberPickerModel } from '../viewControls/NumberPickerModel';
import { ItemStatusModel } from '../viewControls/ItemStatusModel';
import { IconModel } from '../IconArgument';
import { CardItemType } from '../Enums';
import { ICardModel } from './ICardModel';
import { FlagSummaryModel } from '../viewControls/FlagSummaryModel';

export class QuantityCardModel implements ICardModel {
    cardItemType: CardItemType;
    title: ItemTextModel;
    detail1: ItemTextModel;
    detail2: ItemTextModel;
    numberPicker: NumberPickerModel;
    button1: ItemStatusModel;
    button2: ItemStatusModel;
    cardColor: IconModel;
    count: ItemTextModel;
    flagSummary: FlagSummaryModel;
}
