import { ScreenType, SubScreenType } from '../Enums';
import { ActionURI } from '../data/ActionURI';
import { Action } from './Action';

export class LaunchScreenAction {

    screenId: number;
    screenType: ScreenType;
    actionCondition: string;
    screenUri: string;
    screenTag: string;
    safeUrlPath: string;
    name: string;
    uris: ActionURI[];
    screenParameters: any[]; // <string, string>
    actionData: any[]; // <string, string>
    subScreenType: SubScreenType;
    screenPosition: string;
}
