import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Action } from '../../../../models/actions/Action';
import { ActionIdentifier } from '../../../../models/Enums';
import { PostResponse } from '../../../../models/PostResponse';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

@Component({
  selector: 'app-integer-input',
  templateUrl: './integer-input.component.html',
  styleUrls: ['./integer-input.component.scss']
})
export class IntegerInputComponent implements OnInit {

  _dataPath: string;
  @Input() action: Action[];
  @Input() row: any;
  @Input() disabled: boolean;
  @Input() default?: number;
  @Input() placeholder?: string;
  @Input() prefix?: string;
  @Input() maxValue?: number;
  _value: any;
  editUrl: string;

  @Output() onUpdateCompleted = new EventEmitter<any>();
  @Output() onValueChanged = new EventEmitter<any>();

  upInterval: any;
  downInterval: any;
  updateInterval: any;

  upSet = false;
  downSet = false;

  upHover = false;
  downHover = false;
  inputHover = false;

  upFocus = false;
  downFocus = false;
  inputFocus = false;
  inputUpdated = false;

  @ViewChild('input') public input: Input;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {

    // if (!this.dataPath && this.default)
    if (this.dataPath.startsWith('tag') || !this.value) {
      this.value = this.default;
    }
  }

  get value(): any {
    return this._value;
  }
  set value(val: any) {
    this._value = val;
    // this.onValueChanged.emit(this._value);
  }


  valueChanged(value: any) {

    if (this.maxValue != null && this.maxValue <= value) {
      this.row[this.dataPath] = this.maxValue;
      return;
    }

    this.row[this.dataPath] = value;
    this.onValueChanged.emit(value);
  }

  get dataPath(): string {
    return this._dataPath;
  }

  @Input()
  set dataPath(value: string) {

    if (value) {
      if (Utilities.getFirstEntry(value) === 'data') {
        this._dataPath = Utilities.getLastEntry(value);
        this.value = this.row[this.dataPath];
      } else {
        this._dataPath = value;
        this.value = this.row[this.dataPath];
      }
    }
  }

  private moveUp() {

    let value: number = parseInt(this.row[this.dataPath], 10);

    if (value) {
      if (this.maxValue != null && this.maxValue <= value) { return; }
      value++;
    } else {
      value = 1;
    }

    this.row[this.dataPath] = value;
    this.onValueChanged.emit(value);

  }

  hasPlaceholder(): boolean {
    if (this.placeholder) {
      return this.placeholder.length > 0;
    }
    return false;
  }

  private moveDown() {

    let value: number = parseInt(this.row[this.dataPath], 10);

    if (value) {
      value--;
    } else {
      value = 0;
    }

    this.row[this.dataPath] = value;
    this.onValueChanged.emit(value);

  }

  onIncrement() {

    if (!this.upSet) {
      this.moveUp();
    }

    this.upSet = false;

  }

  onDecrement() {

    if (!this.downSet) {
      this.moveDown();
    }

    this.downSet = false;

  }

  get hasFocus(): boolean {
    return (this.upFocus || this.downFocus || this.inputFocus || this.upHover || this.downHover || this.inputHover);
  }

  get hidden(): boolean {
    return !this.hasFocus && !this.inputUpdated;
  }

  onFocusOut(value: number) {

    if (value === -1) {
      this.downFocus = false;
    } else if (value === 0) {
      this.inputFocus = false;
    } else {
      this.upFocus = false;
    }

    if (this.action) {
      this.inputUpdated = true;

      this.updateInterval = setInterval(() => {

        if (!this.hasFocus) {
          this.saveValue();
        }

        clearInterval(this.updateInterval);
        this.updateInterval = null;
        this.inputUpdated = false;

      }, 100);
    }

  }

  saveValue() {

    if (this.upInterval) {
      clearInterval(this.upInterval);
      this.upInterval = null;
    }

    if (this.downInterval) {
      clearInterval(this.downInterval);
      this.downInterval = null;
    }

    if (this.row[this.dataPath] != this.value) {

      const action: Action = Utilities.parseAction(this.action[0], this.row);

      if (action.action === ActionIdentifier.UpdateData) {

        if (action.actionArgument) {

          const newItem: any = Utilities.generateRowFromDefaults(action.actionArgument.newObjectDefaults, this.row, null);

          this.value = this.row[this.dataPath];
          this.disabled = true;

          this.thingService.postDataDetails(action.actionArgument.editUri, newItem)
            .subscribe(result => { this.onPostSuccessful(result); }, error => this.onPostFailed(error));

        }
      }
    }
  }

  private onPostSuccessful(result: PostResponse[]) {

    this.onUpdateCompleted.emit();
    this.disabled = false;
    this.inputUpdated = false;
  }

  private onPostFailed(error: any) {
    this.toastr.error(`Unable to save section to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
    this.disabled = false;
  }

  onUpMouseDown() {
    this.upInterval = setInterval(() => {
      this.moveUp();
      this.upSet = true;
    }, 150);
  }

  onUpMouseUp() {
    if (this.upInterval) {
      clearInterval(this.upInterval);
      this.upInterval = null;
    }
  }

  onDownMouseDown() {
    this.downInterval = setInterval(() => {
      this.moveDown();
      this.downSet = true;
    }, 150);
  }

  onDownMouseUp() {
    if (this.downInterval) {
      clearInterval(this.downInterval);
      this.downInterval = null;
    }
  }

}
