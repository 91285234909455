import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-initials-icon',
  templateUrl: './initials-icon.component.html',
  styleUrls: ['./initials-icon.component.scss']
})
export class InitialsIconComponent implements OnInit {

  @Input() value = '';
  @Input() color = '';
  @Input() clickable = false;
  @Output() click = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onClick(event: any) {
    this.click.emit(event);
  }
}
