import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GanttActivityComponent } from './gantt-activity.component';
import { GanttTimeScaleComponent } from './time-scale/gantt-time-scale.component';
import { GanttActivityBackgroundComponent } from './background/activity-background.component';
import { GanttActivityBarsComponent } from './bars/activity-bars.component';
import { MatIconModule } from '@angular/material/icon';
import { ViewEventsDialogComponent } from './view-events-dialog/view-events-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MyCalendarModule } from '../components/controls/calendar/my-calendar/my-calendar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatRadioModule } from '@angular/material';


@NgModule({
    imports: [
        CommonModule,
        MyCalendarModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        ContextMenuModule,
        MatSelectModule,
        MatOptionModule,
        MatSlideToggleModule,
        MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatDatepickerModule, MatSidenavModule,
        MatCardModule, MatGridListModule, MatButtonToggleModule,
        MatInputModule, MatTableModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatTreeModule, MatDialogModule, MatDividerModule,
        MatTooltipModule, MatChipsModule, MatRadioModule
    ],
    exports: [
        GanttActivityComponent,
        GanttTimeScaleComponent,
        GanttActivityBackgroundComponent,
        GanttActivityBarsComponent,
    ],
    declarations: [
        GanttActivityComponent,
        GanttTimeScaleComponent,
        GanttActivityBackgroundComponent,
        GanttActivityBarsComponent,
        ViewEventsDialogComponent,
        ///TreeBuilder, TreeParentRepeater, TreeChildrenRepeater,
    ],
    providers: [],
})
export class GanttActivityModule { }
