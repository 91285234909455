import { Component, OnInit, Output, EventEmitter, Input, QueryList, ViewChildren, OnDestroy, ViewChild } from '@angular/core';
import { ScreenDetail, DetailSection } from 'src/app/models/ScreenDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { Action } from 'src/app/models/actions/Action';
import { Utilities } from 'src/app/services/utilities/utilities';
import { TextArgument } from 'src/app/models/TextModel';
import { OrderCheckoutItemComponent } from '../../lists/cards/order-checkout-item/order-checkout-item.component';
import { OrderFormComponent } from '../order-form/order-form.component';
import { ActionIdentifier } from 'src/app/models/Enums';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { ToastrService } from 'ngx-toastr';
import { ThingService } from 'src/app/services/thing/thing.service';
import { MatDialog } from '@angular/material';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DateUtils } from 'src/app/services/DateUtils';
import { DataDetail } from 'src/app/models/DataDetail';
import { ItemListComponent } from '../../lists/item-list/item-list.component';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';

@Component({
  selector: 'app-order-checkout',
  templateUrl: './order-checkout.component.html',
  styleUrls: ['./order-checkout.component.scss', './../nav-bar-styles.scss']
})
export class OrderCheckoutComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() action: Action;
  @Input() height: number;
  @Input() screenDetailComponents: ScreenDetail;
  @Input() uriCollections: UriCollection[];
  @Input() screenParameters: any;

  @Output() updateCompleted = new EventEmitter<UpdateCompletedEvent>();
  @Output() backClick = new EventEmitter<boolean>();

  isAmendOrder = false;
  _amendOrder: ScreenDetail;

  screenDetailBasket: ScreenDetail;
  orderBasketUri: UriCollection;
  title: any;
  subtitle: string;
  screenDetailOrders: ScreenDetail;
  orderTemplateDataKey: string;
  orderBasketDataKey: string;

  @ViewChild(ItemListComponent) orderItemList: ItemListComponent;
  @ViewChildren(OrderFormComponent) orderForms: QueryList<OrderFormComponent>;
  isSaving = false;

  constructor(private toastr: ToastrService, private thingService: ThingService, private dialog: MatDialog) { }

  get checkoutButtonName() { return this.isAmendOrder ? 'Save changes' : 'Checkout'; }

  ngOnInit() {

    if (!this.uriCollections) {
      this.uriCollections = [];
      if (this.action) {
        for (const uri of this.action.actionArgument.uris) {
          const actionUri: ActionURI = JSON.parse(JSON.stringify(uri));
          if (!this.uriCollections.find(u => u.name === uri.name)) {
            if (actionUri.dataUri) {
              actionUri.dataUri = Utilities.parseArgumentsFromData(actionUri.dataUri, actionUri.dataUriArgs, null, null);
            }
            this.uriCollections.push({ name: uri.name, uris: actionUri, dataValues: [], });
          }
        }

        const initialLoadData = this.uriCollections.filter(u => u.uris.loadAtStart);
        if (initialLoadData && initialLoadData.length > 0) {
          for (let i = 0; i < initialLoadData.length; i++) {
            const u = initialLoadData[i];
            this.thingService.getData(u.uris.metaUri, u.uris.dataUri)
              .pipe(takeUntil(this.destroy$))
              .subscribe(results => this.getDataDetailSuccessful(u.name, results[1], results[0], i === initialLoadData.length - 1), error => console.log(error));
          }
        }
      }

    } else {
      this.loadScreen();
    }
  }

  loadScreen() {
    if (this.screenDetailComponents) {
      this.onGetScreenDetailSuccessful(this.screenDetailComponents);
    } else if (this.action) {
      this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
        .subscribe(result => this.onGetScreenDetailSuccessful(result), error => this.onGetDetailFailed(error));
    }
  }

  private getDataDetailSuccessful(name: string, dataDetail: DataDetail, columns: FTThingDetail[], loadScreenDetail: boolean) {

    let uriCollection = this.uriCollections.find(u => u.name === name);

    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail.dataItems };
      this.uriCollections.push(uriCollection);
    }

    uriCollection.dataValues = dataDetail.dataItems;
    if (columns) {
      uriCollection.dataMetadata = columns;
    }

    if (name === this.orderBasketDataKey) {
      this.orderItemList.dataItems = uriCollection.dataValues;
    }

    if (loadScreenDetail) {
      this.loadScreen();
    }
  }

  private onGetScreenDetailSuccessful(screenDetailComponents: ScreenDetail) {

    if (screenDetailComponents.screenProperties) {
      this.isAmendOrder = screenDetailComponents.screenProperties['isAmend'];
    }

    this.screenDetailComponents = screenDetailComponents;
    this.screenDetailBasket = this.screenDetailComponents.componentScreens.find(s => s.screenTag === 'itemCheckout');
    this.screenDetailOrders = this.screenDetailComponents.componentScreens.find(s => s.screenTag === 'orderCheckout');
    this.orderBasketDataKey = this.screenDetailBasket.cardItems[0].dataObjectKey;
    this.orderTemplateDataKey = this.screenDetailOrders.componentScreens[0].detailSections[0].templateDataKey;

    if (this.screenDetailBasket.screenTitle) {
      this.title = Utilities.parseArgumentsFromUriCollection(this.screenDetailBasket.screenTitle.text,
        this.screenDetailBasket.screenTitle.argumentIds, this.uriCollections, null, this.screenParameters);
    }
    if (this.screenDetailBasket.screenSubtitle) {
      this.subtitle = Utilities.parseArgumentsFromUriCollection(this.screenDetailBasket.screenSubtitle.text,
        this.screenDetailBasket.screenSubtitle.argumentIds, this.uriCollections, null, this.screenParameters);
    }

    if (this.uriCollections && !this.isAmendOrder) {
      const uriCollection = this.uriCollections.find(u => u.name === this.orderTemplateDataKey);
      // const dataObjects = uriCollection.dataValues;

      // make a new order array each time
      uriCollection.dataValues = [];
      const dataObjects = uriCollection.dataValues;
      this.screenDetailOrders.componentScreens.forEach((orderDetail, index) => {
        // this.metadata = uriCollection.dataMetadata;
        if (dataObjects.length <= index) {
          let order = {};
          if (this.screenDetailComponents.newObjectDefaults && this.screenDetailComponents.newObjectDefaults[this.orderTemplateDataKey]) {
            order = Utilities.createItemFromObjectDefaults(this.screenDetailComponents.newObjectDefaults[this.orderTemplateDataKey], order, order,
              this.uriCollections, this.screenParameters);
          }
          dataObjects.push(order);
        }
      });

      this.orderBasketUri = this.uriCollections.find(u => u.name === this.orderBasketDataKey);

      if (this.screenDetailBasket.screenTitle) {
        this.title = Utilities.parseArgumentsFromUriCollection(this.screenDetailBasket.screenTitle.text,
          this.screenDetailBasket.screenTitle.argumentIds, this.uriCollections, null, this.screenParameters);
      }
      if (this.screenDetailBasket.screenSubtitle) {
        this.subtitle = Utilities.parseArgumentsFromUriCollection(this.screenDetailBasket.screenSubtitle.text,
          this.screenDetailBasket.screenSubtitle.argumentIds, this.uriCollections, null, this.screenParameters);
      }
    } else {
      const initialLoadData = this.uriCollections.filter(u => u.uris.loadAtStart);
      if (initialLoadData && initialLoadData.length > 0) {
        for (let i = 0; i < initialLoadData.length; i++) {
          const u = initialLoadData[i];
          this.thingService.getData(u.uris.metaUri, u.uris.dataUri)
            .pipe(takeUntil(this.destroy$))
            .subscribe(results => this.getDataDetailSuccessful(u.name, results[1], results[0], false), error => console.log(error));
        }
      }
    }
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  onBack() {
    this.backClick.emit(false);
  }

  getText(text: TextArgument, tag: string) {
    if (text.argumentIds && text.argumentIds[0] === 'count.tag') {
      return text.text.replace('{0}', this.orderTagCount(tag).toString());
    } else {
      return Utilities.parseArgumentsFromUriCollection(text.text, text.argumentIds, this.uriCollections, null, this.screenParameters);
    }
  }

  orderTagCount(tag: string) {

    const data = this.uriCollections.find(u => u.name === this.orderBasketDataKey);
    if (data && data.dataValues) {
      let dataValues = [];
      if (this.isAmendOrder) {
        dataValues = data.dataValues;
      } else {
        dataValues = data.dataValues.filter(d => d[OrderCheckoutItemComponent.templateTagProperty] === tag);
      }

      // TODO chek this
      if (this.screenDetailOrders.listCount) {
        const vals = dataValues.map(v => v[Utilities.getLastEntry(this.screenDetailOrders.listCount)]);
        if (vals.length > 0) {
          return vals.reduce((total, num) => total + num);
        } else { return 0; }
      } else {
        return dataValues.length;
      }
    } else { return 0; }
  }

  orderBasketByTag(tag: string): any[] {
    const data = this.uriCollections.find(u => u.name === this.orderBasketDataKey);
    if (data && data.dataValues) {
      return data.dataValues.filter(d => d[OrderCheckoutItemComponent.templateTagProperty] === tag);
    }
  }

  onCheckout() {

    // let orderList = [];
    // let orderItemList = [][];
    this.orderForms.forEach((f, i) => {
      const order = f.dataObj;
      const orderItems = this.orderBasketByTag(f.detailSection.screenTag);
      const actionList = f.detailSection.detailSections[0].conditionAction.sort((a1, a2) => a1.sequence - a2.sequence);


      this.performAction(0, actionList, order, orderItems, i === this.orderForms.length - 1);


      // orderList.push(order);
      // orderItems.forEach(oi => {
      //   const itemDictionary = {};
      //   itemDictionary[this.orderBasketDataKey] = oi;
      //   itemDictionary[this.orderTemplateDataKey] = order;
      //   orderItemList.push(oi);
      // });
    });
    // this.performActionv2(actionli)
  }

  performAction(index: number, actionList: Action[], order: any, orderItems: any[], closeOnFinish: boolean) {

    if (index + 1 > actionList.length) {
      this.isSaving = false;
      if (closeOnFinish) {
        this.close();
      }
      return;
    }

    const a = actionList[index];
    if (a.action === ActionIdentifier.UpdateData && (a.actionArgument as UpdateDataAction).currentItemPath === this.orderBasketDataKey) {

      this.isSaving = true;

      const action = a.actionArgument as UpdateDataAction;
      const postArray = [];
      orderItems.forEach(oi => {
        const itemDictionary = {};
        itemDictionary[this.orderBasketDataKey] = oi;
        itemDictionary[this.orderTemplateDataKey] = order;
        const newItem = Utilities.createPostItemFromData(action.newObjectDefaults, itemDictionary, null, this.uriCollections, this.screenParameters);
        postArray.push(newItem);

      });

      this.thingService.postDataDetails(action.editUri, postArray)
        .pipe(takeUntil(this.destroy$))
        .subscribe(r => console.log(r), error => { }, () => this.performAction(index + 1, actionList, order, orderItems, closeOnFinish));

    } else {
      if (a.action === ActionIdentifier.UpdateData) {
        const updateAction = a.actionArgument as UpdateDataAction;

        if (this.screenDetailComponents.newObjectDefaults && this.screenDetailComponents.newObjectDefaults[this.orderTemplateDataKey]) {
          order = this.setDefaultsOnItem(order, this.screenDetailComponents.newObjectDefaults[this.orderTemplateDataKey]);
        }
        if (updateAction.newObjectDefaults) {
          order = this.setDefaultsOnItem(order, updateAction.newObjectDefaults);
        }
      }
      const actionCompleted = new EventEmitter<ActionCompletedEvent>();
      const ac = new ActionBuilder([a], [order], [order], this.uriCollections, this.screenParameters,
        actionCompleted, null, this.thingService, this.toastr, this.dialog);

      ac.PerformAction();

      actionCompleted.subscribe((r) => {
        order = (r as ActionCompletedEvent).updatedObject;
        this.performAction(index + 1, actionList, order, orderItems, closeOnFinish);
      });
    }
  }

  setDefaultsOnItem(order: any, newObjectDefaults: any) {
    Object.keys(newObjectDefaults).forEach(function (newObjKey) {
      const split = newObjectDefaults[newObjKey].split('.');
      let value;
      switch (split[0].toLowerCase()) {
        case 'screenparameter':
        case 'screenparameters':
          value = this.screenParameters[split[1]];
          break;
        case '@date':
          if (split[1] === 'now') {
            value = DateUtils.getCurrentDateTimeUTCString();
          }
          break;
        case 'data':
          value = Utilities.getValueFromUriCollection(newObjectDefaults[newObjKey], this.uriCollections, this.item, this.screenParameters);
          break;
        default:
          value = split[0];
          break;
      }
      order[newObjKey] = value;
    }, this);
    return order;
  }

  onUpdateCompleted(event: UpdateCompletedEvent) {

    if (event.resultTargetDataObject) {
      event.resultTargetDataObject.split(',').forEach(targetOb => {
        const uriColl = this.uriCollections.find(u => u.name === targetOb);
        if (uriColl) {
          this.thingService.getDataDetailWithHeader(uriColl.uris.dataUri)
            .pipe(takeUntil(this.destroy$))
            .subscribe(results => this.getDataDetailSuccessful(uriColl.name, results, null, false), error => console.log(error));
        }
      });
    } else {
      // console.log('onUpdateCompleted');
    }
  }

  public getTemplateForOrder(): ScreenDetail {

    if (this._amendOrder) {
      return this._amendOrder;
    } else {
      this.screenDetailOrders.componentScreens.forEach(screen => {
        const isMatch = Utilities.evaluateCondition(screen.isVisibleCondition, this.uriCollections, this.screenParameters, false);
        if (isMatch) {
          this._amendOrder = screen;
          return screen;
        }
      });
    }
  }

  close() {
    this.backClick.emit(true);
  }

  ngOnDestroy(): void {

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public get isAmendEnabled(): boolean {
    if (this.screenDetailComponents && this.screenDetailComponents.isEnabledCondition) {
      return Utilities.evaluateCondition(this.screenDetailComponents.isEnabledCondition, this.uriCollections, null, null, null);
    }
    return true;
  }
}
