import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import { QFromToType } from './q-from-to-indicator-model';
import { formatDate, DatePipe } from '@angular/common';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'q-from-to-indicator',
  templateUrl: './q-from-to-indicator.component.html',
  styleUrls: ['./q-from-to-indicator.component.css'],
  providers: [DatePipe]
})

export class QFromToIndicatorComponent implements OnInit, AfterViewInit {

  @Input() public fromToType: QFromToType = QFromToType.Number;
  @Input() public fromValue: any = 1; //new Date('2018-09-01 00:00:00');
  @Input() public toValue: any = 10; //new Date('2018-09-01 10:00:00');
  @Input() public currentValue: any = 5; //new Date('2018-09-01 08:00:00');

  @Input() public displayText: string; // = "00:00 - 12:00";
  @Input() public trackColor: string;
  @Input() public trackOpacity: number;
  @Input() public displayTextColor: any;

  @Input() public isDislayTextAtBottom: boolean = false;

  @Input() public dateFormat: string = 'dd MMM yy';
  @Input() public dateTimeFormat: string = 'dd MMM HH:mm';
  @Input() public timeFormat: string = 'HH:mm';

  public progressWidth: number = 0;

  @ViewChild('fromToIndicatorTrack') trackElement: ElementRef;
  @ViewChild('fromToIndicatorProgress') progressElement: ElementRef;
  @ViewChild('fromToIndicatorText') displayTextElement: ElementRef;

  public lineHeight: string = ''; //set to 'normal' if isDislayTextAtBottom = true
  public textPosition: string = 'absolute'; //set to relative if isDislayTextAtBottom = true

  constructor(private datePipe: DatePipe) {

  }

  ngOnInit() {

    if (this.displayText == null) {
      let formatString: string;

      switch (this.fromToType) {
        case (QFromToType.Date):
          formatString = this.dateFormat;
          break;
        case (QFromToType.DateTime):
          formatString = this.dateTimeFormat;
          break;
        case (QFromToType.Time):
          formatString = this.timeFormat;
          break;
        default:
          this.displayText = this.fromValue + ' - ' + this.toValue;
      }

      if (formatString != null) {
        this.displayText = this.datePipe.transform(this.fromValue, formatString) + ' - ' + this.datePipe.transform(this.toValue, formatString);
      }
    }
  }

  ngAfterViewInit() {

    //Adjust the progress only if currentValue is supplied or if it is a date or datetime
    if (this.currentValue != null || this.fromToType == QFromToType.DateTime || this.fromToType == QFromToType.Date) {
      setTimeout(() => {
        this.initView();
      }, 0);
    }

  }

  private initView()
  {
    if (this.trackColor == null) {
      this.trackColor = window.getComputedStyle(this.trackElement.nativeElement, null).getPropertyValue('background-color');
    }

    if (this.trackOpacity == null) {
      this.trackOpacity = parseInt(window.getComputedStyle(this.progressElement.nativeElement, null).getPropertyValue('opacity'));
    }

    this.lineHeight = this.isDislayTextAtBottom ? '' : this.trackElement.nativeElement.offsetHeight + 'px';
        this.textPosition = this.isDislayTextAtBottom ? 'relative' : 'absolute';
    
        // if (this.displayTextColor == null) {
        //   this.displayTextColor = this.getContrastColor(this.trackColor); //parseInt(window.getComputedStyle(this.displayTextElement.nativeElement, null).getPropertyValue('color'));
        // }
      this.adjustProgress();
  }

  private adjustProgress() {

    if (this.fromValue === this.toValue)
    {
      return;
    }
    let rangeDiff: number = 0;
    let dayAdjusterValue: number = (1000 * 60 * 60 * 24); //Add a day so the end date is inclusive in calculation
    let numberAdjuster: number = 1; //Add a day so the end date is inclusive in calculation
    let isReverse: boolean = false;

    switch (this.fromToType) {
      case (QFromToType.Date):
        this.fromValue = this.fromValue.setHours(0, 0, 0);
        this.toValue = this.toValue.setHours(0, 0, 0); // + dayValue;
        this.currentValue = this.currentValue == null ? new Date(Date.now()).setHours(0, 0, 0) : this.currentValue;
        this.currentValue = this.toValue - this.currentValue;
        rangeDiff = (this.toValue + dayAdjusterValue) - this.fromValue;
        break;

      case (QFromToType.DateTime):
      case (QFromToType.Time):
        this.currentValue = this.currentValue == null ? new Date(Date.now()) : this.currentValue;
        this.currentValue = this.toValue - this.currentValue;
        rangeDiff = this.toValue - this.fromValue;
        break;
      // case (QFromToType.DateTime):
      case (QFromToType.Number):
        rangeDiff = (this.toValue + numberAdjuster) - this.fromValue;
        isReverse = true;


    }

    rangeDiff = rangeDiff === 0 ? 1 : rangeDiff;

    let width: number = ((this.currentValue / rangeDiff) * 100.00);
    if (isReverse) {
      width = 100 - width;
    }
    Utilities.log('RangeDiff=' + rangeDiff + ' log ' + width);
    if (width > 100) {
      width = 100;
    }
    if (width < 0) {
      width = 0;
    }
    this.progressWidth = width;
  }
}
