import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CardItem } from 'src/app/models/cardModels/CardItem';
import { OrderCheckoutItemModel } from 'src/app/models/cardModels/OrderCheckoutItemModel';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { UIDataType } from 'src/app/models/Enums';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { TextArgument } from 'src/app/models/TextModel';
import { ItemListIconTextModel } from 'src/app/models/viewControls/ItemListIconTextModel';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'app-order-checkout-item',
  templateUrl: './order-checkout-item.component.html',
  styleUrls: ['./order-checkout-item.component.scss', './../card-style.scss']
})
export class OrderCheckoutItemComponent implements OnInit {

  public static cardHeight = 150;

  public static templateTagProperty = 'q_templateTag';

  @Input() cardItem: CardItem;
  cardTemplate: OrderCheckoutItemModel;
  @Input() dataObjectKey: string;
  @Input() dataItem: any;
  @Input() groupName: any;
  @Input() selectedItem: any;
  @Input() hasContextMenu: boolean;
  @Input() contextMenu: any;
  @Input() uriCollections?: UriCollection[] = [];
  @Output() onAction = new EventEmitter<any>();
  @Output() onContextMenuClick = new EventEmitter<any>();
  @Input() metadata: FTThingDetail[];

  public DataType = UIDataType;
  public OrderCheckoutItemComponent = OrderCheckoutItemComponent;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit(): void {
    this.cardTemplate = this.cardItem.cardItemObject as OrderCheckoutItemModel;
    if (this.dataItem[OrderCheckoutItemComponent.templateTagProperty] == null && this.cardTemplate.toggle) {
      this.templateTag = this.cardTemplate.toggle.defaultValue ? this.cardTemplate.toggle.defaultValue : 'takeAway';
      // this.setTemplateTag(this.cardTemplate.toggle.defaultValue ? this.cardTemplate.toggle.defaultValue : 'takeAway');
    }
  }

  public getTitle(row: any): string {

    return Utilities.parseTitle(this.cardTemplate.title.text, row, null, null);
  }

  public getDetail1(row: any): string {

    if (this.cardTemplate.detail1 && this.cardTemplate.detail1.text.argumentIds) {
      return Utilities.parseArgumentsFromData(this.cardTemplate.detail1.text.text, this.cardTemplate.detail1.text.argumentIds, row, null, null);
    }

    return '';
  }

  public getDetail2(row: any): string {

    if (this.cardTemplate.detail2 && this.cardTemplate.detail2.text.argumentIds) {
      return Utilities.parseArgumentsFromData(this.cardTemplate.detail2.text.text, this.cardTemplate.detail2.text.argumentIds, row, null, null);
    }
    return '';
  }

  public getText(text: TextArgument, row: any): string {

    if (text) {
      return Utilities.parseArgumentsFromData(text.text, text.argumentIds, row, null, null);
    }
    return '';
  }

  public getFlagListText(listItem: ItemListIconTextModel, index: number, dataItem: any): string {

    if (listItem) {
      if (listItem && listItem.text.argumentIds && index < listItem.text.argumentIds.length) {
        return Utilities.parseArgumentsFromData(listItem.text.text, [listItem.text.argumentIds[index]], dataItem, null, null);
      }
    }
    return null;
  }

  private getFlagListColor(listItem: ItemListIconTextModel, dataItem: any): string {

    if (listItem.icon.colour) {
      const path = Utilities.getLastEntry(listItem.icon.colour);

      const color: string = dataItem[path];
      if (color && color.length > 0) {
        if (color.startsWith('#')) {
          return color;
        } else {
          return '#' + color;
        }
      }
      return '';
    }
    return '';
  }

  public getDataList(listItem: ItemListIconTextModel) {
    if (listItem.dataListKey) {
      const r = Utilities.getOrCreateDataObjectArray(`${listItem.dataListKey}.*`, this.dataItem);
      return r;
    }
  }

  private getIcon(row: any, elementName: string): string {

    const status = this.cardTemplate[elementName];
    if (status && status.icon && row) {
      return Utilities.evaluate(status.icon.iconArgument, row, this.isChecked(row, elementName));
    }

    return '';
  }

  public isVisible(row: any, elementName: string): boolean {

    const status = this.cardTemplate[elementName];
    if (status && status.isVisibleCondition && row) {
      return Utilities.evaluate(status.isVisibleCondition, row);
    }

    return true;
  }

  // public hasElement(elementName: string) {
  //   return (this.cardTemplate[elementName]) ? true : false;
  // }

  public getElementPropertyName(elementName: string): string {
    if (this.cardTemplate[elementName] && this.cardTemplate[elementName].text) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].text.argumentIds[0]);
    } else if (this.cardTemplate[elementName] && this.cardTemplate[elementName].icon) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
    }
    return '';
  }

  private getColor(row: any): string {

    if (this.cardTemplate.cardColour) {
      const path = Utilities.getLastEntry(this.cardTemplate.cardColour.icon.colour);

      const color: string = row[path];
      if (color && color.length > 0) {
        if (color.startsWith('#')) {
          return color;
        } else {
          return '#' + color;
        }
      }
      return '';
    }
    return '';
  }

  public deleteItem(dataItem: any) {
    this.onAction.emit({ event: null, dataItem: dataItem, action: this.cardItem.onRemoveAction });
  }

  public onActionClick(event, dataItem, elementName, isChecked?: boolean) {
    let action;
    if (isChecked != null) {
      // this.cardTemplate[elementName] = isChecked;
      if (isChecked) {
        action = this.cardTemplate[elementName].onCheckedAction;
      } else {
        action = this.cardTemplate[elementName].onUncheckedAction;
      }
    } else {
      action = this.cardTemplate[elementName].action;
    }

    this.onAction.emit({ event: event, dataItem: dataItem, action: action });
  }

  public onContextMenu(event, dataItem) {
    this.onContextMenuClick.emit({ event: event, dataItem: dataItem });
  }

  public valueChanged(elementName: string, event: any) {
    const action = this.cardTemplate[elementName].action;
    if (action) {
      this.onAction.emit({ event: event, dataItem: this.dataItem, action: this.cardTemplate[elementName].action });
    }
  }

  public isEnabled(row: any, elementName: string): boolean {

    const element = this.cardTemplate[elementName];
    if (element && element.isEnabledCondition && row) {
      if (this.uriCollections.length < 2) {
      }
      return Utilities.evaluateCondition(element.isEnabledCondition, this.uriCollections, null, null, row, this.dataObjectKey);
    }
    return true;
  }

  public isChecked(row: any, elementName: string): boolean {

    const element = this.cardTemplate[elementName];
    if (element && element.isCheckedCondition && row) {
      if (this.uriCollections.length < 2) {
      }

      return Utilities.evaluateCondition(element.isCheckedCondition, this.uriCollections, null, null, row, this.dataObjectKey);
    }
    return true;
  }

  public getDataType(elementName: string): UIDataType {

    if (this.metadata && this.getElementPropertyName(elementName)) {
      const m = this.metadata.find(md => md.javaScriptName === this.getElementPropertyName(elementName));
      if (m) {
        const dt = DataUtils.dataTypeToUIDataType(m.dataType, m.referenceTo);
        return dt;
      }
    }
    return UIDataType.Unknown;
  }

  public getImage(elementName: string) {
    if (this.cardTemplate[elementName]) {
      const argId = Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
      const fileId = this.dataItem[argId];
      if (fileId) {
        return this.thingService.getFileUrl(fileId, 'small');
      }
    }
  }

  // setTemplateTag(value: string) {
  //   this.dataItem[OrderCheckoutItemComponent.templateTagProperty] = value;
  // }

  get templateTag() { return this.dataItem[OrderCheckoutItemComponent.templateTagProperty]; }
  set templateTag(value: string) {
    this.dataItem[OrderCheckoutItemComponent.templateTagProperty] = value;
  }

  getCardColor(): string {

    if (!this.cardTemplate.cardColour) {
      return '';
    }
    const property = Utilities.getLastEntry(this.cardTemplate.cardColour.icon.colour);
    if (this.dataItem[property]) {

      if (this.dataItem[property].length > 0) {
        return `${this.dataItem[property]}`;
      }
    }
    return '';
  }

  getHint(elementName: string): string {

    if (!this.cardTemplate[elementName]) { return ''; }

    const path = this.cardTemplate[elementName]['hint'];
    return this.getValue(path);
  }

  getValue(propertyPath: string) {
    if (propertyPath == null || propertyPath === '') {
      return '';
    }
    if (propertyPath.startsWith('data.')) {
      return Utilities.getData(propertyPath, this.dataItem);
    } else {
      return propertyPath;
    }
  }

  isCardEnabled() {
    if (this.cardItem.isEnabledCondition) {
      return Utilities.evaluateCondition(this.cardItem.isEnabledCondition, this.uriCollections, null, null, null);
    }
    return true;
  }
}
