import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-event-dialog',
  templateUrl: './add-event-dialog.component.html',
  styleUrls: ['./add-event-dialog.component.scss']
})
export class AddEventDialogComponent implements OnInit {

  refresh = new Subject<void>();

  date: Date;

  toggled: boolean = false;

  @Output() onSave = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<AddEventDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.data.eventForm.get("start").setValue(this.data.day.date)

    // this.data.eventForm.get("duration").valueChanges.subscribe((x: any) => {
    //   this.date = new Date(this.data.eventForm.get("start").value) as Date;
    //   this.date.setDate(this.date.getDate() + (+this.data.eventForm.get("duration").value - 1));

    //   this.data.eventForm.get("end").setValue(this.date);
    // })
  }


  save() {
    if (this.data.eventForm.invalid)
    {
      this.data.eventForm.markAllAsTouched();
      return;
    }
    this.onSave.emit(null);
  }

  isToggled()
  {
    this.toggled = !this.toggled;
    console.log(this.toggled);
  }

}