import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Action } from 'src/app/models/actions/Action';
import { ChartDataResult } from 'src/app/models/chartModels/ChartDataResult';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { ChartModel } from 'src/app/models/viewControls/ChartModel';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';
import { QChartAxisType, QChartData, QChartDataSeries, QChartType } from '../../charts/q-chart/q-chart-model';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';

export interface DialogData {
  screenParameters: any;
  action: Action;
  chartData: ChartDataResult;
}

@Component({
  selector: 'app-graph-dialog',
  templateUrl: './graph-dialog.component.html',
  styleUrls: ['./graph-dialog.component.scss']
})
export class GraphDialogComponent implements OnInit {

  screenParameters: any;
  action: Action;
  screenDetailComponents: ScreenDetail;
  title: string;
  chartComponent: ChartModel;
  qChartData: any;
  loading = true;
  chartQueryParams: HttpParams;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  dateInterval: number;

  @ViewChild('date1') date1: MatDatepicker<moment.Moment>;
  @ViewChild('date2') date2: MatDatepicker<moment.Moment>;

  constructor(public dialogRef: MatDialogRef<GraphDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {

    console.log('graph dialog');

    // this.createTestData(QChartType.Pie);
    this.screenParameters = this.data.screenParameters;
    this.action = this.data.action;

    this.loadScreen();
  }

  loadScreen(): any {

    this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
      .subscribe(result => {
        this.onGetScreenDetailSuccessful(result);
      }, error => this.onGetDetailFailed(error));
  }

  onGetScreenDetailSuccessful(result: ScreenDetail): any {
    this.screenDetailComponents = result;

    this.chartComponent = this.screenDetailComponents.componentScreens[0].detailSections[0].details[0] as any;


    this.title = Utilities.parseTitle(this.screenDetailComponents.screenTitle, null, null, this.screenParameters);

    if (!this.screenParameters['fromDate'] || !this.screenParameters['toDate']) { return; }

    this.chartQueryParams = new HttpParams();
    this.fromDate = moment(this.screenParameters['fromDate']);
    this.toDate = moment(this.screenParameters['toDate']);
    this.dateInterval = this.toDate.diff(this.fromDate, 'days');

    this.chartQueryParams = this.chartQueryParams.set('fromDate', this.screenParameters['fromDate']);
    this.chartQueryParams = this.chartQueryParams.set('toDate', this.screenParameters['toDate']);
    if (this.screenParameters['assetClassId']) { this.chartQueryParams = this.chartQueryParams.set('assetClassId', this.screenParameters['assetClassId']); }
    if (this.screenParameters['locationId']) { this.chartQueryParams = this.chartQueryParams.set('locationId', this.screenParameters['locationId']); }
    if (this.screenParameters['periodType']) { this.chartQueryParams = this.chartQueryParams.set('periodType', this.screenParameters['periodType']); }
    if (this.screenParameters['orderId']) { this.chartQueryParams = this.chartQueryParams.set('orderId', this.screenParameters['orderId']); }
    if (this.screenParameters['productId']) { this.chartQueryParams = this.chartQueryParams.set('productId', this.screenParameters['productId']); }

    if (this.data.chartData) {
      this.onGetDataDetailSuccessful(this.data.chartData);
      return;
    }

    this.reloadData();
  }

  reloadData() {
    if ((this.data.action.actionArgument as LaunchScreenAction).uris[0] && (this.data.action.actionArgument as LaunchScreenAction).uris[0].dataUri) {
      var uri = (this.data.action.actionArgument as LaunchScreenAction).uris[0].dataUri;
      if(uri.indexOf("?$filter") >= 0)
      {
        this.thingService.getDataDetailWithHeader(uri, null, null, null, null, null, null, null, null, null, this.customParams)
        .subscribe(dataResult => { this.onGetDataDetailSuccessful(dataResult); },
        error => this.onGetDetailFailed(error));
      }
      else
      {
        const dataUri = `${(this.data.action.actionArgument as LaunchScreenAction).uris[0].dataUri}?${this.chartQueryParams.toString()}`;
        this.thingService.getDataDetail(dataUri).subscribe(dataResult => { this.onGetDataDetailSuccessful(dataResult); },
          error => this.onGetDetailFailed(error));
      }
    } else {
      const dataUri = `/api/data/movementhistorysummary?${this.chartQueryParams.toString()}`;
      this.thingService.getDataDetail(dataUri).subscribe(dataResult => { this.onGetDataDetailSuccessful(dataResult); }
        , error => this.onGetDetailFailed(error));
    }
  }

  onGetDataDetailSuccessful(result: any): any {

    let chartType = QChartType.Line;
    switch (this.chartComponent.controlStyle.toLowerCase()) {
      case 'area': chartType = QChartType.Area; break;
      case 'bar': chartType = QChartType.Bar; break;
      case 'column': chartType = QChartType.Column; break;
      case 'dougnut': chartType = QChartType.Dougnut; break;
      case 'line': chartType = QChartType.Line; break;
      case 'pie': chartType = QChartType.Pie; break;
      case 'semidougnut': chartType = QChartType.SemiDougnut; break;
      case 'spline': chartType = QChartType.Spline; break;
      case 'stackedbar': chartType = QChartType.StackedBar; break;
      case 'stackedcolumn': chartType = QChartType.StackedColumn; break;
    }

    this.createChartData(result, chartType);
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  private createChartData(chartResult: ChartDataResult, chartType: QChartType): void {

    this.qChartData = new QChartData();
    this.qChartData.ChartTitle = null; // 'Stock Data';
    this.qChartData.ChartType = chartType;
    this.qChartData.StartDate = chartResult.startDate; // set to today
    this.qChartData.Interval = chartResult.interval;

    // this.QChartData.IsShowSeriesValueLabels = false,
    this.qChartData.Categories = null; // ['01','02','03','04','05','06'];
    this.qChartData.XAxisDisplay = {
      AxisTitle: null,
      AxisType: QChartAxisType.DateTime,
      LabelFormat: null, // '%d %b %y'   //ex- 01 Jan 2016 //https://stackoverflow.com/questions/7101464/how-to-get-highcharts-dates-in-the-x-axis
    };
    this.qChartData.YAxisDisplay = {
      AxisTitle: 'Stock',
      AxisType: null,
      LabelFormat: '{point.name}: {point.y}', // '{point.name}: {point.percentage:.2f}',
    };
    if (chartResult.hirePeriod) {
      this.qChartData.HighlightSegment = {
        BandTitle: 'Hire Period',
        Color: '#eaecef',
        FromDate: new Date(chartResult.hirePeriod.fromDate),
        ToDate: new Date(chartResult.hirePeriod.toDate),
        FromDataItemNo: null,
        ToDataItemNo: null
      };

      this.qChartData.HighlightSegment.FromDate.setDate(this.qChartData.HighlightSegment.FromDate.getDate()); // + 1
      this.qChartData.HighlightSegment.ToDate.setDate(this.qChartData.HighlightSegment.ToDate.getDate());   // was FromDate - mistake?
    }

    // Use this for Pie Types
    if (this.qChartData.IsPieType()) {
      this.qChartData.PieDataSeries = [{
        Data: '35', // Need to use binding for text
        Name: 'Available',
        Color: '#919396',
        IsSliced: false,
        IsSelected: true
      },
      {
        Data: '30', // Need to use binding for text
        Name: 'This Job',
        Color: '#146ec9',
        IsSliced: false,
        IsSelected: false
      },
      {
        Data: '10', // Need to use binding for text
        Name: 'This Job',
        Color: '#7db7f2',
        IsSliced: false,
        IsSelected: false
      }
      ];
    } else {
      // Use this for non Pie Types
      const dataSeriesList: QChartDataSeries[] = [];
      this.chartComponent.seriesLabels.forEach(seriesLabel => {
        const series = chartResult.dataSeries.find(s => s.seriesId.toString() === seriesLabel.tag);

        if (series) {
          dataSeriesList.push({
            seriesName: seriesLabel.label,
            color: seriesLabel.colour.startsWith('#') ? seriesLabel.colour : `#${seriesLabel.colour}`,
            dataArray: series.dataArray,
            enabled: seriesLabel.isEnabledCondition === 'false' ? false : true,
          });
        }
      });

      this.qChartData.DataSeries = dataSeriesList;
    }

    this.loading = false;
  }

  public increment() {

    console.log('increment');
    this.fromDate = this.fromDate.add(this.dateInterval, 'day');
    this.toDate = this.toDate.add(this.dateInterval, 'day');
    this.date1.select(this.fromDate);
    this.date2.select(this.toDate);
    this.chartQueryParams = this.chartQueryParams.set('fromDate', this.fromDate.toJSON());
    this.chartQueryParams = this.chartQueryParams.set('toDate', this.toDate.toJSON());

    this.reloadData();
  }

  public decrement() {

    this.fromDate = this.fromDate.add(-1 * this.dateInterval, 'day');
    this.toDate = this.toDate.add(-1 * this.dateInterval, 'day');
    this.chartQueryParams = this.chartQueryParams.set('fromDate', this.fromDate.toJSON());
    this.chartQueryParams = this.chartQueryParams.set('toDate', this.toDate.toJSON());
    this.reloadData();
  }

  searchNewDates() {

    this.chartQueryParams = this.chartQueryParams.set('fromDate', this.fromDate.toJSON());
    this.chartQueryParams = this.chartQueryParams.set('toDate', this.toDate.toJSON());
    this.reloadData();
  }

  // private createTestData(chartType: QChartType): void {
  //   // this.QChartData = {

  //   this.qChartData = new QChartData();
  //   this.qChartData.ChartTitle = null;// 'Stock Data';
  //   this.qChartData.ChartType = chartType,
  //     this.qChartData.StartDate = new Date(), //set to today
  //     this.qChartData.Interval = QChartInterval.Day,
  //     // this.QChartData.IsShowSeriesValueLabels = false,
  //     this.qChartData.Categories = null; //['01','02','03','04','05','06'];
  //   this.qChartData.XAxisDisplay = {
  //     AxisTitle: null,
  //     AxisType: QChartAxisType.DateTime,
  //     LabelFormat: null,// '%d %b %y'   //ex- 01 Jan 2016 //https://stackoverflow.com/questions/7101464/how-to-get-highcharts-dates-in-the-x-axis
  //   };
  //   this.qChartData.YAxisDisplay = {
  //     AxisTitle: 'Stock',
  //     AxisType: null,
  //     LabelFormat: '{point.name}: {point.y}',// '{point.name}: {point.percentage:.2f}',
  //   };
  //   this.qChartData.HighlightSegment = {
  //     BandTitle: 'Hire Period',
  //     Color: '#eaecef',
  //     FromDate: new Date(Date.now()),
  //     ToDate: new Date(Date.now()),
  //     FromDataItemNo: null,
  //     ToDataItemNo: null
  //   };
  //   //Add Day to
  //   this.qChartData.HighlightSegment.FromDate.setDate(this.qChartData.HighlightSegment.FromDate.getDate() + 1);
  //   this.qChartData.HighlightSegment.ToDate.setDate(this.qChartData.HighlightSegment.FromDate.getDate() + 2);


  //   //Use this for Pie Types
  //   if (this.qChartData.IsPieType()) {
  //     this.qChartData.PieDataSeries = [{
  //       Data: '35', // Need to use binding for text
  //       Name: 'Available',
  //       Color: '#919396',
  //       IsSliced: false,
  //       IsSelected: true
  //     },
  //     {
  //       Data: '30', // Need to use binding for text
  //       Name: 'This Job',
  //       Color: '#146ec9',
  //       IsSliced: false,
  //       IsSelected: false
  //     },
  //     {
  //       Data: '10', // Need to use binding for text
  //       Name: 'This Job',
  //       Color: '#7db7f2',
  //       IsSliced: false,
  //       IsSelected: false
  //     }
  //     ];
  //   }
  //   else {
  //     //Use this for non Pie Types
  //     this.qChartData.DataSeries = [{
  //       SeriesName: 'Available', // Need to use binding for text
  //       DataArray: [35, 35, 40, 40, 15],
  //       Color: '#919396'
  //     },
  //     {
  //       SeriesName: 'This Job', // Need to use binding for text
  //       DataArray: [8, 20, 20, 20, 8],
  //       Color: '#146ec9'
  //     },
  //     {
  //       SeriesName: 'This EQ List', // Need to use binding for text
  //       DataArray: [3, 12, 12, 12, 2],
  //       Color: '#7db7f2'
  //     }
  //     ];
  //   }
  // }
  public get customParams(): string {
    if (this.fromDate && this.toDate) {
      return `FromDate:${this.fromDate.format('YYYY-MM-DD')},ToDate:${this.toDate.format('YYYY-MM-DD')}`;
    }
    return null;
  }
}
