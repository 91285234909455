import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'elementExists'
})
export class ElementExistsPipe implements PipeTransform {

  transform(elementName: string, cardTemplate: any): any {
    return (cardTemplate[elementName]) ? true : false;
  }

}
