import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxClickAction } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FTFlowStatus } from '../../../../models/data/FTFlowStatus';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { DataDetail } from '../../../../models/DataDetail';
import { FilterField } from '../../../../models/FilterField';
import { SelectedItem } from '../../../../models/SelectedItem';
import { FlowService } from '../../../../services/flow/flow.service';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { UIDataType } from 'src/app/models/Enums';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { LookupDetail } from 'src/app/models/LookupDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';

export interface DialogData {
  title: string;
  filterFields: FilterField[];
  columns: FTThingDetail[];
  dataUri: string;
  uriCollections: UriCollection[];
}

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  DataType = UIDataType;

  title = 'Filter';
  filterFields: FilterField[];
  metadata: FTThingDetail[];
  dataUri: string;
  uriCollections: UriCollection[];

  flowStatusFilter: FTFlowStatus[];
  flowStatusLoading = false;
  loading: any = {};
  // objectName: string;

  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>, private thingService: ThingService, public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.title = this.data.title;
    this.filterFields = JSON.parse(JSON.stringify(this.data.filterFields.sort((a, b) => a.sequence > b.sequence ? -1 : 1)));
    this.metadata = this.data.columns;
    this.uriCollections = this.data.uriCollections;

    const pts = this.data.dataUri.split('?');
    if (pts.length > 1) {
      const queryPartSplit = pts[1].split('&');
      const orderIndex = queryPartSplit.findIndex(p => p.startsWith('$orderby'));
      if (orderIndex > -1) {
        queryPartSplit.splice(orderIndex, 1);
      }
      const query = queryPartSplit.join('&');
      this.dataUri = `${pts[0]}?${query}`;
    } else {
      this.dataUri = this.data.dataUri;
    }

    for (const c of this.filterFields.sort((a, b) => a.sequence - b.sequence)) {

      const metadata: FTThingDetail = this.getMetadata(c.fieldName);

      if (metadata) {
        c.columnId = metadata.javaScriptName;
        if (metadata.referenceTo) {
          c.objectName = Utilities.getLastEntry(metadata.referenceTo);
        }
      }

      c.displayString = c.displayString;
      c.displayStringArgs = c.displayStringArgs;

      if (!c.filterLookupValues) {
        c.filterLookupValues = new SelectedItem(c.columnId);
      }
    }
  }

  onSave() {
    this.dialogRef.close(this.filterFields);
  }

  onReset() {
    this.dialogRef.close('reset');
  }

  getMetadata(value: string): FTThingDetail {

    const name = Utilities.getLastEntry(value);
    return this.metadata.find(c => c.javaScriptName === name);
  }

  getDataType(value: FilterField): UIDataType {

    const md: FTThingDetail = this.getMetadata(value.fieldName);

    if (md) {
      var dataType = DataUtils.dataTypeToUIDataType(md.dataType, md.referenceTo);
      if(dataType == UIDataType.Number && value.displayPropertyName){
        //Likely an ID for an external FTThing
        var referencedUriCollection = this.getReferenceCollection(value.displayPropertyName);
        if(referencedUriCollection && referencedUriCollection.dataValues){
          return UIDataType.Unknown;
        }
      }
      return dataType;
    }

    return -1;

  }

  getDataUri(value: FilterField){
    const uriCollection = this.getReferenceCollection(value.displayPropertyName);
    if(uriCollection && uriCollection.uris){
      return uriCollection.uris.dataUri;
    }
    return value.dataUri;
  }

  getReferenceCollection(displayPath: string) : UriCollection{
    var dataPath = new DataPath(displayPath);
    var referencedUriCollection = this.uriCollections.find(uc => uc.name == dataPath.rootObjectName)
    return referencedUriCollection;
  }

  getReferenceBindValue(displayPath: string) : string{
    var collection = this.getReferenceCollection(displayPath);
    return collection.dataMetadata.find(md => md.primaryKey).javaScriptName;
  }
  getReferenceBindLabel(displayPath: string) : string{
    return Utilities.getLastEntry(displayPath);
  }

  getColumnId(value: FilterField): string {
    return Utilities.getLastEntry(value.fieldName);
  }

  lookupData(filterField: FilterField): LookupDetail[] {
    const propertyName = filterField.columnId;

    if (!this.loading[propertyName]) {
      this.loading[propertyName] = true;
      const md: FTThingDetail = this.getMetadata(propertyName);
      if (md) {
        const lookupName = Utilities.getLastEntry(md.referenceTo);

        const r = FlowService.getLookupDetails(lookupName);
        this.loading[propertyName] = false;
        return r;
      }
    }
  }

  flowData(name: string): FTFlowStatus[] {

    if (!this.flowStatusFilter && !this.flowStatusLoading) {

      this.flowStatusLoading = true;

      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, null, '', name, true)
        .subscribe(results => this.onGetFlowDataSuccessful(results), error => this.onGetFlowDataFailed(error));

    }

    return this.flowStatusFilter;

  }

  private onGetFlowDataSuccessful(dataDetail: DataDetail) {

    this.flowStatusFilter = [];

    dataDetail.dataItems.forEach(flow => {

      if (flow.activeFlowState) {
        const status = FlowService.getFlowStatus(flow.activeFlowState, null);

        if (status) {
          this.flowStatusFilter.push(status);
        }
      }

    });

    this.flowStatusLoading = false;

  }

  private onGetFlowDataFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  check(item: FilterField) {

    let ret;
    if (item.filter1 === false) {
      ret = null;
    }
    if (item.filter1 == null) {
      ret = true;
    }
    if (item.filter1 === true) {
      ret = false;
    }

    // need to set item.filter.value to trigger filter code
    item.filter1 = ret;

    item.filterActive = true;
  }
}
