import { Component, OnInit, Input } from '@angular/core';
import { Utilities } from '../../../../services/utilities/utilities';
import * as moment from 'moment';

@Component({
  selector: 'app-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss', './../input-style.scss']
})
export class DatetimeInputComponent implements OnInit {

  _dataPath: string;
  @Input() row: any;
  @Input() placeholder: string;
  @Input() isDate = true;
  @Input() isTime = false;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() errorText: string;
  date: moment.Moment;
  time: string;
  hours: number;
  minutes: number;

  public timeMask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

  constructor() { }

  ngOnInit() {
  }

  get dataPath(): any {
    return this._dataPath;
  }

  @Input()
  set dataPath(value: any) {

    if (value) {
      this._dataPath = Utilities.getLastEntry(value);

      if (this.row[this.dataPath]) {

        this.date = moment(this.row[this.dataPath]);

        this.setHours(this.date.hours(), this.date.minutes());
      }
    }
  }

  setHours(hours: number, minutes: number) {

    let h: string = hours.toString();
    let m: string = minutes.toString();

    if (h.length === 1) {
      h = '0' + h;
    }

    if (m.length === 1) {
      m = '0' + m;
    }

    this.time = h + ':' + m;
  }

  onDateUpdated(event) {

    this.setDateTime();

  }

  onTimeFocusOut() {

    this.setDateTime();

  }

  setDateTime() {

    let value = this.date;

    if (!value) {
      value = moment();
    }

    if (this.isTime && this.time) {
      const index = this.time.indexOf(':');

      if (index > -1) {

        let hours: number = parseInt(this.time.substring(0, index), 10);
        const minutes: number = parseInt(this.time.substring(index + 1), 10);

        if (hours >= 24) {
          hours -= 24;
          this.setHours(hours, minutes);
        }

        value.hour(hours);
        value.minute(minutes);
      }
    }

    this.row[this.dataPath] = moment.utc(value, 'YYYY-MM-DDTHH:mm:ssZ');
  }
}
