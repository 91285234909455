import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FilterType, ViewType } from 'src/app/models/Enums';
import { ScreenColumn } from 'src/app/models/ScreenColumn';
import { DetailSection } from 'src/app/models/ScreenDetail';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { UpdateDetailItem } from 'src/app/models/viewGroupHelpers/UpdateDetailItem';
import { DetailUtilities } from 'src/app/services/utilities/detailUtilities';
import { MatTable, MatTableDataSource } from '@angular/material';
import { Utilities } from 'src/app/services/utilities/utilities';
import { DataPathHelper } from 'src/app/helpers/data-path-helper';
import { BindingPath } from 'src/app/models/dataPathModels/BindingPath';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';
import { MathsHelper } from 'src/app/helpers/maths-helper';
import { DetailChangeEvent } from 'src/app/models/events/DetailChangeEvent';
import { isNumber } from 'util';
import { ThingService } from 'src/app/services/thing/thing.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-embedded-order-basket',
  templateUrl: './embedded-order-basket.component.html',
  styleUrls: ['./embedded-order-basket.component.scss']
})
export class EmbeddedOrderBasketComponent implements OnInit {

  public ViewType = ViewType;
  public FilterType = FilterType;
  public TaxModes = [{name: "No Tax", id: 0}, {name: "Tax Included", id: 1}, {name: "Tax Excluded", id: 2}]
  destroy$: Subject<boolean> = new Subject<boolean>();

  private isNewOrder: boolean;
  
  @Input() template: DetailSection;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() uriCollections: UriCollection[];
  @Input() screenParameters: any;

  private orderID: number;

  Subtotal: number;
  Tax: number;
  Total: number;

  @ViewChild(MatTable) table: MatTable<any>;
  
  _dataObjArray: any[];
  @Input()
  public set dataObjArray(v: any[]) {
    this._dataObjArray = v;
    this.setData();
  }
  public get dataObjArray(): any[] {
    return this._dataObjArray;
  }

  @Output() valueChangedEvent: EventEmitter<DetailChangeEvent[]> = new EventEmitter<DetailChangeEvent[]>();

  @Output() totalChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() orderBasketItemCountChange: EventEmitter<number> = new EventEmitter<number>();

  dataSource = new MatTableDataSource();

  addBlankItem(){
    var newObj = {};
    this.gridColumns.forEach(column => {
      if(column.defaultValue){
        switch(column.tag){
          case "unitPrice":
          case "grossPrice":
          case "taxPc":
          case "totalPrice":
            var num = Number(column.defaultValue);
            if(num || num === 0){
              newObj[column.tag] = num.toFixed(2);
            }
            else{
              newObj[column.tag] = column.defaultValue;
            }
            break;
          default:
            newObj[column.tag] = column.defaultValue;
            break;
        }
      }
    });
    this.dataSource.data.push(newObj);
    var newBackingRowData = {};
    newBackingRowData[this._orderIDField] = this.orderID;
    this._dataObjArray.push(newBackingRowData);
    this.table.renderRows();
    this.orderBasketItemCountChange.emit(this._dataObjArray.length);
  }

  columnHeadings = [];

  details: UpdateDetailItem[] = [];

  addAction: ScreenColumn;

  allColumns: ScreenColumn[] = [];
  gridColumns: any[] = [];

  rowFocus(row: any, index: number){
    if(index == this.dataSource.data.length - 1){
      this.addBlankItem();
    }
  }

  refreshGrid(): void {
    this.columnHeadings = [];
    this.gridColumns = this.detailUtils.generateScreenDetails(this.allColumns, this.uriCollections, this.screenParameters, this.toastr);
    
    this.gridColumns.unshift({tag: "uiIndex", columnHeader: "#", viewType: ViewType.None});
    if(!this.disabled){
      this.gridColumns.push({tag: "uiDelete", columnHeader: "Delete", viewType: ViewType.None});
    }
    this.gridColumns.forEach(column => {
      this.columnHeadings.push(column.tag);
    });

    if (this.template.templateDataKey) {
      const uc = this.uriCollections.find(u => u.name === this.template.templateDataKey);
      if (uc) {
        this.dataObjArray = uc.dataValues;
      } else {
        this.dataObjArray = [];
      }
    }
    this.dataObjArray = this.dataObjArray ? this.dataObjArray : [];

    this.setData();
  }

  setData(){
    var dataValues = [];
    var uc = this.uriCollections.find(coll => coll.name == this.template.templateDataKey);
    var primaryKeyProperty = uc.dataMetadata.find(detail => detail.primaryKey).javaScriptName;
    this.dataObjArray.forEach(row => {
      var dataItem = {};
      dataItem[primaryKeyProperty] = row[primaryKeyProperty];
      this.gridColumns.forEach(column => {
        if(column.secondaryPropertyName){
          if(DataPathHelper.isCalcPath(column.secondaryPropertyName)){
            var result = MathsHelper.evaluateEquationFromString(column.secondaryPropertyName, null, row);
            if(result == "NaN"){
              return 0;
            }
            dataItem[`${column.tag}-secondary`] = result;
          }
          else{ 
            switch (column.viewType) {
            case ViewType.Text:
            case ViewType.TextImage:
            case ViewType.EditText:
            case ViewType.MultilineEditText:
            case ViewType.Dropdown:
              dataItem[`${column.tag}-secondary`] = row[column.secondaryPropertyName];
              break;
  
            }
          }
        }
        if(DataPathHelper.isCalcPath(column.propertyName)){
          var result = MathsHelper.evaluateEquationFromString(column.propertyName, null, row);
          if(result == "NaN"){
            return 0;
          }
          dataItem[column.tag] = result;
        }
        else{ 
          switch (column.viewType) {
          case ViewType.Text:
          case ViewType.TextImage:
          case ViewType.EditText:
          case ViewType.MultilineEditText:
          case ViewType.Dropdown:
            dataItem[column.tag] = row[column.propertyName];
            break;
          case ViewType.Checkbox:
            if(column.invertCheckbox){
              dataItem[column.tag] = !row[column.propertyName];
            }
            else{
              dataItem[column.tag] = row[column.propertyName];
            }
            break;
          }
        }
      });
      dataValues.push(dataItem);
    });
    this.dataSource.data = dataValues;

    this.calculateTotals();
    
    this.changeDetector.detectChanges();

  }

  getText(column: UpdateDetailItem, row: any) {

    if(DataPathHelper.isCalcPath(column.propertyName)){
      var result = MathsHelper.evaluateEquationFromString(column.propertyName, null, row);
      if(result == "NaN"){
        return 0;
      }
      return result;
    }
    else{ 
      switch (column.viewType) {
      case ViewType.Text:
      case ViewType.TextImage:
      case ViewType.EditText:
      case ViewType.MultilineEditText:
        var value = row[column.propertyName];
        switch(column.tag){
          case "unitPrice":
          case "grossPrice":
          case "taxPc":
          case "totalPrice":
            var num = Number(value);
            if(num){
              return num.toFixed(2);
            }
        }
        return row[column.propertyName];

      // case ViewType.Image:
      //   if (column.icon && column.icon.iconArgument && column.icon.iconArgument.length > 0) {

      //     const property = Utilities.getLastEntry(column.icon.iconArgument);
      //     if (!property.startsWith('data.')) {
      //       return row[property];
      //     } else {
      //       return null;
      //     }
      //   }
      }
    }

    
  }

  getBackgroundColor(errorText: string) {
    if (errorText) {
      return { 'background-color': '#fffcf9' };
    } else {
      return { 'background-color': 'transparent' };
    }
  }

  isEnabled(enabledCondition: string, dataItem: any) {
    if (!enabledCondition) { return true; }
    const ie = Utilities.evaluateCondition(enabledCondition, this.uriCollections, this.screenParameters, null, dataItem, null);
    return ie;
  }

  constructor(public toastr: ToastrService, private detailUtils: DetailUtilities, private thingService: ThingService, private changeDetector: ChangeDetectorRef) { }

  private _taxModeScreenParameter: string = "taxMode";
  private _orderTaxStatusField: string = "orderTaxStatus";
  private _orderIDField: string = "orderID";

  ngOnInit() {
    var screenDetails = [];
    this.template.details.forEach(detail => {
      if(detail.tag == "headerField"){
        screenDetails.push(detail);
      }
      else if(detail.tag == "addAction"){
        this.addAction = detail;
      }
      else{
        this.allColumns.push(detail);
      }
    });
    this.details = this.detailUtils.generateScreenDetails(screenDetails, this.uriCollections, this.screenParameters, this.toastr);

    // The following requires an "order" uriCollection present - this is hardcoded
    
    var orderCollection = this.uriCollections.find(u => u.name == "order" || u.name == "orderParentSummary");
    if(orderCollection && orderCollection.dataValue){
      if(orderCollection.dataValue[this._orderIDField]){
        this.orderID = Number(orderCollection.dataValue[this._orderIDField]);
      }
      else{
        this.isNewOrder = true;
      }
      if(orderCollection.dataValue[this._orderTaxStatusField]){
        var defaultOrderTaxState = Number(orderCollection.dataValue[this._orderTaxStatusField]);
        this.setTaxMode(defaultOrderTaxState);
      }
      else{
        this.setTaxMode(1);
      }
    }
  }

  TaxMode: number;

  saveToUriCollections(){
    var newData = [];
    var orderCollection = this.uriCollections.find(u => u.name == "order");
    if(orderCollection && orderCollection.dataValue){
      orderCollection.dataValue[this._orderTaxStatusField] = this.TaxMode
    }
    var uc = this.uriCollections.find(coll => coll.name == this.template.templateDataKey);
    var primaryKeyProperty = uc.dataMetadata.find(detail => detail.primaryKey).javaScriptName;
    this.dataSource.data.forEach((dataItem, index) => {
      // if(!dataItem["product"] && !dataItem["product-secondary"] && !dataItem["description"]){
      //   return;
      // }
      var newItem = this._dataObjArray[index]; //.find(oldItem => dataItem[primaryKeyProperty] && oldItem[primaryKeyProperty] == dataItem[primaryKeyProperty]);
      if(!newItem){
        newItem = {};
        if(this.orderID){
          newItem[this._orderIDField] = this.orderID;
        }
      }
      this.gridColumns.forEach(column => {
        if(!DataPathHelper.isCalcPath(column.propertyName)){
          newItem[column.propertyName] = dataItem[column.tag];
          if(column.propertyName == "requestedQuantity"){
            newItem["chargeableQuantity"] = newItem[column.propertyName];
          }
          if(column.viewType == ViewType.Checkbox && column.invertCheckbox){
            newItem[column.propertyName] = !newItem[column.propertyName];
          }
        }
        if(column.secondaryPropertyName){
          if(!DataPathHelper.isCalcPath(column.secondaryPropertyName)){
            newItem[column.secondaryPropertyName] = dataItem[`${column.tag}-secondary`];
          }
        }
      });
      newData.push(newItem);
    });
    this._dataObjArray = newData;
    // if(this._dataObjArray){
    //   for (let index = 0; index < this._dataObjArray.length; index++) {
    //     this.gridColumns.forEach(column => {
    //       if(!DataPathHelper.isCalcPath(column.propertyName)){
    //         this._dataObjArray[index][column.propertyName] = this.dataSource.data[index][column.tag];
    //       }
    //     });
    //   }
    //   var dataView = this._dataObjArray;
    // }
  }

  public save() {
    this.saveToUriCollections();
    var orderCollection = this.uriCollections.find(u => u.name == "order" || u.name == "orderParentSummary");
    if(this.isNewOrder && orderCollection && orderCollection.dataValue){
      if(orderCollection.dataValue[this._orderIDField]){
        this.orderID = Number(orderCollection.dataValue[this._orderIDField]);
      }
    }
    if(this.orderID){
      for (let index = 0; index < this._dataObjArray.length; index++) {
        const dataItem = this.dataSource.data[index];
        if(!dataItem["product"] && !dataItem["product-secondary"] && !dataItem["description"]){
          //Blank row, don't save
        }
        else{
          const backingDataItem = this._dataObjArray[index];
          if(!backingDataItem.orderID){
            backingDataItem[this._orderIDField] = this.orderID;
          }
          this.saveOrderItem(backingDataItem);
        }
      }
    }
    
  }

  saveOrderItem(item) {
    if(this.disabled){
      //Prevent saving when disabled as a backup
      return;
    }
    this.thingService.updateOrderItem(item)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => console.log(results), error => console.log(error));
  }


  showTax: boolean = false;

  setTaxMode(newTaxMode){
    if(!isNumber(newTaxMode)){
      return;
    }
    this.TaxMode = newTaxMode;
    //set dropdown to new value
    var previousState = this.screenParameters[this._taxModeScreenParameter];
    this.saveToUriCollections();
    switch (newTaxMode) {
      case 0: //No Tax
        this.screenParameters[this._taxModeScreenParameter] = "noTax";
        this.showTax = false;
        try{
          this._dataObjArray.forEach(data => {
            data["itemValueTax"] = 0;
          });
        }
        catch {}
        break;
      case 1: //Tax Included
        this.screenParameters[this._taxModeScreenParameter] = "taxIncluded";
        this.showTax = true;
        if(previousState == "noTax"){
          //set tax on all order items to default rate
        }
        break;
      case 2: //Tax Excluded
        this.screenParameters[this._taxModeScreenParameter] = "taxExcluded";
        this.showTax = true;
        if(previousState == "noTax"){
          //set tax on all order items to default rate
        }
        break;
    
      default:
        break;
    }
    this.refreshGrid();
  }

  getRootObject(item: UpdateDetailItem): any {

    if (item.objectName) {
      let rootOb: any;
      const uriCollection = this.uriCollections.find(u => u.name === item.objectName);
      if (uriCollection && uriCollection.dataValue) {
        if (item.childObjectName) {
          rootOb = uriCollection.dataValue[item.childObjectName];
        } else {
          rootOb = uriCollection.dataValue;
        }
      } else {
        Utilities.log2('UpdateDetail', `Can't find a uriCollection for ${item.objectName} (${item.propertyName})`, 'warn');
      }
      return rootOb;
    }
  }

  public lookupDataUri(itemSource: string) {

    if (!itemSource) { return; }

    itemSource = itemSource.substring(itemSource.lastIndexOf('.') + 1);

    if (!this.uriCollections) { return; }

    const uriCollection = this.uriCollections.find(u => u.name === itemSource);
    if (uriCollection) {
      const uris = this.uriCollections.find(u => u.name === itemSource).uris;
      if (uris) {
        return uris.dataUri;
      }
    } else {
      this.toastr.error(`Can't find uri collection for ${itemSource}`);
    }
    return null;
  }

  dropdownChange(changedValue, column: UpdateDetailItem, row, index: number){
    this.handleValueChange(changedValue, column, row, index);
  }

  valueChanged(changeEvent, column: UpdateDetailItem, row, index: number){
    var ev = changeEvent;
    switch(column.tag){
      case "unitPrice":
      case "grossPrice":
      case "taxPc":
      case "totalPrice":
        var num = Number(changeEvent.target.value);
        if(num){
          this.dataSource.data[index][column.tag] = num.toFixed(2);
        }
    }
    this.handleValueChange(changeEvent.target.value, column, row, index);
  }

  handleValueChange(changedValue, column: UpdateDetailItem, row, index: number){
    if(!column.publishSubscribeAction)
    {
      this.calculateTotals();
      return;
    }

    var detailChangeEvents: DetailChangeEvent[] = [];

    column.publishSubscribeAction.forEach(changeEvent => {
      if(changeEvent.Condition && !Utilities.evaluateCondition(changeEvent.Condition, this.uriCollections, this.screenParameters, null, row, null)){
        return;
      }
      var newValueArgs = [];
      changeEvent.NewValueArgs.forEach(arg => {
        if(DataPathHelper.isBindingPath(arg)){
          var bindingPath = new BindingPath(arg);
          if(bindingPath.isValid){
            var binding = this.details.find(detail => detail.tag == bindingPath.tag);
            if(!binding){
              binding = this.gridColumns.find(detail => detail.tag == bindingPath.tag);
            }

            if(binding){
              var id = `${binding.tag}-${index}`
              var value = (<HTMLInputElement>document.getElementById(id)).value;
              if(value){
                newValueArgs.push(value);
              }
              else{
                newValueArgs.push("0");
              }
            }
            else{
              newValueArgs.push("0");
            }
          }
        }
        else if(arg.includes("value")){
          if(!changedValue){
            return;
          }
          if(arg.startsWith("value|")){
            var argParts = arg.split("|");
            var dataPath = new DataPath(argParts[1]);
            if(dataPath && dataPath.isValid){
              if(changedValue && (changedValue[dataPath.propertyName] === 0 || changedValue[dataPath.propertyName])){
                newValueArgs.push(changedValue[dataPath.propertyName]);
              }
              else{
                var collection = this.uriCollections.find(u => u.name == dataPath.rootObjectName);
                if(!collection){
                  collection = this.uriCollections.find(u => u.name.toLowerCase() == dataPath.rootObjectName.toLowerCase());
                }
                newValueArgs.push(collection.dataValue[dataPath.propertyName]);
              }
            }
          }
          else{
            newValueArgs.push(changedValue);
          }
        }
        else{
          var dataPath = new DataPath(arg);
          if(dataPath && dataPath.isValid){
            if(row && row[dataPath.propertyName]){
              newValueArgs.push(row[dataPath.propertyName]);
            }
            else{
              var collection = this.uriCollections.find(u => u.name == dataPath.rootObjectName);
              if(collection.dataValue[dataPath.propertyName]){
                newValueArgs.push(collection.dataValue[dataPath.propertyName]);
              }
              else{
                newValueArgs.push(0);
              }
            }
          }
        }

      });
    
      var newValue = Utilities.stringFormat(changeEvent.NewValue, newValueArgs.map(arg => !!arg ? arg : 0));
      newValue = MathsHelper.evaluateEquationFromString(newValue, this.uriCollections);

      if(newValue){
        if(changeEvent.Target.startsWith("data.")){
          var pathParts = changeEvent.Target.split('.');
          var dataType = pathParts[1];
          if(dataType == this.template.templateDataKey){
            // Data to be updated is the list in this component
            var uc = this.uriCollections.find(coll => coll.name == this.template.templateDataKey);
            var primaryKeyProperty = uc.dataMetadata.find(detail => detail.primaryKey).javaScriptName;
            var backingRowData = this._dataObjArray[index]; // this._dataObjArray.find(dataObj => dataObj[primaryKeyProperty] == row[primaryKeyProperty]);
            if(!backingRowData){
              var newBackingRowData = {};
              newBackingRowData[this._orderIDField] = this.orderID;
              newBackingRowData[pathParts[2]] = newValue;
              this._dataObjArray.push(newBackingRowData);
            }
            else{
              if(!backingRowData[pathParts[2]] || changeEvent.OverwriteValue){
                backingRowData[pathParts[2]] = newValue;
              }
              this.calculateTotals();
              return;
            }
          }
        }
        else{
          var id = `${changeEvent.Target}-${index}`;
          var element : any = (document.getElementById(id));
          if(element){
            switch(changeEvent.Target){
              case "unitPrice":
              case "grossPrice":
              case "taxPc":
              case "totalPrice":
                var num = Number(newValue);
                if(num){
                  if(!this.dataSource.data[index][changeEvent.Target] || changeEvent.OverwriteValue){
                    this.dataSource.data[index][changeEvent.Target] = num.toFixed(2);
                  }
                  this.calculateTotals();
                  return;
                }
            }
          }
          else if(!this.dataSource.data[index][changeEvent.Target] || changeEvent.OverwriteValue){
            this.dataSource.data[index][changeEvent.Target] = newValue;
          }
        }
        detailChangeEvents.push(new DetailChangeEvent(column.tag, changeEvent.Target, newValue, changeEvent.OverwriteValue, index));
      }
    });

    if(detailChangeEvents.length > 0){
      this.valueChangedEvent.emit(detailChangeEvents);
    }
    
    this.calculateTotals();

  }

  calculateTotals(){
    this.Subtotal = 0;
    this.Tax = 0;
    this.Total = 0;
    for (let index = 0; index < this._dataObjArray.length; index++) {
      const dataItem = this.dataSource.data[index];
      const backingData = this._dataObjArray[index];
      var grossPrice = Number(dataItem["totalPrice"] ? dataItem["totalPrice"] : backingData["itemValueGross"]);
      var taxPrice = Number(dataItem["itemValueTax"] ? dataItem["itemValueTax"] : backingData["itemValueTax"]);
      this.Subtotal += (grossPrice? grossPrice : 0) - (taxPrice? taxPrice : 0);
      this.Tax += taxPrice? taxPrice : 0;
    }
    
    this.Total = this.Subtotal + this.Tax;

    this.totalChangedEvent.emit({net: this.Subtotal, tax: this.Tax, gross: this.Total});
    this.orderBasketItemCountChange.emit(this._dataObjArray.length);
  }

  deleteItem(row: any){
    var index = this.dataSource.data.indexOf(row);
    if(index == -1){
      return
    }
    var uc = this.uriCollections.find(coll => coll.name == this.template.templateDataKey);
    var primaryKeyProperty = uc.dataMetadata.find(detail => detail.primaryKey).javaScriptName;
    if(row[primaryKeyProperty]){
      //Row exists on backend
      // var backingData = uc.dataValues.find(oldItem => oldItem[primaryKeyProperty] == row[primaryKeyProperty]);
      this.thingService.deleteItem("/api/data/delete/orderItem", [row[primaryKeyProperty]])
        .pipe(takeUntil(this.destroy$))
        .subscribe(results => 
          {
            console.log(results);
            this.dataSource.data.splice(index, 1);
            this.dataSource.data = this.dataSource.data;
            this._dataObjArray.splice(index, 1);
            this.calculateTotals();
          }, error => console.log(error));
      }
    else{
      //Row only exists on frontend
      this.dataSource.data.splice(index, 1);
      this.dataSource.data = this.dataSource.data;
      this._dataObjArray.splice(index, 1);
      this.calculateTotals();
    }
  }
}
