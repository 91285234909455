
import { ListDetail } from './ListDetail';

// Web UI generated object
export class ListDetailGroup {

  items: ListDetail[];

  activeItem: ListDetail;
}
