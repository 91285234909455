import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { IGanttOptions, Project } from '../interfaces';
import { GanttService } from '../services/gantt/gantt.service';

@Component({
  selector: 'app-base-gantt',
  templateUrl: './base-gantt.component.html',
  styleUrls: ['./base-gantt.component.scss']
})
export class BaseGanttComponent implements OnInit {

  public _project: Project;
  public _options: IGanttOptions;
  allEvents: any[];
  public primaryEventTypeId: number;

  _currentMode: string;
  @Input()
  public set currentMode(value: string) {
    if (value != this._currentMode) {
      this._currentMode = value;
    }
  }
  public get currentMode() { return this._currentMode; }
  @Output() currentModeChange = new EventEmitter<string>();

  handleCurrentModeChange(value:string){
    this.currentModeChange.emit(value);
  }

  @Input() publicDataSource: string;
  @Input() managerDataSource: string;
  @Input() privateDataSource: string;
  @Input() showDataSourceSelect: boolean;

  @Input()
  events: any[];

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();


  @Input()
  categories: any[];

//   @Input()
//   set project(project: any) {
//       if (project) {
//           this._project = project;
//       } else {
//           this.setDefaultProject();
//       }
//   }
//   get project() { return this._project };

  @Input() 
  set options(options: any) {
      if (!!options && options.scale) {
          this._options = options;
      } else {
          this.setDefaultOptions();
      }
  }
  get options() { return this._options };

  @Output() onGridRowClick: EventEmitter<any> = new EventEmitter<any>();
  
  private ganttContainerWidth: number;

  constructor(private ganttService: GanttService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.allEvents = this.events;
    this.primaryEventTypeId = 1;
   }

  setSizes(): void {
      this.ganttContainerWidth = this.ganttService.calculateContainerWidth();
  }

  setDefaultOptions() {
      console.log(this._project.events);
      var scale = this.ganttService.calculateGridScale(this._project.events);

      this._options = {
          scale: scale
      }
  }

  setDefaultProject() {
      this._project = {
          id: '',
          name: '',
          startDate: undefined,
          events: []
      }
  }

  gridRowClicked(task:any) {
      this.onGridRowClick.emit(task);
  }

  onResize($event: any): void {
      this.setSizes();
  }


  onSelect(item: any) {
    this.itemSelected.emit(item);
}

}
