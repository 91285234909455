import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { Action } from '../../../../models/actions/Action';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { ListDetail } from '../../../../models/ListDetail';
import { ListDetailGroup } from '../../../../models/ListDetailGroup';
import { ActionIdentifier, ScreenType } from '../../../../models/Enums';
import { ItemListComponent } from '../../lists/item-list/item-list.component';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';

export interface DialogData {
  action: Action;
  row: any[];
}

@Component({
  selector: 'app-switch-content-view-dialog',
  templateUrl: './switch-content-view-dialog.component.html',
  styleUrls: ['./switch-content-view-dialog.component.scss']
})
export class SwitchContentViewDialogComponent implements OnInit {

  row: any;
  action: Action;
  listHeight = 430;
  loadingIndicator = true;
  title: string;

  screenDetailComponents: ScreenDetail; // Initialised in ngOnInit subscribe call

  listDetailGroups: ListDetailGroup[];
  activeListDetailGroup: ListDetailGroup; // Active list

  @ViewChildren(ItemListComponent) itemLists: QueryList<ItemListComponent>;

  constructor(public toastr: ToastrService, private thingService: ThingService, public dialogRef: MatDialogRef<SwitchContentViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
     }

  ngOnInit() {
    this.row = this.data.row;
    this.action = Utilities.parseAction(this.data.action, this.row);

    this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
      .subscribe(result => this.onGetDetailSuccessful(result), error => this.onGetDetailFailed(error));
  }

  get loadingMargin(): number {
    let height: number = (this.listHeight / 2) - 16;

    if (height < 0) {
      height = 0;
    }

    return height;
  }

  private onGetDetailSuccessful(result: ScreenDetail) {

    this.loadingIndicator = false;
    this.screenDetailComponents = result;

    this.title = Utilities.parseArgumentsFromData(result.screenTitle.text, result.screenTitle.argumentIds, this.row, null, this.action.actionArgument.screenParameters);

    let activeComponent: ScreenDetail = this.screenDetailComponents.componentScreens.find(c => c.screenTag === this.screenDetailComponents.screenProperties.defaultComponentScreen);

    if (!activeComponent) {
      // Can't find default Component, fallback to selecting first in list
      if (this.screenDetailComponents.componentScreens.length >= 1) {
        activeComponent = this.screenDetailComponents.componentScreens[0];
      }
    }

    this.listDetailGroups = [];
    for (const component of this.screenDetailComponents.componentScreens) {

      const group: ListDetailGroup = new ListDetailGroup();
      group.items = [];

      const item: ListDetail = new ListDetail();
      item.screenDetail = component;
      item.action = this.action;
      item.row = this.row;
      item.loadScreenDetail = false;

      group.items.push(item);
      group.activeItem = item;

      this.listDetailGroups.push(group);

      if (activeComponent === component) {
        this.activeListDetailGroup = group;
      }

    }
  }

  private onGetDetailFailed(error: any) {
    this.loadingIndicator = false;
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  onAction(event) {

    if (event.action.action == ActionIdentifier.LaunchScreen && event.action.actionArgument.screenType == ScreenType.ItemGridList) {

      const existingListDetail: ListDetail = this.activeListDetailGroup.items
        .find(a => a.action.actionArgument.uris[0].dataUri == event.action.actionArgument.uris[0].dataUri);

      if (existingListDetail) {
        alert('select existing!');
        // this.selectAction(existingAction);
      } else {

        const item: ListDetail = new ListDetail();
        item.action = event.action;
        item.row = event.row;

        this.activeListDetailGroup.items.push(item);
        this.activeListDetailGroup.activeItem = item;
      }
    } else {
      alert('Not supported!');
    }
  }

  public onBack() {
    this.onSelect(this.activeListDetailGroup.items[this.activeListDetailGroup.items.length - 2]);
  }

  private onSelect(listDetail: ListDetail) {

    if (listDetail) {
      this.activeListDetailGroup.activeItem = listDetail;
    } else {
      this.activeListDetailGroup.activeItem = this.activeListDetailGroup.items[0];
    }

    // Collapse breadcrumbs
    const index = this.activeListDetailGroup.items.indexOf((this.activeListDetailGroup.activeItem))

    if (index < (this.activeListDetailGroup.items.length + 1)) {
      this.activeListDetailGroup.items.splice(index + 1, this.activeListDetailGroup.items.length - index + 1);
    }
  }

  public onTitleUpdated(event: TitleUpdateEvent) {

    for (const group of this.listDetailGroups) {

      const listDetail = group.items.find(m => m.id === event.id);
      if (listDetail) {
        listDetail.title = event.title;
        listDetail.subtitle = event.subtitle;
      }
    }
  }

  onAdd(event) {
    alert('Not supported!');
  }

  onView(event) {
    alert('Not supported!');
  }

  onDeleteCompleted(event) {
    alert('Not supported!');
  }

  onUpdateCompleted(event: any) {

    for (const listDetail of this.activeListDetailGroup.items) {

      if (listDetail !== this.activeListDetailGroup.activeItem) {

        for (const list of this.itemLists.toArray()) {

          if (list.id === listDetail.id) {
            list.refresh();
          }
        }
      }
    }

  }

  onSwitch() {

    // Support for only switching between to items
    if (this.listDetailGroups.length === 2) {

      if (this.listDetailGroups[0] === this.activeListDetailGroup) {
        this.activeListDetailGroup = this.listDetailGroups[1];
      } else {
        this.activeListDetailGroup = this.listDetailGroups[0];
      }

    }

  }

  onRefreshLists() {
    this.itemLists.forEach(list => {
      list.refresh();
    });
  }

}
