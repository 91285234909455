import { Component, OnInit, Input, ViewChild } from '@angular/core';
// import moment = require('moment');
import { Utilities } from 'src/app/services/utilities/utilities';
import { MatDatepicker, MatDatepickerToggle } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-q-multi-datetime-input',
  templateUrl: './q-multi-datetime-input.component.html',
  styleUrls: ['./q-multi-datetime-input.component.scss'],
})
export class QMultiDatetimeInputComponent implements OnInit {

  // private _date1Path: string;
  // private _date2Path: any;
  @Input() row: any;
  @Input() placeholder1: string;
  @Input() placeholder2: string;
  @Input() required: boolean;
  @Input() min?: Date;
  @Input() max?: Date;

  @Input() date1Path: string;
  @Input() date2Path: string;
  @Input() date1: string;
  @Input() date2: string;
  date1Property: string;
  date2Property: string;

  _date1: moment.Moment;
  _date2: moment.Moment;

  @ViewChild('datePicker1') public datePicker1: MatDatepicker<any>
  @ViewChild('datePicker2') public datePicker2: MatDatepicker<any>
  @ViewChild('datePickerToggle') public datePickerToggle: MatDatepickerToggle<any>

  // TODO bug due to UTC time outstanding

  constructor() { }

  ngOnInit() {
    this.date1Property = Utilities.getLastEntry(this.date1Path);
    this.date2Property = Utilities.getLastEntry(this.date2Path);

    // console.log(moment(this.date1)) // .tz('Europe/London'));
    this._date1 = moment.utc(this.date1).local();
    // console.log(this._date1);
    this._date2 = moment.utc(this.date2).local();
  }

  onDate1Updated(event) {

    this.setDateTime1();
    this.datePickerToggle.datepicker = this.datePicker1;


    if (this.datePicker1.opened) {
      this.datePickerToggle.datepicker = this.datePicker2;
      this.datePicker2.open();
    }
  }

  onDate1Close() {

    this.datePickerToggle.datepicker = this.datePicker2;
    this.datePicker2.open();
  }

  onDate2Updated(event) {

    this.datePickerToggle.datepicker = this.datePicker1;
    this.setDateTime2();
  }

  onDate2Close() {
    this.datePickerToggle.datepicker = this.datePicker1;
  }

  setDateTime1() {

    let value = this._date1;

    if (!value) {
      // value = new Date;
      value = moment();
    }

    console.log(this.row[this.date1Property]);
    this.row[this.date1Property] = value; //.toDate();
    console.log(this.row[this.date1Property]);//.toDate());
  }

  setDateTime2() {

    let value = this._date2;

    if (!value) {
      // value = new Date;
      value = moment();
    }

    this.row[this.date2Property] = value; //.toDate();
  }
}
