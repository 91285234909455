import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { first } from 'rxjs/operators';
import { ValidationResponse } from 'src/app/models/PostResponse';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ThingService } from 'src/app/services/thing/thing.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  oldPasswordError: string; // Observable<string>;
  newPasswordError: string; // Observable<string>;
  confirmPasswordError: string; // Observable<string>;
  oldPasswordMask = true;
  newPasswordMask = true;
  confirmPasswordMask = true;

  constructor(public dataService: ThingService, public authService: AuthenticationService,
    private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ChangePasswordComponent>, private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  validateOldPassword(control: AbstractControl) {
    return this.oldPasswordError ? null : { invalid: true };
  }

  validateNewPassword(control: AbstractControl) {
    return this.newPasswordError ? null : { invalid: true };
  }

  confirmPassword(control: AbstractControl) {
    return this.confirmPasswordError ? null : { invalid: true };
  }

  onSubmit() {

    if (this.changePasswordForm.controls.newPassword.value !== this.changePasswordForm.controls.confirmPassword.value) {
      this.changePasswordForm.controls.newPassword.setErrors({ invalid: true });
      this.newPasswordError = 'These passwords must match';
      this.changePasswordForm.controls.confirmPassword.setErrors({ invalid: true });
      this.confirmPasswordError = 'These passwords must match';

      return;
    } else {
      if (this.changePasswordForm.controls.newPassword.hasError('invalid')) {
        delete this.changePasswordForm.controls.newPassword.errors.invalid;
        this.newPasswordError = null;
        if (!this.changePasswordForm.controls.newPassword.hasError('required')) {
          this.changePasswordForm.controls.newPassword.setErrors(null);
        }
      }
      if (this.changePasswordForm.controls.confirmPassword.hasError('invalid')) {
        delete this.changePasswordForm.controls.confirmPassword.errors.invalid;
        this.confirmPasswordError = null;
        if (!this.changePasswordForm.controls.confirmPassword.hasError('required')) {
          this.changePasswordForm.controls.confirmPassword.setErrors(null);
        }
      }
    }

    if (this.changePasswordForm.invalid) {

      this.changePasswordForm.controls.oldPassword.markAsTouched();
      this.changePasswordForm.controls.newPassword.markAsTouched();
      this.changePasswordForm.controls.confirmPassword.markAsTouched();

      return;
    }

    this.oldPasswordError = this.newPasswordError = this.confirmPasswordError = null;

    this.dataService.changePassword(this.changePasswordForm.controls.oldPassword.value, this.changePasswordForm.controls.newPassword.value,
      this.authService.getToken())
      .pipe(first())
      .subscribe(
        response => {
          if (response.errorCode === 0) {
            this.dialogRef.close();
          } else if (response.validationResponse != null) {
            this.handleValidation(response.validationResponse);
          } else {
            console.log(response.errorMessage);
          }
        },
        error => {
          console.log(error);
          // this.loading = false;
          // setTimeout(() => this.toastr.error(error));
        }
      );
  }

  handleValidation(validationResponse: ValidationResponse) {

    validationResponse.Items.forEach(element => {
      if (element.PropertyName === 'oldPassword') {
        this.changePasswordForm.controls.oldPassword.setErrors({ invalid: true });
        this.oldPasswordError = element.Message;
      }
      if (element.PropertyName === 'newPassword') {
        this.changePasswordForm.controls.newPassword.setErrors({ invalid: true });
        this.newPasswordError = element.Message;
      }
    });
  }
}
