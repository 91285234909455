import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { SelectedItem } from '../../../../models/SelectedItem';
import { DataDetail } from '../../../../models/DataDetail';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-virtual-scroll-multi-dropdown',
  templateUrl: './virtual-scroll-multi-dropdown.component.html',
  styleUrls: ['./virtual-scroll-multi-dropdown.component.scss']
})
export class VirtualScrollMultiDropdownComponent implements OnInit, AfterViewInit {

  @Input()
  selectedItem: SelectedItem;

  _dataUri: string;
  @Input()
  get dataUri(): string {
    return this._dataUri;
  }
  set dataUri(v: string) {
    const pts = v.split('?');
    if (pts.length > 1) {
      const queryPartSplit = pts[1].split('&');
      const orderIndex = queryPartSplit.findIndex(p => p.startsWith('$orderby'));
      if (orderIndex > -1) {
        queryPartSplit.splice(orderIndex, 1);
      }
      const query = queryPartSplit.join('&');
      this._dataUri = `${pts[0]}?${query}`;
    } else {
      this._dataUri = v;
    }
  }

  @Input()
  name: string;

  @Input()
  bindValue: string;

  @Input()
  bindLabel: string;

  @Input()
  labelName: string;

  @Input()
  multiple: boolean = false;

  @Input()
  required: boolean = false;

  totalRecordCount: number;

  returnRecordCount: number;

  loading: boolean = true;

  dataItems: any[];

  pageIndex: number = 0;

  pageSize: number = 10;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.loading = true;

    this.thingService.getDataDetailWithHeader(this.dataUri, this.pageIndex, this.pageSize, null, null, null, "", this.name, true)
      .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));

  }

  private onGetDataSuccessful(dataDetail: DataDetail) {

    this.loading = false;
    this.totalRecordCount = dataDetail.totalRecordCount;

    if (this.pageIndex === 0) {
      this.dataItems = dataDetail.dataItems;
      this.returnRecordCount = dataDetail.returnRecordCount;
    }    else {

      this.dataItems = this.dataItems.concat(dataDetail.dataItems);
      this.returnRecordCount += dataDetail.returnRecordCount;

    }

  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, null, { closeButton: true, tapToDismiss: true });
    this.loading = false;
  }

  fetchMore(event) {

    if (this.returnRecordCount < this.totalRecordCount) {

      this.pageIndex++;
      this.loading = true;

      this.thingService.getDataDetailWithHeader(this.dataUri, this.pageIndex, this.pageSize, null, null, null, "", this.name, true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));

    } else {
      return false;
    }
  }
}
