import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OrderBasketCardModel } from 'src/app/models/cardModels/OrderBasketCardModel';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { UIDataType } from 'src/app/models/Enums';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { LineSlider } from 'src/app/models/viewControls/LineSlider';
import { LineSliderResults } from 'src/app/models/viewControls/LineSliderResults';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'app-order-basket-card',
  templateUrl: './order-basket-card.component.html',
  styleUrls: ['./order-basket-card.component.scss', './../card-style.scss']
})
export class OrderBasketCardComponent implements OnInit {

  public static cardHeight = 160;

  @Input() cardTemplate: OrderBasketCardModel;
  @Input() dataObjectKey: string;
  @Input() dataItem: any;
  @Input() groupName: any;
  @Input() selectedItem: any;
  @Input() hasContextMenu: boolean;
  @Input() contextMenu: any;
  @Input() uriCollections?: UriCollection[] = [];
  @Output() onAction = new EventEmitter<any>();
  @Output() onContextMenuClick = new EventEmitter<any>();
  @Input() metadata: FTThingDetail[];
  lineSliderResults: any;
  stockLoading: boolean = false;

  public DataType = UIDataType;
  public OrderBasketCardComponent = OrderBasketCardComponent;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {
  }

  public getTitle(row: any): string {

    return Utilities.parseTitle(this.cardTemplate.title.text, row, null, null);
  }

  public getDetail1(row: any): string {

    if (this.cardTemplate.detail1 && this.cardTemplate.detail1.text.argumentIds) {
      return Utilities.parseArgumentsFromData(this.cardTemplate.detail1.text.text, this.cardTemplate.detail1.text.argumentIds, row, null, null);
    }

    return '';
  }

  public getDetail2(row: any): string {

    if (this.cardTemplate.detail2 && this.cardTemplate.detail2.text.argumentIds) {
      return Utilities.parseArgumentsFromData(this.cardTemplate.detail2.text.text, this.cardTemplate.detail2.text.argumentIds, row, null, null);
    }
    return '';
  }

  private getIcon(row: any, elementName: string): string {

    const status = this.cardTemplate[elementName];
    if (status && status.icon && row) {
      return Utilities.evaluate(status.icon.iconArgument, row, this.isChecked(row, elementName));
    }

    return '';
  }

  public isVisible(row: any, elementName: string): boolean {

    const status = this.cardTemplate[elementName];
    if (status && status.isVisibleCondition && row) {
      return Utilities.evaluate(status.isVisibleCondition, row);
    }

    return true;
  }

  // public hasElement(elementName: string) {
  //   return (this.cardTemplate[elementName]) ? true : false;
  // }

  public getElementPropertyName(elementName: string): string {
    if (this.cardTemplate[elementName] && this.cardTemplate[elementName].text) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].text.argumentIds[0]);
    } else if (this.cardTemplate[elementName] && this.cardTemplate[elementName].icon) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
    }
    return '';
  }

  private get lineSlider(): LineSlider {

    if (this.cardTemplate.lineSlider) {
      return this.cardTemplate.lineSlider;
    }

    return null;
  }

  public getLineSliderResults(row: any): LineSliderResults {

    if (!row[this.lineSliderResults]) {
      row[this.lineSliderResults] = new LineSliderResults();
      this.loadLineSlider(row, row[this.lineSliderResults]);
    }

    return row[this.lineSliderResults];
  }

  get lineSliderQuantity(): string {

    if (this.lineSlider && this.lineSlider.pointerPathArguments) {

      return Utilities.getLastEntry(this.lineSlider.pointerPathArguments[0]);
    }
  }

  private loadLineSlider(row: any, lineSliderResults: LineSliderResults) {

    this.stockLoading = true;
    const dataUri = Utilities.parseArgumentsFromUriCollection(this.lineSlider.dataUri, this.lineSlider.dataUriArguments, this.uriCollections, row);

    this.thingService.getMovementHistory(dataUri)
      .subscribe(result => {

        if (result.dataSeries) {
          lineSliderResults.minAvailableStock = result.dataSeries[0].minLevel;
          lineSliderResults.maxAvailableStock = result.dataSeries[0].maxLevel;
          lineSliderResults.loading = false;
        }

      }, error => { this.onGetDetailFailed(error); lineSliderResults.loading = false; });
  }

  private onGetDetailFailed(error: any) {
    this.stockLoading = false;
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public setSliderValue(row: any, id: string, event: number) {

    row[id] = event;

  }

  private getColor(row: any): string {

    if (this.cardTemplate.cardColour) {
      const path = Utilities.getLastEntry(this.cardTemplate.cardColour.icon.colour);

      const color: string = row[path];
      if (color && color.length > 0) {
        if (color.startsWith('#')) {
          return '#' + color;
        } else {
          return color;
        }
      }

      return '';
    }

    return '';
  }

  public onActionClick(event, dataItem, elementName, isChecked?: boolean) {
    let action;
    if (isChecked != null) {
      // this.cardTemplate[elementName] = isChecked;
      if (isChecked) {
        action = this.cardTemplate[elementName].onCheckedAction;
      } else {
        action = this.cardTemplate[elementName].onUncheckedAction;
      }
    } else {
      action = this.cardTemplate[elementName].action;
    }

    this.onAction.emit({ event: event, dataItem: dataItem, action: action });
  }

  public onContextMenu(event, dataItem) {
    this.onContextMenuClick.emit({ event: event, dataItem: dataItem });
  }

  public valueChanged(elementName: string, event: any) {
    const action = this.cardTemplate[elementName].action;
    if (action) {
      this.onAction.emit({ event: event, dataItem: this.dataItem, action: this.cardTemplate[elementName].action });
    }
  }

  public isEnabled(row: any, elementName: string): boolean {

    const element = this.cardTemplate[elementName];
    if (element && element.isEnabledCondition && row) {
      if (this.uriCollections.length < 2) {
        console.log('not enough uriCollections');
        console.log(this.uriCollections);
      }
      return Utilities.evaluateCondition(element.isEnabledCondition, this.uriCollections, null, null, row, this.dataObjectKey);
    }
    return true;
  }

  public isChecked(row: any, elementName: string): boolean {

    const element = this.cardTemplate[elementName];
    if (element && element.isCheckedCondition && row) {
      if (this.uriCollections.length < 2) {
        console.log('not enough uriCollections');
      }

      return Utilities.evaluateCondition(element.isCheckedCondition, this.uriCollections, null, null, row, this.dataObjectKey);
    }
    return true;
  }

  public getDataType(elementName: string): UIDataType {

    if (this.metadata && this.getElementPropertyName(elementName)) {
      const m = this.metadata.find(md => md.javaScriptName === this.getElementPropertyName(elementName));
      if (m) {
        const dt = DataUtils.dataTypeToUIDataType(m.dataType, m.referenceTo);
        return dt;
      }
    }
    return UIDataType.Unknown;
  }

  public getImage(elementName: string) {
    if (this.cardTemplate[elementName]) {
      const argId = Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
      const fileId = this.dataItem[argId];
      if (fileId) {
        return this.thingService.getFileUrl(fileId, 'small');
      }
    }
  }

  getCardColor(): string {

    if (!this.cardTemplate.cardColour) {
      return '';
    }
    const property = Utilities.getLastEntry(this.cardTemplate.cardColour.icon.colour);
    if (this.dataItem[property]) {

      if (this.dataItem[property].length > 0) {
        return `${this.dataItem[property]}`;
      }
    }
    return '';
  }

  getHint(elementName: string): string {

    if (!this.cardTemplate[elementName]) { return ''; }

    const path = this.cardTemplate[elementName]['hint'];
    return this.getValue(path);
  }

  getValue(propertyPath: string) {
    if (propertyPath == null || propertyPath === '') {
      return '';
    }
    if (propertyPath.startsWith('data.')) {
      return Utilities.getData(propertyPath, this.dataItem);
    } else {
      return propertyPath;
    }
  }
}
