import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../components/controls/dialogs/confirm-dialog/confirm-dialog.component';
import { GraphDialogComponent } from '../components/controls/dialogs/graph-dialog/graph-dialog.component';
import { ViewDetailsDialogComponent } from '../components/controls/dialogs/view-details-dialog/view-details-dialog.component';
import { IGanttOptions, Project, Zooming } from '../interfaces';
import { Action } from '../models/actions/Action';
import { LaunchScreenAction } from '../models/actions/LaunchScreenAction';
import { UpdateDataAction } from '../models/actions/UpdateDataAction';
import { UpdateScreenParametersAction } from '../models/actions/UpdateScreenParametersAction';
import { CardItem } from '../models/cardModels/CardItem';
import { ActionURI } from '../models/data/ActionURI';
import { FTThingDetail } from '../models/data/FTThingDetail';
import { MenuItem } from '../models/data/MenuItem';
import { DataDetail } from '../models/DataDetail';
import { ActionIdentifier, ScreenType } from '../models/Enums';
import { ActionEvent } from '../models/events/ActionEvent';
import { AddUpdateItemModel } from '../models/events/AddUpdateItemModel';
import { DeleteEvent } from '../models/events/DeleteEvent';
import { ItemClickEvent } from '../models/events/ItemClickEvent';
import { LoadDataEvent } from '../models/events/LoadDataEvent';
import { UpdateCompletedEvent } from '../models/events/UpdateCompletedEvent';
import { UpdateScreenDataEvent } from '../models/events/UpdateScreenDataEvent';
import { ViewDetailEvent } from '../models/events/ViewDetailEvent';
import { PostResponse } from '../models/PostResponse';
import { ScreenDetail } from '../models/ScreenDetail';
import { UriCollection } from '../models/screenModels/UriCollection';
import { ActionBuilder } from '../services/action/action-builder';
import { DataUtils } from '../services/data-utils/data-utils';
import { GanttService } from '../services/gantt/gantt.service';
import { ThingService } from '../services/thing/thing.service';
import { Utilities } from '../services/utilities/utilities';

@Component({
  selector: 'app-gantt-demo',
  templateUrl: './gantt-demo.component.html',
  styleUrls: ['./gantt-demo.component.scss']
})
export class GanttDemoComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() action: Action;
  @Input() row: object[];
  @Input() idName: string; // Used to track name being used for primary key, when deleting an item
  selectedItem: any;
  updateIndicator = false;
  @Input() customId: any;
  @Input() screenDetailComponents: ScreenDetail;

  @Output() onAction = new EventEmitter<ActionEvent>();
  @Output() onAdd = new EventEmitter<AddUpdateItemModel>();
  @Output() onViewItem = new EventEmitter<ViewDetailEvent>();
  @Output() onDisplaySwitchContentDialog = new EventEmitter<any>();
  @Output() onUpdateCompleted = new EventEmitter<UpdateCompletedEvent>();
  @Output() onDeleteCompleted = new EventEmitter<DeleteEvent>();
  @Output() updateScreenData = new EventEmitter<UpdateScreenDataEvent>();

  // Default options
  public options: IGanttOptions = {
    scale: {
      start: new Date(2022, 1, 14),
      end: new Date(2022, 2, 15)
    },
    zooming: Zooming[Zooming.days]
  };
  eventsRetrieved: boolean = false;
  categoriesRetrieved: boolean = false;

  // public project: Project = {
  //   'id': '1',
  //   'name': 'Qmolo Gantt',
  //   'startDate': new Date(),
  //   'events': [
  //   ]
  // };

  events: any[];

  dataDetail: DataDetail;
  dataItems: any[];
  itemCategories: any[];
  item: CardItem;
  loadingIndicator = true;
  metadata: FTThingDetail[];
  screenParameters: any = {};
  filterUri: ActionURI;
  customFilter = '';
  searchValue: string;
  pageSize: number = 25;
  uriCollections?: UriCollection[] = [];
  toolbarItems: any[] & MenuItem[];
  
  currentMode: string;
  publicDataSource: string;
  managerDataSource: string;
  privateDataSource: string;
  categoryDataSource: string;
  showDataSourceSelect: boolean;

  constructor(public route: ActivatedRoute, public dialog: MatDialog, private cdr: ChangeDetectorRef, private ganttService: GanttService, private thingService: ThingService, public toastr: ToastrService) {
    this.ganttService.START_DATE = this.options.scale.start;
  }

  ngOnInit(): void {
    
    this.screenParameters = this.action.actionArgument.screenParameters;

    if (!this.screenDetailComponents) {
      this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
        .subscribe(result => {
          this.screenDetailComponents = result;
          this.onReceiveScreenDetails();
        }, error => this.onGetDetailFailed(error));
    }
    else {
      this.onReceiveScreenDetails();
    }

    this.screenParameters = this.action.actionArgument.screenParameters;

    this.loadUriData(false);

  }

  onReceiveScreenDetails() 
  {
    if (this.screenDetailComponents.componentScreens) {
      this.screenDetailComponents.componentScreens[0].screenTitle = this.screenDetailComponents.screenTitle;
      this.screenDetailComponents.componentScreens[0].toolbarItems = this.screenDetailComponents.toolbarItems;
      this.screenDetailComponents = this.screenDetailComponents.componentScreens[0];
    }
    this.item = this.screenDetailComponents.cardItems[0];
  }

  onGetDataFailed(error: any): void {
    throw new Error('Method not implemented.');
  }

  // groupData(array: any[], f: any): any[] {
  //   var groups = {};
  //   array.forEach((o: any) => {
  //     var group = JSON.stringify(f(o));

  //     groups[group] = groups[group] || [];
  //     groups[group].push(o);
  //   });
  //   return Object.keys(groups).map((group: any) => {
  //     return groups[group];
  //   });
  // }

  
  private onGetDetailFailed(error: any) {
    setTimeout(() =>
      this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
        null, { closeButton: true, tapToDismiss: true }));
  }

  loadUriData(includeScreenDetail: boolean) {

    console.log(this.action);

    DataUtils.setUriCollections(this.thingService, this.action.actionArgument.uris, null, this.action.screenParameters)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetUriCollectionsSuccessful(results), error => this.onGetDataFailed(error));

  }

  onGetUriCollectionsSuccessful(results: UriCollection[]): void {
    this.uriCollections = results;
    
    this.publicDataSource = this.screenParameters["public"];
    this.managerDataSource = this.screenParameters["manager"];
    this.privateDataSource = this.screenParameters["private"];
    this.categoryDataSource = this.screenParameters["category"];

    this.setCategories()
    
    var dataSources = 0;
    if (this.publicDataSource){
      dataSources++;
    }
    if (this.managerDataSource){
      dataSources++;
    }
    if (this.privateDataSource){
      dataSources++;
    }
    this.showDataSourceSelect = dataSources > 1;

    this.currentMode = this.screenParameters["defaultDataSource"];
    if(!this.currentMode){
      if (this.publicDataSource){
        this.changeDataSource("public");
      }
      else if (this.managerDataSource){
        this.changeDataSource("manager");
      }
      else if (this.privateDataSource){
        this.changeDataSource("private");
      }
      else{
        this.changeDataSource();
      }
    }
    this.changeDataSource();
  }

  setCategories() {
    var categoriesCollection = this.uriCollections.find(coll => coll.name.toLowerCase() == this.categoryDataSource.toLowerCase());
    if(!categoriesCollection){
      console.log(`${this.categoryDataSource} not matched to a uri collection`);
      return;
    }
    this.itemCategories = categoriesCollection.dataValues;
    console.log(this.itemCategories);
    this.categoriesRetrieved = true;
  }

  changeDataSource(newMode?: string){
    if(newMode){
      this.currentMode = newMode;
    }

    this.dataItems = [];
    switch (this.currentMode) {
      case "public":
        var publicCollection = this.uriCollections.find(coll => coll.name == this.publicDataSource);
        this.setUriCollectionAsDataSource(publicCollection, true);
        break;
      case "manager":
        var managerCollection = this.uriCollections.find(coll => coll.name == this.managerDataSource);
        this.setUriCollectionAsDataSource(managerCollection, true);
        break;
      case "private":
        var privateCollection = this.uriCollections.find(coll => coll.name == this.privateDataSource);
        this.setUriCollectionAsDataSource(privateCollection, true);
        break;
      default:
        var defaultCollection = this.uriCollections.find(coll => coll.name == this.publicDataSource || coll.name == this.managerDataSource || coll.name == this.privateDataSource);
        this.setUriCollectionAsDataSource(defaultCollection, true);
        break;
    }
  }

  setUriCollectionAsDataSource(uriCollection: UriCollection, refreshData: boolean) {

    if(refreshData){
      this.thingService.getDataDetailWithHeader(uriCollection.uris.dataUri.toString(), null, null, null,
      null, this.customFilter, this.searchValue, null, null, [], null)
      .subscribe(result => {
        uriCollection.dataValues = result.dataItems;
        this.refreshData(uriCollection);
      }, error => this.onGetDetailFailed(error));
    }
    else{
      this.refreshData(uriCollection);
    }
  }

  refreshData(uriCollection: UriCollection){
    // This should involve converting to a known model using the UI Definitions
    if (!!uriCollection.dataValues) {
      for (let i = 0; i < uriCollection.dataValues.length; i++) {
        if (!!uriCollection.dataValues[i].startDate) {
          uriCollection.dataValues[i].startDate = new Date(uriCollection.dataValues[i].startDate);
        }
        if (!!uriCollection.dataValues[i].endDate) {
          uriCollection.dataValues[i].endDate = new Date(uriCollection.dataValues[i].endDate!);
        }
        if (Math.floor((uriCollection.dataValues[i].endDate - uriCollection.dataValues[i].startDate) / (1000 * 60 * 60 * 24)) >= 1) {
          uriCollection.dataValues[i].allDay = true;
        }
      }
    }

    this.dataItems = uriCollection.dataValues;
    this.events = this.dataItems;


    // This should involve converting to a known model using the UI Definitions
    this.events.forEach(x => x.start = new Date(x.startDate));
    this.events.forEach(x => x.end = new Date(x.endDate));

    this.eventsRetrieved = true;
    
    this.cdr.detectChanges();
  }

  gridRowClicked(event) {
    console.log(event);
  }

  onSelect(item: any) {
    this.selectedItem = item;

    if (this.item.onClickAction) {
      this.onItemClick({ action: this.item.onClickAction, row: this.selectedItem });
    }
  }

  public onItemClick(event: ItemClickEvent) {

    console.log(event);

    if (!event.action) {
      console.log('NO ACTION SUPPLIED');
      return;
    }

    event.action.forEach(actionObj => {
      let action: Action = Utilities.parseAction(actionObj, event.row, this.screenParameters);
      if (!action) { return; }

      const run = Utilities.evaluateCondition(action.condition, null, this.screenParameters, null, event.row);
      if (!run) {
        return;
      }

      Utilities.log(JSON.stringify(action));

      if (action.action === ActionIdentifier.DisplayDialog && action.actionArgument.name === 'MovementHistorySummaryChart') {

        // parse action is not taking into account uri collections
        action = event.action[0];
        const screenParameters = Utilities.setScreenParameters(action.actionArgument.screenParameters,
          this.screenDetailComponents.requiredScreenParameters, event.row, this.uriCollections);
        const dialogRef = this.dialog.open(GraphDialogComponent, {
          minWidth: '800px',
          data: {
            screenParameters: screenParameters,
            action: action
          }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
      } else if (action.action === ActionIdentifier.LaunchScreen) {

        const launchScreenAction = action.actionArgument as LaunchScreenAction;
        if (launchScreenAction.screenType === ScreenType.EditDetails) {
          this.onAdd.emit({ id: launchScreenAction.name, action: launchScreenAction, newItem: true, screenParameters: this.screenParameters, row: null });
        } else {
          this.onAction.emit({ action: action, row: event.row, newItem: event.newItem });
        }
      } else if (action.action === ActionIdentifier.LaunchFlyout) {
        this.onViewItem.emit({ row: event.row, action: action, length: 1 });
      } else if (action.action === ActionIdentifier.DisplayDialog) {

        const actionArgument = action.actionArgument as LaunchScreenAction;
        if (actionArgument.screenType === ScreenType.ViewDetails || actionArgument.screenType === ScreenType.CompositeScreen) {

          const newSParams = Utilities.setScreenParameters(actionArgument.screenParameters,
            this.screenDetailComponents.requiredScreenParameters, event.row, this.uriCollections);

          const dialogRef = this.dialog.open(ViewDetailsDialogComponent, {
            width: '600px',
            data: { action: action, row: event.row, screenParameters: newSParams }
          });

          dialogRef.afterClosed().subscribe(result => {
            Utilities.log('View Details Dialog closed');
          });

        } else if (actionArgument.screenType === ScreenType.SwitchContentView) {

          this.onDisplaySwitchContentDialog.emit({ action: action, row: event.row });

        } else {

          const ab = new ActionBuilder(event.action, event.row, null, this.uriCollections, this.screenParameters, event.completionEvent,
            this.onViewItem, this.thingService, this.toastr, this.dialog);
          ab.PerformAction();
        }

      } else if (action.action === ActionIdentifier.UpdateData) {

        if (action.actionArgument) {

          const actionArg = action.actionArgument as UpdateDataAction;
          if (actionArg.editUri) {
            let updateData;
            if (event.updatedItems) {
              updateData = event.updatedItems;
            } else {
              const newItem: any = Utilities.createItemFromObjectDefaults(actionArg.newObjectDefaults, event.row, event.newItem,
                this.uriCollections, this.screenParameters);

              // If sectionId contains '.' we couldn't parse it so remove the property
              if (newItem['sectionId'] && newItem['sectionId'].toString().indexOf('.') > -1) {
                delete newItem['sectionId'];
              }

              if (this.customId) {
                newItem['sectionId'] = this.customId.id;
              }

              if (actionArg.editUri.includes('/actions/')) {
                updateData = newItem;
              } else {
                updateData = [newItem];
              }
            }
            this.updateIndicator = true;
            this.thingService.postDataDetails(actionArg.editUri, updateData)
              .subscribe(result => {
                this.onPostDataDetailSectionSuccessful(action, result, false, updateData, actionArg.currentItemPath);
              }, error => this.onPostDataDetailFailed(error));
          } else {

            const o = Utilities.createItemFromObjectDefaults(actionArg.newObjectDefaults, event.row, null, this.uriCollections, this.screenParameters);
            this.updateScreenData.emit({ targetScreen: actionArg.resultTargetScreen, targetDataObject: actionArg.resultTargetDataObject, newData: o });
          }
        }
      } else if (action.action === ActionIdentifier.DeleteItem) {
        if (this.dataDetail.dataItems.findIndex(d => d.selected) > -1) {
          this.deleteSelected(action);
        } else {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '450px',
            data: { message: 'Are you sure you want to delete this item?' }
          });

          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              const arg: string = action.actionArgument.editUriArgs[0];
              this.idName = arg.substring(arg.lastIndexOf('.') + 1);

              this.loadingIndicator = true;
              this.thingService.deleteItem(action.actionArgument.editUri, [event.row[this.idName]]).subscribe(x => { this.onDeleteItemSuccessful(x) }, error => this.onDeleteItemFailed(error));
            }

          });
        }
      } else if (action.action === ActionIdentifier.ReloadData) {
        // this.reloadData(action);
      } else if (action.action === ActionIdentifier.UpdateScreenParameters) {
        const updateParamsAction = action.actionArgument as UpdateScreenParametersAction;
        if (updateParamsAction.targetScreen == null) {
          if (updateParamsAction.screenParameters) {
            Object.keys(updateParamsAction.screenParameters).forEach(key => {
              this.screenParameters[key] = updateParamsAction.screenParameters[key];
            });
          }

          if (updateParamsAction.screenProperties) {
            Object.keys(updateParamsAction.screenProperties).forEach(key => {
              this.screenDetailComponents.screenProperties[key] = updateParamsAction.screenProperties[key];
            });
          }
        }

      } else {
        alert('Action ' + action.action + ' not defined in onItemClick');
      }
    });
  }

  private onPostDataDetailSectionSuccessful(action: Action, resultArray: PostResponse[], reloadGrid: boolean, originalRow: any, currentItemPath: string) {

    const result = resultArray[0];

    switch (result.errorCode) {
      case 0:
        {
          if (result.errorMessage === 'deleted' && action.actionArgument.resultDataMapping && action.actionArgument.resultDataMapping['primaryKey']) {
            const primaryKey = action.actionArgument.resultDataMapping['primaryKey'];
            // tslint:disable-next-line: triple-equals
            const deleteIndex = this.dataItems.findIndex(d => d[primaryKey] == originalRow[primaryKey]);
            if (deleteIndex > -1) {
              this.dataItems.splice(deleteIndex, 1);
            }
          } else {

            this.updateIndicator = false;
            let sectionId = -1;

            if (result && result.returnedObject.sectionID) {

              sectionId = result.returnedObject.sectionID;
            }

            this.onUpdateCompleted.emit({
              sectionId: sectionId,
              resultTargetDataObject: action.actionArgument.resultTargetDataObject,
              row: result.returnedObject,
              currentItemPath: currentItemPath,
            });

            if (action.actionArgument.resultDataMapping) {
              for (const key in action.actionArgument.resultDataMapping) {
                if (key) {
                  const entry: string = action.actionArgument.resultDataMapping[key];
                  originalRow[key] = result[0].returnedObject[Utilities.getLastEntry(entry)];
                }
              }
            }
            
            if (action.actionArgument.targetDataObject) {
              let newItem;
              if (result[0]) { newItem = result[0].returnedObject; }
              

              const dataItems = Utilities.getUriCollection(action.actionArgument.targetDataObject, this.uriCollections);

              const primaryKeyField = action.actionArgument.targetDataObject + 'ID';
              

              if (newItem[primaryKeyField]) {
                const index = dataItems.dataValues.findIndex(v => v[primaryKeyField] == newItem[primaryKeyField]);
                if (index) {
                  dataItems.dataValues[index] = newItem;
                } else {
                  dataItems.dataValues.push(newItem);
                }
              }
            }
          }

          if (reloadGrid) {
            // this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
          }
        }
        break;
      case 9:
        {
          this.toastr.error(result.errorMessage);


          this.onUpdateCompleted.emit({
            resultTargetDataObject: action.actionArgument.resultTargetDataObject
            // reloadOrderBasket: action.actionArgument.resultTargetDataObject === 'orderBasket'
          });
        }
        break;
    }
  }

  private onPostDataDetailFailed(error: any) {
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
  }

  public deleteSelected(action: Action) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { message: 'Are you sure you want to delete the selected items?' }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        const arg: string = action.actionArgument.editUriArgs[0];
        this.idName = arg.substring(arg.lastIndexOf('.') + 1);

        const selectedIds = [];

        for (const r of this.dataDetail.dataItems) {
          if (r.selected) {
            selectedIds.push(r[this.idName]);
          }
        }

        this.loadingIndicator = true;
        this.thingService.deleteItem(action.actionArgument.editUri, selectedIds)
          .subscribe(results => this.onDeleteItemSuccessful(results), error => this.onDeleteItemFailed(error));
      }

    });

  }

  private onDeleteItemSuccessful(result: PostResponse[]) {

    for (const r of result) {
      if (r.errorCode === 0) {
        // this.refresh();
        this.onDeleteCompleted.emit({ name: this.idName, value: r.returnedObject });
      } else {

        this.toastr.error(r.errorMessage, null, { closeButton: true, tapToDismiss: true });

      }
    }
  }

  private onDeleteItemFailed(error: any) {

    this.loadingIndicator = false;
    this.toastr.error(`Unable to delete data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
  }
}
