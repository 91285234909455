import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { first, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoginResponse } from 'src/app/models/data/LoginResponse';
import { fal } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  selectedSystem: string;
  auth: LoginResponse;
  loggedIn = false;
  passwordMask = true;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main';
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/main';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.toastr.clear();
    this.authenticationService.login(this.f.username.value, this.f.password.value, this.selectedSystem)
      .pipe(first())
      .subscribe(
        auth => {
          this.auth = auth;
          this.loading = false;
          if (auth.messageCode === 0) {

            if (auth.logonComplete) {
              // this gives an error
              // console.log(`Show toast Welcome ${this.f.username.value}`);
              // setTimeout(() => this.toastr.success(`Welcome ${this.f.username.value}.`));
              this.router.navigate([this.returnUrl]);
            } else if (auth.availableSystems.length > 0) {
              this.loggedIn = true;
            }
            else {
              this.loggedIn = false;
            }
          } else {
            setTimeout(() => this.toastr.error(auth.message));
          }
        },
        error => {
          this.loading = false;
          setTimeout(() => this.toastr.error(error));
        });
  }

  getPasswordInputType() {
    return this.passwordMask ? 'password' : 'text';
  }

  // loginToSystem(systemId: string) {

  //   this.authenticationService.login(this.f.username.value, this.f.password.value, systemId)
  //     .pipe(take(1))
  //     .subscribe(
  //       auth => {
  //         this.auth = auth;
  //         this.loading = false;
  //         if (auth.messageCode === 0) {

  //           if (auth.logonComplete) {
  //             setTimeout(() => this.toastr.success(`Welcome ${this.f.username.value}.`));
  //             this.router.navigate([this.returnUrl]);
  //           } else if (auth.availableSystems && auth.availableSystems.length > 0) {

  //           }
  //         } else {
  //           setTimeout(() => this.toastr.error(auth.message));
  //         }
  //       },
  //       error => {
  //         this.loading = false;
  //         setTimeout(() => this.toastr.error(error));
  //       });
  // }
}
