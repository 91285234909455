import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FileSystemFileEntry, UploadEvent, UploadFile } from 'ngx-file-drop';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataDetail } from 'src/app/models/DataDetail';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';
import { FileUploadPost } from 'src/app/models/dataService/FileUploadPost';
import { FtFile as FtFile } from 'src/app/models/dataService/FtFile';
import { SaveEvent } from 'src/app/models/events/SaveEvent';
import { PostResponse } from 'src/app/models/PostResponse';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss', './../input-style.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() parentObjectIIdPath: string;
  @Input() parentObjectType: string;
  @Input() primaryFileIdPath: string;
  // @Input() row: any;
  @Input() uriCollections: UriCollection[];

  @Input() label: string;
  @Input() disabled: boolean;
  @Input() errorText: string;

  @Input() extensions: string;

  @Input() readOnly: boolean;

  @Output() saveParent = new EventEmitter<SaveEvent>();

  parentObjectIID: number;
  fileColumnsToDisplay: string[] = [
    'primaryFileCheck',
    'thumb',
    'fileName',
    'download',
    'delete'
  ];

  files: UploadFile[] = [];
  dataSource: MatTableDataSource<FtFile>;
  @ViewChild(MatSort) sort: MatSort;
  fileList: any[] = [];
  addingFile = false;
  fileEntry: FileSystemFileEntry;

  constructor(private thingService: ThingService, private changeDetectorRefs: ChangeDetectorRef, public toastr: ToastrService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.getFileList();
  }

  getFileList() {
    if (!this.uriCollections) {
      return;
    }

    if (this.parentObjectIIdPath) {
      this.parentObjectIID = Utilities.getDataFromUriCollection(this.parentObjectIIdPath, this.uriCollections);
    }
    if (!this.parentObjectIID) {
      return;
    }

    this.thingService.getFileList(this.parentObjectType, this.parentObjectIID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetFilesSuccessful(results), error => console.log(error));
  }

  public get primaryFileId(): string {
    return Utilities.getDataFromUriCollection(this.primaryFileIdPath, this.uriCollections);
  }
  setPrimaryFileId(v: string) {
    Utilities.setDataToUriCollection(this.primaryFileIdPath, this.uriCollections, v);
    // this.row[Utilities.getLastEntry(this.primaryFileIdPath)] = v;
  }

  startDownload(file: FtFile): void {
    this.thingService.downloadFile(file.ftFileItemID).subscribe(response => this.downloadFile(response, file));
  }

  downloadFile(x: HttpResponse<ArrayBuffer>, file: FtFile){
    var disposition = x.headers.get('content-disposition');
    var filename = "";
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) { 
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    let blob = new Blob([x.body], { type: file.contentType});
    let url = window.URL.createObjectURL(blob);

    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();
  }

  onGetFilesSuccessful(results: DataDetail): void {

    this.fileList = results.dataItems;
    this.dataSource.data = this.fileList;
    this.dataSource.sort = this.sort;

    if (results.dataItems.length === 0) {
      this.setPrimaryFileId(null);
    }
  }

  public dropped(event: UploadEvent) {

    let primaryFileId: number;
    if (this.primaryFileIdPath) {
      primaryFileId = Utilities.getDataFromUriCollection(this.primaryFileIdPath, this.uriCollections);
    }

    if (!this.parentObjectIID && this.parentObjectIIdPath) {
      this.parentObjectIID = Utilities.getDataFromUriCollection(this.parentObjectIIdPath, this.uriCollections);
    }

    this.files = event.files;
    for (const droppedFile of event.files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {

        this.fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        // Check the file is of a valid filetype
        // Get the file extension
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(this.fileEntry.name)[1];

        // Check {{extensions}} includes the extension before uploading the file
        if(this.extensions)
        {
          //extensions have been set - if ext doesn't match them, don't upload
          if(!this.extensions.includes(ext))
          {
            this.toastr.error(`Invalid file.\r\n'${ext} is not supported.'`, null, { closeButton: true, tapToDismiss: true });
            return;
          }
        }
        if (!this.parentObjectIID) {
          const objectKey = new DataPath(this.parentObjectIIdPath).rootObjectName;
          this.saveParent.emit({ objectKey: objectKey, closeOnFinish: false });
          return;
        } else {
          this.uploadFile(this.fileEntry);
        }
        
      }
      //  else {
      //   // It was a directory (empty directories are added, otherwise only files)
      //   const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      //   console.log(droppedFile.relativePath, fileEntry);
      // }
    }
  }

  retryFileUpload(savedRow: any) {
    if(savedRow[this.parentObjectIIdPath]){
      this.parentObjectIID = savedRow[this.parentObjectIIdPath];
    }
    this.uploadFile(this.fileEntry);
  }

  uploadFile(fileEntry: FileSystemFileEntry) {

    if (!fileEntry) {
      return;
    }

    if (!this.parentObjectIID && this.parentObjectIIdPath) {
      this.parentObjectIID = Utilities.getDataFromUriCollection(this.parentObjectIIdPath, this.uriCollections);
    }
    if (this.parentObjectIID === null) {
      return;
    }

    this.addingFile = true;
    fileEntry.file((file: File) => {

      // Here you can access the real file
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      let base64String = '';
      reader.onload = (function (readerEvent) {
        const binaryString = readerEvent.target.result;
        base64String = btoa(binaryString);

        const fileUpload: FileUploadPost = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64String,
          // fileCreated: file.lastModified,
          // fileModified: file.lastModified,
          parentObjectIID: this.parentObjectIID,
          parentObjectType: this.parentObjectType,
        };
        this.thingService.uploadFile(fileUpload)
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            this.addingFile = false;
            if (result.errorCode === 0) {
              const fileOb = result.returnedObject as FtFile;

              this.fileList.push(fileOb);
              this.dataSource.data = this.fileList;
              this.changeDetectorRefs.detectChanges();
              if (this.primaryFileId == null || this.primaryFileId.length === 0) {
                this.setPrimaryFileId(fileOb.ftFileItemID);
              }

            } else {
              Utilities.log2('FileUploadComponent uploadFile', result.errorMessage, 'error');
            }
            this.fileEntry = null;
          }, error => {
            this.addingFile = false;
            this.fileEntry = null;
            console.log(error);
          });
      }).bind(this);
    });
  }

  thumbnailUrl(fileId: string) {
    return this.thingService.getFileUrl(fileId, 'tiny');
  }

  getImage(fileId: string) {
    return this.thingService.getFileUrl(fileId, 'medium');
  }

  public save() {
    this.fileList.forEach(file => {
      this.saveNotes(file);
    });
  }

  saveNotes(file: FtFile) {
    this.thingService.updateFile(file)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => console.log(results), error => console.log(error));
  }
  deleteFile(fileId: string) {
    const setNewPrimaryId = fileId === this.primaryFileId;
    this.thingService.deleteSingleItem(`/api/data/ftfile/${fileId}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onDeleteFileSuccessful(results, fileId, setNewPrimaryId), error => console.log(error));
  }
  onDeleteFileSuccessful(results: PostResponse, fileId: string, setNewPrimaryId: boolean): void {
    if (results.errorCode === 0) {
      const index = this.fileList.findIndex(f => f.ftFileItemID === fileId);
      this.fileList.splice(index, 1);
      this.dataSource.data = this.fileList;
      this.changeDetectorRefs.detectChanges();

      if (setNewPrimaryId && this.dataSource.data && this.dataSource.data.length > 0) {
        this.setPrimaryFileId(this.dataSource.data[0].ftFileItemID);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
