import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { ThingService } from '../../../services/thing/thing.service';
import { Utilities } from '../../../services/utilities/utilities';

import { MenuItems } from '../../../models/data/MenuItems';
import { MenuItem } from '../../../models/data/MenuItem';
import { MenuNode } from '../../../models/menu-tree/MenuNode';
import { Action } from '../../../models/actions/Action';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { X } from '@angular/cdk/keycodes';
import { IconService } from 'src/app/services/icon/icon.service';
import { IconMapping } from 'src/app/models/IconMapping';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  id: string;
  sub: any;

  showExpand = false;
  idName = 'menuItemId';
  titleName = 'menuItemTitle';

  loadingIndicator = true;
  showMenu = true;
  menuItems: MenuItem[];

  interval: any;

  treeControl: NestedTreeControl<MenuNode>;
  treeDataSource: MatTreeNestedDataSource<MenuNode>;
  secondaryDataSource: MatTreeNestedDataSource<MenuNode>;

  menuNodes: MenuNode[] = [];
  secondaryMenuNodes: MenuNode[] = [];

  activeMenuId: number;

  iconMappings: Array<IconMapping>;

  @Output() select = new EventEmitter<Action>();
  @Output() menuOpen = new EventEmitter<boolean>();
  _menuItems: MenuItem[];

  constructor(private toastr: ToastrService, private thingService: ThingService, private router: Router,
    private activatedRoute: ActivatedRoute, private iconHelper: IconHelper, private iconService: IconService) { }

  ngOnInit() {

    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });

    this.treeControl = new NestedTreeControl<MenuNode>(this._getChildren);
    this.treeDataSource = new MatTreeNestedDataSource();
    this.secondaryDataSource = new MatTreeNestedDataSource();

    this.thingService.rootMenu$
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
          this.getTopLevelMenuSuccessful(result);
      },
        error => this.getTopLevelMenuFailed(error));
  }

  private _getChildren = (node: MenuNode) => node.children;

  hasNestedChild = (_: number, nodeData: MenuNode) => nodeData.hasChildren;

  private getTopLevelMenuSuccessful(menu: MenuItems) {

    // console.log(menu);
    this.menuNodes = [];
    this.secondaryMenuNodes = [];
    if (menu && menu.menuItems) {

      var sideNavItems = menu.menuItems.filter(x => x.appScreenCanvas != 1 && x.appScreenCanvas != 3 && x.appScreenCanvas != 4);

      var homeNode = new MenuNode(9998, "Home")
      homeNode.iconId = 21;
      homeNode.icon = "fa-home";
      homeNode.redirectUrl = "home";
      homeNode.index = 0;
      this.menuNodes.push(homeNode);
      this.menuItems = sideNavItems;
      this.activeMenuId = homeNode.id;

      var i=1;
      for (const menuItem of sideNavItems) {

        if (menuItem.parentMenuItemId == null) {
          const menuNode: MenuNode = new MenuNode(menuItem[this.idName], menuItem[this.titleName]);
          menuNode.iconId = menuItem.menuItemIconId;
          // menuNode.icon = this.getMenuIcon(menuItem[this.idName]);
          if (!!menuNode.iconId) {
            var iconHex = this.iconHelper.getFontAwesomeHex(menuNode.iconId);
            var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
            menuNode.icon = this.iconHelper.setIcon(iconMapping, iconHex);
          }

          if (this.hasChildren(menuItem)) {
            this.loadChildren(menuNode, menuItem);
          }

          menuNode.index = i;
          this.menuNodes.push(menuNode);
        }
        i++;
      }


      let item = menu.menuItems.find(m => m.action && m.action[0].actionArgument !== null);

      if (this.id) {
        item = menu.menuItems.find(m => m.action && m.action[0].actionArgument.safeUrlPath === this.id);
      }

      if (item) {
        this.setActiveMenuItem(item);
      }

      this.treeControl = new NestedTreeControl<MenuNode>(this._getChildren);
    }

    this.treeDataSource.data = this.menuNodes;

    if (!!this.menuItems && !!this.menuItems.filter(x => x.appScreenCanvas == 4))
    {
      var preferencesNode = new MenuNode(9999, "Preferences")
      preferencesNode.iconId = 21;
      preferencesNode.icon = "fa-cog";
      preferencesNode.redirectUrl = "preferences";
      this.secondaryMenuNodes.push(preferencesNode);
    }
    this.secondaryDataSource.data = this.secondaryMenuNodes;
    
    this.loadingIndicator = false;
  }

  ngAfterViewInit(): void {
  }

  private getTopLevelMenuFailed(error: any) {
    setTimeout(() =>
      this.toastr.error(`Unable to retrieve menu from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
        null, { closeButton: true, tapToDismiss: true })
    );
  }

  hasChildren(item: MenuItem): boolean {
    return (this.menuItems.findIndex(m => m.parentMenuItemId === item.menuItemId)) > -1;
  }

  loadChildren(menuNode: MenuNode, item: MenuItem) {

    menuNode.children = [];
    menuNode.hasChildren = true;

    const items = this.menuItems.filter(m => m.parentMenuItemId === item.menuItemId);

    for (const childitem of items) {

      const childNode: MenuNode = new MenuNode(childitem[this.idName], childitem[this.titleName]);
      childNode.iconId = childitem.menuItemIconId;

      if (this.hasChildren(childitem)) {
        this.loadChildren(childNode, childitem);
      }
      menuNode.children.push(childNode);
      // if (this.checkedIds && this.checkedIds.findIndex(i => i === childNode.id) > -1) {
      //   this.checklistSelection.select(childNode);
      // }
    }
  }

  setActiveMenuItem(item: MenuItem) {

    this.select.emit(null);
    // If clicking active menu option, call parent component to collapse breadcrumb
    if (item.active) {
      this.select.emit(null);
    } else {

      this.activeMenuId = item.menuItemId;

      if (item.parentMenuItemId) {
        this.menuItems.find(m => m.menuItemId === item.parentMenuItemId).open = true;
      }

      if (item.action[0].actionArgument.safeUrlPath) {
        this.router.navigate(['/main/' + item.action[0].actionArgument.safeUrlPath]);
      } else {
        this.router.navigate(['/main/' + item.menuItemId]);
      }
    }
  }

  onShowMenu() {
    this.showMenu = !this.showMenu;
    this.menuOpen.emit(this.showMenu);
  }

  onClick(menuNode: MenuNode) {

    if (!!menuNode.redirectUrl)
    {
      this.activeMenuId = menuNode.id;
      this.router.navigate(['/main/' + menuNode.redirectUrl]);
      return;
    }

    const menuItem = this.menuItems.find(m => m.menuItemId === menuNode.id);
    this.setActiveMenuItem(menuItem);
  }

  onClickSecondary(menuNode: MenuNode) {
    this.activeMenuId = menuNode.id;
    this.router.navigate(['/main/' + menuNode.redirectUrl]);
  }

  getMenuIcon(menuIconId: number): string {
    switch (menuIconId) {
      case 46:
        {
          // return 'assets/side-menu-icons/orders.svg';
          return 'assets/side-menu-icons/dashboard.svg';
        }
      case 56: {
        return 'assets/side-menu-icons/customers.svg';
      }
      case 70: {
        // return 'assets/side-menu-icons/products.svg';
        return 'assets/side-menu-icons/dashboard.svg';
      }
      case 95: {
        return 'assets/side-menu-icons/inventory.svg';
      }
      default: {
        return 'assets/side-menu-icons/dashboard.svg';
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
