import moment = require('moment');

export class FtFile {
    ftFileItemID: string;
    ftFileOwnerSID: string;
    fileName: string;
    isImage: boolean;
    contentType: string;
    parentObjectIID: number;
    parentObjectType: string;
    fileSize: number;
    notes: string;
    objectCreatedDT: moment.Moment;
    objectCreatedBy: string;
    imageHeight: number;
    imageWidth: number;
    imageAspectRatio: number;
}
