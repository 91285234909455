import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { Action } from 'src/app/models/actions/Action';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { ActionIdentifier, ScreenType } from 'src/app/models/Enums';
import { IconService } from 'src/app/services/icon/icon.service';
import { Utilities } from 'src/app/services/utilities/utilities';
import { TreeFilterDialogComponent } from '../dialogs/tree-filter-dialog/tree-filter-dialog.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  @Input()
  actions: MenuItem[];

  @Output()
  flyoutAction = new EventEmitter<Action>();

  constructor(private router: Router, private iconHelper: IconHelper, private iconService: IconService, public dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.actions);
  }

  onClick(item: MenuItem) {
    const action = item.action[0] as Action;
    switch (action.action) {
      case ActionIdentifier.LaunchFlyout:
        this.flyoutAction.emit(action);
        return;
    
      default:
        break;
    }


    const actionArgument = item.action[0].actionArgument as LaunchScreenAction;
    if (actionArgument.screenType === ScreenType.TreeView) {

      //look at main component??? its doing 56 + 50 + 2????
      const height = Utilities.innerHeight(108) + 50;

      const trigger = new ElementRef(event.target) as ElementRef;
      const rect = trigger.nativeElement.getBoundingClientRect();
      const dialogRef = this.dialog.open(TreeFilterDialogComponent, {
        minWidth: '700px',
        width: 'auto',
        height: `${height}px`,
        data: {
          action: item.action[0],
          screenParameters: item.action[0].actionArgument.screenParameters,
          heightOffset: rect.bottom,
          height: height,
          checkedFilters: item.checkedFilters,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        Utilities.log('View Details Dialog closed');
      });
    }


    if (item.action[0].actionArgument.safeUrlPath) {
      this.router.navigate([`/main/${item.action[0].actionArgument.safeUrlPath}`]);
    }
    else {
      this.router.navigate([`/main/${item.menuItemId}`]);
    }
  }

  getIcon(id: number) {
    var iconHex = this.iconHelper.getFontAwesomeHex(id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }
}
