export class MenuNode {

  constructor(id: number, text: string, isEnabled: boolean = true, color: string = null, iconId: number = null) {
    this.id = id;
    this.iconId = iconId;
    this.text = text;
    if (color) {
      if (color.startsWith('#')) {
        this.color = color;
      } else {
        this.color = '#' + color;
      }
    }
    this.isEnabled = isEnabled;
  }

  id: number;
  text: string;
  color?: string;
  children: MenuNode[];
  hasChildren = false;
  iconId: number;
  isEnabled: boolean;
  icon: string;
  redirectUrl?: string;
  index?: number;
}
