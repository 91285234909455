import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from '../modules/calendar.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { adapterFactory } from '../date-adapters/date-fns';
import { AddEventDialogComponent } from '../../dialogs/add-event-dialog/add-event-dialog.component';
import { BaseCalendarComponent } from './base-calendar.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MultiSelectorDropdownComponent } from './shared/multi-selector-dropdown/multi-selector-dropdown.component';
import { ViewEventComponent } from './view-event/view-event.component';
import { ElementTextPipe } from 'src/app/pipes/card/element-text.pipe';
import { ElementExistsPipe } from 'src/app/pipes/card/element-exists.pipe';
import { MatRadioModule } from '@angular/material';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    ContextMenuModule,
    MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatDatepickerModule, MatSidenavModule,
    MatCardModule, MatGridListModule, MatButtonToggleModule,
    MatInputModule, MatTableModule, MatPaginatorModule, MatProgressSpinnerModule, MatSortModule, MatSelectModule, MatTreeModule, MatDialogModule, MatDividerModule,
    MatTooltipModule, MatChipsModule, MatOptionModule,
    MatListModule, MatExpansionModule, MatMenuModule,
    MatRadioModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [BaseCalendarComponent, AddEventDialogComponent, MultiSelectorDropdownComponent, ViewEventComponent, ElementTextPipe, ElementExistsPipe],
  exports: [BaseCalendarComponent, MultiSelectorDropdownComponent, ElementTextPipe, ElementExistsPipe ],
})
export class MyCalendarModule { }
