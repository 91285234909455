import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from 'src/app/services/utilities/utilities';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { UIDataType } from 'src/app/models/Enums';
import { FlowService } from 'src/app/services/flow/flow.service';

@Pipe({
  name: 'elementText'
})
export class ElementTextPipe implements PipeTransform {

  transform(elementName: string, cardTemplate: any, metadata: FTThingDetail[], dataItem: any): any {

    if (cardTemplate[elementName].text.argumentIds) {
      if (!metadata) {
        // console.log(`No metdata for element ${elementName}`);

      }
      const md = DataUtils.getMetadatumAndDataPath(cardTemplate[elementName].text.argumentIds[0], metadata);
      if (!md) {
        return;
      }
      const metadatum = md[0];
      if (!metadatum) { return; }

      const uiDataType = DataUtils.dataTypeToUIDataType(metadatum.dataType, metadatum.referenceTo);
      if (uiDataType === UIDataType.Lookup) {
        const lookupName = Utilities.getLastEntry(metadatum.referenceTo);
        const item = FlowService.getLookupDetail(lookupName, dataItem[md[1].propertyName]);
        return (item != null ? item.text : '');
      }

      if (uiDataType === UIDataType.TagList) {
        return 'taglist';
      }
    }
    if (cardTemplate[elementName] && cardTemplate[elementName].text) {
      return Utilities.parseArgumentsFromData(cardTemplate[elementName].text.text, cardTemplate[elementName].text.argumentIds, dataItem);
    }
    return '';
  }
}
