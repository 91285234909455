import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from 'src/app/services/utilities/utilities';

@Pipe({
  name: 'elementPropertyName'
})
export class ElementPropertyNamePipe implements PipeTransform {

  transform(elementName: string, cardTemplate: any): any {
    if (cardTemplate[elementName] && cardTemplate[elementName].text) {
      return Utilities.getLastEntry(cardTemplate[elementName].text.argumentIds[0]);
    } else if (cardTemplate[elementName] && cardTemplate[elementName].icon) {
      return Utilities.getLastEntry(cardTemplate[elementName].icon.iconArgument);
    }
    return '';
  }

}
