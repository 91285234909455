import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { MatDialog } from '@angular/material';

import { ToastrService } from 'ngx-toastr';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

import { PriceBandUpdateDialogComponent } from '../../../controls/pricing/price-band-update-dialog/price-band-update-dialog.component';
// tslint:disable-next-line: max-line-length
import { PriceSchemeUpdateDialogComponent } from '../../../controls/pricing/price-scheme-update-dialog/price-scheme-update-dialog.component';

import { Action } from '../../../../models/actions/Action';
import { ActionURI } from '../../../../models/data/ActionURI';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { DataDetail } from '../../../../models/DataDetail';
import { FormTemplateItem } from '../../../../models/screenModels/TemplateItem';
import { ActionIdentifier } from '../../../../models/Enums';
import { PostResponse } from '../../../../models/PostResponse';
import { PriceSchemeDetailUpdateDialogComponent } from '../price-scheme-detail-update-dialog/price-scheme-detail-update-dialog.component';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit, OnDestroy {

  loadingIndicator = true;
  subscription: Subscription;

  @Input() action: Action;
  @Input() row: any;
  @Input() saving: boolean;

  defaultPriceSchemeId: any;

  screenDetailComponents: ScreenDetail;
  screenParameters: Observable<any>;

  uriCollections: UriCollection[] = [];

  priceBandUri: ActionURI;
  priceSchemeUri: ActionURI;
  priceSchemeDetailUri: ActionURI;
  priceSummaryUri: ActionURI;
  productPriceSchemePriceBandUri: ActionURI;

  screenDetailPriceGrid: ScreenDetail;

  // priceBand
  priceBandColumns: FTThingDetail[];
  priceBandDataDetail: DataDetail;

  // priceScheme
  priceSchemeColumns: FTThingDetail[];
  priceSchemeDataDetail: DataDetail;

  // priceSchemeDetail
  priceSchemeDetailColumns: FTThingDetail[];
  priceSchemeDetailDataDetail: DataDetail;

  // price
  priceSummaryColumns: FTThingDetail[];
  priceSummaryDataDetail: DataDetail;

  // productPriceSchemePriceBand
  productPriceSchemePriceBandColumns: FTThingDetail[];
  productPriceSchemePriceBandDataDetail: DataDetail;

  priceBandTemplate: FormTemplateItem;
  priceSummaryTemplate: FormTemplateItem;
  productPriceSchemePriceBandTemplate: FormTemplateItem;

  priceBandDataDetailOriginal: DataDetail;

  priceUpdateCount: number;
  priceUpdateTotal: number;

  @Output() titleUpdated = new EventEmitter<TitleUpdateEvent>();
  @Output() updateCompleted = new EventEmitter<boolean>();

  // ngOnChanges(changes: SimpleChanges): void {
  //   for (let propName in changes) {
  //     switch (propName) {
  //       case 'screenParameters':
  //         this.screenParameters.ngOnChanges();
  //         return;
  //     }
  //   }
  // }

  constructor(public toastr: ToastrService, private thingService: ThingService, public dialog: MatDialog) { }

  ngOnInit() {

    this.loadInitialData();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadInitialData() {

    for (const uri of this.action.actionArgument.uris) {
      const actionUri: ActionURI = JSON.parse(JSON.stringify(uri));

      if (actionUri.dataUri) {
        actionUri.dataUri = Utilities.parseArgumentsFromData(actionUri.dataUri, actionUri.dataUriArgs, null, this.row);
      }

      if (uri.name === 'priceBand') {
        this.priceBandUri = actionUri;
      } else if (uri.name === 'priceScheme') {
        this.priceSchemeUri = actionUri;
      } else if (uri.name === 'priceSchemeDetail') {
        this.priceSchemeDetailUri = actionUri;
      } else if (uri.name === 'priceSummary') {
        this.priceSummaryUri = actionUri;
      } else if (uri.name === 'productPriceSchemePriceBand') {
        this.productPriceSchemePriceBandUri = actionUri;
      }
    }

    const priceSummaryDataUri: string = Utilities.parseArgumentsFromData(this.priceSummaryUri.dataUri,
      this.priceSummaryUri.dataUriArgs, this.row);

    const productPriceSchemePriceBandDataUri: string = Utilities.parseArgumentsFromData(this.productPriceSchemePriceBandUri.dataUri,
      this.productPriceSchemePriceBandUri.dataUriArgs, this.row);

    this.thingService.getScreenDataDetail5(
      this.action.actionArgument.screenUri,
      this.priceBandUri.metaUri, this.priceBandUri.dataUri,
      this.priceSchemeUri.metaUri, this.priceSchemeUri.dataUri,
      this.priceSchemeDetailUri.metaUri, this.priceSchemeDetailUri.dataUri,
      this.priceSummaryUri.metaUri, priceSummaryDataUri,
      this.productPriceSchemePriceBandUri.metaUri, productPriceSchemePriceBandDataUri
    )
      .subscribe(result => {
        const r = result as any;
        this.onGetScreenDataDetail5Successful(r[0], r[1], r[2], r[3], r[4], r[5], r[6], r[7], r[8]);
      }
        , error => this.onGetDetailFailed(error));
  }

  private onGetScreenDataDetail5Successful(screenDetailComponents: ScreenDetail,
    priceBandDataDetail: DataDetail, priceSchemeDataDetail: DataDetail, priceSchemeDetailDataDetail: DataDetail,
    priceSummaryDataDetail: DataDetail, productPriceSchemePriceBandDataDetail: DataDetail,
    priceBandColumns?: FTThingDetail[], priceSchemeColumns?: FTThingDetail[], priceSchemeDetailColumns?: FTThingDetail[],
    priceSummaryColumns?: FTThingDetail[], productPriceSchemePriceBandColumns?: FTThingDetail[]) {

    Utilities.priceBandScreenDetails = this.screenDetailComponents = screenDetailComponents;

    this.screenDetailPriceGrid = this.screenDetailComponents.componentScreens.find(c => c.screenTag === 'priceGrid');

    if (this.screenDetailPriceGrid) {
      this.priceBandTemplate = this.screenDetailPriceGrid.priceTemplates.find(m => m.tag === 'priceBandTemplate');
      this.priceSummaryTemplate = this.screenDetailPriceGrid.priceTemplates.find(m => m.tag === 'priceTemplate');
      this.productPriceSchemePriceBandTemplate = this.screenDetailPriceGrid.priceTemplates
        .find(m => m.tag === 'productPriceSchemeTemplate');
    }

    this.priceBandDataDetail = priceBandDataDetail;
    this.priceSchemeDataDetail = priceSchemeDataDetail;
    this.priceSchemeDetailDataDetail = priceSchemeDetailDataDetail;
    this.priceSummaryDataDetail = priceSummaryDataDetail;

    this.productPriceSchemePriceBandDataDetail = productPriceSchemePriceBandDataDetail;

    if (priceBandColumns) {
      this.priceBandColumns = priceBandColumns;
    }

    if (priceSchemeColumns) {
      this.priceSchemeColumns = priceSchemeColumns;
    }

    if (priceSchemeDetailColumns) {
      this.priceSchemeDetailColumns = priceSchemeDetailColumns;
    }

    if (priceSummaryColumns) {
      this.priceSummaryColumns = priceSummaryColumns;
    }

    if (productPriceSchemePriceBandColumns) {
      this.productPriceSchemePriceBandColumns = productPriceSchemePriceBandColumns;
    }

    this.uriCollections.push({
      name: 'priceBand', dataValues: this.priceBandDataDetail.dataItems, dataMetadata: this.priceBandColumns,
      uris: this.priceBandUri
    });
    this.uriCollections.push({
      name: 'priceScheme', dataValues: this.priceSchemeDataDetail.dataItems, dataMetadata: this.priceSchemeColumns,
      uris: this.priceSchemeUri
    });
    this.uriCollections.push({
      name: 'priceSchemeDetail', dataValues: this.priceSchemeDetailDataDetail.dataItems,
      dataMetadata: this.priceSchemeDetailColumns, uris: this.priceSchemeDetailUri
    });
    this.uriCollections.push({
      name: 'priceSummary', dataValues: this.priceSummaryDataDetail.dataItems,
      dataMetadata: this.priceSummaryColumns, uris: this.priceSummaryUri
    });
    this.uriCollections.push({
      name: 'productPriceSchemePriceBand', dataValues: this.productPriceSchemePriceBandDataDetail.dataItems,
      dataMetadata: this.productPriceSchemePriceBandColumns, uris: this.productPriceSchemePriceBandUri
    });

    this.screenParameters = Utilities.setScreenParameters(this.action.actionArgument.screenParameters,
      this.screenDetailComponents.requiredScreenParameters, this.row, this.uriCollections);

    // TODO this should ideally be set up from the requiredScreenParameters default but is set here
    if (!this.screenParameters['defaultPriceSchemeId']) {
      const defaultPs = this.priceSchemeDataDetail.dataItems.find(p => p.isDefault);
      this.screenParameters['defaultPriceSchemeId'] = defaultPs.priceSchemeID;
    }

    this.defaultPriceSchemeId = this.screenParameters['defaultPriceSchemeId'];
    this.setupTitle();

    this.loadingIndicator = false;
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
  }

  private setupTitle() {

    const title = Utilities.parseArgumentsFromData(this.screenDetailComponents.screenTitle.text,
      this.screenDetailComponents.screenTitle.argumentIds, null, null, this.screenParameters);

    if (title) {
      this.titleUpdated.emit({ id: null, title: title, subtitle: null });
    }
  }

  onMenuClick(event: any) {
    if (event.item.action) {
      {
        if ((event.item.action[0].action as ActionIdentifier) === ActionIdentifier.DisplayDialog) {
          this.displayDialogAction(event);
        } else {

          const completionEvent = new EventEmitter<any>();
          if (event.item.action.find(a => (a.action as ActionIdentifier) === ActionIdentifier.DeleteItem)) {
            completionEvent.subscribe(complete => this.deleteCompleted());
          }

          if (event.item.action.find(a => (a.action as ActionIdentifier) === ActionIdentifier.UpdateData)) {
            if (event.item.action[0].actionArgument.resultTargetDataObject === 'screenParameters') {
              completionEvent.subscribe(() => {
                this.defaultPriceSchemeId = this.screenParameters['defaultPriceSchemeId'];

                // TODO notify the parent grid
              });
            }
          }

          const action = new ActionBuilder(event.item.action, event.row, null, this.uriCollections,
            this.screenParameters, null, completionEvent, this.thingService, this.toastr, this.dialog);
          action.PerformAction();
        }
      }
    }
  }

  displayDialogAction(event: any): any {
    const action = event.item.action[0];
    const screenDetailComponent = this.screenDetailComponents.componentScreens.find(m => m.screenTag === action.actionArgument.screenTag);

    switch (screenDetailComponent.screenTag) {
      case 'amendPriceBand':
        {
          const dialogRef = this.dialog.open(PriceBandUpdateDialogComponent, {
            width: '600px',
            data: {
              screenDetail: screenDetailComponent, action: action, row: event.row, columns: this.priceBandColumns,
              title: event.item.menuItemTitle
            }
          });

          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              this.reloadBands();
            }

          });
        }
        break;

      case 'priceScheme':
        {
          // Make sure we are updating the latest loaded row

          let priceScheme;
          let priceSchemeDetailData = [];
          if (event.row) {
            priceScheme = this.priceSchemeDataDetail.dataItems.find(m => m.priceSchemeID === event.row.priceSchemeID);
            priceSchemeDetailData = this.priceSchemeDetailDataDetail.dataItems.filter(p => p.priceSchemeID === event.row.priceSchemeID);
          }

          const dialogRef = this.dialog.open(PriceSchemeUpdateDialogComponent, {
            width: '600px',
            data: {
              screenDetails: this.screenDetailComponents,
              action: action,
              row: priceScheme,
              columns: this.priceSchemeColumns,
              priceSchemeDetailColumns: this.priceSchemeDetailColumns,
              priceSchemeDetails: priceSchemeDetailData,
              title: event.item.menuItemTitle,
              uriCollections: this.uriCollections,
            },
          });

          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              this.reloadSchemes();
            }

          });
        }
        break;

      case 'priceSchemeDetail':
        {
          // Make sure we are updating the latest loaded row
          const priceScheme = this.priceSchemeDataDetail.dataItems.find(m => m.priceSchemeID === event.row.priceSchemeID);
          const priceSchemeDetailData = this.priceSchemeDetailDataDetail.dataItems.filter(p => p.priceSchemeID === event.row.priceSchemeID);

          const dialogRef = this.dialog.open(PriceSchemeDetailUpdateDialogComponent, {
            width: '600px',
            data: {
              screenDetail: screenDetailComponent,
              title: event.item.menuItemTitle, action: action,
              columns: this.priceSchemeDetailColumns,
              priceSchemeDetails: priceSchemeDetailData,
              priceScheme: priceScheme
            }
          });

          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              this.reloadSchemes();
            }

          });
        }
    }
  }

  private reloadBands() {

    this.priceBandDataDetailOriginal = JSON.parse(JSON.stringify(this.priceBandDataDetail));

    // Reload price bands
    this.loadingIndicator = true;
    this.thingService.getDataDetailWithHeader(this.priceBandUri.dataUri)
      .subscribe(result => {
        this.priceBandDataDetail = result;
        this.restoreSelections();
        this.loadingIndicator = false;
      }, error => this.onGetDetailFailed(error));

  }

  private reloadProductPriceSchemePriceBands() {

    // this.productPriceSchemePriceBandDataDetailOriginal = JSON.parse(JSON.stringify(this.productPriceSchemePriceBandDataDetail));

    // Reload price bands
    this.loadingIndicator = true;
    this.thingService.getDataDetailWithHeader(this.productPriceSchemePriceBandUri.dataUri)
      .subscribe(result => {
        this.productPriceSchemePriceBandDataDetail = result;
        this.restoreSelections();
        this.loadingIndicator = false;
      }, error => this.onGetDetailFailed(error));

  }

  private reloadSchemes() {

    // Reload price schemes and priceSchemeDetails
    this.loadingIndicator = true;

    this.thingService.getData2(this.priceSchemeUri.dataUri, this.priceSchemeDetailUri.dataUri)
      .subscribe(result => {
        this.priceSchemeDataDetail = result[0];
        this.priceSchemeDetailDataDetail = result[1];

        this.restoreSelections();
        this.loadingIndicator = false;
      }, error => this.onGetDetailFailed(error));

    // this.thingService.getDataDetailWithHeader(this.priceSchemeUri.dataUri)
    //   .subscribe(result => {
    //     this.priceSchemeDataDetail = result;

    //     this.restoreSelections();
    //     this.loadingIndicator = false;
    //   }, error => this.onGetDetailFailed(error));

  }

  // private onDeleteItemFailed(error: any) {

  //   this.toastr.error(`Unable to delete data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
  // null, { closeButton: true, tapToDismiss: true });
  // }

  restoreSelections() {

    // Restore original price scheme selections
    if (this.priceBandDataDetailOriginal) {

      for (const band of this.priceBandDataDetail.dataItems) {

        const originalBand = this.priceBandDataDetailOriginal.dataItems.find(m => m.priceBandID === band.priceBandID);

        if (originalBand && originalBand.scheme) {
          band.scheme = originalBand.scheme;
        }

      }

      this.priceBandDataDetailOriginal = null;
    }

  }

  public Save() {

    this.priceUpdateCount = 0;
    this.priceUpdateTotal = 0;

    // Get total prices to save first
    if (this.priceSummaryDataDetail && this.priceSummaryDataDetail.dataItems) {

      for (const priceBand of this.priceBandDataDetail.dataItems) {

        for (const priceScheme of this.priceSchemeDataDetail.dataItems) {

          const prices = this.priceSummaryDataDetail.dataItems
            .filter(m => m.priceSchemeID === priceScheme.priceSchemeID && m.priceBandID === priceBand.priceBandID);

          if (prices.length > 0) {
            this.priceUpdateTotal++;
          }

        }
      }
    }

    const priceEditUri: string = Utilities.parseArgumentsFromData(this.priceSummaryTemplate.onEditAction[0].actionArgument.editUri,
      this.priceSummaryTemplate.onEditAction[0].actionArgument.editUriArgs, this.priceSummaryDataDetail.dataItems);

    this.thingService.postDataDetails(priceEditUri, this.priceSummaryDataDetail.dataItems)
      .subscribe(result => this.onPostDataDetailSuccessful(result), error => this.onPostDataDetailFailed(error));

    const productPriceSchemeEditUri: string =
      Utilities.parseArgumentsFromData(this.productPriceSchemePriceBandTemplate.onEditAction[0].actionArgument.editUri,
        this.priceSummaryTemplate.onEditAction[0].actionArgument.editUriArgs, this.productPriceSchemePriceBandDataDetail.dataItems);

    this.thingService.postDataDetails(productPriceSchemeEditUri, this.productPriceSchemePriceBandDataDetail.dataItems)
      .subscribe(result => this.onPostDataDetailSuccessful(result), error => this.onPostDataDetailFailed(error));


    // I'm not sure why we need to iterate through everything as we can post multiple items to the API...
    // if (this.priceSummaryDataDetail && this.priceSummaryDataDetail.dataItems) {

    //   // for (let priceBand of this.priceBandDataDetail.dataItems) {
    //     for (let productPriceSchemePriceBand of this.productPriceSchemePriceBandDataDetail.dataItems) {
    //       let editUri: string = Utilities.parseArguments(
    //      this.productPriceSchemeTemplate.onEditAction[0].actionArgument.editUri,
    //        this.priceTemplate.onEditAction[0].actionArgument.editUriArgs, productPriceSchemePriceBand);
    //       this.thingService.postDataDetailSingle(editUri, productPriceSchemePriceBand)
    //           .subscribe(result => this.onPostDataDetailSuccessful(result), error => this.onPostDataDetailFailed(error));
    //       let prices = this.priceSummaryDataDetail.dataItems.filter(
    //          m => m.priceSchemeID === productPriceSchemePriceBand.priceSchemeID);// && m.priceBandID === priceBand.priceBandID);
    //       if (prices.length > 0) {
    //         let editUri: string = Utilities.parseArguments(this.priceTemplate.onEditAction[0].actionArgument.editUri,
    //          this.priceTemplate.onEditAction[0].actionArgument.editUriArgs, prices);
    //         // this.thingService.postDataDetailSingle(editUri, prices)
    //         //   .subscribe(result => this.onPostDataDetailSuccessful(result), error => this.onPostDataDetailFailed(error));
    //       }
    //     }
    //   // }
    // }
  }

  private onPostDataDetailSuccessful(result: PostResponse[]) {

    result.forEach(element => {
      if (element.errorCode === 0) {
        this.priceUpdateCount++;
      } else {
        this.toastr.error(element.errorMessage, `Error '${element.errorCode}'`, { closeButton: true, tapToDismiss: true });
      }
    });

    Utilities.log2('Pricing - onPostDataDetailSucessful', JSON.stringify(result));

    this.updateCompleted.emit(true);
  }

  private onPostDataDetailFailed(error: any) {
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
    this.updateCompleted.emit(false);
  }

  deleteCompleted() {
    this.reloadBands();
    this.reloadProductPriceSchemePriceBands();
  }
}
