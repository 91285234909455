export class WorkingWeekConfig {
    ftConfigItemId: number;
    displayName: string;
    value: WorkingWeekValue;
    isActive: string;
    isOverriding: boolean;
    isUserInterface: boolean;
}

export class WorkingWeekValue {

    constructor(jsonStr: string) {
        let jsonObj: any = JSON.parse(jsonStr);
        for (let prop in jsonObj) {
            this[prop] = jsonObj[prop];
        }
    }

    StartOfWorkingWeek: number;
    Monday: BusinessHours;
    Tuesday: BusinessHours;
    Wednesday: BusinessHours;
    Thursday: BusinessHours;
    Friday: BusinessHours;
    Saturday: BusinessHours;
    Sunday: BusinessHours;
}

export class BusinessHours {
    OpeningTime: string;
    ClosingTime: string;
}