import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogButton } from 'src/app/models/actions/ActionAlertDialog';

export interface DialogData {
  title?: string;
  message?: string;
  positiveButton?: DialogButton;
  negativeButton?: DialogButton;
  neutralButton?: DialogButton;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  message = 'message';
  title: string;
  positiveButton: DialogButton;
  negativeButton: DialogButton;
  neutralButton: DialogButton;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.message = this.data.message;
    this.title = this.data.title;
    this.positiveButton = this.data.positiveButton;
    this.negativeButton = this.data.negativeButton;
    this.neutralButton = this.data.neutralButton;

    if (!this.positiveButton && !this.neutralButton) {
      this.neutralButton.title = 'OK';
    }
  }

  onNegative() {
      this.dialogRef.close(-1);
  }

  onPositive() {
    this.dialogRef.close(1);
  }

  onNeutral() {
    this.dialogRef.close(0);
  }
}
