import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { Zooming } from 'src/app/interfaces';
import { GanttService } from 'src/app/services/gantt/gantt.service';
import { ViewEventsDialogComponent } from '../view-events-dialog/view-events-dialog.component';

@Component({
    selector: 'activity-bars',
    templateUrl: './activity-bars.component.html',
    styles: [`
    .gantt_activity_line {
        /*border-radius: 2px;*/
        position: absolute;
        box-sizing: border-box;
        background-color: rgb(18,195,244);
        border: 1px solid #2196F3;
        -webkit-user-select: none;
        border-radius: 3px;
    }

    .gantt_activity_line:hover {
        /*cursor: move;*/
    }

    .gantt_activity_progress {
        text-align: center;
        z-index: 0;
        background: #2196F3;
        position: absolute;
        min-height: 18px;
        display: block;
        height: 18px;
    }

    .gantt_activity_progress_drag {
        height: 8px;
        width: 8px;
        bottom: -4px;
        margin-left: 4px;
        background-position: bottom;
        background-image: "";
        background-repeat: no-repeat;
        z-index: 2;
    }

    .gantt_activity_content {
        font-size: 12px;
        color: #fff;
        width: 100%;
        top: 0;
        position: absolute;
        white-space: nowrap;
        text-align: center;
        line-height: inherit;
        overflow: hidden;
        height: 100%;
    }

    .gantt_activity_content_milestone {
        font-size: 12px;
        // color: #fff;
        width: 100%;
        top: 0;
        position: absolute;
        white-space: nowrap;
        text-align: center;
        line-height: inherit;
        overflow: hidden;
        height: 100%;
        transform: rotate(-45deg);
    }

    .gantt_activity_link_control {
        position: absolute;
        width: 13px;
        top: 0;   
    }

    .gantt_activity_right {
        right: 0;
    }

    .gantt_activity_left {
        left: 0;
    }

    .empty-row {
        padding-bottom: 30px;
        height: 24px;
    }

    // .gantt_activity_right:hover {
    //     cursor:w-resize;
    // }

    // .gantt_activity_left:hover {
    //     cursor:w-resize;
    // }
    `],
    providers: [
        GanttService
    ]
})
export class GanttActivityBarsComponent implements OnInit {
    @Input() timeScale: any;
    @Input() dimensions: any;
    @Input() obj: any;
    @Input() objGrouped: any[][];
    @Input() zoom: any;
    @Input() zoomLevel: any;
    @Input() options: any;

    @Output() itemSelected: EventEmitter<any> = new EventEmitter();

    public containerHeight: number = 0;
    public containerWidth: number = 0;

    currentTaskLength: number;
    currentParentGroupIndex: number = 0;
    currentGroupedDates = Array<Date>();
    public start: Date;
    public end: Date;

    datesToPlot = new Array<Date>();

    toolTip: "hello";

    selectedMileStoneEvents: any;

    public dates = Array<Date>();
    public eventsPerDayCount = Array<Array<number>>();
    eventsForAllDays = Array<Array<Array<any>>>();
    eventTypes: Array<any> = new Array<any>();

    constructor(public ganttService: GanttService, private route: ActivatedRoute, public dialog: MatDialog, private cdr: ChangeDetectorRef, private contextMenuService: ContextMenuService) { }

    ngOnInit() {
        this.eventTypes = this.route.snapshot.data.eventTypes;

        this.containerHeight = this.dimensions.height;
        this.containerWidth = this.dimensions.width;

        this.zoom.subscribe((zoomLevel: string) => {
            this.zoomLevel = zoomLevel;
        });;
        this.start = this.options.scale.start;
        this.end = this.options.scale.end;

        this.generateDateList();
    }

    getStartEndDates(events: any[])
    {
        var startEndDates = new Array<any>();

        //By sorting by start date we elimate the need to check for start date of iterating events being less than the current
        var eventsByStartDate = this.sortByStartDate(events);

        while (eventsByStartDate.length > 0)
        {
            var eventsRemaining = new Array<any>();

            if (!eventsByStartDate[0].endDate)
            {
                eventsByStartDate[0].endDate = new Date(eventsByStartDate[0].start);
                eventsByStartDate[0].endDate.setHours(23, 59, 59);
            }

            var currentEndDate = Date.parse(eventsByStartDate[0].endDate!.toString());
            var currentEndDateEvent = eventsByStartDate[0];

            for (let i=0;i<eventsByStartDate.length;i++)
            {
                //Check below means it overlaps in time
                if (eventsByStartDate[i].startDate.getTime() <= currentEndDate)
                {

                    if (!eventsByStartDate[i].endDate)
                    {
                        eventsByStartDate[i].endDate = new Date(eventsByStartDate[0].start);
                        eventsByStartDate[i].endDate.setHours(23, 59, 59);
                    }


                    var currentEventEndDate = Date.parse(eventsByStartDate[i].endDate!.toString());
                    if (currentEventEndDate >= currentEndDate)
                    {
                        currentEndDate = Date.parse(eventsByStartDate[i].endDate!.toString());
                        currentEndDateEvent = eventsByStartDate[i];
                    }
                    else {
                        eventsRemaining.push(eventsByStartDate[i]);
                    }
                }
                else {
                    eventsRemaining.push(eventsByStartDate[i]);
                }
            }
            startEndDates.push({start: eventsByStartDate[0], end: currentEndDateEvent});

            if (eventsRemaining.length == 1)
            {
                startEndDates.push({start: eventsRemaining[0], end: eventsRemaining[0]});
                console.log(startEndDates);
                return startEndDates;
            }
            // eventsByStartDate = eventsByStartDate.filter(x => eventsRemaining.includes(x));
            eventsByStartDate = eventsByStartDate.filter(x => eventsRemaining.includes(x));
            if (eventsByStartDate.length == 1)
            {
                startEndDates.push({start: eventsByStartDate[0], end: eventsByStartDate[0]});
                console.log(startEndDates);
                return startEndDates;
            }
        }
        console.log(startEndDates);
        return startEndDates;
    }

    public sortByStartDate(events: Array<any>) {
        var events = events.sort((a: any, b: any) => {
            return a.startDate.getTime() - b.startDate.getTime();
        });
        return events;
    }

    //TODO: the ability to move bars needs reviewing and there are a few quirks
    expandLeft($event: any, bar: any) {
        $event.stopPropagation();

        let ganttService = this.ganttService;
        let startX = $event.clientX;
        let startBarWidth = bar.style.width;
        let startBarLeft = bar.style.left;

        function doDrag(e: any) {
            let cellWidth = ganttService.cellWidth;
            let barWidth = startBarWidth - e.clientX + startX;
            let days = Math.round(barWidth / cellWidth);

            bar.style.width = days * cellWidth + days;
            bar.style.left = (startBarLeft - (days * cellWidth) - days);
        }

        this.addMouseEventListeners(doDrag);

        return false;
    }

    generateDateList() {
        //TODO why is this missing the first date?
        var date = new Date(this.start);
        while (date < this.end) {
            this.dates.push(date);
            date = new Date(date.setDate(date.getDate() + 1));
        }
    }

    expandRight($event: any, bar: any) {
        $event.stopPropagation();

        let ganttService = this.ganttService;
        let startX = $event.clientX;
        let startBarWidth = bar.style.width;
        let startBarEndDate = bar.task.end;
        let startBarLeft = bar.style.left;

        function doDrag(e: any) {
            let cellWidth = ganttService.cellWidth;
            let barWidth = startBarWidth + e.clientX - startX;
            let days = Math.round(barWidth / cellWidth);

            if (barWidth < cellWidth) {
                barWidth = cellWidth;
                days = Math.round(barWidth / cellWidth);
            }
            bar.style.width = ((days * cellWidth) + days); // rounds to the nearest cell            
        }

        this.addMouseEventListeners(doDrag);

        return false;
    }

    move($event: any, bar: any) {
        $event.stopPropagation();

        let ganttService = this.ganttService;
        let startX = $event.clientX;
        let startBarLeft = bar.style.left;

        function doDrag(e: any) {
            let cellWidth = ganttService.cellWidth;
            let barLeft = startBarLeft + e.clientX - startX;
            let days = Math.round(barLeft / cellWidth);

            // TODO: determine how many days the bar can be moved
            // if (days < maxDays) {
            bar.style.left = ((days * cellWidth) + days); // rounded to nearest cell

            // keep bar in bounds of grid
            if (barLeft < 0) {
                bar.style.left = 0;
            }
            // }
            // TODO: it needs to take into account the max number of days.
            // TODO: it needs to take into account the current days.
            // TODO: it needs to take into account the right boundary.
        }

        this.addMouseEventListeners(doDrag);

        return false;
    }

    //parent grouping index isn't required anymore but keep in case we want to reintroduce multiple expanded event types.
    public drawBar(task: any, tasks: any[][], index: number, parentGroupingIndex: number) {
        let style = {};

        var rows = 0;
        for (let loopIndex = 0; loopIndex < index; loopIndex++)
        {
            rows += this.obj[loopIndex].length;
        }

        if (this.zoomLevel === Zooming[Zooming.hours]) {
            style = this.ganttService.calculateBar(task, tasks[parentGroupingIndex], index, rows, parentGroupingIndex, this.objGrouped.length, this.timeScale, 0, true);
        } else {
            var gridHeight = 0;
            // if (this.currentParentGroupIndex !== parentGroupingIndex) {
            //     gridHeight = this.currentTaskLength * this.ganttService.rowHeight;
            //     style = this.ganttService.calculateBar(task, tasks, index, parentGroupingIndex, this.objGrouped.length, this.timeScale, gridHeight);
            //     this.currentParentGroupIndex = parentGroupingIndex;
            // }
            // else {
            this.currentTaskLength = tasks.length;
            style = this.ganttService.calculateBar(task, tasks[parentGroupingIndex], index, rows, parentGroupingIndex, this.objGrouped.length, this.timeScale, 0);
            // }
        }
        return style;
    }

    isMileStone(task: any)
    {
        if (Object.prototype.toString.call(task.end) === "[object Date]") {
            if (isNaN(task.end.getTime()) || task.start == task.end) { // d.getTime() or d.valueOf() will also work
                return true;
            }
        }
        if(task.start.getTime() == task.end.getTime()){
            return true;
        }
        return false;
    }

    dateCheck = (from, to, check) => {
        let fDate, lDate, cDate;
        fDate = Date.parse(from);
        lDate = Date.parse(to);
        cDate = Date.parse(check);
        if ((cDate <= lDate && cDate >= fDate)) return true
        return false;
    }

    public drawActivityLine(startEndEvent: any, eventsGrouped: any, index: number, eventsGroupedLength: number)
    {
        this.currentTaskLength = eventsGroupedLength;
        var colour = eventsGrouped[0].colour;

        var line = this.ganttService.calculateActivityLine(startEndEvent, index, this.timeScale, colour);

       return line;
    }

    public drawBarGrouped(date: Date, groupedIndex: number, dateIndex: number, eventsGroupedLength: number) {
        let style;
        var events = this.objGrouped[groupedIndex];
        var count = 0;
        var minStartDate: any;
        var maxEndDate: any;
        var eventEndDateNotSameDay = false;
        var eventsForDay = new Array<any>();
        var eventPrimaryColour = events[0].colour;

        for (let i = 0; i < events.length; i++) {
            if (this.dateCheck(events[i].startDate, events[i].endDate, date)) {

                eventsForDay.push(events[i]);
                if (!minStartDate || events[i].startDate.getTime() < minStartDate.getTime()) {
                    var seconds = events[i].startDate.getSeconds();
                    var mins = events[i].startDate.getMinutes();
                    var hours = events[i].startDate.getHours()
                    var startDate = date;
                    startDate.setHours(hours);
                    startDate.setMinutes(mins);
                    startDate.setSeconds(seconds);
                    minStartDate = startDate;
                }

                var endDate = new Date(events[i].endDate!);
                if (!maxEndDate || endDate.getTime() > maxEndDate.getTime()) {
                    if (!eventEndDateNotSameDay && endDate.getDate() > events[i].startDate.getDate()) {
                        endDate = new Date(date);
                        // endDate = new Date(`${events[i].start.getFullYear()}-${events[i].start.getDate()}-${events[i].start.getMonth()}`);
                        endDate.setHours(23, 59, 59);
                        maxEndDate = endDate;
                        eventEndDateNotSameDay = true;
                    }
                    else {
                        if (!eventEndDateNotSameDay) {
                            maxEndDate = endDate;
                        }
                    }
                }
                // }
                count++;
            }
        }

        if (!this.eventsPerDayCount[groupedIndex]) {
            this.eventsPerDayCount[groupedIndex] = [];
        }
        this.eventsPerDayCount[groupedIndex].push(count);

        if (!this.eventsForAllDays[groupedIndex]) {
            this.eventsForAllDays[groupedIndex] = []
        }
        this.eventsForAllDays[groupedIndex][dateIndex] = eventsForDay;

        if (!!minStartDate && !!maxEndDate) {
            style = this.ganttService.calculateBarGrouped2(minStartDate, maxEndDate, groupedIndex, this.timeScale, eventPrimaryColour as string);
        }
        this.currentTaskLength = eventsGroupedLength;

        return style;
    }

    public addMouseEventListeners(dragFn: any) {

        function stopFn() {
            document.documentElement.removeEventListener('mousemove', dragFn, false);
            document.documentElement.removeEventListener('mouseup', stopFn, false);
            document.documentElement.removeEventListener('mouseleave', stopFn, false);
        }

        document.documentElement.addEventListener('mousemove', dragFn, false);
        document.documentElement.addEventListener('mouseup', stopFn, false);
        document.documentElement.addEventListener('mouseleave', stopFn, false);
    }

    addAdditionalPadding(task: any, previousTask: any) {
        if (task.eventTypeId != previousTask.eventTypeId) {
            return true;
        }
        return false;
    }

    getNumberOfEventsForDay(event: any, events: any)
    {   
        if (this.isMileStone(event))
        {
            var numberOfSameDayMilestones = events.filter(x => x.start.getTime() == event.start.getTime());
            if (!!numberOfSameDayMilestones && numberOfSameDayMilestones.length > 1)
            {
                return numberOfSameDayMilestones.length;
            }
        }
        return;
    }

    onMilestoneSelect($event: KeyboardEvent, event: any, events: any)
    {
            var numberOfSameDayMilestones = events.filter(x => x.start.getTime() == event.start.getTime());
            if (!!numberOfSameDayMilestones)
            {
                if (numberOfSameDayMilestones.length == 1)
                {
                    this.itemSelected.emit(event);
                }
                else if (numberOfSameDayMilestones.length > 1)
                {
                    this.selectedMileStoneEvents = numberOfSameDayMilestones;
                    this.onFlowStatus($event, event);
                }
            }
    }

    onSelect(item: any) {
        this.itemSelected.emit(item);
    }

    public onFlowStatus($event: KeyboardEvent, columnItem) {

        // Must be a better way to get ViewChildren component by index. Tried toArray
        let selectedMenu: ContextMenuComponent;
        // this.contextMenus.forEach(m => {
    
        //   index++;
    
        // });
    
        this.cdr.detectChanges();
    
        this.contextMenuService.show.next({
          anchorElement: $event.target,
          // Optional - if unspecified, all context menu components will open
          contextMenu: selectedMenu,
          event: <any>$event,
          item: columnItem,
        });
    
        $event.preventDefault();
        $event.stopPropagation();
        this.cdr.detectChanges();
    
      }
}
