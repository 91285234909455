import { ItemTextModel } from '../viewControls/ItemTextModel';
import { MenuItem } from '../data/MenuItem';
import { CardItem } from '../cardModels/CardItem';

export class CardScreenDetail {
    screenTitle: ItemTextModel;
    screenSubTitle: ItemTextModel;
    toolbarItems: MenuItem[];
    cardItems: CardItem[];
    requiredScreenParameters: any[];
    // button1: ItemStatusModel;
    // button2: ItemStatusModel;
    // cardColor: ItemStatusModel;
}