import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IconMapping } from 'src/app/models/IconMapping';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IconService {
    iconMappings: Array<IconMapping>;

    constructor(private httpClient: HttpClient) {
    }

    public getIconMappings(): Observable<Array<IconMapping>> {
        return this.httpClient.get(`https://ftwebdev01.azurewebsites.net/Qmolo-FontAwesome.txt`) as Observable<Array<IconMapping>>;
    }

    setIconMappings(val: Array<IconMapping>) {
        val.forEach(y => y.charCode = y.charCode.substring(3));
        this.iconMappings = val;
    }

    getIconMaps() {
        return !!this.iconMappings ? this.iconMappings : new Array<IconMapping>();
    }
}
