import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FTFlowStatus } from 'src/app/models/data/FTFlowStatus';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';
import { DataType } from 'src/app/models/Enums';
import { RequiredScreenParameters } from 'src/app/models/screenModels/RequiredScreenParameters';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { TextArgument } from 'src/app/models/TextModel';
import { environment } from '../../../environments/environment';
import { Action } from '../../models/actions/Action';

import * as moment from 'moment';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { DataPathHelper } from 'src/app/helpers/data-path-helper';
import { ConfigUtilitiesService } from './config-utilities.service';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { FlowService } from '../flow/flow.service';

declare var angular: any;

@Injectable({
  providedIn: 'root'
})
export class Utilities {

  public static readonly captionAndMessageSeparator = ':';
  public static readonly noNetworkMessageCaption = 'No Network';
  public static readonly noNetworkMessageDetail = 'The server cannot be reached';
  public static readonly accessDeniedMessageCaption = 'Access Denied!';
  public static readonly accessDeniedMessageDetail = '';

  static readonly consoleDebug = environment.ConsoleDebug;

  public static priceBandScreenDetails: any;

  public static getHttpResponseMessage(data: HttpResponseBase | any): string[] {

    const responses: string[] = [];

    if (data instanceof HttpResponseBase) {

      if (this.checkNoNetwork(data)) {
        responses.push(`${this.noNetworkMessageCaption}${this.captionAndMessageSeparator} ${this.noNetworkMessageDetail}`);
      } else {
        const responseObject = this.getResponseBody(data);

        if (responseObject && (typeof responseObject === 'object' || responseObject instanceof Object)) {

          for (const key in responseObject) {
            if (key) {
              responses.push(`${key}${this.captionAndMessageSeparator} ${responseObject[key]}`);
            } else if (responseObject[key]) {
              responses.push(responseObject[key].toString());
            }
          }
        }
      }

      if (!responses.length && this.getResponseBody(data)) {
        responses.push(`${data.statusText}: ${this.getResponseBody(data).toString()}`);
      }
    }

    if (!responses.length) { responses.push(data.toString()); }

    if (this.checkAccessDenied(data)) {
      responses.splice(0, 0, `${this.accessDeniedMessageCaption}${this.captionAndMessageSeparator} ${this.accessDeniedMessageDetail}`);
    }

    return responses;
  }

  public static getResponseBody(response: HttpResponseBase) {
    if (response instanceof HttpResponse) { return response.body; }
    if (response instanceof HttpErrorResponse) { return response.error || response.message || response.statusText; }
  }


  public static checkNoNetwork(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 0;
    }

    return false;
  }

  public static checkAccessDenied(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 403;
    }

    return false;
  }

  public static checkNotFound(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 404;
    }

    return false;
  }

  // public static checkIsLocalHost(url: string, base?: string) {
  //   if (url) {
  //     const location = new URL(url, base);
  //     return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
  //   }

  //   return false;
  // }

  /* Get height for control */
  public static innerHeight(top: number) {
    return (window.innerHeight - top) > 300 ? window.innerHeight - top : 300;
  }

  public static innerWidth() {
    return (window.innerWidth);// > 300 ? window.innerHeight - top : 300;
  }

  public static jsonDate(date: Date) {

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();
    let minute = date.getMinutes().toString();
    let hour = date.getHours().toString();
    let second = date.getSeconds().toString();

    if (hour.length === 1) {
      hour = '0' + hour;
    }

    if (minute.length === 1) {
      minute = '0' + minute;
    }

    if (second.length === 1) {
      second = '0' + second;
    }

    if (day.length === 1) { day = '0' + day; }

    if (month.length === 1) { month = '0' + month; }

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  }

  public static setScreenParameters(parameters: any, requiredScreenParameters: RequiredScreenParameters[],
    parentData: any[], uriCollections?: UriCollection[]): any {

    if (!parameters && !requiredScreenParameters) { return; }

    let newParameters = {};

    if (parameters) {
      newParameters = {}; // this.copy(parameters);
    }

    for (const key in parameters) {

      if (key && parameters[key]) {

        let done = false;

        // if the statement begins with '=' do the calculation here:
        if (parameters[key].toString().startsWith('=')) {

          console.log(parameters[key]);
          let i = parameters[key].indexOf('-');
          let op = -1;
          if (i < 0) {
            i = parameters[key].indexOf('+');
            op = +1;
          }
          if (i < 0) {
            newParameters[key] = parameters[key];
            done = true;
            continue;
          }

          const paramValue = parameters[key].substring(1, i);
          let value;
          if (paramValue.startsWith('@date.now')) {
            value = new Date();
          } else {
            value = this.getValueFromUriCollection(paramValue, uriCollections);
          }

          if (value) {
            const dataType = this.getDataTypeFromUriCollection(paramValue, uriCollections);

            if (value) {
              if (dataType === DataType.DateTime || paramValue.startsWith('@date.now')) {

                // console.log('regex it!');
                // const reg = new RegExp('^=@date.now[+-][0-9]+[a-z]$');
                // const res = reg.exec(parameters[key]);
                // console.log(res);
                if (op === +1) {
                  newParameters[key] = moment(value).add(parameters[key].substring(i), 'day').toJSON();
                } else {
                  newParameters[key] = moment(value).add(parameters[key].substring(i), 'day').toJSON();
                }
              }
              done = true;
              continue;
            }
          }
        }

        if (parameters[key].startsWith('@date.now')) {
          newParameters[key] = new Date().toJSON();
          done = true;
          continue;
        }

        if (uriCollections) {
          if (parameters[key] !== undefined) {
            const value = this.getValueFromUriCollection(parameters[key], uriCollections);
            if (value) {
              newParameters[key] = value;
              done = true;
            }
          }
        }
        if (parentData && !done) {
          if (parameters[key] !== undefined) {

            const index: number = parameters[key].toString().lastIndexOf('.');

            if (index > -1) {
              const parameterName = parameters[key].substring(index + 1);
              newParameters[key] = parentData[parameterName];
              done = true;
            }
          }
        }

        if (!newParameters[key] && !done) {
          if (requiredScreenParameters && requiredScreenParameters.find(p => p.key === key)) {
            newParameters[key] = requiredScreenParameters.find(p => p.key === key).default;
            done = true;
          }
        }
      }
    }

    if (requiredScreenParameters) {
      requiredScreenParameters.forEach(rsp => {
        if (!newParameters[rsp.key] && rsp.default) {
          newParameters[rsp.key] = rsp.default;
        }
      });
    }

    return newParameters;
  }

  public static parseTemplate(template: string, data: any): string {

    let content: string = template;

    for (const key in data) {
      if (key) { content = content.replace(`{{${key}}}`, data[key]); }
    }

    return content;
  }

  public static copy(value: any): any {
    return JSON.parse(JSON.stringify(value));
  }

  public static parseAction(initialAction: Action, row: any[], screenParameters?: any): Action {

    // Take a copy of action before parsing values
    if (initialAction) {
      const action = JSON.parse(JSON.stringify(initialAction));

      if (action.actionArgument) {

        if (action.actionArgument.screenParameters) {
          // Set screen parameter values
          for (const key in action.actionArgument.screenParameters) {

            if (!key) { continue; }

            const entry: string = action.actionArgument.screenParameters[key];
            if (entry) {

              const res = this.parseArgumentsFromData(null, [entry], row, null, screenParameters);
              action.actionArgument.screenParameters[key] = res;
            }
          }
        }

        // parse dataUris
        if (action.actionArgument.uris) {
          for (const uri of action.actionArgument.uris as ActionURI[]) {

            if (uri.dataUri) {
              uri.originalDataUri = uri.dataUri.toString();
              uri.dataUri = Utilities.parseArgumentsFromData(uri.dataUri, uri.dataUriArgs, row,
                null, screenParameters);
            }
          }
        }

        if (action.actionArgument.editUri) {
          action.actionArgument.editUri =
            Utilities.parseArgumentsFromData(action.actionArgument.editUri, action.actionArgument.editUriArgs, row, null, screenParameters);
        }

        if (action.actionArgument.dataUri) {
          action.actionArgument.dataUri =
            Utilities.parseArgumentsFromData(action.actionArgument.dataUri, action.actionArgument.dataUriArgs, row, null,
              action.actionArgument.screenParameters);
        }
      }
      return action;
    }
  }

  public static parseActionFromUriCollections(initialAction: Action, uriCollections: UriCollection[], screenParameters?: any): Action {

    // Take a copy of action before parsing values
    if (initialAction) {
      const action = JSON.parse(JSON.stringify(initialAction));

      if (action.actionArgument) {

        if (action.actionArgument.screenParameters) {
          // Set screen parameter values
          for (const key in action.actionArgument.screenParameters) {

            if (!key) { continue; }

            const entry: string = action.actionArgument.screenParameters[key];
            if (entry) {

              const res = this.parseArgumentsFromUriCollection(null, [entry], uriCollections, null, screenParameters);
              action.actionArgument.screenParameters[key] = res;
            }
          }
        }

        // parse dataUris
        if (action.actionArgument.uris) {
          for (const uri of action.actionArgument.uris as ActionURI[]) {

            if (uri.dataUri) {
              uri.originalDataUri = uri.dataUri.toString();
              uri.dataUri = this.parseArgumentsFromUriCollection(uri.dataUri, uri.dataUriArgs, uriCollections, null, screenParameters);
            }
          }
        }

        if (action.actionArgument.editUri) {
          action.actionArgument.editUri =
            Utilities.parseArgumentsFromUriCollection(action.actionArgument.editUri, action.actionArgument.editUriArgs, uriCollections, null,
              screenParameters);
        }

        if (action.actionArgument.dataUri) {
          action.actionArgument.dataUri =
            Utilities.parseArgumentsFromUriCollection(action.actionArgument.dataUri, action.actionArgument.dataUriArgs, uriCollections, null,
              action.actionArgument.screenParameters);
        }
      }
      return action;
    }
  }


  public static evaluate(value: string, data: any[], isChecked?: boolean, screenParameters?: any): any {

    const source: string = this.parseText(value, data, isChecked, screenParameters);

    try {

      // TODO research alternatives https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval
      const notNull = source.indexOf('!=null');
      if (notNull > -1) {
        const compare = source.substring(0, notNull);
        return !(compare == 'null' || compare == 'undefined' || compare == '');
      }
      return eval(source);
    } catch (error) {
      // this.log2('Utilities - evaluate', 'Can\'t evaluate ' + source + ' from expression ' + value, 'warn');
      return false;
    }
  }

  public static parseText(value: string, data: any[], isChecked?: boolean, screenParameters?: any): string {

    let result: string = value;

    if (value === undefined) {
      return '';
    }

    if (isChecked !== undefined) {
      result = result.replace('isChecked', isChecked ? 'true' : 'false');
    }

    while (result.indexOf('data.') > -1) {
      const index: number = result.indexOf('data.');

      const endIndex: number = this.findIndex(result, index, ['#', '=', '&', '>', '<', '!']);

      const columnName: string = result.substring(index, endIndex);

      const d = this.parseArgumentsFromData('{0}', [columnName], data);
      result = result.replace(columnName, d);
      // result = result.replace(columnName, data[this.getLastEntry(columnName)]);
    }

    while (result.indexOf('screenParameters.') > -1) {
      const index: number = result.indexOf('screenParameters.');

      const endIndex: number = this.findIndex(result, index, ['#', '=', '&', '>', '<']);

      const columnName: string = result.substring(index, endIndex);
      if (screenParameters) {
        result = result.replace(columnName, screenParameters[this.getLastEntry(columnName)]);
      }
    }

    return result;
  }

  public static evaluateWithUriCollection(value: string, data: UriCollection[], screenParameters?: any, isChecked?: boolean): any {

    const source: string = this.parseTextWithUriCollection(value, data, screenParameters, isChecked);

    try {
      var conditions = value.split("&&");
      for (const condition of conditions) {
        if(!this.evaluateSingleCondition(condition, data, null, screenParameters, isChecked)){
          return false;
        }
      }

      return true;
    } catch (error) {
      this.log2('Utilities - evaluate', 'Can\'t evaluate ' + source + ' from expression ' + value, 'warn');
      return false;
    }
  }

  public static evaluateSingleCondition(path: string, uriCollections: UriCollection[], row: any, screenParameters?: any, isChecked?: boolean){
    var xor = false;
    if(path.startsWith("!")){
      xor = true;
      path = path.substring(1);
    }

    if(path=="checked"){
      return xor !== isChecked
    }

    if(DataPathHelper.isDataPath(path)){
      var result = false;
      if(path.includes("==")){
        var pathSegs = path.split("==");
        if(row){
          const dataPath = new DataPath(pathSegs[0]);
          var item = row[dataPath.propertyName];
        }
        else{
          var item = Utilities.getDataFromUriCollection(pathSegs[0], uriCollections);
        }
        if(!item && item != 0){
          result = pathSegs[1] == "null";
        }
        else{
          result = item.toString() == pathSegs[1];
        }
      }
      else if(path.includes("!=")){
        var pathSegs = path.split("!=");
        if(row){
          const dataPath = new DataPath(pathSegs[0]);
          var item = row[dataPath.propertyName];
        }
        else{
          var item = Utilities.getDataFromUriCollection(pathSegs[0], uriCollections);
        }
        if(!item){
          result = pathSegs[1] != "null";
        }
        else{
          result = item.toString() != pathSegs[1];
        }
      }
      else if(path.includes(">")){
        var pathSegs = path.split(">");
        var dataType = Utilities.getDataTypeFromUriCollection(pathSegs[0], uriCollections);

        switch (dataType) {
          case DataType.Unknown:
            if(row){
              const dataPath = new DataPath(pathSegs[0]);
              var unknownItem = row[dataPath.propertyName];
            }
            else{
              var unknownItem = Utilities.getDataFromUriCollection(pathSegs[0], uriCollections);
            }
            if(Number(unknownItem) && Number(pathSegs[1])){
              result = Number(unknownItem) > Number(pathSegs[1])
            }
            break;
          case DataType.Int:
          case DataType.Float:
          case DataType.Decimal:
          case DataType.Money:
            if(row){
              const dataPath = new DataPath(pathSegs[0]);
              var numItem = row[dataPath.propertyName];
            }
            else{
              var numItem = Utilities.getDataFromUriCollection(pathSegs[0], uriCollections);
            }
            if(Number(pathSegs[1]) || Number(pathSegs[1]) === 0){
              result = numItem > Number(pathSegs[1]);
            }
            break;
          case DataType.DateTime:
            //TODO DateTime Conditions
          break;
        
          default:
            break;
        }
      }
      else if(path.includes("<")){
        var pathSegs = path.split("<");
        var dataType = Utilities.getDataTypeFromUriCollection(pathSegs[0], uriCollections);
        switch (dataType) {
          case DataType.Unknown:
            if(row){
              const dataPath = new DataPath(pathSegs[0]);
              var unknownItem = row[dataPath.propertyName];
            }
            else{
              var unknownItem = Utilities.getDataFromUriCollection(pathSegs[0], uriCollections);
            }
            if(Number(unknownItem) && Number(pathSegs[1])){
              result = Number(unknownItem) < Number(pathSegs[1])
            }
            break;
          case DataType.Int:
          case DataType.Float:
          case DataType.Decimal:
          case DataType.Money:
            if(row){
              const dataPath = new DataPath(pathSegs[0]);
              var numItem = row[dataPath.propertyName];
            }
            else{
              var numItem = Utilities.getDataFromUriCollection(pathSegs[0], uriCollections);
            }
            if(Number(pathSegs[1]) || Number(pathSegs[1]) === 0){
              result = numItem < Number(pathSegs[1]);
            }
            break;
          case DataType.DateTime:
            //TODO DateTime Conditions
          break;
        
          default:
            break;
        }
      }
      else{
        if(row){
          const dataPath = new DataPath(path);
          var item = row[dataPath.propertyName];
        }
        else{
          var item = Utilities.getDataFromUriCollection(pathSegs[0], uriCollections);
        }
        if(item){
          result = !!item
        }
        else{
          result = false;
        }
      }
    }
    else if(DataPathHelper.isScreenParameterPath(path)){
      var result = false;
      if(path.includes("==")){
        var pathSegs = path.split("==");
        var screenPath = Utilities.getLastEntry(pathSegs[0]);
        if(!screenParameters[screenPath]){
          result = pathSegs[1] == "null";
        }
        else{
          result = screenParameters[screenPath] == pathSegs[1];
        }
      }
      else if(path.includes("!=")){
        var pathSegs = path.split("!=");
        var screenPath = Utilities.getLastEntry(pathSegs[0]);
        if(!screenParameters[screenPath]){
          result = pathSegs[1] != "null";
        }
        else{
          result = screenParameters[screenPath] != pathSegs[1];
        }
      }
      else{
        var screenPath = Utilities.getLastEntry(path);
        return xor !== screenParameters[screenPath];
      }
      return xor !== result;
    }
    else if(path.includes("@workerId")){
      var workerId = sessionStorage.getItem("workerId");
      if(!workerId){
        workerId = "null"
      }
      if(path.includes("==")){
        var pathSegs = path.split("==");
        result = workerId == pathSegs[1];
      }
      else if(path.includes("!=")){
        var pathSegs = path.split("!=");
        result = workerId != pathSegs[1];
      }
    }
    else{
      return xor !== false;
      }
    if(xor){
      return !result;
    }
    return result;
  }

  public static parseTextWithUriCollection(value: string, uriCollections: UriCollection[], screenParameters?: any, isChecked?: boolean): string {

    let result: string = value;



    if (value === undefined) {

      return '';

    }



    if (isChecked !== undefined) {

      result = result.replace('isChecked', isChecked ? 'true' : 'false');

    }



    while (result.indexOf('data.') > -1) {



      const objectName = this.getObjectName(result);

      const uriCollection = uriCollections.find(u => u.name === objectName);

      if (!uriCollection) { continue; }



      let data;

      if (uriCollection.dataValue) {

        data = uriCollection.dataValue;

      } else if (uriCollection.dataValues && uriCollection.dataValues[0]) {

        data = uriCollection.dataValues[0];

      } else {

        continue;

      }



      const index: number = result.indexOf('data.');



      const endIndex: number = this.findIndex(result, index, ['#', '=', '&', '>', '<']);



      const columnName: string = result.substring(index, endIndex);

      result = result.replace(columnName, data[this.getLastEntry(columnName)]);

    }

    while (result.indexOf('screenParameters.') > -1) {
      const index: number = result.indexOf('screenParameters.');

      const endIndex: number = this.findIndex(result, index, ['#', '=', '&', '>', '<']);

      const columnName: string = result.substring(index, endIndex);
      if (screenParameters) {
        result = result.replace(columnName, screenParameters[this.getLastEntry(columnName)]);
      }
    }


    return result;

  }

  public static canPerformAction(actionList: Action[], uriCollections: UriCollection[], isChecked?: boolean, row?: any, rowKey?: string) {
    let canDoAction = false;
    actionList.forEach(action => {
      if (this.evaluateCondition(action.condition, uriCollections, isChecked, row, rowKey)) {
        canDoAction = true;
        return;
      }
    });
    return canDoAction;
  }

  public static evaluateCondition(value: string, uriCollections: UriCollection[], screenParameters: any, isChecked?: boolean, row?: any, rowKey?: string): any {

    if (!value) {
      // no condition so always true
      return true;
    }

    try {
      var conditions = value.split("&&");
      for (const condition of conditions) {
        if(!this.evaluateSingleCondition(condition, uriCollections, row, screenParameters, isChecked)){
          return false;
        }
      }

      return true;
    } catch (error) {
      this.log2('Utilities - evaluate', 'Can\'t evaluate expression ' + value, 'warn');
      return false;
    }

  }

  private static parseCondition(value: string, uriCollections: UriCollection[], screenParameters: any, isChecked?: boolean, row?: any, rowKey?: string): string {
    let result: string = value;

    if (value === undefined) {
      return '';
    }

    if (isChecked !== undefined) {
      result = result.replace('isChecked', isChecked ? 'true' : 'false');
    }

    // let length = result.length;
    let endIndex = 0;
    let remainingResult = result;

    while (endIndex > -1) {
      let newStr = remainingResult;
      if (remainingResult.startsWith('!')) {
        newStr = remainingResult.substring(1);
      }

      const elements = ['#', '==', '!=', '&&', '||', '>', '<'];

      endIndex = -1;
      let index: number;
      let stringLength = 0;

      for (const element of elements) {
        index = newStr.indexOf(element, 0);
        if (index > -1 && (endIndex === -1 || index < endIndex)) {
          endIndex = index;
          stringLength = element.length;
        }
      }

      if (endIndex > -1) {

        const dataPath = newStr.substring(0, endIndex);
        remainingResult = newStr.substring(endIndex + stringLength);

        const parts = dataPath.split('.');

        if (parts[0] === 'data' && parts.length > 2) {
          const objectName = parts[1];
          const columnName = parts[2];

          if (objectName === rowKey || (row && !uriCollections)) {
            // data = row;
            if (row) {
              result = result.replace(dataPath, row[columnName]);
            } else {
              // console.log('data not defined');
            }
          } else if (uriCollections) {
            const data = uriCollections.find(c => c.name === objectName);
            if (data) {
              if (data.dataValues instanceof Array && data.dataValues.length === 1) {
                result = result.replace(dataPath, (data.dataValues[0])[columnName]);
              } else {
                result = result.replace(dataPath, data.dataValue[columnName]);
              }
            }
          }
        } else if (parts[0] === 'screenParameters') {
          var replaceVal = screenParameters[parts[1]];
          if (typeof replaceVal === 'string' || replaceVal instanceof String) {
            replaceVal = `"${replaceVal}"`
          }
          result = result.replace(dataPath, replaceVal);
        }
      } else {
        // const parts = newStr.split('.');
        const dataPathStr = newStr;
        const dataPath = new DataPath(dataPathStr);

        if (dataPath.isValid) {
          // const objectName = parts[1];
          // const columnName = parts[2];

          if (dataPath.rootObjectName === rowKey || (row && !uriCollections)) {
            // data = row;
            if (row) {
              result = result.replace(dataPathStr, row[dataPath.propertyName]);
            } else {
              console.log('data not defined');
            }
          } else {
            const data = uriCollections.find(c => c.name === dataPath.rootObjectName);
            if (data) {
              if (data.dataValues instanceof Array && data.dataValues.length === 1) {
                result = result.replace(dataPathStr, (data.dataValues[0])[dataPath.propertyName]);
              } else {
                result = result.replace(dataPathStr, data.dataValues[dataPath.propertyName]);
              }
            }
          }
        } else if (dataPathStr.startsWith('screenParameters.')) {
          const key = dataPathStr.split('.')[1];
          result = result.replace(dataPathStr, screenParameters[key]);
        }
      }
    }

    return result;
  }

  public static findIndex(value: string, startIndex: number, elements: string[]): number {
    let result: number = value.length;
    let index: number;

    for (const element of elements) {
      index = value.indexOf(element, startIndex);
      if (index > -1 && index < result) {
        result = index;
      }
    }

    return result;
  }

  public static parseTitle(title: TextArgument, data?: any[], parentData?: any[], screenParameters?: any, debugging?: boolean): string {

    if (!title) { return null; }
    return this.parseArgumentsFromData(title.text, title.argumentIds, data, parentData, screenParameters, debugging);
  }

  public static parseArgumentsFromData(valuePath: string, argumentValues: string[], data?: any[],
    parentData?: any[], screenParameters?: any, debugging?: boolean): string {

    let value: any;
    let values: any[] = [];

    if (argumentValues) {

      let index = 0;
      // let argumentName = '';
      for (const arg of argumentValues) {
        // if (arg.startsWith('=@date.now')) {
        //   if (op === +1) {
        //     newParameters[key] = moment(value).add(parameters[key].substring(i), 'day').toJSON();
        //   } else {
        //     newParameters[key] = moment(value).add(parameters[key].substring(i), 'day').toJSON();
        //   }

        //   here
        //   const split = arg.split(',');
        //   const val = split[1].substring(0, split[1].indexOf(')'));
        //   const num = Number.parseInt(val, 10);
        //   if (num) {
        //     value = new Date();
        //     value.setDate(value.getDate() + num);
        //     value = value.toJSON();
        //     continue;
        //   }
        // }
        if (arg.startsWith('=')) {
          let i = arg.indexOf('-');
          let op = -1;
          if (i < 0) {
            i = arg.indexOf('+');
            op = +1;
          }
          if (i < 0) {
            value = arg;
            values.push(arg);
            continue;
          }

          const paramValue = arg.substring(1, i);
          if (paramValue.startsWith('@date.now')) {
            value = new Date();
            values.push(new Date());
          }

          if (value) {

            if (value) {
              if (paramValue.startsWith('@date.now')) {

                // console.log('regex it!');
                // const reg = new RegExp('^=@date.now[+-][0-9]+[a-z]$');
                // const res = reg.exec(parameters[key]);
                // console.log(res);
                if (op === +1) {
                  value = moment(value).add(arg.substring(i), 'day').toJSON();
                  values.push(moment(value).add(arg.substring(i), 'day').toJSON());
                } else {
                  value = moment(value).add(arg.substring(i), 'day').toJSON();
                  values.push(moment(value).add(arg.substring(i), 'day').toJSON());
                }
              }
              continue;
            }
          }
        }

        if (arg.lastIndexOf('.') > -1) {
          const split = arg.split('.');
          switch (split[0].toLowerCase()) {
            case 'screenparameter':
            case 'screenparameters':
              const argumentName = arg.substring(arg.lastIndexOf('.') + 1);
              if (screenParameters && screenParameters[argumentName]) {
                value = screenParameters[argumentName];
                values.push(screenParameters[argumentName]);
              }
              break;
            case 'tag':
              value = data[`tag-${Utilities.getObjectName(arg)}`];
              values.push(data[`tag-${Utilities.getObjectName(arg)}`]);
              break;
            case '@date':
              if (split.length > 0 && split[1] === 'now') {
                value = new Date().toJSON();
                values.push(new Date().toJSON());
              }
              break;
            case 'data': {
              const dataPth = new DataPath(arg);

              let childObName: string;
              let arrayIndex = -1;

              if (!dataPth.childObjectNames) {
                console.log(`is ${dataPth} valid?`);
              }
              if (data && dataPth.childObjectNames && dataPth.childObjectNames.length > 0) {

                const arrayPositionIndex = dataPth.childObjectNames[0].indexOf('[');
                if (arrayPositionIndex > -1) {
                  childObName = dataPth.childObjectNames[0].substr(0, arrayPositionIndex);
                  const startIndex = arrayPositionIndex + 1;
                  const endIndex = dataPth.childObjectNames[0].indexOf(']');
                  arrayIndex = parseInt(dataPth.childObjectNames[0].substring(startIndex, endIndex), 10);
                } else {
                  childObName = dataPth.childObjectNames[0];
                }
              }

              if (data && dataPth.childObjectNames && dataPth.childObjectNames.length === 0 && data[dataPth.propertyName] !== undefined) {
                let newValue = data[dataPth.propertyName].toString();

                if (newValue) {

                  if (newValue.startsWith('string.')) {
                    newValue = newValue.substring(newValue.indexOf('.') + 1);
                  }

                  var isDate = moment(newValue, moment.ISO_8601, true).isValid();
                  if (isDate && !valuePath.match(new RegExp(/\{\d+:\w+\}/gm))) {
                    // newValue = newValue.toISOString().split('T')[0];
                    newValue = moment(newValue).format('DD/MM/YYYY, HH:mm');
                    // var check = moment(newValue, 'YYYY-MM-DD');
                    // var month = check.format('M');
                    // var day   = check.format('D');
                    // var year  = check.format('YYYY');


                  }
                  value = newValue;
                  values.push(newValue);
                }
              } else if (data && dataPth.childObjectNames && data[childObName] &&
                ((arrayIndex < 0 && data[childObName][dataPth.propertyName] !== undefined)
                  || (arrayIndex > -1 && data[childObName][arrayIndex] && data[childObName][arrayIndex][dataPth.propertyName] !== undefined))
              ) {

                let row: any;
                if (arrayIndex > -1) {
                  row = data[childObName][arrayIndex];
                } else {
                  row = data[childObName];
                }

                // console.log(row);
                if (row[dataPth.propertyName] !== undefined) {
                  let newValue = row[dataPth.propertyName].toString();

                  if (newValue) {

                    if (newValue.startsWith('string.')) {
                      newValue = newValue.substring(newValue.indexOf('.') + 1);
                    }

                    value = newValue;
                    values.push(newValue);
                  }
                }

              } else if (parentData !== null && parentData !== undefined && parentData[dataPth.propertyName] !== undefined) {

                value = parentData[dataPth.propertyName].toString();
                values.push(parentData[dataPth.propertyName].toString());
              } else {
                // deals also with the case of a preceding space
                value = '';
                values.push('');
              }
            }
              break;
            default:
              console.log(`can't parse ${arg}`);
              break;
          }
        } else {
          // fixed value
          value = arg;
          values.push(arg);
        }


        // if (valuePath) {
        //   if (value === '') {
        //     valuePath = valuePath.replace(` {${index}}`, '');
        //     valuePath = valuePath.replace(`{${index}}`, '');
        //   }
        //   valuePath = valuePath.replace(`{${index}}`, value);
        // } else {
        //   return value;
        // }

        index++;
      }
      
      return this.stringFormat(valuePath, values.map(v => v.toString()));
    }

    // Remove unused placeholder
    if (valuePath) {
      valuePath = valuePath.replace('{0}', '');
      valuePath = valuePath.replace('{1}', '');
      // valuePath = valuePath.replace('{2}', '');
      // valuePath = valuePath.replace('{3}', '');
      // valuePath = valuePath.replace('{4}', '');
      // valuePath = valuePath.replace('{5}', '');

      return valuePath;
    } else {
      return value;
    }
  }

  public static stringFormat(valuePath: any, values: string[]){
    
    let index = 0;

    if(!valuePath){
      return values[0];
    }

    for (const val in values) {
      if (valuePath) {
        // Get text to replace
        const regexp = new RegExp(`\{${index}(?::\\w+)?\\}`);
        var toReplace = (valuePath as string).match(regexp);
        if(toReplace){
          for (const part of toReplace) {
            var newValue = values[index];
            if(part.includes(':'))
            {
              var indexAndFormat = part.split(":");
              if(indexAndFormat.length == 2){
                try 
                {
                  //TODO support more format strings
                  switch (indexAndFormat[1].charAt(0)) 
                  {
                    case "f":
                      newValue = Number(values[index]).toFixed(Number(indexAndFormat[1].charAt(1)));
                      break;
                    case "c":
                      //TODO add currency symbol
                      newValue = Number(values[index]).toFixed(2);
                      break;
                    case "p":
                      newValue = `${(Number(values[index]) * 100).toFixed(0)}%`;
                      break;
                    case "d":
                      if(moment(values[index]).isValid()){
                        newValue = moment(values[index]).format("DD/MM/YYYY");
                      }
                      else{
                        newValue = values[index];
                      }
                      break;
                    default:
                      break;
                  }
                } catch (error) {
                    
                }
              }
            }
            //valuePath.replaceAll(` ${part}`, newValue);
            valuePath = valuePath.replaceAll(part, newValue);
          }
        }
        else{
          valuePath = valuePath.replaceAll(` {${index}}`, values[index]);
          valuePath = valuePath.replaceAll(`{${index}}`, values[index]);
        }
      } else {
        return val;
      }
  
      index++;
    }

    return valuePath;
    
  }

  public static getUriCollection(name: string, uriCollections?: UriCollection[]) {

    if (!name || name.length < 1) { return; }

    name = name.toString();

    if (name.lastIndexOf('.') > -1) {

      const objectName = this.getObjectName(name);
      if (uriCollections) {
        return uriCollections.find(c => c.name === objectName);
      }
    } else {

      const objectName = name;
      if (uriCollections) {
        return uriCollections.find(c => c.name === objectName);
      }
    }
  }

  public static getValueFromUriCollection(arg: string, uriCollections?: UriCollection[], data?: any[], screenParameters?: any) {

    if (!arg || arg.length < 1) { return; }

    arg = arg.toString();

    if (arg.lastIndexOf('.') > -1) {

      // argumentName = arg.substring(arg.lastIndexOf('.') + 1);

      if (arg.indexOf('screenParameters') >= 0) {

        const argumentName = this.getLastEntry(arg);
        if (screenParameters && screenParameters[argumentName]) {
          return screenParameters[argumentName];
        }
      } else {

        let done = false;

        const argumentName = this.getLastEntry(arg);
        if (data !== null && data !== undefined && data[argumentName] !== undefined) {

          let newValue = data[argumentName].toString();

          if (newValue) {

            if (newValue.startsWith('string.')) {
              newValue = newValue.substring(newValue.indexOf('.') + 1);
            }

            return newValue;
            done = true;
          }
        }
        if (!done) {
          const objectName = this.getObjectName(arg);
          if (uriCollections) {
            const coll = uriCollections.find(c => c.name.toLowerCase() === objectName.toLowerCase());
            if (coll) {

              let uriCollectionData = coll.dataValue;
              if (!uriCollectionData) {
                uriCollectionData = coll.dataValues;
                if (coll.dataValues instanceof Array && coll.dataValues.length === 1) {
                  uriCollectionData = coll.dataValues[0];
                }
              }

              if (uriCollectionData !== null && uriCollectionData !== undefined && uriCollectionData[argumentName] !== undefined) {

                let newValue = uriCollectionData[argumentName].toString();

                if (newValue) {

                  if (newValue.startsWith('string.')) {
                    newValue = newValue.substring(newValue.indexOf('.') + 1);
                  }

                  return newValue;
                }
              }
            }
          }
        }
      }
    } else {
      // fixed value
      return arg;
    }
  }

  private static getDataTypeFromUriCollection(arg: string, uriCollections?: UriCollection[], data?: any[], screenParameters?: any): DataType {

    if (!arg || arg.length < 1) { return; }

    arg = arg.toString();

    if (arg.lastIndexOf('.') > -1) {

      // argumentName = arg.substring(arg.lastIndexOf('.') + 1);

      if (arg.indexOf('screenParameters') >= 0) {
        return DataType.NVarChar;
      } else {

        const done = false;

        const argumentName = this.getLastEntry(arg);
        if (data !== null && data !== undefined && data[argumentName] !== undefined) {

          const newValue = data[argumentName].toString();

          if (newValue) {
            if (newValue.startsWith('string.')) {
              return DataType.NVarChar;
            }
          }
        }

        if (!done) {
          const objectName = this.getObjectName(arg);
          if (uriCollections) {
            const coll = uriCollections.find(c => c.name === objectName);
            if (coll) {

              // const data = coll.dataValues;
              if (coll.dataMetadata) {

                const metadatum = coll.dataMetadata.find(m => m.javaScriptName === this.getLastEntry(arg));
                return metadatum.dataType;
              }
            }
          }
        }
      }
    } else {
      // fixed value
      return DataType.NVarChar;
    }
  }

  public static getDataFromUriCollection(propertyPath: string, uriCollections: UriCollection[]): any {

    if (propertyPath === '' || propertyPath == null) {
      return null;
    }

    const dataPath = new DataPath(propertyPath);

    const coll = uriCollections.find(c => c.name === dataPath.rootObjectName);
    if (coll) {

      let uriData: any;
      if (coll.dataValue) {
        uriData = coll.dataValue;
      } else if (coll.dataValues && coll.dataValues[0]) {
        uriData = coll.dataValues[0];
      }

      if (uriData !== null && uriData !== undefined) {
        if (!dataPath.childObjectNames || dataPath.childObjectNames.length === 0) {
          return uriData[dataPath.propertyName];
        } else {
          return uriData[dataPath.childObjectNames[0]][dataPath.propertyName];
        }
      }
    }
  }

  public static getData(propertyPath: string, dataObject: any) {

    if (propertyPath == null || propertyPath.length === 0) { return; }

    const dataPath = new DataPath(propertyPath);
    if (dataObject !== null && dataObject !== undefined) {
      if (!dataPath.childObjectNames || dataPath.childObjectNames.length === 0) {
        return dataObject[dataPath.propertyName];
      } else {
        return dataObject[dataPath.childObjectNames[0]][dataPath.propertyName];
      }
    }
  }

  public static getOrCreateDataObject(propertyPath: string, rootDataObject: any) {

    if (propertyPath == null || propertyPath.length === 0) { return; }

    if (!rootDataObject) { rootDataObject = {}; }

    const dataPath = new DataPath(propertyPath);
    if (rootDataObject !== null && rootDataObject !== undefined) {
      if (!dataPath.childObjectNames || dataPath.childObjectNames.length === 0) {
        return rootDataObject;
      } else {
        if (!rootDataObject[dataPath.childObjectNames[0]]) {
          rootDataObject[dataPath.childObjectNames[0]] = {};
        }
        return rootDataObject[dataPath.childObjectNames[0]];
      }
    }
  }

  public static getOrCreateDataObjectArray(propertyPath: string, rootDataObject: any) {

    if (propertyPath == null || propertyPath.length === 0) { return; }

    if (!rootDataObject) { rootDataObject = {}; }

    const dataPath = new DataPath(propertyPath);
    if (rootDataObject !== null && rootDataObject !== undefined) {
      if (!dataPath.childObjectNames || dataPath.childObjectNames.length === 0) {
        return rootDataObject;
      } else {
        if (!rootDataObject[dataPath.childObjectNames[0]]) {
          rootDataObject[dataPath.childObjectNames[0]] = [];
        }
        return rootDataObject[dataPath.childObjectNames[0]];
      }
    }
  }

  public static setDataToUriCollection(propertyPath: string, uriCollections: UriCollection[], value: any): any {

    const dataPath = new DataPath(propertyPath);

    const coll = uriCollections.find(c => c.name === dataPath.rootObjectName);
    if (coll) {

      let uriData: any;
      if (coll.dataValue) {
        uriData = coll.dataValue;
      } else if (coll.dataValues && coll.dataValues[0]) {
        uriData = coll.dataValues[0];
      }

      if (uriData !== null && uriData !== undefined) {
        if (!dataPath.childObjectNames || dataPath.childObjectNames.length === 0) {
          uriData[dataPath.propertyName] = value;
        } else {
          uriData[dataPath.childObjectNames[0]][dataPath.propertyName] = value;
        }
      }
    }
  }

  public static parseArgumentsFromUriCollection(value: string, argumentValues: string[], uriCollections?: UriCollection[],
    data?: any[], screenParameters?: any, debugging?: boolean): string {

    if (!value) { value = '{0}'; }

    if (argumentValues) {

      let index = 0;
      // let argumentName: string = '';
      for (const arg of argumentValues) {

        if (arg.lastIndexOf('.') > -1) {

          // argumentName = arg.substring(arg.lastIndexOf('.') + 1);

          if (arg.startsWith('screenParameter')) {

            const argumentName = this.getLastEntry(arg);
            if (screenParameters && screenParameters[argumentName]) {
              value = value.replace(`{${index}}`, screenParameters[argumentName]);
            }
            //  else if (requiredScreenParameters) {
            //   const item = requiredScreenParameters.find(s => s.key === argumentName);

            //   if (item) {
            //     value = value.replace(`{${index}}`, item.default);
            //   }
            // }
          } else if (arg.startsWith('tag.')) {
            value = data[`tag-${Utilities.getObjectName(arg)}`];
          } else {

            let done = false;
            const argumentName = this.getLastEntry(arg);

            if (data !== null && data !== undefined && data[argumentName] !== undefined) {

              let newValue = data[argumentName].toString();

              if (newValue) {

                if (newValue.startsWith('string.')) {
                  newValue = newValue.substring(newValue.indexOf('.') + 1);
                }

                value = value.replace(`{${index}}`, newValue);
                done = true;
              }
            }
            if (!done) {
              const dataPath = new DataPath(arg);

              const coll = uriCollections.find(c => c.name === dataPath.rootObjectName);
              if (coll) {

                let uriData;
                if (coll.dataValue) {
                  uriData = coll.dataValue;
                } else if (coll.dataValues && coll.dataValues[0]) {
                  uriData = coll.dataValues[0];
                }

                if (uriData !== null && uriData !== undefined) {
                  //  && uriData[argumentName] !== undefined) {
                  let newValue;
                  if ((!dataPath.childObjectNames || dataPath.childObjectNames.length === 0)) {
                    newValue = uriData[dataPath.propertyName];
                  } else if (uriData[dataPath.childObjectNames[0]]) {
                    var currentValue = uriData;
                    dataPath.childObjectNames.forEach(pathPart => {
                      if (typeof currentValue === 'string' || currentValue instanceof String){
                        var convertedObj = JSON.parse(currentValue.toString());
                        if(convertedObj && convertedObj[pathPart]){
                          currentValue = convertedObj[pathPart];
                        }
                      }
                      else if(currentValue[pathPart]){
                        currentValue = currentValue[pathPart];
                      }
                    });
                    newValue = currentValue[dataPath.propertyName];
                  }

                  // must use !=null otherwise it doesn't pick up when we have a value of 0
                  if (newValue != null) {

                    if (newValue.toString().startsWith('string.')) {
                      newValue = newValue.substring(newValue.indexOf('.') + 1);
                    }

                    value = value.toString().replace(`{${index}}`, newValue);
                  }
                }
              }
              // else if (parentData !== null && parentData !== undefined && parentData[argumentName] !== undefined) {

              //   value = value.replace(`{${index}}`, parentData[argumentName].toString());
              // }
            }
          }
        } else {
          // fixed value
          value = value.toString().replace(`{${index}}`, arg);
        }

        index++;
      }

    }

    // Remove unused placeholder
    value = value.toString().replace('{0}', '');

    return value;
  }

  public static getFirstEntry(value: string) {

    return value.substring(0, value.indexOf('.'));

  }

  /**
   * returns last part of a dot separated path
   * @param value input path
   */
  public static getLastEntry(value: string) {

    if (value) {
      const parts = value.split('.');
      return parts[parts.length - 1];
    } else {
      return null;
    }

    // if (text.startsWith('data.')) {
    //   let parts = text.split('.');
    //   return parts[1];
    // }

    // if (value == null)
    //   return null;
    // let index = value.lastIndexOf('.') + 1;
    // if (index > -1) {
    //   value = value.substring(index);
    // }

    // return value;
  }

  // public static getObjectName(text: string) {

  //   if (text.startsWith('data.')) {
  //     text = text.substring(5); // remove data
  //     return text.substring(0, text.indexOf('.'));
  //   }

  //   return '';
  // }

  public static getObjectName(text: string) {

    if (text.startsWith('data.')) {
      const parts = text.split('.');
      // return 2nd to last entry
      return parts[parts.length - 2];
    }
    if (text.startsWith('tag.')) {
      const parts = text.split('.');
      return parts[1];
    }
    return '';
  }

  public static getObjectNameFromDataUri(uri: string) {
    if (!uri) {
      return;
    }
    const parts = uri.split('/');
    const dataIndex = parts.findIndex(p => p === 'data' || p === 'odata');
    if (dataIndex > -1 && parts[dataIndex + 1]) {
      return parts[dataIndex + 1];
    }
  }

  public static getAttributeName(name: string, argumentIds: string) {

    let result = argumentIds;
    const index = argumentIds.indexOf(name);

    if (index >= 0) {
      result = argumentIds.substr(index + name.length + 1);
    }

    return result;
  }

  public static uniqueId() {
    return this.randomNumber(1000000, 9000000).toString();
  }

  public static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static generateRowFromDefaults(newObjectDefaults: any, row: any, screenParameters: any, rowDataKey?: string, uriCollections?: UriCollection[], configService?: ConfigUtilitiesService): any {

    const newItem: any = JSON.parse(JSON.stringify(newObjectDefaults));

    for (const key in newItem) {

      if (newItem[key] === '@date.now') {
        newItem[key] = new Date();
      } 
      else if (newItem[key] === '@workerId')
      {
        newItem[key] = sessionStorage.getItem("workerId");
      }
      else if (newItem[key].startsWith('@flow.')){
        var flowParts = newItem[key].split('.');
        var selectedFlow = FlowService.flows.find(f => f.name == flowParts[1])
        newItem[key] = selectedFlow.ftFlowID;
      }
      else if (newItem[key].startsWith('data.')) {

        if (row && !rowDataKey || this.getObjectName(newItem[key]) === rowDataKey) {
          newItem[key] = row[Utilities.getLastEntry(newItem[key])];

          if (newItem[key] && newItem[key].value) {
            newItem[key] = newItem[key].value;
          } else if (newItem[key] && newItem[key].name === '') {
            // If value / name object make sure item is cleared if newItem[key] value not set
            newItem[key] = '';
          }
        } else {
          newItem[key] = this.getValueFromUriCollection(newItem[key], uriCollections, row, screenParameters);
        }
      } else if (row && newItem[key].startsWith('dragParameters.')) {

        newItem[key] = row[Utilities.getLastEntry(newItem[key])];
      } else if (newItem[key].startsWith('screenParameters.')) {

        if (screenParameters) {
          newItem[key] = screenParameters[Utilities.getLastEntry(newItem[key])];

          if (row && newItem[key] && newItem[key].length > 5 && newItem[key].startsWith('data.')) {
            newItem[key] = row[Utilities.getLastEntry(newItem[key])];
          }
        } else {
          // Remove unused screenParameter
          // Not sure why we are getting this!
          delete newItem[key];
        }
      } else if (row && newItem[key].startsWith('tag.')) {
        newItem[key] = row[`tag-${Utilities.getLastEntry(newItem[key].replace('.value', ''))}`];
      
      
      } else if (newItem[key].startsWith('config.')) {
        var configPrefix = 'config.';
        var configPath = newItem[key].substring(configPrefix.length);
        newItem[key] = configService.GetConfigValue(configPath);
      } else {  
        if (row && row[newItem[key]]) {
          newItem[key] = row[newItem[key]];
        }
      }

    }

    // Clear undefined data entries
    for (const key in newItem) {

      if (newItem[key] && newItem[key].toString().startsWith('data.')) {
        newItem[key] = '';
      }

    }
    return newItem;
  }

  public static addItemsToRow(newRow: any, row: any): any {

    for (const key in row) {

      if (newRow[row[key]]) {
        newRow[key] = row[row[key]];
      }
    }

    return newRow;

  }

  public static addClass(value: string, className: string): string {

    let result: string = value;

    if (!result) {
      result = className;
    } else if (result.indexOf(className) === -1) {

      if (result.length > 0) {
        result += ',';
      }

      result += className;
    }

    return result;
  }

  public static findInArray(key, value, myArray) {

    for (const item of myArray) {
      if (item[key] == value) {
        return item;
      }
    }
  }

  public static log(value: any) {

    if (this.consoleDebug) {
      console.log(value);
    }
  }

  public static log2(source: string, value: any, type?: 'warn' | 'error') {

    switch (type) {
      case 'warn':
        console.warn(source + ': ' + value);
        break;
      case 'error':
        console.error(source + ': ' + value);
        break;
      default:
        if (this.consoleDebug) { console.log(source + ': ' + value); }
        break;
    }
  }

  public static log2f(source: string, value: any, type?: string) {

    switch (type) {
      case 'warn':
        console.warn(source + ': ' + value);
        break;
      case 'error':
        console.error(source + ': ' + value);
        break;
      default:
        console.log(source + ': ' + value);
        break;
    }
  }

  public static round(value: number, decimals: number) {
    return value.toFixed(decimals);
  }

  public static createPostItemFromData(newObjectDefaults: any, itemDictionary: any, newItem: any, uriCollections: UriCollection[], screenParameters: any): any {

    if (!newObjectDefaults) { return; }

    const defaults = Utilities.copy(newObjectDefaults);
    Object.keys(defaults).forEach(function (key) {
      const split = defaults[key].split('.');
      let value;
      switch (split[0].toLowerCase()) {
        case 'screenparameter':
        case 'screenparameters':
          value = screenParameters[split[1]];
          break;
        case 'data':
          const dataPath = new DataPath(defaults[key]);
          const item = itemDictionary[dataPath.rootObjectName];
          value = Utilities.getValueFromUriCollection(defaults[key], uriCollections, item, screenParameters);
          if (!value) {
            value = item[this.getLastEntry(defaults[key])];
          }
          if (!value && newItem) {
            value = newItem[this.getLastEntry(defaults[key])];
          }
          break;
        case 'ftflow':
          const flow = newItem as FTFlowStatus;
          value = flow.ftFlowID;
          break;
        case 'ftflowstatus':
          const flow1 = newItem as FTFlowStatus;
          value = flow1.ftFlowStatusID;
          break;
        // case 'tag':
        //   try {
        //     value = item[`tag-${Utilities.getObjectName(defaults[key])}`];
        //   } catch {
        //     value = '';
        //   }
        //   break;
        // case 'dragparameters':
        //   value = item[Utilities.getLastEntry(defaults[key])];
        //   break;
        case '@date':
          if (split.length > 0 && split[1] === 'now') {
            value = new Date();
          }
          break;
        case 'screen':
          // TODO need to handle the argument
          delete defaults[key];
          break;
        default:
          value = defaults[key];
      }

      // let value = Utilities.getValueFromUriCollection(defaults[key], this.uriCollections, this.item, this.screenParameters);

      if (value) {
        defaults[key] = value;
      }
    }, this);

    return defaults;
  }

  public static createItemFromObjectDefaults(newObjectDefaults: any, item: any, newItem: any, uriCollections: UriCollection[], screenParameters: any): any {

    if (!newObjectDefaults) { return item; }

    const defaults = Utilities.copy(newObjectDefaults);
    Object.keys(defaults).forEach(function (key) {
      let value;
      try{
        const split = defaults[key].split('.');
        switch (split[0].toLowerCase()) {
          case 'screenparameter':
          case 'screenparameters':
            value = screenParameters[split[1]];
            break;
          case 'data':
            value = Utilities.getValueFromUriCollection(defaults[key], uriCollections, item, screenParameters);
            if (!value) {
              value = item[this.getLastEntry(defaults[key])];
            }
            if (!value && newItem) {
              value = newItem[this.getLastEntry(defaults[key])];
            }
            defaults[key] = value;
            break;
          case 'ftflow':
            const flow = newItem as FTFlowStatus;
            value = flow.ftFlowID;
            break;
          case 'ftflowstatus':
            const flow1 = newItem as FTFlowStatus;
            value = flow1.ftFlowStatusID;
            break;
          case 'tag':
            try {
              value = item[`tag-${Utilities.getObjectName(defaults[key])}`];
            } catch {
              value = '';
            }
            break;
          case 'dragparameters':
            value = item[Utilities.getLastEntry(defaults[key])];
            break;
          case '@date':
            if (split.length > 0 && split[1] === 'now') {
              value = new Date();
            }
            break;
          case '@flow':
            if (split.length > 0){
              var selectedFlow = FlowService.flows.find(f => f.name == split[1])
              newItem[key] = selectedFlow.ftFlowID;
            }
          case 'screen':
            // TODO need to handle the argument
            delete defaults[key];
            break;
          default:
            value = defaults[key];
        }
      }
      catch{
        value = defaults[key];
      }

      // let value = Utilities.getValueFromUriCollection(defaults[key], this.uriCollections, this.item, this.screenParameters);

      if (value) {
        defaults[key] = value;
      }
    }, this);

    return defaults;
  }

  /**
   * Returns the data item from an array
   * @param array
   * @param path Can be the full dot-separated path, or the item key
   */
  public static getValueFromArray(array: any[], path: string): any {
    if (path && array) {
      const key = this.getLastEntry(path);
      return array[key];
    }
  }
  
  public static getPrimaryKey(dataMetadata: FTThingDetail[], dataValue: any) {
    var primaryKeyMetadata = dataMetadata.find(md => md.primaryKey);
    return dataValue[primaryKeyMetadata.javaScriptName];
  }
}
