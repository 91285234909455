import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { ContextMenuService, ContextMenuComponent } from 'ngx-contextmenu';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { FlowService } from '../../../../services/flow/flow.service';

import { FormTemplateItem } from '../../../../models/screenModels/TemplateItem';
import { Action } from '../../../../models/actions/Action';
import { MenuItem } from '../../../../models/data/MenuItem';
import { DataDetail } from 'src/app/models/DataDetail';
import { ActionIdentifier, MenuType } from 'src/app/models/Enums';
import { ScreenColumn } from 'src/app/models/ScreenColumn';

@Component({
  selector: 'app-price-scheme',
  templateUrl: './price-scheme.component.html',
  styleUrls: ['./price-scheme.component.scss']
})
export class PriceSchemeComponent implements OnInit {

  menuType = MenuType;
  schemeIdField: string;
  schemeTextField: string;
  // schemeIsDefaultField: string;

  _templateItem: FormTemplateItem;
  _defaultPriceSchemeId: any;

  @Input() priceSchemeList: DataDetail;
  @Input() productPriceSchemePriceBand: any;
  @Input() priceSummaryDataDetails: DataDetail;
  @Input() priceSummaryTemplate: FormTemplateItem;

  //TODO these are just for 'add' and should be screenParameters
  @Input() priceBand: any;
  @Input() product: any;
  @Input() screenParameters: any; 
  @Output() menuClick = new EventEmitter<any>();

  schemeDetailsAction: Action;
  schemeDetails: any[];
  durationUnit: string;
  isDefault: boolean; // not needed?
  defaultVisibility: string;

  @ViewChild(ContextMenuComponent) public schemeMenu: ContextMenuComponent
  dateTemplate: ScreenColumn;

  get defaultPriceSchemeId(): any {
    return this._defaultPriceSchemeId;
  }
  @Input()
  set defaultPriceSchemeId(value: any) {
    this._defaultPriceSchemeId = value;
    if (this.priceScheme) {
      this.isDefault = this._defaultPriceSchemeId == this.priceScheme.priceSchemeID;
      this.defaultVisibility = this.isDefault ? 'visible' : 'hidden';
    }
  }

  get priceScheme(): any {
    return this.priceSchemeList.dataItems.find(s => s.priceSchemeID === this.productPriceSchemePriceBand.priceSchemeID);
  }

  get productPriceSchemePriceBandTemplate(): FormTemplateItem {
    return this._templateItem;
  }

  get priceSummaries(): any[] {

    Utilities.log2('PriceScheme', 'Getprices for productPriceSchemePriceBand ' + this.productPriceSchemePriceBand.productPriceSchemePriceBandID);
    const ret = this.priceSummaryDataDetails.dataItems.filter(m =>
      m.productPriceSchemePriceBandID === this.productPriceSchemePriceBand.productPriceSchemePriceBandID);
    Utilities.log(JSON.stringify(ret));
    return ret;
  }

  @Input()
  set productPriceSchemePriceBandTemplate(value: FormTemplateItem) {

    Utilities.log2('PriceScheme', 'set template item');
    this._templateItem = value;

    if (value) {

      // priceSchemeId
      this.schemeIdField = Utilities.getLastEntry(this.productPriceSchemePriceBandTemplate.templateItem[1].dataPath);

      // displayName
      this.schemeTextField = Utilities.getLastEntry(this.productPriceSchemePriceBandTemplate.templateItem[1].template[1].text.argumentIds[0]);

      // For now manully setting isDefault
      // this.schemeIsDefaultField = 'isDefault';

      // Action for scheme details
      this.schemeDetailsAction = this.productPriceSchemePriceBandTemplate.templateItem[1].action[0];

      this.loadSchemeDetails();
    }
  }

  
  constructor(public toastr: ToastrService, private thingService: ThingService, private contextMenuService: ContextMenuService) { }

  ngOnInit() {

    if (this.priceScheme) {
      let lookupDetail = FlowService.getLookupDetail('Duration', this.priceScheme.priceSchemeID);

      if (lookupDetail) {
        this.durationUnit = lookupDetail.text;
      }

      // TODO we should be able to do this by evaluating the condition
      // let isDefaultTemplate = this.templateItem.templateItem.find(t => t.tag === 'defaultColour');
      // this.isDefault = Utilities.evaluateCondition(isDefaultTemplate.isVisibleCondition, this.);
      this.isDefault = this.screenParameters['defaultPriceSchemeId'] === this.priceScheme.priceSchemeID;
      this.defaultVisibility = this.isDefault ? 'visible' : 'hidden';
    }

    this.dateTemplate = this.productPriceSchemePriceBandTemplate.templateItem.find(t=>t.tag==='dates');
  }

  loadSchemeDetails() {

    Utilities.log2('PriceScheme', 'loadSchemeDetails');
    if (this.priceScheme) {

      let action = Utilities.parseAction(this.schemeDetailsAction, this.priceScheme);

      Utilities.log2('PriceScheme - loadSchemeDetails', action.actionArgument.dataUri);

      this.thingService.getDataDetail(action.actionArgument.dataUri)
        .subscribe(result => this.onGetDetailSuccessful(result), error => this.onGetDetailFailed(error));

    }
    else {
      this.schemeDetails = null;
    }

  }

  private onGetDetailSuccessful(result: any) {

    Utilities.log2('PriceScheme - onGetDetailSuccessful', 'onGetDetailSuccessful');
    this.schemeDetails = result;

    // now need to set up some template price items based on priceSchemeType and if there any priceschemedetail items set up for this price scheme
    // tiered by quantity || tiered by duration
    if (this.priceScheme.priceSchemeType == 2 || this.priceScheme.priceSchemeType == 3) {
      // add a new template price for each price that doesn't already exist

      let priceSchemeDetailIds = this.schemeDetails.map(function (v) { return v.priceSchemeDetailID });

      priceSchemeDetailIds.forEach(psd => {
        if (this.priceSummaries.find(p => p.priceSchemeDetailID == psd) == null) {
          Utilities.log2('PriceScheme - onGetDetailSuccessful', psd + ' not found so adding a template');
          this.addPriceTemplate(psd);
        }
        else {
          Utilities.log2('PriceScheme - onGetDetailSuccessful', psd + ' found');
        }
      });

      // this.prices.forEach(price => {
      //   if (price.find(x => priceSchemeDetailIds.find(x.priceSchemeDetailID)) == null) {
      //     Utilities.log2('PriceScheme - onGetDetailSuccessful', price.priceSchemeDetailID + ' not found so adding a template');
      //     // let price: any = {
      //     //   productPriceSchemePriceBandID: this.selectedSchemeId,
      //     //   productID: this.product.productID,
      //     //   priceBandID: this.priceBand.priceBandID
      //     //   // startDate: TODO,
      //     //   // endDate: TODO,
      //     // }
      //   }
      //   else {
      //     Utilities.log2('PriceScheme - onGetDetailSuccessful', price.priceSchemeDetailID + ' found');
      //   }
      // });

    }
    else {
      if (this.priceSummaries.length == 0) {
        Utilities.log2('PriceScheme - onGetDetailSuccessful', 'No price found for scheme type so adding a line');
        this.addPriceTemplate();
      }
      else {
        Utilities.log2('PriceScheme - onGetDetailSuccessful', 'Found a price');
        Utilities.log(JSON.stringify(this.priceSummaryDataDetails.dataItems));
      }

      // // add a new template price line
      // let price: any = {
      //   productPriceSchemePriceBandID: this.selectedSchemeId,
      //   productID: this.product.productID,
      //   priceBandID: this.priceBand.priceBandID
      //   // startDate: TODO,
      //   // endDate: TODO,
      // }
      // this.priceSummaryDataDetail.dataItems.push(price);
    }
  }

  private addPriceTemplate(priceSchemeDetailID?: number) {
    let price: any = {
      productPriceSchemePriceBandID: this.productPriceSchemePriceBand.productPriceSchemePriceBandID,
      priceAmountDurationUnit: 0,
      priceAmountFixed: 0,
      priceSchemeDetailID: priceSchemeDetailID,
      priceSchemeID: this.priceScheme.priceSchemeID,
      priceBandID: this.priceBand.priceBandID,
      productID: this.product.productID

      // startDate: TODO,
      // endDate: TODO,
    }

    this.priceSummaryDataDetails.dataItems.push(price);
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  isVisible(menuItem: MenuItem): boolean {

    if (menuItem.visibleCondition === undefined) {
      return true;
    }

    if (this.priceScheme) {
      return Utilities.evaluate(menuItem.visibleCondition, this.priceScheme);
    }

    return true;
  }

  // Show context menu on left mouse click
  onContextMenu($event: MouseEvent, item: any) {

    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: this.schemeMenu,
      event: $event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();

  }

  onContextExecute(menuItem: MenuItem) {

    this.menuClick.emit({ item: menuItem, row: this.productPriceSchemePriceBand });
  }

  get debug(): String {

    return JSON.stringify(this.priceSummaryDataDetails.dataItems);

  }

  getSchemeDetail(price: any): any {

    if (this.schemeDetails) {
      return this.schemeDetails.find(m => m.priceSchemeID == price.priceSchemeID && m.priceSchemeDetailID == price.priceSchemeDetailID);
    }
    else {
      Utilities.log2('PriceScheme', 'no scheme details found :(');
    }
  }
}
