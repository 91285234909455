import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SummaryCardModel } from 'src/app/models/cardModels/SummaryCardModel';
import { CardItem } from 'src/app/models/cardModels/CardItem';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { ActionURI } from '../../../../models/data/ActionURI';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { DataDetail } from '../../../../models/DataDetail';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';

@Component({
  selector: 'app-order-total',
  templateUrl: './order-total.component.html',
  styleUrls: ['./order-total.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderTotalComponent implements OnInit {

  loadingIndicator = true;
  @Input() uri: ActionURI;
  @Input() loadScreenDetail = true;
  @Input() screenParameters: any;
  @Input() uriCollections?: UriCollection[] = [];

  columns: FTThingDetail[];
  dataDetail: DataDetail;
  _screenDetail: ScreenDetail;

  cardItem: CardItem;
  cardItemObject: SummaryCardModel;

  detail1: string;
  detail2: string;
  title: string;
  dataItem: any[];
  // cardTemplate: import('c:/dev-git/Qmolo.Web.Customer/src/app/models/data/CardItemObject').CardItemObject;

  public get height(): number {
    if (!this._screenDetail) { return 0; }
    if (this.cardItem && this.cardItemObject.detail1 && this.cardItemObject.detail2) {
      return 64;
    } else {
      return 49;
    }
  }


  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {
  }

  get screenDetail(): ScreenDetail {
    return this._screenDetail;
  }

  @Input()
  set screenDetail(value: ScreenDetail) {

    this._screenDetail = value;

    if (!this.loadScreenDetail && this.screenDetail) {

      this.thingService.getData(this.uri.metaUri, this.uri.dataUri, 0, 0, null, null, null, null)
        .subscribe(result => this.getDataDetailWithHeaderSuccessful(this.uri.name, result[1], result[0]), error => this.onGetDetailFailed(error));

    }
  }

  private getDataDetailWithHeaderSuccessful(name: string, dataDetail: DataDetail, columns?: FTThingDetail[], screenDetailComponents?: ScreenDetail) {

    let uriCollection = this.uriCollections.find(u => u.name === name);
    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail.dataItems };
      this.uriCollections.push(uriCollection);
    }

    if (columns) {
      uriCollection.dataMetadata = columns;
    }

    uriCollection.dataValues = dataDetail.dataItems;

    this.dataDetail = dataDetail;

    if (columns) {
      this.columns = columns;
    }

    if (screenDetailComponents) {
      this.screenDetail = screenDetailComponents.componentScreens[0];
      // this.cardTemplate = this.screenDetail.cardItems[0].cardItemObject;
    }

    if (this.dataDetail.dataItems && this.dataDetail.dataItems.length === 1) {
      this.dataItem = this.dataDetail.dataItems[0];

      if (this.screenDetail && this.screenDetail.cardItems && this.screenDetail.cardItems.length > 0) {

        this.cardItem = this.screenDetail.cardItems[0];
        this.cardItemObject = this.cardItem.cardItemObject as SummaryCardModel;

        if (this.cardItemObject) {

          if (this.cardItemObject.detail1) {
            this.detail1 = Utilities.parseArgumentsFromData(this.cardItemObject.detail1.text.text,
              this.cardItemObject.detail1.text.argumentIds, this.dataItem, null, null);
          }
          if (this.cardItemObject.detail2) {
            this.detail2 = Utilities.parseArgumentsFromData(this.cardItemObject.detail2.text.text,
              this.cardItemObject.detail2.text.argumentIds, this.dataItem, null, null);
          }
          if (this.cardItemObject.title) {
            this.title = Utilities.parseArgumentsFromData(this.cardItemObject.title.text.text,
              this.cardItemObject.title.text.argumentIds, this.dataItem, null, null);
          }
        }
      }
    }

    this.loadingIndicator = false;
  }

  private onGetDetailFailed(error: any) {
    this.loadingIndicator = false;
  }

  loadData() {

    this.loadingIndicator = true;

    this.thingService.getDataDetailWithHeader(this.uri.dataUri)
      .subscribe(result => this.getDataDetailWithHeaderSuccessful(this.uri.name, result, this.columns), error => this.onGetDetailFailed(error));

  }

  public hasElement(elementName: string) {
    return true;
    // return (this.cardTemplate[elementName]) ? true : false;
  }

  private getIcon(elementName: string): string {

    const status = this.cardItemObject[elementName];
    if (status && status.icon && this.dataItem) {
      return Utilities.evaluate(status.icon.iconArgument, this.dataItem, Utilities.evaluate(status.isCheckedCondition, this.dataItem));
    }
    return '';
  }

  public isVisible(elementName: string): boolean {

    const status = this.cardItemObject[elementName];
    if (status && status.isVisibleCondition && this.dataItem) {
      return Utilities.evaluate(status.isVisibleCondition, this.dataItem);
    }

    return true;
  }

  public onActionClick(event, elementName, isChecked?: boolean) {
    let action;
    if (isChecked != null) {
      // this.cardTemplate[elementName] = isChecked;
      if (isChecked) {
        action = this.cardItemObject[elementName].onCheckedAction;
      } else {
        action = this.cardItemObject[elementName].onUncheckedAction;
      }
    } else {
      action = this.cardItemObject[elementName].action;
    }

    const eventEmitter = new EventEmitter<ActionCompletedEvent>();
    eventEmitter.subscribe((ev: ActionCompletedEvent) => this.updateComplete(ev.isComplete));
    const actionService = new ActionBuilder(action, this.dataItem, this.dataItem, this.uriCollections, this.screenParameters, eventEmitter,
      null, this.thingService, this.toastr, null);
    actionService.PerformAction();
    // this.onAction.emit({ event: event, dataItem: dataItem, action: action });
  }

  private updateComplete(complete: boolean) {
    const uriCollection = this.uriCollections.find(u => u.name === this.cardItem.dataObjectKey);
    // lockStatus is undefined??
    uriCollection.dataValues = [this.dataItem];
  }

  public isChecked(elementName: string): boolean {

    const element = this.cardItemObject[elementName];
    if (element && element.isCheckedCondition && this.dataItem) {
      return Utilities.evaluate(element.isCheckedCondition, this.dataItem);
    }
    return true;
  }

  // public getTitle(row: any): string {

  //   if (this.cardTemplate.title && this.cardTemplate.title.text.argumentIds) {
  //     return Utilities.parseArgumentsFromData(this.cardTemplate.title.text.text, this.cardTemplate.title.text.argumentIds, row, null, null);
  //   }

  // }

  // public getDetail1(row: any): string {

  //   if (this.cardTemplate.detail1 && this.cardTemplate.detail1.text.argumentIds) {
  //     return Utilities.parseArgumentsFromData(this.cardTemplate.detail1.text.text, this.cardTemplate.detail1.text.argumentIds, row, null, null);
  //   }

  //   return '';
  // }

  // public getDetail2(row: any): string {

  //   if (this.cardTemplate.detail2 && this.cardTemplate.detail2.text.argumentIds) {
  //     return Utilities.parseArgumentsFromData(this.cardTemplate.detail2.text.text, this.cardTemplate.detail2.text.argumentIds, row, null, null);
  //   }
  //   return '';
  // }
}
