import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { Action } from 'src/app/models/actions/Action';
import { TimeLineCardModel } from 'src/app/models/cardModels/timeLineCardModel';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { TimeLine } from 'src/app/models/timeline/TimeLine';
import { IconService } from 'src/app/services/icon/icon.service';
import { ThingService } from 'src/app/services/thing/thing.service';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'app-view-timeline',
  templateUrl: './view-timeline.component.html',
  styleUrls: ['./view-timeline.component.scss']
})
export class ViewTimelineComponent implements OnInit, AfterViewInit {
  private _linkedRow: any;
  _action: Action;
  subtitle: string;
  hideAll = false;
  itemsReversed = false;

  @Input() id: string;
  @Input() height: number;
  @Input() width: number;

  @Output() title: EventEmitter<string> =  new EventEmitter<string>();

  uriCollections: any[] = [];

  screenDetailComponents: ScreenDetail;
  timeLineItems: TimeLine[] = [];
  largestWidth: number = 0;
  interval: any;

  get action(): Action {
    return this._action;
  }
  @Input()
  set action(value: Action) {
    if (value) {
      this._action = value;
      this.loadData();
    }
  }

  get linkedRow(): any {
    return this._linkedRow;
  }

  @Input()
  set linkedRow(value: any) {

    if (value !== null) {

      this._linkedRow = value;
    }
  }

  constructor(public toastr: ToastrService,
    private thingService: ThingService,
    private iconHelper: IconHelper,
    private iconService: IconService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.interval = setInterval(() => {
      this.getLargestWidth();
    }, 100)
  }

  loadData() {
    this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
      .subscribe(results => this.onGetDetailSuccessful(results), error => this.onGetDetailFailed(error));
  }

  getLargestWidth() {
    if (!!this.timeLineItems && this.timeLineItems.length > 0) {
      for (let i = 0; i < this.timeLineItems.length; i++) {
        const width = document.getElementById(`${i}`).offsetWidth;
        if (document.getElementById(`${i}`).offsetWidth > this.largestWidth) {
          this.largestWidth = width;
        }
      }
      for (let i = 0; i < this.timeLineItems.length; i++) {
        document.getElementById(`${i}`).style.width = `${this.largestWidth}px`;
        document.getElementById(`${i}`).style.marginRight = `-${this.largestWidth*0.13}px`;
      }
      clearInterval(this.interval);
    }
  }

  private onGetDetailSuccessful(screenDetailComponents: ScreenDetail) {

    console.log(screenDetailComponents);
    this.screenDetailComponents = screenDetailComponents;

    var cardItem = this.screenDetailComponents.componentScreens[0].cardItems[0].cardItemObject as TimeLineCardModel;

    // this.thingService.getDataDetail(uri.dataUri)

    for (const uri of this.action.actionArgument.uris) {

      if (uri.dataUri) {
        uri.dataUri = Utilities.parseArgumentsFromData(uri.dataUri, uri.dataUriArgs, this.linkedRow);
      }
      if (!this.uriCollections.find(u => u.name === uri.name)) {
        this.uriCollections.push(uri);
      }
    }

    var screenParameters = Utilities.setScreenParameters(this.action.actionArgument.screenParameters,
      screenDetailComponents ? screenDetailComponents.requiredScreenParameters : null, this.linkedRow, this.uriCollections);

      this.thingService.getData(this.uriCollections[0].metaUri, this.uriCollections[0].dataUri, 0, null, null, null, null, null)
      .subscribe(result => {
        for (const dataItem of result[1].dataItems)
        {
          var cardColor = this.getCardColor(cardItem.status1.icon.colour, dataItem);

          var timeLineItem = new TimeLine();
            timeLineItem.title = Utilities.parseArgumentsFromData(null, cardItem.title.text.argumentIds, null, dataItem, null),
            timeLineItem.date = Utilities.parseArgumentsFromData(null, cardItem.date.text.argumentIds, null, dataItem, null),
            timeLineItem.detail1 = Utilities.parseArgumentsFromData(null, cardItem.detail1.text.argumentIds, null, dataItem, null),
            timeLineItem.icon = Utilities.evaluate(cardItem.status1.icon.iconArgument, dataItem);
            timeLineItem.colour = cardColor === "#ffffff" ? "#a4b9ca" : cardColor;
            timeLineItem.detail2 = Utilities.parseArgumentsFromData(null, cardItem.detail2.text.argumentIds, null, dataItem, null),
            timeLineItem.detail3 = Utilities.parseArgumentsFromData(null, cardItem.detail3.text.argumentIds, null, dataItem, null)
            //timeLineItem.detail3 = "Location Text";
            timeLineItem.expanded = true;
          console.log(timeLineItem);
            this.timeLineItems.push(timeLineItem);
        }
      }, error => this.onGetDetailFailed(error));

    this.title.emit(Utilities.parseTitle(this.screenDetailComponents.screenTitle, this.linkedRow, null, screenParameters));
    this.subtitle = Utilities.parseTitle(this.screenDetailComponents.screenSubtitle, this.linkedRow, null, screenParameters);
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  getCardColor(colour: any, dataItem: any): string {

    const property = Utilities.getLastEntry(colour);
    if (dataItem[property]) {

      if (dataItem[property].length > 0) {
        return `${dataItem[property]}`;
      }
    }
    return '';
  }

  getDateDifference(index: number)
  {
    if (this.timeLineItems.length > index+1)
    {
      return this.getDateDifferenceText(Date.parse(this.timeLineItems[index+1].date), Date.parse(this.timeLineItems[index].date));
    }
    return;
  }

  private getDateDifferenceText(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    //greater than a day in ms
    if (diffInMs >= 86400000)
    {
      var days = Math.round(diffInMs / (1000 * 60 * 60 * 24))

      return days === 1 ? "1 day" : `${Math.round(diffInMs / (1000 * 60 * 60 * 24))} days`;
    }
    //greater than or equal to an hour in ms
    else if(diffInMs >= 3600000)  {
      var hours = Math.floor(diffInMs / (1000 * 60 * 24));

      var msInHours = hours * 1000 * 60 * 24;
      var hourText = "hours"
      if (hours === 1)
      {
        hourText = "hour";
      }

      var msInMinutesExludingHours = diffInMs - msInHours;

      var minutes = Math.round(msInMinutesExludingHours / (1000 * 60));

      return `${hours} ${hourText}, ${minutes} ${minutes === 1 ? "1 minute" : minutes + "minutes"}`;
    }
    //greater or equal to a minute
    else if (diffInMs >= 60000)
    {
      var minutes = Math.floor(diffInMs / (1000 * 60));

      var msInMinutes = minutes * 1000 * 60;
      
      var msInSecondsExcludingMinutes = diffInMs - msInMinutes;

      var seconds = Math.round(msInSecondsExcludingMinutes / 1000);

      return `${minutes} minutes, ${seconds} seconds`;
    }
    else {
      var seconds = Math.round(diffInMs / 1000);
      return seconds === 1 ? `1 second` : `${Math.round(diffInMs / 1000)} seconds`;
    }
  }

  isEven(index: number)
  {
    return ((index+1) % 2 == 0);
  }

  getIcon(id: any)
  {
    var iconHex = this.iconHelper.getFontAwesomeHex(id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  toggleExpansionForItem(index: number)
  {
    this.timeLineItems[index].expanded = !this.timeLineItems[index].expanded;
  }

  toggleAllItems()
  {
    this.hideAll = !this.hideAll;
    this.timeLineItems.forEach(x => x.expanded = !this.hideAll);
  }

  reverseItems()
  {
    this.itemsReversed = !this.itemsReversed;
    this.timeLineItems.reverse();
  }
}
