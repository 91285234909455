import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Action } from 'src/app/models/actions/Action';
import { ItemListComponent } from '../../lists/item-list/item-list.component';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { Utilities } from 'src/app/services/utilities/utilities';
import { ThingService } from 'src/app/services/thing/thing.service';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ToastrService } from 'ngx-toastr';
import { TreeItemSelectedEvent } from 'src/app/models/events/TreeItemSelectedEvent';
import { MenuNode } from 'src/app/models/menu-tree/MenuNode';
import { ActionIdentifier, ScreenType } from 'src/app/models/Enums';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { DataDetail } from 'src/app/models/DataDetail';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { ListDetail } from 'src/app/models/ListDetail';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';

@Component({
  selector: 'app-product-catalogue',
  templateUrl: './product-catalogue.component.html',
  styleUrls: ['./product-catalogue.component.scss', './../nav-bar-styles.scss']
})
export class ProductCatalogueComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() action: Action;
  @Input() height;
  loadComplete = false;
  checkoutScreenDetailComponents: ScreenDetail;
  get width() { return window.innerWidth; }

  @Input() breadcrumbListDetails: ListDetail[];
  @Output() navigationBack = new EventEmitter<any>();
  @Output() navigationSelect = new EventEmitter<ListDetail>();

  uriCollections: UriCollection[] = [];
  showCategory = true;
  treeWidth = 300;

  screenDetailComponents: ScreenDetail;
  screenParameters: any;
  title: string;
  orderUriCollection: UriCollection;
  orderItemUriCollection: UriCollection;
  subtitle: string;
  productClassUriCollection: UriCollection;
  productItemUriCollection: UriCollection;
  screenDetailProductList: ScreenDetail;
  screenDetailCategoryList: ScreenDetail;

  isCheckout = false;

  get basketItemCount(): number {
    if (this.orderItemUriCollection && this.orderItemUriCollection.dataValues && this.orderItemUriCollection.dataValues.length > 0) {
      if (this.screenDetailProductList.listCount && this.screenDetailProductList.listCount.startsWith('sum')) {
        const vals = this.orderItemUriCollection.dataValues.map(v => v[Utilities.getLastEntry(this.screenDetailProductList.listCount)]);
        if (vals.length > 0) {
          return vals.reduce((total, num) => total + num);
        } else { return 0; }
      }
      return this.orderItemUriCollection.dataValues.length;// > 0 ? this.orderItemUriCollection.dataValues.length : '';
    } else { return 0; }
  }

  @ViewChild('productItems') productItems: ItemListComponent;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {
    this.loadInitialData();
  }

  loadInitialData() {

    for (const uri of this.action.actionArgument.uris) {
      const actionUri: ActionURI = JSON.parse(JSON.stringify(uri));
      if (!this.uriCollections.find(u => u.name === uri.name)) {
        if (actionUri.dataUri) {
          actionUri.dataUri = Utilities.parseArgumentsFromData(actionUri.dataUri, actionUri.dataUriArgs, null, null);
        }
        this.uriCollections.push({ name: uri.name, uris: actionUri, dataValues: [], });
      }
    }
    this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
      .subscribe(result => this.onGetDetailSuccessful(result), error => this.onGetDetailFailed(error));
  }

  private onGetDetailSuccessful(screenDetailComponents: ScreenDetail) {

    this.screenDetailComponents = screenDetailComponents;

    this.screenDetailCategoryList = this.screenDetailComponents.componentScreens.find(s => s.screenType === ScreenType.TreeView);
    this.screenDetailProductList = this.screenDetailComponents.componentScreens.find(s => s.screenType === ScreenType.SwitchContentView);
    this.checkoutScreenDetailComponents = this.screenDetailComponents.componentScreens.find(s => s.screenTag === 'productCatalogueCheckout');

    if (this.screenDetailCategoryList) {
      this.productClassUriCollection = this.uriCollections.find(u => u.name === this.screenDetailCategoryList.treeViewItems[0].dataObjectKey);
    }
    if (this.screenDetailProductList) {
      this.productItemUriCollection = this.uriCollections.find(u => u.name === this.screenDetailProductList.screenProperties['dataObjectKey']);
    }

    this.screenParameters = this.action.actionArgument.screenParameters;
    this.screenParameters = Utilities.setScreenParameters(this.screenParameters, this.screenDetailComponents.requiredScreenParameters,
      null, this.uriCollections);

    if (this.screenDetailComponents.screenTitle) {
      this.title = Utilities.parseArgumentsFromUriCollection(this.screenDetailComponents.screenTitle.text,
        this.screenDetailComponents.screenTitle.argumentIds, this.uriCollections, null, this.action.actionArgument.screenParameters);
    }

    this.orderUriCollection = this.uriCollections.find(u => u.name === this.screenDetailComponents.screenProperties['orderUriKey']);
    this.orderItemUriCollection = this.uriCollections.find(u => u.name === this.screenDetailComponents.screenProperties['orderItemUriKey']);

    const initialLoadData = this.uriCollections.filter(u => u.uris.loadAtStart);
    if (initialLoadData && initialLoadData.length > 0) {
      for (let i = 0; i < initialLoadData.length; i++) {
        const u = initialLoadData[i];
        this.thingService.getData(u.uris.metaUri, u.uris.dataUri)
          .pipe(takeUntil(this.destroy$))
          .subscribe(results => this.getDataDetailSuccessful(u.name, results[1], results[0], i === initialLoadData.length - 1), error => console.log(error));
      }
    } else {
      this.setUpRootOrder();
    }
  }

  private getDataDetailSuccessful(name: string, dataDetail: DataDetail, columns: FTThingDetail[], setUpRootOrder: boolean) {

    let uriCollection = this.uriCollections.find(u => u.name === name);

    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail.dataItems };
      this.uriCollections.push(uriCollection);
    }

    uriCollection.dataValues = dataDetail.dataItems;
    if (columns) {
      uriCollection.dataMetadata = columns;
    } else {
      if (name.startsWith('data.')) {
        // console.log(`Incorrect refresh data object format ${name}`);
        name = name.substring(5);
      }
      if (name === this.productItemUriCollection.name) {
        this.productItems.refresh();
      }
    }

    if (setUpRootOrder) {
      if (this.screenDetailComponents.screenSubtitle) {
        this.subtitle = Utilities.parseArgumentsFromUriCollection(this.screenDetailComponents.screenSubtitle.text,
          this.screenDetailComponents.screenSubtitle.argumentIds, this.uriCollections, null, this.action.actionArgument.screenParameters);
      }
      this.setUpRootOrder();
    }
  }

  setUpRootOrder() {

    if (!this.orderUriCollection.dataValues || this.orderUriCollection.dataValues.length === 0) {
      this.createNewOrder();
    } else {
      this.orderUriCollection.dataValue = this.orderItemUriCollection.dataValues[0];
    }
  }

  createNewOrder() {
    this.thingService.getData(this.orderUriCollection.uris.metaUri, this.orderUriCollection.uris.dataUri)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetOrderSuccessful(results), error => console.log(error));
  }

  onGetOrderSuccessful(results: [FTThingDetail[], DataDetail]): void {
    this.orderUriCollection.dataMetadata = results[0];
    this.orderUriCollection.dataValues = results[1].dataItems;
    if (this.orderUriCollection.dataValues && this.orderUriCollection.dataValues.length > 0) {
      this.orderUriCollection.dataValue = this.orderUriCollection.dataValues[0];

      this.orderItemUriCollection.uris.dataUri = Utilities.parseArgumentsFromUriCollection(this.orderItemUriCollection.uris.originalDataUri,
        this.orderItemUriCollection.uris.dataUriArgs, this.uriCollections);
      this.thingService.getData(this.orderItemUriCollection.uris.metaUri, this.orderItemUriCollection.uris.dataUri)
        .pipe(takeUntil(this.destroy$))
        .subscribe(orderItemResult => this.onGetOrderItemsSuccessful(orderItemResult), error => console.log(error));
    }
  }

  onGetOrderItemsSuccessful(results: [FTThingDetail[], DataDetail]): void {
    this.orderItemUriCollection.dataMetadata = results[0];
    this.orderItemUriCollection.dataValues = results[1].dataItems;

    this.loadComplete = true;
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public onTreeItemSelected(value: TreeItemSelectedEvent) {

    const id: string = value.id;
    const actionList: Action[] = value.actionList;
    const menuNodes: MenuNode[] = value.data;

    actionList.forEach(action => {
      if (action.action === ActionIdentifier.ReloadData) {

        let filter = '';

        if (action.actionArgument.targetDataObject === this.productItemUriCollection.name) {

          for (const node of menuNodes) {

            if (filter !== '') {
              filter += ' or ';
            }

            filter += `${id} eq ${node.id}`;
          }
          this.productItems.setFilter(filter);
        }
      }
    });
  }

  onShowCategory() {
    this.showCategory = !this.showCategory;
    this.treeWidth = this.showCategory ? 300 : 40;
    // setTimeout(() => {
    //   this.basketItems.updateWidth();
    // });
  }

  onBack() {
    this.navigationBack.emit();
  }

  onSelect(listDetail: ListDetail) {
    this.navigationSelect.emit(listDetail);
  }

  onUpdateCompleted(event: UpdateCompletedEvent) {

    if (event.resultTargetDataObject) {
      event.resultTargetDataObject.split(',').forEach(targetOb => {
        const uriColl = this.uriCollections.find(u => u.name === targetOb);
        if (targetOb === event.currentItemPath) {
          // TODO should merge
          uriColl.dataValues.push(event.row);
        } else {
          this.thingService.getDataDetailWithHeader(uriColl.uris.dataUri)
            .pipe(takeUntil(this.destroy$))
            .subscribe(results => this.getDataDetailSuccessful(uriColl.name, results, null, false), error => console.log(error));
        }
      });
    } else {
      // console.log('onUpdateCompleted');
    }
  }

  onExitCheckout(finish: boolean) {
    this.isCheckout = false;
    if (finish) {
      this.onBack();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkoutItems() {
    this.isCheckout = true;
  }
}
