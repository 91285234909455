import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventType } from './entities/eventType';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { User } from './entities/user';
import { AddEventDialogComponent } from '../../dialogs/add-event-dialog/add-event-dialog.component';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { ActionURI } from 'src/app/models/data/ActionURI';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { DataDetail } from 'src/app/models/DataDetail';
import { LoadDataEvent } from 'src/app/models/events/LoadDataEvent';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { Utilities } from 'src/app/services/utilities/utilities';
import { Action } from 'src/app/models/actions/Action';
import { ThingService } from 'src/app/services/thing/thing.service';
import { ToastrService } from 'ngx-toastr';
import { ScreenDetail } from 'src/app/models/ScreenDetail';
import { CardItem } from 'src/app/models/cardModels/CardItem';
import { ItemClickEvent } from 'src/app/models/events/ItemClickEvent';
import { ActionIdentifier, ScreenType } from 'src/app/models/Enums';
import { GraphDialogComponent } from '../../dialogs/graph-dialog/graph-dialog.component';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { UpdateScreenParametersAction } from 'src/app/models/actions/UpdateScreenParametersAction';
import { ViewDetailsDialogComponent } from '../../dialogs/view-details-dialog/view-details-dialog.component';
import { ActionEvent } from 'src/app/models/events/ActionEvent';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { PostResponse } from 'src/app/models/PostResponse';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { UpdateScreenDataEvent } from 'src/app/models/events/UpdateScreenDataEvent';
import { WorkingWeekConfig, WorkingWeekValue } from 'src/app/models/calendar/WorkingWeekConfig';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { MenuNode } from 'src/app/models/menu-tree/MenuNode';
import { FlowService } from 'src/app/services/flow/flow.service';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { IconService } from 'src/app/services/icon/icon.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { DataUtils } from 'src/app/services/data-utils/data-utils';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-base-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './base-calendar.component.html',
  styleUrls: ['./base-calendar.component.scss']
})
export class BaseCalendarComponent implements OnInit {

  private SELECTED_DATE_TIME_SCREEN_PARAMETER = "startDate";

  destroy$: Subject<boolean> = new Subject<boolean>();
  public uriCollections?: UriCollection[] = [];
  dataDetail: DataDetail;
  dataItems: any[];
  filterUri: ActionURI;
  customFilter = '';
  searchValue: string;
  pageSize: number = 25;
  metadata: FTThingDetail[];
  pageIndex = 0;
  filterActive: boolean = false;

  loadingIndicator = true;

  @Input() action: Action;
  @Input() row: object[];
  @Input() customId: any;
  @Input() idName: string; // Used to track name being used for primary key, when deleting an item
  @Input()
  public set triggerRefresh(value: boolean) {
    if (value) {
      this.refreshData();
      this.triggerRefreshChange.emit(false);
    }
  }
  @Output() triggerRefreshChange = new EventEmitter<boolean>();


  updateIndicator = false;

  @Output() onAction = new EventEmitter<ActionEvent>();
  @Output() onAdd = new EventEmitter<AddUpdateItemModel>();
  @Output() onViewItem = new EventEmitter<ViewDetailEvent>();
  @Output() onDisplaySwitchContentDialog = new EventEmitter<any>();
  @Output() onUpdateCompleted = new EventEmitter<UpdateCompletedEvent>();
  @Output() onDeleteCompleted = new EventEmitter<DeleteEvent>();
  @Output() updateScreenData = new EventEmitter<UpdateScreenDataEvent>();

  view: CalendarView = CalendarView.Month;

  eventForm: FormGroup;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  userId: number;
  selectedItem: any;
  contextRow: any;
  contextItem: any;
  statusMenus: MenuNode[] = [];
  obj: any;

  eventApprovalActions: Array<string> = new Array<string>("Accept", "Reject", "Awaiting Approval")

  eventTypes: Array<EventType> = new Array<EventType>();

  modalAddEventData: {
    day: any;
  }

  refresh = new Subject<void>();

  dayStartHourScroll: number = 0;// 9;

  eventsForUser: Array<any>;

  allEventsApproval: Array<CalendarEvent> = new Array<CalendarEvent>();
  eventsAwaitingApproval: Array<CalendarEvent> = new Array<CalendarEvent>();
  rejectedEvents: Array<CalendarEvent> = new Array<CalendarEvent>();
  acceptedEvents: Array<CalendarEvent> = new Array<CalendarEvent>();

  activeDayIsOpen: boolean = false;

  users: User[];
  workingWeek: WorkingWeekConfig;
  weekendDays: any;
  @Input() screenDetailComponents: ScreenDetail;
  defaultItem: CardItem;
  monthItem: CardItem;
  weekItem: CardItem;
  dayItem: CardItem;
  itemCategories: CardItem;
  screenParameters: any = {};
  toolbarItems: any[] & MenuItem[];
  monthTemplate: any;
  weekTemplate: any;
  dayTemplate: any;
  primaryEventCategories: Array<any> = [];
  categories: Array<any> = [];
  showFilter: boolean = false;
  enabledParentIds: Array<number>;
  enabledFilterStringKVP: Array<any> = [];

  
  currentMode: string;
  publicDataSource: string;
  managerDataSource: string;
  privateDataSource: string;
  showDataSourceSelect: boolean;

  @ViewChildren(ContextMenuComponent) contextMenus: QueryList<ContextMenuComponent>;

  constructor(
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    private contextMenuService: ContextMenuService,
    private thingService: ThingService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private iconHelper: IconHelper,
    private iconService: IconService,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    this.screenParameters = this.action.actionArgument.screenParameters;

    if (!this.screenDetailComponents) {
      this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
        .subscribe(result => {
          this.screenDetailComponents = result; // Property update will call screenDetailSetup & loadUriData
          this.onReceiveScreenDetails();

        }, error => this.onGetDetailFailed(error));
    }
    else {
      this.onReceiveScreenDetails();
    }

    this.loadUriData(false);
    this.eventTypes = this.route.snapshot.data.eventTypes;

    this.thingService.getWorkingWeekConfig().subscribe(x => {
      let workingWeekValueObj: WorkingWeekValue = new WorkingWeekValue(x[0].value);
      x[0].value = workingWeekValueObj;

      this.workingWeek = x[0];
      // this.workingWeek.value = this.workingWeek.value.replace('\\', '');
      console.log(this.workingWeek.value);
      this.cdr.detectChanges();

      switch (this.workingWeek.value.StartOfWorkingWeek) {
        case 1:
          this.dayStartHourScroll = +this.workingWeek.value.Monday.OpeningTime.split(":")[0] - 2;
          break;
        case 2:
          this.dayStartHourScroll = +this.workingWeek.value.Tuesday.OpeningTime.split(":")[0] - 2;
          break;
        case 3:
          this.dayStartHourScroll = +this.workingWeek.value.Wednesday.OpeningTime.split(":")[0] - 2;
          break;
        case 4:
          this.dayStartHourScroll = +this.workingWeek.value.Thursday.OpeningTime.split(":")[0] - 2;
          break;
        case 5:
          this.dayStartHourScroll = +this.workingWeek.value.Friday.OpeningTime.split(":")[0] - 2;
          break;
        case 6:
          this.dayStartHourScroll = +this.workingWeek.value.Saturday.OpeningTime.split(":")[0] - 2;
          break;
        case 7:
          this.dayStartHourScroll = +this.workingWeek.value.Sunday.OpeningTime.split(":")[0] - 2;
          break;
        default:
          this.dayStartHourScroll = +this.workingWeek.value.Monday.OpeningTime.split(":")[0] - 2;
          break;
      }
    })

    this.eventForm = this.formBuilder.group({
      title: ['', Validators.required],
      eventType: ['', Validators.required],
      subEventType: ['', Validators.required],
      description: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  setupMultipleDataSources(){
    this.publicDataSource = this.screenParameters["public"];
    this.managerDataSource = this.screenParameters["manager"];
    this.privateDataSource = this.screenParameters["private"];
    
    var dataSources = 0;
    if (this.publicDataSource){
      dataSources++;
    }
    if (this.managerDataSource){
      dataSources++;
    }
    if (this.privateDataSource){
      dataSources++;
    }
    this.showDataSourceSelect = dataSources > 1;

    this.currentMode = this.screenParameters["defaultDataSource"];
    if(!this.currentMode){
      if (this.publicDataSource){
        this.changeDataSource("public");
      }
      else if (this.managerDataSource){
        this.changeDataSource("manager");
      }
      else if (this.privateDataSource){
        this.changeDataSource("private");
      }
      else{
        this.changeDataSource();
      }
    }
    this.changeDataSource();
  }

  changeDataSource(newMode?: string){
    if(newMode){
      this.currentMode = newMode;
    }

    this.dataItems = [];
    switch (this.currentMode) {
      case "public":
        var publicCollection = this.uriCollections.find(coll => coll.name == this.publicDataSource);
        this.setUriCollectionAsDataSource(publicCollection, true);
        break;
      case "manager":
        var managerCollection = this.uriCollections.find(coll => coll.name == this.managerDataSource);
        this.setUriCollectionAsDataSource(managerCollection, true);
        break;
      case "private":
        var privateCollection = this.uriCollections.find(coll => coll.name == this.privateDataSource);
        this.setUriCollectionAsDataSource(privateCollection, true);
        break;
      default:
        var defaultCollection = this.uriCollections.find(coll => coll.name == this.publicDataSource || coll.name == this.managerDataSource || coll.name == this.privateDataSource);
        this.setUriCollectionAsDataSource(defaultCollection, true);
        break;
    }
  }

  setUriCollectionAsDataSource(collection: UriCollection, refreshData: boolean){
    this.metadata = collection.dataMetadata;
    if(refreshData){
      this.thingService.getDataDetailWithHeader(collection.uris.dataUri.toString(), this.pageIndex, 100, null,
      null, this.customFilter, this.searchValue, null, null, [], null)
      .subscribe(result => {
        this.getDataDetailWithHeaderSuccessful(collection.uris.name, false, result);
      }, error => this.onGetDetailFailed(error));
    }
    else{
      this.dataItems = collection.dataValues;
    }

  }

  onReceiveScreenDetails() {
    if (this.screenDetailComponents.componentScreens) {
      this.screenDetailComponents.componentScreens[0].screenTitle = this.screenDetailComponents.screenTitle;
      this.screenDetailComponents.componentScreens[0].toolbarItems = this.screenDetailComponents.toolbarItems;
      this.screenDetailComponents = this.screenDetailComponents.componentScreens[0];
    }
    this.defaultItem = this.screenDetailComponents.cardItems[0];

    this.monthItem = this.screenDetailComponents.cardItems.find(i => i.tag == "month");
    this.monthTemplate = this.monthItem ? this.monthItem.cardItemObject : this.defaultItem.cardItemObject;

    this.weekItem = this.screenDetailComponents.cardItems.find(i => i.tag == "week");
    this.weekTemplate = this.weekItem ? this.weekItem.cardItemObject : this.defaultItem.cardItemObject;

    this.dayItem = this.screenDetailComponents.cardItems.find(i => i.tag == "day");
    this.dayTemplate = this.dayItem ? this.dayItem.cardItemObject : this.defaultItem.cardItemObject;

    this.cdr.detectChanges();

    this.toolbarItems = new Array();
    this.screenDetailComponents.toolbarItems.forEach(toolbarItem => {
      if (toolbarItem.visibleCondition) {
        const visible = Utilities.evaluateCondition(toolbarItem.visibleCondition, this.uriCollections, this.screenParameters);
        if (visible) {
          this.toolbarItems.push(toolbarItem);
        }
      }
      else {
        this.toolbarItems.push(toolbarItem);
      }
    });

    console.log(this.toolbarItems);
  }


  get f() { return this.eventForm.controls; }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.dataItems = this.dataItems.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.onSelect(event);
  }

  handleEvent(action: string, event: any): void {
    this.selectedItem = event;

    if (this.defaultItem.onClickAction) {
      this.onItemClick({ action: this.defaultItem.onClickAction, row: this.selectedItem });
    }
  }

  onSelect(item: any) {
    this.selectedItem = item;

    if (this.defaultItem.onClickAction) {
      this.onItemClick({ action: this.defaultItem.onClickAction, row: this.selectedItem });
    }
  }

  hexToRgbA(hex) {
    if (!hex) {
      hex = '#0d6efd';
    }
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', 0.2)';
    }
    throw new Error('Bad Hex');
  }

  addEventDay(day: any) {
    console.log("add event clicked");
    this.modalAddEventData = { day };
  }

  getRequestedUser(event: any) {
    if (!!this.users) {
      return this.users.find(x => x.id == event.userId).firstName;
    }
    return;
  }

  accept(event: any) {
    var eventBeingEdited = this.allEventsApproval.indexOf(event);

    event.approvedYN = true;
    event.actionDate = new Date();
  }

  reject(event: any) {
    var eventBeingEdited = this.allEventsApproval.indexOf(event);
    event.approvedYN = false;
    event.actionDate = new Date();
  }

  awaitingApproval(event: any) {
    var eventBeingEdited = this.allEventsApproval.indexOf(event);
    event.approvedYN = undefined;
    event.actionDate = undefined;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getStatus(approvedYN?: boolean) {
    if (approvedYN == undefined) {
      return 'Awaiting approval';
    }
    return approvedYN ? 'Approved' : 'Rejected';
  }

  acceptRejectEvent(value: any, event: any) {
    switch (value) {
      case "Accept":
        this.accept(event);
        break;
      case "Reject":
        this.reject(event);
        break;
      case "Awaiting Approval":
        this.awaitingApproval(event);
        break;
    }
  }

  getBackgroundColour(colour: string) {
    return !colour ? '#0d6efd' : colour;
  }

  getOpacity(isDraft: boolean) {
    return isDraft ? 0.5 : 1;
  }

  isStartDay(startDate: Date, day: any) {
    return (startDate.getDate() === day.date.getDate() || day.day === this.workingWeek.value.StartOfWorkingWeek);
  }

  loadUriData(includeScreenDetail: boolean) {

    DataUtils.setUriCollections(this.thingService, this.action.actionArgument.uris, null, this.action.screenParameters)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetUriCollectionsSuccessful(results), error => this.onGetDataFailed(error));

    return;
  }

  applyFilter(){

    let uriCollection: UriCollection;

    switch (this.currentMode) {
      case "public":
        uriCollection = this.uriCollections.find(coll => coll.name == this.publicDataSource);
        break;
      case "manager":
        uriCollection = this.uriCollections.find(coll => coll.name == this.managerDataSource);
        break;
      case "private":
        uriCollection = this.uriCollections.find(coll => coll.name == this.privateDataSource);
        break;
      default:
        uriCollection = this.uriCollections.find(coll => coll.name == this.publicDataSource || coll.name == this.managerDataSource || coll.name == this.privateDataSource);
        break;
    }

    if (uriCollection) {

      this.filterUri = uriCollection.uris;

      this.thingService.getFTThingDetail(this.filterUri.metaUri)
        .subscribe(result => {
          this.metadata = result;
        });

      if (this.filterUri.dataUri) {

        this.thingService.getData(this.filterUri.metaUri, this.filterUri.dataUri, 0, 25, null, null, this.customFilter, this.searchValue)
            .subscribe(result => {
              this.getDataDetailWithHeaderSuccessful(this.filterUri.name, false, result[1], result[0]);
              //this.onLoadData({ pageIndex: this.pageIndex, pageSize: this.pageSize });
            }, error => this.onGetDetailFailed(error));
            
      }
    } else {

      Utilities.log(JSON.stringify(this.action));
      Utilities.log('uris not present');
    }
  }

  private onGetUriCollectionsSuccessful(uc: UriCollection[]){
    this.uriCollections = uc;
    if(!this.currentMode){
      this.setupMultipleDataSources();
    }
  }

  private onGetDataFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public onLoadData(event: LoadDataEvent) {

    this.loadingIndicator = true;

    let filter = this.customFilter;
    let tags: string[] = [];

    const dataUri = this.filterUri.dataUri.toString();

    this.thingService.getDataDetailWithHeader(this.filterUri.dataUri.toString(), this.pageIndex, 100, null,
      null, filter, this.searchValue, null, null, tags, event.customParams)
      .subscribe(result => {
        this.getDataDetailWithHeaderSuccessful(this.filterUri.name, false, result);
      }, error => this.onGetDetailFailed(error));
  }

  private getDataDetailWithHeaderSuccessful(name: string, paging: boolean, dataDetail: DataDetail, columns?: FTThingDetail[]) {

    let uriCollection;

    uriCollection = this.uriCollections.find(collection => collection.name == name);

    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail ? dataDetail.dataItems : null };
      this.uriCollections.push(uriCollection);
    }

    if (columns) {
      uriCollection.dataMetadata = columns;
    }

    if (!!dataDetail) {

      this.dataDetail = dataDetail;
      uriCollection.dataValues = this.dataDetail.dataItems;

      if (!!this.dataDetail.dataItems) {
        for (let i = 0; i < this.dataDetail.dataItems.length; i++) {
          if (!!this.dataDetail.dataItems[i].startDate) {
            this.dataDetail.dataItems[i].startDate = new Date(this.dataDetail.dataItems[i].startDate);
          }
          if (!!this.dataDetail.dataItems[i].endDate) {
            this.dataDetail.dataItems[i].endDate = new Date(this.dataDetail.dataItems[i].endDate!);
          }
          if (Math.floor((this.dataDetail.dataItems[i].endDate - this.dataDetail.dataItems[i].startDate) / (1000 * 60 * 60 * 24)) >= 1) {
            this.dataDetail.dataItems[i].allDay = true;
          }
        }
      }

      if (columns) {
        this.metadata = columns;
      }

      this.dataItems = this.dataDetail.dataItems;
      this.obj = [];
      this.primaryEventCategories = [];
      this.dataItems.forEach((e, i) => (i = e.ftCalendarItemCategoryID as number, this.obj[i + 1] ? this.obj[i + 1].push(e) : (this.obj[i + 1] = [e])));
      this.obj = this.obj.filter(function (element) {
        return element !== undefined;
      });

      for (var i = 0; i < this.obj.length; i++) {
        if (!!this.obj[i] && this.obj[i].length > 0) {
          var key = this.obj[i][0].ftCalendarItemCategoryID;
          var values = new Array<any>();
          this.obj[i].forEach(x => !x.filter1 || !!values.find(y => y.filter1 == x.filter1) ? null : values.push({ filter1: x.filter1, checked: false }));
          if (!this.categories[i]) {
            this.categories[i] = { key: key, name: this.obj[i][0].name, values: values, checked: true };
          }
          this.primaryEventCategories.push({ key: key, name: this.obj[i][0].name, values: values });
        }
        else {
          if (!this.categories[i]) {
            this.categories[i] = undefined;
          }
        }
      }
      if (!this.enabledParentIds && this.primaryEventCategories.length > 0) {
        this.enabledParentIds = [];
        this.primaryEventCategories.forEach(x => this.enabledParentIds.push(x.key));
      }

      console.log(this.categories);
      var allDataItems = _.cloneDeep(this.dataItems);
      var parentItems = allDataItems.filter(x => this.enabledParentIds.includes(x.ftCalendarItemCategoryID));
      var filterStrings = this.enabledFilterStringKVP.map(y => y.filterString);
      var childFilterItems = allDataItems.filter(x => filterStrings.includes(x.filter1));

      //Now remove the items belonging to the parent that has a child checked
      for (let i = 0; i < this.enabledFilterStringKVP.length; i++) {
        parentItems = parentItems.filter(x => x.ftCalendarItemCategoryID != this.enabledFilterStringKVP[i].id);
      }
      this.dataItems = childFilterItems.concat(parentItems);
      this.dataItems.map(item => {
        item.start = item.startDate;
        item.end = item.endDate;
      })

      console.log(this.dataItems);

      this.buildContextMenus();
    }

    this.cdr.detectChanges();
  }

  public convertDateToUTC(date: Date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  private onGetDetailFailed(error: any) {
    setTimeout(() =>
      this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
        null, { closeButton: true, tapToDismiss: true }));
  }

  private filterParentEvent(id: number, checked: boolean) {
    if (checked) {
      this.enabledParentIds.push(id);
    }
    else {
      this.categories.forEach(x => {
        if (x.key == id) {
          x.values.forEach(z => z.checked = false);
        }
      })
      this.enabledParentIds = this.enabledParentIds.filter(x => x != id);
      this.enabledFilterStringKVP = this.enabledFilterStringKVP.filter(x => x.id != id);
    }
    this.applyFilter();
  }

  private filterEvents(id: number, filterString: string) {
    if (!!this.enabledFilterStringKVP.find(x => x.id == id && x.filterString == filterString)) {
      this.enabledFilterStringKVP = this.enabledFilterStringKVP.filter(x => x.filterString != filterString)
    }
    else {
      this.enabledFilterStringKVP.push({ id: id, filterString: filterString });
    }
    this.applyFilter();
  }

  // checkCategory(categoryId: number) {
  //   this.categories.forEach(x => {
  //       if (x.key == categoryId) {
  //           x.checked = !x.checked;
  //       }
  //   });
  // }

  public logDay(day: any) {
    // console.log(day);
  }

  public onItemClick(event: ItemClickEvent) {

    console.log(event);

    if (!event.action) {
      console.log('NO ACTION SUPPLIED');
      return;
    }

    event.action.forEach(actionObj => {
      let action: Action = Utilities.parseAction(actionObj, event.row, this.screenParameters);
      if (!action) { return; }

      const run = Utilities.evaluateCondition(action.condition, null, this.screenParameters, null, event.row);
      if (!run) {
        return;
      }

      Utilities.log(JSON.stringify(action));

      if (action.action === ActionIdentifier.DisplayDialog && action.actionArgument.name === 'MovementHistorySummaryChart') {

        // parse action is not taking into account uri collections
        action = event.action[0];
        const screenParameters = Utilities.setScreenParameters(action.actionArgument.screenParameters,
          this.screenDetailComponents.requiredScreenParameters, event.row, this.uriCollections);
        const dialogRef = this.dialog.open(GraphDialogComponent, {
          minWidth: '800px',
          data: {
            screenParameters: screenParameters,
            action: action
          }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
      } else if (action.action === ActionIdentifier.LaunchScreen) {

        const launchScreenAction = action.actionArgument as LaunchScreenAction;
        if (launchScreenAction.screenType === ScreenType.EditDetails) {
          this.onAdd.emit({ id: launchScreenAction.name, action: launchScreenAction, newItem: true, screenParameters: this.screenParameters, row: null });
        } else {
          this.onAction.emit({ action: action, row: event.row, newItem: event.newItem });
        }
      } else if (action.action === ActionIdentifier.LaunchFlyout) {
        this.onViewItem.emit({ row: event.row, action: action, length: 1 });
      } else if (action.action === ActionIdentifier.DisplayDialog) {

        const actionArgument = action.actionArgument as LaunchScreenAction;
        if (actionArgument.screenType === ScreenType.ViewDetails || actionArgument.screenType === ScreenType.CompositeScreen) {

          const newSParams = Utilities.setScreenParameters(actionArgument.screenParameters,
            this.screenDetailComponents.requiredScreenParameters, event.row, this.uriCollections);

          const dialogRef = this.dialog.open(ViewDetailsDialogComponent, {
            width: '600px',
            data: { action: action, row: event.row, screenParameters: newSParams }
          });

          dialogRef.afterClosed().subscribe(result => {
            Utilities.log('View Details Dialog closed');
          });

        } else if (actionArgument.screenType === ScreenType.SwitchContentView) {

          this.onDisplaySwitchContentDialog.emit({ action: action, row: event.row });

        } else {

          const ab = new ActionBuilder(event.action, event.row, null, this.uriCollections, this.screenParameters, event.completionEvent,
            this.onViewItem, this.thingService, this.toastr, this.dialog);
          ab.PerformAction();
        }

      } else if (action.action === ActionIdentifier.UpdateData) {

        if (action.actionArgument) {

          const actionArg = action.actionArgument as UpdateDataAction;
          if (actionArg.editUri) {
            let updateData;
            if (event.updatedItems) {
              updateData = event.updatedItems;
            } else {
              const newItem: any = Utilities.createItemFromObjectDefaults(actionArg.newObjectDefaults, event.row, event.newItem,
                this.uriCollections, this.screenParameters);

              // If sectionId contains '.' we couldn't parse it so remove the property
              if (newItem['sectionId'] && newItem['sectionId'].toString().indexOf('.') > -1) {
                delete newItem['sectionId'];
              }

              if (this.customId) {
                newItem['sectionId'] = this.customId.id;
              }

              if (actionArg.editUri.includes('/actions/')) {
                updateData = newItem;
              } else {
                updateData = [newItem];
              }
            }
            this.updateIndicator = true;
            this.thingService.postDataDetails(actionArg.editUri, updateData)
              .subscribe(result => {
                this.onPostDataDetailSectionSuccessful(action, result, false, updateData, actionArg.currentItemPath);
              }, error => this.onPostDataDetailFailed(error));
          } else {

            const o = Utilities.createItemFromObjectDefaults(actionArg.newObjectDefaults, event.row, null, this.uriCollections, this.screenParameters);
            this.updateScreenData.emit({ targetScreen: actionArg.resultTargetScreen, targetDataObject: actionArg.resultTargetDataObject, newData: o });
          }
        }
      } else if (action.action === ActionIdentifier.DeleteItem) {
        if (this.dataDetail.dataItems.findIndex(d => d.selected) > -1) {
          this.deleteSelected(action);
        } else {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '450px',
            data: { message: 'Are you sure you want to delete this item?' }
          });

          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              const arg: string = action.actionArgument.editUriArgs[0];
              this.idName = arg.substring(arg.lastIndexOf('.') + 1);

              this.loadingIndicator = true;
              this.thingService.deleteItem(action.actionArgument.editUri, [event.row[this.idName]]).subscribe(x => { this.onDeleteItemSuccessful(x) }, error => this.onDeleteItemFailed(error));
            }

          });
        }
      } else if (action.action === ActionIdentifier.ReloadData) {
        // this.reloadData(action);
      } else if (action.action === ActionIdentifier.UpdateScreenParameters) {
        const updateParamsAction = action.actionArgument as UpdateScreenParametersAction;
        if (updateParamsAction.targetScreen == null) {
          if (updateParamsAction.screenParameters) {
            Object.keys(updateParamsAction.screenParameters).forEach(key => {
              this.screenParameters[key] = updateParamsAction.screenParameters[key];
            });
          }

          if (updateParamsAction.screenProperties) {
            Object.keys(updateParamsAction.screenProperties).forEach(key => {
              this.screenDetailComponents.screenProperties[key] = updateParamsAction.screenProperties[key];
            });
          }
        }

      } else {
        alert('Action ' + action.action + ' not defined in onItemClick');
      }
    });
  }

  private onPostDataDetailSectionSuccessful(action: Action, resultArray: PostResponse[], reloadGrid: boolean, originalRow: any, currentItemPath: string) {

    const result = resultArray[0];

    switch (result.errorCode) {
      case 0:
        {
          if (result.errorMessage === 'deleted' && action.actionArgument.resultDataMapping && action.actionArgument.resultDataMapping['primaryKey']) {
            const primaryKey = action.actionArgument.resultDataMapping['primaryKey'];
            // tslint:disable-next-line: triple-equals
            const deleteIndex = this.dataItems.findIndex(d => d[primaryKey] == originalRow[primaryKey]);
            if (deleteIndex > -1) {
              this.dataItems.splice(deleteIndex, 1);
            }
          } else {

            this.updateIndicator = false;
            let sectionId = -1;

            if (result && result.returnedObject.sectionID) {

              sectionId = result.returnedObject.sectionID;
            }

            this.onUpdateCompleted.emit({
              sectionId: sectionId,
              resultTargetDataObject: action.actionArgument.resultTargetDataObject,
              row: result.returnedObject,
              currentItemPath: currentItemPath,
            });

            if (action.actionArgument.resultDataMapping) {
              for (const key in action.actionArgument.resultDataMapping) {
                if (key) {
                  const entry: string = action.actionArgument.resultDataMapping[key];
                  originalRow[key] = result[0].returnedObject[Utilities.getLastEntry(entry)];
                }
              }
            }

            if (action.actionArgument.targetDataObject) {
              let newItem;
              if (result[0]) { newItem = result[0].returnedObject; }

              const dataItems = Utilities.getUriCollection(action.actionArgument.targetDataObject, this.uriCollections);

              const primaryKeyField = action.actionArgument.targetDataObject + 'ID';

              if (newItem[primaryKeyField]) {
                const index = dataItems.dataValues.findIndex(v => v[primaryKeyField] == newItem[primaryKeyField]);
                if (index) {
                  dataItems.dataValues[index] = newItem;
                } else {
                  dataItems.dataValues.push(newItem);
                }
              }
            }
          }

          if (reloadGrid) {
            // this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
          }
        }
        break;
      case 9:
        {
          this.toastr.error(result.errorMessage);


          this.onUpdateCompleted.emit({
            resultTargetDataObject: action.actionArgument.resultTargetDataObject
            // reloadOrderBasket: action.actionArgument.resultTargetDataObject === 'orderBasket'
          });
        }
        break;
    }
  }

  private onPostDataDetailFailed(error: any) {
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
  }

  public deleteSelected(action: Action) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { message: 'Are you sure you want to delete the selected items?' }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        const arg: string = action.actionArgument.editUriArgs[0];
        this.idName = arg.substring(arg.lastIndexOf('.') + 1);

        const selectedIds = [];

        for (const r of this.dataDetail.dataItems) {
          if (r.selected) {
            selectedIds.push(r[this.idName]);
          }
        }

        this.loadingIndicator = true;
        this.thingService.deleteItem(action.actionArgument.editUri, selectedIds)
          .subscribe(results => this.onDeleteItemSuccessful(results), error => this.onDeleteItemFailed(error));
      }

    });

  }

  private onDeleteItemSuccessful(result: PostResponse[]) {

    for (const r of result) {
      if (r.errorCode === 0) {
        // this.refresh();
        this.onDeleteCompleted.emit({ name: this.idName, value: r.returnedObject });
      } else {

        this.toastr.error(r.errorMessage, null, { closeButton: true, tapToDismiss: true });

      }
    }
  }

  private onDeleteItemFailed(error: any) {

    this.loadingIndicator = false;
    this.toastr.error(`Unable to delete data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
  }

  public getColor(row: any, columnField: any): string {

    if (columnField.color && row[columnField.color]) {

      if (row[columnField.color].length > 0) {
        const color: string = row[columnField.color];
        if (color.startsWith('#')) {
          return color;
        } else {
          return `#${color}`;
        }
      }
    }
    return '#dfdfdf';
  }

  public refreshData() {
    this.loadUriData(false);
    this.refresh.next();
  }

  public onCalendarClick($event: KeyboardEvent, columnItem, row, date?) {

    this.screenParameters[this.SELECTED_DATE_TIME_SCREEN_PARAMETER] = date ? date.date : null;

    // Must be a better way to get ViewChildren component by index. Tried toArray
    let index = 0;
    let selectedMenu: ContextMenuComponent;

    this.cdr.detectChanges();

    this.contextMenuService.show.next({
      anchorElement: $event.target,
      // Optional - if unspecified, all context menu components will open
      contextMenu: selectedMenu,
      event: <any>$event,
      item: columnItem,
    });

    $event.preventDefault();
    $event.stopPropagation();
    this.cdr.detectChanges();

  }

  buildContextMenus() {

    // activeFlowID and activeFlowState should NOT be hardcoded
    // Clear existing menus
    this.statusMenus = [];

    for (const item of this.dataDetail.dataItems) {

      if (item.activeFlowID) {

        let menu: MenuNode = this.statusMenus.find(m => m.id == item.activeFlowID);

        if (!menu) {

          menu = new MenuNode(item.activeFlowID, '');
          this.statusMenus.push(menu);

          for (const status of FlowService.getFlowStatuses(item.activeFlowID, item.activeFlowState, true, true)) {

            if (!menu.children) {
              menu.children = [];
            }

            menu.children.push(new MenuNode(status.ftFlowStatusID, status.text, status.isUserSettable));
          }
        }
      }
    }
  }

  public getFlowStatusName(flowStatusId: number, referenceTo: string) {

    const item = FlowService.getFlowStatus(flowStatusId, referenceTo);
    return (item != null ? item.text : '');

  }

  public onClick(item: MenuItem) {

    if (item.action) {
      const a = new ActionBuilder(item.action, this.row, null, this.uriCollections, this.screenParameters, null,
        this.onAction, this.thingService, this.toastr, this.dialog);
      a.PerformAction();
    } else {
      alert('No action defined!');
    }
  }

  getIconById(id: number) {
    var iconHex = this.iconHelper.getFontAwesomeHex(id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  public sortByStartDate(events: Array<any>) {
    var events = events.sort((a: any, b: any) => {
      return a.startDate.getTime() - b.startDate.getTime();
    });
    return events;
  }

  toggleFilter() {
    this.showFilter = !this.showFilter
  }

  setFilterStatus() {
    var checkedCategories = this.categories.filter(x => !x.checked);
    this.filterActive = !!checkedCategories && checkedCategories.length > 0;

    var setFilter = false;
    for(let i=0;i<this.categories.length;i++)
    {
        var currentCategoryValues = this.categories[i].values;
        if(currentCategoryValues.length > 0)
        {
            var currentCategoryFiltersChecked = currentCategoryValues.filter(x => x.checked);
            if (!!currentCategoryFiltersChecked && currentCategoryFiltersChecked.length > 0)
            {
                this.filterActive = true;
                setFilter = true;
            }
        }
    }
}



}
