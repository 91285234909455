import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoginResponse } from '../../models/data/LoginResponse';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {

  static readonly loginTokenKey = 'loginToken';
  static readonly securityEntityId = 'securityEntityId';
  static readonly workerId = 'workerId';
  static readonly webApiUriKey = 'webApiUri';
  static readonly userDataKey = 'userData';
  static readonly apiRoot = 'https://ftocdev01.azurewebsites.net/api';

  constructor(private http: HttpClient) {
  }

  /**
   * Gets the token from session storage
   * @returns {string}
   */
  public getToken(): string {
    return sessionStorage.getItem(AuthenticationService.loginTokenKey);
  }

  /**
   * Store the login token in session storage
   * @param {string} token
   */
  private storeToken(token: string) {
    sessionStorage.setItem(AuthenticationService.loginTokenKey, token);
  }

  public getSecurityEntityId(): string {
    return sessionStorage.getItem(AuthenticationService.securityEntityId);
  }

  private storeSecurityEntityId(token: string) {
    sessionStorage.setItem(AuthenticationService.securityEntityId, token);
  }

  public getWorkerIdKey(): string {
    return sessionStorage.getItem(AuthenticationService.workerId);
  }

  private storeWorkerId(token: string) {
    sessionStorage.setItem(AuthenticationService.workerId, token);
  }

  /**
    * Gets the token from session storage
    * @returns {string}
    */
  public getWebApiUri(): string {
    const r = sessionStorage.getItem(AuthenticationService.webApiUriKey);
    if (r) { return r; }

    const uri = this.getLoginInfo().availableSystems.find(s => s.systemID === this.getCurrentSystemId()).webApiUri;
    this.storeWebApiUri(uri);
    return uri;
  }

  /**
   * Store the login token in session storage
   * @param {string} webApiUri
   */
  private storeWebApiUri(webApiUri: string) {
    sessionStorage.setItem(AuthenticationService.webApiUriKey, webApiUri);
  }

  public getLoginInfo(): LoginResponse {
    return JSON.parse(sessionStorage.getItem(AuthenticationService.userDataKey)) as LoginResponse;
  }

  private storeLoginInfo(response: LoginResponse) {
    sessionStorage.setItem(AuthenticationService.userDataKey, JSON.stringify(response));
  }

  /**
   * Obtain a valid session token from the back end
   * @param {string} userId - user id
   * @param {string} password - password
   * @returns {Observable<ILoginResponse>} - login response
   */
  login(userId: string, password: string, systemId: string): Observable<LoginResponse> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<LoginResponse>(`${AuthenticationService.apiRoot}/security/logon`,
      {
        // TODO was emailId - deprecated?
        logonName: userId,
        password: password,
        requestedSystem: systemId,
      }
      // ,httpOptions
    ).
      pipe(
        map(response => {
          if (response.messageCode === 0) {
            this.storeLoginInfo(response);
            if (response.logonComplete) {
              this.storeToken(response.token);
              this.storeSecurityEntityId(response.securityEntityID);
              this.getWorkerId().subscribe(y => {
                this.storeWorkerId(y[0].workerID);
              }, error =>{
                // Could not fetch worker ID, but don't block login for this alone
                console.log("Could not fetch worker info for the current user")
              })

              const currentSystem = response.availableSystems.find(s => s.systemID === response.loggedOnSystemID);
              if (currentSystem) { this.storeWebApiUri(currentSystem.webApiUri); }
            }
          }
          return response;
        }),
        catchError(error => {
          console.log(error);
          return this.handleError(error, null);
        })
      );
  }

  getWorkerId() {
    var dataUri = `/api/search/worker/securityentityid/${this.getSecurityEntityId()}`;
    return this.http.get(`${this.getWebApiUri()}${dataUri}`)
    .pipe(map(res => res),
      catchError(error => {
        // Could not fetch worker, but don't block login for this alone
        return throwError(error);
      }));
  }

  /**
   * Obtain a valid session token from the back end
   * @param {string} userId - user id
   * @param {string} password - password
   * @returns {Observable<ILoginResponse>} - login response
   */
  loginToSystem(token: string, logonName: string, systemId: string): Observable<LoginResponse> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    const body = {
      token: token,
      logonName: logonName,
      requestedSystem: systemId,
    };

    const h = this.http.post<LoginResponse>(`${AuthenticationService.apiRoot}/security/logon`,
      body
      , httpOptions
    ).
      pipe(
        map(response => {
          if (response.messageCode === 0) {
            this.storeLoginInfo(response);
            if (response.logonComplete) {
              this.storeToken(response.token);
              this.storeSecurityEntityId(response.securityEntityID);
              const currentSystem = response.availableSystems.find(s => s.systemID === response.loggedOnSystemID);
              if (currentSystem) { this.storeWebApiUri(currentSystem.webApiUri); }
            }
          }
          return response;
        }),
        catchError(error => {
          return this.handleError(error, null);
        })
      );
    return h;
  }

  protected handleError(error: HttpErrorResponse, continuation: () => Observable<any>) {

    if (error.status === 401) {
      this.logout();
      return throwError(error);
    } else {
      return throwError(error);
    }
  }

  /**
   * Get the name of the currently logged in user
   * @returns {string}
   */
  getUsername(): string {
    const loginInfo = this.getLoginInfo();
    if (loginInfo.forename !== '' && loginInfo.surname !== '') {
      return loginInfo.forename + ' ' + loginInfo.surname;
    }

    return loginInfo.eMail;
  }

  /**
   * Determine if the current user is logged in
   * @returns {boolean}
   */
  isLoggedIn(): boolean {
    return sessionStorage.getItem(AuthenticationService.loginTokenKey) !== null;
  }

  /**
   * Logout the current user
   */
  logout() {
    sessionStorage.removeItem(AuthenticationService.loginTokenKey);
    sessionStorage.removeItem(AuthenticationService.securityEntityId);
    sessionStorage.removeItem(AuthenticationService.userDataKey);
  }

  public getCurrentSystemId() {
    const loginInfo = this.getLoginInfo();
    return loginInfo ? loginInfo.loggedOnSystemID : null;
  }

  public getCurrentSystem() {
    const loginInfo = this.getLoginInfo();
    if (loginInfo && loginInfo.availableSystems) {
      return loginInfo.availableSystems.find(s => s.systemID === loginInfo.loggedOnSystemID);
    }
  }

  public changeSystem(systemId: string): Observable<LoginResponse> {
    return this.loginToSystem(this.getToken(), this.getLoginInfo().eMail, systemId);
  }
}
