import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-counter-icon',
  templateUrl: './counter-icon.component.html',
  styleUrls: ['./counter-icon.component.scss']
})
export class CounterIconComponent implements OnInit {

  @Input() position: number = null;
  @Input() total: number;
  @Input() color = '';
  text: string;

  constructor() { }

  ngOnInit() {
  }

  @Input() set value(value: string) {

    if (value) {
      this.text = value;
      const index: number = value.indexOf('/');
      if (index > -1) {
        this.position = parseInt(value.substring(0, index), 10);
        this.total = parseInt(value.substring(index + 1), 10);
      }
    }
  }

}
