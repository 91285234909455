import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { IconService } from "../services/icon/icon.service";

@Injectable({ providedIn: 'root' })
export class IconResolver implements Resolve<any> {

    constructor (private iconService: IconService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>| Promise<any>|any {

        return this.iconService.getIconMappings().pipe(
            map((x) => (this.iconService.setIconMappings(x))),
            catchError((error) => {
              return of();
            }),
          );
    }
}