import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { Utilities } from '../../../../services/utilities/utilities';
import { ThingService } from '../../../../services/thing/thing.service';

import { ScreenDetail } from '../../../../models/ScreenDetail';
import { Action } from '../../../../models/actions/Action';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { FormTemplateItem } from '../../../../models/screenModels/TemplateItem';
import { PostResponse } from '../../../../models/PostResponse';
import { ScreenColumn } from 'src/app/models/ScreenColumn';

export interface DialogData {
  screenDetail: ScreenDetail;
  row: any;
  title: string;
  action: Action;
  columns: FTThingDetail[];
}

@Component({
  selector: 'app-price-band-update-dialog',
  templateUrl: './price-band-update-dialog.component.html',
  styleUrls: ['./price-band-update-dialog.component.scss']
})
export class PriceBandUpdateDialogComponent implements OnInit {

  screenDetail: ScreenDetail;
  row: any;
  title: string;
  action: Action;
  columns: FTThingDetail[];

  addNew: boolean = false;
  priceTemplate: FormTemplateItem;

  nameDisplayName: string;
  nameInitials: string;
  nameNotes: string;
  nameColor: string;
  nameStartDate: string;
  nameEndDate: string;

  columnDisplayName: FTThingDetail;
  columnInitials: FTThingDetail;
  columnNotes: FTThingDetail;
  columnColor: FTThingDetail;
  columnStartDate: FTThingDetail;
  columnEndDate: FTThingDetail;

  updating: boolean = false;

  selected: { startdDate: moment.Moment, endDate: moment.Moment };

  constructor(public toastr: ToastrService, private thingService: ThingService, public dialogRef: MatDialogRef<PriceBandUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.screenDetail = this.data.screenDetail;
    this.row = Utilities.copy(this.data.row);
    this.action = this.data.action;
    this.columns = this.data.columns;
    this.title = this.data.title; // Using menu option has title to avoid displaying "Add / update ...." in title

    if (!this.title && this.screenDetail) {
      this.title = Utilities.parseArgumentsFromData(this.screenDetail.screenTitle.text, this.screenDetail.screenTitle.argumentIds);
    }

    this.priceTemplate = this.screenDetail.priceTemplates[0];

    // Setup data names    
    this.nameDisplayName = Utilities.getLastEntry(this.getItem("displayName").dataPath);
    this.nameInitials = Utilities.getLastEntry(this.getItem("initials").dataPath);
    this.nameNotes = Utilities.getLastEntry(this.getItem("notes").dataPath);
    this.nameColor = Utilities.getLastEntry(this.getItem("color").dataPath);
    this.nameStartDate = Utilities.getLastEntry(this.getItem("dates").dataPath);
    this.nameEndDate = Utilities.getLastEntry(this.getItem("dates").dataPath2);

    // Setup columns
    this.columnDisplayName = this.columns.find(m => m.javaScriptName === this.nameDisplayName);
    this.columnInitials = this.columns.find(m => m.javaScriptName === this.nameInitials);
    this.columnNotes = this.columns.find(m => m.javaScriptName === this.nameNotes);
    this.columnColor = this.columns.find(m => m.javaScriptName === this.nameColor);
    this.columnStartDate = this.columns.find(m => m.javaScriptName === this.nameStartDate);
    this.columnEndDate = this.columns.find(m => m.javaScriptName === this.nameEndDate);

    // No actionData defined, so create new row
    if (!this.action.actionArgument.actionData) {

      this.addNew = true;
      this.row =  Utilities.generateRowFromDefaults(this.priceTemplate.onAddAction[0].actionArgument.newObjectDefaults, null, null);

    }
  }

  public getItem(name: string): ScreenColumn {

    return this.priceTemplate.templateItem.find(m => m.tag === name);

  }

  public onSave() {

    this.updating = true;
    let editUri: string = Utilities.parseArgumentsFromData(this.priceTemplate.onEditAction[0].actionArgument.editUri, this.priceTemplate.onEditAction[0].actionArgument.editUriArgs, this.row);

    this.thingService.postDataDetail(editUri, this.row)
      .subscribe(result => this.onPostDataDetailSuccessful(result), error => this.onPostDataDetailFailed(error));

  }

  private onPostDataDetailSuccessful(result: PostResponse[]) {

    Utilities.log("response");

    this.updating = false;
    this.dialogRef.close(true);
  }

  private onPostDataDetailFailed(error: any) {
    this.updating = false;
    this.toastr.error(`Unable to save data to server.\r\nErrors: "${Utilities.getHttpResponseMessage(error)}"`, null, { closeButton: true, tapToDismiss: true });
  }

}
