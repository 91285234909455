import { Component, OnInit, Inject, ViewChild, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { Utilities } from '../../../../services/utilities/utilities';
import { FlowService } from '../../../../services/flow/flow.service';
import { ThingService } from '../../../../services/thing/thing.service';

import { ScreenDetail } from '../../../../models/ScreenDetail';
import { Action } from '../../../../models/actions/Action';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { FormTemplateItem } from '../../../../models/screenModels/TemplateItem';
import { PostResponse } from '../../../../models/PostResponse';
import { LookupDetail } from '../../../../models/LookupDetail';
import { ScreenColumn } from 'src/app/models/ScreenColumn';
import { DataDetail } from 'src/app/models/DataDetail';
import { PriceSchemeDetailComponent } from '../price-scheme-detail/price-scheme-detail.component';
import { forkJoin } from 'rxjs';
import { ViewType } from 'src/app/models/Enums';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';

export interface DialogData {
  screenDetails: ScreenDetail;
  action: Action;
  row: any;
  columns: FTThingDetail[];
  priceSchemeDetailColumns: FTThingDetail[];
  priceSchemeDetails: any;
  title: string;
  uriCollections: UriCollection[];
}

interface ColumnData {
  columnItem: ScreenColumn;
  name: string;
  metadata: FTThingDetail;
}

@Component({
  selector: 'app-price-scheme-update-dialog',
  templateUrl: './price-scheme-update-dialog.component.html',
  styleUrls: ['./price-scheme-update-dialog.component.scss']
})
export class PriceSchemeUpdateDialogComponent implements OnInit {

  screenDetailComponents: ScreenDetail;
  priceSchemeDetailScreenDetail: ScreenDetail;
  priceSchemeScreenDetail: ScreenDetail;
  title: string;
  action: Action;
  columns: FTThingDetail[];

  priceScheme: any;
  priceSchemeDetails: any;
  priceSchemeDetailColumns: FTThingDetail[];

  addNew = false;
  priceSchemeTemplate: FormTemplateItem;

  columnDataCyclePeriod: ColumnData;
  columnDataCycleDurationUnit: ColumnData;
  columnDataMinDurations: ColumnData;
  columnDataMaxDurations: ColumnData;
  columnDataDurationUnit: ColumnData;
  columnDataPriceSchemeType: ColumnData;
  columnDataSchemeName: ColumnData;
  columnDataColor: ColumnData;
  columnDataIcon: ColumnData;
  columnDataInitials: ColumnData;
  columnDataDurationType: ColumnData;

  updating = false;

  selected: { startdDate: moment.Moment, endDate: moment.Moment };

  priceSchemeDurationTemplate: FormTemplateItem;
  priceSchemeCyclicalTemplate: FormTemplateItem;
  priceSchemeFooterTemplate: FormTemplateItem;
  columnDataCurrency: ColumnData;
  columnDataNotes: ColumnData;
  columnDataDefault: ColumnData;
  columnDataDelete: ColumnData;

  priceSchemeTypes: LookupDetail[];
  private _selectedSchemeType: LookupDetail;
  durationTypes: LookupDetail[];
  private _selectedDurationType: LookupDetail;
  uriCollections: UriCollection[];
  // deleteButton: MenuItem;

  get selectedSchemeType(): LookupDetail {
    return this._selectedSchemeType;
  }

  set selectedSchemeType(value: LookupDetail) {
    this._selectedSchemeType = value;
    if (value) {
      this.priceScheme[this.columnDataPriceSchemeType.name] = value.value;
    }
  }

  get selectedDurationType(): LookupDetail {
    return this._selectedDurationType;
  }

  set selectedDurationType(value: LookupDetail) {
    this._selectedDurationType = value;
    if (value) {
      this.priceScheme[this.columnDataDurationType.name] = value.value;
    }
  }

  @ViewChild(PriceSchemeDetailComponent) public priceSchemeDetailComponent: PriceSchemeDetailComponent;

  constructor(public toastr: ToastrService, private thingService: ThingService, public dialogRef: MatDialogRef<PriceSchemeUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.screenDetailComponents = this.data.screenDetails;
    this.uriCollections = this.data.uriCollections;

    if (this.screenDetailComponents === null) {
      return;
    }

    this.priceSchemeScreenDetail = this.screenDetailComponents.componentScreens.find(m => m.screenTag === 'priceScheme');
    this.priceSchemeDetailScreenDetail = this.screenDetailComponents.componentScreens.find(m => m.screenTag === 'priceSchemeDetail');

    this.priceSchemeTemplate = this.priceSchemeScreenDetail.priceTemplates.find(p => p.tag === 'priceScheme');
    this.priceSchemeDurationTemplate = this.priceSchemeScreenDetail.priceTemplates.find(p => p.tag === 'priceSchemeDuration');
    this.priceSchemeCyclicalTemplate = this.priceSchemeScreenDetail.priceTemplates.find(p => p.tag === 'priceSchemeCyclical');
    this.priceSchemeFooterTemplate = this.priceSchemeScreenDetail.priceTemplates.find(p => p.tag === 'priceSchemeFooter');

    this.action = this.data.action;
    this.columns = this.data.columns;
    this.title = this.data.title; // Using menu option has title to avoid displaying 'Add / update ....' in title

    if (!this.title && this.priceSchemeScreenDetail) {
      this.title = Utilities.parseArgumentsFromData(this.priceSchemeScreenDetail.screenTitle.text, this.priceSchemeScreenDetail.screenTitle.argumentIds, null, null, null, null);
    }
    // No actionData defined, so create new row
    if (!this.action.actionArgument.actionData) {

      this.addNew = true;
      this.priceScheme = Utilities.generateRowFromDefaults(this.priceSchemeTemplate.onAddAction[0].actionArgument.newObjectDefaults, null, null);
      this.priceScheme.priceSchemeID = -1;

    } else {
      this.priceScheme = Utilities.copy(this.data.row);
    }

    this.priceSchemeDetails = this.data.priceSchemeDetails.filter(p => p.priceSchemeID === this.priceScheme.priceSchemeID);

    this.priceSchemeDetailColumns = this.data.priceSchemeDetailColumns;

    this.columnDataPriceSchemeType = this.getColumnData('priceSchemeType', this.priceSchemeTemplate);
    this.columnDataSchemeName = this.getColumnData('schemeName', this.priceSchemeTemplate);
    this.columnDataColor = this.getColumnData('color', this.priceSchemeTemplate);
    this.columnDataIcon = this.getColumnData('icon', this.priceSchemeTemplate);
    this.columnDataInitials = this.getColumnData('initials', this.priceSchemeTemplate);
    this.columnDataDurationType = this.getColumnData('durationType', this.priceSchemeDurationTemplate);
    this.columnDataCyclePeriod = this.getColumnData('cyclePeriod', this.priceSchemeCyclicalTemplate);
    this.columnDataCycleDurationUnit = this.getColumnData('cycleDurationUnit', this.priceSchemeCyclicalTemplate);
    this.columnDataMinDurations = this.getColumnData('minDurationsInCycle', this.priceSchemeCyclicalTemplate);
    this.columnDataMaxDurations = this.getColumnData('maxDurationsInCycle', this.priceSchemeCyclicalTemplate);
    this.columnDataDurationUnit = this.getColumnData('durationUnit', this.priceSchemeCyclicalTemplate);
    this.columnDataCurrency = this.getColumnData('currency', this.priceSchemeFooterTemplate);
    this.columnDataNotes = this.getColumnData('notes', this.priceSchemeFooterTemplate);
    this.columnDataDefault = this.getColumnData('default', this.priceSchemeFooterTemplate);

    this.columnDataDelete = this.getColumnData('delete', this.priceSchemeFooterTemplate);

    // this.priceSchemeTemplate.contextMenu[0];

    this.priceSchemeTypes = FlowService.getLookupDetails('PriceSchemeType');
    this.durationTypes = FlowService.getLookupDetails('Duration');

    // Object reference to selected scheme type
    this.selectedSchemeType = this.priceSchemeTypes.find(m => m.value == this.priceScheme[this.columnDataPriceSchemeType.name]);
    this.selectedDurationType = this.durationTypes.find(m => m.value == this.priceScheme[this.columnDataDurationType.name]);
  }

  getColumnData(columnTag: string, template: FormTemplateItem): ColumnData {

    const item = this.getColumnItem(columnTag, template);
    if (item) {
      // get main argument based on ViewType
      let name;
      switch (item.viewType) {
        case ViewType.IconPicker:
          name = Utilities.getLastEntry(item.iconArgument);
          break;
        case ViewType.Checkbox:
          name = Utilities.getLastEntry(item.isCheckedCondition);
          break;
        default:
          name = Utilities.getLastEntry(item.dataPath);
          break;
      }
      if (name == null) {
        Utilities.log2('PriceSchemeUpdateDialog', 'null name for ' + columnTag, 'warn');
      }

      return {
        columnItem: item,
        name: name,
        metadata: this.columns.find(m => m.javaScriptName === name)
      };
    }
  }

  public getColumnItem(name: string, template: FormTemplateItem): ScreenColumn {

    return template.templateItem.find(m => m.tag === name);
  }

  public onSave() {

    if (this.priceScheme.priceSchemeID < 0) {
      this.priceScheme.priceSchemeID = null;
    }

    this.updating = true;
    const editUri: string = Utilities.parseArgumentsFromData(this.priceSchemeTemplate.onEditAction[0].actionArgument.editUri,
      this.priceSchemeTemplate.onEditAction[0].actionArgument.editUriArgs, this.priceScheme);

    // Convert scheme object back to value
    if (this.selectedSchemeType) {
      this.priceScheme[this.columnDataPriceSchemeType.name] = this.selectedSchemeType.value;
    } else {
      this.priceScheme[this.columnDataPriceSchemeType.name] = '';
    }

    this.thingService.postDataDetail(editUri, this.priceScheme)
      .subscribe(result => this.onPostDataDetailSuccessful(result), error => this.onPostDataDetailFailed(error));
  }

  public isVisible(condition: string): boolean {
    return Utilities.evaluate(condition, this.priceScheme);
  }

  onUpdateCompleted(event) {

    // this.updating = false;

    // if (event) {
    //   this.dialogRef.close(true);
    // }
  }

  private onPostDataDetailSuccessful(result: PostResponse[]) {

    Utilities.log2('onPostDataDetailSuccessful', 'response');
    this.priceScheme = result[0].returnedObject;

    // Now save priceSchemeDetails, if any
    if (this.priceSchemeDetailComponent) {
      this.priceSchemeDetailComponent.priceScheme = this.priceScheme;
      this.priceSchemeDetailComponent.Save();
    }

    this.updating = false;
    this.dialogRef.close(true);
  }

  private onPostDataDetailFailed(error: any) {
    this.updating = false;
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  get isNew(): boolean {
    return this.priceScheme.ovn === undefined;
  }

  public onDelete() {
    const completionEvent = new EventEmitter<ActionCompletedEvent>();
    completionEvent.subscribe(complete => {
      this.updating = false;
      this.dialogRef.close(true);
    });

    const a = new ActionBuilder(this.columnDataDelete.columnItem.action, this.priceScheme, null, this.uriCollections, null, completionEvent,
      null, this.thingService, this.toastr, null);
    a.PerformAction();
  }
}
