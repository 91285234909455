import { EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AlertDialogComponent } from 'src/app/components/controls/dialogs/alert-dialog/alert-dialog.component';
import { UpdateDetailsDialogComponent } from 'src/app/components/controls/dialogs/update-details-dialog/update-details-dialog.component';
import { Action } from 'src/app/models/actions/Action';
import { ActionAlertDialog } from 'src/app/models/actions/ActionAlertDialog';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
import { ReloadDataAction } from 'src/app/models/actions/ReloadDataAction';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { FTFlowStatus } from 'src/app/models/data/FTFlowStatus';
import { ActionIdentifier, IntentType, ScreenType } from 'src/app/models/Enums';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ThingService } from '../thing/thing.service';
import { Utilities } from '../utilities/utilities';
import { log } from 'util';

export class ActionBuilder {
  actionList: Action[];

  constructor(actionList: Action[],
    public item: any,
    public newItem: any,
    public uriCollections: UriCollection[],
    public screenParameters: any,
    public updateCompletedEvent: EventEmitter<ActionCompletedEvent>,
    public viewItemEvent = new EventEmitter<ViewDetailEvent>(),
    private thingService: ThingService,
    private toastrService: ToastrService,
    public dialog: MatDialog
  ) {
    // clone the array and sort it in ascending order
    this.actionList = Object.assign([], actionList.sort((a, b) => a.sequence - b.sequence));
  }

  public async PerformAction(): Promise<boolean> {

    if (!this.actionList || this.actionList.length === 0) {
      return false;
    }

    for (const action of this.actionList) {
      const a = await this.performAction(action);
      if (!a) {
        return false;
      }
    }

    return true;
  }

  private async performAction(action: Action): Promise<boolean> {

    if (!action) {
      return false;
    }

    // start by checking if the condition for the action is met
    if (action.condition) {
      const doAction = Utilities.evaluateCondition(action.condition, this.uriCollections, this.screenParameters);
      if (!doAction) {
        Utilities.log2('ActionBuilder_performAction', `Condition for ${action.condition} not met`, 'warn');
        return false;
      }
    }

    switch (action.action) {
      case ActionIdentifier.UpdateData:
      case ActionIdentifier.ChooseLookup:

        const updateDataAction = action.actionArgument as UpdateDataAction;

        const editUri: string = Utilities.parseArgumentsFromUriCollection(
          updateDataAction.editUri, updateDataAction.editUriArgs, this.uriCollections, null, this.screenParameters);

        let postData;

        if (editUri.includes('merge') || editUri.includes('actions')) {

          const value = Utilities.createItemFromObjectDefaults(updateDataAction.newObjectDefaults, this.item, this.newItem, this.uriCollections, this.screenParameters);
          postData = [value];

          // const defaults = Utilities.copy(updateDataAction.newObjectDefaults);
          // Object.keys(defaults).forEach(function (key) {
          //   const split = defaults[key].split('.');
          //   let value;
          //   switch (split[0].toLowerCase()) {
          //     case 'screenparameter':
          //     case 'screenparameters':
          //       value = this.screenParameters[split[1]];
          //       break;
          //     case 'data':
          //       value = Utilities.getValueFromUriCollection(defaults[key], this.uriCollections, this.item, this.screenParameters);
          //       break;
          //     case 'ftflow':
          //       const flow = this.newItem as FTFlowStatus;
          //       value = flow.ftFlowID;
          //       break;
          //     case 'ftflowstatus':
          //       const flow1 = this.newItem as FTFlowStatus;
          //       value = flow1.ftFlowStatusID;
          //       break;
          //     case 'tag':
          //       value = this.item[`tag-${Utilities.getLastEntry(this.newItem[key].replace('.value', ''))}`];
          //       break;
          //     case '@date':
          //       if (split[1] === '.now') {
          //         value = new Date();
          //       }
          //       break;
          //     default:
          //       value = defaults[key];
          //   }

          // let value = Utilities.getValueFromUriCollection(defaults[key], this.uriCollections, this.item, this.screenParameters);
          //   defaults[key] = value;
          // }, this);

        } else {
          postData = this.item;
        }

        // data items is already an array

        let refreshOnSaveHeader = false;
        if (updateDataAction.resultTargetDataObject) {
          refreshOnSaveHeader = true;
        }
        const r = await this.thingService.postDataDetails(editUri, postData, refreshOnSaveHeader)
          .subscribe(
            result => {
              var returnedObject = result[0].returnedObject;
              if(!returnedObject){
                returnedObject = (result[0] as any).ReturnedObject;
              }

              if (updateDataAction.resultTargetDataObject === 'screenParameters') {
                const obj = Utilities.copy(updateDataAction.resultDataMapping);

                Object.keys(obj).forEach(function (key) {
                  const value = Utilities.getValueFromUriCollection(obj[key], this.uriCollections, this.item, this.screenParameters);
                  this.screenParameters[Utilities.getLastEntry(key)] = value;
                }, this);
              } else if (updateDataAction.resultDataMapping) {
                const keys = Object.keys(updateDataAction.resultDataMapping);
                
                keys.forEach(key => {
                  const entry: string = updateDataAction.resultDataMapping[key];
                  key = Utilities.getLastEntry(key);
                  this.item[key] = returnedObject[Utilities.getLastEntry(entry)];
                });
                // updateDataAction.resultDataMapping.forEach(key => {
                //   const entry: string = updateDataAction.resultDataMapping[key];
                //   this.item[key] = result[0].returnedObject[Utilities.getLastEntry(entry)];
                // });
                // for (let key in action.actionArgument.resultDataMapping) {
                //   if (!key) {
                //     return;
                //   }
                //   const entry: string = action.actionArgument.resultDataMapping[key];
                //   this.item[key] = result[0].returnedObject[Utilities.getLastEntry(entry)];
                // }
              }
              this.updateCompletedEvent.emit({ isComplete: true, updatedObject: returnedObject });
            },
            error => {
              this.toastrService.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
              this.updateCompletedEvent.emit({ isComplete: false });
            });

        return true;

      case ActionIdentifier.ReloadData:
        const reloadDataAction = action.actionArgument as ReloadDataAction;
        if (reloadDataAction.targetDataObject) {
          let collection = this.uriCollections.find(c => c.name === reloadDataAction.targetDataObject);
          if (collection === undefined) {
            collection = { name: reloadDataAction.targetDataObject, dataValues: [] };
            this.uriCollections.push(collection);
          }

          const dataUri = Utilities.parseArgumentsFromUriCollection(reloadDataAction.dataUri, reloadDataAction.dataUriArgs, this.uriCollections, this.item);

          const result = await this.thingService.getDataDetail(dataUri).toPromise();
          collection.dataValues = result;
          return true;
        }
        break;
      // console.log('Perform action: ' + action.action.toString());

      // return true;
      case ActionIdentifier.DisplayDialog:
        {
          const dialogAction = (action.actionArgument as any) as LaunchScreenAction;

          if (dialogAction.screenType === ScreenType.EditDetails) {
            const callingAction: AddUpdateItemModel = {
              id: dialogAction.name,
              action: dialogAction,
              row: this.item,
              newItem: true,
              screenParameters: this.screenParameters,
              // uriCollections: this.uriCollections
            };

            const dialogRef = this.dialog.open(UpdateDetailsDialogComponent, {
              disableClose: true,
              closeOnNavigation: false,
              width: '500px',
              data: {
                action: callingAction,
                // uriCollections: this.uriCollections,

              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                const ev = result as UpdateCompletedEvent;
                this.updateCompletedEvent.emit({ isComplete: true, actionType: action.action, updatedObject: ev.row });
              }
            });
          }
          return true;
        }
        break;

      case ActionIdentifier.AlertDialog:
        {
          const dialogAction = (action.actionArgument as any) as ActionAlertDialog;

          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              title: Utilities.parseArgumentsFromUriCollection(dialogAction.title, dialogAction.titleArgs, this.uriCollections),
              message: Utilities.parseArgumentsFromUriCollection(dialogAction.message, dialogAction.messageArgs, this.uriCollections),
              positiveButton: dialogAction.positiveButton, negativeButton: dialogAction.negativeButton, neutralButton: dialogAction.neutralButton
            }
          });
          const res = await dialogRef.afterClosed().toPromise();
          let actionListDialog: Action[] = [];
          switch (res) {
            case -1:
              if (dialogAction.negativeButton.action) {
                actionListDialog = dialogAction.negativeButton.action;
              }
              break;
            case 0:
              if (dialogAction.neutralButton.action) {
                actionListDialog = dialogAction.neutralButton.action;
              }
              break;
            case 1:
              if (dialogAction.positiveButton.action) {
                actionListDialog = dialogAction.positiveButton.action;
              }
              break;
          }
          actionListDialog.forEach(async dlgAction => {

            const a = await this.performAction(dlgAction);
            if (!a) {
              // actionDone = false;
              return false;
            }
          });
          return true;
        }

      case ActionIdentifier.DeleteItem:
        // parses the arguments sent in the row
        const deleteUri: string = Utilities.parseArgumentsFromData(action.actionArgument.editUri, action.actionArgument.editUriArgs, this.item);
        this.thingService.deleteSingleItem(deleteUri)
          .subscribe(
            result => {
              this.updateCompletedEvent.emit({ isComplete: true });
              return true;
            }, error => this.onDeleteItemFailed(error));


        break;


      case ActionIdentifier.SetDragParameters:
        // parses the arguments sent in the row
        // let result = Utilities.generateRowFromDefaults(action.actionArgument.newObjectDefaults, this.item, this.screenParameters);
        // this.updateCompletedEvent.emit(result);

        break;

      case ActionIdentifier.LaunchScreen:
      case ActionIdentifier.LaunchFlyout:
        const parsedAction = Utilities.parseActionFromUriCollections(action, this.uriCollections, this.screenParameters);  //(action, this.row, this.screenParameters);
        const launchScreenAction = parsedAction;
        this.viewItemEvent.emit({ row: null, action: launchScreenAction, length: 1 });
        return true;
        break;

      case ActionIdentifier.Intent:
        var intentAction = action.actionArgument;
        switch (intentAction.intentType) {
          case IntentType.ExternalLink:
            var link = intentAction.actionArgument.externalLink;
            var params = intentAction.actionArgument.externalLinkArgs;
            var formattedLink = Utilities.parseArgumentsFromUriCollection(link, params, this.uriCollections, null, this.screenParameters);
            window.open(formattedLink, "_blank");

            break;
        
          default:
            break;
        }
        break;

      default:
        Utilities.log2('ActionService', 'Action type ' + action.action + ' not implemented yet.', 'warn');
    }
  }

  private onDeleteItemFailed(error: any) {

    this.toastrService.error(`Unable to delete data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }
}
