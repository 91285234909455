import { Component, OnInit, Input } from '@angular/core';
import { ObjectFlag } from 'src/app/models/data/ObjectFlag';
import { MatDialog } from '@angular/material';
import { FlagSummaryDialogComponent } from '../flag-summary-dialog/flag-summary-dialog.component';

@Component({
  selector: 'app-flag-summary',
  templateUrl: './flag-summary.component.html',
  styleUrls: ['./flag-summary.component.scss']
})
export class FlagSummaryComponent implements OnInit {

  @Input() flagList: ObjectFlag[];
  @Input() title: string;
  @Input() controlStyle: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  public get hasWarnings(): boolean {
    return this.flagList != null && this.flagList.find(f => f.warningText != null) != null;
  }

  public get hasInfo(): boolean {
    return this.flagList != null && this.flagList.find(f => f.informationText != null && f.warningText == null) != null;
  }

  warningClick() {
    const flagsToShow = this.flagList.filter(f => f.warningText);
    const dialogRef = this.dialog.open(FlagSummaryDialogComponent, {
      width: '450px',
      data: { flags: flagsToShow, type: 'warning', title: this.title }
    });
  }

  infoClick() {
    const flagsToShow = this.flagList.filter(f => f.informationText && !f.warningText);
    const dialogRef = this.dialog.open(FlagSummaryDialogComponent, {
      width: '450px',
      data: { flags: flagsToShow, type: 'info', title: this.title }
    });
  }
}
