import { isNumber } from "util";
import { DataPath } from "../models/dataPathModels/DataPath";
import { UriCollection } from "../models/screenModels/UriCollection";
import { DataPathHelper } from "./data-path-helper";

export class MathsHelper{
    public static evaluateEquationFromString(source: string, uriCollections?: UriCollection[], dataObj?: any){
        // Add 5 to start to account for "calc<"
        var startIndex = source.indexOf("calc<") + 5;
        var endIndex = source.indexOf(">");

        //Since we added 5, StartIndex equals 4 if no match was found
        if(startIndex == 4 || endIndex == -1){
            return source;
        }

        var equation = source.substring(startIndex, endIndex);

        if(equation.includes(':')){
            var equationAndFormat = equation.split(":");
            if(equationAndFormat.length == 2){
                try {
                    //TODO support more format strings
                    var evaluatedEquation = this.EvaluateEquation(equationAndFormat[0], uriCollections, dataObj);
                    switch (equationAndFormat[1].charAt(0)) {
                        case "f":
                            return evaluatedEquation.toFixed(Number(equationAndFormat[1].charAt(1)))
                            break;
                        case "c":
                            //TODO add currency symbol
                            return evaluatedEquation.toFixed(2);
                            break;
                        case "p":
                            return `${(evaluatedEquation * 100).toFixed(0)}%`;
                            break;
                        default:
                            break;
                    }



                    return source.replace("calc<" + equation + ">", evaluatedEquation.toString())
                } catch (error) {
                    
                }
            }
            else{
                return source.replace("calc<" + equation + ">", this.EvaluateEquation(equationAndFormat[0], uriCollections, dataObj).toString())
            }
        }
        else{
            return source.replace("calc<" + equation + ">", this.EvaluateEquation(equation, uriCollections, dataObj).toString())
        }
    }

    public static EvaluateEquation(equation: string, uriCollections?: UriCollection[], dataObj?: any): number{
        if(!isNaN(+equation))
        {
            return Number(equation);
        }

        //Brackets evaluated first to prevent equations being split up incorrectly
        if(equation.includes('(') && equation.includes(')')){
            var bracketCount = (equation.match(/\(/g) || []).length;
            for (let index = 0; index < bracketCount; index++) {
                var firstOpen = equation.indexOf('(');
                var nestedBracketCount = 0;
                var firstClose = equation.indexOf(')');
                if(bracketCount > 1){
                    // There could be nested brackets - use a more thourough solution than indexOf
                    for(let bracketSearch = firstOpen + 1; bracketSearch < equation.length; bracketSearch++){
                        if(equation.charAt(bracketSearch) == "("){
                            nestedBracketCount++;
                        }
                        else if(equation.charAt(bracketSearch) == ")"){
                            //If there is a nested bracket, this ")" will be closing that instead
                            if(nestedBracketCount == 0){
                                firstClose = bracketSearch;
                                break;
                            }
                            else{
                                nestedBracketCount--;
                            }
                        }
                    }
                }
                var contents = equation.substring(firstOpen, firstClose + 1) // +1 to catch closing bracket
                var bracketResult = this.EvaluateEquation(contents.substring(1, contents.length - 1), uriCollections, dataObj);
                equation = equation.replace(contents, bracketResult.toString());
            }
        }

        //Operations in reverse priority order - function is recursive, so first statement hit will happen last
        if(equation.includes('-')){
            var subtractionParts = equation.split('-');
            var evaluatedParts = subtractionParts.map(part => this.EvaluateEquation(part, uriCollections, dataObj));
            var subtraction = evaluatedParts[0];
            for (let index = 1; index < evaluatedParts.length; index++) {
                subtraction = +subtraction - +evaluatedParts[index];
            }
            return subtraction;
        }
        if(equation.includes('+')){
            var additionParts = equation.split('+');
            var evaluatedParts = additionParts.map(part => this.EvaluateEquation(part, uriCollections, dataObj));
            var addition = evaluatedParts[0];
            for (let index = 1; index < evaluatedParts.length; index++) {
                addition = +addition + +evaluatedParts[index];
            }
            return addition;
        }
        if(equation.includes('*')){
            var multiplicationParts = equation.split('*');
            var evaluatedParts = multiplicationParts.map(part => this.EvaluateEquation(part, uriCollections, dataObj));
            var multiplication = evaluatedParts[0];
            for (let index = 1; index < evaluatedParts.length; index++) {
                multiplication = +multiplication * +evaluatedParts[index];
            }
            return multiplication;
        }
        if(equation.includes('/')){
            var divisionParts = equation.split('/');
            var evaluatedParts = divisionParts.map(part => this.EvaluateEquation(part, uriCollections, dataObj));
            var division = evaluatedParts[0];
            for (let index = 1; index < evaluatedParts.length; index++) {
                division = +division / +evaluatedParts[index];
            }
            return division;
        }
        if(equation.includes('^')){
            var exponentParts = equation.split('^');
            var evaluatedParts = exponentParts.map(part => this.EvaluateEquation(part, uriCollections, dataObj));
            var exponent = evaluatedParts[0];
            for (let index = 1; index < evaluatedParts.length; index++) {
                exponent = Math.pow(+exponent, +evaluatedParts[index]);
            }
            return exponent;
        }

        if(DataPathHelper.isDataPath(equation)){
            var dataValue;
            var dataPath = new DataPath(equation);
            if(uriCollections){
                var collection = uriCollections.find(u => u.name == dataPath.rootObjectName);
                dataValue = collection.dataValue[dataPath.propertyName];
            }
            else if(dataObj){
                dataValue = dataObj[dataPath.propertyName];
            }

            if(dataValue){
                return dataValue
            }
            else{
                return 0;
            }
        }
    }
}