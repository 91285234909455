import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Action } from 'src/app/models/actions/Action';
import { ReloadDataAction } from 'src/app/models/actions/ReloadDataAction';
import { ActionIdentifier } from 'src/app/models/Enums';
import { MenuNode } from 'src/app/models/menu-tree/MenuNode';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { TreeItemSelectedEvent } from 'src/app/models/events/TreeItemSelectedEvent';
import { TreeFilterEvent } from 'src/app/models/events/TreeFilterEvent';
import { Utilities } from 'src/app/services/utilities/utilities';

export interface DialogData {
  action: Action;
  uriCollections: UriCollection[];
  screenParameters: any;
  heightOffset: number;
  checkedFilters: number[];
  height: number;
}

@Component({
  selector: 'app-tree-filter-dialog',
  templateUrl: './tree-filter-dialog.component.html',
  styleUrls: ['./tree-filter-dialog.component.scss']
})
export class TreeFilterDialogComponent implements OnInit {

  isEditable: Boolean;

  @Output() filterSet = new EventEmitter<TreeFilterEvent>();
  height: number;

  constructor(public dialogRef: MatDialogRef<TreeFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

    var isEditable = !!this.data.action.actionArgument.screenParameters && this.data.action.actionArgument.screenParameters['isEditable'];
    this.isEditable = !!isEditable && isEditable == 'true';
    this.height = this.data.height - 104;
  }

  public onTreeItemSelected(value: TreeItemSelectedEvent) {

    const id: string = value.id;
    const actionList: Action[] = value.actionList;
    const menuNodes: MenuNode[] = value.data;

    actionList.forEach(action => {
      if (action.action === ActionIdentifier.ReloadData) {

        let filter = '';
        for (const node of menuNodes) {

          if (filter !== '') {
            filter += ' or ';
          }

          filter += `${id} eq ${node.id}`;
        }
        this.filterSet.emit({ filter: filter, checkedIds: menuNodes.map(m => m.id) });
      }
    });
  }

  public closeDialog(event: any)
  {
    this.dialogRef.close();
  }
}
