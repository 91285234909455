import { Component, OnInit, Input, ViewChild, AfterViewInit, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, debounceTime, switchMap, tap } from 'rxjs/operators';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { SelectedItem } from '../../../../models/SelectedItem';
import { DataDetail } from '../../../../models/DataDetail';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { Action } from 'src/app/models/actions/Action';
import { MatDialog } from '@angular/material';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { MenuType } from 'src/app/models/Enums';
import { DataUtils } from 'src/app/services/data-utils/data-utils';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => AutoCompleteDropdownComponent),
  multi: true
};

@Component({
  selector: 'app-auto-complete-dropdown',
  templateUrl: './auto-complete-dropdown.component.html',
  styleUrls: ['./auto-complete-dropdown.component.scss', './../dropdown-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AutoCompleteDropdownComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {

  // _selectedItem: SelectedItem;
  selectString: string;
  _displayStringArgs: string[];
  propertyDisplayArgs: string[];
  _bindValue: string;
  _selectedValue: any; // number;
  isDisabled: boolean;
  newItem = false;

  @Output() actionEvent = new EventEmitter<Action[]>();
  @Output() selectionChanged = new EventEmitter<any>();
  @Output() itemAdded = new EventEmitter<any>();
  @Output() viewDetailEvent = new EventEmitter<ViewDetailEvent>();
  searchStringActive: boolean;
  @Input() savedObject: any;

  @Input() placeholder;

  set value(val: any) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    val = Number(val);
    this._selectedValue = val; // parseInt(val as any, 10);
    this.onChange(val);
    this.onTouched(val);

    if (this.dropdown.items == null && this.value) {

      const filter = `${this.bindValue} eq '${this.value}'`;

      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, filter, '', this.getSelectString(), true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
    }
  }
  get value(): any {
    return this._selectedValue;
  }

  @Input() hint: string;
  @Input() name: string;
  @Input() dataUri: string;
  @Input()
  set bindValue(v: string) {
    this._bindValue = Utilities.getLastEntry(v);
  }
  get bindValue() { return this._bindValue; }
  @Input() labelName: string;
  @Input() multiple = false;
  @Input() required = false;
  @Input() showValuesByDefault = true;
  @Input() showLabel = true;

  @Input() iconProperty: string;
  @Input() colourProperty: string;
  @Input() displayString: string;
  @Input()
  set displayStringArgs(v: string[]) {
    this.propertyDisplayArgs = v ? v.map(str => Utilities.getLastEntry(str)) : [];
    this._displayStringArgs = v;
  }
  get displayStringArgs(): string[] {
    return this._displayStringArgs;
  }
  @Input() fixedItems: MenuItem[];
  @Input() errorText: string;
  @Input() useSelectString: boolean;

  totalRecordCount: number;
  returnRecordCount: number;
  loading = false;
  dataItems: any[];
  typeahead = new Subject<string>();

  @ViewChild('dropdown') public dropdown: NgSelectComponent;

  constructor(public toastr: ToastrService, private thingService: ThingService, private dialog: MatDialog) { }

  ngOnInit() {
    if (this.showValuesByDefault) {
      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, null, '', this.getSelectString(), true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
    }
    else if (this.value) {
      const filter = `${this.bindValue} eq '${this.value}'`;

      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, filter, '', this.getSelectString(), true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.savedObject && !!changes.savedObject.currentValue) {
      const queryString = this.propertyDisplayArgs
        .map(v => `substringof('${changes.savedObject.currentValue.displayName}',${v})`)
        .join(' or ');

      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, queryString, '', this.getSelectString(), true)
        .subscribe(results => { this.onGetDataSuccessful(results); this.value = changes.savedObject.currentValue[this.bindValue] }, error => this.onGetDetailFailed(error));
    }
  }

  ngAfterViewInit() {

    if (this.value) {

      const filter = `${this.bindValue} eq '${this.value}'`;

      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, filter, '', this.getSelectString(), true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
    }

    this.typeahead.pipe(
      tap(() => this.loading = true),
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term => {
        this.searchStringActive = term ? term.length > 0 : false;
        const queryString = this.propertyDisplayArgs
          .map(v => `substringof('${term}',${v})`)
          .join(' or ');

        return this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null,
          queryString, '', this.getSelectString(), true);
      }),
    ).subscribe(results => {
      this.onGetDataSuccessful(results);
    }, error => this.onGetDetailFailed(error));
  }

  refresh(){
    const filter = this.value ? `${this.bindValue} eq '${this.value}'` : null;
    this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, filter, '', this.getSelectString(), true)
        .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
  }

  writeValue(value: any): void {
    if (value !== this.value) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  onChange: any = () => { };
  onTouched: any = () => { };

  public get selectedItem(): any {
    if (this.dropdown.selectedItems) {
      return this.dropdown.selectedItems[0].value;
    }
  }

  public changeValue(event: any)
  {
    this.selectionChanged.emit(this.selectedItem);
    if (!event)
    {
      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, null, '', this.getSelectString(), true)
      .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
    }
  }


  private getSelectString() {
    if (!this.selectString && this.useSelectString) {
      const props = Object.assign([{}], this.propertyDisplayArgs);
      if (this.colourProperty) {
        props.push(Utilities.getLastEntry(this.colourProperty));
      }
      if (this.iconProperty) {
        props.push(Utilities.getLastEntry(this.iconProperty));
      }
      this.selectString = props.join(',');
      if (this.propertyDisplayArgs.findIndex(s => s === this.bindValue) < 0) {
        this.selectString = this.selectString + `,${this.bindValue}`;
      }
    }
    return this.selectString;
  }

  private onGetDataSuccessful(dataDetail: DataDetail) {

    if (!dataDetail) {
      console.log('No dataDetail for ' + this.value + ' ' + this.dataUri);
    }
    this.loading = false;
    this.dataItems = dataDetail.dataItems;
    this.returnRecordCount = dataDetail.returnRecordCount;
    this.totalRecordCount = dataDetail.totalRecordCount;
  }

  getDisplayTextSelected(item: any) {
    if (item) {
      // console.log("val" + this.value);
      // console.log("bind" + this.bindValue);
      if (!!this.savedObject)
      {
        return Utilities.parseArgumentsFromData(this.displayString, this.displayStringArgs, this.savedObject);
      }
      return Utilities.parseArgumentsFromData(this.displayString, this.displayStringArgs, item);
    }
  }

  getDisplayText(item: any) {
    if (item) {
      // console.log("val" + this.value);
      // console.log("bind" + this.bindValue);
      var temp = Utilities.parseArgumentsFromData(this.displayString, this.displayStringArgs, item);
      return temp;
    }
  }

  hasIcon() {
    return this.iconProperty;
  }

  hasColor() {
    return this.colourProperty;
  }

  getIconId(item: any) {
    if (item) {
      if (this.iconProperty) {
        return Utilities.parseArgumentsFromData(null, [this.iconProperty], item);
      }
    }
  }

  getColor(item: any): string {

    if (this.colourProperty) {
      const path = Utilities.getLastEntry(this.colourProperty);

      const color: string = item[path];
      if (color && color.length > 0) {
        if (!color.startsWith('#')) {
          return '#' + color;
        } else {
          return color;
        }
      }

      return '';
    }

    return '';
  }

  public getHint() {
    return this.hint;
  }

  private onGetDetailFailed(error: any) {

    // console.log(error);
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
    this.loading = false;
  }

  public clear() {
    this.value = null;
    this.dataItems = [];
    this.returnRecordCount = 0;
    this.totalRecordCount = 0;
  }

  public focus() {
    this.dropdown.focus();
  }

  onAction(action: Action[]) {

    const completionEvent = new EventEmitter<ActionCompletedEvent>();
    completionEvent.subscribe((ev: ActionCompletedEvent) => {
      if (ev.isComplete) {

        const items = Object.assign([], this.dataItems);
        items.push(ev.updatedObject);
        this.onGetDataSuccessful({ dataItems: items, totalRecordCount: this.totalRecordCount + 1, returnRecordCount: this.returnRecordCount + 1 });
        this.value = ev.updatedObject[this.bindValue];
        this.newItem = true;
        this.itemAdded.emit(ev.updatedObject);
      }
    });

    const ac = new ActionBuilder(action, null, null, null, null, completionEvent, this.viewDetailEvent,
      this.thingService, this.toastr, this.dialog);

    ac.PerformAction();
  }

  addItemMenuAction() {
    if (this.fixedItems) {
      return this.fixedItems.find(m => m.menuItemType === MenuType.DropdownAdd);
    }
  }

}
