import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatTableDataSource, MatSort } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

export class GridTableDataSource<T> extends MatTableDataSource<T> {

    private _buffer = 15;
    private _unsortedData: any[];
    private _sortedData: any[];
    _rowHeight: number;
    _pageSize: number;
    _compareFn = new Intl.Collator('en').compare;

    private readonly visibleData: BehaviorSubject<any[]> = new BehaviorSubject([]);
    _filterString: string;
    _scrollStart = 0;
    _totalItemSize: number;

    offset = 0;
    offsetChange = new BehaviorSubject(0);

    get data(): any[] {
        return this._unsortedData.slice();
    }
    set data(data: any[]) {

        let initialLoad = false;
        if (this._unsortedData == null) {
            initialLoad = true;
        }
        this._unsortedData = Object.assign([], data);
        this._sortedData = Object.assign([], data);

        if (this.sort) {
            this.sortData(data, this.sort);
        } else {
            this.filteredData = this._unsortedData;
            if (initialLoad) {
                this.viewport.setTotalContentSize(this.itemSize * this._totalItemSize);
                const start = Math.floor(this.viewport.measureScrollOffset() / this._rowHeight);
                this.viewport.scrollToOffset(start);


                console.log('scroll a bit');
                this.viewport.scrollTo({ top: 100 });
            }
            // this.viewport.setTotalContentSize(this.itemSize * this._totalItemSize);
            // const start = Math.floor(this.viewport.measureScrollOffset() / this._rowHeight);

            // this.viewport.scrollToOffset(start);
            this.visibleData.next(this._unsortedData.slice(0, this._pageSize + this._buffer));
        }
    }

    public get totalItemSize(): number {
        if (this._totalItemSize < this.data.length) {
            this._totalItemSize = this.data.length;
        }
        return this._totalItemSize;
    }

    public set totalItemSize(v: number) {
        if (v === this._totalItemSize) {
            return;
        }
        this._totalItemSize = v;
        this.viewport.setTotalContentSize(this._totalItemSize * this.itemSize);
        const start = Math.floor(this.viewport.measureScrollOffset() / this._rowHeight);
        this.viewport.scrollToOffset(start);
    }

    constructor(initialData: any[], private viewport: CdkVirtualScrollViewport, private itemSize: number, private pageSize: number) {
        super();

        this._rowHeight = itemSize;
        this._pageSize = pageSize;
        this._unsortedData = initialData;
        this.viewport.elementScrolled().subscribe((ev: any) => {

            const start = Math.floor(ev.currentTarget.scrollTop / this._rowHeight);
            if (start !== this._scrollStart) {

                const prevExtraData = start > this._buffer ? this._buffer : 0;
                const slicedData = this.filteredData.slice(start - prevExtraData, start + (this._pageSize - prevExtraData));
                this.offset = this._rowHeight * (start - prevExtraData);
                this.viewport.setRenderedContentOffset(this.offset);
                this.offsetChange.next(this.offset);
                this.visibleData.next(slicedData);
                this._scrollStart = start;
            }
        });
    }

    connect(): BehaviorSubject<T[]> {
        return this.visibleData;
    }

    disconnect(): void {
        this.visibleData.complete();
    }

    sortData: ((data: T[], sort: MatSort) => T[]) = (data: T[], sort: MatSort): T[] => {

        const start = Math.floor(this.viewport.measureScrollOffset() / this._rowHeight);
        const prevExtraData = start > this._buffer ? this._buffer : 0;
        // const prevExtraData = 0;
        this.offset = this._rowHeight * (start - prevExtraData);
        this.viewport.setRenderedContentOffset(this.offset);
        this.offsetChange.next(this.offset);
        // let newData: any[];

        const active = sort.active;
        const direction = sort.direction;
        if (!active || direction === '') {
            this.filteredData = this._unsortedData;
        } else {
            // data returns empty array in this instance
            this._sortedData = this._sortedData.sort((a, b) => {
                const valueA = this.sortingDataAccessor(a, active);
                const valueB = this.sortingDataAccessor(b, active);

                const comparatorResult = this._compareFn(<string>valueA, <string>valueB);
                return comparatorResult * (direction === 'asc' ? 1 : -1);
            });
            this.filteredData = this._sortedData;
        }


        if (this.filter) {
            this.filteredData = !this.filter ? this.filteredData :
                this.filteredData.filter(obj => this.filterPredicate(obj, this.filter));
            this.filteredData = this.filteredData;
        }

        this.visibleData.next(this.filteredData.slice(start - prevExtraData, start + (this._pageSize - prevExtraData)));
        this.viewport.setTotalContentSize(this.itemSize * this._totalItemSize);

        return this.filteredData;
    }

    set filter(filterString: string) {
        this._filterString = filterString;
        this.sortData(this.data, this.sort);
    }
    get filter() {
        return this._filterString;
    }
}
