import { Component, OnInit, Input, ViewChild, AfterViewInit, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, debounceTime, switchMap, tap, startWith, map } from 'rxjs/operators';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { SelectedItem } from '../../../../models/SelectedItem';
import { DataDetail } from '../../../../models/DataDetail';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { Action } from 'src/app/models/actions/Action';
import { MatDialog } from '@angular/material';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { MenuType } from 'src/app/models/Enums';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { bind } from '@angular/core/src/render3';

@Component({
  selector: 'app-auto-complete-edit-text',
  templateUrl: './auto-complete-edit-text.component.html',
  styleUrls: ['./auto-complete-edit-text.component.scss']
})
export class AutoCompleteEditTextComponent implements OnInit {

  filteredOptions: Observable<any[]>;
  myControl = new FormControl();
  _displayStringArgs: string[];
  propertyDisplayArgs: string[];
  selectString: string;

  _selectedID: number;
  @Input()
  set selectedID(val: number) {
    if(this._selectedID != val){
      this.selectedIDChange.emit(val);
      this._selectedID = val;
      this.selectItemByID(this._selectedID, false);
    }
  }
  get selectedID() {
    return this._selectedID;
  }
  @Output()
  selectedIDChange: EventEmitter<number> = new EventEmitter<number>(); 

  @Output() selectionChanged = new EventEmitter<any>();
  @Output() focus = new EventEmitter<any>();
  
  _selectedText: string;
  @Input()
  set selectedText(val: string) {
    if(!this.value){
      this.value = val;
    }
    if(this._selectedText != val){
      this.selectedTextChange.emit(val);
      this._selectedText = val;
      this.selectItemByText(this._selectedText);
    }
  }
  get selectedText() {
    return this._selectedText;
  }
  @Output()
  selectedTextChange: EventEmitter<string> = new EventEmitter<string>(); 

  rowFocus(){
    this.focus.emit("focus");
  }
  
  _value: any;
  set value(val: any){
    if (typeof val === 'string'){
      if(val != this._selectedText){
        this._selectedText = val;
        this.selectedTextChange.emit(val);
        this.selectItemByText(val);
      }
    }
    else{
      if(val != this._value){
        this._selectedText = val.text;
        this.selectedTextChange.emit(this._selectedText);
        this._selectedID = val.id;
        this.selectedIDChange.emit(this._selectedID);
        this.selectItemByID(this._selectedID, !this.isInitialValueSet);
        this._value = val;
        this.isInitialValueSet = false;
      }
    }
  }
  get value(): any{
    return this._value;
  }

  isInitialValueSet = true;

  @Input() dataUri: string;
  @Input() disabled: boolean;
  _idBindValue: string;
  _textBindValue: string;

  @Input() useSelectString: boolean;
  
  @Input() iconProperty: string;
  @Input() colourProperty: string;
  
  @Input()
  set idBindValue(v: string) {
    this._idBindValue = Utilities.getLastEntry(v);
    if(this.selectedID){
      this.selectItemByID(this.selectedID, false);
    }
  }
  get idBindValue() { return this._idBindValue; }

  @Input()
  set textBindValue(v: string) {
    this._textBindValue = Utilities.getLastEntry(v);
  }
  get textBindValue() { return this._textBindValue; }

  @Input() displayString: string;
  @Input()
  set displayStringArgs(v: string[]) {
    this.propertyDisplayArgs = v ? v.map(str => Utilities.getLastEntry(str)) : [];
    this._displayStringArgs = v;
    if(this.selectedText){
      this.selectItemByText(this.selectedText);
    }
  }
  get displayStringArgs(): string[] {
    return this._displayStringArgs;
  }

  selectItemByID(id: number, updateParent = true){
    if(id && this._idBindValue){
      const filter = `${this._idBindValue} eq '${id}'`;
  
        this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, filter, '', this.getSelectString(), true)
          .subscribe(results => this.onGetDataSuccessful(results, updateParent), error => this.onGetDetailFailed(error));
    }
  }

  selectItemByText(text: string){
    var items = this.filter(text).subscribe(
      results => {
        if(results.length == 0 || results.length > 1 || text != results[0].text){
          this.value = {id: null, text: text};
        }
        else{
          this.value = results[0]
        }
      },
      error => {
        this.value = {id: null, text: text};
      })
    // if(this.selectedID){
    //   this.value = text;
    // }
    // else{
    //   this.value = {id: null, text: text};
    // }
  }

  private onGetDataSuccessful(dataDetail: DataDetail, updateParent: boolean) {

    if (!dataDetail || !dataDetail.dataItems) {
      // console.log('No dataDetail for ' + this.value + ' ' + this.dataUri);
      return;
    }
    // this.loading = false;
    var value = this.getDropdownItem(dataDetail.dataItems[0]);
    if(this._selectedID != value.id || this._selectedText != value.text){
      this._selectedID = value.id;
      this._selectedText = value.text;
    }
    if(this.value != value){
      this._value = value;
      this.changeDetector.detectChanges();
    }
    if(updateParent){
      this.selectionChanged.emit(dataDetail.dataItems[0]);
    }
  }
  onGetDetailFailed(error: any){

  }

  constructor(public toastr: ToastrService, private thingService: ThingService, private dialog: MatDialog, private changeDetector: ChangeDetectorRef) { }
  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        if(val && val.text)
          return this.filter(val.text);
        return this.filter(val || '');
      })       
    );
  }

  filter(val: string): Observable<any[]> {
    if(!this.propertyDisplayArgs){
      return;
    }
    const queryString = this.propertyDisplayArgs
          .map(v => `substringof('${val}',${v})`)
          .join(' or ');
    // call the service which makes the http-request
    if(val){
      return this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, queryString, '', this.getSelectString(), true)
       .pipe(
         map(response => response.dataItems.map(dataItem =>{
           return {id: this.getBindValue(dataItem), text: this.getDisplayText(dataItem)};
         }))
       )
    }
    else{
      this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, null, '', this.getSelectString(), true)
      .pipe(
        map(response => response.dataItems.map(dataItem =>{
          return this.getDropdownItem(dataItem);
        }))
      )
    }
   }  
  
  getDropdownItem(dataItem) : any{
    return {id: this.getBindValue(dataItem), text: this.getDisplayText(dataItem)};
  }

   displayFn(item){
     if(item){
      return item.text;
     }
   }

   getDisplayText(item: any) {
    if (item) {
      var temp = Utilities.parseArgumentsFromData(this.displayString, this.displayStringArgs, item);
      return temp;
    }
  }

  getBindValue(item: any){
    if(item){
      var temp = item[this._idBindValue];
      return temp;
    }
  }

  private getSelectString() {
    if (!this.selectString && this.useSelectString) {
      const props = Object.assign([{}], this.propertyDisplayArgs);
      if (this.colourProperty) {
        props.push(Utilities.getLastEntry(this.colourProperty));
      }
      if (this.iconProperty) {
        props.push(Utilities.getLastEntry(this.iconProperty));
      }
      this.selectString = props.join(',');
      if (this.propertyDisplayArgs.findIndex(s => s === this._idBindValue) < 0) {
        this.selectString = this.selectString + `,${this._idBindValue}`;
      }
    }
    return this.selectString;
  }
  
}
