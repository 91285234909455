import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multi-selector-dropdown',
  templateUrl: './multi-selector-dropdown.component.html',
  styleUrls: ['./multi-selector-dropdown.component.scss']
})
export class MultiSelectorDropdownComponent implements OnInit {

  ngOnInit(): void {
    console.log(this.list);
  }

  showDropDown: boolean = false;

  @Input() list: any[];

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();


  checkedList: any[];
  currentSelected: {};

  constructor() {
    this.checkedList = [];
  }
  getSelectedValue(status: Boolean, value: String) {
    if (status) {
      this.checkedList.push(value);
    } else {
      var index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }

    this.currentSelected = { checked: status, id: value };

    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }

  getEventTypeIdName(id: number) {
    if (id == 1) {
      return 'Absence';
    }
    if (id == 2) {
      return 'Orders';
    }
    if (id == 3) {
      return 'Generic';
    }
    return 'Unknown Event Type';
  }

  getCheckedListString() {
    var string = "";
    for (var i = 0; i < this.checkedList.length; i++) {
      if (this.checkedList[i] == 1) {
        string.concat('Absence, ')
      }
      if (this.checkedList[i] == 2) {
        return 'Orders, ';
      }
    }
  }

}
