import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { QChartAxisType, QChartData, QChartInterval, QChartType } from '../q-chart/q-chart-model';

@Component({
  selector: 'q-chart',
  templateUrl: './q-chart.component.html',
  styleUrls: ['./q-chart.component.css']
})

export class QChartComponent implements OnInit {

  highChart: Chart;
  _qChartData: QChartData;

  get qChartData(): QChartData {
    return this._qChartData;
  }
  @Input() set qChartData(value: QChartData) {
    this._qChartData = value;

    if (value) {
      this.initialise();
    }
  }

  constructor() { }

  ngOnInit() {
  }

  initialise() {
    const chartOptions: Highcharts.Options = {
      chart: {
        plotShadow: false,
      },
      exporting: {
        enabled: this.qChartData.IsExportEnabled,
      },
      title: {
        text: this.qChartData.ChartTitle
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: this.qChartData.XAxisDisplay.AxisTitle
        },
        categories: this.qChartData.Categories,
        type: this.qChartData.XAxisDisplay.AxisType,

        dateTimeLabelFormats: {
          day: (this.qChartData.XAxisDisplay.LabelFormat == null) ? '%d %b %y' : this.qChartData.XAxisDisplay.LabelFormat
        },
        labels: {
          format: this.qChartData.XAxisDisplay.AxisType === QChartAxisType.DateTime ? null : this.qChartData.XAxisDisplay.LabelFormat,
          // formatter:function()
          // {
          //   return null;
          // }
        },

        plotBands: this.qChartData.HighlightSegment == null ? null :
          [{
            color: this.qChartData.HighlightSegment.Color,
            label: {
              text: this.qChartData.HighlightSegment.BandTitle,
              align: 'center',
              // align: 'left', // Positioning of the label.
              // Default to center. x: +10 // Amount of pixels the label will be repositioned according to the alignment. 
            },
            from: this.qChartData.HighlightSegment.FromDate == null ? this.qChartData.HighlightSegment.FromDataItemNo :
              Date.UTC(this.qChartData.HighlightSegment.FromDate.getFullYear(),
                this.qChartData.HighlightSegment.FromDate.getMonth(),
                this.qChartData.HighlightSegment.FromDate.getDate()),
            // from: Date.UTC(this.QChartData.StartDate.getFullYear(), this.QChartData.StartDate.getMonth(), this.QChartData.StartDate.getDate()),
            to: this.qChartData.HighlightSegment.ToDate == null ? this.qChartData.HighlightSegment.ToDataItemNo :
              Date.UTC(this.qChartData.HighlightSegment.ToDate.getFullYear(),
                this.qChartData.HighlightSegment.ToDate.getMonth(),
                this.qChartData.HighlightSegment.ToDate.getDate())
            // to: Date.UTC(2018, this.QChartData.StartDate.getMonth(), 6)
            // Or if the plotSeries is NOT defined as a datetime, can say 2nd to the 3rd data as shown below:
            //  from: 1,
            //  to: 2
          }]

      },

      yAxis: {
        title: {
          text: this.qChartData.YAxisDisplay.AxisTitle
        },
        type: this.qChartData.YAxisDisplay.AxisType,
      }
      ,
      plotOptions: {

        column: { // Leave empty for customisation
          showInLegend: this.qChartData.IsShowLegend,
          borderColor: this.qChartData.SeriesValueBorderColor,
          dataLabels: {
            enabled: this.qChartData.IsShowSeriesValueLabels,
            color: this.qChartData.SeriesValueLabelsColor,
            style: {
              textOutline: '' // Remove the shadow on labels
            }
          },
        },
        bar: { // Leave empty for customisation
          showInLegend: this.qChartData.IsShowLegend,
          borderColor: this.qChartData.SeriesValueBorderColor,
          dataLabels: {
            enabled: this.qChartData.IsShowSeriesValueLabels,
            color: this.qChartData.SeriesValueLabelsColor,
            style: {
              textOutline: '' // Remove the shadow on labels
            }
          },
        },
        pie: {
          showInLegend: true,
          borderColor: this.qChartData.SeriesValueBorderColor,
          allowPointSelect: this.qChartData.IsShowLegend,
          startAngle: this.qChartData.ChartType !== QChartType.SemiDougnut ? 0 : -90,
          endAngle: this.qChartData.ChartType !== QChartType.SemiDougnut ? 0 : 90,
          center: ['50%', '50%'],
          dataLabels: {
            enabled: true, // this.QChartData.IsShowSeriesValueLabels,
            format: this.qChartData.YAxisDisplay.LabelFormat,
            color: this.qChartData.SeriesValueLabelsColor,
            style: {
              textOutline: '' // Remove the shadow on labels
            }
          }

        },
        series: {
          shadow: this.qChartData.IsShowSeriesShadow,

          // pointStart: Date.UTC(2018, 9, 4), // #here you can mention your start date
          // pointInterval: 24 * 3600 * 1000 // 1 Day
        }
      }, // Leave empty for customisation
      legend: {
        itemStyle: {
          fontWeight: '500', // semibold
          color: this.qChartData.SeriesValueLabelsColor,
        }
      }
    };

    switch (this.qChartData.ChartType) {
      case QChartType.StackedColumn:
        chartOptions.chart.type = QChartType.Column;
        chartOptions.plotOptions.column.stacking = 'normal';
        break;
      case QChartType.StackedBar:
        chartOptions.chart.type = QChartType.Bar;
        chartOptions.plotOptions.bar.stacking = 'normal';
        break;
      case QChartType.Dougnut:
      case QChartType.SemiDougnut:
        chartOptions.chart.type = QChartType.Pie;
        break;
      default:
        chartOptions.chart.type = this.qChartData.ChartType;
    }

    if (this.qChartData.XAxisDisplay.AxisType === QChartAxisType.DateTime) {
      this.setDateTimePlotOptions(chartOptions);
    }

    // if (this.QChartData.XAxisDisplay.LabelFormat != null && this.QChartData.XAxisDisplay.AxisType == QAxisType.DateTime)
    // {
    //   //Set format based on day, week, year, etc
    //   Utilities.log(chartOptions.xAxis);
    //   chartOptions.xAxis.dateTimeLabelFormats.Day = '%d %b %y';
    //   //chartOptions.xAxis.dateTimeLabelFormats.Month = '%b %y';
    // }

    const chart = new Chart(chartOptions);
    this.setDataSeries(chart);
    this.highChart = chart;

    // chart.ref$.subscribe(ref=>
    // {
    //   let opt = ref.options;
    //   opt.plotOptions.series.pointIntervalUnit = 'day';
    // });
  }

  private setDateTimePlotOptions(options: Highcharts.Options) {


    // Set Plot Options
    if (this.qChartData.StartDate != null) {
      const startDate = new Date(this.qChartData.StartDate);
      options.plotOptions.series.pointStart = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    }

    //#region plotOptions
    let interval = 0;
    let intervalUnit: any = 'day';
    switch (this.qChartData.Interval) {
      case QChartInterval.Seconds: // milliseconds
        interval = 1000;
        break;
      case QChartInterval.Minute: // milliseconds
        interval = 1000 * 60;
        break;
      case QChartInterval.Hours: // milliseconds
        interval = 1000 * 3600;
        break;
      case QChartInterval.Week: // milliseconds
        interval = 1000 * 3600 * 24 * 7;
        break;
      case QChartInterval.Month:
        intervalUnit = 'month';
        break;
      case QChartInterval.Year:
        intervalUnit = 'year';
        break;
      default: // Day
        intervalUnit = 'day';
        break;
    }

    if (interval !== 0) {
      options.plotOptions.series.pointInterval = interval;
    } else {
      options.plotOptions.series.pointIntervalUnit = intervalUnit;
    }
    //#endregion plotOptions
  }

  private setDataSeries(chart: Chart) {

    // Series options: https://api.highcharts.com/class-reference/Highcharts.Series
    if (this.qChartData.IsPieType()) {
      const pieData: any[] = [];
      for (const item of this.qChartData.PieDataSeries) {
        pieData.push(
          {
            name: item.Name,
            y: +item.Data, // Use Unary operator to ensure it is a number
            sliced: item.IsSliced,
            selected: item.IsSelected
          }
        );
      }

      chart.addSeries(
        {
          data: pieData,
          innerSize: (this.qChartData.ChartType === QChartType.Dougnut || this.qChartData.ChartType === QChartType.SemiDougnut) ? '50%' : ''
        });

    } else {
      if (this.qChartData.DataSeries) {
        for (const item of this.qChartData.DataSeries) {
          chart.addSeries(
            {
              name: item.seriesName,
              data: item.dataArray,
              color: item.color,
              visible: item.enabled
              // visible: false <-- setsaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa the series greyed out intially - click to activate it
            });
        }
      } else {
        console.log('No data series present');
      }
    }
  }

  // Call this to create test data
  private createTestData(chartType: QChartType): void {
    // this.QChartData = {

    this.qChartData = new QChartData();
    this.qChartData.ChartTitle = null; // 'Stock Data';
    this.qChartData.ChartType = chartType,
      this.qChartData.StartDate = new Date(), // set to today
      this.qChartData.Interval = QChartInterval.Day,
      // this.QChartData.IsShowSeriesValueLabels = false,
      this.qChartData.Categories = null; // ['01','02','03','04','05','06'];
    this.qChartData.XAxisDisplay = {
      AxisTitle: null,
      AxisType: QChartAxisType.DateTime,
      LabelFormat: null, // '%d %b %y'   //ex- 01 Jan 2016 //https://stackoverflow.com/questions/7101464/how-to-get-highcharts-dates-in-the-x-axis
    };
    this.qChartData.YAxisDisplay = {
      AxisTitle: 'Stock',
      AxisType: null,
      LabelFormat: '{point.name}: {point.y}', // '{point.name}: {point.percentage:.2f}',
    };
    this.qChartData.HighlightSegment = {
      BandTitle: 'Hire Period',
      Color: '#eaecef',
      FromDate: new Date(Date.now()),
      ToDate: new Date(Date.now()),
      FromDataItemNo: null,
      ToDataItemNo: null
    };
    // Add Day to
    this.qChartData.HighlightSegment.FromDate.setDate(this.qChartData.HighlightSegment.FromDate.getDate() + 1);
    this.qChartData.HighlightSegment.ToDate.setDate(this.qChartData.HighlightSegment.FromDate.getDate() + 2);


    // Use this for Pie Types
    if (this.qChartData.IsPieType()) {
      this.qChartData.PieDataSeries = [{
        Data: '35', // Need to use binding for text
        Name: 'Available',
        Color: '#919396',
        IsSliced: false,
        IsSelected: true
      },
      {
        Data: '30', // Need to use binding for text
        Name: 'This Job',
        Color: '#146ec9',
        IsSliced: false,
        IsSelected: false
      },
      {
        Data: '10', // Need to use binding for text
        Name: 'This Job',
        Color: '#7db7f2',
        IsSliced: false,
        IsSelected: false
      }
      ];
    } else {
      // Use this for non Pie Types
      this.qChartData.DataSeries = [{
        seriesName: 'Available', // Need to use binding for text
        dataArray: [35, 35, 40, 40, 15],
        color: '#919396',
        enabled: true
      },
      {
        seriesName: 'This Job', // Need to use binding for text
        dataArray: [8, 20, 20, 20, 8],
        color: '#146ec9',
        enabled: true
      },
      {
        seriesName: 'This EQ List', // Need to use binding for text
        dataArray: [3, 12, 12, 12, 2],
        color: '#7db7f2',
        enabled: true
      }
      ];
    }
  }
}
