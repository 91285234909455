import { LaunchScreenAction } from '../actions/LaunchScreenAction';
import { UriCollection } from '../screenModels/UriCollection';

export class AddUpdateItemModel {
    id: string;
    action: LaunchScreenAction;
    row: any;
    newItem: boolean;
    screenParameters: any[];
    uriCollections?: UriCollection[];
}
