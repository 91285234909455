import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormTemplateItem } from '../../../../models/screenModels/TemplateItem';
import { Utilities } from 'src/app/services/utilities/utilities';
import { ScreenColumn } from 'src/app/models/ScreenColumn';

@Component({
  selector: 'app-price-item',
  templateUrl: './price-item.component.html',
  styleUrls: ['./price-item.component.scss']
})
export class PriceItemComponent implements OnInit {

  readonly qtyMin: string = "qtyMin";
  readonly qtyMax: string = "qtyMax";

  @Input() schemeDetail: any;
  @Input() price: any;
  @Input() durationUnit: string;
  
  _templateItem: FormTemplateItem;
  showDetail: boolean = false;
  fixedPriceColumnItem: ScreenColumn;
  priceColumnitem: ScreenColumn;
  fixedPriceName: string;
  priceName: string;

  constructor(public toastr: ToastrService) { }

  ngOnInit() {

    this.fixedPriceColumnItem = this._templateItem.templateItem.find(m => m.tag === "fixedPrice");
    this.priceColumnitem = this._templateItem.templateItem.find(m => m.tag === "price");

    this.fixedPriceName = Utilities.getLastEntry(this.fixedPriceColumnItem.dataPath);
    this.priceName = Utilities.getLastEntry(this.priceColumnitem.dataPath);
  }

  get templateItem(): FormTemplateItem {
    return this._templateItem;
  }

  @Input()
  set templateItem(value: FormTemplateItem) {

    this._templateItem = value;

    if (value) {

    }
  }

  // get debug(): string {
  //   return JSON.stringify(this.price);
  // }

  // get quantity(): string {
    
  //   let result: string = "";
    
  //   result += this.schemeDetail[this.qtyMin];

  //   if (this.schemeDetail[this.qtyMin] && this.schemeDetail[this.qtyMax]) {
  //     result = `${this.schemeDetail[this.qtyMin]} - ${this.schemeDetail[this.qtyMax]}`;
  //   }

  //   return result;
  // }

  onShowDetail(value: boolean) {
    this.showDetail = value;

    // Prevent dialog from being closed
    return false;
  }

}
