import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ContextMenuService, ContextMenuComponent } from 'ngx-contextmenu';
import { ToastrService } from 'ngx-toastr';

import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { FlowService } from '../../../../services/flow/flow.service';

import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { FilterDialogComponent } from '../../dialogs/filter-dialog/filter-dialog.component';
import { ViewDetailsDialogComponent } from '../../dialogs/view-details-dialog/view-details-dialog.component';

import { CardListComponent } from '../card-list/card-list.component';
import { GridListComponent } from '../grid-list/grid-list.component';
import { SectionListComponent } from '../section-list/section-list.component';

import { DataDetail } from '../../../../models/DataDetail';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { MenuItem } from '../../../../models/data/MenuItem';
import { Action } from '../../../../models/actions/Action';
import { ActionURI } from '../../../../models/data/ActionURI';
import { MenuNode } from '../../../../models/menu-tree/MenuNode';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { PostResponse } from '../../../../models/PostResponse';
import { FilterField } from '../../../../models/FilterField';
import { ScreenType, SubScreenType, ActionIdentifier, MenuType, UIDataType } from '../../../../models/Enums';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { GraphDialogComponent } from '../../dialogs/graph-dialog/graph-dialog.component';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { ActionEvent } from 'src/app/models/events/ActionEvent';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { LoadDataEvent } from 'src/app/models/events/LoadDataEvent';
import { ItemClickEvent } from 'src/app/models/events/ItemClickEvent';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { RefreshEvent } from 'src/app/models/events/RefreshEvent';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { Subscription } from 'rxjs';
import { FilterAction } from 'src/app/models/actions/FilterAction';
import { TreeFilterDialogComponent } from '../../dialogs/tree-filter-dialog/tree-filter-dialog.component';
import { TreeFilterEvent } from 'src/app/models/events/TreeFilterEvent';
import { UpdateScreenParametersAction } from 'src/app/models/actions/UpdateScreenParametersAction';
import { UpdateScreenDataEvent } from 'src/app/models/events/UpdateScreenDataEvent';
import { StockGridListComponent } from '../stock-grid-list/stock-grid-list.component';
import { ListDetailGroup } from 'src/app/models/ListDetailGroup';
import { ListDetail } from 'src/app/models/ListDetail';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { IconService } from 'src/app/services/icon/icon.service';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';
import { GenerateDocumentAction, GenerateDocumentHelper } from 'src/app/models/actions/GenerateDocumentAction';
import { el } from 'date-fns/locale';
import { GenerateDocumentDialogComponent } from '../../dialogs/generate-document-dialog/generate-document-dialog.component';
import { clone } from 'lodash';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ItemListComponent implements OnInit, AfterViewInit {

  menuType = MenuType; // So template can see enum

  screenType = ScreenType;
  screenSubType = SubScreenType;
  buttonHeaderHeight = 56;
  loadingIndicator = true;
  updateIndicator = false;
  dataLoaded = false;

  onClickAction: Action[] = null;

  customFilter = '';

  allChecked = 0;
  singleSelection: any;

  pageSize = 25;
  _infinteScrollPageSize = 100;

  // Call parent page that has a collection of actions
  @Output() onAction = new EventEmitter<ActionEvent>();
  @Output() onAdd = new EventEmitter<AddUpdateItemModel>();
  @Output() onViewItem = new EventEmitter<ViewDetailEvent>();
  // @Output() onCloseItem = new EventEmitter<any>();
  @Output() onDeleteCompleted = new EventEmitter<DeleteEvent>();
  @Output() onUpdateCompleted = new EventEmitter<UpdateCompletedEvent>();
  @Output() onTitleUpdated = new EventEmitter<TitleUpdateEvent>(true);
  @Output() onDisplaySwitchContentDialog = new EventEmitter<any>();
  @Output() refreshLists = new EventEmitter();
  @Output() updateScreenData = new EventEmitter<UpdateScreenDataEvent>();


  @Input() id: string;
  @Input() title: string;
  @Input() action: Action;
  @Input() loadScreenDetail = true;
  @Input() row: object[];
  @Input() stockIndicator = false; // Show stock indicator
  @Input() visible = true;
  @Input() sideBarMode: 'over' | 'push';
  @Input() previousDetail: ListDetail;

  _sideBarOpen: boolean;
  toggleContentSwitch: boolean;
  listDetailGroups: any[];
  activeListDetailGroup: ListDetailGroup;
  replaceData: boolean;
  allowExcelExport: boolean;
  excelExportColumns: string;
  @Input()

  public get sideBarOpen(): boolean {
    return this._sideBarOpen;
  }
  public set sideBarOpen(v: boolean) {
    this._sideBarOpen = v;
    if (!this.screenParameters) { this.screenParameters = {}; }
    this.screenParameters.sidebarOpen = v;
    this.screenParameters.sidebarOpenPush = v && this.sideBarMode === 'push';
  }

  @Input() showLoadingIndicator = true;
  @Input() height: number;
  @Input() width: number;
  @Input() screenParameters: any = {};
  @Input() screenUri: string;
  @Input() multiple = false;
  @Input() componentIndex = 0;
  @Input() customId: any;
  @Input() showCheckboxForSingleSelect = false;
  @Input() uri: ActionURI;
  @Input() uriCollections?: UriCollection[] = [];
  @Input() search = true;
  @Input() showData = true;
  @Input() pager = false;
  @Input() rowClick = true;
  @Input() sectionUri: ActionURI; // TODO this is being passed from orderBasket only
  @Input() idName: string; // Used to track name being used for primary key, when deleting an item
  @Input() groupName: string;
  @Input() readOnly = false;

  _screenDetailComponents: ScreenDetail;
  metadata: FTThingDetail[];
  dataDetail: DataDetail;
  _dataUri: string;
  totalRecordCount = 0;

  showSettings = false;
  rows: any[];
  bulkAction = false;
  filterUri: ActionURI;

  pageIndex = 0; 
  searchValue: string;
  previousSearchValue: string;
  sortActive = '';
  sortDirection = '';
  paging = false;

  filterMenuItem: MenuItem; // If defined a filter is available
  filterFields: FilterField[];
  userFilterFields: FilterField[];
  filterActive = false;
  switchContentView = false;

  dataItems: any[];

  @ViewChild('itemList') itemList: ElementRef;
  @ViewChild(GridListComponent) gridListComponent: GridListComponent;
  @ViewChild(StockGridListComponent) stockGridListComponent: StockGridListComponent;
  @ViewChild(CardListComponent) cardListComponent: CardListComponent;
  @ViewChild(SectionListComponent) sectionListComponent: SectionListComponent;
  @ViewChildren(ContextMenuComponent) contextMenus: QueryList<ContextMenuComponent>;

  contextRow: any;
  contextItem: any;
  cardWidth: number; // Used by card list to adjust columns

  _activeComponent: ScreenDetail; // Tracks the active component e.g. grid or card view

  statusMenus: MenuNode[] = [];
  dataDetailSubscription: Subscription;
  filterMenus: MenuItem[];

  constructor(public toastr: ToastrService, private thingService: ThingService, private contextMenuService: ContextMenuService,
    public dialog: MatDialog, private cdr: ChangeDetectorRef, private iconHelper: IconHelper, private iconService: IconService) {
  }

  get screenDetailComponents(): ScreenDetail {
    return this._screenDetailComponents;
  }

  @Input() set screenDetailComponents(value: ScreenDetail) {
    this._screenDetailComponents = value;

    if (value) {

      this.screenDetailSetup();
      this.loadUriData(false);
    }
  }

  get activeComponent(): ScreenDetail {
    return this._activeComponent;
  }

  set activeComponent(value: ScreenDetail) {

    this._activeComponent = value;

    this.bulkAction = false;
    this.filterMenuItem = null;
    this.filterFields = null;
    this.userFilterFields = null;

    // Do we have any bulk actions
    if (this.screenDetailComponents.toolbarItems && this.screenDetailComponents.toolbarItems
      .find(t => t.menuItemType === MenuType.BulkActions)) {
      this.bulkAction = true;
    } else if (this.activeComponent.toolbarItems && this.activeComponent.toolbarItems.find(t => t.menuItemType === MenuType.BulkActions)) {
      this.bulkAction = true;
    }

    // Do we have a filter action
    if (!this.filterMenuItem && (this._activeComponent.toolbarItems || this.screenDetailComponents.toolbarItems)) {
      const toolbarItems = this._activeComponent.toolbarItems ? this._activeComponent.toolbarItems : this.screenDetailComponents.toolbarItems;
      this.filterMenus = toolbarItems.filter(t => t.menuItemType === MenuType.Filter);
      const mainFilterIndex = this.filterMenus.findIndex(f => {
        if (f.action && f.action[0].action === ActionIdentifier.SearchData) {
          return true;
        } else { return false; }
      });

      if (mainFilterIndex > -1 && mainFilterIndex < this.filterMenus.length) {
        this.filterMenuItem = JSON.parse(JSON.stringify(this.filterMenus[mainFilterIndex]));
        this.filterMenus.splice(mainFilterIndex, 1);
      }
    }

    // if (!this.filterMenuItem && this._activeComponent.toolbarItems) {
    //   this.filterMenuItem = this._activeComponent.toolbarItems.find(t => t.menuItemType === MenuType.Filter);
    // }

    if (this.filterMenuItem) {
      // Assume one action for filter
      this.filterFields = (this.filterMenuItem.action[0].actionArgument as FilterAction).filterFields;
      this.userFilterFields = JSON.parse(JSON.stringify(this.filterFields));

      this.userFilterFields.forEach(filter => {
        if (!filter.defaultValue1) { return; }

        filter.filter1 = filter.defaultValue1;
        filter.filter2 = filter.defaultValue2;
        filter.columnId = Utilities.getLastEntry(filter.fieldName);
        this.filterActive = true;
      });
    }
  }

  get listHeight(): number {
    return (this.search ? this.height - this.buttonHeaderHeight : this.height) + 50;
  }

  get loadingMargin(): number {
    let height: number = (this.height / 2) - 70;
    if (height < 0) {
      height = 0;
    }

    return height;
  }

  // If active compoent is a section card list, return all data items.
  get componentPageSize(): number {
    if (this.activeComponent && this.activeComponent.screenType === ScreenType.SectionCardList) {
      return null;
    }
    return this.pageSize;
  }

  get dataUri(): string {
    return this._dataUri;
  }

  @Input()
  set dataUri(value: string) {
    this._dataUri = value;
    if (this.dataUri) {
      this.refresh();
    }
  }

  screenDetailSetup() {

    // TODO this needs to be from UI definitions - manipulating the UI definitions here is a bad solution
    if (this.sectionUri) {
      if (!this.screenDetailComponents.componentScreens.find(m => m.screenType === ScreenType.SectionCardList)) {

        for (const screenComponent of this.screenDetailComponents.componentScreens) {

          if (screenComponent.screenType === ScreenType.ItemCardList) {

            const sectionComponent: ScreenDetail = JSON.parse(JSON.stringify(screenComponent));
            sectionComponent.screenType = ScreenType.SectionCardList;
            sectionComponent.screenTag += '_section';
            this.screenDetailComponents.componentScreens.push(sectionComponent);

            // remove the section card of the remaining screen
            const childCard = screenComponent.cardItems.find(c => c.groupBy != null && c.groupBy.length > 0);
            screenComponent.cardItems = [childCard];
            screenComponent.cardItems[0].groupBy = null;
            screenComponent.cardItems[0].onChangeParentAction = null;
            break;
          }
        }
      }
    }

    if (this.action) {
      this.screenParameters = this.action.actionArgument.screenParameters;

      this.screenParameters = Utilities.setScreenParameters(this.screenParameters, this.screenDetailComponents.requiredScreenParameters,
        this.row, this.uriCollections);
      if (!this.screenParameters) { this.screenParameters = {}; }
      this.screenParameters.sidebarOpen = this.sideBarOpen;
      this.screenParameters.sidebarOpenPush = this.sideBarOpen && this.sideBarMode === 'push';;
    }

    let title: string;
    let subtitle: string;

    if (this.screenDetailComponents.screenTitle) {
      title = Utilities.parseArgumentsFromData(this.screenDetailComponents.screenTitle.text,
        this.screenDetailComponents.screenTitle.argumentIds, this.row, null, this.screenParameters);
    }
    if (this.screenDetailComponents.screenSubtitle) {
      subtitle = Utilities.parseArgumentsFromData(this.screenDetailComponents.screenSubtitle.text,
        this.screenDetailComponents.screenSubtitle.argumentIds, this.row, null, this.screenParameters);
    }

    if (title) {
      this.onTitleUpdated.emit({ id: this.id, title: title, subtitle: subtitle });
    }

    if (this.screenDetailComponents.screenProperties && this.screenDetailComponents.screenProperties.defaultComponentScreen) {
      this.allowExcelExport = this.screenDetailComponents.screenProperties.allowExcelExport;
      this.excelExportColumns = this.screenDetailComponents.screenProperties.excelExportColumns;
    }

    if (this.screenDetailComponents.componentScreens) {

      // Get default component screen
      if (this.screenDetailComponents.screenProperties && this.screenDetailComponents.screenProperties.defaultComponentScreen) {
        const component: ScreenDetail = this.screenDetailComponents.componentScreens
          .find(c => c.screenTag === this.screenDetailComponents.screenProperties.defaultComponentScreen);

        if (component) {
          this.activeComponent = component;
        } else {
          // Can't find default Component, fallback to selecting first in list
          if (this.screenDetailComponents.componentScreens.length >= 1) {
            this.activeComponent = this.screenDetailComponents.componentScreens[0];
          }
        }
      } else if (this.screenDetailComponents.componentScreens.length >= 1) {
        // No default component screen, so select first in list
        this.activeComponent = this.screenDetailComponents.componentScreens[0];
      }
    } else {

      this.screenDetailComponents.componentScreens = [this.screenDetailComponents];
      this.activeComponent = this.screenDetailComponents;

    }

    this.switchContentView = (this.screenDetailComponents.screenType === ScreenType.SwitchContentView);
    this.toggleContentSwitch = (this.screenDetailComponents.screenType === ScreenType.SwitchContentView && this.screenDetailComponents.screenTitle.text == 'Asset management');
    if (this.toggleContentSwitch) {
      this.listDetailGroups = [];
      for (const component of this.screenDetailComponents.componentScreens) {

        const group: ListDetailGroup = new ListDetailGroup();
        group.items = [];

        const item: ListDetail = new ListDetail();
        item.screenDetail = component;
        item.action = this.action;
        item.row = this.row;
        item.loadScreenDetail = false;

        group.items.push(item);
        group.activeItem = item;

        this.listDetailGroups.push(group);

        if (this.activeComponent === component) {
          this.activeListDetailGroup = group;
        }
      }

      console.log(this.listDetailGroups);
    }

    // Make sure item selected for toggle
    if (this.screenDetailComponents.toolbarItems) {
      for (const item of this.screenDetailComponents.toolbarItems) {
        item.value = Utilities.uniqueId();
      }

      for (const item of this.screenDetailComponents.toolbarItems) {

        if (item.menuItemType === MenuType.ToggleGroup && !item.parentMenuItemId) {

          let childItem = this.screenDetailComponents.toolbarItems.find(m => m.parentMenuItemId === item.menuItemId && m.active);
          if (childItem) {
            item.value = childItem.value;
          } else {
            childItem = this.screenDetailComponents.toolbarItems.find(m => m.parentMenuItemId === item.menuItemId);
            childItem.active = true;
            item.value = childItem.value;
          }
        }
      }
    }
  }

  ngOnInit() {

    // Make sure lookups and flows have been loaded and cached. Need to move to a central settings component
    if (!FlowService.flowGroups) {
      this.thingService.getFlowGroupFlowAndLookupsStatus().subscribe(results => {
        FlowService.setup(results[0], results[1], results[2], results[3], results[4]);

      });
    }

    if (this.loadScreenDetail) {

      this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
        .subscribe(result => {
          this.screenDetailComponents = result; // Property update will call screenDetailSetup & loadUriData
        }, error => this.onGetDetailFailed(error));

    }
  }

  ngAfterViewInit() {
    // this.updateWidth();
  }

  goPrevious() {
    this.onItemClick({ action: [this.previousDetail.action], row: null, newItem: true });
  }

  onResize(event) {
    this.updateWidth();
  }

  updateWidth() {
    this.cardWidth = this.itemList.nativeElement.offsetWidth;
  }

  setContextItem($event) {
    //This is for setting card context items. This area needs refactoring and this is a temp resolution.
    this.gridListComponent.contextItem = $event;
    this.gridListComponent.contextRow = $event;
  }

  exportToExcel(){
    const response = confirm("Do you wish to export this data to Excel?");
    if(!response){
      return;
    }

    if (this.dataUri) {
      var excelUri = this.dataUri.replace("odata","oexcel");
      excelUri = excelUri.replace("oview","oexcel");
      excelUri = excelUri.replace("data","oexcel");
      excelUri = excelUri.replace("view","oexcel");

      if(this.excelExportColumns){
        if(excelUri.includes("$")){
          //There is already an operator in the endpoint
          excelUri = `${excelUri}&$select=${this.excelExportColumns}`
        }
        else{
          excelUri = `${excelUri}$select=${this.excelExportColumns}`
        }
      }

      this.loadingIndicator = true;
      this.thingService.getExcelDocument(excelUri).subscribe(x => 
        {
          this.loadingIndicator = false;
          this.saveExcelFile(x)
        });
    } else {
      var excelUri = this.filterUri.dataUri.toString().replace("odata","oexcel");
      excelUri = excelUri.replace("oview","oexcel");
      excelUri = excelUri.replace("data","oexcel");
      excelUri = excelUri.replace("view","oexcel");

      if(this.excelExportColumns){
        if(excelUri.includes("$")){
          //There is already an operator in the endpoint
          excelUri = `${excelUri}&$select=${this.excelExportColumns}`
        }
        else{
          excelUri = `${excelUri}?$select=${this.excelExportColumns}`
        }
      }

      this.loadingIndicator = true;
      this.thingService.getExcelDocument(excelUri).subscribe(x => 
        {
          this.loadingIndicator = false;
          this.saveExcelFile(x)
        });
    }
  }

  saveExcelFile(data){
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }

  loadUriData(includeScreenDetail: boolean) {

    let uris: ActionURI;
    let secondaryUris: ActionURI[] = [];

    // If paging disabled, clear page size
    if (!this.pager) {
      this.pageSize = this._infinteScrollPageSize;
    }

    if (this.uri) {
      uris = this.uri;
    } else {

      const action: Action = JSON.parse(JSON.stringify(this.action));

      for (const uri of action.actionArgument.uris) {

        if (uri.dataUri) {
          uri.dataUri = Utilities.parseArgumentsFromData(uri.dataUri, uri.dataUriArgs, this.row);
        }
      }

      if (action.actionArgument.uris.length === 1) {
        uris = action.actionArgument.uris[0];
      } else {

        if (this.screenDetailComponents && this.screenDetailComponents.grid) {
          uris = action.actionArgument.uris.find(u => u.name === this.screenDetailComponents.grid.dataObjectKey);
          secondaryUris = action.actionArgument.uris.filter(u => u.name != this.screenDetailComponents.grid.dataObjectKey);
        } else if (this.screenDetailComponents && this.screenDetailComponents.cardItems) {
          uris = action.actionArgument.uris.find(u => u.name === this.screenDetailComponents.cardItems[0].dataObjectKey);
          secondaryUris = action.actionArgument.uris.filter(u => u.name != this.screenDetailComponents.cardItems[0].dataObjectKey);
        } else {
          //Find first screen with grid
          if(this.screenDetailComponents.componentScreens){
            var gridScreen = this.screenDetailComponents.componentScreens.find(scr => !!scr.grid);
            if (gridScreen && gridScreen.grid) {
              uris = action.actionArgument.uris.find(u => u.name === this.screenDetailComponents.componentScreens[0].grid.dataObjectKey);
              secondaryUris = action.actionArgument.uris.filter(u => u.name != this.screenDetailComponents.componentScreens[0].grid.dataObjectKey);
            }
            else {
              // console.log('Error: can\'t identify URIs');
              alert('ERROR: Need to identify uris');
            }
          }
          else {
            // console.log('Error: can\'t identify URIs');
            alert('ERROR: Need to identify uris');
          }
        }
      }
    }

    if (uris) {

      // if (!this.uriCollections.find(u => u.name === uris.name))
      //   this.uriCollections.push({ name: uris.name, uris: uris, dataValues: [] });

      this.filterUri = uris;

      if (uris.dataUri && this.screenDetailComponents.screenSubType !== SubScreenType.StockLevelGrid) {

        if (includeScreenDetail) {
          // uris.dataUri
          this.thingService.getDataScreenDetail(uris.metaUri, null, this.action.actionArgument.screenUri,
            0, this.componentPageSize, null, null, this.customFilter, this.searchValue)
            .subscribe(result => {
              this.getDataDetailWithHeaderSuccessful(uris.name, false, result[1], result[0], result[2]);
              var sortParts = [null, null];
              if(this.activeComponent){
                if(this.activeComponent.grid && this.activeComponent.grid.orderBy){
                  sortParts = this.activeComponent.grid.orderBy.split('|');
                }
                else if(this.activeComponent.cardItems && this.activeComponent.cardItems[0].orderBy){
                  sortParts = this.activeComponent.grid.orderBy.split('|');
                }
              }
              this.onLoadData({ pageIndex: this.pageIndex, pageSize: this.componentPageSize, sortColumn: sortParts[0], sortDirection: sortParts[1] });
              console.log(this.dataDetail.dataItems)
              console.log(this.dataItems);
            }, error => this.onGetDetailFailed(error));

        } else {
          this.thingService.getData(uris.metaUri, null, 0, this.componentPageSize, null, null, this.customFilter, this.searchValue)
            .subscribe(result => {
              this.getDataDetailWithHeaderSuccessful(uris.name, false, result[1], result[0], null);
              var sortParts = [null, null];
              if(this.activeComponent){
                if(this.activeComponent.grid && this.activeComponent.grid.orderBy){
                  sortParts = this.activeComponent.grid.orderBy.split('|');
                }
                else if(this.activeComponent.cardItems && this.activeComponent.cardItems[0].orderBy){
                  sortParts = this.activeComponent.grid.orderBy.split('|');
                }
              }
              this.onLoadData({ pageIndex: this.pageIndex, pageSize: this.componentPageSize, sortColumn: sortParts[0], sortDirection: sortParts[1] });
              // console.log(this.dataDetail?.dataItems)
              // console.log(this.dataItems);
            }, error => this.onGetDetailFailed(error));
        }

      } else {

        this.thingService.getFTThingDetail(uris.metaUri)
          .subscribe(result => {
            this.metadata = result;
            // console.log('got metadata - set loading indicator false');
            // this.loadingIndicator = false;
          });
      }
    } else {

      Utilities.log(JSON.stringify(this.action));
      Utilities.log('uris not present');
    }

    if(secondaryUris){
      secondaryUris.forEach(uri => {
        this.thingService.getData(uri.metaUri, uri.dataUri, 0, this.componentPageSize, null, null, this.customFilter, this.searchValue)
          .subscribe(result => {
            this.getSecondaryDataDetailWithHeaderSuccessful(uri.name, false, result[1], result[0]);
          }, error => this.onGetDetailFailed(error));
      });
    }
  }

  private getSecondaryDataDetailWithHeaderSuccessful(name: string, paging: boolean, dataDetail: DataDetail, columns?: FTThingDetail[]){
    let uriCollection = this.uriCollections.find(u => u.name === name);

    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail ? dataDetail.dataItems : null };
      this.uriCollections.push(uriCollection);
      return;
    }

    if (columns) { uriCollection.dataMetadata = columns; }

    uriCollection.dataValues = dataDetail.dataItems;
  }

  private getDataDetailWithHeaderSuccessful(name: string, paging: boolean, dataDetail: DataDetail, columns?: FTThingDetail[],
    screenDetailComponents?: ScreenDetail) {

    let uriCollection = this.uriCollections.find(u => u.name === name);

    if (!uriCollection) {
      uriCollection = { name: name, dataMetadata: columns, dataValues: dataDetail ? dataDetail.dataItems : null };
      this.uriCollections.push(uriCollection);
    }

    if (columns) { uriCollection.dataMetadata = columns; }

    if (!!dataDetail && !!dataDetail.dataItems) {

      if (paging && !this.replaceData) {
        var newItems = [];
        if (!!dataDetail && !!dataDetail.dataItems && dataDetail.dataItems.length > 0) {
          if (this.dataDetail.dataItems.length <= dataDetail.totalRecordCount)
          {
            var keys = Object.keys(dataDetail.dataItems[0]);
            var ftDetailWithPk = this.metadata.find(x => x.primaryKey == true);

            for (let i = 0; i < dataDetail.dataItems.length; i++) {
              var currentItem = this.dataDetail.dataItems.find(x => x[ftDetailWithPk.javaScriptName] == dataDetail.dataItems[i][ftDetailWithPk.javaScriptName]);
              if (!!currentItem) {
                var index = this.dataDetail.dataItems.indexOf(currentItem);
                //found the item but make sure all the properties are the same otherwise it's been updated
                keys.forEach(x => {
                  if (currentItem[x] != dataDetail.dataItems[i][x]) {
                    currentItem[x] = dataDetail.dataItems[i][x];
                    this.dataDetail.dataItems[index] = currentItem;
                  }
                });
                continue;
              }
              else {
                newItems.push(dataDetail.dataItems[i]);
              }
            }
          }
          else{
            var keys = Object.keys(dataDetail.dataItems[0]);
            // if total count from API is less than current data, that means something has been deleted
            for (let i = 0; i < dataDetail.dataItems.length; i++) {
              var dataIds = dataDetail.dataItems.map(x => x[keys[0]]);
              var currentDataIds = this.dataDetail.dataItems.map(x => x[keys[0]]);

              var deletedId = currentDataIds.find(x => !dataIds.includes(x));

              if (!!deletedId)
              {
                var deletedItem = this.dataDetail.dataItems.find(x => x[keys[0]] == deletedId);
                if (!!deletedItem) {
                  var index = this.dataDetail.dataItems.indexOf(deletedItem);
                  if (index > -1)
                  {
                    this.dataDetail.dataItems.splice(index, 1);
                    break;
                  }
                }
              }
            }            
          }
        }

        if (newItems.length > 0)
        {
          dataDetail.dataItems = this.dataDetail.dataItems.concat(newItems);
        }
        dataDetail.returnRecordCount = dataDetail.dataItems.length;

      }

      this.dataDetail = dataDetail;
      uriCollection.dataValues = this.dataDetail.dataItems;
      this.dataLoaded = true;
      this.totalRecordCount = dataDetail.totalRecordCount;
      this.dataItems = this.dataDetail.dataItems;
      this.replaceData = false;
    }

    if (screenDetailComponents) {

      this.screenDetailComponents = screenDetailComponents; // Property update will call screenDetailSetup & loadUriData
    }

    if (columns) {
      this.metadata = columns;
    }

    if (!this.cardWidth) {
      this.updateWidth();
    }

    this.loadingIndicator = false;
    this.cdr.detectChanges();


  }

  private onGetDetailFailed(error: any) {
    setTimeout(() =>
      this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
        null, { closeButton: true, tapToDismiss: true }));
  }

  public getFontAwesomeIcon(id: any) {
    var iconHex = this.iconHelper.getFontAwesomeHex(+id);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  public onLoadData(event: LoadDataEvent) {

    this.loadingIndicator = true;

    let filter = this.customFilter;
    let filter1;
    let filter2;
    let filterValues;
    let tags: string[] = [];

    if (this.filterMenuItem) {

      for (const c of this.userFilterFields) {
        if ((c.filterLookupValues && c.filterLookupValues.value != null && c.filterLookupValues.value.toString() !== '')
          || c.filter1 != null) {

          const md = this.metadata.find(d => d.javaScriptName === c.columnId);
          if (!md) {
            continue;
          }
          var dataType = DataUtils.dataTypeToUIDataType(md.dataType, md.referenceTo);
          if(dataType == UIDataType.Number && c.displayPropertyName){
            //Likely an ID for an external FTThing
            var referencedUriCollection = this.getReferenceCollection(c.displayPropertyName);
            if(referencedUriCollection && referencedUriCollection.dataValues){
              dataType = UIDataType.Unknown;
            }
          }

          filterValues = c.filterLookupValues ? c.filterLookupValues.value : null;
          filter1 = c.filter1;
          filter2 = c.filter2;

          if (filter && filter.length > 0 && dataType !== UIDataType.TagList) {
            filter += ' and ';
          }

          switch (dataType) {
            case UIDataType.DateTime:
              filter += `${c.columnId} ge '${Utilities.jsonDate(new Date(filter1.toString()))}'`;

              if (filter2) {
                filter += ` and ${c.columnId} le '${Utilities.jsonDate(new Date(filter2.toString()))}'`;
              }
              break;

            case UIDataType.Number:
              filter += `${c.columnId} ge ${filter1.toString()}`;

              if (filter2) {
                filter += ` and ${c.columnId} le ${filter2.toString()}`;
              }
              break;
            case UIDataType.TagList:
              tags = filterValues;
              break;

            case UIDataType.Bool:
              filter += `${c.columnId} eq ${filter1.toString()}`;
              break;

            case UIDataType.String:
            case UIDataType.FlowStatus:
            case UIDataType.Lookup:
              // or c.filterType > 0??
              if (dataType === UIDataType.FlowStatus) {
                const stringIds: string[] = [];

                for (const f of filterValues) {
                  stringIds.push(f.ftFlowStatusID);
                }
                filterValues = stringIds;
              }
              if (dataType === UIDataType.Lookup) {
                const stringIds: string[] = [];

                for (const f of filterValues) {
                  stringIds.push(f.value);
                }
                filterValues = stringIds;
              }

              if (filterValues && filterValues.length > 1) {
                filter += '(';
              }

              let filterIndex = 0;

              for (const f of filterValues) {

                if (filterIndex > 0) {
                  filter += ' or ';
                }

                filter += `${c.columnId} eq '${filterValues[filterIndex]}'`;

                filterIndex++;
              }

              if (filterValues && filterValues.length > 1) {
                filter += ')';
              }
              break;
            
            case UIDataType.Unknown:
              if (filterValues && filterValues.length > 1) {
                filter += '(';
              }
  
              let unknownFilterIndex = 0;

              for (const f of filterValues) {
                if (unknownFilterIndex > 0) {
                  filter += ' or ';
                }
 
                filter += `${c.columnId} eq '${filterValues[unknownFilterIndex]}'`;
  
                unknownFilterIndex++;
              }
  
              if (filterValues && filterValues.length > 1) {
                filter += ')';
              }
            break;
            default:
              filter += filter1;
              break;
          }
        }
      }
    }

    Utilities.log(filter);

    if (event.pageIndex != null) {
      this.pageIndex = event.pageIndex;
    }

    if (event.pageSize != null) {
      this.pageSize = event.pageSize;
    }

    if (event.sortColumn) {
      this.sortActive = event.sortColumn;
    }

    if (event.sortDirection) {
      this.sortDirection = event.sortDirection;
    }

    if (event.virtualScroll) {
      if ((this.componentPageSize * (this.pageIndex + 1)) < this.totalRecordCount)
      {
        this.paging = true;
        this.pageIndex++;
      }
      else {
        this.loadingIndicator = false;
        return;
      }
    }

    if (this.dataDetailSubscription) {
      this.dataDetailSubscription.unsubscribe();
    }

    if (this.dataUri) {
      // // TODO this is the wrong way to do this but sufficient until all refactored to use uriCollections
      // // let name = this.uriCollections[0].name;

      // this.uriCollections.forEach(uc => {
      //   this.thingService.getDataDetailWithHeader(this.dataUri, this.pageIndex, this.componentPageSize,
      // this.sortActive, this.sortDirection, filter, this.searchValue)
      //     .subscribe(result => this.getDataDetailWithHeaderSuccessful(uc.name, this.paging, result),
      // error => this.onGetDetailFailed(error));
      // });
      this.dataDetailSubscription = this.thingService.getDataDetailWithHeader(this.dataUri.toString(), this.pageIndex, this.componentPageSize, this.sortActive,
        this.sortDirection, filter, this.searchValue, null, null, tags, event.customParams)
        .subscribe(result => this.getDataDetailWithHeaderSuccessful(this.filterUri.name, this.paging, result), error => this.onGetDetailFailed(error));
    } else {

      const dataUri = this.filterUri.dataUri.toString();

      this.dataDetailSubscription = this.thingService.getDataDetailWithHeader(this.filterUri.dataUri.toString(), this.pageIndex, this.componentPageSize, this.sortActive,
        this.sortDirection, filter, this.searchValue, null, null, tags, event.customParams)
        .subscribe(result => {
          this.getDataDetailWithHeaderSuccessful(this.filterUri.name, this.paging, result);
        }, error => this.onGetDetailFailed(error));
    }
  }

  getReferenceCollection(displayPath: string) : UriCollection{
    var dataPath = new DataPath(displayPath);
    var referencedUriCollection = this.uriCollections.find(uc => uc.name == dataPath.rootObjectName)
    return referencedUriCollection;
  }

  public onClick(event: MouseEvent, item: MenuItem) {

    console.log(item);
    item.action.forEach(action => {

      switch (item.menuItemType) {
        case MenuType.ToggleGroup:
          for (const menuItem of this.screenDetailComponents.toolbarItems.filter(m => m.parentMenuItemId === item.parentMenuItemId)) {
            menuItem.active = (menuItem.menuItemId === item.menuItemId);
          }

          break;
        case MenuType.Filter:
          const actionArgument = action.actionArgument as LaunchScreenAction;
          if (actionArgument.screenType === ScreenType.TreeView) {

            //look at main component??? its doing 56 + 50 + 2????
            const height = Utilities.innerHeight(108) + 50;

            const screenParameters = Utilities.setScreenParameters(action.actionArgument.screenParameters,
              this.screenDetailComponents.requiredScreenParameters, null, this.uriCollections);

            const trigger = new ElementRef(event.target) as ElementRef;
            const rect = trigger.nativeElement.getBoundingClientRect();
            const dialogRef = this.dialog.open(TreeFilterDialogComponent, {
              minWidth: '700px',
              width: 'auto',
              //width: `${Utilities.innerWidth()}px`,
              height: `${height}px`,
              // position: { right: `${Utilities.innerWidth() - rect.right}px`, top: `${rect.bottom + 10}px` },
              data: {
                action: action,
                uriCollections: this.uriCollections,
                screenParameters: screenParameters,
                heightOffset: rect.bottom,
                height: this.height,
                checkedFilters: item.checkedFilters,
              },
            });

            dialogRef.afterClosed().subscribe(result => {
              Utilities.log('View Details Dialog closed');
            });

            dialogRef.componentInstance.filterSet.subscribe((treeFilter: TreeFilterEvent) => {
              item.filterActive = (treeFilter.filter && treeFilter.filter !== '');
              item.checkedFilters = treeFilter.checkedIds;
              this.setFilter(treeFilter.filter);
            });
          }
          break;
        default:
          this.onItemClick({ action: item.action, row: null, newItem: true });
      }
    });

    // if (action.action === ActionIdentifier.LaunchScreen) {

    //   const parsedAction = Utilities.parseAction(action, this.row, this.screenParameters);
    //   const launchScreenAction = parsedAction.actionArgument as LaunchScreenAction;
    //   this.onAdd.emit({ action: launchScreenAction, newItem: true, screenParameters: this.screenParameters, row: null });
    // } else if (action.action === ActionIdentifier.DeleteItem) {
    //   this.deleteSelected(action);
    // } else if (action.action === ActionIdentifier.ReloadData) {
    //   this.reloadData(action);
    // } else {
    //   this.toastr.error('Action ' + action.action + ' not defined');
    // }
  }

  private reloadData(action: Action) {

    const dataUri: string = Utilities.parseArgumentsFromData(action.actionArgument.dataUri, action.actionArgument.dataUriArgs,
      null, this.row, this.screenParameters);
    // TODO should use uriCollections to refresh and so know the name

    const name = this.uriCollections[0].name;

    console.log('reloadData set loading indicator true');
    this.loadingIndicator = true;

    this.thingService.getDataDetailWithHeader(dataUri, this.pageIndex, this.componentPageSize, this.sortActive, this.sortDirection,
      null, this.searchValue)
      .subscribe(result => this.getDataDetailWithHeaderSuccessful(name, this.paging, result), error => this.onGetDetailFailed(error));
  }

  public deleteSelected(action: Action) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { message: 'Are you sure you want to delete the selected items?' }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

        const arg: string = action.actionArgument.editUriArgs[0];
        this.idName = arg.substring(arg.lastIndexOf('.') + 1);

        const selectedIds = [];

        for (const r of this.dataDetail.dataItems) {
          if (r.selected) {
            selectedIds.push(r[this.idName]);
          }
        }

        this.loadingIndicator = true;
        this.thingService.deleteItem(action.actionArgument.editUri, selectedIds)
          .subscribe(results => this.onDeleteItemSuccessful(results), error => this.onDeleteItemFailed(error));
      }

    });

  }

  private onDeleteSingleItemSuccessful(result: PostResponse) {

    this.refresh();
  }


  private onDeleteItemSuccessful(result: PostResponse[]) {

    for (const r of result) {
      if (r.errorCode === 0) {
        this.refresh();
        this.onDeleteCompleted.emit({ name: this.idName, value: r.returnedObject });
      } else {

        this.toastr.error(r.errorMessage, null, { closeButton: true, tapToDismiss: true });

      }
    }
  }

  private onDeleteItemFailed(error: any) {

    this.loadingIndicator = false;
    this.toastr.error(`Unable to delete data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
  }

  public getFlowStatusIconId(flowStatusId: number) {

    const item = FlowService.getFlowStatus(flowStatusId, null);
    return (item != null ? item.iconID : '');

  }

  public getFlowStatusName(flowStatusId: number) {

    const item = FlowService.getFlowStatus(flowStatusId, null);
    return (item != null ? item.text : '');

  }

  public getFlowStatusStyle(flowStatusId: number) {

    let styles = {};

    const item = FlowService.getFlowStatus(flowStatusId, null);

    if (item != null && item.colour) {
      styles = {
        '-webkit-filter': 'opacity(.5) drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)',
        'filter': 'opacity(.5)  drop-shadow(0 0 0 #' + item.colour + ') contrast(350%)'
      };
    }

    return styles;
  }

  public onItemClick(event: ItemClickEvent) {

    console.log(event);

    if (!event.action) {
      console.log('NO ACTION SUPPLIED');
      return;
    }

    event.action.forEach(actionObj => {
      let action: Action = Utilities.parseAction(actionObj, event.row, this.screenParameters);
      if (!action) { return; }

      const run = Utilities.evaluateCondition(action.condition, null, this.screenParameters, null, event.row);
      if (!run) {
        return;
      }

      Utilities.log(JSON.stringify(action));

      if (action.action === ActionIdentifier.DisplayDialog && action.actionArgument.name === 'MovementHistorySummaryChart') {

        // parse action is not taking into account uri collections
        action = event.action[0];
        const screenParameters = Utilities.setScreenParameters(action.actionArgument.screenParameters,
          this.screenDetailComponents.requiredScreenParameters, event.row, this.uriCollections);
        const dialogRef = this.dialog.open(GraphDialogComponent, {
          minWidth: '800px',
          data: {
            screenParameters: screenParameters,
            action: action
          }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
      } else if (action.action === ActionIdentifier.LaunchScreen) {

        const launchScreenAction = action.actionArgument as LaunchScreenAction;
        if (launchScreenAction.screenType === ScreenType.EditDetails) {
          this.onAdd.emit({ id: launchScreenAction.name, action: launchScreenAction, newItem: true, screenParameters: this.screenParameters, row: null });
        } else {
          this.onAction.emit({ action: action, row: event.row, newItem: event.newItem });
        }
      } else if (action.action === ActionIdentifier.LaunchFlyout) {
        this.onViewItem.emit({ row: event.row, action: action, length: 1 });
      } else if (action.action === ActionIdentifier.DisplayDialog) {

        const actionArgument = action.actionArgument as LaunchScreenAction;
        if (actionArgument.screenType === ScreenType.ViewDetails || actionArgument.screenType === ScreenType.CompositeScreen) {

          const newSParams = Utilities.setScreenParameters(actionArgument.screenParameters,
            this.screenDetailComponents.requiredScreenParameters, event.row, this.uriCollections);

          const dialogRef = this.dialog.open(ViewDetailsDialogComponent, {
            width: '600px',
            data: { action: action, row: event.row, screenParameters: newSParams }
          });

          dialogRef.afterClosed().subscribe(result => {
            Utilities.log('View Details Dialog closed');
          });

        } else if (actionArgument.screenType === ScreenType.SwitchContentView) {

          this.onDisplaySwitchContentDialog.emit({ action: action, row: event.row });

        } else {

          const ab = new ActionBuilder(event.action, event.row, null, this.uriCollections, this.screenParameters, event.completionEvent,
            this.onViewItem, this.thingService, this.toastr, this.dialog);
          ab.PerformAction();
        }

      } else if (action.action === ActionIdentifier.UpdateData) {

        if (action.actionArgument) {

          const actionArg = action.actionArgument as UpdateDataAction;
          if (actionArg.editUri) {
            let updateData;
            if (event.updatedItems) {
              updateData = event.updatedItems;
            } else {
              const newItem: any = Utilities.createItemFromObjectDefaults(actionArg.newObjectDefaults, event.row, event.newItem,
                this.uriCollections, this.screenParameters);

              // If sectionId contains '.' we couldn't parse it so remove the property
              if (newItem['sectionId'] && newItem['sectionId'].toString().indexOf('.') > -1) {
                delete newItem['sectionId'];
              }

              if (this.customId) {
                newItem['sectionId'] = this.customId.id;
              }

              if (actionArg.editUri.includes('/actions/')) {
                updateData = newItem;
              } else {
                updateData = [newItem];
              }
            }
            this.updateIndicator = true;
            this.thingService.postDataDetails(actionArg.editUri, updateData)
              .subscribe(result => {
                this.onPostDataDetailSectionSuccessful(action, result, true, updateData, actionArg.currentItemPath);
                if(actionArg.dialog){
                  this.toastr.success(actionArg.dialog);
                }
              }, error => this.onPostDataDetailFailed(error));
          } else {

            const o = Utilities.createItemFromObjectDefaults(actionArg.newObjectDefaults, event.row, null, this.uriCollections, this.screenParameters);
            this.updateScreenData.emit({ targetScreen: actionArg.resultTargetScreen, targetDataObject: actionArg.resultTargetDataObject, newData: o });
          }
        }
      } else if (action.action === ActionIdentifier.DeleteItem) {
        if (this.dataDetail.dataItems.findIndex(d => d.selected) > -1) {
          this.deleteSelected(action);
        } else {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '450px',
            data: { message: 'Are you sure you want to delete this item?' }
          });

          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              const arg: string = action.actionArgument.editUriArgs[0];
              this.idName = arg.substring(arg.lastIndexOf('.') + 1);

              this.loadingIndicator = true;
              this.thingService.deleteItem(action.actionArgument.editUri, [event.row[this.idName]]).subscribe(x => { this.onDeleteItemSuccessful(x) }, error => this.onDeleteItemFailed(error));
              // this.thingService.deleteSingleItem(action.actionArgument.editUri)
              //   .subscribe(r => this.onDeleteSingleItemSuccessful(r), error => this.onDeleteItemFailed(error));
            }

          });
        }
      } else if (action.action === ActionIdentifier.ReloadData) {
        this.reloadData(action);
      } else if (action.action === ActionIdentifier.UpdateScreenParameters) {
        const updateParamsAction = action.actionArgument as UpdateScreenParametersAction;
        if (updateParamsAction.targetScreen == null) {
          if (updateParamsAction.screenParameters) {
            Object.keys(updateParamsAction.screenParameters).forEach(key => {
              this.screenParameters[key] = updateParamsAction.screenParameters[key];
            });
          }

          if (updateParamsAction.screenProperties) {
            Object.keys(updateParamsAction.screenProperties).forEach(key => {
              this.screenDetailComponents.screenProperties[key] = updateParamsAction.screenProperties[key];
            });
          }
        }

      } else if (action.action === ActionIdentifier.GenerateDocument) {
        const generateDocumentAction = action.actionArgument as GenerateDocumentAction;
        var gdBody = GenerateDocumentHelper.getGenerateDocumentBody(generateDocumentAction, event.row);
        this.dialog.open(GenerateDocumentDialogComponent, {
          width: '450px',
          data: {
            body: gdBody,
            title: generateDocumentAction.title
          }
        })
        .afterClosed()
        .subscribe((documentGenerated) => {
          if(documentGenerated){
            //Respond to later actions
            var nextActions = clone(event);
            nextActions.action = generateDocumentAction.onGenerateAction;
            this.onItemClick(nextActions);
          }
        });
      } else {
        alert('Action ' + action.action + ' not defined in onItemClick');
      }
    });
    // else if (action.action === ActionIdentifier.DeleteItem) {

    //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //     width: '450px',
    //     data: { message: 'Are you sure you want to delete this item?' }
    //   });

    //   dialogRef.afterClosed().subscribe(result => {

    //     if (result) {

    //       this.loadingIndicator = true;
    //       this.thingService.deleteSingleItem(action.actionArgument.editUri)
    //         .subscribe(r => this.onDeleteSingleItemSuccessful(r), error => this.onDeleteItemFailed(error));
    //     }

    //   });
    // }
  }

  private onPostDataDetailSectionSuccessful(action: Action, resultArray: PostResponse[], reloadGrid: boolean, originalRow: any, currentItemPath: string) {

    var result = resultArray[0];
    if(!result){
      var singleResult = resultArray as any;
      if(singleResult.errorCode || singleResult.errorCode === 0){
        result = singleResult as PostResponse;
      }
    }

    switch (result.errorCode) {
      case 0:
        {
          if (result.errorMessage === 'deleted' && action.actionArgument.resultDataMapping && action.actionArgument.resultDataMapping['primaryKey']) {
            const primaryKey = action.actionArgument.resultDataMapping['primaryKey'];
            // tslint:disable-next-line: triple-equals
            const deleteIndex = this.dataItems.findIndex(d => d[primaryKey] == originalRow[primaryKey]);
            if (deleteIndex > -1) {
              this.dataItems.splice(deleteIndex, 1);
            }
          } else if(result.returnedObject.Message) {
            this.toastr.error(result.returnedObject.Message);
           
          } else {

            this.updateIndicator = false;
            let sectionId = -1;

            if (result && result.returnedObject.sectionID) {

              sectionId = result.returnedObject.sectionID;
            }

            this.onUpdateCompleted.emit({
              sectionId: sectionId,
              resultTargetDataObject: action.actionArgument.resultTargetDataObject,
              row: result.returnedObject,
              currentItemPath: currentItemPath,
            });

            if (action.actionArgument.resultDataMapping) {
              for (const key in action.actionArgument.resultDataMapping) {
                if (key) {
                  const entry: string = action.actionArgument.resultDataMapping[key];
                  originalRow[key] = result[0].returnedObject[Utilities.getLastEntry(entry)];
                }
              }
            }

            // if (action.actionArgument.resultTargetDataObject)
            //   action.actionArgument.targetDataObject = action.actionArgument.resultTargetDataObject;
            if (action.actionArgument.targetDataObject) {
              let newItem;
              if (result[0]) { newItem = result[0].returnedObject; }
              // else
              //   newItem = result;

              const dataItems = Utilities.getUriCollection(action.actionArgument.targetDataObject, this.uriCollections);

              const primaryKeyField = action.actionArgument.targetDataObject + 'ID';
              // if (action.actionArgument.resultTargetDataObject == 'orderBasket')
              //   primaryKeyField = 'orderItemID';

              if (newItem[primaryKeyField]) {
                const index = dataItems.dataValues.findIndex(v => v[primaryKeyField] == newItem[primaryKeyField]);
                if (index) {
                  dataItems.dataValues[index] = newItem;
                } else {
                  dataItems.dataValues.push(newItem);
                }
              }
            }
          }

          if (reloadGrid) {
            this.refresh()
            // this.loadData(this.paginator.pageIndex, this.paginator.pageSize);
          }
        }
        break;
      case 1:{
        if(reloadGrid){
          this.refresh();
        }
        
      }
      break;
      case 9:
        {
          this.toastr.error(result.errorMessage);


          this.onUpdateCompleted.emit({
            resultTargetDataObject: action.actionArgument.resultTargetDataObject
            // reloadOrderBasket: action.actionArgument.resultTargetDataObject === 'orderBasket'
          });
        }
        break;
    }
  }

  private onPostDataDetailFailed(error: any) {
    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
      null, { closeButton: true, tapToDismiss: true });
  }

  public updateCompleted(event: any) {
    this.onUpdateCompleted.emit(event);
  }

  public onView(event) {



    if (event) {
      this.onViewItem.emit({
        row: event.row, action: event.action, length: event.length, toolbarItems: this.screenDetailComponents.toolbarItems,
        toolbarItems2: this.activeComponent.toolbarItems
      });
    }
  }

  public onFlowStatus($event: KeyboardEvent, item, row) {

    this.contextItem = item;
    this.contextRow = row;

    const menuIndex: number = this.statusMenus.findIndex(m => m.id == row['activeFlowID']);

    // Must be a better way to get ViewChildren component by index. Tried toArray
    let index = 0;
    let selectedMenu: ContextMenuComponent;
    this.contextMenus.forEach(m => {

      if (index === menuIndex) {
        selectedMenu = m;
      }

      index++;

    });

    if (menuIndex > -1) {

      this.contextMenuService.show.next({
        anchorElement: $event.target,
        // Optional - if unspecified, all context menu components will open
        contextMenu: selectedMenu,
        event: <any>$event,
        item: item,
      });
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  refresh(event?: RefreshEvent) {

    const rowUpdated = false;
    if (event && !event.sectionId && event.primaryKey) {

      const updatedItem = Utilities.findInArray(event.primaryKey, event.row[event.primaryKey], this.dataDetail.dataItems);

      if (updatedItem) {
        const index = this.dataDetail.dataItems.indexOf(updatedItem);
        this.dataDetail.dataItems[index] = event.row;

        if (this.gridListComponent) {
          this.gridListComponent.refresh();
        }
        if (this.cardListComponent) {
          this.cardListComponent.refresh();
        }
        if (this.stockGridListComponent) {
          this.stockGridListComponent.refresh();
        }
      }
    }

    if (!rowUpdated) {
      // Refresh whole grid
      if (this.activeComponent) {
        if (this.activeComponent.screenType === ScreenType.ItemGridList) {
          if (this.gridListComponent) {
            if (!!event && !!event.increment)
            {
              this.gridListComponent.refresh(event.increment);
            }
            else {
              this.gridListComponent.refresh();
            }
          } else if (this.stockGridListComponent) {
            this.stockGridListComponent.refresh();
          }
        } else if (this.activeComponent.screenType === ScreenType.ItemCardList) {
          if (this.cardListComponent) {
            this.cardListComponent.refresh();
          }
        }
        // always reload sections
        // if (this.activeComponent.screenType != ScreenType.SectionCardList) {
        if (this.sectionListComponent) {
          this.sectionListComponent.refresh(event ? event.sectionId : 0);
        }
        // }
      }
    }
  }

  public onFilter() {

    console.log('onFilter');

    const filterDialog = this.dialog.open(FilterDialogComponent, {
      width: '466px',
      data: { title: 'Filter', filterFields: this.userFilterFields, columns: this.metadata, dataUri: this.filterUri.dataUri, uriCollections: this.uriCollections }
    });

    filterDialog.afterClosed().subscribe(result => {

      if (result) {

        console.log('onFilter set loading indicator true');
        this.loadingIndicator = true;

        if (result === 'reset') {

          this.filterActive = false;
          this.userFilterFields = JSON.parse(JSON.stringify(this.filterFields));

        } else {

          this.filterActive = false;

          for (const element of result) {

            const col = this.userFilterFields.find(c => c.fieldName === (element.fieldName as string));

            if (col) {
              col.filterLookupValues = element.filterLookupValues;
              col.filter1 = element.filter1;
              col.filter2 = element.filter2;
              col.columnId = element.columnId;

              if ((col.filterLookupValues.value && col.filterLookupValues.value.toString() !== '') || col.filter1 != null || col.filter2 != null) {
                this.filterActive = true;
              }
            }
          }
        }
      }

      this.refresh();
    });

  }

  private onGetDataDetailFailed(error: any) {
    setTimeout(() =>
      this.toastr.error(`Unable to load data from server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`,
        null, { closeButton: true, tapToDismiss: true })
    );
  }

  getStyles(item: any) {

    return {
      'style': item.style
    };

  }

  getClasses(item: any) {

    return item.class;

  }

  hasClass(item: any, value: string) {

    if (item && item.class) {
      if (item.class.indexOf(value) > -1) {
        return true;
      }
    }

    return false;
  }

  onSearch() {

    if (this.searchValue !== this.previousSearchValue) {
      this.previousSearchValue = this.searchValue;

      this.replaceData = true;
      // this.pageIndex = 0;
      this.refresh();
    }

  }

  onSearchClear() {

    if (this.searchValue) {
      this.searchValue = null;
      this.previousSearchValue = null;
      this.refresh();
    }

  }

  onSettings() {
    if (this.activeComponent.screenType === ScreenType.ItemGridList) {
      if (this.activeComponent.screenSubType === SubScreenType.StockLevelGrid) {
        this.stockGridListComponent.displaySettings();
      } else {
        this.gridListComponent.displaySettings();
      }
    }
  }

  public setFilter(value: string) {

    console.log(`set filter ${value}`);
    this.customFilter = value;
    let customParams = null;
    if (this.activeComponent.screenSubType === SubScreenType.StockLevelGrid) {
      customParams = this.stockGridListComponent.customParams;
    }

    this.replaceData = true;
    this.onLoadData({ pageIndex: 0, customParams: customParams });
  }

  buildContextMenus() {

    // Clear existing menus
    this.statusMenus = [];

    for (const item of this.dataDetail.dataItems) {

      if (item.activeFlowID) {

        const menu: MenuNode = this.statusMenus.find(m => m.id == item.activeFlowID);

        if (!menu) {

          const menuNd = new MenuNode(item.activeFlowID, '');
          this.statusMenus.push(menuNd);

          for (const status of FlowService.getFlowStatuses(item.activeFlowID, item.activeFlowState, false, true)) {

            if (!menuNd.children) {
              menuNd.children = [];
            }

            menuNd.children.push(new MenuNode(status.ftFlowStatusID, status.text));

          }

        }
      }

    }
  }

  onToggleChange(event, menuItem: MenuItem) {

    menuItem.value = event.value;
    this.onClick(null, this.screenDetailComponents.toolbarItems.find(m => m.value == event.value));
  }

  onDisplayChange(event) {

    this.activeComponent = this.screenDetailComponents.componentScreens.find(c => c.screenTag === event.value);

    // if (this.activeComponent.screenType === ScreenType.ItemGridList && this.pageIndex > 0) {
    //   this.pageIndex = 0;
    //   this.paging = false;
    //   this.dataItems = [];
    //   this.onLoadData({ pageIndex: 0 });
    // } else if (this.activeComponent.screenType === ScreenType.ItemCardList) {

    //   if (this.pageIndex > 0) {
    //     this.onLoadData({ pageIndex: 0 });
    //   }

    // This is giving the card view opportunity to reset, so scroll resets to top
    //   setTimeout(() => {

    //     this.dataItems = this.dataDetail.dataItems;
    //   },
    //     200);
    // }
  }

  onSwitch() {

    // Support for only switching between to items
    if (this.listDetailGroups.length === 2) {
      if (this.listDetailGroups[0] === this.activeListDetailGroup) {
        this.activeListDetailGroup = this.listDetailGroups[1];
        this.activeComponent = this.activeListDetailGroup.activeItem.screenDetail;
      } else {
        this.activeListDetailGroup = this.listDetailGroups[0];
        this.activeComponent = this.activeListDetailGroup.activeItem.screenDetail;
      }
    }

    // const u = this.uriCollections.find(u => u.name === this.activeComponent.grid.dataObjectKey);
    // if (u) {
    //   this.filterUri = u.uris;
    //   // this.dataDetail = u.;
    // } else {

    //   const uris = this.action.actionArgument.uris.find(u => u.name === this.activeComponent.grid.dataObjectKey);
    //   this.filterUri = uris;
    //   this.thingService.getData(uris.metaUri, null, 0, this.componentPageSize, null, null, this.customFilter, this.searchValue)
    //     .subscribe(result => {
    //       this.getDataDetailWithHeaderSuccessful(uris.name, false, result[1], result[0], null);
    //       this.onLoadData({ pageIndex: this.pageIndex, pageSize: this.componentPageSize });
    //     }, error => this.onGetDetailFailed(error));
    // }
  }

  onRefreshLists() {
    this.refreshLists.emit(true);
  }

  activeColor(item: MenuItem): string {

    let result = 'primary';

    if (item.active) {
      result = 'primary';
    }

    return result;
  }

  isMenuItemVisible(item: MenuItem) {
    return Utilities.evaluateCondition(item.visibleCondition, this.uriCollections, this.screenParameters);
  }


  /* public onSelectAll(event) {

    this.rows.forEach(r => {
      r.selected = event.checked;
    });

    let row: any = null;
    let length: number = (this.rows.filter(r => r.selected).length);

    if (length === 1) {
      row = this.rows.find(r => r.selected);
    }

    if (length === 0) {
      this.onViewItem.emit(null);
    }
    else {
      // 9 = Display Flyout / sidebar
      // 12 =
      if (this.onClickAction[0].action === 9 || this.onClickAction[0].action == 12) {
        this.onViewItem.emit({ row: row, action: this.onClickAction[0], length: length,
          toolbarItems: this.screenDetailComponents.toolbarItems });
      }
    }

  } */

  /* public onSelectSingle(event, row) {

    // Clear existing selection
    this.rows.forEach(r => {
      r.selected = false;
    });

    this.onSelect(event, row);
  } */

  /*   public onSelect(event, row) {

      this.selectRow(event.checked, row);

      let length: number = (this.rows.filter(r => r.selected).length);

      let activeRow = row;

      if (length === 1) {
        activeRow = this.rows.find(r => r.selected);
      }

      if (length === 0) {
        this.onViewItem.emit(null);
      }
      else {
        if (this.onClickAction[0].action === 9 || this.onClickAction[0].action == 12) {
          this.onViewItem.emit({ row: activeRow, action: this.onClickAction[0], length: length,
            toolbarItems: this.screenDetailComponents.toolbarItems });
        }
        else {
          this.toastr.success('Action' + this.onClickAction[0].action + ' not supported.');
        }
      }
    } */


  /*   private selectRow(value: boolean, row) {

      row.selected = value;

      let checked = this.rows.find(r => r.selected);
      let unchecked = this.rows.find(r => !r.selected);

      if (checked && unchecked) {
        this.allChecked = 2;
      }
      else {
        this.allChecked = (checked && !unchecked) ? 1 : 0;
      }

      if (!this.bulkAction) {
        if (row.selected) {
          this.singleSelection = row;
        }
        else {
          this.singleSelection = null;
        }
      }



    } */
}
