import { ActionIdentifier } from '../Enums';

export class Action {
  
  action: ActionIdentifier;

  condition: string;

  sequence: number;

  actionArgument: any;

  row: any;
  newItem: boolean;
  screenParameters: any;
}
