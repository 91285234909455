import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationSystem } from 'src/app/models/login/ApplicationSystem';
import { MatDialog } from '@angular/material';
import { ManageProfileComponent } from '../../login-views/manage-profile/manage-profile.component';
import { ChangePasswordComponent } from '../../login-views/change-password/change-password.component';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  username: string;
  currentSystem: string;
  availableSystems: ApplicationSystem[];
  @Output() reloadPage = new EventEmitter();
  @Output() clearMenu = new EventEmitter();

  constructor(private authService: AuthenticationService,
    private router: Router, public dialog: MatDialog,
    private activatedRoute: ActivatedRoute) {
    this.availableSystems = authService.getLoginInfo().availableSystems;
  }

  ngOnInit() {
    this.username = this.authService.getUsername();
    this.currentSystem = this.authService.getCurrentSystem().name;
  }

  logout() {
    this.clearMenu.emit(null);
    this.authService.logout();
    this.router.navigate(['/login']);
    return false;
  }

  manageProfile() {

    const dialogRef = this.dialog.open(ManageProfileComponent,
      {
        width: '600px',
      });

    // this.router.navigate(['/manageProfile']);
    // return false;
  }

  changePassword() {

    const dialogRef = this.dialog.open(ChangePasswordComponent,
      {
        width: '600px',
      });
  }

  selectSystem(system: ApplicationSystem) {
    this.authService.changeSystem(system.systemID)
      .subscribe(r => {
        this.username = this.authService.getUsername();
        this.currentSystem = this.authService.getCurrentSystem().name;

        this.router.navigate(['/main']);
        this.activatedRoute.params = this.activatedRoute.params;
        this.reloadPage.emit(null);
      });
  }
}
