import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { FTFlowStatus } from 'src/app/models/data/FTFlowStatus';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { DataPath } from 'src/app/models/dataPathModels/DataPath';
import { ActionIdentifier, DataType, FilterType, InstanceStyle, MenuType, SubScreenType, TemplateType, UIDataType, ViewType } from 'src/app/models/Enums';
import { ActionCompletedEvent } from 'src/app/models/events/ActionCompletedEvent';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { SaveEvent } from 'src/app/models/events/SaveEvent';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { UpdateDetailItem } from 'src/app/models/viewGroupHelpers/UpdateDetailItem';
import { ActionBuilder } from 'src/app/services/action/action-builder';
import { DataUtils } from 'src/app/services/data-utils/data-utils';
import { DateUtils } from 'src/app/services/DateUtils';
import { FlowService } from 'src/app/services/flow/flow.service';
import { ColorPalette } from 'src/app/values/ColorPalette';
import { Action } from '../../../models/actions/Action';
import { FTThingDetail } from '../../../models/data/FTThingDetail';
import { PostResponse, ValidationResponse } from '../../../models/PostResponse';
import { ScreenColumn } from '../../../models/ScreenColumn';
import { DetailSection, ScreenDetail } from '../../../models/ScreenDetail';
import { ThingService } from '../../../services/thing/thing.service';
import { Utilities } from '../../../services/utilities/utilities';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { InlineGridComponent } from '../inputGroups/inline-grid/inline-grid.component';
import { FileUploadComponent } from '../inputs/file-upload/file-upload.component';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { IconMapping } from 'src/app/models/IconMapping';
import { IconService } from 'src/app/services/icon/icon.service';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { DetailUtilities } from 'src/app/services/utilities/detailUtilities';
import { DetailChangeEvent } from 'src/app/models/events/DetailChangeEvent';
import { DataPathHelper } from 'src/app/helpers/data-path-helper';
import { EmbeddedOrderBasketComponent } from '../inputGroups/embedded-order-basket/embedded-order-basket.component';
import { BindingPath } from 'src/app/models/dataPathModels/BindingPath';
import { MathsHelper } from 'src/app/helpers/maths-helper';
import { FTConfigItem } from 'src/app/models/data/ConfigItem';
import { ConfigUtilitiesService } from 'src/app/services/utilities/config-utilities.service';
import { SimpleDropdownComponent } from '../dropdowns/simple-dropdown/simple-dropdown.component';
import { AutoCompleteDropdownComponent } from '../dropdowns/auto-complete-dropdown/auto-complete-dropdown.component';
import { VirtualScrollDropdownComponent } from '../dropdowns/virtual-scroll-dropdown/virtual-scroll-dropdown.component';
import { ColourPalleteService } from 'src/app/services/colour-palette/colour-palette.service';

@Component({
  selector: 'app-update-detail',
  templateUrl: './update-detail.component.html',
  styleUrls: ['./update-detail.component.scss'],
  providers: [DetailUtilities]
})
export class UpdateDetailComponent implements OnInit, OnDestroy, OnChanges {

  destroy$: Subject<boolean> = new Subject<boolean>();

  public TemplateType = TemplateType;
  public InstanceStyle = InstanceStyle;
  public ViewType = ViewType;
  public DataType = UIDataType;
  public FilterType = FilterType;
  public SubScreenType = SubScreenType;

  colorPalette: string[] = [];

  updateIndicator = false;
  launchScreenAction: LaunchScreenAction;
  _callingAction: AddUpdateItemModel;

  uniqueId: string = Utilities.uniqueId();
  newItem = false;

  columnFieldGroups: DetailSection[];

  public timeMask = [/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/];

  @Input() height: number;
  @Input() showCancelButton = true;
  hasSaveButton = false;
  @Input() savedObject: any;

  @Output() onUpdate = new EventEmitter<AddUpdateItemModel>();
  @Output() onUpdateCompleted = new EventEmitter<UpdateCompletedEvent>();
  @Output() onUpdateIndicator = new EventEmitter<boolean>();
  @Output() onCancel = new EventEmitter();
  @Output() onSetTitle = new EventEmitter<TitleUpdateEvent>();
  @Output() onDeleteCompleted = new EventEmitter<DeleteEvent>();
  @Output() saveCompleteEvent = new EventEmitter<any>();


  //Not used but keep incase it needs to be added back in.
  // @Output() navigateBack = new EventEmitter<Action[]>();
  @Output() viewItemEvent = new EventEmitter<ViewDetailEvent>();
  @Output() itemMenuActions = new EventEmitter<any[] & MenuItem[]>();
  flowStatuses: FTFlowStatus[];
  screenDefColumnItems: ScreenColumn[];
  screenParameters: any;
  uriCollections: UriCollection[];
  screenDetail: ScreenDetail;
  menuSave: MenuItem;
  showActionsAtTop: boolean;
  initialising = true;
  iconMappings: Array<IconMapping>;
  iconFilterControl = new FormControl();

  orderBasketItemCount: number;

  @ViewChild('detailsForm') detailsForm: NgForm;
  @ViewChildren(FileUploadComponent) fileUploadComponents: QueryList<FileUploadComponent>;
  @ViewChildren(InlineGridComponent) inlineGridComponents: QueryList<InlineGridComponent>;
  @ViewChildren(EmbeddedOrderBasketComponent) orderBasketComponents: QueryList<EmbeddedOrderBasketComponent>;

  //Dropdowns
  @ViewChildren(SimpleDropdownComponent) simpleDropdowns: QueryList<SimpleDropdownComponent>;
  @ViewChildren(AutoCompleteDropdownComponent) autoCompleteDropdowns: QueryList<AutoCompleteDropdownComponent>;
  @ViewChildren(VirtualScrollDropdownComponent) virtualScrollDropdowns: QueryList<VirtualScrollDropdownComponent>;

  refreshDropdowns(){
    if(this.simpleDropdowns){
      this.simpleDropdowns.forEach(dropdown => dropdown.ngAfterViewInit());
    }
    if(this.autoCompleteDropdowns){
      this.autoCompleteDropdowns.forEach(dropdown => dropdown.refresh());
    }
    if(this.virtualScrollDropdowns){
      this.virtualScrollDropdowns.forEach(dropdown => dropdown.ngAfterViewInit());
    }
  }
  

  toolbarItems: any[] & MenuItem[];
  idName: string; // Used to track name being used for primary key, when deleting an item
  backMenu: MenuItem;
  rootOb: any;

  constructor(public toastr: ToastrService, 
    private thingService: ThingService, 
    public dialog: MatDialog, 
    private iconService: IconService, 
    private iconHelper: IconHelper, 
    private detailUtils: DetailUtilities,
    private configService: ConfigUtilitiesService, 
    private colourPalleteService: ColourPalleteService) { }

  ngOnInit() {
    this.iconMappings = this.iconService.getIconMaps();
    
    this.colourPalleteService.getColourPallete().subscribe(x => {
      this.colorPalette = x;

      var length = this.colorPalette.length;

      var value = 1
      for (let z = 0; z < 9; z++) {
        value -= 0.2;
        for (let i = 0; i < length; i++) {
          const color = this.colorLuminance(this.colorPalette[i], value);
          this.colorPalette.push(color);
        }
      }
    })

    this.iconFilterControl.valueChanges.subscribe(x => {
      this._filter(x);
    });
  }

  public colorLuminance(hex, lum) {
    // Validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex.replace(/(.)/g, '$1$1');
    }
    lum = lum || 0;
    // Convert to decimal and change luminosity
    var rgb = "#",
      c;
    for (var i = 0; i < 3; ++i) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }
    return rgb;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  get callingAction(): AddUpdateItemModel {
    return this._callingAction;
  }

  @Input()
  set callingAction(value: AddUpdateItemModel) {
    this.initialising = true;
    this._callingAction = value;
    if (this._callingAction) {
      if (this._callingAction.action !== null && (this._callingAction.newItem || this._callingAction.row !== null)) {
        this.initViewDetail();
        this.updateIndicator = false;
        this.onUpdateIndicator.emit(false);
      }
    }
  }

  private _filter(value: string) {
    this.iconMappings = this.iconService.getIconMaps();
    const filterValue = value.toLowerCase();

    this.iconMappings = this.iconMappings.filter(option => option.name[0].value.toLowerCase().includes(filterValue));
  }

  private initViewDetail() {

    // this.columnFields = [];
    this.columnFieldGroups = [];
    const rawAction = JSON.parse(JSON.stringify(this.callingAction.action)) as LaunchScreenAction;

    this.launchScreenAction = rawAction as LaunchScreenAction;

    this.newItem = this.callingAction.newItem;

    if (this.callingAction.uriCollections) {
      this.onGetUriCollectionsSuccessful(this.callingAction.uriCollections);
    } else {
      DataUtils.setUriCollections(this.thingService, this.launchScreenAction.uris, this.callingAction.row, this.callingAction.screenParameters)
        .pipe(takeUntil(this.destroy$))
        .subscribe(results => this.onGetUriCollectionsSuccessful(results), error => this.onGetDetailFailed(error));
    }
  }

  private onGetUriCollectionsSuccessful(uc: UriCollection[]) {

    this.uriCollections = uc;

    if (!this.uriCollections) {
      this.toastr.error('No UriCollections for component');
    }
    this.thingService.getScreenDetail(this.launchScreenAction.screenUri)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetScreenDetailSuccessful(results), error => this.onGetDetailFailed(error));
  }

  public stopPropagation(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private onGetScreenDetailSuccessful(screenDetailComponents: ScreenDetail) {

    this.screenDetail = screenDetailComponents;



    // this.columnFields = [];
    this.columnFieldGroups = [];

    // TODO these still use calling action row - may not work now
    this.screenParameters = Utilities.setScreenParameters(this.launchScreenAction.screenParameters, screenDetailComponents.requiredScreenParameters,
      this.callingAction.row, this.uriCollections);

    const title = Utilities.parseArgumentsFromUriCollection(screenDetailComponents.screenTitle.text, screenDetailComponents.screenTitle.argumentIds,
      this.uriCollections, this.callingAction.row, this.screenParameters);
    this.onSetTitle.emit({ id: null, title: title, subtitle: null });

    const screenDetail: ScreenDetail = screenDetailComponents.componentScreens[0];

    this.uriCollections.forEach(uriCol => {
      if (uriCol.dataValues && uriCol.dataValues.length === 1) {
        uriCol.dataValue = uriCol.dataValues[0];
      }

      if (!uriCol.dataValue) {
        const uriCollectionName = uriCol.name;
        // Create row from default
        if (screenDetailComponents.newObjectDefaults && screenDetailComponents.newObjectDefaults[uriCollectionName]) {

          uriCol.dataValue = Utilities.generateRowFromDefaults(screenDetailComponents.newObjectDefaults[uriCollectionName], this.callingAction.row,
            this.screenParameters, uriCollectionName, this.uriCollections, this.configService);
        }
        if (!uriCol.dataValues) {
          uriCol.dataValues = [{}];
        }
      }

      if (!uriCol.dataValue) {
        uriCol.dataValue = {};
      }
    });

    //this.columnFieldGroups = Object.assign([], screenDetail.detailSections);
    this.columnFieldGroups = screenDetail.detailSections.filter(section => {
      if(section.isVisibleCondition){
        return Utilities.evaluateCondition(section.isVisibleCondition, this.uriCollections, this.screenParameters);
      }
      else{
        return true;
      }
    });
    for (const section of this.columnFieldGroups) {
      section.columnFields = this.detailUtils.generateScreenDetails(section.details, this.uriCollections, this.screenParameters, this.toastr);
    }

    if (this.screenDetail.toolbarItems) {
      // this.menuSave = this.screenDetail.toolbarItems.find(a => a.action.findIndex(ac => ac.action === ActionIdentifier.UpdateData) > -1);
      // if (this.menuSave) {
      //   this.showActionsAtTop = this.menuSave.menuItemType === MenuType.TopToolbar;
      // }
      this.toolbarItems = new Array();
      this.screenDetail.toolbarItems.forEach(toolbarItem => {
        if (toolbarItem.visibleCondition) {
          const visible = Utilities.evaluateCondition(toolbarItem.visibleCondition, this.uriCollections, this.screenParameters);
          if (visible) {
            this.toolbarItems.push(toolbarItem);
          }
        }
        else {
          this.toolbarItems.push(toolbarItem);
        }
      })


      //this.toolbarItems = Object.assign([], this.screenDetail.toolbarItems);
      const backActionIn = this.toolbarItems.findIndex(a => a.menuItemType === MenuType.BackPressed);
      if (backActionIn > -1) {

        this.backMenu = this.toolbarItems.splice(backActionIn, 1)[0];
      }

      var saveActions = this.toolbarItems.filter(a => a.action && a.action.findIndex(ac => ac.action === ActionIdentifier.UpdateData) > -1);
      if(saveActions.length == 1){
        const mi = this.toolbarItems.findIndex(a => a.action && a.action.findIndex(ac => ac.action === ActionIdentifier.UpdateData) > -1);
        if (mi > -1) {
          this.menuSave = Object.assign({}, this.toolbarItems[mi]);
          this.toolbarItems.splice(mi, 1);
          if (this.menuSave) {
            this.showActionsAtTop = this.menuSave.menuItemType === MenuType.TopToolbar;
          }
        }
      }
    }
    if (!this.menuSave || !this.menuSave.action) {
      this.hasSaveButton = false;
      console.log('Save action must be implemented');
      // return;
    }

    this.itemMenuActions.emit(this.toolbarItems);
    this.initialising = false;

  }

  getText(text: string, argumentIds: string[]) {
    const r = Utilities.parseArgumentsFromUriCollection(text, argumentIds, this.uriCollections, null, this.screenParameters);
    return r;
  }

  public getIcon(iconId: number)
  {
    var iconHex = this.iconHelper.getFontAwesomeHex(iconId);
    var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
    return this.iconHelper.setIcon(iconMapping, iconHex);
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  getUriDataItem(collectionName: string): any {

    if (collectionName) {
      const uriCollection = this.uriCollections.find(u => u.name === collectionName);
      if (uriCollection && uriCollection.dataValue) {
        return uriCollection.dataValue;
      }
    }
  }

  onDropdownChange(changeEvent, column: UpdateDetailItem, index: number){
    this.handleValueChange(changeEvent, column, index);
  }

  checkboxChange(changeEvent, column: UpdateDetailItem, index: number){
    this.handleValueChange(changeEvent.checked, column, index);
  }

  handleValueChange(changedValue, column: UpdateDetailItem, index: number){
    if(!column.publishSubscribeAction)
    {
      return;
    }

    var detailChangeEvents: DetailChangeEvent[] = [];

    column.publishSubscribeAction.forEach(changeEvent => {
      if(changeEvent.Condition && !Utilities.evaluateCondition(changeEvent.Condition, this.uriCollections, this.screenParameters, null, null, null)){
        return;
      }
      var newValueArgs = [];
      changeEvent.NewValueArgs.forEach(arg => {
        if(DataPathHelper.isBindingPath(arg)){
          var bindingPath = new BindingPath(arg);
          if(bindingPath.isValid){
            //Find section
            var targetSection = this.columnFieldGroups.find(group => group.columnFields.some(columnField => columnField.tag == bindingPath.tag));
            if(!targetSection){
              return;
            }
            var binding = targetSection.columnFields.find(detail => detail.tag == bindingPath.tag);

            if(binding){
              var id = `${binding.tag}-${index}`
              var value = (<HTMLInputElement>document.getElementById(id)).value;
              if(value){
                newValueArgs.push(value);
              }
              else{
                newValueArgs.push("0");
              }
            }
            else{
              newValueArgs.push("0");
            }
          }
        }
        else if(arg.includes("value")){
          if(!changedValue){
            return;
          }
          if(arg.startsWith("value|")){
            var argParts = arg.split("|");
            var dataPath = new DataPath(argParts[1]);
            if(dataPath && dataPath.isValid){
              if(changedValue && changedValue[dataPath.propertyName]){
                newValueArgs.push(changedValue[dataPath.propertyName]);
              }
              else{
                var collection = this.uriCollections.find(u => u.name == dataPath.rootObjectName);
                newValueArgs.push(collection.dataValue[dataPath.propertyName]);
              }
            }
          }
          else{
            newValueArgs.push(changedValue);
          }
        }
        else{
          var dataPath = new DataPath(arg);
          if(dataPath && dataPath.isValid){
            var collection = this.uriCollections.find(u => u.name == dataPath.rootObjectName);
            if(collection.dataValue[dataPath.propertyName]){
              newValueArgs.push(collection.dataValue[dataPath.propertyName]);
            }
            else{
              newValueArgs.push(0);
            }
          }
        }

      });
    
      var newValue = Utilities.stringFormat(changeEvent.NewValue, newValueArgs);
      newValue = MathsHelper.evaluateEquationFromString(newValue, this.uriCollections);

      if(newValue){
        detailChangeEvents.push(new DetailChangeEvent(column.tag, changeEvent.Target, newValue, changeEvent.OverwriteValue, index));
      }
    });

    if(detailChangeEvents.length > 0){
      this.handleValueChangedEvents(detailChangeEvents);
    }
    
  }

  handleValueChangedEvents(detailChangeEvents: DetailChangeEvent[]){
    detailChangeEvents.forEach(changeEvent => {
      if(DataPathHelper.isDataPath(changeEvent.target)){
        if(changeEvent.newValue){
          Utilities.setDataToUriCollection(changeEvent.target, this.uriCollections, changeEvent.newValue);
        }
      }
      else if(DataPathHelper.isBindingPath(changeEvent.target)){
        var parts = changeEvent.target.split(".");
        var bindingTag = parts[1];
        var property = parts[2];
        if(bindingTag && property){
          //Find section
          this.setFieldValueByTag(bindingTag, property, changeEvent.overwrite, changeEvent.newValue);
        }
      }
      else {
        //Find target field
        var element : any = (document.getElementById(changeEvent.target));
        if(!element && changeEvent.row != null){
          var id = `${changeEvent.target}-${changeEvent.row}`;
          element = (document.getElementById(id));
        }
        if(!element && changeEvent.row != null){
          var id = `${changeEvent.target}-${this.uniqueId}`;
          element = (document.getElementById(id));
        }
        if(element){
          if(!element.value || changeEvent.overwrite)
          element.value = changeEvent.newValue
        }
        // var targetSection = this.columnFieldGroups.find(group => group.columnFields.some(columnField => columnField.tag == changeEvent.target));
        // if(!targetSection){
        //   return;
        // }

        // var targetDetail = targetSection.columnFields.find(columnField => columnField.tag == changeEvent.target);
        // if(!targetDetail){
        //   return;
        // }

        // var dataObj = this.getRootObject(targetDetail);
        // if(!dataObj[targetDetail.propertyName] || changeEvent.overwrite){
        //   dataObj[targetDetail.propertyName] = changeEvent.newValue;
        // }
      }
    });
  }

  public handleBasketTotalChanged(basketTotals){
    this.changeBasketTotalFields(basketTotals.net, "@basketNet");
    this.changeBasketTotalFields(basketTotals.tax, "@basketTax");
    this.changeBasketTotalFields(basketTotals.gross, "@basketGross");
  }

  changeBasketTotalFields(value: number, tag: string){
    var sections = this.columnFieldGroups.filter(group => group.columnFields.some(columnField => columnField.tag && columnField.tag.endsWith(tag)));
    if(!sections){
      return;
    }

    //Find fields
    var targetDetailsGroups = sections.map(section => 
      section.columnFields.filter(columnField => columnField.tag && columnField.tag.endsWith(tag)));
    
    targetDetailsGroups.forEach(targetDetails => {
      targetDetails.forEach(targetDetail => {
        this.uriCollections.find(collection => collection.name == targetDetail.objectName)
          .dataValue[targetDetail.propertyName] = value;
      })
        
    });

    var targetDetailTags = sections.map(section => 
                              section.columnFields.filter(columnField => columnField.tag && columnField.tag.endsWith(tag)).map(field => field.propertyName));
    if(!targetDetailTags){
      return;
    }
    targetDetailTags.forEach(targetTags => {
      targetTags.forEach(targetTag => {
        var element : any = (document.getElementById(`${targetTag}-${this.uniqueId}`));
        if(element){
            element.value = value;
        }    
      });
    });
  }

  setFieldValueByTag(bindingTag: string, property: string, shouldOverwrite: boolean, newValue: any){
    var targetSection = this.columnFieldGroups.find(group => group.columnFields.some(columnField => columnField.tag == bindingTag));
    if(!targetSection){
      return;
    }

    //Find field
    var targetDetail = targetSection.columnFields.find(columnField => columnField.tag == bindingTag);
    if(!targetDetail){
      return;
    }

    //Set property
    if(!targetDetail[property] || shouldOverwrite){
      targetDetail[property] = newValue;
    }
  }

  getRootObject(item: UpdateDetailItem): any {

    if (item.objectName) {
      let rootOb: any;
      const uriCollection = this.uriCollections.find(u => u.name === item.objectName);
      if (uriCollection && uriCollection.dataValue) {
        if (item.childObjectName) {
          rootOb = uriCollection.dataValue[item.childObjectName];
        } else {
          rootOb = uriCollection.dataValue;
        }
      } else {
        Utilities.log2('UpdateDetail', `Can't find a uriCollection for ${item.objectName} (${item.propertyName})`, 'warn');
      }
      this.rootOb = rootOb;
      return rootOb;
    }
  }

  getRootObjectArray(path: string) {

    const dataPath = new DataPath(path);
    if (dataPath && dataPath.rootObjectName && dataPath.propertyName) {
      let rootOb: any[];
      const uriCollection = this.uriCollections.find(u => u.name === dataPath.rootObjectName);
      if (uriCollection && uriCollection.dataValue) {
        if (dataPath.childObjectNames[0]) {
          rootOb = uriCollection.dataValue[dataPath.childObjectNames[0]][dataPath.propertyName];
        } else {
          rootOb = uriCollection.dataValue[dataPath.propertyName];
        }
      } else {
        // Utilities.log2('UpdateDetail', `Can't find a uriCollection for ${item.objectName} (${item.propertyName})`, 'warn');
      }
      return rootOb;
    } else {
      return this.uriCollections.find(u => u.name === path).dataValues;
    }
  }

  public async onClick(item: MenuItem) {

    if (item.action) {

      let row = this.getUriDataItem(this.screenDetail.componentScreens[0].detailSections[0].templateDataKey);
      if (item.action[0].action === ActionIdentifier.LaunchFlyout) {

        const saved = this.onSave({ closeOnFinish: false, objectKey: null }, this.saveCompleteEvent);

        //TODO Revisit
        //this.updateIndicator = true;
        this.onUpdateIndicator.emit(true);

        this.saveCompleteEvent.subscribe(s => {
          //TODO Revisit
          //this.updateIndicator = true;
          this.onUpdateIndicator.emit(true);
          if (s) {
            if (item.action[0].actionArgument.actionData && item.action[0].actionArgument.actionData.rowKey) {
              row = this.getUriDataItem(item.action[0].actionArgument.actionData.rowKey);
            } else {
              row = this.getUriDataItem(this.screenDetail.componentScreens[0].detailSections[0].templateDataKey);
            }
            const launchScreenAction = item.action[0].actionArgument as LaunchScreenAction;
            this.onUpdate.emit({
              id: launchScreenAction.name, row: row, action: launchScreenAction,
              newItem: item.menuItemType !== MenuType.BackPressed, screenParameters: this.screenParameters
            });
          }
        });

        // } else if (item.action[0].action === ActionIdentifier.LaunchScreen && item.action[0].actionArgument.screenType === ScreenType.ShoppingBasket) {

        //   this.onDisplayScreen.emit({ action: item.action[0], row: this.row });
      } else if (item.action.find(a => a.action === ActionIdentifier.DisplayDialog || a.action === ActionIdentifier.LaunchScreen)) {

        const action: Action = JSON.parse(JSON.stringify(item.action[0]));

        Utilities.log2('ViewDetail component', 'onClick(menuItem)');
        Utilities.log(JSON.stringify(action));

        if (action.actionArgument.uris) {
          for (const uri of action.actionArgument.uris) {

            if (uri.dataUri) {
              uri.dataUri = Utilities.parseArgumentsFromData(uri.dataUri, uri.dataUriArgs, row, null, this.screenParameters);
            }

          }
        }
        this.viewItemEvent.emit({ action: action, row: row, newItem: true, screenParameters: null });
      } else if (item.action[0].action === ActionIdentifier.DeleteItem) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '450px',
          data: { message: 'Are you sure you want to delete this item?' }
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result) {

            const arg: string = item.action[0].actionArgument.editUriArgs[0];
            this.idName = arg.substring(arg.lastIndexOf('.') + 1);

            row = this.getUriDataItem(this.screenDetail.componentScreens[0].detailSections[0].templateDataKey);
            this.thingService.deleteItem(item.action[0].actionArgument.editUri, [row[this.idName]])
              .subscribe(results => this.onDeleteItemSuccessful(results), error => this.onDeleteItemFailed(error));
          }

        });
      } else if (item.action[0].action == ActionIdentifier.UpdateData) {
        this.onSave({closeOnFinish: true, objectKey: null}, null, item.action);
      }
    } else {
      alert('No action defined!');
    }
  }

  private onDeleteItemSuccessful(result: PostResponse[]) {

    result.forEach(r => {

      switch (r.errorCode) {
        case 0:
          this.onDeleteCompleted.emit({ name: this.idName, value: r.returnedObject });
          break;
        case 400:
          this.processValidationResponse(null, r.validationResponse);
          break;
        default:
          this.toastr.error(r.errorMessage, null, { tapToDismiss: true });
          break;

      }
    });
  }
  private onDeleteItemFailed(error: any) {

    this.toastr.error(`Unable to delete data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }


  public async onSave(saveEvent: SaveEvent, saveCompleteEvent?: EventEmitter<boolean>, action?: Action[]): Promise<boolean> {

    console.log('onSave');
    // reset all errors in preparation for next save attempt
    this.columnFieldGroups.forEach(g => g.columnFields.forEach(c => c.errorText = null));

    if (this.fileUploadComponents) {
      this.fileUploadComponents.forEach(fuc => fuc.save());
    }

    // if (this.orderBasketComponents) {
    //   this.orderBasketComponents.forEach(element => element.save());
    // }
    


    // set any additional data properties that are set by the action on a column
    this.columnFieldGroups.forEach(g => g.columnFields.forEach(column => {

      const updateRow = this.getRootObject(column);
      const key = column.propertyName;

      if (column && column.action && column.action[0].action === ActionIdentifier.UpdateData) {
        const act = column.action[0].actionArgument;
        if (act.newObjectDefaults) {
          Object.keys(act.newObjectDefaults).forEach(function (newObjKey) {
            const split = act.newObjectDefaults[newObjKey].split('.');
            let value: any;
            switch (split[0].toLowerCase()) {
              // case 'screenparameter':
              // case 'screenparameters':
              //   value = this.screenParameters[split[1]];
              //   break;
              // case 'data':
              //   value = Utilities.getValueFromUriCollection(act.newObjectDefaults[newObjKey], this.uriCollections, this.item, this.screenParameters);
              //   break;

              // TODO I think this is redundant now
              case 'ftflow':
                const flow = FlowService.getFlowStatus(updateRow[key] as number);
                value = flow.ftFlowID;
                break;
              case 'ftflowstatus':
                const flow1 = FlowService.getFlowStatus(updateRow[key].value as number);
                value = flow1.ftFlowStatusID;
                break;
            }

            // let value = Utilities.getValueFromUriCollection(defaults[key], this.uriCollections, this.item, this.screenParameters);
            updateRow[newObjKey] = value;
          }, this);
        }
      }
    }));

    //TODO Revisit
    //this.updateIndicator = true;
    this.onUpdateIndicator.emit(true);

    if(!action){
      action = this.menuSave.action;
    }

    let acts: Action[];
    if (saveEvent && saveEvent.objectKey) {
      acts = Object.assign([], action.filter(a => {
        if (a.action === ActionIdentifier.UpdateData) {
          const arg = a.actionArgument as UpdateDataAction;
          return arg.currentItemPath === saveEvent.objectKey;
        }
      }));
    } else {
      acts = Object.assign([], action.sort((a, b) => a.sequence - b.sequence));
    }

    // TODO try action builder
    var result = await this.postData(acts, null, saveEvent ? saveEvent.closeOnFinish : true, saveCompleteEvent, null, null);
    // if (this.orderBasketComponents) {
    //   this.orderBasketComponents.forEach(element => element.save());
    // }
    return result;
  }

  async postData(actions: Action[], viewDetailEventData: ViewDetailEvent, closeOnFinish: boolean, saveCompleteEvent: EventEmitter<boolean>, savedRow: any, primaryKey: string): Promise<boolean> {

    const locViewItemEvent = new EventEmitter<ViewDetailEvent>();
    locViewItemEvent.subscribe(data => {
      // last action
      viewDetailEventData = data;
    });

    if (actions.length === 0) {

      if (!closeOnFinish) {
        this.fileUploadComponents.forEach(f => f.retryFileUpload(savedRow));
      }

      if (!saveCompleteEvent) {
        this.updateIndicator = false;
        //if(!this.hasSavedBaskets){
          this.orderBasketComponents.forEach(b => b.save());
        //}
        this.onUpdateIndicator.emit(false);

      }
      if (closeOnFinish) {
        if (viewDetailEventData) {
          this.viewItemEvent.emit(viewDetailEventData);
        } else {
          this.onUpdateCompleted.emit({ row: savedRow, newItem: null, closeFlyout: true, primaryKey: primaryKey });
        }
      }
    }

    // TODO here we are assuming that all actions are UpdateDataActions
    const nextAction = actions.shift();

    if (!nextAction) {
      if (closeOnFinish) {
        this.saveCompleteEvent.emit(savedRow);
      }
      return true;
    }

    switch (nextAction.action) {
      case ActionIdentifier.UpdateData:
        {
          const updateAction = nextAction.actionArgument as UpdateDataAction;

          if (!Utilities.evaluateCondition(nextAction.condition, this.uriCollections, this.screenParameters)) {
            this.postData(actions, viewDetailEventData, closeOnFinish, saveCompleteEvent, savedRow, primaryKey);
            if (actions.length === 0) {
              this.onUpdateCompleted.emit({ row: savedRow, newItem: this.newItem, closeFlyout: closeOnFinish, primaryKey: primaryKey });
            }
            break;
          }
          let updateCollection = this.uriCollections.find(u => u.name === updateAction.currentItemPath);

          if (!updateCollection) {
            if (updateAction.editUri.startsWith('/api/action')) {
              updateCollection = { name: null, dataValues: null, dataValue: {} };
            } else {
              this.toastr.error('currentItemPath must be set on the UpdateData action');
            }
          }

          if (updateAction.newObjectDefaults) {

            if (updateAction.editUri === '/api/actions/createProduct') {
              // Object.keys(updateAction.newObjectDefaults).forEach(function (newObjKey) {
              updateCollection.dataValue['productId'] =
                Utilities.getValueFromUriCollection(updateAction.newObjectDefaults['productId'], this.uriCollections, null, this.screenParameters);
              let compositeKey = updateAction.newObjectDefaults['productGroupProducts'];
              if (compositeKey) {
                compositeKey = compositeKey.replace('data.', '');
                updateCollection.dataValue['productGroupProducts'] = [];
                const composites = this.uriCollections.find(u => u.name === compositeKey);
                if (composites && composites.dataValues) {
                  composites.dataValues.forEach(comp => {
                    const pgp = {};
                    pgp['productId'] = Utilities.getValueFromUriCollection(updateAction.newObjectDefaults['productGroupProducts.productId'], null, comp, this.screenParameters);
                    pgp['quantity'] = Utilities.getValueFromUriCollection(updateAction.newObjectDefaults['productGroupProducts.quantity'], null, comp, this.screenParameters);
                    updateCollection.dataValue['productGroupProducts'].push(pgp);
                  });
                }
              }
              // }, this);
            } else {

              Object.keys(updateAction.newObjectDefaults).forEach(function (newObjKey) {
                const split = updateAction.newObjectDefaults[newObjKey].split('.');
                let value;

                switch (split[0].toLowerCase()) {
                  case 'screenparameter':
                  case 'screenparameters':
                    value = this.screenParameters[split[1]];
                    break;
                  case '@date':
                    if (split[1] === 'now') {
                      value = DateUtils.getCurrentDateTimeUTCString();
                    }
                    break;
                  case 'data':
                    value = Utilities.getValueFromUriCollection(updateAction.newObjectDefaults[newObjKey], this.uriCollections, this.item, this.screenParameters);
                    break;
                  case '[]':
                    value = [];
                    break;
                  default:
                    value = split[0];
                    break;
                  // // TODO I think this is redundant now
                  // case 'ftflow':
                  //   const flow = this.flowsService.getFlowStatus(updateRow[key]);
                  //   value = flow.ftFlowID;
                  //   break;
                  // case 'ftflowstatus':
                  //   const flow1 = this.flowsService.getFlowStatus(updateRow[key].value);
                  //   value = flow1.ftFlowStatusID;
                  //   break;
                }

                updateCollection.dataValue[newObjKey] = value;
              }, this);
            }
          }

          if (updateCollection && updateCollection.dataValue) {

            // if (this.callingAction.uriCollections) {
            //   let c = this.callingAction.uriCollections.find(u => u.name === updateCollection.name);
            //   if (c) {
            //     c = updateCollection;
            //   } else {
            //     this.callingAction.uriCollections.push(updateCollection);
            //   }
            // } else {
            //   this.callingAction.uriCollections = [];
            //   this.callingAction.uriCollections.push(updateCollection);
            // }

            // this.callingAction.uriCollections = this.uriCollections;

            let refreshOnSaveHeader = false;
            if (updateAction.resultTargetDataObject) {
              refreshOnSaveHeader = true;
            }

            // // FIXME remove this and re-enable the save
            // console.log(updateCollection.dataValue);
            // return;


            for (var key of Object.keys(updateCollection.dataValue)) {
              const keyValue = updateCollection.dataValue[key];
              if (typeof keyValue === 'string' || keyValue instanceof String) {
                updateCollection.dataValue[key] = keyValue.trim();
              }
            }

            if(updateAction.saveAsSingleItem){
              this.thingService.postSingleDataDetail(updateAction.editUri, updateCollection.dataValue, refreshOnSaveHeader)
                .pipe(takeUntil(this.destroy$))
                .subscribe(result => this.onPostDataDetailSuccessful(updateCollection.name, [result], updateAction, actions, viewDetailEventData, closeOnFinish, saveCompleteEvent),
                  error => this.onPostDataDetailFailed(error));
              }
            else  {
              this.thingService.postDataDetail(updateAction.editUri, updateCollection.dataValue, refreshOnSaveHeader)
                .pipe(takeUntil(this.destroy$))
                .subscribe(result => this.onPostDataDetailSuccessful(updateCollection.name, result, updateAction, actions, viewDetailEventData, closeOnFinish, saveCompleteEvent),
                  error => this.onPostDataDetailFailed(error));
            }
          }
        }
        break;
      // case ActionIdentifier.ReloadData:
      //   const reloadAction = nextAction.actionArgument as ReloadDataAction;
      //   const dataUri = Utilities.parseArgumentsFromUriCollection(reloadAction.dataUri, reloadAction.dataUriArgs, this.uriCollections, null, null, this.screenParameters);

      //   this.onUpdateCompleted.emit({ row: response.returnedObject, newItem: this.newItem });
      //   break;

      default:

        console.log('action');
        const completionEvent = new EventEmitter<ActionCompletedEvent>();
        const ac = new ActionBuilder([nextAction], null, null, this.uriCollections, this.screenParameters,
          completionEvent, locViewItemEvent, this.thingService, this.toastr, this.dialog);

        await ac.PerformAction();

        this.postData(actions, viewDetailEventData, closeOnFinish, saveCompleteEvent, savedRow, primaryKey);
        break;
    }
  }

  private onPostDataDetailSuccessful(objectName: string, result: PostResponse[], action: UpdateDataAction,
    remainingActions: Action[], viewDetailEventData: ViewDetailEvent, closeOnFinish: boolean, saveCompleteEvent: EventEmitter<boolean>) {

    Utilities.log('response');

    // Data has been saved, so fetch updated view record
    // const uris = this.launchScreenAction.uris.find(u => u.editUri === action.editUri);

      if(!!result){

        //Check for script response
        if((result as any).returnedObject || (result as any).errorCode){
          var oldResult = (result as any);
          result = [];
          result.push(oldResult);
        }

        result.forEach(response => {

          const rowKey = this.screenDetail.componentScreens[0].detailSections[0].templateDataKey;
          
          // TODO this should just be based on the errorCode but at the moment the service is not reliably returning it
          if (response == null || response.errorCode !== 0) {
            this.updateIndicator = false;
            this.onUpdateIndicator.emit(false);
    
            if (response.errorCode === 400) {
              this.processValidationResponse(objectName, response.validationResponse);
            }
            if (response.errorCode === 500) {
              this.toastr.error(response.errorMessage, `POST failed`, { closeButton: true, tapToDismiss: true });
            }
            if (response.errorCode === 1){
              if((response as any).returnedObject && (response as any).returnedObject.Message){
                this.toastr.error((response as any).returnedObject.Message);
              }
            }
            else{
              return;
            }
          }
          else{
            if((response as any).returnedObject && (response as any).returnedObject.Message){
              this.toastr.error((response as any).returnedObject.Message);
            }
          }
    
          if (action.resultTargetDataObject) {
    
            const resultCollection = this.uriCollections.find(u => u.name === action.resultTargetDataObject);
            if (resultCollection) {
              resultCollection.dataValue = response.returnedObject;
              resultCollection.dataValue['ovn'] = null;
            }
            // this.callingAction.uriCollections = this.uriCollections;
          }
    
          const singleItemUriCollection = this.uriCollections.find(uri => uri.uris.singleItemUri != null && uri.name == action.currentItemPath);
          let primaryKey;
          let singleItemDataUri;
          if (singleItemUriCollection) {
            primaryKey = Utilities.getLastEntry(singleItemUriCollection.uris.singleItemUriArgs[0]);
            singleItemDataUri = Utilities.parseArgumentsFromUriCollection(singleItemUriCollection.uris.singleItemUri,
              singleItemUriCollection.uris.singleItemUriArgs, this.uriCollections);
          }
    
          if(action.resultDataMapping){
            Object.keys(action.resultDataMapping).forEach(mapping => {
              var path = action.resultDataMapping[mapping] as string;
              if(mapping.startsWith("screenParameters")){
                var mappingParts = mapping.split('.')
                var screenParameter = mappingParts[1];
                var resultParts = path.split('.');
                var currentLevel = response.returnedObject;
                resultParts.forEach(part => {
                  if(currentLevel[part]){
                    currentLevel = currentLevel[part];
                  }
                });
                this.screenParameters[screenParameter] = currentLevel;
              }
            });
          }

          // get the number before we do postActions as the count will change before we get to onGetDataDetailSuccessful
          const noActionsRemaining = remainingActions.length;
          if(objectName == rowKey)
            this.postData(remainingActions, viewDetailEventData, closeOnFinish, saveCompleteEvent, response.returnedObject, primaryKey);
          else
            this.postData(remainingActions, viewDetailEventData, closeOnFinish, saveCompleteEvent, null, primaryKey);
    
          
          if (singleItemDataUri) {
            this.thingService.getDataDetail(singleItemDataUri)
              //.pipe(takeUntil(this.destroy$))
              .subscribe(
                res => this.onGetDataDetailSuccessful(res, primaryKey, noActionsRemaining === 0, closeOnFinish),
                error => this.onGetDataDetailFailed(error));
          } else {;
            if (objectName === rowKey) {
              this.onUpdateCompleted.emit({ row: response.returnedObject, newItem: this.newItem, closeFlyout: noActionsRemaining === 0 && closeOnFinish, primaryKey: primaryKey, sectionId: null, resultTargetDataObject: null, currentItemPath: null });
            } else {
              this.onUpdateCompleted.emit({ row: null, newItem: null, closeFlyout: noActionsRemaining === 0 && closeOnFinish, primaryKey: primaryKey, sectionId: null, resultTargetDataObject: null, currentItemPath: null });
            }
          }
    
          // const singleItemUriCollection = this.uriCollections.find(uri => uri.uris.singleItemUri != null);
          // if (singleItemUriCollection) {
          //   const dataUri = Utilities.parseArgumentsFromData(singleItemUriCollection.uris.singleItemUri, singleItemUriCollection.uris.singleItemUriArgs, response.returnedObject);
          //   this.thingService.getDataDetail(dataUri)
          //     .subscribe(
          //       res => this.onGetDataDetailSuccessful(res, Utilities.getLastEntry(singleItemUriCollection.uris.singleItemUriArgs[0])),
          //       error => this.onGetDataDetailFailed(error));
          // }
    
          // this.updateIndicator = false;
          // this.onUpdateCompleted.emit({ row: response.returnedObject, newItem: this.newItem });
          // }
        });
      }

    
  }

  getValue(row: any, childObjectName: string, propertyName) {
    const v = childObjectName ? row[childObjectName][propertyName] : row[propertyName];
    if (v) { return v; } else { return null; }
  }

  processValidationResponse(objectName: string, validationResponse: ValidationResponse) {

    //Backend is sometimes populating Items and items? Communicate to Chris but as a temp workaround
    if (!!validationResponse.Items)
    {
      validationResponse.Items.forEach(validationItem => {

        let columnField;
        this.columnFieldGroups.forEach(g => {
          columnField = g.columnFields.find(c => c.propertyName === validationItem.PropertyName);
          if (columnField) {
            return;
          }
        });
  
        if (columnField) {
  
          // this.detailsForm.form.controls[validationItem.propertyName].setErrors({ 'incorrect': true });
          columnField.errorText = validationItem.Message;
        } else {
          this.toastr.error(validationItem.Message, `Save failed - ${validationItem.PropertyName}`, { closeButton: true, tapToDismiss: true });
        }
      });
    }

    if (!!validationResponse.items)
    {
      validationResponse.items.forEach(validationItem => {

        let columnField;
        this.columnFieldGroups.forEach(g => {
          columnField = g.columnFields.find(c => c.propertyName === validationItem.PropertyName);
          if (columnField) {
            return;
          }
        });
  
        if (columnField) {
  
          // this.detailsForm.form.controls[validationItem.propertyName].setErrors({ 'incorrect': true });
          columnField.errorText = validationItem.Message;
        } else {
          this.toastr.error(validationItem.Message, `Save failed - ${validationItem.PropertyName}`, { closeButton: true, tapToDismiss: true });
        }
      });
    }
  }

  private onPostDataDetailFailed(error: any) {
    this.updateIndicator = false;
    this.onUpdateIndicator.emit(false);

    this.toastr.error(`Unable to save data to server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  private onGetDataDetailSuccessful(result: any, primaryKey: string, closeFlyout: boolean, closeOnFinish: boolean) {

    Utilities.log(JSON.stringify(result));

    if (closeOnFinish) {
      this.onUpdateCompleted.emit({ row: result, newItem: this.newItem, primaryKey: primaryKey, closeFlyout: closeFlyout });
    }
  }

  private onGetDataDetailFailed(error: any) {
    this.toastr.error(`Unable to load data from server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public onCancelItem() {

    this.onCancel.emit();
  }

  public lookupDataUri(itemSource: string) {

    if (!itemSource) { return; }

    itemSource = itemSource.substring(itemSource.lastIndexOf('.') + 1);

    if (!this.uriCollections) { return; }

    const uriCollection = this.uriCollections.find(u => u.name === itemSource);
    if (uriCollection) {
      const uris = this.uriCollections.find(u => u.name === itemSource).uris;
      if (uris) {
        return uris.dataUri;
      }
    } else {
      // this.toastr.error(`Can't find uri collection for ${itemSource}`);
      console.log(`Can't find uri collection for ${itemSource}`);
    }
    return null;
  }

  public isTextType(dataType: DataType) {
    switch (dataType) {
      case DataType.Char:
      case DataType.VarChar:
      case DataType.VarCharMax:
      case DataType.NChar:
      case DataType.NText:
      case DataType.NVarChar:
      case DataType.NVarCharMax:
        return true;
      default:
        return false;
    }
  }

  parseToInt(value: any): number {
    if (!value) { return null; }
    return Number.parseInt(value.toString, 10);
  }

  isEnabled(enabledCondition: string, dataItem: any) {
    if (!enabledCondition) { return true; }
    const ie = Utilities.evaluateCondition(enabledCondition, this.uriCollections, this.screenParameters, null, dataItem, null);
    return ie;
  }

  isDisabled(enabledCondition: string, dataItem: any) {
    if (!enabledCondition) { return this.updateIndicator; }

    const ds = this.updateIndicator || !this.isEnabled(enabledCondition, dataItem);
    return ds;
  }

  getBackgroundColor(errorText: string) {
    if (errorText) {
      return { 'background-color': '#fffcf9' };
    } else {
      return { 'background-color': 'transparent' };
    }
  }

  onCompositeAdd() {
    if (this.inlineGridComponents) {
      this.inlineGridComponents.forEach(g => g.refreshGrid());
    }
  }

  onNavigateBack() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { message: 'Save item?' }
    });

    // this.backMenu.action.forEach(action => {
    //   action = Utilities.parseActionFromUriCollections(this.backMenu.action[0], this.uriCollections, this.screenParameters);
    // });
    dialogRef.afterClosed().subscribe(result => {
      this.onClick(this.backMenu);
      // if (result) {
      //   const saveCompleteEvent = new EventEmitter<boolean>();
      //   this.updateIndicator = true;
      //   this.onSave({ closeOnFinish: false, objectKey: null }, saveCompleteEvent);
      //   saveCompleteEvent.subscribe(e => {
      //     if (e) {
      //       this.onClick(this.backMenu);
      //       // this.navigateBack.emit(this.backMenu.action);
      //     }
      //   });
      // } else {
      //   this.onClick(this.backMenu);
      //   // this.navigateBack.emit(this.backMenu.action);
      // }
    });
  }

  amendIcon(obj, propertyName, iconMapping: IconMapping) {

    var iconDecimalValue = this.iconHelper.geticonHexToDecimal(iconMapping.charCode);
    obj[propertyName] = +iconDecimalValue;
  }

  public onViewDetail(event: ViewDetailEvent) {
    this.viewItemEvent.emit(event);
  }

  ngOnDestroy(): void {

    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  jsonString(obj: any) {
    return JSON.stringify(obj);
  }

  setBasketCount(event){
    this.orderBasketItemCount = event;
  }
}
