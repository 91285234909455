import { Injectable } from '@angular/core';
import { el } from 'date-fns/locale';
import { FTConfigItem } from 'src/app/models/data/ConfigItem';
import { ThingService } from '../thing/thing.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigUtilitiesService {

  private configItems: FTConfigItem[];

  constructor(private thingService: ThingService) 
  { 
    this.configItems = null;
    this.thingService.getConfig()
      .subscribe(results => this.onGetConfigSuccessful(results), error => this.onGetConfigFailed(error));
  }
  onGetConfigSuccessful(results: FTConfigItem[]): void {
    this.configItems = results;
  }
  onGetConfigFailed(error: any): void {
    console.log(`Config not loaded: error ${error}`);
  }

  public GetConfigValue(name: string): string{
    if(this.configItems){
      var selectedItem = this.configItems.find(i => i.displayName == name);
      if(selectedItem){
        return selectedItem.value;
      }
      else{
        console.log(`ConfigItem ${name} not found`);
      }
    }
    else{
      console.log(`Config not loaded`);
    }
  }
}
