import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
  title: string;
  message: string;
  columnFields: any[];
}

@Component({
  selector: 'app-grid-settings-dialog',
  templateUrl: './grid-settings-dialog.component.html',
  styleUrls: ['./grid-settings-dialog.component.scss']
})
export class GridSettingsDialogComponent implements OnInit {

  title = 'Settings';
  editColumnFields = [];
  selectField: any = null;

  constructor(public dialogRef: MatDialogRef<GridSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.title = this.data.title;

    this.editColumnFields = [];

    for (const column of this.data.columnFields) {

      if (column.dataType === -5) {
        this.selectField = column;
      }

      if (column.dataType != -5 && column.dataType != -3 && column.columnHeader) {
        this.editColumnFields.push(column);
      }
    }
  }

  drop(event: CdkDragDrop<any>)
  {
    moveItemInArray(this.editColumnFields, event.previousIndex, event.currentIndex);
  }

  onSave() {
    this.dialogRef.close(this.editColumnFields);
  }

}
