import { AfterViewInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataDetail } from '../../../../models/DataDetail';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';
import { MatCheckboxChange } from '@angular/material';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => ChecklistComponent),
  multi: true
};

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ChecklistComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  propertyDisplayArgs: string[];
  _displayStringArgs: string[];
  selectString: any;
  isDisabled: boolean;
  // @Input() selectedItem: SelectedItem;
  @Input() dataUri: string;
  @Input() name: string;
  @Input() bindValue: string;
  @Input() labelName: string;
  @Input() multiple: false;
  @Input() required: false;
  @Input() dataItems: any[];
  @Input() displayString: string;
  @Input() errorText: string;
  @Input() iconProperty: string;
  @Input() colourProperty: string;
  @Input() readonly: boolean;
  _selectedValue: number;
  _selectedArray: number[];
  @Input()
  set displayStringArgs(v: string[]) {
    this.propertyDisplayArgs = v ? v.map(str => Utilities.getLastEntry(str)) : [];
    this._displayStringArgs = v;
  }
  get displayStringArgs(): string[] {
    return this._displayStringArgs;
  }

  set value(val: any) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){

    if (!this.multiple) {
      this._selectedValue = parseInt(val as any, 10);
    } else {
      this._selectedArray = val ? val : [];
    }
    this.onChange(val);
    this.onTouched(val);
  }

  get value(): any {
    if (!this.multiple) {
      return this._selectedValue;
    } else {
      return this._selectedArray;
    }
  }

  totalRecordCount: number;
  returnRecordCount: number;
  loading: boolean;
  nestLevelKey = 'nestLevel';

  constructor(public toastr: ToastrService, private thingService: ThingService) { }
  // 

  ngOnInit() {
  }

  ngAfterViewInit() {

    const select = this.getSelectString();

    if (this.dataItems) {
      this.returnRecordCount = this.dataItems.length;
      this.totalRecordCount = this.dataItems.length;
    }

    if (this.dataUri) {
      setTimeout(() => {
        this.loading = true;
        this.thingService.getDataDetailWithHeader(this.dataUri, 0, null, null, null, null, '', select, true)
          .subscribe(results => this.onGetDataSuccessful(results), error => this.onGetDetailFailed(error));
      });
    }
  }

  writeValue(value: any): void {
    if (value !== this.value) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  onChange: any = (_) => { };
  onTouched: any = () => { };

  private getSelectString() {
    if (!this.selectString) {
      const props = Object.assign([{}], this.propertyDisplayArgs);
      if (this.colourProperty) {
        props.push(Utilities.getLastEntry(this.colourProperty));
      }
      if (this.iconProperty) {
        if (!props.find(p => p === Utilities.getLastEntry(this.iconProperty))) {
          props.push(Utilities.getLastEntry(this.iconProperty));
        }
      }
      if (this.propertyDisplayArgs.findIndex(s => s === this.bindValue) < 0) {
        if (!props.find(p => p === Utilities.getLastEntry(this.bindValue))) {
          props.push(Utilities.getLastEntry(this.bindValue));
        }
      }

      this.selectString = props.join(',');
    }
    return this.selectString;
  }

  private onGetDataSuccessful(dataDetail: DataDetail) {

    this.loading = false;
    this.dataItems = dataDetail.dataItems;

    this._selectedArray.forEach(val => {
      const v = this.dataItems.find(d => d[this.bindValue] === val);
      if (v) {
        v.q_checked = true;
      }
    });
    this.returnRecordCount = dataDetail.returnRecordCount;
    this.totalRecordCount = dataDetail.totalRecordCount;
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
    this.loading = false;
  }

  getDisplayText(item: any) {
    if (item) {
      return Utilities.parseArgumentsFromData(this.displayString, this.displayStringArgs, item);
    }
  }

  hasColor() {
    return this.colourProperty;
  }

  getColor(item: any): string {

    if (this.colourProperty) {
      const path = Utilities.getLastEntry(this.colourProperty);

      const color: string = item[path];
      if (color && color.length > 0) {
        if (!color.startsWith('#')) {
          return '#' + color;
        } else {
          return color;
        }
      }

      return '';
    }

    return '';
  }

  onChanged(event: MatCheckboxChange) {

    if (this.dataItems && this.multiple) {
      this._selectedArray = this.dataItems.filter(i => i.q_checked).map(j => j[this.bindValue]);
      this.onChange(this._selectedArray);
      this.onTouched(this._selectedArray);
    }
  }
}
