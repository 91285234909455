import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UpdateCompletedEvent } from 'src/app/models/events/UpdateCompletedEvent';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { UpdateDetailComponent } from '../../update-detail/update-detail.component';
import { log } from 'util';

export interface DialogData {
  action: AddUpdateItemModel;
}

@Component({
  selector: 'app-update-details-dialog',
  templateUrl: './update-details-dialog.component.html',
  styleUrls: ['./update-details-dialog.component.scss']
})
export class UpdateDetailsDialogComponent implements OnInit {

  @ViewChild(UpdateDetailComponent) updateDetailComponent: UpdateDetailComponent;

  action: AddUpdateItemModel;
  title: any;
  subtitle: any;
  updateRow: any;
  newItem: boolean;

  updateIndicator: boolean = false;

  onUpdateIndicator(event: boolean)
  {
    this.updateIndicator = event;
  }

  constructor(
    public dialogRef: MatDialogRef<UpdateDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  public onSetTitle(value: TitleUpdateEvent) {

    this.title = value.title;
    this.subtitle = value.subtitle;
  }

  checkShowButton(){
    if(this.updateDetailComponent){
      return this.updateDetailComponent.menuSave;
    }
    return false;
  }

  public onUpdateSave(e) {
    e.preventDefault();
    if (this.updateDetailComponent.detailsForm.valid)
    {
      this.updateDetailComponent.onSave({ closeOnFinish: true, objectKey: null });
    }
  }

  public onUpdateCompleted(event: UpdateCompletedEvent) {
    if (event.row){
      this.updateRow = event.row;
      this.newItem = event.newItem;
    }
    if (event.closeFlyout) {
      event.row = this.updateRow;
      event.newItem = this.newItem;
      this.dialogRef.close(event);
    }
  }

  
  public onDeleteCompleted(event: DeleteEvent) {

    // console.log("hey");
    // this.viewStateAction = null;
    // this.viewStateRow = null;
    // this.updateItem = false;

    // if (this.itemLists.last && this.itemLists.last.id === this.activeListDetail.id) {
    //   this.itemLists.last.refresh();
    // } else if (this.viewMultipleLists.last && this.viewMultipleLists.last.id === this.activeListDetail.id) {
    //   this.viewMultipleLists.last.refresh();
    // }
  }

  public onAction(event: ViewDetailEvent) {

    // // console.log('onAction');
    // const action = event.action as Action;

    // if (action.action === ActionIdentifier.LaunchScreen || action.action === ActionIdentifier.LaunchFlyout) {

    //   const actionArg = action.actionArgument as LaunchScreenAction;

    //   switch (actionArg.screenType) {
    //     case ScreenType.EditDetails:
    //     case ScreenType.ViewDetails:
    //       this.onView(event);
    //       // this.onAdd({ action: actionArg, row: event.row, screenParameters: event.screenParameters, newItem: event.newItem });
    //       break;

    //     case ScreenType.ItemGridList:
    //     case ScreenType.SwitchContentView:

    //       const existingListDetail: ListDetail = this.listDetails
    //         .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

    //       if (existingListDetail) {
    //         this.onSelect(existingListDetail);
    //       } else {

    //         const listDetail: ListDetail = new ListDetail();
    //         listDetail.action = event.action;
    //         listDetail.row = event.row;
    //         listDetail.screenType = actionArg.screenType;

    //         this.listDetails.push(listDetail);
    //         this.activeListDetail = listDetail;
    //       }
    //       break;

    //     case ScreenType.CompositeScreen:

    //       const existingCompositeViewDetail: ListDetail = this.listDetails
    //         .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

    //       if (existingCompositeViewDetail) {
    //         this.onSelect(existingCompositeViewDetail);
    //       } else {

    //         const listDetail: ListDetail = new ListDetail();
    //         listDetail.action = event.action;
    //         listDetail.row = event.row;
    //         listDetail.screenType = actionArg.screenType;

    //         this.listDetails.push(listDetail);
    //         this.activeListDetail = listDetail;
    //       }
    //       break;

    //     case ScreenType.TreeView:
    //       const existingTreeViewDetail: ListDetail = this.listDetails
    //         .find(a => (a.action.actionArgument.safeUrlPath) === actionArg.safeUrlPath);

    //       if (existingTreeViewDetail) {
    //         this.onSelect(existingTreeViewDetail);
    //       } else {

    //         const listDetail: ListDetail = new ListDetail();
    //         listDetail.action = event.action;
    //         listDetail.row = event.row;
    //         listDetail.screenType = actionArg.screenType;

    //         this.listDetails.push(listDetail);
    //         this.activeListDetail = listDetail;
    //       }
    //       break;

    //     case ScreenType.ProductCatalogue:
    //       this._showSideBar = false;

    //       const productCatalogueDetail: ListDetail = new ListDetail();
    //       productCatalogueDetail.action = event.action;
    //       productCatalogueDetail.row = event.row;
    //       productCatalogueDetail.screenType = actionArg.screenType;

    //       this.activeListDetail = productCatalogueDetail;
    //       break;

    //     case ScreenType.OrderCheckout:
    //       this._showSideBar = false;

    //       const orderCheckoutDetail: ListDetail = new ListDetail();
    //       orderCheckoutDetail.action = event.action;
    //       orderCheckoutDetail.row = event.row;
    //       orderCheckoutDetail.screenType = actionArg.screenType;

    //       this.activeListDetail = orderCheckoutDetail;
    //       break;

    //     case ScreenType.ShoppingBasket:
    //       // this.router.navigate(['/main/' + 'shoppingBasket']);
    //       this.onDisplayScreen(event);
    //       break;
    //     default:

    //       alert(`Main: Screen Type not supported ***! ${actionArg.screenType}`);
    //   }
    // } else if (event.action.action === ActionIdentifier.DisplayDialog) {

    //   if (event.action.actionArgument.screenType == ScreenType.PricingScreen) {

    //     const dialogRef = this.dialog.open(PricingDialogComponent, {
    //       width: '1000px',
    //       data: { action: event.action, row: event.row }
    //     });

    //     dialogRef.afterClosed().subscribe(result => {
    //       // TODO should pass the primaryKey generically - right now not sure why this event has the 'new' flag on it...
    //       const e = {
    //         row: event.row,
    //         primaryKey: event.row.productID,
    //         newItem: false
    //       };
    //       this.onUpdateCompleted(e);
    //     });

    //   }
    // } else {
    //   alert(`Main: Action not supported! ${action.action}`);
    // }
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
