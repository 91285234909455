import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ObjectFlag } from 'src/app/models/data/ObjectFlag';

export interface DialogData {
  title: string;
  flags: ObjectFlag[];
  type: 'warning' | 'info';
}

@Component({
  selector: 'app-flag-summary-dialog',
  templateUrl: './flag-summary-dialog.component.html',
  styleUrls: ['./flag-summary-dialog.component.scss']
})
export class FlagSummaryDialogComponent implements OnInit {
  title: any;

  constructor(public dialogRef: MatDialogRef<FlagSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    if (!this.data.title) {
      this.title = this.data.type === 'warning' ? 'Warnings' : 'Info';
    } else {
      this.title = this.data.title;
    }
  }

}
