import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { ToastrService } from 'ngx-toastr';
import { OrderBasketCardModel } from 'src/app/models/cardModels/OrderBasketCardModel';
import { QuantityCardModel } from 'src/app/models/cardModels/QuantityCardModel';
import { SimpleStatusCardModel } from 'src/app/models/cardModels/SimpleStatusCardModel';
import { StatusCardModel } from 'src/app/models/cardModels/StatusCardModel';
import { Action } from 'src/app/models/actions/Action';
import { MenuItem } from 'src/app/models/data/MenuItem';
import { ItemClickEvent } from 'src/app/models/events/ItemClickEvent';
import { LoadDataEvent } from 'src/app/models/events/LoadDataEvent';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { CardItem } from '../../../../models/cardModels/CardItem';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { DataDetail } from '../../../../models/DataDetail';
import { ActionIdentifier, CardItemType } from '../../../../models/Enums';
import { MenuNodeSection } from '../../../../models/menu-tree/MenuNodeSection';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss']
})
export class SectionListComponent implements OnInit {

  cardItemType = CardItemType;

  loadingIndicator = true;
  loadingData = false;
  dataLoaded = false;
  selectedItem: any;

  @Input() height: number;
  // @Input() metadata: FTThingDetail[];
  @Input() screenDetail: ScreenDetail;
  @Input() pageSize: number;
  @Input() totalRecordCount: number;
  @Input() width: number;
  _active: boolean;
  isEditing = false;
  @Input()
  set active(value: boolean) {
    this._active = value;
    this.loadingIndicator = !value;
  }
  @Input() screenParameters: any;
  @Input() idName: string;
  @Input() groupName: string;
  @Input() uriCollections?: UriCollection[] = [];
  @Input() readOnly = false;

  @Output() onLoadData = new EventEmitter<LoadDataEvent>();
  @Output() onViewItem = new EventEmitter<any>();
  @Output() onItemClick = new EventEmitter<ItemClickEvent>();
  @Output() onSectionSelected = new EventEmitter<MenuNodeSection>();

  // context menu
  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  contextRowItem: any;

  sectionCardTag: string;
  childCardTag: string;

  private _sectionKeys: any;
  private _childKeys: any;

  // TODO make this a generic solution
  productItemObjectName = 'productItemCurrentCatalogue';

  parentIdKey(cardItemTag: string) {
    if (cardItemTag === this.sectionCardTag) {
      return this._sectionKeys.parentKey;
    } else if (cardItemTag === this.childCardTag) {
      return this._childKeys.parentKey;
    }
  }

  idKey(cardItemTag: string) {
    if (cardItemTag === this.sectionCardTag) {
      return this._sectionKeys.idKey;
    } else if (cardItemTag === this.childCardTag) {
      return this._childKeys.idKey;
    }
  }

  foreignKey(cardItemTag: string) {
    if (cardItemTag === this.sectionCardTag) {
      return this._sectionKeys.foreignKey;
    } else if (cardItemTag === this.childCardTag) {
      return this._childKeys.foreignKey;
    }
  }

  sequenceKey(cardItemTag: string) {
    if (cardItemTag === this.sectionCardTag) {
      return this._sectionKeys.sequenceKey;
    } else if (cardItemTag === this.childCardTag) {
      return this._childKeys.sequenceKey;
    }
  }

  constructor(public toastr: ToastrService, private thingService: ThingService, private contextMenuService: ContextMenuService) {

  }

  ngOnInit() {
    if (!this.screenDetail) {
      return;
    }

    const childCard = this.screenDetail.cardItems.find(c => c.groupBy != null && c.groupBy.length > 0);
    if (childCard) {
      this.childCardTag = childCard.tag;
      this._childKeys = this.parseCardKeys(childCard);
    }
    const sectionCard = this.screenDetail.cardItems.find(c => c.groupBy == null || c.groupBy.length === 0);
    if (sectionCard) {
      this.sectionCardTag = sectionCard.tag;
      this._sectionKeys = this.parseCardKeys(sectionCard);
    }
  }

  private parseCardKeys(cardItem: CardItem) {
    const result: any = {};
    if (cardItem.nestBy) {
      const nestByParts = cardItem.nestBy.split('=');
      result.parentKey = Utilities.getLastEntry(nestByParts[0]);
      result.idKey = Utilities.getLastEntry(nestByParts[1]);
    }

    if (cardItem.groupBy) {
      const groupByParts = cardItem.groupBy.split('=');
      result.foreignKey = Utilities.getLastEntry(groupByParts[0]);
    }

    if (cardItem.orderBy) {
      result.sequenceKey = Utilities.getLastEntry(cardItem.orderBy);
    }
    return result;
  }

  public getData(cardItem: CardItem, parentId?: number, parentCardTag?: string) {

    let r: any[] = [];

    // root item
    if (parentCardTag == null) {
      r = this.uriCollections.find(u => u.name === cardItem.dataObjectKey).dataValues.filter(d => d[this.parentIdKey(cardItem.tag)] == parentId);
      if (this.foreignKey(cardItem.tag)) {
        r = r.filter(d => d[this.foreignKey(cardItem.tag)] == parentId);
      }
    } else if (parentCardTag && parentCardTag !== cardItem.tag) {
      // different parent item type

      // should give the list of orderItems filtered by 'sectionId = #'
      if (this.foreignKey(cardItem.tag)) {
        r = this.uriCollections.find(u => u.name === cardItem.dataObjectKey).dataValues
          .filter(d => d[this.foreignKey(cardItem.tag)] == parentId && d[this.parentIdKey(cardItem.tag)] == null);
      }
    } else {
      r = this.uriCollections.find(u => u.name === cardItem.dataObjectKey).dataValues.filter(d => d[this.parentIdKey(cardItem.tag)] == parentId);
    }

    if (this.sequenceKey(cardItem.tag)) {
      return r.sort((data1, data2) => data1[this.sequenceKey(cardItem.tag)] < data2[this.sequenceKey(cardItem.tag)] ? -1 : 1);
    } else {
      return r;
    }
  }

  public getMetadata(cardItem: CardItem) {
    return this.uriCollections.find(u => u.name === cardItem.dataObjectKey).dataMetadata;
  }

  public hasChildren(parentCardItem: CardItem, dataItem: any) {

    let hasChildren = false;
    this.cardItems.forEach(cardItem => {
      if (!dataItem[this.idKey(parentCardItem.tag)]) {
        hasChildren = false;
      } else if (this.getData(cardItem, dataItem[this.idKey(parentCardItem.tag)], parentCardItem.tag).length > 0) {
        hasChildren = true;
      }
    });

    return hasChildren;
  }

  public getItemId(cardItem: CardItem, dataItem: any) {
    return dataItem[this.idKey(cardItem.tag)];
  }

  // TODO
  private reloadData(sectionId?: number) {
    // TODO could use sectionId to only refresh the required item
    if (!this.loadingData) {
      this.loadingData = true;
      const sectionUriKey = this.getCardItem(this.sectionCardTag).dataObjectKey;
      const sectionUri = Utilities.getUriCollection(sectionUriKey, this.uriCollections);

      this.thingService.getDataDetailWithHeader(sectionUri.uris.dataUri)
        .subscribe(result => this.onGetDetailSuccessful(sectionUri.uris.name, null, result), error => this.onGetDetailFailed(error));
    }
  }

  private onGetDetailSuccessful(name: string, columns: FTThingDetail[], dataDetail: DataDetail) {

    this.dataLoaded = true;
    this.loadingData = false;

    const uriCollection = this.uriCollections.find(u => u.name === name);

    if (uriCollection) {
      if (columns) {
        uriCollection.dataMetadata = columns;
      }
      uriCollection.dataValues = dataDetail.dataItems;
    }

    this.loadingIndicator = false;
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  public getCardItem(cardItemTag: string): CardItem {
    return this.cardItems.find(c => c.tag === cardItemTag);
  }

  public getCardItemType(cardItem: CardItem): number {
    return cardItem.cardItemTypeId;
  }

  public getCardItemHeight(cardItem: CardItem, dataItem: any): string {

    if (cardItem.cardItemTypeId === this.cardItemType.OrderBasketCard) {
      return '150px';
    } else if (cardItem.cardItemTypeId === this.cardItemType.StatusCardItem || cardItem.cardItemTypeId === this.cardItemType.SimpleStatusCardItem) {
      if (dataItem.isEditingCard) {
        return '200px';
      } else {
        const cardOb = cardItem.cardItemObject as SimpleStatusCardModel;
        if (cardOb.detail1.text.argumentIds[0] && dataItem[Utilities.getLastEntry(cardOb.detail1.text.argumentIds[0])]) {
          return '80px';
        } else {
          return '58px';
        }
      }
    }
  }

  get cardItems(): CardItem[] {

    return this.screenDetail.cardItems;
  }

  public getCardTemplate(cardItem: CardItem): any {

    return cardItem.cardItemObject;
  }

  public getCardDataObjectKey(cardItem: CardItem): any {

    return cardItem.dataObjectKey;
  }

  public getCardDataObjectTag(cardItem: CardItem): any {

    return cardItem.tag;
  }

  hasColor(cardItem: CardItem): boolean {
    if (this.colorPropertyName(cardItem)) {
      return true;
    } else {
      return false;
    }
  }

  colorPropertyName(cardItem: CardItem): string {
    const cardItemType = cardItem.cardItemTypeId;
    let propertyName: string;
    switch (cardItemType) {
      case CardItemType.StatusCardItem:
        const statusCard = cardItem.cardItemObject as StatusCardModel;
        if (statusCard.cardColour && statusCard.cardColour.icon) {
          propertyName = statusCard.cardColour.icon.colour;
        }
        break;

      case CardItemType.SimpleStatusCardItem:
        const simpleStatusCard = cardItem.cardItemObject as SimpleStatusCardModel;
        if (simpleStatusCard.cardColour && simpleStatusCard.cardColour.icon) {
          propertyName = simpleStatusCard.cardColour.icon.colour;
        }
        break;

      case CardItemType.OrderBasketCard:
        const orderBasketCard = cardItem.cardItemObject as OrderBasketCardModel;
        if (orderBasketCard.cardColour && orderBasketCard.cardColour.icon) {
          propertyName = orderBasketCard.cardColour.icon.colour;
        }
        break;

      case CardItemType.QuantityCardItem:
      case CardItemType.QuantityCardItemWide:
        const quantityCard = cardItem.cardItemObject as QuantityCardModel;
        if (quantityCard.cardColor && quantityCard.cardColor.icon) {
          propertyName = quantityCard.cardColor.icon.colour;
        }
        break;
    }

    if (propertyName) {
      return Utilities.getLastEntry(propertyName);
    }
  }

  getColor(row: any, cardItem: CardItem): string {

    const propertyName = this.colorPropertyName(cardItem);
    if (row[propertyName]) {
      return `${row[propertyName]}`;
    }
    return '';
  }

  getTitle(cardItem: CardItem, row: any): string {

    if (cardItem.cardItemObject.title && cardItem.cardItemObject.title.text.argumentIds) {
      return Utilities.parseArgumentsFromData(cardItem.cardItemObject.title.text.text, cardItem.cardItemObject.title.text.argumentIds, row, null, null);
    }
  }

  drop(event: CdkDragDrop<string[]>) {

    if (!event.item.data) {
      return;
    }

    if (this.getListScreenTag(event.previousContainer.id) === this.getListScreenTag(event.container.id)) {
      this.dragDropWithinList(event);
    } else {
      this.dragDropFromAnotherList(event);
    }
  }

  private dragDropWithinList(event: CdkDragDrop<string[]>) {

    // if 'cardItem' exists then it has been sent from a section list
    const cardItem: CardItem = event.item.data.cardItem;
    const dataItem = event.item.data.item;

    // if the section is the same and can change sequence
    if (event.container.id === event.previousContainer.id && this.sequenceKey(cardItem.tag)) {
      this.updateSequence(event, cardItem, dataItem);
    } else {
      this.moveToNewSection(event, cardItem, dataItem);
      // drag between sections
    }
  }

  private updateSequence(event: CdkDragDrop<string[], string[]>, cardItem: CardItem, dataItem: any): any {

    const action = cardItem.onReorderAction;
    let subList: any[];
    const cardItemTag = cardItem.tag;
    if (!action) {
      return;
    }

    // reorder the list
    if (cardItemTag === this.getListParentCardItemTag(event.container.id)) {
      subList = this.getData(cardItem, dataItem[this.parentIdKey(cardItemTag)], cardItemTag);
    } else {
      subList = this.getData(cardItem, dataItem[this.foreignKey(cardItemTag)], this.getListParentCardItemTag(event.container.id));
    }

    const difference = event.currentIndex - event.previousIndex;
    if (difference > 0) {
      // the item is moved forward in the list
      if (!subList[event.previousIndex][this.sequenceKey(cardItemTag)]) {
        subList[event.previousIndex][this.sequenceKey(cardItemTag)] = 1;
      } else {
        subList[event.previousIndex][this.sequenceKey(cardItemTag)] += difference;
      }

      for (let i = event.previousIndex + 1; i < event.currentIndex + 1; i++) {
        if (!subList[i][this.sequenceKey(cardItemTag)]) {
          subList[i][this.sequenceKey(cardItemTag)] = 0;
        } else {
          subList[i][this.sequenceKey(cardItemTag)] -= 1;
        }
      }
    } else if (difference < 0) {
      // the item is moved backwards in the list
      if (!subList[event.previousIndex][this.sequenceKey(cardItemTag)]) {
        subList[event.previousIndex][this.sequenceKey(cardItemTag)] = 0;
      } else {
        subList[event.previousIndex][this.sequenceKey(cardItemTag)] += difference;
      }

      for (let i = event.currentIndex; i < event.previousIndex; i++) {
        if (!subList[i][this.sequenceKey(cardItemTag)]) {
          subList[i][this.sequenceKey(cardItemTag)] = 1;
        } else {
          subList[i][this.sequenceKey(cardItemTag)] += 1;
        }
      }
    } else {
      // no change - do nothing
    }

    // var uri = Utilities.parseArgumentsFromUriCollection(action[0].actionArgument.editUri, action[0].actionArgument.editUriArgs, this.uriCollections);
    if (action[0].actionArgument.editUri.includes('/actions/')) {

      subList.forEach(item => {
        this.onItemClick.emit({ row: item, newItem: item, action: action });
      });
    } else {
      this.onItemClick.emit({ updatedItems: subList, action: action });
    }
  }

  private moveToNewSection(event: CdkDragDrop<string[], string[]>, cardItem: CardItem, dataItem: any): any {
    const action = cardItem.onChangeParentAction;
    let subList: any[];
    const cardItemTag = cardItem.tag;
    const dataItems = this.uriCollections.find(u => u.name === cardItem.dataObjectKey).dataValues;

    if (!Utilities.canPerformAction(action, this.uriCollections, false, dataItem, cardItem.dataObjectKey)) {
      return;
    }

    // move the item to its new parent
    const newParentId = this.getListParentId(event.container.id);

    if (this.getListParentCardItemTag(event.container.id) === this.getListParentCardItemTag(event.previousContainer.id)) {
      if (this.getListParentCardItemTag(event.container.id) === cardItemTag) {
        dataItem[this.parentIdKey(cardItemTag)] = newParentId;
      } else {
        dataItem[this.foreignKey(cardItemTag)] = newParentId;
      }
    } else {
      dataItem[this.foreignKey(cardItemTag)] = newParentId;
      dataItem[this.parentIdKey(cardItemTag)] = null;
    }

    // resequence the new container
    if (this.sequenceKey(cardItemTag)) {

      const reorderaction = this.getCardItem(cardItemTag).onReorderAction;
      if (reorderaction) {

        subList = this.getData(this.getListCardItem(event.container.id), newParentId, this.getListParentCardItemTag(event.container.id));
        if (subList) {
          dataItem[this.sequenceKey(cardItemTag)] = subList[0][this.sequenceKey(cardItemTag)];
          for (let i = event.currentIndex; i < subList.length; i++) {
            (subList[i])[this.sequenceKey(cardItemTag)] += 1;
          }
        } else {
          dataItem[this.sequenceKey(cardItemTag)] = 0;
        }

        if (reorderaction[0].actionArgument.editUri.includes('/actions/')) {
          subList.forEach(item => {
            this.onItemClick.emit({ row: item, newItem: item, action: reorderaction });
          });
        } else {
          this.onItemClick.emit({ updatedItems: subList, action: reorderaction });
        }
      }
    }

    // move parent of the new item
    const index = dataItems.findIndex(d => d[this.idKey(cardItemTag)] == dataItem[this.idKey(cardItemTag)]);
    if (index) {
      if (this.sequenceKey(cardItemTag)) {
        dataItems[index][this.sequenceKey(cardItemTag)] = dataItem[this.sequenceKey(cardItemTag)];
      }
      dataItems[index][this.parentIdKey(cardItemTag)] = dataItem[this.parentIdKey(cardItemTag)];
    }

    // update dataItem
    if (action[0].actionArgument.editUri.includes('/actions/')) {
      this.onItemClick.emit({ row: dataItem, action: action });
    } else {
      this.onItemClick.emit({ updatedItems: [dataItem], action: action });
    }

    // now update the main list if we are re-sequencing
    if (subList && this.sequenceKey(cardItemTag)) {
      subList.forEach(element => {
        const subindex = dataItems.findIndex(d => d.id == element[this.idKey(cardItemTag)]);
        const item = this.uriCollections.find(u => u.name == cardItem.dataObjectKey).dataValues[subindex];
        if (item) {
          item[this.sequenceKey(cardItemTag)] = element[this.sequenceKey(cardItemTag)];
        }
      });
    }
  }

  private dragDropFromAnotherList(event: CdkDragDrop<string[], string[]>): any {

    const cardItem = this.getCardItem(this.getListCardItemTag(event.container.id));
    if (cardItem.onDropAction) {
      const action = cardItem.onDropAction;
      const newSection: any = {};

      if (event.item.data.senderKey && event.item.data.senderKey === this.productItemObjectName && this.getListCardItemTag(event.container.id) === this.sectionCardTag) {
        const newParentId = this.getListParentId(event.container.id);
        if (newParentId) {
          newSection[this.foreignKey(this.childCardTag)] = newParentId;
        }
      }

      if (action) {
        this.onItemClick.emit({ row: event.item.data.item, newItem: newSection, action: action });
      }
    }
  }

  dragStarted(item: any) {
    item.cardExpanded = false;
  }

  getDragData(item: any, cardItem: CardItem) {

    return {
      item: item,
      cardItem: cardItem
    };
  }

  getListName(cardItem: CardItem, parentCardTag, parentId?: number) {

    const name = parentId ?
      'childList-' + cardItem.tag + '-' + parentCardTag + '-' + parentId :
      'root-' + cardItem.tag;
    return this.screenDetail.screenTag + '-' + name;
  }

  getListScreenTag(listName: string) {
    const p = listName.split('-');
    return p[0];
  }

  getListParentId(listName: string) {

    const p = listName.split('-');
    if (p.length > 4) {
      if (p[1] === 'childList') {
        return parseInt(p[4], 10);
      }
    } else {
      return null;
    }
  }

  getListCardItem(listName: string) {

    const p = listName.split('-');

    if (p[1] === 'childList' || p[1] === ('root')) {
      const cardTag = p[2];
      let c = this.screenDetail.cardItems.find(card => card.tag === cardTag);
      if (!c) {
        c = this.screenDetail.cardItems.find(card => card.dataObjectKey === cardTag);
      }
      return c;
    } else {
      return null;
    }
  }

  getListParentCardItem(listName: string) {

    const p = listName.split('-');

    if (p[1] === ('childList')) {
      const cardTag = p[3];
      let c = this.screenDetail.cardItems.find(card => card.tag === cardTag);
      if (!c) {
        c = this.screenDetail.cardItems.find(card => card.dataObjectKey === cardTag);
      }
      return c;
    } else {
      return null;
    }
  }

  getListCardItemTag(listName: string) {

    const p = listName.split('-');
    if (p[1] === ('childList') || p[1] === ('root')) {
      return p[2];
    } else {
      return null;
    }
  }

  getListParentCardItemTag(listName: string) {

    const p = listName.split('-');
    if (p[1] === ('childList')) {
      return p[3];
    } else {
      return null;
    }
  }

  public itemClick(event) {

    this.onItemClick.emit(event);

  }

  refresh(sectionId: number) {
    console.log(`Refresh sectionId ${sectionId}`);
    this.reloadData(sectionId);
    this.onLoadData.emit({ pageIndex: 0, sectionId: sectionId });
  }

  // manage sections

  canAddNewSection() {
    if (this.sectionCardTag && this.childCardTag) {
      const sectionCardItem = this.getCardItem(this.sectionCardTag);
      if (sectionCardItem && sectionCardItem.onAddAction != null) {
        return true;
      }
    }
    return false;
  }

  canDelete(cardItem: CardItem) {
    return cardItem.onRemoveAction != null;
  }

  canEdit(cardItem: CardItem) {
    return cardItem.onEditAction != null;
  }

  canChangeParent(cardItem: CardItem) {
    return cardItem.onChangeParentAction != null;
  }

  canPromote(cardItem: CardItem, dataItem: any, parentId?: number, parentCardTag?: string) {
    // var changeParentAction = cardItem.onChangeParentAction;
    if (!parentCardTag || parentId == null) {
      return false;
    } else {
      return true;
    }
  }

  canDemote(cardItem: CardItem, dataItem: any, parentId?: number, parentCardTag?: string) {
    const dataList = this.getData(cardItem, parentId, parentCardTag);
    const index = dataList.findIndex(d => d[this.idKey(cardItem.tag)] === dataItem[this.idKey(cardItem.tag)]);
    return index > 0;
  }

  addNew(parentId: number, sectionId: number) {
    this.isEditing = true;

    const sectionCardItem = this.getCardItem(this.sectionCardTag);
    const action = sectionCardItem.onAddAction;
    if (!action) {
      return;
    }

    const dataObjectKey = sectionCardItem.dataObjectKey;
    const dataList = this.uriCollections.find(u => u.name === dataObjectKey).dataValues;

    const newObject = Utilities.generateRowFromDefaults(action[0].actionArgument.newObjectDefaults, null, this.screenParameters, dataObjectKey, this.uriCollections);

    // add the new section to the END of the list
    if (this.sequenceKey(this.sectionCardTag)) {
      let sequenceNo = 0;
      if (dataList.length > 0) {
        sequenceNo = dataList.sort((data1, data2) =>
          data1[this.sequenceKey(this.sectionCardTag)] > data2[this.sequenceKey(this.sectionCardTag)] ? -1 : 1)[0][this.sequenceKey(this.sectionCardTag)] + 1;
      }

      newObject[this.sequenceKey(this.sectionCardTag)] = sequenceNo;
    }

    if (this.foreignKey(this.sectionCardTag)) {
      newObject[this.foreignKey(this.sectionCardTag)] = sectionId;
    }

    newObject.isEditingCard = true;
    // new object has no parent
    newObject[this.parentIdKey(this.sectionCardTag)] = parentId;
    dataList.push(newObject);
  }

  promoteItem(cardItem: CardItem, dataItem: any, parentId?: number, parentCardTag?: string) {

    let dataList;
    if (cardItem.tag === parentCardTag) {
      const parentItem = this.uriCollections.find(u => u.name === cardItem.dataObjectKey).dataValues.find(d => d[this.idKey(parentCardTag)] === parentId);
      if (this.parentIdKey(cardItem.tag)) {
        dataList = this.getData(this.getCardItem(parentCardTag), parentItem[this.parentIdKey(parentCardTag)], parentCardTag);
        if (this.sequenceKey(cardItem.tag)) {
          // need to place the item directly after its parent
          dataItem[this.sequenceKey(parentCardTag)] = parentItem[this.sequenceKey(parentCardTag)] ? parentItem[this.sequenceKey(parentCardTag)] + 1 : 1;
          const parentIndex = dataList.find(i => i[this.idKey(parentCardTag)] == parentId);
          for (let index = parentIndex + 1; index < dataList.length; index++) {
            dataList[index][this.sequenceKey(parentCardTag)] += 1;
          }
        }
        // update the parentId of the item
        dataItem[this.parentIdKey(parentCardTag)] = parentItem[this.parentIdKey(parentCardTag)];

        dataList.push(dataItem);
        this.onItemClick.emit({ updatedItems: dataList, action: cardItem.onChangeParentAction });
      }
    }
  }

  demoteItem(cardItem: CardItem, dataItem: any, parentId?: number, parentCardTag?: string) {

    // This will make this a child of the item above it in the list

    // find the list of items our item is in
    const dataList = this.getData(cardItem, parentId, parentCardTag);
    // find our item in the list
    const index = dataList.findIndex(d => d[this.idKey(cardItem.tag)] == dataItem[this.idKey(cardItem.tag)]);

    if (index > 0) {
      // make sure the previous item is expanded
      dataList[index - 1].cardExpanded = true;
      // set the parentId to the id of the item above
      dataItem[this.parentIdKey(cardItem.tag)] = dataList[index - 1][this.idKey(cardItem.tag)];
    } else {
      return;
    }

    let subList;
    let sequenceNo = 0;
    if (this.sequenceKey(cardItem.tag)) {
      // get all the new parent's current children
      subList = this.getData(cardItem, dataList[index - 1][this.idKey(cardItem.tag)], cardItem.tag);
      // put the new item last in the list
      if (subList) {
        sequenceNo = dataList.sort((data1, data2) =>
          data1[this.sequenceKey(this.sectionCardTag)] > data2[this.sequenceKey(this.sectionCardTag)] ? -1 : 1)[0][this.sequenceKey(this.sectionCardTag)] + 1;
      }
    }
    dataItem[this.sequenceKey(cardItem.tag)] = sequenceNo;

    const changeParentAction = cardItem.onChangeParentAction;

    this.onItemClick.emit({ updatedItems: [dataItem], action: changeParentAction });
  }

  saveItem(cardItem: CardItem, dataItem: any) {

    dataItem.isEditingCard = false;

    const action = cardItem.onAddAction;
    if (!action) {
      return;
    }

    this.onItemClick.emit({ action: action, updatedItems: [dataItem] });
  }

  renameItem(cardItem: CardItem, dataItem: any) {

    dataItem.isEditingCard = true;
  }

  deleteItem(cardItem: CardItem, dataItem: any) {

    // Delete locally
    const dataList = this.uriCollections.find(u => u.name === cardItem.dataObjectKey).dataValues;
    const index = dataList.findIndex(i => i[this.idKey(cardItem.tag)] == dataItem[this.idKey(cardItem.tag)]);
    if (index > -1) {
      dataList.splice(index, 1);
    }

    if (cardItem && cardItem.onRemoveAction) {
      this.onItemClick.emit({ row: dataItem, action: cardItem.onRemoveAction });
    }
  }

  // fix spacebar
  handleSpacebar(ev) {
    if (ev.keyCode === 32) {
      ev.stopPropagation();
    }
  }

  public onAction(event: any) {
    this.onItemClick.emit({ row: event.dataItem, action: event.action });
  }

  onSelect(cardItem: CardItem, item: any) {
    this.selectedItem = item;
    if (cardItem.onClickAction) {
      const action: Action = cardItem.onClickAction[0];
      // 9 = Display Flyout / sidebar
      if (action.action === ActionIdentifier.LaunchFlyout || action.action === ActionIdentifier.ReloadData) {
        this.onViewItem.emit({ row: item, action: action, length: 1 });
      } else {
        alert(action.action + ' not defined');
      }
    }
  }

  // Context menu items

  hasContextMenu(cardItem: CardItem): boolean {
    return (cardItem.contextMenu && cardItem.contextMenu.length > 0);
  }

  onContextMenu($event: MouseEvent, item: any) {

    this.contextMenuService.show.next({
      // Optional - if unspecified, all context menu components will open
      contextMenu: this.basicMenu,
      event: $event,
      item: item,
    });
    // This is  fix for the sub-item (exectute) function not being called
    this.contextRowItem = item;
    $event.preventDefault();
    $event.stopPropagation();

  }

  onContextExecute(row: any, menuItem: MenuItem, event?: any) {
    let item = row;
    // Fix for sub-items not calling (execute) and passing item in the event - pick from global variable
    if (!item) {
      item = this.contextRowItem;
    }

    this.onItemClick.emit({ action: menuItem.action, row: item, newItem: menuItem.item });
  }

  hasMenuItems(parentMenuId?: number): boolean {
    if (!this.screenDetail.cardItems[0].contextMenu) {
      return false;
    }

    if (parentMenuId) {
      return this.screenDetail.cardItems[0].contextMenu.filter(m => m.parentMenuItemId === parentMenuId).length > 0;
    } else {
      return true;
    }
  }

  getMenuItems(parentMenuId?: number): MenuItem[] {

    if (!this.screenDetail.cardItems[0].contextMenu) {
      return;
    }

    let retList: MenuItem[] = [];
    if (!parentMenuId) {
      retList = this.screenDetail.cardItems[0].contextMenu.filter(m => !!!m.parentMenuItemId);
    } else {
      const itemList = this.screenDetail.cardItems[0].contextMenu.filter(m => m.parentMenuItemId === parentMenuId);
      if (itemList.length <= 0) {
        return;
      }

      itemList.forEach(menuItem => {
        if (menuItem.menuItemTitle.startsWith('data.')) {
          const list = Utilities.getUriCollection(menuItem.menuItemTitle, this.uriCollections).dataValues;
          list.forEach(item => {
            const newItem = {
              value: item.sectionId,
              menuItemId: item.sectionId,
              parentMenuItemId: 0,
              menuItemType: menuItem.menuItemType,
              menuItemTitle: Utilities.getValueFromArray(item, menuItem.menuItemTitle),
              menuItemIconId: 0,
              enableCondition: null,
              visibleCondition: null,
              action: menuItem.action,
              sequence: 0,
              active: true,
              open: true,
              item: item
            };
            retList.push(newItem);
          });
        } else {
          retList.push(menuItem);
        }
      });
    }

    return retList;
  }
}
