import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent } from 'angular-calendar';

@Component({
  selector: 'app-view-events-dialog',
  templateUrl: './view-events-dialog.component.html',
  styleUrls: ['./view-events-dialog.component.scss']
})
export class ViewEventsDialogComponent implements OnInit {


  events: Array<any>;

  constructor(public dialogRef: MatDialogRef<ViewEventsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.events = this.data.events;
  }

  getEventTypeName(event: any) {

    if (event.eventTypeId == 1) {
      return 'Absence';
    }
    if (event.eventTypeId == 2) {
      return 'Another Event Type';
    }
    return;
  }
}
