import { TextArgument } from './TextModel';
import { ScreenColumn } from './ScreenColumn';
import { MenuItem } from './data/MenuItem';
import { RequiredScreenParameters } from './screenModels/RequiredScreenParameters';
import { Grid } from './screenModels/Grid';
import { TreeViewItem } from './screenModels/TreeViewItem';
import { CardItem } from './cardModels/CardItem';
import { ScreenProperties } from './ScreenProperties';
import { ScreenType, TemplateType, SubScreenType, InstanceStyle } from './Enums';
import { FormTemplateItem } from './screenModels/TemplateItem';
import { Action } from './actions/Action';
import { UpdateDetailItem } from './viewGroupHelpers/UpdateDetailItem';

export class ScreenDetail {

  screenTag: string;
  screenPosition: string;
  screenTitle: TextArgument;
  screenSubtitle: TextArgument;
  listCount: string;
  screenProperties: ScreenProperties;
  requiredScreenParameters: RequiredScreenParameters[];
  screenType: ScreenType;
  screenSubType: SubScreenType;
  toolbarItems: MenuItem[];
  componentScreens: ScreenDetail[];
  detailSections: DetailSection[];
  newObjectDefaults: any;
  grid: Grid;
  isVisibleCondition: string;
  treeViewItems: TreeViewItem[];
  cardItems: CardItem[];
  priceTemplates: FormTemplateItem[];
  isEnabledCondition?: string;
}

export class DetailSection {

  templateType: TemplateType;
  orientation: string;
  details: ScreenColumn[];
  tag: string;
  title: TextArgument;
  templateDataKey: string;
  onAddAction: Action[];
  onEditAction: Action[];
  onRemoveAction: Action[];
  conditionAction: Action[];
  isVisibleCondition: string;
  colour: string;
  iconArgument: string;
  instanceStyle: InstanceStyle;
  sequenceNo: number;

  columnFields: UpdateDetailItem[];
}
