import { Component, Input, OnInit } from '@angular/core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import * as moment from 'moment';
import { Utilities } from 'src/app/services/utilities/utilities';

@Component({
  selector: 'app-date-time-range-input',
  templateUrl: './date-time-range-input.component.html',
  styleUrls: ['./date-time-range-input.component.scss']
})
export class DateTimeRangeInputComponent implements OnInit {

  _firstDataPath: string;
  _secondDataPath: string;
  @Input() row: any;
  @Input() placeholder: string;
  @Input() isDate = true;
  @Input() isTime = false;
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() errorText: string;

  firstDate: moment.Moment;
  firstTime: string;
  firstHours: number;
  firstMinutes: number;

  secondDate: moment.Moment;
  secondTime: string;
  secondHours: number;
  secondMinutes: number;

  isAllDay: boolean;
  
  public timeMask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

  get firstDataPath(): any {
    return this._firstDataPath;
  }
  get secondDataPath(): any {
    return this._secondDataPath;
  }

  @Input()
  set firstDataPath(value: any) {

    if (value) {
      this._firstDataPath = Utilities.getLastEntry(value);

      if (this.row[this.firstDataPath]) {

        this.firstDate = moment(this.row[this.firstDataPath]);

        this.setHours(true, this.firstDate.hours(), this.firstDate.minutes());
      }
    }
  }

  @Input()
  set secondDataPath(value: any) {

    if (value) {
      this._secondDataPath = Utilities.getLastEntry(value);

      if (this.row[this.secondDataPath]) {

        this.secondDate = moment(this.row[this.secondDataPath]);

        this.setHours(false, this.secondDate.hours(), this.secondDate.minutes());
      }
    }
  }

  setHours(isFirst: boolean, hours: number, minutes: number) {

    let h: string = hours.toString();
    let m: string = minutes.toString();

    if (h.length === 1) {
      h = '0' + h;
    }

    if (m.length === 1) {
      m = '0' + m;
    }

    if(isFirst){
      this.firstTime = h + ':' + m;
    }
    else{
      this.secondTime = h + ':' + m;
    }
    
    this.isAllDay = (this.firstTime == "00:00" && this.secondTime == "23:59");

  }

  constructor() { }

  ngOnInit() {
  }

  onDateUpdated(event, isFirst: boolean) {

    this.setDateTime(isFirst);

  }

  onTimeFocusOut(isFirst: boolean) {

    this.setDateTime(isFirst);

  }

  allDayChange(changeEvent){
    this.setDateTime(true);
    this.setDateTime(false);
  }

  setDateTime(isFirst: boolean) {

    let value = isFirst ? this.firstDate : this.secondDate;
    let time = isFirst ? this.firstTime : this.secondTime;
    let dataPath = isFirst ? this.firstDataPath : this.secondDataPath;

    if (!value) {
      value = moment();
    }

    if (this.isTime && time && !this.isAllDay) {
      const index = time.indexOf(':');

      if (index > -1) {

        let hours: number = parseInt(time.substring(0, index), 10);
        const minutes: number = parseInt(time.substring(index + 1), 10);

        if (hours >= 24) {
          hours -= 24;
          this.setHours(isFirst, hours, minutes);
        }

        value.hour(hours);
        value.minute(minutes);
      }
    }
    else{
      value = isFirst ? value.startOf('day') : value.endOf('day');
      value.milliseconds(0);
      value.seconds(0);
    }

    this.row[dataPath] = moment.utc(value, 'YYYY-MM-DDTHH:mm:ssZ');
  }

}
