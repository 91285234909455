export class TimeLine {
    title: string;
    date: string;
    detail1: string;
    detail2: string;
    detail3: string;
    icon: string;
    colour: string;
    eventText?: string;
    expanded: boolean;
}