import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { StatusCardModel } from 'src/app/models/cardModels/StatusCardModel';
import { FTThingDetail } from 'src/app/models/data/FTThingDetail';
import { UIDataType } from 'src/app/models/Enums';
import { Utilities } from 'src/app/services/utilities/utilities';
import { ThingService } from 'src/app/services/thing/thing.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FtFile } from 'src/app/models/dataService/FtFile';
import { FilePagerDialogComponent } from '../../../dialogs/file-pager-dialog/file-pager-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-simple-status-card',
  templateUrl: './simple-status-card.component.html',
  styleUrls: ['./simple-status-card.component.scss', './../card-style.scss']
})
export class SimpleStatusCardComponent implements OnInit, OnDestroy {

  public static cardHeight = 140;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() cardTemplate: StatusCardModel;
  @Input() dataItem: any;
  @Input() groupName: any;
  @Input() isEditing = false;
  @Input() metadata: FTThingDetail[];

  public SimpleStatusCardComponent = SimpleStatusCardComponent;
  public DataType = UIDataType;

  constructor(public dialog: MatDialog, public thingService: ThingService) { }

  ngOnInit() {
  }
  public getElementPropertyName(elementName: string): string {
    if (this.cardTemplate[elementName] && this.cardTemplate[elementName].text) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].text.argumentIds[0]);
    } else if (this.cardTemplate[elementName] && this.cardTemplate[elementName].icon) {
      return Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
    }
    return '';
  }

  showPictureViewer(elementName: string) {

    const elementPropertyName = Utilities.getLastEntry(this.cardTemplate[elementName].icon.iconArgument);
    const fileId = this.dataItem[elementPropertyName];

    this.thingService.getFileItem(fileId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => this.onGetFileSuccessful(results, elementPropertyName), error => console.log(error));
  }

  onGetFileSuccessful(file: FtFile, primaryFileIdPath: string) {
    this.dialog.open(FilePagerDialogComponent, {
      // width: '450px',
      data: {
        parentObjectIID: file.parentObjectIID,
        parentObjectType: file.parentObjectType,
        rowObject: this.dataItem,
        primaryFileIdPath: primaryFileIdPath
      }
    });
  }

  getCardColor(): string {

    if (!this.cardTemplate.cardColour) {
      return '';
    }
    const property = Utilities.getLastEntry(this.cardTemplate.cardColour.icon.colour);
    if (this.dataItem[property]) {

      if (this.dataItem[property].length > 0) {
        return `${this.dataItem[property]}`;
      }
    }
    return '';
  }

  getHint(elementName: string): string {

    if (!this.cardTemplate[elementName]) { return ''; }

    const path = this.cardTemplate[elementName]['hint'];
    return this.getValue(path);
  }

  getValue(propertyPath: string) {
    if (propertyPath == null || propertyPath === '') {
      return '';
    }
    if (propertyPath.startsWith('data.')) {
      return Utilities.getData(propertyPath, this.dataItem);
    } else {
      return propertyPath;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
