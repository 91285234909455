import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, throwError, of, BehaviorSubject } from 'rxjs';
import { map, catchError, shareReplay, tap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Utilities } from '../../services/utilities/utilities';
import { CachedHttp } from 'angular-async-cache';

import { MenuItems } from '../../models/data/MenuItems';
import { Lookup } from '../../models/data/Lookup';
import { LookupDetail } from '../../models/LookupDetail';
import { FTThingDetail } from '../../models/data/FTThingDetail';
import { ScreenDetail } from '../../models/ScreenDetail';
import { DataDetail } from '../../models/DataDetail';
import { FTFlowGroup } from '../../models/data/FTFlowGroup';
import { FTFlow } from '../../models/data/FTFlow';
import { FTFlowStatus } from '../../models/data/FTFlowStatus';
import { PostResponse } from '../../models/PostResponse';
import { MovementHistory } from '../../models/chartModels/MovementHistory';
import { AuthenticationService } from '../authentication/authentication.service';
import { FTTag } from 'src/app/models/data/FTTag';
import { FileUploadPost } from 'src/app/models/dataService/FileUploadPost';
import { FtFile } from 'src/app/models/dataService/FtFile';
import { FTConfigItem } from 'src/app/models/data/ConfigItem';
import { ConfigUtilitiesService } from '../utilities/config-utilities.service';
import { GenerateDocumentBody } from 'src/app/models/actions/GenerateDocumentAction';

@Injectable()
export class ThingService {

  constructor(private http: HttpClient, private cachedHttp: CachedHttp, private authenticationService: AuthenticationService) { }

  private rootMenuSubject: BehaviorSubject<MenuItems> = new BehaviorSubject(null);
  rootMenu$: Observable<MenuItems> = this.rootMenuSubject.asObservable();

  protected handleError(error, continuation: () => Observable<any>) {

    return throwError(error);
  }

  private handleError2(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  changePassword(oldPassword: string, newPassword: string, token: string): Observable<PostResponse> {

    const data = {
      token: token,
      oldPassword: oldPassword,
      newPassword: newPassword
    };

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<PostResponse>(`${AuthenticationService.apiRoot}/security/changePassword`,
      JSON.stringify(data), httpOptions).pipe(map(res => res),
        catchError(error => {
          return this.handleError(error, () => this.changePassword(oldPassword, newPassword, token));
        }));
  }

  getAbsenceCategoryById(id: number)
  {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

      const result = this.http.get<any>(`${this.authenticationService.getWebApiUri()}/api/data/absence/${id}`)
      .pipe(map(res => {
        return res;
      }));

    return result;
  }

  
  getAbsenceCategories()
  {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

      const result = this.http.get<any>(`${this.authenticationService.getWebApiUri()}/api/data/absenceCategory`)
      .pipe(map(res => {
        return res;
      }));

    return result;
  }



  getRootMenu(id: number): Observable<MenuItems> {
    const r = this.updateRootMenu(id);
    if (r) {
      return r.pipe(
        tap((data) => {
          this.rootMenuSubject.next(data);
        }));
    } else { return r; }
  }

  clearRootMenu()
  {
    this.rootMenuSubject.next(null);
  }

  updateRootMenu(id: number): Observable<MenuItems> {

    Utilities.log2('ThingService', 'getRootMenu');

    const uri = `/api/data/FTRootMenu/web/${id}`;

    Utilities.log(`${this.authenticationService.getWebApiUri()}${uri}`);

    // cachedHttp stops this running again when we log into a different system
    const result = this.http.get<MenuItems>(`${this.authenticationService.getWebApiUri()}${uri}`)
      .pipe(map(res => {
        return res;
      }),
        catchError(error => {
          return this.handleError(error, () => this.getRootMenu(id));
        }));

    return result;
  }

  getHomeMenu(): Observable<MenuItems> {

    Utilities.log2('ThingService', 'getRootMenu');

    const uri = `/api/data/FTRootMenu/mob/1`;

    Utilities.log(`${this.authenticationService.getWebApiUri()}${uri}`);

    // cachedHttp stops this running again when we log into a different system
    const result = this.http.get<MenuItems>(`${this.authenticationService.getWebApiUri()}${uri}`)
      .pipe(map(res => {
        return res;
      }),
        catchError(error => {
          return this.handleError(error, () => this.getHomeMenu());
        }));

    return result;
  }

  getLookups(): Observable<Lookup[]> {

    const uri = `/api/data/FTUserLookup`;

    Utilities.log2('ThingService', 'getLookups');
    Utilities.log(`${this.authenticationService.getWebApiUri()}${uri}`);

    return this.cachedHttp.get<Lookup[]>(`${this.authenticationService.getWebApiUri()}${uri}`)
      .pipe(map(res => res),
        catchError(error => {
          return this.handleError(error, () => this.getLookups());
        }));
  }

  getLookupDetails(): Observable<LookupDetail[]> {

    const uri = `/api/data/FTUserLookupDetail`;

    Utilities.log2('ThingService', 'getAllLookupDetails');
    Utilities.log(`${this.authenticationService.getWebApiUri()}${uri}`);

    return this.cachedHttp.get<LookupDetail[]>(`${this.authenticationService.getWebApiUri()}${uri}`).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getLookupDetails());
      }));
  }

  getViewDetail(metaURI: string, dataUri: string, screenUri: string) {

    Utilities.log2('ThingService', 'getViewDetail');

    return forkJoin(
      [this.getFTThingDetail(metaURI),
      this.getDataDetail(dataUri),
      this.getScreenDetailComponents(screenUri)]);
  }

  getMetaDataDetail(metaURI: string, dataUri: string) {

    Utilities.log2('ThingService', 'getMetaDataDetail');
    return forkJoin(
      [this.getFTThingDetail(metaURI),
      this.getDataDetail(dataUri)]);
  }

  getAddDetail(metaURI: string, screenUri: string) {

    Utilities.log2('ThingService', 'getAllDetail');
    return forkJoin(
      [this.getFTThingDetail(metaURI),
      this.getScreenDetailComponents(screenUri)]);
  }

  // Get FT Thing Detail
  getFTThingDetail(metaURI: string): Observable<FTThingDetail[]> {

    if (!metaURI) {
      return of(null);
    }
    Utilities.log2('ThingService', 'getFTThingDetail');

    Utilities.log(`${this.authenticationService.getWebApiUri()}${metaURI}`);

    const result = this.cachedHttp.get<FTThingDetail[]>(`${this.authenticationService.getWebApiUri()}${metaURI}`)
      .pipe(map(res => res),
        catchError(error => {
          Utilities.log2('ThingService', 'Error with uri ' + metaURI, 'error');
          return this.handleError(error, () => this.getFTThingDetail(metaURI));
        }));

    return result;
  }

  getDataDetail<T>(dataUri: string): Observable<T[]> {

    if (dataUri) {
      return this.http.get<T[]>(`${this.authenticationService.getWebApiUri()}${dataUri}`)
        .pipe(map(res => res),
          catchError(error => {
            return this.handleError(error, () => this.getDataDetail(dataUri));
          }));
    }
  }

  getMetaDataDetailwithHeader(metaURI: string, dataUri: string) {

    Utilities.log2('ThingService', 'getMetaDataDetail');
    return forkJoin(
      [this.getFTThingDetail(metaURI),
      this.getDataDetailWithHeader(dataUri)]);
  }

  getDataDetailWithHeader(dataUri: string, pageIndex: number = 0, pageSize?: number,
    sortName?: string, sortDirection?: string, filter?: string, search?: string, select?: string,
    distinct?: boolean, tags?: String[], customParams?: string): Observable<DataDetail> {

    if (!dataUri || dataUri.length === 0) {
      return of(null);
    }

    Utilities.log2('ThingService', 'getDataDetailWithHeader');

    let params = new HttpParams();

    if (pageSize && pageSize > 0) {
      params = params.set('$page', pageIndex.toString());
      params = params.set('$pageSize', pageSize.toString());
    }

    if (sortName) {
      params = params.set('$orderby', sortName + ' ' + sortDirection);
    }

    if (filter) {
      if (dataUri.includes('$filter')) {
        // params = params.set('$filter1', filter);
        params = params.set('$filter1', `(${filter})`);
      } else {
        params = params.set('$filter', filter);
      }
    }

    if (tags) {
      params = params.set('$tags', tags.join(','));
    }

    if (select) {
      params = params.set('$select', select);
    }

    if (distinct) {
      params = params.set('$distinct', distinct ? 'yes' : 'no');
    }

    if (search) {
      params = params.set('$freetextsearch', search);
    }

    if (customParams){
      params = params.set('@params', customParams);
    }

    const httpOptions = {
      params: params,
      headers: new HttpHeaders({ 'getheader': 'true' })
    };

    return this.http.get<DataDetail>(`${this.authenticationService.getWebApiUri()}${dataUri}`, httpOptions)
      .pipe(map(res => {
        return res;
      }),
        catchError(error => {
          Utilities.log2('ThingService', 'Error with uri ' + dataUri, 'error');
          return this.handleError(error, () => this.getDataDetail(dataUri));
        }));
  }

  postDataDetails<T>(editUri: string, data: object[], refreshOnSaveHeader?: boolean): Observable<PostResponse[]> {

    Utilities.log2('ThingService', 'postDataDetailSingle');
    Utilities.log2('ThingService', `${this.authenticationService.getWebApiUri()}${editUri}`);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if (refreshOnSaveHeader) {
      httpOptions.headers = httpOptions.headers.append('refreshonsave', 'true');
    }

    Utilities.log2('ThingService', JSON.stringify(data));

    if (editUri.includes('/actions/')) {
      return this.http.post<PostResponse>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify(data), httpOptions).pipe(map(res => [res]),
          catchError(error => {
            return this.handleError(error, () => this.postDataDetails(editUri, data));
          }));
    } else if (editUri.includes('/runnerbyname/')) {
      return this.http.post<PostResponse>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify({UpdatedObject: data[0]}), httpOptions).pipe(map(res => [res]),
          catchError(error => {
            return this.handleError(error, () => this.postDataDetails(editUri, data));
          }));
    } else if (editUri.includes('/runner/')) {
      return this.http.post<PostResponse>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify({UpdatedObject: data[0]}), httpOptions).pipe(map(res => [res]),
          catchError(error => {
            return this.handleError(error, () => this.postDataDetails(editUri, data));
          }));
    } else {
      return this.http.post<PostResponse[]>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify(data), httpOptions).pipe(map(res => res),
          catchError(error => {
            return this.handleError(error, () => this.postDataDetails(editUri, data, refreshOnSaveHeader));
          }));
    }
  }

  postDataDetail(editUri: string, data: any, refreshOnSaveHeader?: boolean): Observable<PostResponse[]> {

    Utilities.log2('ThingService', 'postDataDetail');
    Utilities.log(`${this.authenticationService.getWebApiUri()}${editUri}`);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if (refreshOnSaveHeader) {
      httpOptions.headers = httpOptions.headers.append('refreshonsave', 'true');
    }

    Utilities.log2('ThingService', JSON.stringify(data));

    if (editUri.includes('/actions/')) {
      return this.http.post<PostResponse>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify(data), httpOptions).pipe(map(res => [res]),
          catchError(error => {
            return this.handleError(error, () => this.postDataDetails(editUri, data));
          }));
    } else {

      const array = [data];
      return this.http.post<PostResponse[]>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify(array), httpOptions).pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return this.handleError(error, () => this.postDataDetail(editUri, data, refreshOnSaveHeader));
          }));
    }
  }

  postSingleDataDetail(editUri: string, data: any, refreshOnSaveHeader?: boolean): Observable<PostResponse> {

    Utilities.log2('ThingService', 'postDataDetail');
    Utilities.log(`${this.authenticationService.getWebApiUri()}${editUri}`);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    if (refreshOnSaveHeader) {
      httpOptions.headers = httpOptions.headers.append('refreshonsave', 'true');
    }

    Utilities.log2('ThingService', JSON.stringify(data));

    if (editUri.includes('/actions/')) {
      return this.http.post<PostResponse>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify(data), httpOptions).pipe(map(res => res),
          catchError(error => {
            return this.handleError(error, () => this.postDataDetails(editUri, data));
          }));
    } else {

      return this.http.post<PostResponse>(`${this.authenticationService.getWebApiUri()}${editUri}`,
        JSON.stringify(data), httpOptions).pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return this.handleError(error, () => this.postSingleDataDetail(editUri, data, refreshOnSaveHeader));
          }));
    }
  }

  getScreenDetail(screenUri: string): Observable<ScreenDetail> {

    Utilities.log2('ThingService', 'getScreenDetail');
    Utilities.log(`${this.authenticationService.getWebApiUri()}${screenUri}`);

    return this.http.get<ScreenDetail>(`${this.authenticationService.getWebApiUri()}${screenUri}`).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getScreenDetail(screenUri));
      }));
  }

  getTagList(objectType: string): Observable<FTTag[]> {

    const uri = `${this.authenticationService.getWebApiUri()}/api/view/FTTagByParentObjectType?ParentObjectType=${objectType}`;

    return this.http.get<FTTag[]>(uri)
      .pipe(map(res => res),
        catchError(error => {
          return this.handleError(error, () => this.getTagList(objectType));
        }));
  }

  uploadFile(fileUploadPost: FileUploadPost): Observable<PostResponse> {

    const uri = `${this.authenticationService.getWebApiUri()}/api/data/ftfile`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<PostResponse>(uri,
      JSON.stringify(fileUploadPost), httpOptions).pipe(map(res => res),
        catchError(error => {
          return this.handleError(error, () => this.uploadFile(fileUploadPost));
        }));
  }

  getFileList(parentObjectType: string, parentObjectIID: number): Observable<DataDetail> {

    const uri = `${this.authenticationService.getWebApiUri()}/api/oview/ftfileitemsummary?$filter=parentObjectType eq '${parentObjectType}' and parentObjectIID eq '${parentObjectIID}'`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.get<DataDetail>(uri, httpOptions).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getFileList(parentObjectType, parentObjectIID));
      }));
  }

  updateFile(file: FtFile) {
    const uri = `${this.authenticationService.getWebApiUri()}/api/data/ftfileitem`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<PostResponse>(uri,
      JSON.stringify([file]), httpOptions).pipe(map(res => res),
        catchError(error => {
          return this.handleError(error, () => this.updateFile(file));
        }));
  }

  updateOrderItem(item: any) {
    const uri = `${this.authenticationService.getWebApiUri()}/api/data/orderItem`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<PostResponse>(uri,
      JSON.stringify([item]), httpOptions).pipe(map(res => res),
        catchError(error => {
          return this.handleError(error, () => this.updateOrderItem(item));
        }));
  }

  getFileItem(fileItemId: string) {
    const uri = `${this.authenticationService.getWebApiUri()}/api/data/ftfileitem/${fileItemId}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.get<FtFile>(uri, httpOptions).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getFileItem(fileItemId));
      }));
  }

  getFileUrl(fileId: string, size?: 'tiny' | 'small' | 'medium' | 'large') {
    if (!fileId) {
      return null;
    }

    if (size) {
      return `${this.authenticationService.getWebApiUri()}/api/data/ftfile/${fileId}/${size}`;
    } else {
      return `${this.authenticationService.getWebApiUri()}/api/data/ftfile/${fileId}`;
    }
  }
  getFile(fileId: string, size?: 'tiny' | 'small' | 'medium' | 'large') {
    const uri = this.getFileUrl(fileId, size);
    return this.http.get(uri, {
      observe: 'response',
      responseType: 'text'});
  }

  downloadFile(fileId: string): Observable<HttpResponse<ArrayBuffer>>{
    return this.http.get(`${this.authenticationService.getWebApiUri()}/api/data/ftfile/${fileId}`,{
      observe: 'response',
      responseType: 'arraybuffer'});
  }

  getExcelDocument(uri: string){
    if (uri) {
      return this.http.get(`${this.authenticationService.getWebApiUri()}${uri}`, { responseType: 'blob'})
        .pipe(map(res => res),
          catchError(error => {
            return this.handleError(error, () => this.getExcelDocument(uri));
          }));
    }
  }


  generateHTMLDocument(generateDocumentBody: GenerateDocumentBody): Observable<HttpResponse<string>>{
    return this.http.post(`${this.authenticationService.getWebApiUri()}/api/actions/generatedocument`, generateDocumentBody, {observe: 'response', responseType: 'text'});
    
  }
  generatePDFDocument(generateDocumentBody: GenerateDocumentBody): Observable<HttpResponse<ArrayBuffer>>{
    return this.http.post(`${this.authenticationService.getWebApiUri()}/api/actions/generatedocument`, generateDocumentBody, {observe: 'response', responseType: 'arraybuffer'});
  }

  getData(metaURI: string, dataUri: string, pageIndex: number = 0, pageSize?: number,
    sortName?: string, sortDirection?: string, filter?: string, search?: string, select?: string, distinct?: boolean) {

    Utilities.log2('ThingService', 'getData');

    return forkJoin(
      [this.getFTThingDetail(metaURI),
      this.getDataDetailWithHeader(dataUri, pageIndex, pageSize, sortName, sortDirection, filter, search, select, distinct)]);
  }

  // getScreenDataDetail2(screenUri: string, metaURI1: string, dataUri1: string, metaURI2: string, dataUri2: string) {

  //   Utilities.log2('ThingService', 'getScreenDataDetail2');

  //   return forkJoin(
  //     [this.getScreenDetailComponents(screenUri),
  //     this.getDataDetailWithHeader(dataUri1, 0),
  //     this.getDataDetailWithHeader(dataUri2, 0),
  //     this.getFTThingDetail(metaURI1),
  //     this.getFTThingDetail(metaURI2)]);
  // }

  // getScreenDataDetail3(screenUri: string, metaURI1: string, dataUri1: string, metaURI2: string, dataUri2: string,
  //   metaURI3: string, dataUri3: string) {

  //   Utilities.log2('ThingService', 'getScreenDataDetail3');

  //   return forkJoin(
  //     [this.getScreenDetailComponents(screenUri),
  //     this.getDataDetailWithHeader(dataUri1, 0),
  //     this.getDataDetailWithHeader(dataUri2, 0),
  //     this.getDataDetailWithHeader(dataUri3, 0),
  //     this.getFTThingDetail(metaURI1),
  //     this.getFTThingDetail(metaURI2),
  //     this.getFTThingDetail(metaURI3)]);
  // }

  getScreenDataDetail5(
    screenUri: string,
    metaURI1: string, dataUri1: string,
    metaURI2: string, dataUri2: string,
    metaURI3: string, dataUri3: string,
    metaURI4: string, dataUri4: string,
    metaURI5: string, dataUri5: string) {

    Utilities.log2('ThingService', 'getScreenDataDetail5');

    return forkJoin(
      [
        this.getScreenDetailComponents(screenUri),
        this.getDataDetailWithHeader(dataUri1, 0),
        this.getDataDetailWithHeader(dataUri2, 0),
        this.getDataDetailWithHeader(dataUri3, 0),
        this.getDataDetailWithHeader(dataUri4, 0),
        this.getDataDetailWithHeader(dataUri5, 0),
        this.getFTThingDetail(metaURI1),
        this.getFTThingDetail(metaURI2),
        this.getFTThingDetail(metaURI3),
        this.getFTThingDetail(metaURI4),
        this.getFTThingDetail(metaURI5)
      ]);
  }

  // getDataDetail2(metaURI1: string, dataUri1: string, metaURI2: string, dataUri2: string) {

  //   Utilities.log2('ThingService', 'getDataDetal2');

  //   return forkJoin(
  //     [this.getDataDetailWithHeader(dataUri1, 0),
  //     this.getDataDetailWithHeader(dataUri2, 0),
  //     this.getFTThingDetail(metaURI1),
  //     this.getFTThingDetail(metaURI2)]);
  // }

  getData2(dataUri1: string, dataUri2: string) {

    Utilities.log2('ThingService', 'getData2');

    return forkJoin(
      [this.getDataDetailWithHeader(dataUri1, 0),
      this.getDataDetailWithHeader(dataUri2, 0)]);
  }

  getDataScreenDetail(metaURI: string, dataUri: string, screenUri: string, pageIndex: number = 0, pageSize?: number,
    sortName?: string, sortDirection?: string, filter?: string, search?: string, select?: string, distinct?: boolean) {

    Utilities.log2('ThingService', 'getScreenDetail');

    return forkJoin(
      [this.getFTThingDetail(metaURI),
      this.getDataDetailWithHeader(dataUri, pageIndex, pageSize, sortName, sortDirection, filter, search, select, distinct),
      this.getScreenDetailComponents(screenUri)]);
  }
  getScreenDetailComponents(screenUri: string): Observable<ScreenDetail> {

    Utilities.log2('ThingService', 'getScreenDetailComponents');

    Utilities.log(`${this.authenticationService.getWebApiUri()}${screenUri}`);

    // const result = this.cachedHttp.get<ScreenDetail>(`${this.authenticationService.getWebApiUri()}${screenUri}`).pipe(map(res => res),
    const result = this.http.get<ScreenDetail>(`${this.authenticationService.getWebApiUri()}${screenUri}`).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getScreenDetailComponents(screenUri));
      }));

    return result;

  }

  getFlowGroupFlowAndLookupsStatus() {

    return forkJoin([
      this.getFlowGroups(),
      this.getFlows(),
      this.getFlowStatuses(),
      this.getLookups(),
      this.getLookupDetails()
    ]);
  }

  getFlowGroupFlowAndStatus() {

    return forkJoin([
      this.getFlowGroups(),
      this.getFlows(),
      this.getFlowStatuses()
    ]);
  }

  getLookupAndDetails(): Observable<[Lookup[], LookupDetail[]]> {

    return forkJoin([
      this.getLookups(),
      this.getLookupDetails()
    ]);
  }

  getFlowGroups(): Observable<FTFlowGroup[]> {

    Utilities.log2('ThingService', 'getFlowGroups');
    Utilities.log(`${this.authenticationService.getWebApiUri()}/api/data/FTFlowGroup`);

    return this.http.get<FTFlowGroup[]>(`${this.authenticationService.getWebApiUri()}/api/data/FTFlowGroup/`).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getFlowGroups());
      }));

  }

  getFlows(): Observable<FTFlow[]> {

    Utilities.log2('ThingService', 'getFlows');
    Utilities.log(`${this.authenticationService.getWebApiUri()}/api/data/FTFlow`);

    return this.http.get<FTFlow[]>(`${this.authenticationService.getWebApiUri()}/api/data/FTFlow/`).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getFlows());
      }));

  }

  getFlowStatuses(): Observable<FTFlowStatus[]> {

    Utilities.log2('ThingService', 'getFlowStatuses');
    Utilities.log(`${this.authenticationService.getWebApiUri()}/api/data/FTFlowStatus`);

    return this.http.get<FTFlowStatus[]>(`${this.authenticationService.getWebApiUri()}/api/data/FTFlowStatus`).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getFlowStatuses());
      }));

  }

  deleteItem(editUri: string, ids: string[]): Observable<PostResponse[]> {

    Utilities.log2('ThingService', 'deleteItem');
    Utilities.log(`${this.authenticationService.getWebApiUri()}${editUri}`);
    Utilities.log(JSON.stringify(ids));

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<PostResponse[]>(`${this.authenticationService.getWebApiUri()}${editUri}`,
      JSON.stringify(ids), httpOptions).pipe(map(res => res),
        catchError(error => {
          return this.handleError(error, () => this.deleteItem(editUri, ids));
        }));

  }

  deleteSingleItem(editUri: string): Observable<PostResponse> {

    Utilities.log2('ThingService', 'deleteSingleItem');
    Utilities.log(`${this.authenticationService.getWebApiUri()}${editUri}`);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.delete<PostResponse>(`${this.authenticationService.getWebApiUri()}${editUri}`, httpOptions).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.deleteSingleItem(editUri));
      }));

  }

  getMovementHistory(dataUri: string): Observable<MovementHistory> {

    Utilities.log2('ThingService', 'getMovementHistory');
    Utilities.log2('ThingService', `${this.authenticationService.getWebApiUri()}${dataUri}`);
    return this.http.get<MovementHistory>(`${this.authenticationService.getWebApiUri()}${dataUri}`).pipe(map(res => res),
      catchError(error => {
        return this.handleError(error, () => this.getMovementHistory(dataUri));
      }));
  }

  getConfig() : Observable<FTConfigItem[]>{
    const result = this.http.get<FTConfigItem[]>(`${this.authenticationService.getWebApiUri()}/api/data/ftconfigitem`)
      .pipe(map(res => {
        return res;
      }),
      catchError(error => {
        return this.handleError(error, () => this.getConfig());
      }));

      return result;
  }

  getWorkingWeekConfig(): Observable<any> {

    // cachedHttp stops this running again when we log into a different system
    const result = this.http.get<any>(`${this.authenticationService.getWebApiUri()}/api/search/ftconfigitem/displayname/CustomerConfig.WorkingWeekSpecification`)
      .pipe(map(res => {
        return res;
      }),
        catchError(error => {
          return this.handleError(error, () => this.getWorkingWeekConfig());
        }));

    return result;
  }
}
