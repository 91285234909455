import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuNode } from 'src/app/models/menu-tree/MenuNode';

@Component({
  selector: 'app-menu2',
  templateUrl: './menu2.component.html',
  styleUrls: ['./menu2.component.scss']
})
export class Menu2Component implements OnInit {

  @Input()
  canAdd: boolean;

  @Input()
  canDelete: boolean;

  @Input()
  editItemNodeId: any;

  @Input()
  node: MenuNode;

  @Output()
  addItem = new EventEmitter<MenuNode>();

  @Output()
  deleteItem = new EventEmitter<MenuNode>();

  constructor() { }

  ngOnInit() {
  }

  onAddItem(item: MenuNode)
  {
    console.log(item);
    this.addItem.emit(item);
  }

  onDeleteItem(item: MenuNode)
  {
    this.deleteItem.emit(item);
  }

}
