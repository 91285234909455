export class DataPath {

    constructor(dataPath: string) {
            if (dataPath == null || !dataPath.startsWith('data.')) {
                // console.log(`${dataPath} is not a data path`);
                this.isValid = false;
                return null;
            }
    
            this.isValid = true;
            const parts = dataPath.split('.');
            parts.shift();
            this.rootObjectName = parts.shift();
            this.propertyName = parts.pop();
            this.childObjectNames = parts;
    }
    isValid: boolean;
    rootObjectName: string;
    childObjectNames: string[];
    propertyName: string;
}
