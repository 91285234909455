import { SelectionModel } from '@angular/cdk/collections';
import { E, X } from '@angular/cdk/keycodes';
import { NestedTreeControl } from '@angular/cdk/tree';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { IconHelper } from 'src/app/helpers/icon-helper';
import { LaunchScreenAction } from 'src/app/models/actions/LaunchScreenAction';
import { UpdateDataAction } from 'src/app/models/actions/UpdateDataAction';
import { TreeItemSelectedEvent } from 'src/app/models/events/TreeItemSelectedEvent';
import { IconMapping } from 'src/app/models/IconMapping';
import { PostResponse } from 'src/app/models/PostResponse';
import { UriCollection } from 'src/app/models/screenModels/UriCollection';
import { ColourPalleteService } from 'src/app/services/colour-palette/colour-palette.service';
import { IconService } from 'src/app/services/icon/icon.service';
import { ColorPalette } from 'src/app/values/ColorPalette';
import { Action } from '../../../../models/actions/Action';
import { ActionURI } from '../../../../models/data/ActionURI';
import { FTThingDetail } from '../../../../models/data/FTThingDetail';
import { DataDetail } from '../../../../models/DataDetail';
import { ActionIdentifier, CardItemType } from '../../../../models/Enums';
import { MenuNode } from '../../../../models/menu-tree/MenuNode';
import { ScreenDetail } from '../../../../models/ScreenDetail';
import { ThingService } from '../../../../services/thing/thing.service';
import { Utilities } from '../../../../services/utilities/utilities';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit, AfterViewInit {


  @Input() loadScreenDetail = true;
  @Input() action: Action;
  @Input() uri: ActionURI;
  @Input() uriCollections: UriCollection[];
  // @Input() height: number;
  @Input() checkedIds: number[];
  title: string;
  @Input() isEditable: Boolean;

  editedNodeIndexList: Array<number> = new Array<number>();

  treeLoaded = false; // True once data has been loaded
  columns: FTThingDetail[];
  dataDetail: DataDetail;
  _screenDetail: ScreenDetail; // Referenced by filter dialog
  _visible = true; // Prevents auto load if not visible

  loadingIndicator = true;
  menuNodes: MenuNode[] = [];

  @Output() treeItemSelected = new EventEmitter<TreeItemSelectedEvent>();

  parentIdName: string;
  idName: string;
  largestWidth: number = 0;
  interval: any;

  iconMappings: Array<IconMapping>;
  filteredOptions: Observable<IconMapping[]>;
  options: Array<IconMapping>;
  iconFilterControl = new FormControl();

  showMenu1: boolean = false;
  showMenu2: boolean = false;

  treeControl: NestedTreeControl<MenuNode>;
  treeDataSource: MatTreeNestedDataSource<MenuNode>;
  @ViewChild('matTree') tree: MatTree<MenuNode>;

  // The selection for the checklist
  checklistSelection = new SelectionModel<MenuNode>(true /* multiple */);
  textAttribute: string;
  colorAttribute: string;
  iconAttribute: string;
  addItemNodeId: number;
  editItemNodeId: number;
  hoveredNodeId: number;

  colorPalette: string[] = [];
  isEditing = false;
  canAdd = false;
  canEdit = false;
  canDelete = false;
  hasFiltered

  get screenDetail(): ScreenDetail {
    return this._screenDetail;
  }

  @Input()
  set screenDetail(value: ScreenDetail) {

    this._screenDetail = value;
    this.initialLoad();
  }

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {

    this._visible = value;
    this.initialLoad();
  }

  @Output()
  onCloseDialog = new EventEmitter<boolean>();

  constructor(public toastr: ToastrService, private thingService: ThingService, private iconHelper: IconHelper, private iconService: IconService, private colourPalleteService: ColourPalleteService) { }

  ngAfterViewInit(): void {
    this.interval = setInterval(() => {
      this.getLargestWidth();
    }, 100)
  }


  getLargestWidth() {
    if (!!this.menuNodes && this.menuNodes.length > 0) {
      for (let i = 0; i < this.menuNodes.length; i++) {
        const width = document.getElementById(`${i}`).offsetWidth;
        if (document.getElementById(`${i}`).offsetWidth > this.largestWidth) {
          this.largestWidth = width;
        }
      }
      for (let i = 0; i < this.menuNodes.length; i++) {
        document.getElementById(`${i}`).style.width = `${this.largestWidth + 30}px`;
      }
      clearInterval(this.interval);
    }
  }

  public colorLuminance(hex, lum) {
    // Validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex.replace(/(.)/g, '$1$1');
    }
    lum = lum || 0;
    // Convert to decimal and change luminosity
    var rgb = "#",
      c;
    for (var i = 0; i < 3; ++i) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }
    return rgb;
  }

  private _filter(value: string) {
    this.iconMappings = this.iconService.getIconMaps();
    const filterValue = value.toLowerCase();

    this.iconMappings = this.iconMappings.filter(option => option.name[0].value.toLowerCase().includes(filterValue));
  }

  ngOnInit() {

    this.iconMappings = this.iconService.getIconMaps();

    this.iconFilterControl.valueChanges.subscribe(x => {
      this._filter(x);
    });

    this.colourPalleteService.getColourPallete().subscribe(x => {
      this.colorPalette = x;

      var length = this.colorPalette.length;

      var value = 1
      for (let z = 0; z < 9; z++) {
        value -= 0.2;
        for (let i = 0; i < length; i++) {
          const color = this.colorLuminance(this.colorPalette[i], value);
          this.colorPalette.push(color);
        }
      }
    })

    this.treeControl = new NestedTreeControl<MenuNode>(this._getChildren);
    this.treeDataSource = new MatTreeNestedDataSource();

    if (!this.uri && this.screenDetail) {
      const treeViewItem = this.screenDetail.treeViewItems.find(t => t.treeItemTypeId === CardItemType.TreeViewItem);
      const dataKey = treeViewItem.dataObjectKey;

      if (dataKey) {
        this.uri = (this.action.actionArgument as LaunchScreenAction).uris.find(u => u.name === dataKey);
      } else {
        this.uri = (this.action.actionArgument as LaunchScreenAction).uris[0];
      }
      this.thingService.getDataScreenDetail(this.uri.metaUri, this.uri.dataUri, this.action.actionArgument.screenUri)
        .subscribe(result => this.getDataDetailWithHeaderSuccessful(result[1], result[0], result[2]),
          error => this.onGetDetailFailed(error));
    } else if (this.loadScreenDetail) {

      this.thingService.getDataScreenDetail(null, null, this.action.actionArgument.screenUri)
        .subscribe(result => this.getDataDetailWithHeaderSuccessful(result[1], result[0], result[2]),
          error => this.onGetDetailFailed(error));
    }
  }

  onNodeTextChange(node: MenuNode, event)
  {
    this.editedNodeIndexList.push(node.index);
    node.text = event;
  }

  containsNodeIndex(index: number)
  {
    return this.editedNodeIndexList.includes(index);
  }

  public getIcon(id: number) {
    return String.fromCharCode(id);
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  private initialLoad() {

    if (this.screenDetail && !this.loadScreenDetail && this.visible && !this.treeLoaded) {

      if (this.uriCollections) {

        const treeViewItem = this.screenDetail.treeViewItems.find(t => t.treeItemTypeId === CardItemType.TreeViewItem);
        const dataKey = treeViewItem.dataObjectKey;

        const coll = this.uriCollections.find(u => u.name === dataKey);
        if (coll) {
          const dd: DataDetail = {
            dataItems: coll.dataValues,
            returnRecordCount: coll.dataValues.length,
            totalRecordCount: coll.dataValues.length
          };
          this.getDataDetailWithHeaderSuccessful(dd, coll.dataMetadata);
        }
      } else if (!this.uri) {
        const treeViewItem = this.screenDetail.treeViewItems.find(t => t.treeItemTypeId === CardItemType.TreeViewItem);
        const dataKey = treeViewItem.dataObjectKey;

        if (dataKey) {
          this.uri = (this.action.actionArgument as LaunchScreenAction).uris.find(u => u.name === dataKey);
        } else {
          this.uri = (this.action.actionArgument as LaunchScreenAction).uris[0];
        }
      }

      this.thingService.getData(this.uri.metaUri, this.uri.dataUri, 0, 0, null, null, null, null)
        .subscribe(result => this.getDataDetailWithHeaderSuccessful(result[1], result[0]), error => this.onGetDetailFailed(error));
    }
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  private getDataDetailWithHeaderSuccessful(dataDetail: DataDetail, columns?: FTThingDetail[], screenDetailComponents?: ScreenDetail) {

    Utilities.log('getDataDetailWithHeaderSuccessful');
    if (screenDetailComponents) {
      this.title = Utilities.parseArgumentsFromData(screenDetailComponents.screenTitle.text,
        screenDetailComponents.screenTitle.argumentIds, null, null, null);
      this.screenDetail = screenDetailComponents.componentScreens[0];
    }
    const treeViewItem = this.screenDetail.treeViewItems.find(t => t.treeItemTypeId === CardItemType.TreeViewItem);

    if (!this.uri && !dataDetail) {
      if (!this.uri) {
        const dataKey = treeViewItem.dataObjectKey;
        if (dataKey) {
          this.uri = (this.action.actionArgument as LaunchScreenAction).uris.find(u => u.name === dataKey);
        } else {
          this.uri = (this.action.actionArgument as LaunchScreenAction).uris[0];
        }

        if (this.uriCollections) {
          const coll = this.uriCollections.find(u => u.name === dataKey);
          if (coll) {
            dataDetail = { dataItems: coll.dataValues, totalRecordCount: coll.dataValues.length, returnRecordCount: coll.dataValues.length };
            columns = coll.dataMetadata;
          }
        }

        if (!dataDetail) {
          this.thingService.getDataScreenDetail(this.uri.metaUri, this.uri.dataUri, this.action.actionArgument.screenUri)
            .subscribe(result => this.getDataDetailWithHeaderSuccessful(result[1], result[0], result[2]),
              error => this.onGetDetailFailed(error));
        }
      }
      return;
    }

    this.treeLoaded = true;
    this.dataDetail = dataDetail;

    if (columns) {
      this.columns = columns;
    }

    this.canAdd = this.screenDetail.treeViewItems[0].onAddAction != null;
    this.canEdit = this.screenDetail.treeViewItems[0].onEditAction != null;
    this.canDelete = this.screenDetail.treeViewItems[0].onRemoveAction != null;

    // find the tree view template and properties
    if (!this.screenDetail.treeViewItems) {
      return;
    }

    let colorProperty: string;
    let iconProperty: string;
    const textProperty = treeViewItem.treeItemObject.title.text.argumentIds[0];

    if (treeViewItem.treeItemObject.cardColor && treeViewItem.treeItemObject.cardColor.icon) {
      colorProperty = treeViewItem.treeItemObject.cardColor.icon.colour;
    }
    if (treeViewItem.treeItemObject.icon && treeViewItem.treeItemObject.icon.icon) {
      iconProperty = treeViewItem.treeItemObject.icon.icon.iconArgument;
    }
    if (textProperty) {
      this.textAttribute = Utilities.getLastEntry(textProperty);
    }
    if (colorProperty) {
      this.colorAttribute = Utilities.getLastEntry(colorProperty);
    }
    if (iconProperty) {
      this.iconAttribute = Utilities.getLastEntry(iconProperty);
    }

    if (this.screenDetail) {
      const nestBy: string[] = treeViewItem.nestBy.split('=');

      // Assume parent Id is first followed by main
      this.parentIdName = nestBy[0].substring(nestBy[0].lastIndexOf('.') + 1);
      this.idName = nestBy[1].substring(nestBy[0].lastIndexOf('.') + 1);

      const items = this.dataDetail.dataItems.filter(m => !m[this.parentIdName]);

      this.createMenuItems(items, this.menuNodes);
    }

    this.treeControl = new NestedTreeControl<MenuNode>(this._getChildren);

    for (let i = 0; i < this.menuNodes.length; i++) {
      this.menuNodes[i].index = i;
    }

    this.treeDataSource.data = this.menuNodes;

    this.loadingIndicator = false;
  }

  createMenuItems(items: any[], menuNodes: MenuNode[]) {
    for (const item of items) {

      const menuNode: MenuNode = new MenuNode(item[this.idName], item[this.textAttribute], true, item[this.colorAttribute], item[this.iconAttribute]);

      if (this.hasChildren(item)) {

        menuNode.children = [];
        menuNode.hasChildren = true;
        this.createMenuItems(this.children(item), menuNode.children);
        // this.loadChildren(menuNode, item);
      }

      var iconHex = this.iconHelper.getFontAwesomeHex(menuNode.iconId);
      var iconMapping = this.iconService.getIconMaps().find(x => x.charCode === iconHex);
      menuNode.icon = this.iconHelper.setIcon(iconMapping, iconHex);

      menuNodes.push(menuNode);

      if (this.checkedIds && this.checkedIds.findIndex(i => i === menuNode.id) > -1) {
        this.checklistSelection.select(menuNode);
      }
    }
  }

  public closeDialog() {
    this.onCloseDialog.emit(true);
  }

  private _getChildren = (node: MenuNode) => node.children;

  hasNestedChild = (_: number, nodeData: MenuNode) => nodeData.hasChildren;

  hasChildren(item: any): boolean {

    return (this.dataDetail.dataItems.find(m => m[this.parentIdName] === item[this.idName]) != null);

  }

  children(item: any): any[] {

    return (this.dataDetail.dataItems.filter(m => m[this.parentIdName] === item[this.idName]));

  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: MenuNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: MenuNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  menuItemSelectionToggleSingle(node: MenuNode): void {
    this.checklistSelection.toggle(node);
    this.selectionChanged();
  }


  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  menuItemSelectionToggle(node: MenuNode): void {
    this.checklistSelection.toggle(node);

    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    this.selectionChanged();
  }

  deselectAllItems(): void {
    this.treeDataSource.data.forEach(x => {

      const descendants = this.treeControl.getDescendants(x);
      if (this.checklistSelection.isSelected(x))
      {
        this.checklistSelection.toggle(x);
        this.checklistSelection.deselect(...descendants);
      }
  
      this.selectionChanged();
    });
  }

  selectionChanged() {

    if (this.screenDetail.treeViewItems) {

      for (const item of this.screenDetail.treeViewItems) {

        Utilities.log(JSON.stringify(item));

        if (item.onClickAction) {
          this.treeItemSelected.emit({ id: this.idName, actionList: item.onClickAction, data: this.checklistSelection.selected });
        }
      }
    }
  }

  editItem(node: MenuNode) {
    this.editItemNodeId = node.id;
  }

  addItem(node: MenuNode) {
    this.addItemNodeId = node.id;
  }

  amendColor(node: MenuNode, color: string) {
    node.color = color;
    this.editItemNode(node);
  }

  amendIcon(node: MenuNode, iconMapping: IconMapping) {
    //this.menuNodes.find(x => x.id === node.id).icon = iconMapping.cssName;
    var menuNode;
    var currentNodes = this.menuNodes;
    while (!menuNode && currentNodes.length > 0)
    {
      menuNode = currentNodes.find(x => x.id === node.id);
      if (!menuNode)
      {
        var arrays = currentNodes.map(d => d.children)
        currentNodes = [].concat.apply([], arrays);
        currentNodes = currentNodes.filter(x => !!x);
      }
      else {
        menuNode.icon = iconMapping.cssName;
      }
    }

    var iconDecimalValue = this.iconHelper.geticonHexToDecimal(iconMapping.charCode);
    node.iconId = +iconDecimalValue;
    this.editItemNode(node);
  }

  addItemNode(node: MenuNode, value: any) {

    if (!value || value === '') { return; }
    const ac = this.screenDetail.treeViewItems[0].onAddAction;
    if (ac[0].action === ActionIdentifier.UpdateData) {
      const action = ac[0].actionArgument as UpdateDataAction;
      const newItem = Utilities.createItemFromObjectDefaults(action.newObjectDefaults, { 'tag-value': value }, null, null, null);

      if (node) {
        const nestBy = this.screenDetail.treeViewItems[0].nestBy;
        if (nestBy) {
          newItem[this.parentIdName] = node.id;
        }
        // set parent colour by default
        newItem[this.colorAttribute] = node.color;
      }
      this.thingService.postDataDetail(action.editUri, newItem)
        .subscribe(results => this.onAddNodeSuccessful(results, node), error => this.onAddNodeFailed(error));
    }
  }

  onAddNodeSuccessful(results: PostResponse[], node: MenuNode): void {

    const newOb = results[0].returnedObject;
    this.dataDetail.dataItems.push(newOb);
    const newNode = new MenuNode(newOb[this.idName], newOb[this.textAttribute], true, newOb[this.colorAttribute]);
    if (this.hasChildren(newOb)) {
      this.createMenuItems(this.children(newOb), newNode.children);
    }
    if (node) {
      if (!node.hasChildren) {
        node.children = [];
        node.hasChildren = true;
      }
      node.children.push(newNode);
    } else {
      this.menuNodes.push(newNode);
    }

    this.treeDataSource.data = null;
    this.treeDataSource.data = this.menuNodes;
    this.tree.renderNodeChanges(this.treeDataSource.data);
    this.addItemNodeId = -1;
  }

  editItemNode(node: MenuNode) {
    if (!node) { return; }

    const ac = this.screenDetail.treeViewItems[0].onEditAction;

    const item = this.dataDetail.dataItems.find(i => i[this.idName] === node.id);
    item[this.textAttribute] = node.text;
    item[this.colorAttribute] = node.color;
    item[this.iconAttribute] = node.iconId;

    if (ac[0].action === ActionIdentifier.UpdateData) {
      const action = ac[0].actionArgument as UpdateDataAction;
      // const uri = Utilities.parseArgumentsFromData(action.editUri, action.editUriArgs, item);

      this.thingService.postDataDetail(action.editUri, item)
        .subscribe(results => this.onEditNodeSuccessful(results, node), error => this.onAddNodeFailed(error));
    }
  }

  onEditNodeSuccessful(results: PostResponse[], node: MenuNode): void {
    this.editItemNodeId = null;
    this.editedNodeIndexList = this.editedNodeIndexList.filter(x => x !== node.index);
  }

  deleteItemNode(node: MenuNode) {

    if (!node || node.hasChildren) { return; }

    const ac = this.screenDetail.treeViewItems[0].onRemoveAction;
    if (ac[0].action === ActionIdentifier.DeleteItem) {
      const action = ac[0].actionArgument as UpdateDataAction;
      const item: any = {};
      item[this.idName] = node.id;

      const uri = Utilities.parseArgumentsFromData(action.editUri, action.editUriArgs, item);
      this.thingService.deleteSingleItem(uri)
        .subscribe(results => this.onDeleteNodeSuccessful(results, node), error => this.onAddNodeFailed(error));
    }
  }
  onDeleteNodeSuccessful(results: PostResponse, node: MenuNode): void {

    this.removeNodeIfMatch(this.menuNodes, node.id);

    this.treeDataSource.data = null;
    this.treeDataSource.data = this.menuNodes;
    this.tree.renderNodeChanges(this.treeDataSource.data);
  }

  removeNodeIfMatch(parentNodes: MenuNode[], id: number) {
    parentNodes.forEach(n => {
      if (n.hasChildren) {
        this.removeNodeIfMatch(n.children, id);
        n.hasChildren = n.children.length > 0;
      } else {
        const index = parentNodes.findIndex(pn => pn.id === id);
        if (index > -1) {
          parentNodes.splice(index, 1);
        }
      }
    });
  }

  onAddNodeFailed(error: any): void {
    console.log(error);
  }
}
