import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ThingService } from '../../../services/thing/thing.service';
import { Utilities } from '../../../services/utilities/utilities';

import { Action } from '../../../models/actions/Action';
import { ScreenType, ActionIdentifier } from '../../../models/Enums';
import { ScreenDetail } from '../../../models/ScreenDetail';
import { TitleUpdateEvent } from 'src/app/models/events/TitleUpdateEvent';
import { ActionEvent } from 'src/app/models/events/ActionEvent';
import { AddUpdateItemModel } from 'src/app/models/events/AddUpdateItemModel';
import { ItemListComponent } from '../lists/item-list/item-list.component';
import { DeleteEvent } from 'src/app/models/events/DeleteEvent';
import { ViewDetailEvent } from 'src/app/models/events/ViewDetailEvent';
import { RefreshEvent } from 'src/app/models/events/RefreshEvent';
import { MenuNode } from 'src/app/models/menu-tree/MenuNode';
import { ReloadDataAction } from 'src/app/models/actions/ReloadDataAction';
import { TreeItemSelectedEvent } from 'src/app/models/events/TreeItemSelectedEvent';

@Component({
  selector: 'app-view-multiple',
  templateUrl: './view-multiple.component.html',
  styleUrls: ['./view-multiple.component.scss']
})
export class ViewMultipleComponent implements OnInit, AfterViewInit {
  screenType = ScreenType;
  private _linkedRow: any;

  @Input() id: string;
  @Input() screenParameters: any;
  @Input() height: number;
  @Input() width: number;
  screenDetailComponents: ScreenDetail;
  @Output() onSetTitle = new EventEmitter<TitleUpdateEvent>();
  @Output() onAction = new EventEmitter<ActionEvent>();
  @Output() onAdd = new EventEmitter<AddUpdateItemModel>();
  @Output() onViewItem = new EventEmitter<ViewDetailEvent>();
  @Output() onDeleteCompleted = new EventEmitter<DeleteEvent>();
  // @Output() onUpdateCompleted = new EventEmitter<any>();
  orientation: string;
  _action: Action;
  layoutAlign: string;
  showTree = true;
  treeWidth = 400;
  @ViewChildren(ItemListComponent) itemLists: QueryList<ItemListComponent>;

  constructor(public toastr: ToastrService, private thingService: ThingService) { }

  ngOnInit() {
    // this.width = this.width - 95;
  }

  ngAfterViewInit(): void {
    // this.width = this.width - 55;

  }

  get action(): Action {
    return this._action;
  }
  @Input()
  set action(value: Action) {
    if (value) {
      this._action = value;
      this.loadData();
    }
  }

  get linkedRow(): any {
    return this._linkedRow;
  }

  @Input()
  set linkedRow(value: any) {

    if (value !== null) {

      this._linkedRow = value;
    }

    // if (this.linkedRow) {

    //   if (this.linkedRow.action !== null && this.linkedRow.row !== null) {

    //     this.loadData();

    //   }
    // }
  }

  refresh(event?: RefreshEvent) {
    if (this.itemLists && this.itemLists.last) {
      this.itemLists.last.refresh(event);
    }
  }

  loadData() {

    this.thingService.getScreenDetailComponents(this.action.actionArgument.screenUri)
      .subscribe(results => this.onGetDetailSuccessful(results), error => this.onGetDetailFailed(error));

  }

  private onGetDetailSuccessful(screenDetailComponents: ScreenDetail) {

    this.screenDetailComponents = screenDetailComponents;

    // Sort items so top first then bottom
    let first: ScreenDetail[] = this.screenDetailComponents.componentScreens.filter(m => m.screenPosition === 'top');
    let second: ScreenDetail[] = this.screenDetailComponents.componentScreens.filter(m => m.screenPosition === 'bottom');

    if (first.length > 0 && second.length > 0) {
      this.orientation = 'column';
      this.layoutAlign = null;
    } else {
      first = this.screenDetailComponents.componentScreens.filter(m => m.screenPosition === 'left');
      second = this.screenDetailComponents.componentScreens.filter(m => m.screenPosition === 'right');

      this.orientation = 'row';
      this.layoutAlign = 'space-between stretch';
    }

    this.screenDetailComponents.componentScreens = first;

    for (const item of second) {
      this.screenDetailComponents.componentScreens.push(item);
    }
    const title = Utilities.parseTitle(this.screenDetailComponents.screenTitle, this.linkedRow, null, this.screenParameters);
    const subtitle = Utilities.parseTitle(this.screenDetailComponents.screenSubtitle, this.linkedRow, null, this.screenParameters);

    this.onSetTitle.emit({ id: this.id, title: title, subtitle: subtitle });
  }

  performAction(event) {
     this.onAction.emit(event);
  }
  performAdd(event) {
     this.onAdd.emit(event);
  }
  performViewItem(event: ViewDetailEvent) {
    this.onViewItem.emit(event);
  }
  performDeleteCompleted(event) { this.onDeleteCompleted.emit(event); }

  getTitle(item: ScreenDetail) {
    return Utilities.parseTitle(item.screenTitle, this.linkedRow, null, this.screenParameters);
  }

  public isItemListScreen(screenType: ScreenType) {
    return screenType === ScreenType.ItemGridList ||
      screenType === ScreenType.ItemCardList ||
      screenType === ScreenType.SectionCardList ||
      // TODO SwitchContentView shouldn't be in here but for now it works!
      screenType === ScreenType.SwitchContentView;
  }

  private onGetDetailFailed(error: any) {
    this.toastr.error(`Unable to retrieve data from the server.\r\nErrors: '${Utilities.getHttpResponseMessage(error)}'`, null, { closeButton: true, tapToDismiss: true });
  }

  onShowTree() {
    this.showTree = !this.showTree;
    this.treeWidth = this.showTree ? 400 : 40;
    // setTimeout(() => {
    //   this.basketItems.updateWidth();
    // });
  }

  public onTreeItemSelected(value: TreeItemSelectedEvent) {

    const id: string = value.id;
    const actionList: Action[] = value.actionList;
    const menuNodes: MenuNode[] = value.data;

    actionList.forEach(action => {
      if (action.action === ActionIdentifier.ReloadData) {

        const actionArg = action.actionArgument as ReloadDataAction;
        let filter = '';

        for (const node of menuNodes) {

          if (filter !== '') {
            filter += ' or ';
          }

          filter += `${id} eq ${node.id}`;
        }

        const screen = this.itemLists.find(i => i.id === actionArg.targetScreen);
        if (screen) {
          screen.setFilter(filter);
        }
      }
    });
  }
}
