import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GenerateDocumentBody, GenerateDocumentHelper } from 'src/app/models/actions/GenerateDocumentAction';
import { ThingService } from 'src/app/services/thing/thing.service';

export interface DialogData {
  body?: GenerateDocumentBody;
  title: string;
}

@Component({
  selector: 'app-generate-document-dialog',
  templateUrl: './generate-document-dialog.component.html',
  styleUrls: ['./generate-document-dialog.component.scss']
})
export class GenerateDocumentDialogComponent implements OnInit {

  constructor(private thingService: ThingService, public dialogRef: MatDialogRef<GenerateDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.documentReturnOptions = [];
    this.body = this.data.body;
    
    if(this.body.allowPDF){
      this.documentReturnOptions.push({id: 0, text: "PDF"});
    }
    if(this.body.allowHTML){
      this.documentReturnOptions.push({id: 1, text: "HTML"});
    }

    if(this.body.dataContext.FTDocumentTemplateID){
      this.templateID = this.body.dataContext.FTDocumentTemplateID;
    }
    if(this.body.documentReturnOptions || this.body.documentReturnOptions == 0){
      this.formatID = this.body.documentReturnOptions;
    }
    
    this.title = this.data.title;
    this.thingService.getData(null, "/api/data/ftDocumentTemplate").subscribe(x => {
      this.templates = x[1].dataItems;
      if(this.templates.length == 1){
        this.templateID = this.templates[0].ftDocumentTemplateID;
      }
    })
  }

  body: GenerateDocumentBody;
  title: string;
  templates: any[];
  templateID: number;
  documentReturnOptions: any[];
  formatID: number;

  generateDocument(){
    if(!this.templateID || (!this.formatID && this.formatID != 0)){
      return;
    }
    this.body.dataContext.FTDocumentTemplateID = this.templateID;
    this.body.documentReturnOptions = this.formatID;

    if(this.body.documentReturnOptions == 0){
      this.thingService.generatePDFDocument(this.body).subscribe(x => {
        var disposition = x.headers.get('content-disposition');
        var filename = "";
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) { 
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        var fileDownloaded = GenerateDocumentHelper.downloadFile(x.body, "application/pdf", filename);
        if(fileDownloaded){
          this.dialogRef.close(true);
        }
      })
    }
    else{
      this.thingService.generateHTMLDocument(this.body).subscribe(x => {
        GenerateDocumentHelper.openHTML(x.body);
        this.dialogRef.close(true);
      })
    }

    
  }

  templateSelect(event){
    this.templateID = event.value;
  }

  close(){
    this.dialogRef.close(false);
  }

}
